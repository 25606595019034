import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Alert } from "react-bootstrap";
import * as SkillQuarterService from "../../service/skillquaterly";
import * as CreateSkillService from "../../service/createSkill";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import ViewSkill from "../SkillMaster/ViewSkill";
if (typeof window !== "undefined") {
  injectStyle();
}

const CreateSkill = (props) => {
  const { formValidation, setFormValidation, isDisplay } = props;
  const [dropData, setdropData] = useState("");
  const [allEmployee, setAllEmployee] = useState([]);
  const [dataEmp, setDataEmp] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [createData, setCreateData] = useState({
    skill_id: 0,
    employee_id: 0,
    current_level: "",
    planned_level: "",
    quarter: isDisplay.quarter,
    year: isDisplay.year,
  });

  const getSKillPlan = (sort_by, planId) => {
    CreateSkillService.getSKillPlan(sort_by, planId, "create")
      .then((response) => {
        setPlanData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getEmployee = (paginate) => {
    SkillQuarterService.getEmployeeByCreator(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = {
            value: x.employee_id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(data1);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getAllSkills = (paginate) => {
    CreateSkillService.getAllSkillsByCreator(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = {
            value: x.id,
            label: x.skill_id,
          };
          data.push(data1);
        });
        setAllSkills(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const onView = async (data) => {
    const response = await CreateSkillService.getSkillById(data.skill_Id)
    setViewForm(true);
    setViewFormData(response.data.dataList.result[0]);
  };

  const onViewClose = () => {
    setViewForm(false);
  };

  const saveCreateSkill = () => {
    let data = {};
    data.year = isDisplay.year;
    data.quarter = isDisplay.quarter;
    data.skill_id = createData.skill_id;
    data.employee_id = createData.employee_id;
    data.current_level = createData.current_level;
    data.planned_level = dropData;
    data.plan_id = isDisplay.plan;
    CreateSkillService.saveCreateSkill(data)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          getSKillPlan("id", isDisplay?.plan);
          setError(false);
          toast.success(response.data.message);
          setCreateData({
            skill_id: 0,
            employee_id: 0,
            current_level: "",
            planned_level: "",
            quarter: isDisplay.quarter,
            year: isDisplay.year,
          });
          setDataEmp([]);
          setDataSkill([]);
          setdropData("");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const isDataExists = () => {
    let data = {};
    data.skill_id = createData.skill_id;
    data.employee_id = createData.employee_id;
    data.plan_id = isDisplay.plan
    CreateSkillService.isDataExists(data)
      .then((response) => {
        console.log(response)
        if (response?.data?.dataList?.is_exist === false) {
          saveCreateSkill();
        } else if (response?.data?.dataList?.is_exist === true) {
          setError(true);
          setErrorMsg(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const deleteCreate = (id) => {
    CreateSkillService.deleteCreate(id)
      .then((res) => {
        if (res.data.status === true) {
          getSKillPlan("id", isDisplay?.plan);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id) => {
    confirmAlert({
      title: "Delete Data",
      message: `Are you sure to delete?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCreate(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleValidate = () => {
    let isError = false;

    const formValid = { ...formValidation };
    if (!createData.employee_id) {
      isError = true;
      formValid.employee_id = "*Employee Code is Required";
      setFormValidation(formValid);
    }
    if (!createData.skill_id) {
      isError = true;
      formValid.skill_id = "*Skill is Required";
      setFormValidation(formValid);
    }
    if (!createData.current_level) {
      isError = true;
      formValid.current_level = "*Current Level is Required";
      setFormValidation(formValid);
    }
    return isError;
  };

  const handleValidateTab = () => {
    if (handleValidate()) return false;
    console.log("hey there");
    isDataExists();
  };

  const UserPush = (e) => {
    setDataEmp(e);
    setCreateData({
      ...createData,
      employee_id: e.value,
    });
  };

  const PushSKill = (e) => {
    setDataSkill(e);
    setCreateData({
      ...createData,
      skill_id: e.value,
    });
  };

  const dataFunctionChange = (value) => {
    console.log(value);
    if (value === "Beginner") {
      setdropData("Intermediate");
    }
    if (value === "Intermediate") {
      setdropData("Master");
    }
    if (value === "Master") {
      setdropData("Expert");
    }
    if (value === "") {
      setdropData("");
    }
  };

  useEffect(() => {
    getAllSkills(false);
    getEmployee(false);
  }, []);

  useEffect(() => {
    if (isDisplay?.plan !== undefined) {
      getSKillPlan("id", isDisplay?.plan);
    }
  }, [isDisplay]);

  return (
    <>
      {isDisplay?.is_create ? (
        <>
          <div className="px-4 pb-2">
            <ToastContainer autoClose={1000} />
            <h5 className="text-dark fa-20 border-bottom pb-2 my-3">
              <strong>
                Skill Plan for Quarter:{" "}
                {isDisplay?.quarter?.replace("-", " to ")} {isDisplay?.year}
              </strong>
            </h5>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered tablecured viewpo innergroup leavetableapply vehiclelog">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Skill Code</th>
                  <th>Skill Description</th>
                  <th>Employee Code</th>
                  <th>Detail</th>
                  <th>Current Level</th>
                  <th>Planned Level</th>
                </tr>
              </thead>
              <tbody>
                {planData?.map((data, index) => (
                  <>
                    <tr key={`plan${index}`}>
                      <td>
                        <button
                          className="btn btn-blanktd text-danger ml-2"
                          type="button"
                          onClick={() => {
                            submit(data?.id);
                          }}
                        >
                          <i className="far fa-trash-alt text-danger"></i>
                        </button>
                      </td>
                      <td>{data?.skill_Id}</td>
                      <td>{data?.skill_description}</td>
                      <td>
                        {data?.employee_name} ({data?.employee_code})
                      </td>
                      <td>
                        <button className="btn btnblanktd text-primary p-0 f-14" onClick={() => {
                          onView(data)
                        }}>
                          Check
                        </button>
                      </td>
                      <td> {data?.current_level}</td>
                      <td> {data?.planned_level}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>

          <div className="px-4 pb-2">
            <div className="row align-items-center">
              <div className="col-md-12 text-sm-right">
                <button
                  className="btn btnblanktd text-primary"
                  type="submit"
                  onClick={() => {
                    handleValidateTab();
                  }}
                >
                  <i className="far fa-plus-square mr-2"></i> Add More
                </button>
              </div>
            </div>

            <div className="bg-light px-3 pt-3 mb-3">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Skill<span className="text-danger">*</span>
                    </label>
                    <Select
                      name="skill_id"
                      id="skill_id"
                      options={allSkills}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        PushSKill(e);
                        setFormValidation({
                          ...formValidation,
                          skill_id: "",
                        });
                      }}
                      value={dataSkill}
                    />
                    <div className="small text-danger">
                      {formValidation.skill_id}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Employee Code<span className="text-danger">*</span>
                    </label>
                    <Select
                      name="employee_id"
                      id="employee_ids"
                      options={allEmployee}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        UserPush(e);
                        setFormValidation({
                          ...formValidation,
                          employee_id: "",
                        });
                      }}
                      value={dataEmp}
                    />
                    <div className="small text-danger">
                      {formValidation.employee_id}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Current Level<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control newbgselect"
                      name="current_level"
                      onChange={(e) => {
                        dataFunctionChange(e.target.value);
                        setCreateData({
                          ...createData,
                          current_level: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          current_level: "",
                        });
                      }}
                      value={createData.current_level}
                    >
                      <option value="">Select</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Master">Master</option>
                    </select>
                    <div className="small text-danger">
                      {formValidation.current_level}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Planned Level</label>
                    <select
                      disabled
                      className="form-control newbgselect"
                      name="planned_level"
                      value={dropData}
                    >
                      <option value="">Select</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Master">Master</option>
                      <option value="Expert">Expert</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {error && (
              <Alert variant="danger" className="mb-5 mt-2">
                <i className="fa fa-exclamation-triangle"></i> {errorMsg}
              </Alert>
            )}

            {/* <div className="travel-note">
              <h4>Notes :-</h4>
              <ul>
                <li>
                  <a href="#">Click here</a> to Download Sample File.
                </li>
              </ul>
            </div> */}
          </div>
        </>
      ) : (
        <p style={{ color: "#abaaa6", marginTop: "47px", marginLeft: "25px" }}>
          Create skill plan is not open yet.
        </p>
      )}
      {viewForm ? (
        <>
          <ViewSkill
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default CreateSkill;
