import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getCropPattern = async () => {
    return axios.get(`${AUTH_BASE_URL}/crop-pattern`);
};

export const getCategoryDropdown = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/crop-pattern/crops-category/drop-down`);
}

export const getCropsByCatId = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/crop-pattern/crops/drop-down${query}`)
}

export const getLastCropPattern = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/crop-pattern/last-crop-pattern${query}`)
}

export const getAllDates = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/crop-pattern/crops-dates/drop-down`)
}


export const postCropPatternSize = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/crop-pattern`,body);
}

export const putCropPatternSize = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/crop-pattern`,body);
}

export const getCropPatternApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/crop-pattern/approver-crop-pattern-logs${query}`)
}

export const postCropPatternAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/crop-pattern/action-crop-pattern`,body);
}