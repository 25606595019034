import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Dropdown } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { getPlantList } from "../../service/jv_service";
import {
  getUsersListDataByOrg,
  getUsersListDataNew,
} from "../../service/userService";
import {
  deleteUserPlantMapping,
  getUserPlantMapping,
  getUserPlantMappingExport,
  saveUserPlantMapping,
} from "./reservationService";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { selectUserData, selectFinalUrlPermission } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

function UserPlantMapping() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const roleOptions = [
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "User",
      value: "user",
    },
  ];
  const userData = useSelector(selectUserData);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [addNew, showAddNew] = useState(false);
  const openAddNew = () => showAddNew(true);
  const closeAddNew = () => showAddNew(false);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [editRecord, setEditRecord] = useState(false);
  const [plantOptions, setPlantOptions] = useState([]);
  const [userList, setUserList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [selectedData, setSelectedData] = useState(null);
  const [error, setErrors] = useState({
    plant: false,
    user: false,
    duplicate: false,
  });
  let condition = "true";
  const [mappedDataFilter, setMappedDataFilter] = useState({
    status: true,
    role_type: "",
  });
  const [formObj, setFormObj] = useState({
    user: "",
    plant: [],
  });
  useEffect(() => {
    if (userData?.organisation_id !== undefined) {
      getRecords(
        searchStr,
        mappedDataFilter?.role_type,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  }, [userData]);

  useEffect(() => {
    getData();
  }, [recordList]);

  const handleKey = (
    search,
    role_type,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (role_type !== "" && role_type !== undefined && role_type !== null) {
      queryParm = queryParm + "&role_type=" + role_type;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getRecords = async (
    search,
    role_type,
    currentPage,
    pageNo,
    soryBy,
    paginate
  ) => {
    await getUserPlantMapping(
      handleKey(search, role_type, currentPage, pageNo, soryBy, paginate)
    ).then((response) => {
      if (response?.status === 200) {
        setRecordList(response?.data?.dataList?.result);
      }
    });
  };

  const getRecordsExport = async (
    search,
    role_type,
    currentPage,
    pageNo,
    soryBy,
    paginate
  ) => {
    await getUserPlantMappingExport(
      handleKey(search, role_type, currentPage, pageNo, soryBy, paginate)
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "UserPlantMapping.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const getData = async () => {
    await getPlant();
    await getUserList();
  };

  const getUserList = async () => {
    await getUsersListDataNew(0).then((response) => {
      if (response?.status === 200) {
        let users = response?.data?.dataList?.result
          .filter((obj) => {
            if (
              recordList
                ?.map((x) => {
                  return x?.user_id;
                })
                .includes(obj?.id) ||
              obj?.username === "super_pi" ||
              obj?.username === "admin"
            ) {
              return false;
            } else {
              return true;
            }
          })
          .map((obj) => {
            return {
              label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
              value: obj?.id,
            };
          });
        setUserList(users);
      }
    });
  };

  const getPlant = async () => {
    await getPlantList(userData?.organisation_id)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.description} (${obj?.code})`,
          };
        });
        res.push({
          value: "ALL",
          label: `ALL`,
        });
        setPlantOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event, name, isEdit) => {
    switch (name) {
      case "user":
        setFormObj((prev) => ({
          ...prev,
          user: event,
        }));
        break;
      case "plant":
        setFormObj((prev) => ({
          ...prev,
          plant: event,
        }));
        break;
      default:
        break;
    }
  };

  const resetForm = () => {
    closeAddNew();
    setFormObj({
      user: "",
      plant: [],
    });
    setErrors({
      user: false,
      plant: false,
    });
    setEditRecord(false);
  };

  const saveRecord = async () => {
    if (formObj?.plant?.length > 0 && formObj?.user !== "") {
      if (condition === "true") {
        condition = "false";
        let data = formObj;
        if (formObj?.plant[0]?.value === "ALL") {
          data.plant = plantOptions?.filter((obj) => {
            if (obj?.value !== "ALL") {
              return true;
            }
          });
        }
        await saveUserPlantMapping(data)
          .then((response) => {
            if (response?.status === 200) {
              condition = "true";
              getRecords(
                searchStr,
                mappedDataFilter?.role_type,
                currentPage,
                pageSizeNo,
                sortData.sort_by,
                true
              );
              if (formObj?.id !== undefined) {
                toast.success("Record Updated Successfully.");
              } else {
                toast.success("Plants Mapped to user successfully.");
              }
              resetForm();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        plant: formObj?.plant?.length === 0 ? true : false,
        user: formObj?.user === "" ? true : false,
      }));
    }
  };

  const handleSearch = (e) => {
    getRecords(
      e.target.value.toLowerCase(),
      mappedDataFilter?.role_type,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    setSearchStr(e.target.value);
  };

  const viewData = (data) => {
    setSelectedData(data);
    setEditRecord(true);
  };

  const deleteRecord = (id, reservationNum) => {
    confirmAlert({
      title: "Delete User Plant Mapping",
      message: `Are you sure to delete the mapping ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => delteRecord(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const delteRecord = async (id) => {
    await deleteUserPlantMapping(id)
      .then((response) => {
        if (response?.data?.code === 200) {
          toast.success("Record deleted Successfully.");
          getRecords(
            searchStr,
            mappedDataFilter?.role_type,
            currentPage,
            pageSizeNo,
            sortData?.sort_by,
            true
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getRecords(
      searchStr,
      mappedDataFilter?.role_type,
      activePage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  };

  const checkRecordExists = (user) => {
    let data = recordList?.filter((obj) => {
      if (obj?.user_id === user?.value) {
        return true;
      }
    });
    if (data?.length > 0) {
      setErrors((prev) => ({
        ...prev,
        duplicate: true,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        duplicate: false,
      }));
    }
  };

  const updateRecord = (obj) => {
    openAddNew();
    setFormObj((prev) => ({
      ...prev,
      id: obj?.id,
      user: {
        value: obj?.user_id,
        label: `${obj?.full_name} (${obj?.user__username})`,
      },
      plant: obj?.plant?.map((plant) => {
        return {
          label: `${plant?.description} (${plant?.code})`,
          value: plant?.id,
        };
      }),
    }));
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="row">
        <div className="col-md-12">
          <div className="content-wrapper-inner content-wrapper-inner2">
            <div className="innerheadsec">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="inner-page-title">User Plant Mapping</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/home">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Reservations</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div className="col-md-12">
                  <div className="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="input-group table-search">
                    <span className="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" className="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getRecordsExport(
                                searchStr,
                                mappedDataFilter?.role_type,
                                currentPage,
                                pageSizeNo,
                                sortData?.sort_by,
                                false
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {finalUrlValue?.A ? (
                      <button
                        onClick={openAddNew}
                        className="btn btn-primary-inner"
                      >
                        <img src="images/upload.png" alt="" className="mr-3" />
                        Add New
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="purchaseinnertabs">
              <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>User</th>
                      <th>Plant</th>
                      <th>Created By</th>
                      <th>Created At</th>
                      {finalUrlValue?.V || finalUrlValue?.R ? (
                        <th>Actions</th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {recordList.map((obj, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>
                          {obj?.user__username} ({obj?.full_name})
                        </td>
                        <td>{obj?.plant?.length}</td>
                        <td>{obj?.created_by}</td>
                        <td>
                          {moment
                            .utc(obj?.created_at)
                            .format("Do MMM YYYY, h:mm:ss A")}
                        </td>
                        {finalUrlValue?.V ||
                        finalUrlValue?.E ||
                        finalUrlValue?.R ? (
                          <td>
                            {finalUrlValue?.V ? (
                              <button
                                onClick={() => viewData(obj)}
                                className="btn btn-blanktd text-primary ml-2"
                              >
                                <img src="images/eye.png" alt="" />
                              </button>
                            ) : (
                              ""
                            )}
                            {finalUrlValue?.E ? (
                              <>
                                <button
                                  class="btn btn-blanktd text-primary ml-2"
                                  onClick={() => {
                                    updateRecord(obj);
                                  }}
                                >
                                  <i class="far fa-edit"></i>{" "}
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                            {finalUrlValue?.R ? (
                              <button
                                className="btn btn-blanktd text-danger ml-2"
                                onClick={() => {
                                  deleteRecord(obj.id);
                                }}
                              >
                                <i className="far fa-trash-alt text-danger"></i>
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {recordList?.length == 0 ? (
        ""
      ) : (
        <div className="row">
          <div className="col-sm-5">
            <div className="sortinglist">
              Show
              <select
                className="form-control"
                onChange={(e) => {
                  setPageSizeNo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value={10} label={10}>
                  10
                </option>
                <option value={20} label={20}>
                  20
                </option>
                <option value={30} label={30}>
                  30
                </option>
                <option value={100} label={100}>
                  100
                </option>
              </select>
            </div>
          </div>
          <div className="col-sm-7">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-left"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      )}
      <Modal
        show={addNew}
        onHide={() => {
          closeAddNew();
          setEditRecord(false);
          resetForm();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editRecord ? "View Reservations" : "Add/Edit Reservations"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      User<span className="text-danger">*</span>
                    </label>
                    <Select
                      options={userList}
                      name="user"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isDisabled={editRecord}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                        setErrors((prev) => ({
                          ...prev,
                          user: false,
                        }));
                        checkRecordExists(newValue);
                      }}
                      onBlur={(e) => {
                        if (e.value === undefined && formObj?.user === "") {
                          setErrors((prev) => ({
                            ...prev,
                            user: true,
                          }));
                        }
                      }}
                      value={formObj.user}
                    />
                    <div
                      className="text-left pt-2 text-danger"
                      hidden={!error.user}
                    >
                      *Kindly Select User
                    </div>
                    <div
                      className="text-left pt-2 text-danger"
                      hidden={!error.duplicate}
                    >
                      *Record already exists for selected user.
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Plant<span className="text-danger">*</span>
                    </label>
                    <Select
                      options={plantOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="plant"
                      isDisabled={editRecord}
                      isMulti
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                        setErrors((prev) => ({
                          ...prev,
                          plant: false,
                        }));
                      }}
                      onBlur={(e) => {
                        if (
                          e.target.value === "" &&
                          formObj?.plant?.length === 0
                        ) {
                          setErrors((prev) => ({
                            ...prev,
                            plant: true,
                          }));
                        }
                      }}
                      value={formObj.plant}
                    />
                    <div
                      className="text-left pt-2 text-danger"
                      hidden={!error.plant}
                    >
                      *Kindly Select Plant
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {!editRecord && (
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              onClick={() => {
                closeAddNew();
                setErrors({
                  user: false,
                  plant: false,
                });
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary-inner bpi-main"
              onClick={saveRecord}
            >
              Submit
            </button>
          </Modal.Footer>
        )}
      </Modal>
      <Modal
        show={editRecord}
        onHide={resetForm}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>View User Plant Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row viewformaftersubmit">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>User</label>
                    <p>{`${selectedData?.user__username} (${selectedData?.full_name})`}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Plant</label>
                    <p>
                      {selectedData?.plant.map((b) => (
                        <h6>{`${b?.description} (${b?.code})`}</h6>
                      ))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserPlantMapping;
