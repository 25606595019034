import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import * as VisitorService from "./visitor_service";
import moment from "moment/moment";

function VisitorMasterManagement() {
  const [pageCount, setpageCount] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [visitorData, setVisitorData] = useState([]);

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
  };

  const handleKey = (id, self_data, page_no, page_size, paginate, search) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (id !== "" && id !== undefined && id !== null) {
      queryParm = queryParm + "&id=" + id;
    }
    if (self_data !== "" && self_data !== undefined && self_data !== null) {
      queryParm = queryParm + "&self_data=" + self_data;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = (e) => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
    }
  };

  useEffect(() => {
    VisitorService.get_visitor_info(handleKey("", "", 1, 10, true, "")).then(
      (response) => {
        setVisitorData(response?.data?.dataList?.result);
      }
    );
  }, []);

  return (
    <div>
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Visitor Management</h4>
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on <strong> Email</strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    type="text"
                    id="searchbar"
                    class="form-control"
                    placeholder="Search on Email..."
                    onChange={(e) => {
                      handleSearch(e);
                      setCurrentPage(1);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={(e) => {
                          closeButtonCallBack(e);
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                {/* {finalUrlValue?.A ? ( */}
                <Link to="/visitor">
                  <button class="btn btn-primary-inner bpi-main" type="button">
                    Send Link to New Visitor
                  </button>
                </Link>
                {/* ):""} */}
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                {/* {finalUrlValue?.R || finalUrlValue?.E ? */}
                <th>Action</th>
                {/* // : ""} */}
                <th>Company Name</th>
                <th>Contact Person</th>
                <th>Email Id</th>
                <th>Response Submission Status</th>
                <th>Response Submitted At</th>
                <th>Created By</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {visitorData?.length ? (
                visitorData?.map((data) => (
                  <>
                    <tr>
                      {/* {finalUrlValue?.R || finalUrlValue?.E ? */}
                      <td>
                        <Link
                          to={`/visiting-site-details?visitor_info_id=${data?.id}`}
                        >
                          <button class="btn btn-blanktd text-success">
                            <i
                              class="far fa-eye"
                              style={{ color: "#0195D4" }}
                            ></i>
                          </button>
                        </Link>
                      </td>
                      {/* // : ""} */}
                      <td>{data?.company_name}</td>
                      {/* <td>
                        {moment(data?.visiting_date).format("DD-MM-YYYY")}
                      </td> */}
                      <td>{data?.contact_person}</td>
                      <td>{data?.email}</td>
                      {data?.response_submitted === false ? (
                        <>
                          <td>Pending</td>
                          <td></td>
                        </>
                      ) : (
                        <>
                          <td>Submitted</td>
                         
                        </>
                      )}
                      <td>{data?.creator}</td>
                      <td>{moment(data?.created_at).format("DD-MM-YYYY")}</td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <div class="sortinglist">
              Show
              <select
                class="form-control main-pointer"
                onChange={(e) => {
                  setPageSizeNo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value={10} label={10}>
                  10
                </option>
                <option value={20} label={20}>
                  20
                </option>
                <option value={30} label={30}>
                  30
                </option>
                <option value={100} label={100}>
                  100
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-7">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-left"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default VisitorMasterManagement;
