import React, {useEffect, useRef, useState} from "react";
import * as VisitorService from './visitor_service';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function VisitingSite() {

    const navigate = useNavigate();

    let qry = new URLSearchParams(window.location.search)
    const visitor_info_id = qry.get('visitor_info_id')

    const [visitorResponseData, setVisitorResponseData] = useState([]);
    const [visitingSiteDetailsData, setVisitingSiteDetailsData] = useState([]);
    
    const [other_remark, setOther_remark] = useState('');
    const [visitingSiteData, setVisitingSiteData] = useState([{
        visitor_info_id: visitor_info_id,
        visiting_site: '',
        nearest_airport: '',
        distance_from_mumbai: '',
        distance_from_delhi: '',
        nearest_city: '',
        site_distance_from_city: '',        
        site_climatic_condition: '',
        site_operating_hours: '',
        local_city_attraction: ''
    }])
    const ref = useRef(null);
    const ref2 = useRef(null);
    const printRef = useRef(null);
    const backRef = useRef(null);
    
    useEffect(()=>{
        VisitorService.get_visitor_response(visitor_info_id).then((response)=>{
          //  console.log(response?.data?.dataList?.result2);
            setVisitorResponseData(response?.data?.dataList?.result)
            setOther_remark(response?.data?.dataList?.result[0]?.other_remark)
            setVisitingSiteDetailsData(response?.data?.dataList?.result2)
            if(response?.data?.dataList?.result2?.length > 0){
                let backendData = response?.data?.dataList?.result2[0]
                setVisitingSiteData(prevState => ({
                    ...prevState,
                    visitor_info_id: backendData.visitor_info_id || '',
                    from_visiting_site: backendData.from_visiting_site || '',
                    to_visiting_site: backendData.to_visiting_site || '',
                    nearest_airport: backendData.nearest_airport || '',
                    distance_from_mumbai: backendData.distance_from_mumbai || '',
                    distance_from_delhi: backendData.distance_from_delhi || '',
                    nearest_city: backendData.nearest_city || '',
                    site_distance_from_city: backendData.site_distance_from_city || '',
                    recommended_hotel: backendData.recommended_hotel || '',
                    site_climatic_condition: backendData.site_climatic_condition || '',
                    site_operating_hours: backendData.site_operating_hours || '',
                    local_city_attraction: backendData.local_city_attraction || ''
                  }));
            }
        })
    },[])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setVisitingSiteData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

    const handlePrint = () => {
      const div = ref.current; // corresponding DOM node
      const div2 = ref2.current; // corresponding DOM node
      const print = printRef.current; // corresponding DOM node
      
      div.style.overflow = "visible";
      div2.style.overflow = "visible";
      backRef.current.style.display ='none'
      print.style.display = 'none';
      window.print();
      div.style.overflow = "auto";
      div2.style.overflow = "auto";
      print.style.display = 'block';
      backRef.current.style.display ='block'
    };

    const saveVisitingSiteDetails = () => {
        VisitorService.save_visiting_site_details(visitingSiteData).then((response)=>{
            toast.success('Details Saved Successfully !!')
            navigate('/visitor-master-management')
        })
    }
   

    return (
        <div class="content-wrapper-inner">
        <div class="innerheadsec">
            <div className="row">
              <div class="col-md-12 d-flex justify-content-space-between align-items-center">
              <div class="col-md-6">
                <h4 class="inner-page-title">
                  Visiting Site Details
                </h4>
                </div>
              </div>
              <div class="col-md-12">
                <div class="border-top mt-2 mt-md-4 mb-3"></div>
              </div>
            </div>
            <div class="border-right"></div>
            {visitorResponseData?.length > 0 && (
              <div className="col-md-12 mt-1 text-right"  ref={printRef} >
              <button className="btn btn-primary-inner bpi-main mr-3" type="button"
              onClick={handlePrint}>
               Print
              </button>
            </div> )
            }
          </div>
          
                    <div class="table-responsive" ref={ref}>
                    
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Visiting Site</th>
                        <th>From Visiting Date</th>
                        <th>To Visiting Date</th>
                        <th>Distance From Delhi</th>
                        <th>Distance From Mumbai</th>
                        <th>Nearest Airport</th>
                        <th>Nearest City</th>
                        <th>Distance of site from City</th>
                        <th>Site Operating hours</th>
                        <th>Site Climatic Condition</th>
                        <th>Local city Attraction</th>
                      </tr>
                    </thead>
                    <tbody>
                      {visitingSiteDetailsData?.map((data, index)=>(
                      <tr>
                        <td>{index + 1}</td>
                        <td>{data?.visiting_site_details}</td>
                        <td>{data?.from_visiting_date}</td>
                        <td>{data?.to_visiting_date}</td>
                        <td>{data?.distance_from_delhi}</td>
                        <td>{data?.distance_from_mumbai}</td>
                        <td>{data?.nearest_airport}</td>
                        <td>{data?.nearest_city}</td>
                        <td>{data?.site_distance_from_city}</td>
                        <td>{data?.site_operating_hours}</td>
                        <td>{data?.site_climatic_condition}</td>
                        <td>{data?.local_city_attraction}</td>
                      </tr>
                    ))}
                    
                       
                    </tbody>
                  </table>
                </div>
        <div class="table-responsive" ref={ref2}>
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Guest Type</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Address</th>
                    <th>Apron Size</th>
                    <th>Gender</th>
                    <th>Safety Shoes Size</th>
                    <th>Food Category</th>
                    <th>Spice Level</th>
                    <th>Other Restrictions</th>
                    <th>Restrictions Details</th>
                  </tr>
                </thead>
                <tbody>
                    {visitorResponseData?.length >  0 ?
                    visitorResponseData?.map((data)=>(
                      <>
                    <tr>
                        <td>{data?.guest_type}</td>
                        <td>{data?.guest_name}</td>
                        <td>{data?.guest_email}</td>
                        <td>{data?.guest_address}</td>
                        <td>{data?.apron_size}</td>
                        <td>{data?.gender}</td>
                        <td>{data?.shoe_size}</td>
                        <td>{data?.food_category}</td>
                        <td>{data?.spice_level}</td>
                        {data?.other_restrictions === true ? 
                        <td> Yes </td>
                        :
                        <td>No</td>
                        }
                        <td>{data?.other_restrictions_details}</td>
                       
                    </tr>
                   
                    </>
                    ))
                    :
                    <tr>
                        <td colSpan={12}>Response not Submitted by Visitor yet...</td>
                    </tr>
                    }
                </tbody>
              </table>
            </div>
            {visitorResponseData?.length > 0 && (
                <>
                 <div class="col-sm-4">
                              <div class="form-group innergroup">
                                <label>Other Remarks</label>
                                <textarea className="form-control" disabled={true} rows="3" cols="30" >{other_remark}</textarea>              
                              </div>
                            </div>
            {/* <div className="mx-sm-3 mt-sm-3 mx-2 mt-2">
                          <div class="row viewformaftersubmit">
                            <div class="col-sm-4">
                              <div class="form-group innergroup">
                                <label>Name of the visiting Site</label>
                                <input
                                  disabled={visitingSiteDetailsData?.length > 0}
                                  type="text"
                                  className="form-control"
                                  name="visiting_site"
                                  placeholder="Enter name of the visiting Site"
                                  value={visitingSiteData?.visiting_site}
                                  onChange={(event) => handleChange(event)}
                                />
                                
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="form-group innergroup">
                                <label>Nearest International / national Airport</label>
                                <input
                                  disabled={visitingSiteDetailsData?.length > 0}
                                  type="text"
                                  className="form-control"
                                  name="nearest_airport"
                                  placeholder="Enter name of the Airport"
                                  value={visitingSiteData?.nearest_airport}
                                  onChange={(event) => handleChange(event)}
                                />
                              
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="form-group innergroup">
                                <label>Distance from Mumbai</label>
                                <input
                                  disabled={visitingSiteDetailsData?.length > 0}
                                  type="text"
                                  className="form-control"
                                  name="distance_from_mumbai"
                                  placeholder="Enter Distance in KM"
                                  value={visitingSiteData?.distance_from_mumbai}
                                  onChange={(event) => handleChange(event)}
                                />
                               
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="form-group innergroup">
                                <label>Distance from Delhi</label>
                                <input
                                  disabled={visitingSiteDetailsData?.length > 0}
                                  type="text"
                                  className="form-control"
                                  name="distance_from_delhi"
                                  placeholder="Enter Distance in KM"
                                  value={visitingSiteData?.distance_from_delhi}
                                  onChange={(event) => handleChange(event)}
                                />
                              
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="form-group innergroup">
                                <label>Nearest City for Stay</label>
                                <input
                                  disabled={visitingSiteDetailsData?.length > 0}
                                  type="text"
                                  className="form-control"
                                  name="nearest_city"
                                  placeholder="Enter city Name"
                                  value={visitingSiteData?.nearest_city}
                                  onChange={(event) => handleChange(event)}
                                />
                               
                              </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group innergroup">
                                <label className="d-block mb-2">
                                Distance of site from city<span class="text-danger"></span>
                                </label>
                                <input
                                  disabled={visitingSiteDetailsData?.length > 0}
                                  type="text"
                                  className="form-control"
                                  name="site_distance_from_city"
                                  placeholder="Enter Distance in KM"
                                  value={visitingSiteData?.site_distance_from_city}
                                  onChange={(event) => handleChange(event)}
                                />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group innergroup">
                                <label className="d-block mb-2">
                                Recommended Hotels<span class="text-danger"></span>
                                </label>
                                <input
                                  disabled={visitingSiteDetailsData?.length > 0}
                                  type="text"
                                  className="form-control"
                                  name="recommended_hotel"
                                  placeholder="Enter Hotel Name"
                                  value={visitingSiteData?.recommended_hotel}
                                  onChange={(event) => handleChange(event)}
                                />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group innergroup">
                                <label className="d-block mb-2">
                                Climatic conditions of the visiting sites<span class="text-danger"></span>
                                </label>
                                <input
                                  disabled={visitingSiteDetailsData?.length > 0}
                                  type="text"
                                  className="form-control"
                                  name="site_climatic_condition"
                                  placeholder="Enter Climatic conditions"
                                  value={visitingSiteData?.site_climatic_condition}
                                  onChange={(event) => handleChange(event)}
                                />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group innergroup">
                                <label className="d-block mb-2">
                                Mfg. Site Operating hours<span class="text-danger"></span>
                                </label>
                                <input
                                  disabled={visitingSiteDetailsData?.length > 0}
                                  type="text"
                                  className="form-control"
                                  name="site_operating_hours"
                                  placeholder="Enter Operating hours"
                                  value={visitingSiteData?.site_operating_hours}
                                  onChange={(event) => handleChange(event)}
                                />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group innergroup">
                                <label className="d-block mb-2">
                                Local City attractions<span class="text-danger"></span>
                                </label>
                                <input
                                  disabled={visitingSiteDetailsData?.length > 0}
                                  type="text"
                                  className="form-control"
                                  name="local_city_attraction"
                                  placeholder="Enter Local City attractions"
                                  value={visitingSiteData?.local_city_attraction}
                                  onChange={(event) => handleChange(event)}
                                />
                                </div>
                            </div>
                          </div>
                        </div> */}
                  </>
                  )}
            <div class="border-top"></div>
              <div className="col-md-4 mt-3">
              <button className="btn btn-primary-inner bpi-main mr-3" ref={backRef} type="button" 
              onClick={() => {
                    window.history.go(-1);
                    return false;
                  }}>
               Back
              </button>
              {/* {visitorResponseData?.length > 0 && (
              <button className="btn btn-primary-inner bpi-main" type="button" onClick={(e)=>{saveVisitingSiteDetails()}} disabled={visitingSiteDetailsData?.length > 0}>
               Submit
              </button>
              )} */}
              </div>
            </div>
    );
}
export default VisitingSite;