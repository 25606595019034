import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import * as PrService from "../../service/prService";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

const validationSchema = Yup.object({
  unit_code: Yup.string()
    .matches(/[^\s*]/g, "* This field should contain minimum 2 alphabet")
    // .matches(
    //     /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
    //     "Insert only character"
    // )
    .required("*PR Unit Code is required")
    .nullable(),
  unit_desc: Yup.string()
    .matches(
      /[^\s*].*[^\s*]/g,
      "* This field should contain minimum 2 alphabet"
    )
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only character"
    )
    .required("*PR Unit Desc is required")
    .nullable(),
});

const initialValues = {
  unit_code: "",
  unit_desc: "",
  is_active: true,
};

const PrAddUnit = (props) => {
  const { addnewclose, addnewshow, refreshData, currentPRUnit } = props;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        let response = null;
        if (currentPRUnit) {
          response = await PrService.updatePrUnit(currentPRUnit.id, values);
        } else {
          response = await PrService.addPrUnit(values);
        }
        if (response.data?.status) {
          toast.success(response.data.message);
          refreshData();
          handleModelClose();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: pr-add-unit.js:99 ~ onSubmit: ~ error:",
          error
        );
      } finally {
        setSubmitting(false);
      }
    },
  });
  const {
    handleSubmit,
    getFieldProps,
    values,
    errors,
    setFieldValue,
    touched,
    isSubmitting,
    resetForm,
  } = formik;

  const handleModelClose = () => {
    resetForm();
    addnewclose();
  };

  useEffect(() => {
    if (currentPRUnit) {
      setFieldValue("unit_code", currentPRUnit.unit_code);
      setFieldValue("unit_desc", currentPRUnit.unit_desc);
      setFieldValue("is_active", currentPRUnit.is_active);
    }
  }, [currentPRUnit]);

  return (
    <Modal
      show={addnewshow}
      onHide={handleModelClose}
      keyboard={false}
      backdrop="static"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add/Edit Unit</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <>
          <Modal.Body>
            <div class="row justify-content-center">
              <ToastContainer autoClose={1000} />
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group innergroup">
                      <label>
                        PR Unit Code<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={30}
                        name="unit_code"
                        class="form-control"
                        placeholder="Enter PR Unit Code"
                        value={values.unit_code}
                        {...getFieldProps("unit_code")}
                      />
                      {touched.unit_code && errors.unit_code ? (
                        <div className="small text-danger">
                          {errors.unit_code}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group innergroup">
                      <label>
                        PR Unit Description <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={50}
                        name="unit_desc"
                        class="form-control"
                        placeholder="Enter PR Unit Description"
                        value={values.unit_desc}
                        {...getFieldProps("unit_desc")}
                      />
                      {touched.unit_desc && errors.unit_desc ? (
                        <div className="small text-danger">
                          {errors.unit_desc}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group innergroup">
                      <label className="d-block mb-2">
                        Status<span class="text-danger">*</span>
                      </label>
                      <label class="logCheck d-inline-block mr-4">
                        Active
                        <input
                          type="radio"
                          name="is_active"
                          id="active"
                          onChange={() => {
                            setFieldValue("is_active", true);
                          }}
                          checked={values.is_active}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label
                        className="logCheck d-inline-block"
                        htmlFor="Inactive"
                      >
                        Inactive
                        <input
                          type="radio"
                          name="is_active"
                          id="Inactive"
                          onChange={() => {
                            setFieldValue("is_active", false);
                          }}
                          checked={!values.is_active}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitting ? (
              <Spinner
                animation="border"
                variant="primary"
                as="span"
                role="status"
                aria-hidden="true"
              ></Spinner>
            ) : (
              <>
                <button
                  class="btn btn-outline-danger"
                  onClick={handleModelClose}
                  type="button"
                >
                  Cancel
                </button>
                <button class="btn btn-primary-inner bpi-main" type="submit">
                  Submit
                </button>
              </>
            )}
          </Modal.Footer>
        </>
      </form>
    </Modal>
  );
};

export default PrAddUnit;
