import React, { useState, useEffect } from "react";
import {
  Modal,
  Dropdown
} from "react-bootstrap";
import Select from "react-select";
import CustomMenu from "./CustomMenu";
import CustomPagination from "./CustomPagination";
import ReactPaginate from "react-paginate";
import { getUserRmList } from "../../service/jv_service";
import * as PrService from "../../service/prService";
import { selectUserData } from "../redux/piDataStore";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as HSNService from "../../service/hsnService";
import { confirmAlert } from "react-confirm-alert";

function AuthorizeUserMaster() {
  const userData = useSelector(selectUserData);
  const CustomBreadcrumbItems = [
    { to: '/home', title: 'Home' },
    { to: '/', title: 'Transactions' },
    { active: true, title: 'Authorize User' }
  ]

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [addnewshow, addnewsetShow] = useState(false);
  const [error, setError] = useState({
    plant_id: "",
    material_type_id: "",
    user_id: "",
  });
  const [matTypeValue, setMatTypeValue] = useState();
  const [plantListValue, setPlantListValue] = useState();
  const [editMode, setEditMode] = useState(false);
  const [user, setUser] = useState();
  const [status, setStatus] = useState(true);
  const [usersOptions, setUsersOptions] = useState([]);
  const [plantOptions, setPlantOptions] = useState([]);
  const [authUserList,setAuthUserList] = useState([]);
  const [materialTypeOptions, setMaterialTypeOptions] = useState([]);
  const [authUserData, setAuthUserData] = useState({
    plant_list: 0,
    material_type_list: 0,
    user: 0,
    status: true,
  });
  const [pageCount, setpageCount] = useState(1);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");


  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => {
    addnewsetShow(false);
    setPlantListValue()
    setUser()
    setMatTypeValue()
  }

  useEffect(() => {
      getUsers();
      getPlants();
      getMaterialTypePlant();
      getUserAuthorizeMaster(
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        searchStr,
        true
      );
  }, []);

  const getUserAuthorizeMaster =(page_no, page_size, sort_by, search, paginate)=>{
    HSNService.getHsnAuthUserListing(handleKey(page_no, page_size, sort_by, search, paginate)).then((response)=>{
      setAuthUserList(response?.data?.dataList?.result)
      setpageCount(response?.data?.dataList?.paginated_data?.totalPages)
    })
  }
  const handleKey = (page_no, page_size, sort_by, search, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  const getUsers = () => {
    getUserRmList(false)
      .then((response) => {
        let users = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
          };
          users.push(data);
        });
        setUsersOptions(users);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPlants = () => {
    PrService.getPlantsList({ organisation_id: userData?.organisation_id })
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: item.code + "(" + item?.description + ")",
          }));
          setPlantOptions(options);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getMaterialTypePlant = () => {
    HSNService.getMaterialTypePlantFullList()
      .then((response) => {
        let matData = response?.data?.dataList?.result?.map(
          (item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }
        );
        setMaterialTypeOptions(matData);
        // let data = response?.data?.dataList?.result?.plant?.map((res) => {
        //   return {
        //     value: res.id,
        //     label: res.description + "(" + res.code + ")",
        //   };
        // });
        // data.unshift({ value: "", label: "Select" });
        // setPlantList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onPushMatType = (newValue, name) => {
    handleChange(newValue, name);
    setMatTypeValue(newValue);
  };

  const onPushPlant = (newValue, name) => {
    handleChange(newValue, name);
    setPlantListValue(newValue);
  };

  const onPushUser = (newValue, name) => {
    handleChange(newValue, name);
    setUser(newValue);
  };

  const onSelectStatus = (newValue, name) => {
    console.log(newValue,name);
    handleChange(newValue, name);
    setStatus(newValue);
  };

  const handleChange = (event, name, regex, length) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "material_type_id":
        let mat_id = event?.map((res)=>{
          return res?.value
        })
        setAuthUserData((prev) => ({
          ...prev,
          material_type_list: mat_id,
        }));
        break;
      case "plant_id":
        let plant_id = event?.map((res)=>{
          return res?.value
        })
        setAuthUserData((prev) => ({
          ...prev,
          plant_list: plant_id,
        }));
        break;
      case "user_id":
        setAuthUserData((prev) => ({
          ...prev,
          user: event.value,
        }));
        break;
      case "status":
        setAuthUserData((prev) => ({
          ...prev,
          status: event,
        }));
        break;
      default:
        break;
    }
  };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...error };
    if (!authUserData.user) {
      isError = true;
      formerr.user_id = "*User is required";
      setError(formerr);
    }
    if (!authUserData.material_type_list) {
      isError = true;
      formerr.material_type_id = " *Material Type is required";
      setError(formerr);
    }
    if (!authUserData.plant_list) {
      isError = true;
      formerr.plant_id = " *Plant is required";
      setError(formerr);
    }
    return isError;
  };

  const isHSNUserAuthExist = () =>{
    HSNService.checkIsExist(authUserData).then((response)=>{
      if(response?.data?.dataList?.is_exist === true && editMode === false){
        toast.error("Record Already Exist for this User")
      }
      else{
        handleSaveData()
      }
    })
  }

  const handleSaveData = () => {
    if (handleValidate()) return false;
    if (authUserData.id == undefined) {
      saveHsnAuthUser(authUserData);
      //   setHsnData();
    } else {
      //   updateHsnDataList(powerUserData.id, powerUserData);
    }
    addnewclose();
  };

  const saveHsnAuthUser = (data) => {
    HSNService.saveHsn_AuthUser(data)
      .then((response) => {
        // getHsnListData(1, 10);
        getUserAuthorizeMaster(currentPage, pageSizeNo, sortData.sort_by, searchStr, true)
        toast.success("User Authorization added Successfully")
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getUserAuthorizeMaster(
      activePage,
      pageSizeNo,
      sortData.sort_by,
      searchStr,
      true,
    )
    setCurrentPage(activePage);
  };

  const handleSearch = (e) => {
    const search=e.target.value;
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
    getUserAuthorizeMaster(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      search,
      true,
    );
    }
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to proceed",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await HSNService.deleteHsnAuthUser(id)
              .then((response) => {
                if (response?.data?.code === 200) {
                  getUserAuthorizeMaster(currentPage, pageSizeNo, sortData.sort_by, searchStr, true)
                  toast.success(response?.data?.message);
                }
              })
              .catch((error) => {
                toast.error(error?.response?.data?.message ?? error.message);
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("bye"),
        },
      ],
    });
  };

  const handleEdit = (data) =>{
    setEditMode(true)
    let mat_id = data?.material_type_list?.map((res)=>{
      return {
        value:res?.id,
        label:res?.name
      }
    })
    let plant_id = data?.plant_list?.map((res)=>{
      return {
        value:res?.id,
        label:res?.description + "(" + res?.code + ")"
      }
    })
    let user_id = {value:data?.user_id, label:data?.fullname + "(" + data?.username + ")"}
    setPlantListValue(plant_id)
    setMatTypeValue(mat_id)
    setUser(user_id)

    let mat_id_list = data?.material_type_list?.map((res)=>{
      return res?.id
    })
    let plant_id_list = data?.plant_list?.map((res)=>{
      return res?.id
    })
    
    setAuthUserData((prev) => ({
      ...prev,
      material_type_list: mat_id_list,
      plant_list:plant_id_list,
      user:data?.user_id
    }));
    addnewopen()
  }

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <CustomMenu />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Authorize User</h4>
                  {/* <CustomBreadcrumb items={CustomBreadcrumbItems} /> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    {/* <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <button class="btn btn-primary-inner" onClick={addnewopen}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>User ID</th>
                    <th>User Name</th>
                    <th>Material Type</th>
                    <th>Plant</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {authUserList?.length > 0 ?
                  authUserList?.map((data)=>{return(
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12" onClick={()=>{handleEdit(data)}}>
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12" onClick={() => handleDelete(data?.id)}>
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="">{data?.username}</td>
                    <td>{data?.fullname}</td>
                    <td>
                      {data?.material_type_list?.map((res)=>{return(
                        res?.name + ','
                      )})}
                    </td>
                    <td>
                    {data?.plant_list?.map((res)=>{return(
                        res?.description + '(' + res?.code + ')' + ','
                      )})}
                    </td>
                    <td>
                      {data?.status === true ?
                      <span class="badge bad-status badge-success">
                        Active
                      </span>
                      :
                      <span class="badge bad-status badge-danger">
                        InActive
                      </span>
                      }
                    </td>
                  </tr>
                  )})
                  :
                  <tr>
                    <td colSpan={12}>No Records Found...</td>
                  </tr>
                }
                </tbody>
              </table>
            </div>

              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />

          </div>
        </div>
      </div>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Authorize User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      User Id<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={usersOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="user_id"
                      onChange={(newValue, action) => {
                        onPushUser(newValue, action?.name);
                        setError({ ...error, user_id: "" });
                      }}
                      value={user}
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Material Type<span class="text-danger">*</span>
                    </label>
                    <Select
                    isMulti
                    options={materialTypeOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="material_type_id"
                    onChange={(newValue, action) => {
                      onPushMatType(newValue, action?.name);
                      setError({ ...error, material_type_id: "" });
                    }}
                    value={matTypeValue}
                  />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Plant<span class="text-danger">*</span>
                    </label>
                    <Select
                    isMulti
                      options={plantOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="plant_id"
                      onChange={(newValue, action) => {
                        onPushPlant(newValue, action?.name);
                        setError({ ...error, plant_id: "" });
                      }}
                      value={plantListValue}
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label className="d-block mb-2">
                      Status<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Active
                      <input type="radio" name="status" 
                       onClick={() => {
                        onSelectStatus(true, "status");
                      }} 
                      checked={authUserData?.status===true}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input type="radio" name="status"
                       onClick={() => {
                        onSelectStatus(false, "status");
                      }} 
                      checked={authUserData?.status===false}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main" type="btn" onClick={()=>{isHSNUserAuthExist()}}>Submit</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AuthorizeUserMaster;
