/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import * as LeaveService from "../../service/leaveService";
import "react-toastify/dist/ReactToastify.css";
import AuditLog from "../audit/auditlog";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object({
  // name: Yup.string()
  //   .matches(/[^\s*].*[^\s*]/g, "*This field cannot contain only blank spaces")
  //   .matches(
  //     /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
  //     "Insert only normal character"
  //   )
  //   .required("*Leave Type is required"),
  no_of_days: Yup.string()
    .max(8, "Max 10 digits")
    .required("*Leave Day's required"),
  // frequency: Yup.string().required("*Select Leave Frequency").nullable(),
  // reasons: Yup.array()
  //     .min(1, "You can't leave this blank.")
  //     .required("You can't leave this blank.")
  //     .nullable(),
  gender: Yup.string().required("*Select Gender").nullable(),
  applicable_for: Yup.string().required("*Select Applicable For").nullable(),
  // club: Yup.string().required("*Select City").nullable(),
});
const leaveCodes = [
  { label: "CL", value: "CL", name: "Casual Leave" },
  { label: "COL", value: "COL", name: "Contingency Leave" },
  { label: "FPL", value: "FPL", name: "Family Planning leave" },
  { label: "LCP", value: "LCP", name: "Late Coming Present" },
  { label: "LWP", value: "LWP", name: "Leave without pay" },
  { label: "MTL", value: "MTL", name: "Maternity Leave" },
  { label: "PL", value: "PL", name: "Privilege Leave" },
  { label: "PTL", value: "PTL", name: "Paternity Leave" },
  { label: "OL", value: "OL", name: "Optional Holiday" },
  { label: "SL", value: "SL", name: "Sick Leave" },
  { label: "T", value: "T", name: "On Duty" },
  { label: "TL", value: "TL", name: "Transfer Leave" },
  { label: "WFH", value: "WFH", name: "Work From Home" },
];
const AddLeaveManage = (props) => {
  const nameForm = "Leave Manage Master";
  let condition = "true";
  const { edit, showForm, onClose, editLeaveManage, onAddCallBack, leaveList } =
    props;
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const dispatch = useDispatch();
  const [clubList, setClubList] = useState([]);
  const [filterClub, setFilterClub] = useState([]);
  const [clubData, setClubData] = useState([]);
  const [clubDataList, setClubDataList] = useState([]);
  const [validateFeature, setValidateFeature] = useState(false);
  const [newAddLeave, setNewAddLeave] = useState([{ reason: "" }]);
  const [isDeptExist, setIsDeptExist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [leaveCode, setLeaveCode] = useState(null);
  const [selectedLeaveName, setSelectedLeaveName] = useState("");
  const [leaveExist, setLeaveExist] = useState("");
  const [isSavLeave, setIsSavLeave] = useState(false);
  const [error, setError] = useState({
    leaveDays: false,
    code: false,
  });
  const [chkError, setChkError] = useState({
    orgError: false,
    countryError: false,
    stateError: false,
    cityError: false,
  });
  const [dropDownData, setDropDownData] = useState({
    select_organisation: { value: "", label: "" },
    select_country: { value: null, label: null },
    select_state: { value: null, label: null },
    select_city: { value: null, label: null },
  });
  const [leaveManageData, setLeaveManageData] = useState({
    name: "",
    frequency: "Y",
    club: [],
    gender: "",
    no_of_days: "",
    is_active: false,
    is_document_required: false,
    allow_half_day: false,
    reasons: [{ reason: "" }],
    applicable_for: "",
    from_saviour: false,
    allow_bulk: false,
  });

  function leaveDetail() {
    const arrayLeave = [];
    if (editLeaveManage) {
      for (let i = 0; i < editLeaveManage.reasons.length; i++) {
        arrayLeave.push({
          reason: editLeaveManage.reasons[i].reason,
        });
      }
      setNewAddLeave(arrayLeave);
    }
  }

  const getLeaveManageData = (paginate) => {
    let query = `sort_by=code&paginate=${false}`;
    LeaveService.getLeaveManageData(query)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result
          .filter((obj) => {
            if (obj?.is_active === true) {
              return true;
            }
          })
          .map((x) => {
            let data2 = { value: x.id, label: x.code };
            data.push(data2);
          });
        setClubList(data);
        // if (edit) {
        //   handleLeaveValidations(editLeaveManage?.code, data);
        // } else {
        setFilterClub(data);
        // }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const loadLeaveManageMasterList = (pageNo, paginate, searchStr, sort_by) => {
    LeaveService.getLeaveManageList(
      pageNo,
      pageSizeNo,
      sort_by,
      paginate,
      searchStr
    )
      .then((response) => {
        let data = response.data.dataList?.result;
        let users = [];
        data.map((x) => {
          let data2 = { value: x.id, label: x.name };
          users.push(data2);
        });
        setLeaveManageData(users);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  // console.log(clubDataList, 'final')
  const updateLeaveManage = (values) => {
    const ItemsData = newAddLeave?.map((items) => ({ reason: items.reason }));
    let datass = values;
    datass.name = selectedLeaveName;
    datass.club = clubDataList;
    datass.reasons = ItemsData;
    datass.leaveCode = leaveCode;
    LeaveService.updateLeaveManage(values.id, datass)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          toast.success("Leave Type Updated.");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);

        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const saveLeaveManage = (values) => {
    const ItemsData = newAddLeave?.map((items) => ({ reason: items.reason }));
    if (ItemsData[0].reason === "") {
      toast.error("Kindly provide atleast one reason.");
    } else if (!leaveExist) {
      if (condition === "true") {
        condition = "false";
        let datass = values;
        datass.club = clubDataList;
        datass.name = selectedLeaveName;
        datass.reasons = ItemsData;
        datass.leaveCode = leaveCode;
        LeaveService.saveLeaveManage(datass)
          .then((response) => {
            if (response.data.code == 200) {
              condition = "true";
              onAddCallBack(response);
              toast.success("Leave Type Created.");
            }
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error(error.response.data.message);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      }
    }
  };
  const chkIsActive = () => {
    let flag = false;
    if (dropDownData?.select_organisation.label === "") {
      setChkError((prev) => ({
        ...prev,
        orgError: true,
      }));
      flag = true;
    }
    // if (dropDownData?.select_country.label === "") {
    //   setChkError((prev) => ({
    //     ...prev,
    //     countryError: true,
    //   }));
    //   flag = true;
    // }
    // if (dropDownData?.select_state.label === "") {
    //   setChkError((prev) => ({
    //     ...prev,
    //     stateError: true,
    //   }));
    //   flag = true;
    // }
    // if (dropDownData?.select_city.label === "") {
    //   setChkError((prev) => ({
    //     ...prev,
    //     cityError: true,
    //   }));
    //   flag = true;
    // }
    return flag;
  };
  const pushLeaveManagers = (e) => {
    if (!edit) {
      e.map((data) => {
        setClubDataList([...clubDataList, data.value]);
      });
      setClubData(e);
    } else {
      console.log(e);
      let ids = [];
      e.map((data) => {
        ids.push(data?.value);
      });
      setClubDataList(ids);
      setClubData(e);
    }
  };

  useEffect(() => {
    getLeaveManageData(false);
  }, []);

  const updateValueData = (field, i, e) => {
    const copyValue = [...newAddLeave];
    copyValue[i][field] = e.target.value;
    setNewAddLeave(copyValue);
  };

  // const validateServiceFeature = () => {
  //   let errorExist = false;
  //   newAddLeave.map((item) => {
  //     if (item.reason.trim().length === 0) {
  //       errorExist = true;
  //     }
  //     return item;
  //   });
  //   return errorExist;
  // };

  const addMoreInputField = (ele) => {
    // if (validateServiceFeature()) {
    //   toast.error("All fields are necesssary without White Spaces in starting");
    //   setValidateFeature(true);
    // } else {

    if (newAddLeave.length < 20) {
      let copyValue = [...newAddLeave];
      ele = JSON.parse(JSON.stringify(ele));
      ele.reason = "";
      copyValue.push(ele);
      setNewAddLeave(copyValue);
    } else {
      toast.error("max");
    }
  };
  //     setValidateFeature(false);
  //   }
  // };

  const isBranchExists = (e, fieldName) => {
    let data = {};
    data[fieldName] = e.target.value;
    if (editLeaveManage?.name == e.target.value) {
      setIsDeptExist(false);
      return;
    } else {
      LeaveService.isBranchExists(data)
        .then((res) => {
          if (e.target.name == "name") {
            setIsDeptExist(res?.data?.dataList?.is_exist);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const removeLeaveRow = (idx) => {
    const newLeaveDetails = newAddLeave.filter((item, i) => i !== idx);
    setNewAddLeave(newLeaveDetails);
  };

  // useEffect(() => {
  //   loadLeaveManageMasterList(1, true, "", "-id");
  // }, []);

  useEffect(() => {
    if (editLeaveManage) {
      let datas = editLeaveManage;
      let clubList = [];
      let clubLeaveId = [];
      datas?.club?.map((x) => {
        let data1 = { value: x.club_with, label: x.club_with_name };
        clubLeaveId.push(x.club_with);
        clubList.push(data1);
      });
      setClubDataList(clubLeaveId);
      if (clubDataList !== null && clubDataList.length > 0) {
        let data = clubDataList;
        data.concat(clubLeaveId);
        setClubDataList(data);
      }
      setClubData(clubList);
      setLeaveManageData(editLeaveManage);
      Object.keys(editLeaveManage).forEach((item) => {
        if (item === "code") {
          setLeaveCode({
            label: editLeaveManage[item],
            value: editLeaveManage[item],
          });
        }
        if (item === "name") {
          setSelectedLeaveName(editLeaveManage[item]);
        }
        leaveManageData[item] = editLeaveManage[item];
      });
      leaveDetail();
    }
  }, [editLeaveManage]);

  const checkLeaveExist = (e) => {
    setFilterClub(clubList);
    let check = leaveList?.filter((obj) => {
      if (obj?.code === e.value) {
        return true;
      }
    });
    if (check?.length > 0) {
      setLeaveExist(true);
    } else {
      setLeaveExist(false);
    }
  };

  const handleLeaveValidations = (e, data) => {
    let club = [];
    let toFilter = [];
    switch (e) {
      case "COL":
        toFilter = data !== undefined ? data : filterClub;
        club = toFilter.filter((obj) => {
          if (obj?.label === "TL") {
            return true;
          }
        });
        setFilterClub(club);
        break;
      case "TL":
        toFilter = data !== undefined ? data : filterClub;
        club = toFilter.filter((obj) => {
          if (obj?.label === "COL") {
            return true;
          }
        });
        setFilterClub(club);
        break;
      case "PL":
        toFilter = data !== undefined ? data : filterClub;
        club = toFilter.filter((obj) => {
          if (obj?.label !== "CL" && obj?.label !== "PL") {
            return true;
          }
        });
        setFilterClub(club);
        break;
      case "CL":
        toFilter = data !== undefined ? data : filterClub;
        club = toFilter.filter((obj) => {
          if (obj?.label !== "PL" && obj?.label !== "CL") {
            return true;
          }
        });
        setFilterClub(club);
        break;
      case "PTL":
        toFilter = data !== undefined ? data : filterClub;
        club = toFilter.filter((obj) => {
          if (obj?.label === "PL") {
            return true;
          }
        });
        setFilterClub(club);
        break;
      case "MTL":
        toFilter = data !== undefined ? data : filterClub;
        club = toFilter.filter((obj) => {
          if (obj?.label === "PL") {
            return true;
          }
        });
        setFilterClub(club);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Formik
        initialValues={leaveManageData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (!isDeptExist && !leaveExist && !error?.leaveDays) {
            if (values.id) {
              updateLeaveManage(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            } else {
              saveLeaveManage(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            }
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <div>
            <Modal
              show={showForm}
              onHide={onClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              className="modaldefaultclose maxwidth90 modaldefaultclosecenter"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {edit === true ? "Edit " : "Add "}
                  Leave Type
                </Modal.Title>
              </Modal.Header>
              <form onSubmit={handleSubmit}>
                <>
                  <Modal.Body>
                    <div class="row justify-content-center">
                      <ToastContainer autoClose={1000} />
                      <div class="col-md-11">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Leave Code<span class="text-danger">*</span>
                              </label>
                              <Select
                                name="code"
                                options={leaveCodes}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => {
                                  setLeaveCode(e);
                                  setSelectedLeaveName(e.name);
                                  checkLeaveExist(e);
                                  // handleLeaveValidations(e?.label);
                                  setError((prev) => ({
                                    ...prev,
                                    code: false,
                                  }));
                                }}
                                onBlur={(e) => {
                                  if (
                                    e.target.value === "" &&
                                    leaveCode === null
                                  ) {
                                    setError((prev) => ({
                                      ...prev,
                                      code: true,
                                    }));
                                  }
                                }}
                                value={leaveCode}
                              />
                              {leaveExist && (
                                <div className="small text-danger">
                                  Leave Already Created.
                                </div>
                              )}
                              {error?.code && (
                                <div className="small text-danger">
                                  Select Leave Code.
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Leave Type</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Leave Type"
                                maxLength={60}
                                name="name"
                                disabled
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                value={selectedLeaveName}
                              />

                              {touched.name && errors.name ? (
                                <div className="small text-danger">
                                  {errors.name}
                                </div>
                              ) : isDeptExist === true ? (
                                <div className="small text-danger">
                                  Leave Type Already Exist
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Club Leave with</label>
                              <Select
                                disabled
                                name="club"
                                isMulti
                                options={filterClub}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => {
                                  pushLeaveManagers(e);
                                }}
                                value={clubData}
                              />

                              <div className="small text-danger">
                                {errors.club}
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Leave Frequency
                                {/* <span class="text-danger">*</span> */}
                              </label>
                              <select
                                class="form-control newbgselect main-pointer"
                                type="number"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                name="frequency"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  setLeaveManageData({
                                    ...leaveManageData,
                                    frequency: e.target.value,
                                  });
                                }}
                                value={values.frequency}
                              >
                                <option value="Y" label="Yearly" selected>
                                  Yearly
                                </option>
                              </select>
                              {touched.frequency && errors.frequency ? (
                                <div className="small text-danger">
                                  {errors.frequency}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Gender<span class="text-danger">*</span>
                              </label>
                              <select
                                class="form-control newbgselect main-pointer"
                                type="number"
                                name="gender"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  setLeaveManageData({
                                    ...leaveManageData,
                                    gender: e.target.value,
                                  });
                                }}
                                value={values.gender}
                              >
                                <option value="" disabled>
                                  Select
                                </option>
                                <option value="A">All</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                              </select>
                              {touched.gender && errors.gender ? (
                                <div className="small text-danger">
                                  {errors.gender}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                User Type<span class="text-danger">*</span>
                              </label>
                              <select
                                class="form-control newbgselect main-pointer"
                                type="number"
                                name="applicable_for"
                                style={{
                                  fontWeight: "lighter",
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  setLeaveManageData({
                                    ...leaveManageData,
                                    applicable_for: e.target.value,
                                  });
                                }}
                                value={values.applicable_for}
                              >
                                <option value="" label="Select" disabled>
                                  Select
                                </option>
                                <option value="A" label="ALL">
                                  ALL
                                </option>
                                <option value="SF" label="SF">
                                  SF
                                </option>
                                <option value="NSF" label="Non-SF">
                                  Non-SF
                                </option>
                              </select>
                              {touched.applicable_for &&
                              errors.applicable_for ? (
                                <div className="small text-danger">
                                  {errors.applicable_for}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Leave Days<span class="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Enter Leave Days"
                                maxLength={60}
                                name="no_of_days"
                                disabled={isSavLeave}
                                onChange={(e) => {
                                  if (
                                    e.target.value > 0 &&
                                    e.target.value <= 365
                                  ) {
                                    handleChange(e);
                                    setError((prev) => ({
                                      ...prev,
                                      leaveDays: false,
                                    }));
                                  } else {
                                    handleChange(e);
                                    setError((prev) => ({
                                      ...prev,
                                      leaveDays: true,
                                    }));
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.no_of_days}
                              />
                              {touched.no_of_days && errors.no_of_days ? (
                                <div className="small text-danger">
                                  {errors.no_of_days}
                                </div>
                              ) : error?.leaveDays ? (
                                <div className="small text-danger">
                                  Leave Days should be in between (1 - 365)
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="col-md-12">
                            <table class="table table-striped table-bordered tablecured leavetableapply">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>
                                    Reasons<span class="text-danger">*</span>
                                  </th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {newAddLeave &&
                                  newAddLeave?.length &&
                                  newAddLeave?.map((items, i) => (
                                    <tr class="innergroup">
                                      <td>{i + 1}</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Enter Reason"
                                          name="reason"
                                          onChange={(e) =>
                                            updateValueData("reason", i, e)
                                          }
                                          value={items?.reason}
                                        />
                                      </td>
                                      <td>
                                        {newAddLeave.length === i + 1 ? (
                                          <>
                                            {newAddLeave?.length > 1 && (
                                              <a
                                                class="btn btn-blanktd text-danger"
                                                onClick={() =>
                                                  removeLeaveRow(i)
                                                }
                                              >
                                                <i class="far fa-trash-alt"></i>
                                              </a>
                                            )}
                                            <a
                                              class="btn btn-blanktd text-theme"
                                              onClick={() => {
                                                addMoreInputField(items);
                                              }}
                                            >
                                              <i class="far fa-plus-square"></i>
                                            </a>
                                          </>
                                        ) : (
                                          <a
                                            class="btn btn-blanktd text-danger"
                                            onClick={() => removeLeaveRow(i)}
                                          >
                                            <i class="far fa-trash-alt"></i>
                                          </a>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {/* {touched.reasons && errors.reasons ? (
                              <div className="small text-danger">
                                {errors.reasons}
                              </div>
                            ) : null} */}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-3">
                            <div className="form-group innergroup">
                              <label className="d-block mb-2">
                                Allow For Bulk Upload
                              </label>
                              <label
                                className="logCheck d-inline-block mr-4"
                                htmlFor="yesBulk"
                              >
                                Yes
                                <input
                                  type="radio"
                                  name="allow_bulk"
                                  id="yesBulk"
                                  value={values.allow_bulk}
                                  onChange={() => {
                                    setFieldValue("allow_bulk", true);
                                  }}
                                  checked={values.allow_bulk === true}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="logCheck d-inline-block"
                                htmlFor="NoBulk"
                              >
                                No
                                <input
                                  type="radio"
                                  name="allow_bulk"
                                  id="NoBulk"
                                  value={values.allow_bulk}
                                  onChange={(e) => {
                                    setFieldValue("allow_bulk", false);
                                  }}
                                  checked={values.allow_bulk === false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div className="form-group innergroup">
                              <label className="d-block mb-2">
                                Allow Half Day
                              </label>
                              <label
                                className="logCheck d-inline-block mr-4"
                                htmlFor="yes"
                              >
                                Yes
                                <input
                                  type="radio"
                                  name="allow_half_day"
                                  id="yes"
                                  value={values.allow_half_day}
                                  onChange={() => {
                                    setFieldValue("allow_half_day", true);
                                  }}
                                  checked={values.allow_half_day === true}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="logCheck d-inline-block"
                                htmlFor="No"
                              >
                                No
                                <input
                                  type="radio"
                                  name="allow_half_day"
                                  id="No"
                                  value={values.allow_half_day}
                                  onChange={(e) => {
                                    setFieldValue("allow_half_day", false);
                                  }}
                                  checked={values.allow_half_day === false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div className="form-group innergroup">
                              <label className="d-block mb-2">Attachment</label>
                              <label
                                className="logCheck d-inline-block mr-4"
                                htmlFor="yesdoc"
                              >
                                Yes
                                <input
                                  type="radio"
                                  name="is_document_required"
                                  id="yesdoc"
                                  value={values.is_document_required}
                                  onChange={() => {
                                    setFieldValue("is_document_required", true);
                                  }}
                                  checked={values.is_document_required === true}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="logCheck d-inline-block"
                                htmlFor="Nodoc"
                              >
                                No
                                <input
                                  type="radio"
                                  name="is_document_required"
                                  id="Nodoc"
                                  value={values.is_active}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "is_document_required",
                                      false
                                    );
                                  }}
                                  checked={
                                    values.is_document_required === false
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div className="form-group innergroup">
                              <label className="d-block mb-2">Status</label>
                              <label
                                className="logCheck d-inline-block mr-1"
                                htmlFor="active"
                              >
                                Active
                                <input
                                  type="radio"
                                  name="is_active"
                                  id="active"
                                  value={values.is_active}
                                  onChange={() => {
                                    setFieldValue("is_active", true);
                                  }}
                                  checked={values.is_active === true}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="logCheck d-inline-block"
                                htmlFor="Inactive"
                              >
                                Inactive
                                <input
                                  type="radio"
                                  name="is_active"
                                  id="Inactive"
                                  value={values.is_active}
                                  onChange={(e) => {
                                    setFieldValue("is_active", false);
                                  }}
                                  checked={values.is_active === false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        variant="primary"
                        as="span"
                        role="status"
                        aria-hidden="true"
                      ></Spinner>
                    ) : (
                      <>
                        <button
                          class="btn btn-outline-danger"
                          type="button"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                        <button
                          class="btn btn-primary-inner bpi-main"
                          type="submit"
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </Modal.Footer>
                </>
              </form>
            </Modal>
          </div>
        )}
      </Formik>
    </>
  );
};
export default AddLeaveManage;
