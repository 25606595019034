import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Tabs, Tab, Dropdown } from "react-bootstrap";
import Select from "react-select";
import SourceListForm from "./sourceListForm";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tippy";
import {
  getMateriallist,
  getSourcelist,
  getSourcelistAll,
  getSourcelistExport,
  getSourcelistExportAll,
} from "../../service/source-list";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getVendorCode } from "../../service/userService";
import { getPlantList } from "../../service/jv_service";
import * as searchPoService from "../../service/searchpo";

function SourceList() {

  const pathname = window.location.pathname
  const userData = useSelector(selectUserData)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [vendorList, setvendorList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [filterApplied, setFilterApplied] = useState(false);
  const [approverStatus, setApproverStatus] = useState("D");
  const [search, setSearch] = useState("");
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const [filterdata, setfilterData] = useState({
    category: { value: "" },
    plant_id: { value: 0 },
    vendor_id: { value: "" },
    material_id: { value: "" },
    created_at: "",
    created_to: "",
    status: "",
  });
  const resetFilterData = () =>
    setfilterData({
      plant_id: { value: 0 },
      category: { value: "" },
      created_at: "",
      created_to: "",
      status: "",
      vendor_id: { value: "" },
      material_id: { value: "" },
    });
  const [activeTab, setActiveTabData] = useState([]);
  const [pendingCount, setPendingCount] = useState();

  const [activeTabvalue, setActiveTabvalue] = useState("");
  const options = [
    { value: "Capex", label: "Capex" },
    { value: "Engineering", label: "Engineering" },
    { value: "RM/PM", label: "RM/PM" },
  ];
  const [plantlist, setPlantList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  const getlist = (
    search,
    pageno,
    page_size,
    sort_by,
    status,
    created_at,
    created_to,
    category,
    sourcelist_id,
    material_id,
    plant_id,
    vendor_id,
    self_approval_data,
    approverStatus,
  ) => {
    dispatch(setDisplayLoader("Display"));
    getSourcelistAll(
      search,
      pageno,
      page_size,
      sort_by,
      status,
      created_at,
      created_to,
      category,
      sourcelist_id,
      material_id,
      plant_id,
      vendor_id,
      self_approval_data,
      approverStatus,
    )
      .then((response) => {
        setActiveTabData(response?.data?.dataList?.result);
        if (status === "P") {
          setPendingCount(
            response?.data?.dataList?.paginated_data?.totalRecords
          );
        }
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        materiallist(0);
        vendorCodeList(0);
        plantList();

        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getlistExport = (
    search,
    pageno,
    page_size,
    sort_by,
    status,
    created_at,
    created_to,
    category,
    sourcelist_id,
    material_id,
    plant_id,
    vendor_id,
    approverStatus
  ) => {
    getSourcelistExportAll(
      search,
      pageno,
      page_size,
      sort_by,
      status,
      created_at,
      created_to,
      category,
      sourcelist_id,
      material_id,
      plant_id,
      vendor_id,
      approverStatus
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SourceList.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const plantList = () => {
    getPlantList()
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.code + "(" + x?.description + ")",
          };

          plant.push(data);
        });
        setPlantList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const vendorCodeList = (id) => {
    getVendorCode(id)
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.code + "(" + x?.name + ")",
            name: x?.name,
          };
          plant.push(data);
        });
        setvendorList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const materiallist = (plantid, search) => {
    let data = { plant_id: plantid, search: search };
    getMateriallist(data)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.code + "(" + x?.description + ")",
            name: x?.description,
          };
          plant.push(data);
        });
        setMaterialList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "created_at":
        setfilterData((prev) => ({
          ...prev,
          created_at: event.target.value,
        }));
        break;
      case "created_to":
        setfilterData((prev) => ({
          ...prev,
          created_to: event.target.value,
        }));
        break;
      case "select-category":
        setfilterData((prev) => ({
          ...prev,
          category: event,
        }));
        break;

      case "material_id":
        setfilterData((prev) => ({
          ...prev,
          material_id: event,
        }));
        break;
      case "plant_id":
        setfilterData((prev) => ({
          ...prev,
          plant_id: event,
        }));
        break;
      case "vendor_id":
        setfilterData((prev) => ({
          ...prev,
          vendor_id: event,
        }));
        break;
      case "status":
        setfilterData((prev) => ({
          ...prev,
          status: event.target.value,
        }));
        break;
    }
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    if (activeTabvalue !== "DD" && activeTabvalue !== "AA") {
      getlist(
        search,
        activePage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        "",
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value,
        pathname === "/source-list-all" ? false : true
      );
    }
    if (activeTabvalue == "DD") {
      setApproverStatus(activeTabvalue);
      getlist(
        search,
        activePage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        undefined,
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value,
        pathname === "/source-list-all" ? false : true,
        "D"
      );
    }
    if (activeTabvalue == "AA") {
      setApproverStatus(activeTabvalue);
      getlist(
        search,
        activePage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        undefined,
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value,
        pathname === "/source-list-all" ? false : true,
        "A"
      );
    }
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      if (activeTabvalue !== "DD" && activeTabvalue !== "AA") {
        getlist(
          e.target.value.toLowerCase(),
          currentPage,
          pageSizeNo,
          "-id",
          filterdata?.status,
          filterdata?.created_at,
          filterdata?.created_to,
          filterdata?.category?.value,
          "",
          filterdata?.material_id?.value,
          filterdata?.plant_id?.value,
          filterdata?.vendor_id?.value
        );
      }
      if (activeTabvalue == "DD") {
        setApproverStatus(activeTabvalue);
        getlist(
          e.target.value.toLowerCase(),
          currentPage,
          pageSizeNo,
          "-id",
          filterdata?.status,
          filterdata?.created_at,
          filterdata?.created_to,
          filterdata?.category?.value,
          undefined,
          filterdata?.material_id?.value,
          filterdata?.plant_id?.value,
          filterdata?.vendor_id?.value,
          "D"
        );
      }
      if (activeTabvalue == "AA") {
        setApproverStatus(activeTabvalue);
        getlist(
          e.target.value.toLowerCase(),
          currentPage,
          pageSizeNo,
          "-id",
          filterdata?.status,
          filterdata?.created_at,
          filterdata?.created_to,
          filterdata?.category?.value,
          undefined,
          filterdata?.material_id?.value,
          filterdata?.plant_id?.value,
          filterdata?.vendor_id?.value,
          "A"
        );
      }
      setSearch(e.target.value);
    }
  };
  useEffect(() => {
    getSapSchedule()
    if (activeTabvalue == "P") {
      getlist(
        "",
        currentPage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        "",
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value,
        pathname === "/source-list-all" ? false : true
      );
    }
    if (activeTabvalue == "A") {
      getlist(
        "",
        currentPage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        "",
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value,
        pathname === "/source-list-all" ? false : true
      );
    }
    if (activeTabvalue == "D") {
      getlist(
        "",
        currentPage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        "",
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value,
        pathname === "/source-list-all" ? false : true
      );
    }
    if (activeTabvalue == "") {
      getlist(
        "",
        currentPage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        "",
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value,
        pathname === "/source-list-all" ? false : true
      );
    }
  }, [pageSizeNo, activeTabvalue]);
  // useEffect(() => {
  //   if (activeTabvalue == "P") {
  //     getlist(
  //       "",
  //       currentPage,
  //       pageSizeNo,
  //       "-id",
  //       filterdata?.status,
  //       filterdata?.created_at,
  //       filterdata?.created_to,
  //       filterdata?.category?.value,
  //       "",
  //       filterdata?.material_id?.value,
  //       filterdata?.plant_id?.value,
  //       filterdata?.vendor_id?.value
  //     );
  //   }
  //   if (activeTabvalue == "") {
  //     getlist(
  //       "",
  //       currentPage,
  //       pageSizeNo,
  //       "-id",
  //       filterdata?.status,
  //       filterdata?.created_at,
  //       filterdata?.created_to,
  //       filterdata?.category?.value,
  //       "",
  //       filterdata?.material_id?.value,
  //       filterdata?.plant_id?.value,
  //       filterdata?.vendor_id?.value
  //     );
  //   }
  //   if (activeTabvalue == "A") {
  //     getlist(
  //       "",
  //       currentPage,
  //       pageSizeNo,
  //       "-id",
  //       filterdata?.status,
  //       filterdata?.created_at,
  //       filterdata?.created_to,
  //       filterdata?.category?.value,
  //       "",
  //       filterdata?.material_id?.value,
  //       filterdata?.plant_id?.value,
  //       filterdata?.vendor_id?.value
  //     );
  //   }
  //   if (activeTabvalue == "D") {
  //     getlist(
  //       "",
  //       currentPage,
  //       pageSizeNo,
  //       "-id",
  //       filterdata?.status,
  //       filterdata?.created_at,
  //       filterdata?.created_to,
  //       filterdata?.category?.value,
  //       "",
  //       filterdata?.material_id?.value,
  //       filterdata?.plant_id?.value,
  //       filterdata?.vendor_id?.value
  //     );
  //   }
  // }, [activeTabvalue]);
  const mainDataFilter = () => {
    setFilterApplied(true);
    if (activeTabvalue !== "DD" && activeTabvalue !== "AA") {
      getlist(
        "",
        currentPage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        "",
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value
      );
    }

    filterclose();
  };
  const exports = () => {
    if (activeTabvalue !== "DD" && activeTabvalue !== "AA") {
      getlistExport(
        "",
        currentPage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        "",
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value
      );
    }
    if (activeTabvalue == "DD") {
      setApproverStatus(activeTabvalue);
      getlistExport(
        "",
        currentPage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        undefined,
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value,
        "D"
      );
    }
    if (activeTabvalue == "AA") {
      setApproverStatus(activeTabvalue);
      getlistExport(
        "",
        currentPage,
        pageSizeNo,
        "-id",
        filterdata?.status,
        filterdata?.created_at,
        filterdata?.created_to,
        filterdata?.category?.value,
        undefined,
        filterdata?.material_id?.value,
        filterdata?.plant_id?.value,
        filterdata?.vendor_id?.value,
        "A"
      );
    }
  };
  const resetFilter = () => {
    setFilterApplied(false);
    filterclose();
    resetFilterData();
    getlist(
      "",
      currentPage,
      pageSizeNo,
      "-id",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };
  const getmateriallist = (e) => {
    let search = e;
    if (search != "") {
      materiallist(0, e);
    }
  };

  const getSapSchedule = () => {
    searchPoService.get_sap_schedule('sync_post_source_list_data').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link to="/source-list">Source List</Link>
            </li>
            <li>
              <Link to="/source-approver">Assign Approver</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Source List</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Source List</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            // disabled={cityList.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() => exports()}
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Req. ID</th>
                      <th>Plant</th>
                      <th>Pur. Org</th>
                      <th>Vendor</th>
                      <th>Material</th>
                      <th>Valid from</th>
                      <th>File</th>
                      <th>Category</th>
                      <th>Approver</th>
                      <th>Sap status</th>
                      <th>Requested By</th>
                      <th>SAP Log</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeTab.map((x) => (
                      <tr>
                        <td class="text-theme fw-bold">
                          <Link to={"/approve-source"} state={{ from: x?.id }}>
                            {x?.id}
                          </Link>
                        </td>
                        <td>{x?.plant_code}</td>
                        <td>{x?.purchase_org_code}</td>
                        <td>
                          <Tooltip
                            title={`${x?.vendor_code}- ${x?.vendor_code_name}`}
                            position="bottom"
                          >
                            {x?.vendor_code} - {x?.vendor_code_name}
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip
                            title={`${x?.material_code}- ${x?.material_code_name}`}
                            position="bottom"
                          >
                            {x?.material_code} - {x?.material_code_name}
                          </Tooltip>
                        </td>
                        <td>{moment(x?.valid_from).format("Do MMM YYYY")}</td>
                        <td>
                          {x?.attachment.map((a) => (
                            <a
                              target="_blank"
                              href={a?.attachment}
                              rel="noreferrer"
                            >
                              <i class="fas fa-paperclip"></i>
                            </a>
                          ))}
                        </td>
                        <td>{x?.category}</td>
                        <td>{x?.currentApprover[0]?.approver_name}</td>
                        <td>
                          {x?.sap_status === true
                            ? "Synced"
                            : x?.status === "D"
                              ? ""
                              : "Pending for synced"}
                        </td>
                        <td>{x?.created_by}</td>
                        <td>
                          <Tooltip title={x?.sap_error} position="bottom">
                            {x?.sap_error?.slice(0, 12)}...
                          </Tooltip>
                        </td>
                        <td>
                          <td>
                            {x?.status == "A" ? (
                              <span class="badge bad-status badge-success">
                                Approved{" "}
                              </span>
                            ) : x?.status == null || x?.status === "P" ? (
                              <span class="badge bad-status badge-warning">
                                Pending{" "}
                              </span>
                            ) : x?.status == "D" ? (
                              <span class="badge bad-status badge-danger">
                                Declined{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Plant<span class="text-danger">*</span>
            </label>
            <Select
              options={plantlist}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Press enter to search"
              name="plant_id"
              value={filterdata?.plant_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Material Code<span class="text-danger">*</span>
            </label>
            <Select
              options={materialList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Press enter to search"
              name="material_id"
              value={filterdata?.material_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
              id="materialList"
              onInputChange={(e) => {
                getmateriallist(e);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Vendor Code<span class="text-danger">*</span>
            </label>
            <Select
              options={vendorList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Press enter to search"
              name="vendor_id"
              value={filterdata?.vendor_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />{" "}
          </div>
          <div class="form-group innergroup">
            <label>Select Category</label>
            <Select
              options={options}
              name="select-category"
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.category}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Created From</label>
            <input
              onChange={handleChange}
              name="created_at"
              value={filterdata?.created_at}
              type="date"
              class="form-control"
            />
          </div>
          <div class="form-group innergroup">
            <label>Created To</label>
            <input
              onChange={handleChange}
              name="created_to"
              value={filterdata?.created_to}
              type="date"
              class="form-control"
            />
          </div>
          <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              class="form-control newbgselect"
              name="status"
              onChange={handleChange}
              value={filterdata?.status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="P">Pending</option>
              <option value="A">Approved</option>
              <option value="D">Rejected</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Clear
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {addnewshow && (
        <SourceListForm
          show={addnewshow}
          getList={() =>
            getlist(
              "",
              currentPage,
              pageSizeNo,
              "-id",
              "P",
              filterdata?.created_at,
              filterdata?.created_to,
              filterdata?.category?.value,
              "",
              filterdata?.material_id?.value,
              filterdata?.plant_id?.value,
              filterdata?.vendor_id?.value
            )
          }
          close={addnewclose}
          open={addnewopen}
        ></SourceListForm>
      )}
    </>
  );
}

export default SourceList;
