import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Select, { useStateManager } from "react-select";
import * as moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import * as VehicleLogApi from "../../service/vehiclelog";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import { confirmAlert } from "react-confirm-alert";
import * as TravelListingAPI from "../../service/travelService";

const VehicleLog = (props) => {
  const { setCheckActiveTab, vehicleAssignValues } = props;
  const { state } = useLocation();
  let doubleClick = "false";
  let sumDriverWages = 0;
  const userData = useSelector(selectUserData);
  const [mode, setMode] = useState([]);
  const [logData, setLogData] = useState([]);
  const [checkKms, setCheckKms] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [modeRate, setModeRate] = useState();
  const [wagesValidate, setWagesValidate] = useState(false);
  const [indexValue, setIndexValue] = useState();
  const [driverWagesValid, setDriverWagesValid] = useState(false);
  const [vehicleLogMonthlyData, setVehicleLogMonthlyData] = useState();
  const [vehicleDailyWagesData, setVehicleDailyWagesData] = useState();
  const [driverExists, setDriverExists] = useState();
  const [submitException, setSubmitException] = useState(true);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [Errorattachment, setErrorattachment] = useState(false);
  const [totalValueSum, setTotalValueSum] = useState();
  const [flag, setflag] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [file, setfiles] = useState([]);
  const [kmsNotNull, setKmsNotNull] = useState(false);
  const [popUpShow, setPopUpShow] = useState(false);
  const [deleteattachment, setDeleteAttachment] = useState([]);
  const [validateFuelAmount, setValidateFuelAmount] = useState(false);
  const [fuelQuantValid, setFuelQuantValid] = useState(false);
  const [washAmountValid, setWashAmountValid] = useState(false);
  const [vehicleTypeSelect, setVehicleTypeSelect] = useState(false);
  const [washingCurrAmount, setWashingCurrAmount] = useState();
  const [vehValue, setVehValue] = useState(false);
  const [vehicleTotalError, setVehicleTotalError] = useState(false);
  const [vehicleTravelExists, setVehicleTravelExists] = useState();
  const [vehLogData, setVehLogData] = useState([
    {
      travel_date: "",
      start_kms: 0,
      close_kms: 0,
      total_kms: 0,
      mode_id: 0,
      mode_name: "",
      fuel_quantity: 0,
      fuel_amount: 0,
      driver_daily_wages: 0,
      driver_da_type: "",
      driver_da_value: 0,
      other_expenses: 0,
      travel_with: "",
      partner_name: "",
      washing_amount: 0,
      total_amount: 0,
      is_exception: false,
      is_personal_car_exception: false,
    },
  ]);

  const getModeList = (is_vehicle) => {
    VehicleLogApi.getModeList(is_vehicle)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.mode,
          };
          data?.push(data1);
          if (
            vehicleAssignValues?.is_free != false ||
            vehicleAssignValues?.is_free == undefined
          ) {
            let dataValue = data?.filter((ele) => {
              if (ele?.label != "Car") {
                return true;
              }
            });
            setMode(dataValue);
          } else {
            setMode(data);
          }
        });
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getVehicleLog = (travelId, user_id) => {
    VehicleLogApi.getVehicleLog(travelId, user_id)
      .then((response) => {
        setLogData(response?.data?.dataList?.result);
        setAttachment(response?.data?.dataList?.attachment);
        setUploadDataIm([]);
        setfiles([]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onProfileChange = async (e, flag) => {
    let file = e.target.files;
    setfiles(file);
    let files = [];
    let myAllPic = [];
    let tmp_filesToUpload = [...filesToUpload];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      tmp_filesToUpload.push(fileData);

      // await TravelListingAPI.uploadFile(fileData).then((response) => {
      //   let name = { name: data.name };
      //   filename.push(name);
      //   files.push(response.data);
      // });
    }

    setFilesToUpload(tmp_filesToUpload);
    setflag(true);
    setErrorattachment(false);
    setAttachment([]);
    setUploadDataIm(files);
  };

  const getVehicleModeRate = (travelId, org_id, index, from, to) => {
    if (travelId != "") {
      VehicleLogApi.getVehicleModeRate(travelId, org_id)
        .then((response) => {
          setModeRate(response?.data?.dataList?.result[0]);
          let rate = response?.data?.dataList?.result[0]?.rate;
          CalculateTotalKm(from, to, index, rate);
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const getVehicleLogMonthly = (user_id, travel_id) => {
    VehicleLogApi.getVehicleLogMonthly(user_id, travel_id)
      .then((response) => {
        setVehicleLogMonthlyData(response?.data?.dataList?.current_limit);
        setWashingCurrAmount(response?.data?.dataList?.washing_amount);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getVehicleDailyWages = (user_id) => {
    VehicleLogApi.getVehicleDailyWages(user_id)
      .then((response) => {
        setVehicleDailyWagesData(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getUserDriverExists = (user_id) => {
    VehicleLogApi.getUserDriverExists(user_id)
      .then((response) => {
        setDriverExists(response?.data?.dataList?.is_exist);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getvehicleExists = (data) => {
    let obj = { travel_date: "", travel_id: "" };
    obj.travel_date = moment(data?.travel_date).format("YYYY-MM-DD");
    obj.travel_id = data?.travel_id;
    VehicleLogApi.getvehicleExists(obj)
      .then((response) => {
        setVehicleTravelExists(response?.data?.dataList?.is_exist);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleAlert = () => {
    confirmAlert({
      title: "Info",
      message: `Exception:One Time exception is triggered for claiming driver Wages`,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            handleValidateException();
          },
        },
      ],
    });
  };

  const getDa = (travel_id) => {
    TravelListingAPI.getDaDataList(travel_id)
      .then((response) => {
        // window.location.reload();
        // setAttachment([...imageLocalPreviewList, ...response?.data?.dataList?.attachment_path]);
        // let temp=[...imageLocalPreviewList]
        // temp.push(response?.data?.dataList?.attachment_path)
        // setAttachment(temp)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveVehicleLog = async (data) => {
    if (doubleClick === "false") {
      doubleClick = "true";

      let files = [];
      for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
        await TravelListingAPI.uploadFile(filesToUpload[afilee])
          .then((response) => {
            // let name = { name: data.name };
            // filename.push(name);
            files.push(response.data);
          })
          .catch((error) => {
            // dispatch(setDisplayLoader("Hide"));

            toast.error(`${error} `, {
              autoClose: 2000,
            });
            return;
          });
      }
      const updatedItems = data?.filter((item) => item.disable == undefined);
      let payload = {
        travel_id: state?.id,
        attachment_path: files,
        attachment_delete: deleteattachment,
        vehicle_data: updatedItems?.length > 0 ? updatedItems : vehLogData,
      };
      VehicleLogApi.saveVehicleLog(payload)
        .then((response) => {
          if (response?.data.code == 200) {
            toast.success("Vehicle Log Added Successfully");
            setCheckActiveTab();
            doubleClick = "false";
            setVehValue(true);
            setSubmitException(false);
            getDa(state?.id);
            setFilesToUpload([]);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const updateVehicleLog = async (data) => {
    if (doubleClick === "false") {
      doubleClick = "true";
      const modifiedData = data?.map((data, i) => ({
        appr_driver_da_value: data?.appr_driver_da_value,
        appr_driver_daily_wages: data?.appr_driver_daily_wages,
        appr_fuel_amount: data?.appr_fuel_amount,
        appr_other_expenses: data?.appr_other_expenses,
        appr_washing_amount: data?.appr_washing_amount,
        approved_amount: data?.approved_amount,

        close_kms: data?.close_kms == "" ? 0 : data?.close_kms,
        driver_da_type: data?.driver_da_type,
        driver_da_value:
          data?.driver_da_value == "" ? 0 : data?.driver_da_value,
        driver_daily_wages:
          data?.driver_daily_wages == "" ? 0 : data?.driver_daily_wages,
        fuel_amount: data?.fuel_amount == "" ? 0 : data?.fuel_amount,
        fuel_quantity: data?.fuel_quantity == "" ? 0 : data?.fuel_quantity,
        id: data?.id,
        is_exception: data?.is_exception,
        is_personal_car_exception: data?.is_personal_car_exception,
        mode_id:
          (data?.mode_id == "" || data?.mode_id == 0 || data?.mode_id == null) ? null : data?.mode_id,
        modee_name: data?.modee_name,
        other_expenses: data?.other_expenses == "" ? 0 : data?.other_expenses,
        partner_name: data?.partner_name,
        start_kms: data?.start_kms == "" ? 0 : data?.start_kms,
        total_amount: data?.total_amount,
        total_kms: data?.total_kms,
        travel_date: data?.travel_date,
        travel_id: data?.travel_id,
        travel_with: data?.travel_with,
        user_id: data?.user_id,
        user_name: data?.user_name,
        washing_amount: data?.washing_amount == "" ? 0 : data?.washing_amount,
      }));

      let files = [];
      for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
        await TravelListingAPI.uploadFile(filesToUpload[afilee])
          .then((response) => {
            // let name = { name: data.name };
            // filename.push(name);
            files.push(response.data);
          })
          .catch((error) => {
            // dispatch(setDisplayLoader("Hide"));

            toast.error(`${error} `, {
              autoClose: 2000,
            });
            return;
          });
      }
      let payload = {
        travel_id: state?.id,
        attachment_path: files,
        attachment_delete: deleteattachment,
        vehicle_data: modifiedData,
      };
      VehicleLogApi.updateVehicleLog(state?.id, payload)
        .then((response) => {
          if (response.data?.status == true) {
            toast.success("Vehicle Log Updated Successfully");
            setCheckActiveTab();
            doubleClick = "false";
            setSubmitException(false);
            getDa(state?.id);
            setFilesToUpload([]);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const saveHandler = (data) => {
    if (
      checkKms === false &&
      // validateKms == false &&
      driverWagesValid == false &&
      washAmountValid == false &&
      vehicleTypeSelect == false &&
      kmsNotNull == false
      // &&
      // vehicleTotalError == false
    ) {
      if (logData.length == 0 && vehValue == false) {
        saveVehicleLog(data);
      } else {
        updateVehicleLog(data);
      }
    }
  };

  useEffect(() => {
    if (logData.length !== 0) {
      setVehLogData(logData);
    }
  }, [logData]);

  const [strt, setStrt] = useState("");
  const [end, setEnd] = useState("");

  const startKm = (value, index) => {
    setStrt(value);
    getVehicleModeRate(
      vehLogData[index]?.mode_id,
      userData?.organisation_id,
      index,
      value,
      end
    );
  };

  const endKm = (value, index) => {
    setEnd(value);
    getVehicleModeRate(
      vehLogData[index]?.mode_id,
      userData?.organisation_id,
      index,
      strt,
      value
    );
  };

  const CalculateTotalKm = (from, to, index, rate) => {
    let rateValue = rate == undefined ? 0 : rate;
    let logData = vehLogData;
    let toTravel = to === "" ? logData[index]?.close_kms : to;
    let fromTravel = to === "" ? logData[index]?.start_kms : from;
    let diffnce = toTravel - fromTravel;
    let sum = 0;
    if (logData[index].start_kms !== 0 && logData[index]?.close_kms !== 0) {
      logData[index].total_kms = diffnce;
      if (
        ["Bike", "Car", "Personal Car"]?.includes(vehLogData[index].mode_name)
      ) {
        let sumMulti = Number(logData[index]?.total_kms) * rateValue;
        logData[index].fuel_amount = sumMulti;
        sum =
          Number(logData[index]?.other_expenses) > 0
            ? Number(logData[index]?.other_expenses) +
              Number(logData[index]?.fuel_amount)
            : Number(logData[index]?.fuel_amount);
        logData[index].total_amount = sum;
        if (
          vehLogData[index]?.mode_name != null &&
          vehLogData[index]?.mode_id !== undefined &&
          (logData[index]?.total_amount == undefined ||
            logData[index]?.total_amount == "" ||
            logData[index]?.total_amount == 0)
        ) {
          setVehicleTotalError(true);
          setVehicleTypeSelect(false);
        } else {
          setVehicleTotalError(false);
        }
      }
    }
    setVehLogData(logData);
  };
  const changeDataHandler = (value, name, index) => {
    let data = [...vehLogData];
    let sum = 0;
    let washSum = 0;
    data[index][name] = value;

    // handleValidateException(index);
    setIndexValue(index);
    if (name == "mode_id") {
      data[index].modee_name =
        value.target.options[value.target.selectedIndex]?.getAttribute("label");
      data[index].mode_name =
        value.target.options[value.target.selectedIndex]?.getAttribute("label");
      data[index].mode_id = value.target.value;
    } else if (name === "driver_daily_wages") {
      if (vehicleDailyWagesData?.driver_wage < value) {
        setDriverWagesValid(true);
      } else {
        setDriverWagesValid(false);
      }
    } else if (name == "fuel_quantity") {
      if (vehLogData[index]?.fuel_quantity > 99) {
        setFuelQuantValid(true);
      } else {
        setFuelQuantValid(false);
      }
    } else if (name == "washing_amount") {
      let pp = washingCurrAmount == undefined ? 0 : Number(washingCurrAmount);
      vehLogData?.map((data, index) => {
        washSum = Number(data?.washing_amount) + Number(washSum);
        if (Number(pp) >= Number(750)) {
          if (Number(washSum) > Number(pp)) {
            setWashAmountValid(true);
          } else {
            setWashAmountValid(false);
          }
        } else {
          if (Number(washSum) > Number(750)) {
            setWashAmountValid(true);
          } else {
            setWashAmountValid(false);
          }
        }
      });
    } else if (name == "close_kms") {
      if (
        vehLogData[index]?.mode_id == "" ||
        vehLogData[index]?.mode_id == null
      ) {
        setVehicleTypeSelect(true);
      } else {
        setVehicleTypeSelect(false);
      }
    }
    if (vehLogData[index]?.mode_name === "Bike") {
      data[index].fuel_quantity = 0;
      data[index].driver_daily_wages = 0;
      data[index].driver_da_type = "";
      data[index].driver_da_value = 0;
      data[index].other_expenses = 0;
    } else if (vehLogData[index]?.mode_name === "Personal Car") {
      data[index].fuel_quantity = 0;
      data[index].driver_daily_wages = 0;
      data[index].driver_da_type = "";
      data[index].driver_da_value = 0;
    } else if (vehLogData[index]?.mode_id == 0) {
      data[index].start_kms = 0;
      data[index].close_kms = 0;
      data[index].total_kms = 0;
      data[index].mode_id = null;
      data[index].fuel_quantity = 0;
      data[index].fuel_amount = 0;
      data[index].driver_daily_wages = 0;
      data[index].driver_da_type = "";
      data[index].driver_da_value = 0;
      data[index].other_expenses = 0;
      data[index].travel_with = "";
      data[index].partner_name = "";
      data[index].washing_amount = 0;
      data[index].total_amount = 0;
    }
    setVehLogData(data);
    sum =
      Number(data[index].fuel_amount) +
      Number(data[index].driver_daily_wages) +
      Number(data[index].driver_da_value) +
      Number(data[index].other_expenses) +
      Number(data[index].washing_amount);
    data[index].total_amount = sum;
    setVehLogData(data);
    if (
      vehLogData[index]?.mode_name != null &&
      vehLogData[index]?.mode_id !== undefined &&
      (vehLogData[index]?.total_amount == undefined ||
        vehLogData[index]?.total_amount == "" ||
        vehLogData[index]?.total_amount == 0)
    ) {
      setVehicleTotalError(true);
      setVehicleTypeSelect(false);
    } else {
      setVehicleTotalError(false);
    }
    if (
      (vehLogData[index].close_kms == "" &&
        vehLogData[index].close_kms !== 0) ||
      (vehLogData[index].start_kms == "" && vehLogData[index].start_kms !== 0)
    ) {
      setKmsNotNull(true);
    } else {
      setKmsNotNull(false);
    }
  };
  useEffect(() => {
    let data = [...vehLogData];
    if (vehicleTravelExists == true) {
      data[indexValue].start_kms = 0;
      data[indexValue].close_kms = 0;
      data[indexValue].total_kms = 0;
      data[indexValue].mode_id = null;
      data[indexValue].fuel_quantity = 0;
      data[indexValue].fuel_amount = 0;
      data[indexValue].driver_daily_wages = 0;
      data[indexValue].driver_da_type = "";
      data[indexValue].driver_da_value = 0;
      data[indexValue].other_expenses = 0;
      data[indexValue].travel_with = "";
      data[indexValue].partner_name = "";
      data[indexValue].washing_amount = 0;
      data[indexValue].total_amount = 0;
      data[indexValue].disable = true;
      setVehicleTotalError(false);
      toast.error("This date is already exists.");
    }
    setVehLogData(data);
  }, [vehicleTravelExists]);

  const handleFuelValidation = (index) => {
    let data = JSON.parse(JSON.stringify(vehLogData));
    if (data[index].fuel_quantity !== 0 && data[index].fuel_amount == 0) {
      setValidateFuelAmount(true);
    } else {
      setValidateFuelAmount(false);
    }
  };

  const pushVehicleType = (e, i) => {
    getVehicleModeRate(e.target.value, userData?.organisation_id, i, strt, end);
    changeDataHandler(e, "mode_id", i);
  };

  useEffect(() => {
    let diffInMs;
    let diffInDays;
    let diffInD;
    const startDate = new Date(state?.travel_detail_data[0]?.from_date);
    const endDate = new Date(
      state?.travel_detail_data[state?.travel_detail_data?.length - 1]?.to_date
    );
    if (state?.travel_detail_data?.length > 0 && logData.length === 0) {
      diffInMs = Math.abs(endDate - startDate);
      diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
      diffInD = diffInDays + 1;
    } else {
      diffInDays = 1;
    }
    let counter = 0;
    let fd = new Array(diffInD).fill({
      travel_date: "",
      start_kms: 0,
      close_kms: 0,
      total_kms: 0,
      mode_id: undefined,
      fuel_quantity: 0,
      fuel_amount: 0,
      driver_daily_wages: 0,
      driver_da_type: "",
      driver_da_value: 0,
      other_expenses: 0,
      travel_with: "",
      partner_name: "",
      washing_amount: 0,
      total_amount: 0,
      is_exception: false,
      is_personal_car_exception: false,
    });
    let test = fd.map((obj, i) => {
      let curValue = JSON.parse(JSON.stringify(obj));
      // curValue.mode_id = state?.travel_detail_data[0]?.mode_id;
      // curValue.mode_name = state?.travel_detail_data[0]?.mode_name;
      curValue.travel_date = moment(state?.travel_detail_data[0]?.from_date)
        .add(counter, "day")
        .format("YYYY-MM-DD");
      counter += 1;
      return curValue;
    });
    setVehLogData(test);
    getVehicleLog(state?.id, userData.id);
  }, [state]);

  useEffect(() => {
    getModeList(true);
  }, [vehicleAssignValues]);

  const onHandleCheck = (value, i) => {
    let data = JSON.parse(JSON.stringify(vehLogData));
    data[i].close_kms = value;
    if (Number(vehLogData[i].start_kms) > Number(vehLogData[i].close_kms)) {
      setCheckKms(true);
    } else {
      setCheckKms(false);
    }
  };

  const deleteAttachment = (id) => {
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (attachment == undefined) {
      let obj = {};
      Object.keys(file)
        .filter((del) => {
          if (file[del].name !== id) {
            return true;
          }
        })
        .map((item) => {
          obj[item] = file[item];
        });
      document.getElementById("policyattachment").value = "";
      setfiles(obj);
    } else {
      let deleteId = [];
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          if (del.attachment_path?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            deleteattachment.push(del.id);
          }
        });
        setAttachment(data2);
        document.getElementById("policyattachment").value = "";
      } else {
        let obj = {};
        Object.keys(file)
          .filter((del) => {
            if (file[del].name !== id) {
              return true;
            }
          })
          .map((item) => {
            obj[item] = file[item];
          });
        document.getElementById("policyattachment").value = "";
        setfiles(obj);
      }
    }
  };

  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ||
                data2.name.includes(".csv") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name.includes(".msg") ? (
                <img
                  src={"/images/policy-icon/msg.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      );
    }
    return data;
  };

  function scrollWin() {
    window.scrollTo(0, 100);
  }

  const handleValidateException = (i) => {
    scrollWin();
    setPopUpShow(false);
    if (submitException === true && indexValue !== undefined) {
      // if (
      //   (["Taxi", "Personal Car"].includes(
      //     vehLogData[indexValue]?.mode_name != undefined
      //       ? vehLogData[indexValue]?.mode_name
      //       : vehLogData[indexValue]?.modee_name
      //   ) &&
      //     vehLogData[indexValue]?.close_kms !== 0 &&
      //     vehLogData[indexValue]?.close_kms !== "") ||
      //   (vehLogData[indexValue]?.driver_daily_wages !== 0 &&
      //     vehLogData[indexValue]?.driver_daily_wages !== null &&
      //     driverExists == false) ||
      //   (Number(vehLogData[indexValue]?.total_kms) > 250 &&
      //     (userData.organisation_code == "PIIL" ||
      //       userData.organisation_code == "PIJA"))
      // ) {
      vehLogData?.map((value, idx) => {
        if (
          ["Taxi", "Personal Car"].includes(
            value?.mode_name != undefined ? value?.mode_name : value?.modee_name
          ) &&
          value?.close_kms !== 0 &&
          value?.close_kms !== ""
        ) {
          vehLogData[idx].is_personal_car_exception = true;
        } else if (
          (value?.driver_daily_wages !== 0 &&
            value?.driver_daily_wages !== null &&
            driverExists == false) ||
          (Number(value?.total_kms) > 250 &&
            (userData.organisation_code == "PIIL" ||
              userData.organisation_code == "PIJA"))
        ) {
          vehLogData[idx].is_exception = true;
        }
        if (
          ["Bike", "Car"].includes(
            value?.mode_name != undefined ? value?.mode_name : value?.modee_name
          )
        ) {
          vehLogData[idx].is_personal_car_exception = false;
          vehLogData[idx].is_exception = false;
        } else if (
          (value?.driver_daily_wages == 0 &&
            value?.driver_daily_wages == null &&
            driverExists == false) ||
          (Number(value?.total_kms) < 250 &&
            (userData.organisation_code == "PIIL" ||
              userData.organisation_code == "PIJA"))
        ) {
          vehLogData[idx].is_exception = false;
        }
      });
      // } else if (
      //   ["Bike", "Car"].includes(
      //     vehLogData[indexValue]?.mode_name != undefined
      //       ? vehLogData[indexValue]?.mode_name
      //       : vehLogData[indexValue]?.modee_name
      //   ) ||
      //   (vehLogData[indexValue]?.driver_daily_wages == 0 &&
      //     vehLogData[indexValue]?.driver_daily_wages == null &&
      //     driverExists == false) ||
      //   (Number(vehLogData[indexValue]?.total_kms) < 250 &&
      //     (userData.organisation_code == "PIIL" ||
      //       userData.organisation_code == "PIJA"))
      // ) {
      //   vehLogData?.map((value, idx) => {
      //     if (
      //       ["Bike", "Car"].includes(
      //         value?.mode_name != undefined
      //           ? value?.mode_name
      //           : value?.modee_name
      //       )
      //     ) {
      //       vehLogData[idx].is_personal_car_exception = false;
      //       vehLogData[idx].is_exception = false;
      //     } else if (
      //       (value?.driver_daily_wages == 0 &&
      //         value?.driver_daily_wages == null &&
      //         driverExists == false) ||
      //       (Number(value?.total_kms) < 250 &&
      //         (userData.organisation_code == "PIIL" ||
      //           userData.organisation_code == "PIJA"))
      //     ) {
      //       vehLogData[idx].is_exception = false;
      //     }
      //   });
      // }
      saveHandler(vehLogData);
    } else if (submitException === true) {
      saveHandler(vehLogData);
    }
  };

  const changeDriverWages = (value) => {
    getUserDriverExists(userData?.id);
  };

  const handleClick = (index) => {
    if (index != 0) {
      let data = JSON.parse(JSON.stringify(vehLogData));
      let value = data[index - 1]?.close_kms;
      data[index].start_kms = value;
      setStrt(value);
      setVehLogData(data);
    }
  };

  const renderStateOptions = (optionsList) => {
    return optionsList.map((item, index) => (
      <option key={index} value={`${item.value}`} label={`${item.label}`} />
    ));
  };

  useEffect(() => {
    getVehicleLogMonthly(userData.id, state?.id);
  }, [userData]);

  const handleChangeDriver = (value, i) => {
    let sum = 0;
    let data = JSON.parse(JSON.stringify(vehLogData));
    data[i].driver_da_value = Number(value);
    sum =
      Number(data[i].fuel_amount) +
      Number(data[i].driver_daily_wages) +
      Number(value) +
      Number(data[i].other_expenses) +
      Number(data[i].washing_amount);
    data[i].total_amount = sum;
    setVehLogData(data);
  };

  useEffect(() => {
    let totalSum = 0;
    vehLogData?.map((data, i) => {
      totalSum = data?.total_amount + totalSum;
    });
    setTotalValueSum(totalSum);
  }, [vehLogData]);

  const handleSubmitVehicle = () => {
    setSubmitException(true);
    vehLogData?.forEach((data, index) => {
      sumDriverWages = data?.driver_daily_wages + sumDriverWages;
    });
    if (sumDriverWages > 0 && driverExists == false) {
      handleAlert();
    } else if (sumDriverWages == 0 && driverExists == false) {
      handleValidateException();
    }
  };

  // useEffect(() => {
  //   if (driverExists == true && popUpShow == true) {
  //     handleValidateException();
  //   } else if (driverExists == true && popUpShow == false) {
  //     handleValidateException();
  //   } else if (driverExists == false && popUpShow == false) {
  //     handleValidateException();
  //   }
  // }, [popUpShow, driverExists]);
  // useEffect(() => {
  //   if (submitException === true) {
  //     vehLogData?.map((data, index) => {
  //       console.log(
  //         data.driver_daily_wages != 0 && data?.driver_daily_wages != null,
  //         "driver"
  //       );
  //       if (data?.driver_daily_wages != 0 && data?.driver_daily_wages != null) {
  //         console.log(data.driver_daily_wages, "driver1");
  //         setPopUpShow(true);
  //       }
  //     });
  //   }
  // }, [submitException]);

  useEffect(() => {
    getUserDriverExists(userData?.id);
    getVehicleDailyWages(userData?.id);
  }, []);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "ArrowDown"].includes(e.key) && e.preventDefault();

  return (
    <>
      <div className="px-4 pb-2">
        {checkKms && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Closing km should not
            be less than starting km.
          </Alert>
        )}
        {kmsNotNull && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Opening and Closing
            Kms should not blank.
          </Alert>
        )}
        {driverWagesValid && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Driver daily wages
            can not be greater than Rs. {vehicleDailyWagesData.driver_wage}
          </Alert>
        )}
        {fuelQuantValid && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> FuelQty can not be
            greater than 99 Ltrs.
          </Alert>
        )}
        {vehicleTotalError && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i>Total Amount is 0 in
            few rows where Vehicle Type is selected
          </Alert>
        )}
        {washAmountValid && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Washing Amount can
            not be greater than 750Rs.
          </Alert>
        )}
        {vehicleTypeSelect && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Please Select Vehicle
            Type first
          </Alert>
        )}
        {/* {validateKms && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Total Kms should not
            be 0.
          </Alert>
        )} */}
        {validateFuelAmount && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Fuel Amount should
            not 0 or null
          </Alert>
        )}
        <div class="col-md-12">
          <div className="row">
            <div class="col-md-4" style={{ float: "left" }}>
              <h6 class="f mt-2">
                <strong>Total :</strong>
                <span class="text-muted">
                  {" "}
                  <i class="fas fa-rupee-sign f-14"></i>{" "}
                  {Number.isNaN(totalValueSum)
                    ? 0
                    : Number(totalValueSum).toFixed(2)}
                </span>
              </h6>
            </div>
            <div class="col-md-4" style={{ float: "left" }}>
              <h6 class="f mt-2">
                <strong>Vehicle No : </strong>
                <span class="text-muted">
                  {vehicleAssignValues && vehicleAssignValues?.vehicle_no}
                </span>
              </h6>
            </div>

            <div class="col-md-4" style={{ float: "left" }}>
              {userData?.organisation_code == "PIIL" && (
                <h6 class="f mt-2">
                  <strong>Driver Allowed : </strong>
                  <span class="text-muted">
                    {driverExists === false ? "No" : "Yes"}
                  </span>
                </h6>
              )}
            </div>
          </div>
        </div>
        {vehLogData?.map((data, i) => (
          <>
            <div
              className="bg-light px-3 pt-3 mb-3 border-bottom"
              id={data?.id}
            >
              <div col-sm-12>
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <strong>Date</strong>
                      <input
                        type="text"
                        name="travel_date"
                        className="form-control"
                        value={moment(data?.travel_date).format("DD-MM-YYYY")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Vehicle Type</label>
                      <select
                        name="mode_id"
                        id="mode_ids"
                        class="form-control newbgselect"
                        disabled={data?.disable}
                        onChange={(e) => {
                          pushVehicleType(e, i);
                          getvehicleExists(data);
                        }}
                        value={vehLogData[i]?.mode_id}
                      >
                        <option value="">Select</option>
                        {renderStateOptions(mode)}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="form-group innergroup">
                      <label>Start Kms</label>
                      <input
                        name="start_kms"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        disabled={data?.disable}
                        onKeyDown={blockInvalidChar}
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value < 1000000) {
                            changeDataHandler(e.target.value, e.target.name, i);
                            startKm(e.target.value, i);
                          }
                        }}
                        onClick={(e) => handleClick(i)}
                        value={vehLogData[i]?.start_kms}
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="form-group innergroup">
                      <label>Close Kms</label>
                      <input
                        name="close_kms"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        disabled={data?.disable}
                        onKeyDown={blockInvalidChar}
                        className="form-control"
                        onBlur={(e) => onHandleCheck(e.target.value, i)}
                        onChange={(e) => {
                          if (e.target.value < 1000000) {
                            changeDataHandler(e.target.value, e.target.name, i);
                            endKm(e.target.value, i);
                          }
                        }}
                        // onClick={(e) => handleClick(i)}
                        value={vehLogData[i]?.close_kms}
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="form-group innergroup">
                      <label>Total Kms</label>
                      <input
                        type="number"
                        name="total_kms"
                        disabled
                        className="form-control"
                        value={vehLogData[i]?.total_kms}
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="form-group innergroup">
                      <label>Fuel Qty</label>
                      <input
                        name="fuel_quantity"
                        disabled={
                          data?.mode_name
                            ? ["Bike", "Personal Car"]?.includes(
                                vehLogData[i]?.mode_name
                              )
                            : ["Bike", "Personal Car"]?.includes(
                                vehLogData[i]?.modee_name
                              )
                        }
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value < 1000000) {
                            changeDataHandler(e.target.value, e.target.name, i);
                          }
                        }}
                        value={vehLogData[i]?.fuel_quantity}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Fuel Amount</label>
                      <input
                        name="fuel_amount"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        onBlur={() => handleFuelValidation(i)}
                        disabled={
                          data?.mode_name
                            ? ["Bike", "Personal Car"]?.includes(
                                vehLogData[i]?.mode_name
                              )
                            : ["Bike", "Personal Car"]?.includes(
                                vehLogData[i]?.modee_name
                              )
                        }
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value < 1000000) {
                            changeDataHandler(e.target.value, e.target.name, i);
                          }
                        }}
                        value={vehLogData[i]?.fuel_amount}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Driver Daily Wage</label>
                      <input
                        name="driver_daily_wages"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        onBlur={(e) => changeDriverWages(e.target.value)}
                        disabled={
                          data?.mode_name
                            ? ["Bike", "Personal Car"]?.includes(
                                vehLogData[i]?.mode_name
                              )
                            : ["Bike", "Personal Car"]?.includes(
                                vehLogData[i]?.modee_name
                              )
                        }
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value < 1000000) {
                            changeDataHandler(e.target.value, e.target.name, i);
                          }
                        }}
                        value={vehLogData[i]?.driver_daily_wages}
                      />
                      <div>{wagesValidate && "not more than 400"}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div col-sm-12>
                <div className="row">
                  <div className="col-md-1">
                    <div className="form-group innergroup">
                      <label>Driver DA</label>
                      <select
                        name="driver_da_type"
                        disabled={
                          data?.mode_name
                            ? ["Bike", "Personal Car"]?.includes(
                                vehLogData[i]?.mode_name
                              )
                            : ["Bike", "Personal Car"]?.includes(
                                vehLogData[i]?.modee_name
                              )
                        }
                        className="form-control newbgselect"
                        onChange={(e) => {
                          changeDataHandler(e.target.value, e.target.name, i);
                          handleChangeDriver(e.target.value, i);
                        }}
                        value={vehLogData[i]?.driver_da_type}
                      >
                        <option value="">Select</option>
                        <option value="0">Local</option>
                        <option value="155">NH</option>
                        <option value="100">RHQ</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Driver DA Value</label>
                      <input
                        name="driver_da_value"
                        type="number"
                        disabled
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value < 1000000) {
                            changeDataHandler(e.target.value, e.target.name, i);
                          }
                        }}
                        value={vehLogData[i]?.driver_da_value}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Toll/Puncture/Parking</label>
                      <input
                        name="other_expenses"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        disabled={
                          data?.mode_name
                            ? ["Bike"]?.includes(vehLogData[i]?.mode_name)
                            : ["Bike"]?.includes(vehLogData[i]?.modee_name)
                        }
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value < 1000000) {
                            changeDataHandler(e.target.value, e.target.name, i);
                          }
                        }}
                        value={vehLogData[i]?.other_expenses}
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="form-group innergroup">
                      <label>Washing</label>
                      <input
                        name="washing_amount"
                        type="number"
                        // disabled={userData?.organisation_code == "PIJA"}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value < 1000000) {
                            changeDataHandler(e.target.value, e.target.name, i);
                          }
                        }}
                        value={vehLogData[i]?.washing_amount}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Travel With</label>
                      <select
                        name="travel_with"
                        className="form-control newbgselect"
                        disabled={data?.disable}
                        onChange={(e) => {
                          changeDataHandler(e.target.value, e.target.name, i);
                        }}
                        value={vehLogData[i]?.travel_with}
                      >
                        <option value="">Select</option>
                        <option value="Dealer/Distributor">
                          Dealer/Distributor
                        </option>
                        <option value="Colleague">Colleague</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Partner Name</label>
                      <input
                        name="partner_name"
                        type="text"
                        className="form-control"
                        disabled={data?.disable}
                        onChange={(e) => {
                          changeDataHandler(e.target.value, e.target.name, i);
                        }}
                        value={vehLogData[i]?.partner_name}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Total</label>
                      <input
                        name="total_amount"
                        type="number"
                        disabled
                        className="form-control"
                        value={vehLogData[i]?.total_amount}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
        {/* </tbody> */}
        {/* </table> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}

        {/* <div class="mb-4"> */}
        <div class="row">
          <div class="col-md-4 d-flex flex-column mt-3">
            <h5 class="text-theme pb-2 mb-3">
              <strong>
                Attachments<span class="text-danger">*</span>
              </strong>
            </h5>

            <div class="form-group mt-auto mb-0 innergroup position-relative modaldefaultclose ">
              <label>
                Attach File<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control bg-white"
                disabled
                value={
                  // edit === true
                  // ? editPolicy?.logo
                  uploadDataIm?.logo?.original_name
                }
                placeholder="(JPG, PNG , Word, Pdf, ppt, Video)"
              />
              <div class="upload-btn-wrapper up-loposition">
                <button class="uploadBtn">Browse</button>
                <input
                  type="file"
                  title=""
                  id="policyattachment"
                  multiple
                  name="attachment_path"
                  accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                  onChange={(e) => onProfileChange(e, "attachment_path")}
                />
              </div>
              <p>
                Selected files <span> {uploadDataIm[0]?.original_name}</span>
              </p>
            </div>
            {/* </div> */}
            {Object.keys(file).length !== 0 ? (
              <div className="d-flex">{attachments123()}</div>
            ) : attachment != undefined && attachment.length != 0 ? (
              <>
                <div class="d-flex">
                  {attachment?.map((Data) => (
                    <div class="uploaded-image mr-1">
                      <i class="" style={{ width: "20px" }}>
                        <a
                          href={Data?.attachment_path}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          {Data?.attachment_path?.includes(".pdf") ? (
                            <img
                              src={"/images/policy-icon/pdf.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".xlsx") ? (
                            <img
                              src={"/images/policy-icon/xlsx.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".xls") ? (
                            <img
                              src={"/images/policy-icon/xlsx.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".doc") ? (
                            <img
                              src={"/images/policy-icon/docs.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".docs") ||
                            Data?.attachment_path.includes(".csv") ? (
                            <img
                              src={"/images/policy-icon/docs.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".pptx") ? (
                            <img
                              src={"/images/policy-icon/ppt.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data.attachment_path.includes(".mp4") ? (
                            <img
                              src={"/images/policy-icon/video.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".msg") ? (
                            <img
                              src={"/images/policy-icon/msg.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : (
                            <img
                              src={Data.attachment_path}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          )}
                        </a>
                      </i>
                      <button
                        type="button"
                        onClick={(e) => {
                          deleteAttachment(
                            Data?.attachment_path?.split("/")[4]
                          );
                        }}
                        className="btn btn-blanktd text-danger ml-2"
                      >
                        <i className="far fa-trash-alt text-danger"></i>
                      </button>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="mb-3">
          <div className="row">
            <div className="col-md-12 mt-3 text-center">
              <button
                type="button"
                class="btn btn-outline-secondary mb-2 mt-2"
                onClick={() => {
                  setCheckActiveTab("Summary");
                }}
              >
                Back
              </button>
              &nbsp;&nbsp;
              <button
                className="btn btn-primary-inner bpi-main"
                type="button"
                onClick={() => {
                  setSubmitException(true);
                  if (driverExists == false) {
                    handleSubmitVehicle();
                  } else {
                    handleValidateException();
                  }
                }}
                style={{ marginRight: "202px" }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleLog;
