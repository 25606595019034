import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Dropdown, Pagination, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import CustomPagination from "./CustomPagination";
import { Tooltip } from "react-tippy";
import { getRequests, getRequestsExport } from "../../service/materialService";
import * as moment from "moment";
import { setDisplayLoader, selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

function MatApproval() {
  const dispatch = useDispatch();
  const [activeTabvalue, setActiveTabvalue] = useState("P");
  const userData = useSelector(selectUserData);

  const pathname = window.location.pathname;
  const CustomBreadcrumbItems = [
    { to: "/home", title: "Home" },
    { to: "/", title: "Transactions" },
    { active: true, title: "Material Request Approval" },
  ];
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [approvalCount, setapprovalCount] = useState(0);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({
    sort_by: "-id",
    status: "P",
  });
  const [searchStr, setSearchStr] = useState("");

  const handleSearch = (e) => {
    if (activeTabvalue == "Report") {
      getData(e.target.value.toLowerCase(), "", 1, pageSizeNo, "id", true, true);
    } else {
      getData(
        e.target.value.toLowerCase(),
        activeTabvalue,
        1,
        pageSizeNo,
        "id",
        true,
        false
      );
    }
    setSearchStr(e.target.value.toLowerCase());
  };
  const getExportDetails = (
    search,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
     getRequestsExport(
      handleKey(search, status, page_no, page_size, sort_by, paginate)
    )
   
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "MaterialRequest.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handlePageClick = (action) => {
    const activePage = action.selected + 1;
    setCurrentPage(activePage);
    if (activeTabvalue == "Report") {
      getData("", "", activePage, pageSizeNo, filter.sort_by, true, true);
    } else {
      getData(searchStr, activeTabvalue, activePage, pageSizeNo, "id", true, false);
    }
  };
  const handleKey = (search, status, page_no, page_size, sort_by, paginate, report_flag) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      activeTabvalue !== "" &&
      activeTabvalue !== undefined &&
      activeTabvalue !== null &&
      activeTabvalue !== {} &&
      activeTabvalue !== "Report"
    ) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (report_flag !== "" && report_flag !== undefined && report_flag !== null) {
      queryParm = queryParm + "&report_flag=" + report_flag;
    }
    return queryParm;
  };
  const getData = async (
    search,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    report_flag
  ) => {
    dispatch(setDisplayLoader("Display"));
    await getRequests(
      handleKey(search, status, page_no, page_size, sort_by, paginate,report_flag)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        if (response.data.code === 200) {
          let result = response.data?.dataList?.result?.filter(
            (ele) =>
              ele?.creater ===
              userData.full_name + "(" + userData.username + ")"
          );
          setList(response?.data?.dataList?.result);
          setapprovalCount(
            response?.data?.dataList?.paginated_data?.approvalCount
          );
          setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (pathname === "/mat-approval-report") {
      getData(searchStr, "", 1, pageSizeNo, filter.sort_by, true, true);
    } else {
      getData(
        searchStr,
        activeTabvalue,
        currentPage,
        pageSizeNo,
        filter.sort_by,
        true,
        false
      );
    }
  }, [activeTabvalue, pageSizeNo]);

  useEffect(() => {
    if (pathname === "/mat-approval-report") {
      getData("", "", 1, pageSizeNo, filter.sort_by, true, true);
      setActiveTabvalue("Report");
    } else {
      getData(
        searchStr,
        activeTabvalue,
        currentPage,
        pageSizeNo,
        filter.sort_by,
        true,
        false
      );
    }
  }, [pathname]);

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <CustomMenu 
          approvalCount={approvalCount}
          />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Material Request Approval</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            disabled={list.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                              searchStr,
                                activeTabvalue,
                                1,
                                pageSizeNo,
                                "id",
                                true
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs
                onSelect={(e) => setActiveTabvalue(e)}
                defaultActiveKey="approvaltabs"
                id="approvaltabs"
                activeKey={activeTabvalue}
              >
                {pathname == "/mat-approval" && (
                  <Tab
                    eventKey="P"
                    title={
                      <React.Fragment>
                        Pending
                        {/* <span class="numberpurchse">{approvalCount}</span> */}
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Temporary Code</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Plant</th>
                            <th>Group</th>
                            <th>Type</th>
                            <th>Unit</th>
                            <th>Req. Date</th>
                            <th>Requestor</th>
                            <th>Pending At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.map((x) => (
                            <tr>
                              <td>
                                <Link
                                  state={{
                                    from: x?.id,
                                  }}
                                  to="/approve-mat-req"
                                >
                                  <img src="images/eye.png" alt="" />
                                </Link>
                              </td>
                              <td>{x?.temp_code}</td>
                              <td class="text-theme">
                                <Link
                                  state={{
                                    from: x?.id,
                                  }}
                                  to="/approve-mat-req"
                                  class="fw-bold text-theme"
                                >
                                  {x?.description}
                                </Link>
                              </td>
                              <td>
                                <span class="badge bad-status badge-warning">
                                  Pending
                                </span>
                              </td>
                              <td>{x?.plant_name}</td>
                              <td>{x?.group}</td>
                              <td>{x?.material_type}</td>
                              <td>{x?.unit}</td>
                              <td>
                                {moment(x?.created_at).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {x?.creater?.length > 25 ? (
                                  <Tooltip title={x?.creater} position="bottom">
                                    {x?.creater?.substr(0, 25)}...
                                  </Tooltip>
                                ) : (
                                  x?.creater
                                )}
                                {/* {x?.creater} */}
                              </td>
                              <td>
                                {x?.CureentApprover?.user?.length > 25 ? (
                                  <Tooltip
                                    title={x?.CureentApprover?.user}
                                    position="bottom"
                                  >
                                    {x?.CureentApprover?.user?.substr(0, 25)}
                                    ...
                                  </Tooltip>
                                ) : (
                                  x?.CureentApprover?.user
                                )}
                                {/* {x?.CureentApprover?.user} */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
        <div class="col-sm-5">
          <div class="sortinglist">
            Show
            <select
              class="form-control"
              onChange={(e) => {
                setPageSizeNo(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <div class="col-sm-7">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-left"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
                  </Tab>
                )}
                {pathname == "/mat-approval" && (
                  <Tab
                    eventKey="A"
                    title={<React.Fragment>Approved</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Temporary Code</th>
                            <th>Material Desc.</th>
                            <th>Status</th>
                            <th>Plant</th>
                            <th>Material Group</th>
                            <th>Material Type</th>
                            <th>Material Unit</th>
                            <th>Storage Location</th>
                            <th>Material No. </th>
                            <th>Req. Date</th>
                            <th>Requestor</th>
                            <th>Approved By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.map((x) => (
                            <tr>
                              <td>
                                <Link
                                  state={{
                                    from: x?.id,
                                  }}
                                  to="/approve-mat-req"
                                >
                                  <img src="images/eye.png" alt="" />
                                </Link>
                              </td>
                              <td>{x?.temp_code}</td>
                              <td class="text-theme">
                                <Link
                                  state={{
                                    from: x?.id,
                                  }}
                                  to="/approve-mat-req"
                                  class="fw-bold text-theme"
                                >
                                  {x?.description}
                                </Link>
                              </td>
                              <td>
                                <span class="badge bad-status badge-success">
                                  Approved
                                </span>
                              </td>
                              <td>{x?.plant_name}</td>
                              <td>{x?.group}</td>
                              <td>{x?.material_type}</td>
                              <td>{x?.unit}</td>
                              <td>{x?.store_location}</td>
                              <td>{x?.material_type}</td>
                              <td>
                                {moment(x?.created_at).format("DD-MM-YYYY")}
                              </td>
                              <td>{x?.creater}</td>
                              <td>{x?.CureentApprover?.user}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
        <div class="col-sm-5">
          <div class="sortinglist">
            Show
            <select
              class="form-control"
              onChange={(e) => {
                setPageSizeNo(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <div class="col-sm-7">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-left"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
                  </Tab>
                )}
                {pathname == "/mat-approval" && (
                  <Tab
                    eventKey="R"
                    title={<React.Fragment>Rejected</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Material Desc.</th>
                            <th>Status</th>
                            <th>Plant</th>
                            <th>Material Group</th>
                            <th>Material Type</th>
                            <th>Material Unit</th>
                            <th>Storage Location</th>
                            <th>Material No. </th>
                            <th>Req. Date</th>
                            <th>Requestor</th>
                            <th>Rejected By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.map((x) => (
                            <tr>
                              <td>
                                <Link
                                  state={{
                                    from: x?.id,
                                  }}
                                  to="/approve-mat-req"
                                >
                                  <img src="images/eye.png" alt="" />
                                </Link>
                              </td>
                              <td class="text-theme">
                                <Link
                                  state={{
                                    from: x?.id,
                                  }}
                                  to="/approve-mat-req"
                                  class="fw-bold text-theme"
                                >
                                  {x?.description}
                                </Link>
                              </td>
                              <td>
                                <span class="badge bad-status badge-danger">
                                  Rejected
                                </span>
                              </td>
                              <td>{x?.plant_name}</td>
                              <td>{x?.group}</td>
                              <td>{x?.material_type}</td>
                              <td>{x?.unit}</td>
                              <td>{x?.store_location}</td>
                              <td>{x?.material_type}</td>
                              <td>
                                {moment(x?.created_at).format("DD-MM-YYYY")}
                              </td>
                              <td>{x?.creater}</td>
                              <td>{x?.CureentApprover?.user}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
        <div class="col-sm-5">
          <div class="sortinglist">
            Show
            <select
              class="form-control"
              onChange={(e) => {
                setPageSizeNo(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <div class="col-sm-7">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-left"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
                  </Tab>
                )}
                {pathname == "/mat-approval-report" && (
                  <Tab
                    eventKey="Report"
                    title={
                      <React.Fragment>
                        Report
                        {/* <span class="numberpurchse">{approvalCount}</span> */}
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Temporary Code</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Plant</th>
                            <th>Group</th>
                            <th>Type</th>
                            <th>Unit</th>
                            <th>Req. Date</th>
                            <th>Requestor</th>
                            <th>Pending At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.map((x) => (
                            <tr>
                              <td>
                                <Link
                                  state={{
                                    from: x?.id,
                                  }}
                                  to="/approve-mat-req"
                                >
                                  <img src="images/eye.png" alt="" />
                                </Link>
                              </td>
                              <td>{x?.temp_code}</td>
                              <td class="text-theme">
                                <Link
                                  state={{
                                    from: x?.id,
                                  }}
                                  to="/approve-mat-req"
                                  class="fw-bold text-theme"
                                >
                                  {x?.description}
                                </Link>
                              </td>
                              <td>
                                {x?.status === "A"
                                  ? "Approved"
                                  : x?.status === "P"
                                  ? "Pending"
                                  : "Rejected"}
                              </td>
                              <td>{x?.plant_name}</td>
                              <td>{x?.group}</td>
                              <td>{x?.material_type}</td>
                              <td>{x?.unit}</td>
                              <td>
                                {moment(x?.created_at).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {x?.creater?.length > 25 ? (
                                  <Tooltip title={x?.creater} position="bottom">
                                    {x?.creater?.substr(0, 25)}...
                                  </Tooltip>
                                ) : (
                                  x?.creater
                                )}
                                {/* {x?.creater} */}
                              </td>
                              <td>
                                {x?.CureentApprover?.user?.length > 25 ? (
                                  <Tooltip
                                    title={x?.CureentApprover?.user}
                                    position="bottom"
                                  >
                                    {x?.CureentApprover?.user?.substr(0, 25)}...
                                  </Tooltip>
                                ) : (
                                  x?.CureentApprover?.user
                                )}
                                {/* {x?.CureentApprover?.user} */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <CustomPagination
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                      handlePageClick={handlePageClick}
                      pageCount={pageCount}
                    />
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              GL Code<span class="text-danger">*</span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Select User<span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Reviewer<span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MatApproval;
