import React, { useState, useEffect } from "react";
import { Tabs, Tab, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import Select from "react-select";
import * as moment from "moment";
import * as UserService from "../../../service/userService";
import {
  selectFinalUrlPermission,
  selectUserData,
} from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

const UserDetails = (props) => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();

  const { viewForm, onViewClose } = props;
  const userDetails = useSelector(selectUserData);
  const [group, setGroups] = useState([]);
  const [currentTab, setCurrentTab] = useState("primary");
  const [viewFormData, setViewFormData] = useState([]);

  const getUserDetails = (data) => {
    UserService.getUserDetails(data)
      .then((response) => {
        setViewFormData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (userDetails?.username !== undefined) {
      getUserDetails(userDetails?.username);
    }
  }, [userDetails]);

  // useEffect(() => {
  //   console.log("viewFormData.groups", viewFormData[0]?.groups);

  //   let data = viewFormData[0]?.groups;
  //   let groupss = [];
  //   data?.map((x) => {
  //     let data2 = { value: x?.id, label: x?.name };
  //     groupss.push(data2);
  //   });
  //   console.log("groupss.groups", groupss);

  //   setGroups(groupss);
  // }, []);

  useEffect(() => {
    console.log("viewFormData", viewFormData);
    console.log(
      "viewFormData[0]?.organisation_name",
      viewFormData[0]?.organisation_name
    );
    console.log("viewFormData.groups", viewFormData[0]?.groups);
    let data = viewFormData[0]?.groups;
    let groups = [];
    data?.map((x) => {
      console.log("xxxx", x);
      let data2 = { value: x?.id, label: x?.name };
      groups.push(data2);
    });
    setGroups(groups);
  }, [viewFormData]);

  return (
    <>
      {" "}
      <div className="content-wrapper-inner">
        <div className="innerheadsec pb-0">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">My Information</h4>
              {/* <Breadcrumb>
                                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                            <Breadcrumb.Item href="/home">
                                                Role Management
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item href="/user">
                                                User Master
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>View User</Breadcrumb.Item>
                                        </Breadcrumb> */}
            </div>
          </div>
        </div>

        <div className="addusertabs">
          <Tabs
            activeKey={currentTab}
            id="adduserinfo"
            onSelect={(e) => setCurrentTab(e)}
          >
            <Tab
              eventKey="primary"
              title={
                <React.Fragment>
                  <span>1</span> Primary Info
                </React.Fragment>
              }
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Organisation</label>

                    <input
                      type="text"
                      className="form-control"
                      value={
                        viewFormData[0]?.organisation_name +
                        "(" +
                        viewFormData[0]?.organisation_code +
                        ")"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Business Unit</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData[0]?.business_unit_name +
                          "(" +
                          viewFormData[0]?.business_unit_code +
                          ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Id</label>
                    <input
                      type="email"
                      className="form-control"
                      value={viewFormData[0]?.username}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.first_name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.last_name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>PAN No.</label>
                    <input
                      type="text"
                      className="form-control text-uppercase"
                      value={viewFormData[0]?.pan_no}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Vendor Code</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Vendor Code"
                      name="employee_vendor_code"
                      value={viewFormData[0]?.employee_vendor_code}
                      disabled
                    />
                  </div>
                </div>
                {/*                 
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Vendor Code</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={`${
                          viewFormData[0]?.vendor_name != null
                            ? viewFormData[0]?.vendor_name
                            : ""
                        } ${
                          viewFormData[0]?.vendor_code != null
                            ? "(" + viewFormData[0]?.vendor_code + ")"
                            : ""
                        }`}
                      />
                    </select>
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Business Email</label>
                    <input
                      type="email"
                      className="form-control"
                      value={viewFormData[0]?.business_email}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>HR Name</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData[0]?.hr_first_name +
                          " " +
                          viewFormData[0]?.hr_last_name +
                          " " +
                          "(" +
                          viewFormData[0]?.hr_username +
                          ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Business Mobile No.</label>
                    <div className="mobile-country-drop">
                      <div className="row">
                        <div className="col-3 pr-0">
                          <select
                            className="form-control newbgselect"
                            name="business_unit_id"
                            disabled
                          >
                            <option
                              value=""
                              label={viewFormData[0]?.business__country_code}
                            />
                          </select>
                        </div>
                        <div className="col-9 pl-0">
                          <input
                            type="number"
                            className="form-control"
                            value={viewFormData[0]?.business_mobile}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Reporting Manager</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData[0]?.reporting_person_firstname +
                          " " +
                          viewFormData[0]?.reporting_person_lastname +
                          " " +
                          "(" +
                          viewFormData[0]?.reporting_person_username +
                          ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Functional Head</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData[0]?.functional_head_first_name +
                          " " +
                          viewFormData[0]?.functional_head_last_name +
                          " " +
                          "(" +
                          viewFormData[0]?.functional_head_username +
                          ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Cost Center</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={`${
                          viewFormData[0]?.cost_center_name != null
                            ? viewFormData[0]?.cost_center_name
                            : ""
                        } ${
                          viewFormData[0]?.cost_center_code != null
                            ? "(" + viewFormData[0]?.cost_center_code + ")"
                            : ""
                        }`}
                        //  label={viewFormData[0]?.cost_center_name!=null ? viewFormData[0]?.cost_center_name : "" + "("+viewFormData[0]?.cost_center_code!=null ? viewFormData[0]?.cost_center_code : ""+")"}
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Department</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData[0]?.department_name +
                          "(" +
                          viewFormData[0]?.department_code +
                          ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Designation</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={`${
                          viewFormData[0]?.designation_name != null
                            ? viewFormData[0]?.designation_name
                            : ""
                        } (${
                          viewFormData[0]?.designation_code != null
                            ? viewFormData[0]?.designation_code
                            : ""
                        })`}
                        //  label={viewFormData[0]?.designation_name + "("+viewFormData[0]?.designation_code+")"}
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>MyPI Role</label>
                    <Select
                      isMulti
                      // options={selrole}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      isDisabled
                      value={group}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>SAP Role</label>
                    <input
                      type="text"
                      className="form-control"
                      value={`${
                        viewFormData[0]?.sap_role === null
                          ? ""
                          : viewFormData[0]?.sap_role
                      }${
                        viewFormData[0]?.sap_role_code === null
                          ? ""
                          : "(" + viewFormData[0]?.sap_role_code + ")"
                      } `}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Gender</label>
                    <select className="form-control newbgselect" disabled>
                      <option
                        value=""
                        label={
                          viewFormData[0]?.gender == "M" ? "Male" : "Female"
                        }
                      />
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Mandatory Course Days{" "}
                       
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={viewFormData[0]?.course_days}
                      disabled
                    />
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Date of Joining</label>
                    <input
                      type="text"
                      value={moment(viewFormData[0]?.date_of_joining).format(
                        "DD-MM-YYYY"
                      )}
                      className="form-control"
                      placeholder="Enter Mandatory course days"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Personal Area</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData[0]?.personal_area_desc == null
                            ? ""
                            : viewFormData[0]?.personal_area_desc +
                              "(" +
                              viewFormData[0]?.personal_area_name +
                              ")"
                        }
                      />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sub-Personal Area</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData[0]?.sub_personal_area_desc == null
                            ? ""
                            : viewFormData[0]?.sub_personal_area_desc +
                              "(" +
                              viewFormData[0]?.sub_personal_area_name +
                              ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Date of Leaving</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.date_of_leaving}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div  className="col-md-4">
                                                        <div  className="form-group innergroup position-relative inneereye">
                                                            <label>Password <span  className="text-danger">*</span></label>
                                                            <input type="password"  className="form-control"  disabled/>
                                                            <i  className="far fa-eye-slash eyepassword"></i>
                                                             
                                                        </div> 
                                                    </div> */}
                {/* <div  className="col-md-4">
                                                        <div  className="form-group innergroup position-relative inneereye">
                                                            <label>Confirm Password <span  className="text-danger">*</span></label>
                                                            <input type="password"  className="form-control" disabled/>
                                                            <i  className="far fa-eye eyepassword"></i>
                                                             
                                                        </div> 
                                                    </div>  */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Is SF User</label>
                    <select className="form-control newbgselect" disabled>
                      <option
                        value=""
                        label={
                          viewFormData[0]?.is_sf_user == true ? "Yes" : "No"
                        }
                      />
                    </select>
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup position-relative">
                    <label>
                      Attachment  
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      disabled
                      value={viewFormData[0]?.image}
                    /> */}
                {/* <div className="upload-btn-wrapper up-loposition">
                      <button className="uploadBtn">Browse</button>
                      <input type="file" title="" disabled />
                    </div> */}
                {/* <div className="mt-2">
                      <a href={viewFormData[0]?.image} target="_blank">
                        <img
                          src={viewFormData[0]?.image}
                          style={{ width: "22%" }}
                        />
                      </a>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label className="d-block mb-2">
                      Block User  
                    </label>
                    <label className="logCheck d-inline-block mr-4">
                      Block
                      <input
                        type="radio"
                        name="statusupdate2"
                        checked={viewFormData[0]?.is_blocked === true}
                        disabled
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="logCheck d-inline-block">
                      UnBlock
                      <input
                        type="radio"
                        name="statusupdate2"
                        checked={viewFormData[0]?.is_blocked === false}
                        disabled
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div> */}

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label className="d-block mb-2">
                      Status  
                    </label>
                    <label className="logCheck d-inline-block mr-4">
                      Active
                      <input
                        type="radio"
                        name="statusupdate1"
                        checked={viewFormData[0]?.is_active === true}
                        disabled
                      />
                      <span className="checkmark check-cursor"></span>
                    </label>
                    <label className="logCheck d-inline-block">
                      Inactive
                      <input
                        type="radio"
                        name="statusupdate1"
                        checked={viewFormData[0]?.is_active === false}
                        disabled
                      />
                      <span className="checkmark check-cursor"></span>
                    </label>
                  </div>
                </div> */}

                <div className="row"></div>

                <div className="col-md-12 mt-4">
                  <button
                    id="dashboard_home"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    className="btn btn-outline-secondary mr-3"
                  >
                    <i className="far fa fa-home"></i>&nbsp;Home
                  </button>
                  <button
                    onClick={() => {
                      setCurrentTab("personal");
                    }}
                    className="btn btn-primary-inner bpi-main"
                  >
                    <i className="far fa-arrow-alt-circle-right"></i> Next
                  </button>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="personal"
              title={
                <React.Fragment>
                  <span>2</span> Personal Details
                </React.Fragment>
              }
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Date of Birth</label>
                    <input
                      type="text"
                      className="form-control"
                      value={moment(viewFormData[0]?.date_of_birth).format(
                        "DD-MM-YYYY"
                      )}
                      disabled
                    />
                  </div>
                </div>

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Birthday Visibility  
                    </label>
                    <select className="form-control newbgselect" disabled>
                      <option
                        label={
                          viewFormData[0]?.birthday_visible === true
                            ? "Yes"
                            : "No"
                        }
                      >
                        Select
                      </option>
                    </select>
                  </div>
                </div> */}

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Personal Email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.personal_email}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Peronsal Mobile No.</label>
                    <div class="mobile-country-drop">
                      <div className="row">
                        <div className="col-3 pr-0">
                          <select
                            className="form-control newbgselect"
                            name="business_unit_id"
                            disabled
                          >
                            <option
                              value=""
                              label={viewFormData[0]?.country__code}
                            />
                          </select>
                        </div>
                        <div className="col-9 pl-0">
                          <input
                            type="text"
                            className="form-control"
                            value={viewFormData[0]?.personal_mobile}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Blood Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.blood_group}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Marital Status</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.marital_status}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  <button
                    onClick={() => setCurrentTab("primary")}
                    type="button"
                    className="btn btn-outline-secondary mr-3"
                  >
                    <i className="far fa-arrow-alt-circle-left"></i> Previous
                  </button>
                  <button
                    type="button"
                    onClick={() => setCurrentTab("other")}
                    className="btn btn-primary-inner bpi-main"
                  >
                    <i className="far fa-arrow-alt-circle-right"></i> Next
                  </button>
                </div>
              </div>
            </Tab>
            {/* <Tab
              eventKey="Addressess"
              title={
                <React.Fragment>
                  <span>3</span> Addressess
                </React.Fragment>
              }
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Country</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        viewFormData[0]?.country_name == null
                          ? ""
                          : viewFormData[0]?.country_name +
                            " (" +
                            viewFormData[0]?.country_dial_code +
                            ")"
                      }
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>State</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option value="" label={viewFormData[0]?.state_name} />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>City</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option value="" label={viewFormData[0]?.city_name} />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>District</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option value="" label={viewFormData[0]?.district_name} />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Personal Area</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData[0]?.personal_area_desc == null
                            ? ""
                            : viewFormData[0]?.personal_area_desc +
                              "(" +
                              viewFormData[0]?.personal_area_name +
                              ")"
                        }
                      />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sub-Personal Area</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData[0]?.sub_personal_area_desc == null
                            ? ""
                            : viewFormData[0]?.sub_personal_area_desc +
                              "(" +
                              viewFormData[0]?.sub_personal_area_name +
                              ")"
                        }
                      />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.address_line_1}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Address Line 1</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.address_line_2}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Address Line 2</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.address_line_3}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Landmark</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.landmark}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Pincode</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.pincode}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <button
                    type="button"
                    onClick={() => setCurrentTab("personal")}
                    className="btn btn-outline-secondary mr-3"
                  >
                    <i className="far fa-arrow-alt-circle-left"></i> Previous
                  </button>
                  <button
                    type="button"
                    onClick={() => setCurrentTab("other")}
                    className="btn btn-primary-inner bpi-main"
                  >
                    <i className="far fa-arrow-alt-circle-right"></i> Next
                  </button>
                </div>
              </div>
            </Tab> */}
            <Tab
              eventKey="other"
              title={
                <React.Fragment>
                  <span>3</span> Other
                </React.Fragment>
              }
            >
              <div className="row">
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Company</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.ex_company}
                      disabled
                    />
                  </div>
                </div> */}
                {/* 
                

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent Address Line 1</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.perm_address_line_1}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent Address Line 2</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.perm_address_line_2}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent Address Line 3</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.perm_address_line_3}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent Country</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.perm_country_name}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent State</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.perm_state_name}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent City</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.perm_city_name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent District</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.perm_district_name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent Pincode</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.perm_pincode}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Bank Key</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.bank_key}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Bank Account Number</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.bank_account}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Payment Method</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.payment_method}
                      disabled
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Payscale group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.payment_method}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Payscale Level</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.payscalelevel}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Payscale Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.payscalegroup}
                      disabled
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.empgroup}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Login Status</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.loginstatus}
                      disabled
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Payscale group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.payscalegroup}
                      disabled
                    />
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sales Dist.</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.sales_dist}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sales Office</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.sales_off}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sales Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.sales_grp}
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created By</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.created_by}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created At</label>
                    <input
                      type="text"
                      className="form-control"
                      value={moment
                        .utc(viewFormData[0]?.created_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Modified By</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.updated_by}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated At</label>
                    <input
                      type="text"
                      className="form-control"
                      value={moment
                        .utc(viewFormData[0]?.updated_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>SAP Sync</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.sap_sync}
                      disabled
                    />
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Sub Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.emp_sub_group}
                      disabled
                    />
                  </div>
                </div>

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Business Area</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData[0]?.business_area}
                      disabled
                    />
                  </div>
                </div> */}

                <div className="col-md-12 mt-4">
                  <button
                    type="button"
                    onClick={() => setCurrentTab("Addressess")}
                    className="btn btn-outline-secondary mr-3"
                  >
                    <i className="far fa-arrow-alt-circle-left"></i> Previous
                  </button>
                  <button
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    className="btn btn-primary-inner bpi-main"
                  >
                    <i className="far fa fa-home"></i>&nbsp;Home
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
