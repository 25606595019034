import React, { useState, useEffect } from "react";
import Select from "react-select";
import * as ExpenseService from "../../service/cashbookService";
import { selectFinalUrlPermission, selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

const AddExpense = ({ setactivetab }) => {
  const dispatch = useDispatch();
  const [masterData, setMasterData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendorValue, setVendorValue] = useState();
  const [costCenterData, setCostCenterData] = useState([]);
  const [costCenterValue, setCostCenterValue] = useState();
  const [expGlData, setExpGlData] = useState([]);
  const [expGlValue, setexpGlValue] = useState();
  const userDetails = useSelector(selectUserData);
  console.log(userDetails, "kanu123");

  const [expenseItem, setExpenseItem] = useState({
    item_no: null,
    expense_gl: null,
    hsn: null,
    uom: null,
    quantity: null,
    credit: null,
    tax_rate: null,
    tax_code: null,
    base_amount: null,
    tax_amount: 0,
    total_amount: 0,
  });
  const [expenseDetail, setExpenseDetail] = useState({
    vendor_id: 0,
    gst_state: 0,
    gst_type: "",
    gst_no: "",
    vendor_address: "",
    pi_gst_no: 0,
    invoice_no: "",
    invoice_date: "",
    cost_center_id: 0,
    expense_detail: [],
    // gst_state: vendorValue?.gst_state_id,
    // gst_type: vendorValue?.gst_type,
    // gst_no: vendorValue?.gst_no,
    // vendor_address: vendorValue?.vendor_address,
  });

  const [formValidation, setFormValidation] = useState({
    vendor_id: "",
    invoice_no: "",
    invoice_date: "",
    cost_center_id: "",
    item_no: "",
  });

  const [formValidationForItem, setFormValidationForItem] = useState({
    vendor_id: "",
    invoice_no: "",
    invoice_date: "",
    cost_center_id: "",
    item_no: "",
  });
  {
    console.log(expenseDetail, "expenseDetail--");
  }
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    let regexforGst = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    let regexName = /^[A-Za-z]+$/;
    if (!expenseDetail.vendor_id) {
      isError = true;
      formerr.name = "*Vendor Id is required ";
      setFormValidation(formerr);
    }
    if (!expenseDetail.invoice_no) {
      isError = true;
      formerr.invoice_no = "Invoice Number is required";
      setFormValidation(formerr);
    }
    if (!expenseDetail.invoice_date) {
      isError = true;
      formerr.invoice_date = "*Invoice Date is required";
      setFormValidation(formerr);
    }
    if (!expenseDetail.cost_center_id) {
      isError = true;
      formerr.cost_center_id = "*Cost Center Id is required";
      setFormValidation(formerr);
    }
    if (!expenseDetail.vendor_address) {
      isError = true;
      formerr.vendor_address = "*Vendor Address is required";
      setFormValidation(formerr);
    }
    return isError;
  };
  const getMasterData = (data) => {
    ExpenseService.getMasterData(data)
      .then((response) => {
        setMasterData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  {
    console.log(expenseDetail, "expenseDetailk");
  }
  const getVendors = (paginate) => {
    ExpenseService.getVendorList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj?.id,
            label: `${obj?.name}`,
            id: obj?.id,
            gst_state: obj?.state_name,
            gst_type: obj?.gst_type,
            gst_no: obj?.gst_no,
            vendor_address: obj?.vendor_address,
          };
        });
        setVendors(res);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCostCenters = (paginate) => {
    ExpenseService.getCostCenterList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj?.id,
            label: `${obj?.cost_center}`,
          };
        });
        setCostCenterData(res);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const pushVendorId = (e) => {
    setVendorValue(e);
    console.log(e, "kk");
    setExpenseDetail({
      ...expenseDetail,
      vendor_id: e.value,
      gst_state: e.gst_state,
      gst_type: e.gst_type,
      gst_no: e.gst_no,
      vendor_address: e.vendor_address,
    });
  };
  const pushCostCenter = (e) => {
    setCostCenterValue(e);
    setExpenseDetail({
      ...expenseDetail,
      cost_center_id: e.value,
    });
  };
  const addRecord = () => {
    let newExpenseDetailItem = expenseDetail;
    setExpenseDetail((prev) => ({
      ...prev,
      expense_detail: [...prev.expense_detail, expenseItem],
    }));
    setExpenseItem({
      item_no: null,
      expense_gl: null,
      hsn: null,
      uom: null,
      quantity: null,
      credit: null,
      tax_rate: null,
      tax_code: null,
      base_amount: null,
      tax_amount: 0,
      total_amount: 0,
    });
  };

  useEffect(() => {
    getMasterData();
    getVendors(false);
    getCostCenters(false);
  }, []);

  return (
    <div>
      <div class="px-4 pb-2">
        <div class="tickethistorbg mt-2">
          <div class="row viewformaftersubmit">
            <div class="col-md-3">
              <div class="innergroup">
                <p>Cashbook </p>
                <label>Cash in Hand - {masterData[0]?.state_name ?? "-"}</label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="innergroup">
                <p>Opening Balance</p>
                <label>
                  <i class="fas fa-rupee-sign mr-1"></i> INR{" "}
                  {masterData[0]?.opening_balance ?? "-"}
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="innergroup">
                <p>Current Balance</p>
                <label>
                  <i class="fas fa-rupee-sign mr-1"></i> INR{" "}
                  {masterData[0]?.current_balance ?? "-"}
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="innergroup">
                <p>Provision Balance</p>
                <label>
                  <i class="fas fa-rupee-sign mr-1"></i> INR{" "}
                  {masterData[0]?.provision_balance ?? "-"}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group innergroup">
              <label>
                Vendor<span class="text-danger">*</span>
              </label>
              <Select
                options={vendors}
                className="basic-multi-select"
                classNamePrefix="select"
                name="vendor_id"
                onChange={(e) => {
                  console.log(e, "kiu");
                  pushVendorId(e);
                  setFormValidation({
                    ...formValidation,
                    vendor_id: "",
                  });
                }}
                value={vendorValue}
              />
              <div className="small text-danger">
                {formValidation.vendor_id}
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group innergroup">
              <label>
                GST State<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={vendorValue?.gst_state}
                placeholder="Enter GST Type"
              />
              {/* <select class="form-control newbgselect" disabled>
                <option value="">01 Jammu and Kashmir</option>
              </select> */}
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group innergroup">
              <label>
                GST Type<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                disabled
                value={vendorValue?.gst_type}
                placeholder="Enter GST Type"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group innergroup">
              <label>
                GST No<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                disabled
                value={vendorValue?.gst_no}
                placeholder="Enter GST No"
                // onChange={(e) => {
                //   setExpenseDetail({
                //     ...expenseDetail,
                //     gst_no: e.target.value,
                //   });
                // }}
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group innergroup">
              <label>
                Address<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                value={vendorValue?.vendor_address}
                disabled
                placeholder="Enter Address"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group innergroup">
              <label>
                Business Place GST No<span class="text-danger">*</span>
              </label>
              <select class="form-control newbgselect">
                <option value="">22AABCP2183M1ZF-Chattisgarh</option>
              </select>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group innergroup">
              <label>
                Invoice No<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Invoice Number"
                name="invoice_no"
                maxLength={15}
                onChange={(e) => {
                  setExpenseDetail({
                    ...expenseDetail,
                    invoice_no: e.target.value,
                  });
                  setFormValidation({
                    ...formValidation,
                    invoice_no: "",
                  });
                }}
                value={expenseDetail?.invoice_no}
              />
              <div className="small text-danger">
                {formValidation.invoice_no}
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group innergroup">
              <label>
                Invoice Date<span class="text-danger">*</span>
              </label>
              <input
                type="date"
                class="form-control"
                name="invoice_date"
                onChange={(e) => {
                  setExpenseDetail({
                    ...expenseDetail,
                    invoice_date: e.target.value,
                  });
                  setFormValidation({
                    ...formValidation,
                    invoice_date: "",
                  });
                }}
                value={expenseDetail?.invoice_date}
              />
              <div className="small text-danger">
                {formValidation.invoice_date}
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group innergroup">
              <label>
                Expense Cost Centre<span class="text-danger">*</span>
              </label>
              <Select
                options={costCenterData}
                className="basic-multi-select"
                classNamePrefix="select"
                name="cost_center_id"
                onChange={(e) => {
                  pushCostCenter(e);
                  setFormValidation({
                    ...formValidation,
                    cost_center_id: "",
                  });
                }}
                value={costCenterValue}
              />
              <div className="small text-danger">
                {formValidation.cost_center_id}
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group innergroup">
              <label>
                Amount<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                disabled
                value="0.00"
                class="form-control"
                placeholder="Enter Amount"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mb-2 ml-4">
        <h6 class="text-theme mb-0">
          <i class="far fa-arrow-alt-circle-right"></i> <strong>Items</strong>
        </h6>
      </div>

      <div class="table-responsive">
        <table class="table table-striped table-bordered tablecured viewpo">
          <thead>
            <tr>
              <th>Action</th>
              <th>Item</th>
              <th>Expense GL</th>
              <th>HSN Code</th>
              <th>UOM</th>
              <th>Quantity</th>
              <th>GST Credit</th>
              <th>Tax Rate</th>
              <th>Tax Code</th>
              <th>Base Amount</th>
              <th>Tax Amount</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <button class="btn btn-blanktd text-primary f-12">
                  <i class="far fa-edit"></i>
                </button>
                <button class="btn btn-blanktd text-danger ml-2 f-12">
                  <i class="far fa-trash-alt text-danger"></i>
                </button>
              </td>
              <td>
                <span class="badge badge-secondary">10</span>
              </td>
              <td>388019 - PRofessioanl Tax Payable</td>
              <td>996812 - Courier</td>
              <td>Others</td>
              <td>10</td>
              <td>Yes</td>
              <td>5%</td>
              <td>98765</td>
              <td>0.00</td>
              <td>0.00</td>
              <td>0.00</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="px-4 pb-2">
        <div class="row align-items-center">
          <div class="col-md-12 text-sm-right">
            <button class="btn btnblanktd text-primary" onClick={addRecord}>
              <i class="far fa-plus-square mr-2"></i> Add Item More
            </button>
          </div>
        </div>

        <div class="bg-light px-3 pt-3 mb-3">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Item<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Item"
                  name="item_no"
                  maxLength={15}
                  onChange={(e) => {
                    setExpenseItem({
                      ...expenseItem,
                      item_no: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      item_no: "",
                    });
                  }}
                  value={expenseDetail?.item_no}
                />
                <div className="small text-danger">
                  {formValidation.item_no}
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Expense GL<span class="text-danger">*</span>
                </label>
                <Select
                  options={costCenterData}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="expense_gl"
                  onChange={(e) => {
                    pushCostCenter(e);
                    setFormValidation({
                      ...formValidation,
                      expense_gl: "",
                    });
                  }}
                  value={costCenterValue}
                />
                <div className="small text-danger">
                  {formValidation.expense_gl}
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  HSN Code<span class="text-danger">*</span>
                </label>
                <Select
                  options={options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  UOM<span class="text-danger">*</span>
                </label>
                <select class="form-control newbgselect">
                  <option value="">Select</option>
                  <option value="2">Others</option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Quantity<span class="text-danger">*</span>
                </label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter Quantity"
                  maxLength={15}
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  GST Credit<span class="text-danger">*</span>
                </label>
                <select class="form-control newbgselect">
                  <option value="">Select</option>
                  <option value="2">Yes</option>
                  <option value="4">No</option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Tax Rate<span class="text-danger">*</span>
                </label>
                <select class="form-control newbgselect">
                  <option value="">Select</option>
                  <option value="2">0%</option>
                  <option value="2">5%</option>
                  <option value="2">12%</option>
                  <option value="2">18%</option>
                  <option value="4">28%</option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Tax Code</label>
                <input
                  type="text"
                  disabled
                  class="form-control"
                  placeholder="Enter Tax Code"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Base Amount<span class="text-danger">*</span>
                </label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter Base Amount"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Tax Amount<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  value="0.00"
                  class="form-control"
                  placeholder="Enter Tax Amount"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Total Amount<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  value="0.00"
                  class="form-control"
                  placeholder="Enter Total Amount"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="row">
            <div class="col-md-12 mt-3 text-center">
              <button class="btn btn-outline-secondary mr-2">Cancel</button>
              <button class="btn btn-outline-primary mr-2">
                Save as Draft
              </button>
              <button class="btn btn-primary-inner bpi-main">
                Add to Cashbook
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpense;
