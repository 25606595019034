import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getMyidea = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/my-idea?${queryParm}`);
};
export const getMyideaCount = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/my-idea/count?${queryParm}`);
};
export const saveMyidea = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/my-idea`, data);
};
export const getUserList = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/users?paginate=${paginate}`);
};
export const saveComment = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/my-idea/comment`, data);
};
export const deleteComment = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/my-idea/delete-comment`, data);
};
export const updateMyidea = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/my-idea`, data);
};

export const makeUserBspoc = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/my-idea/assignBspoc`, data);
};

export const getAssignedBspoc = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/my-idea/getBspoc?${queryParam}`);
};

export const removeUserAsBspoc = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/my-idea/removeBspoc`, data);
};

export const getAreaAndScope = async () => {
  return axios.get(`${AUTH_BASE_URL}/my-idea/idea-area-scope/list`);
};

export const assignDuration = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/my-idea/impDuration`, data);
};

export const getUsersByOrg = async () => {
  return axios.get(`${AUTH_BASE_URL}/my-idea/userByOrg`);
};

export const getExportValueData = async (queryParm) => {
  return axios({
      url: `${AUTH_BASE_URL}/my-idea/export?${queryParm}`,
      method: "GET",
      responseType: "blob",
  });
};

export const getImpHistory = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/my-idea/imp_history?${queryParm}`);
};

export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const getRolePermission = async () => {
  return axios.get(`${AUTH_BASE_URL}/my-idea/idea_role_permission`);
};

export const getIdeaObjectiveResult = async (value) => {
  return axios.get(`${AUTH_BASE_URL}/my-idea/get_idea_objective?objective=${value}`);
}

export const postVoteToIdea = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/my-idea/vote`, data);
};