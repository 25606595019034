import axios from "../../utils/axios";
 import { AUTH_BASE_URL } from "../../constants/URL";


  export const getPlant = async () => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_plant_master`);
    
  };
  
  export const savePlant = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/lr_gr_plant_master`, data);
  };
  
  export const updatePlant = async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/lr_gr_plant_master/${id}`, data);
  };
  
  export const deletePlant = async (id) => {
    return axios.delete(`${AUTH_BASE_URL}/lr_gr_plant_master/${id}`);
  };

  export const getBranch = async (paginate) => {
    return axios.get(
      `${AUTH_BASE_URL}/business_units?paginate=${paginate}&sort_by=name`
    );
  };
  
  export const isPlantExists = async (isData) => {
    return axios.post(`${AUTH_BASE_URL}/lr_gr_plant_master/exists`, isData);
  };
  
  export const getAllPlant = async (paginate) => {
    return axios.get(
      `${AUTH_BASE_URL}/lr_gr_plant_master?paginate=${paginate}&sort_by=name`
    );
  };
  
  export const getExportValue = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/lr_gr_plant_master/export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };
  