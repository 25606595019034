import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import * as HeaderService from "../../service/jv_service";
import { Alert } from "react-bootstrap";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";

const JvRmApproval = (props) => {
  const nameForm = "Add RM Approval Form";
  const { edit, showForm, onClose, editDepartment, onAddCallBack } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [jvRmList, setjvRmList] = useState([]);
  const [alertValidate, setAlertValidate] = useState(false);
  const [userValue, setUserValue] = useState([]);
  const [rmValue, setRmValue] = useState([]);
  const [jvApprovalData, setJvApprovalData] = useState({
    rm_id: "",
    user_id: "",
  });
  const [formValidation, setFormValidation] = useState({
    rm_id: "",
    user_id: "",
  });

  const addJvRmApproval = (values) => {
    setIsLoading(true);
    HeaderService.addJvRmApproval(values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.detail[0].msg);
        }
        setIsLoading(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateJvRmApproval = (values) => {
    setIsLoading(true);
    HeaderService.updateJvRmApproval(editDepartment.id, values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.detail[0].msg);
        }
        setIsLoading(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleError = () => {
    let isError = false;
    const formerr = { ...formValidation };
    if (!jvApprovalData.rm_id) {
      isError = true;
      formerr.rm_id = "*Req Manager is Required";
      setFormValidation(formerr);
    }
    if (!jvApprovalData.user_id) {
      isError = true;
      formerr.user_id = "*User is Required";
      setFormValidation(formerr);
    }
    return isError;
  };

  const getUserJvRmList = (paginate, sort_by) => {
    HeaderService.getUserJvRmList(paginate, sort_by)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data2 = {
            value: x.id,
            label: x.first_name + " " + x.last_name + "( " + x.username + ")",
          };
          data.push(data2);
        });
        setjvRmList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const pushUserList = (e) => {
    setUserValue(e);
    setJvApprovalData({
      ...jvApprovalData,
      user_id: e.value,
    });
  };
  const pushUserData = (e) => {
    setRmValue(e);
    setJvApprovalData({
      ...jvApprovalData,
      rm_id: e.value,
    });
  };

  const handleValidate = (data) => {
    if (edit === true && data.id) {
      updateJvRmApproval(data);
    } else {
      addJvRmApproval(data);
    }
  };

  function dataSave() {
    if (handleError()) return false;
    let data = {
      id: editDepartment?.id,
      rm_id: Number(jvApprovalData.rm_id),
      user_id: Number(jvApprovalData.user_id),
    };
    handleValidate(data);
  }

  useEffect(() => {
    if (editDepartment) {
      let datas = editDepartment;
      let data1 = {
        value: datas?.rm_id?.id,
        label: datas?.rm_id?.first_name + " " + datas?.rm_id?.last_name + " ",
      };
      setRmValue(data1);
      let data2 = {
        value: datas?.user_id?.id,
        label:
          datas?.user_id?.first_name + " " + datas?.user_id?.last_name + " ",
      };
      setUserValue(data2);
      Object.keys(editDepartment).forEach((item) => {
        if (item == "user_id" || item == "rm_id") {
          jvApprovalData[item] = editDepartment[item].id;
        }
      });
    }
  }, [editDepartment]);

  useEffect(() => {
    getUserJvRmList(false, "first_name");
  }, []);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Modal
        show={showForm}
        onHide={onClose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{edit === true ? "Edit" : "Add"} JV RM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Select User
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="user_id"
                      options={jvRmList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      onChange={(e) => {
                        pushUserList(e);
                        setFormValidation({
                          ...formValidation,
                          user_id: "",
                        });
                      }}
                      value={userValue}
                    />
                    <div className="small text-danger">
                      {formValidation.user_id}
                    </div>{" "}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Select Reporting Manager
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="rm_id"
                      options={jvRmList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      onChange={(e) => {
                        pushUserData(e);
                        setFormValidation({
                          ...formValidation,
                          rm_id: "",
                        });
                      }}
                      value={rmValue}
                    />
                    <div className="small text-danger">
                      {formValidation.rm_id}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {alertValidate && (
          <Alert variant="danger" className="mb-5 mt-2">
            <i className="fa fa-exclamation-triangle"></i> User cannot have
            there own repoting manager
          </Alert>
        )}
        <Modal.Footer>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary-inner bpi-main"
                onClick={() => dataSave()}
              >
                Submit
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JvRmApproval;
