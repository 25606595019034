import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as userGl from "../../service/userGl";
import { toast, ToastContainer } from "react-toastify";
import * as ActivePoServices from "../../service/activePo";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";

const VendorCardMapping = (props) => {
  const dispatch = useDispatch();
  const { edit, show, onCloseButtonClick, editUserCardData, onAddCallBack } =
    props;
  const [allEmployee, setAllEmployee] = useState([]);
  const [vendorDataList, setVendorDataList] = useState([]);
  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const [vendorValue, setVendorValue] = useState();
  const [cardData, setCardData] = useState({
    user_id: 0,
    vendor_id: null,
    card_no: "",
    is_active: true,
  });
  const [error, setError] = useState({
    user_id: "",
    vendor_id: "",
    card_no: "",
  });

  const [uploadAttachments, setUploadAttachments] = useState({
    attachment_path: [],
  });

  const [imgSize, setImgSize] = useState(false);
  const [link, setLink] = useState(null);


  const onProfileChange = async (e, flag) => {
    let filesArr = e.target.files
    let _attachment_path=[]
    // console.log(`files`,filesArr);

    for(let i=0;i<filesArr.length;i++) {
      const files = filesArr[i];

      // console.log(`filing`,files);

    if (
      (files.size < 1033415 || files.size === 1033415) &&
      (files?.type === "application/pdf" ||
        files?.type === "image/jpeg" ||
        files?.type === "image/jpg" ||
        files?.name.includes(".msg"))
    ) {
      setLink(undefined);
      setImgSize(false);
      const fileData = new FormData();
      fileData.append("uploaded_file", files);
      dispatch(setDisplayLoader("Display"));
      await userGl.uploadAttachment(fileData).then((response) => {
        dispatch(setDisplayLoader("Hide"));
        if (flag === "attachment_path") {
          // console.log(`response`,response.data);
          _attachment_path.push(response.data)
          
        }
      });
    } else {
      setImgSize(true);
    }
  }

  setUploadAttachments((pre)=>({
    ...pre,
    attachment_path:_attachment_path
  }));

  // console.log('_att',_attachment_path);
  };

  const getUsers = (paginate) => {
    userGl
      .getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
            record: x?.username,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const blockInvalidChar = (e) =>
    [
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "+",
      "|",
      ":",
      "<",
      ">",
      "!",
      "e",
    ].includes(e.key) && e.preventDefault();


    const isPettyUserExists = (dataa) => {
      dataa["attachment_path"]=uploadAttachments?.attachment_path

      userGl
        .isPettyUserExists("user_id="+dataa.user_id,"")
        .then((response) => {

          console.log(`res..`,response);
          if(response?.data?.dataList?.is_exist){

            toast.error("User already exists")
          }
          else{
            saveOtherExpensePetty(cardData);

          }


          
          
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    };

  const saveOtherExpensePetty = (values) => {
    userGl
      .saveOtherExpensePetty(values)
      .then((response) => {
        if (response.data.code == 200) {
          onAddCallBack(response);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateOtherExpensePetty = (id, values) => {
    let datass = values;
    datass["attachment_path"]=uploadAttachments?.attachment_path
    userGl
      .updateOtherExpensePetty(id, datass)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getVendorId = (id) => {
    ActivePoServices.getVendorId(id)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: `${x?.name} (${x?.code})`,
          };
          plant.push(data);
        });
        setVendorDataList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...error };
    if (cardData.card_no?.length>0 && !cardData.vendor_id) {
      isError = true;
      formerr.vendor_id = "*Vendor Name is required  ";
      setError(formerr);
    }
    if (cardData.vendor_id && !cardData.card_no) {
      isError = true;
      formerr.card_no = " *Crad Number is required ";
      setError(formerr);
    }
    if (!cardData.user_id) {
      isError = true;
      formerr.user_id = " *User Id is required ";
      setError(formerr);
    }
    return isError;
  };

  const handleChange = (event, name, regex, length) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "vendor_id":
        setCardData((prev) => ({
          ...prev,
          vendor_id: event.value,
        }));
        break;
      case "user_id":
        setCardData((prev) => ({
          ...prev,
          user_id: event.value,
        }));

        break;
      case "card_no":
        setCardData((prev) => ({
          ...prev,
          card_no: event.target.value,
        }));
        break;
      case "is_active":
        setCardData((prev) => ({
          ...prev,
          is_active: event,
        }));
        break;
      default:
        break;
    }
  };

  const pushUsers = (newValue, name) => {
    setAllEmployeeData(newValue);
    handleChange(newValue, name);
  };
  const pushDataVendor = (newValue, name) => {
    setVendorValue(newValue);
    handleChange(newValue, name);
  };

  const handleSubmit = () => {
    if (handleValidate()) return false;
    if (cardData.id === undefined) {
      isPettyUserExists(cardData)
      
    } else {
      updateOtherExpensePetty(cardData.id, cardData);
    }
  };

  useEffect(() => {
    getUsers(false);
    getVendorId();
  }, []);

  useEffect(() => {
    if (editUserCardData) {
      let userCode = {
        value: editUserCardData.user_id,
        label:
          editUserCardData?.user_name +
          " " +
          "(" +
          editUserCardData?.user_code +
          ")",
      };

      let vendorItem = [];
      let vendorData = {
        value: editUserCardData.vendor_id,
        label: editUserCardData.vendor_name,
      };
      vendorItem.push(vendorData);
      setVendorValue(vendorItem);

      setAllEmployeeData(userCode);
      Object.keys(editUserCardData).forEach((item) => {
        cardData[item] = editUserCardData[item];
      });
    }
  }, [editUserCardData]);

  return (
    <>
      <Modal
        show={show}
        onHide={onCloseButtonClick}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {edit === true ? "Edit" : "Add"} Access to Petty user
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row justify-content-center">
            <ToastContainer autoClose={1000} />
            <div class="col-md-11">
              <div class="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      User Code<span className="text-danger">*</span>
                    </label>
                    <Select
                      name="user_id"
                      options={allEmployee}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        pushUsers(newValue, action?.name);
                      }}
                      value={allEmployeeData}
                    />
                    <div className="small text-danger">{error.user_id}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Vendor Name
                      {/* <span class="text-danger">*</span> */}
                    </label>
                    <Select
                      options={vendorDataList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="vendor_id"
                      onChange={(newValue, action) => {
                        pushDataVendor(newValue, action?.name);
                      }}
                      onInputChange={(e) => {
                        getVendorId(e);
                      }}
                      value={vendorValue}
                    />
                    <div className="small text-danger">{error.vendor_id}</div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Card No
                      {/* <span class="text-danger">*</span> */}
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Enter Card No"
                      name="card_no"
                      onKeyDown={blockInvalidChar}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      value={cardData.card_no}
                    />
                    <div className="small text-danger">{error.card_no}</div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label className="d-block">Status</label>
                    <label
                      className="logCheck d-inline-block mr-4"
                      htmlFor="active"
                    >
                      Active
                      <input
                        type="radio"
                        name="is_active"
                        id="active"
                        value={cardData.is_active}
                        onChange={(e) => {
                          handleChange(true, "is_active");
                        }}
                        checked={cardData.is_active == true}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      className="logCheck d-inline-block"
                      htmlFor="Inactive"
                    >
                      Inactive
                      <input
                        type="radio"
                        name="is_active"
                        id="Inactive"
                        value={cardData.is_active}
                        onChange={(e) => {
                          handleChange(false, "is_active");
                        }}
                        checked={cardData.is_active == false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group innergroup position-relative modaldefaultclose ">
                    <label>
                      Upload files <span className="text-danger"></span>
                    </label>

                    {/* <input
                      type="text"
                      className="form-control bg-white"
                      value={uploadAttachments?.attachment_path?.original_name}
                      disabled
                      placeholder="(Image, PDF, MSG Format)"
                    />
                     */}
                    <input
                      type="text"
                      className="form-control bg-white"
                      // value={uploadAttachments?.attachment_path?.original_name}
                      disabled
                      placeholder="(Image, PDF, MSG Format)"
                    />
                    

                    <div className="upload-btn-wrapper up-loposition">
                      <button className="uploadBtn">Browse</button>
                      <input
                        type="file"
                        multiple
                        // id="attachment_path"
                        // name="attachment_path"
                        accept=".msg, .pdf, .jpg"
                        onChange={(e) => onProfileChange(e, "attachment_path")}
                      />
                    </div>
                    

                    {imgSize ? (
                      <div className="small text-danger">
                        File size should not exceed 1mb
                      </div>
                    ) : null}
                    {uploadAttachments?.attachment_path?.length >0 && (
                      <p>
                        Selected files{" "}
                        {/* <span>
                          {" "}
                          {uploadAttachments?.attachment_path?.original_name}
                        </span> */}

                        { uploadAttachments?.attachment_path?.map((at_data,_idx)=>(
                          <span >
                          {at_data?.original_name+(uploadAttachments?.attachment_path?.length!=_idx+1?", ":"")}
                        </span>
                        ))}

                      </p>
                    )}
                    
                    {/* {link !== undefined && (
                      <p>Selected file {<span> {link}</span>}</p>
                    )} */}

                     {/* { 
                         editGlData ?
                        
                         editGlData?.attachment?.map((adata,_idx)=>(
                           <div>{_idx+1}.<Link target="_blank" to={adata?.attachment_path}>
                           View attachment
                         </Link></div>)):""
                      } */}
                    
                  </div>
                </div>


              </div>

              <div className="row">
              {
                        editUserCardData && editUserCardData?.attachment?.length>0?
                        <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                            <thead>
                                <tr>
                              
                                  <th>FILE NAME	</th>
                                  <th>VIEW</th>
                                
                                </tr>
                            </thead>
                            <tbody>
                            {editUserCardData?.attachment?.map((adata,idx)=>(
                                <tr>           
                                  <td>{adata?.attachment_path?.substring(adata?.attachment_path?.lastIndexOf("/")+1)}</td>
                                  <td>
                                      <Link target="_Blank" to={adata?.attachment_path}>View</Link>
                                  </td>
                                </tr>
                                
                            ))}
                            </tbody>
                           </table>
                        :""
                      }

              </div>

              
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <>
            <button
              class="btn btn-outline-secondary mr-2"
              onClick={() => onCloseButtonClick()}
            >
              Cancel
            </button>
            <button
              class="btn btn-primary-inner bpi-main"
              type="submit"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendorCardMapping;
