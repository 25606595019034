import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import * as agreement from "../../service/agree-terms";
import { useDispatch } from "react-redux";
import moment from "moment";

function SocialPolicy(props) {
  const Location = useLocation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [isChecked, setisChecked] = useState(false);

  const getQuaterlyChecks = () => {
    let queryParam = `curMonth=${moment(new Date()).month() + 1}`;
    agreement.quaterlyTerms(queryParam).then((response) => {
      if (response?.status === 200) {
        if (response?.data?.dataList?.checked === true) {
          Navigate("/dashboard");
        } else {
          Navigate("/quater-terms", {
            state: {
              component: "dashboard",
            },
          });
        }
      }
    });
  };

  const acceptTerms = (event) => {
    event.preventDefault();
    let payload = {
      accepted_social: true,
    };

    agreement
      .acceptTerms(payload)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          if (Location?.state?.component == "quater-terms") {
            getQuaterlyChecks();
          }
          if (Location?.state?.component == "mandatory") {
            Navigate("/mandatory");
          }
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      <div className="login-bg">
        <div className="container">
          <div className="row justify-content-between my-3">
            <div className="col-md-2 d-flex flex-column text-center text-sm-left">
              <div>
                <img src={Logo} alt="" height="55px" />
              </div>
            </div>
            <div className="col-md-10 col-lg-9 mt-3 mt-sm-0">
              <div className="loginTabs">
                <div className="tabfliping agreebox">
                  <h3>Social Media Alert !</h3>
                  <form onSubmit={(e) => acceptTerms(e)}>
                    <div className="agreecontent">
                      <div>
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <a
                            href="https://www.piindustries.com/Media/Documents/Final_SM%20Policy.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="thumbnail_image001.jpg"
                              width="100%"
                              alt=""
                              class="cursor-pointer"
                            />
                          </a>
                        </div>
                      </div>
                      <div>
                        <label className="logCheck d-inline-block mr-4">
                          Please accept our *T&C
                          <input
                            type="checkbox"
                            name="termscondition"
                            onChange={() => {
                              setisChecked((prev) => !prev);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary agreebtn"
                      type="submit"
                      disabled={!isChecked}
                    >
                      Proceed
                    </button>
                    {/* <Link to="/mandatory" className="btn btn-primary agreebtn"
                                        
                                        >Proceed</Link>  */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialPolicy;
