import React, { useState, useEffect } from "react";
import AddHotel from "./AddHotel";
import * as TravelListingAPI from "../../service/travelService";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { injectStyle } from "react-toastify/dist/inject-style";
import AddHotelChild from "./AddHotelChild";
if (typeof window !== "undefined") {
  injectStyle();
}

const Hotel = (props) => {
  const { checkActiveTab, dataNew, setCheckActiveTab } = props;
  const { state } = useLocation();
  const [hotelDataValue, setHotelDataValue] = useState([]);
  const [isEdit, setIsEdit] = useState("false");
  const [hotelList, setHotelList] = useState([]);
  const [deleteHotelData, setDeleteHotelData] = useState([]);
  const [isDelete, setIsDelete] = useState("false");
  const [getTotalAmt, setGetTotalAmount] = useState([]);
  const [hotelListDataTwo, setHotelListDataTwo] = useState([]);
  const [att, setAtt] = useState();
  const [ss, setSS] = useState(false);
  const getHotelList = async (data) => {
    await TravelListingAPI.getHotelList(data)
      .then((response) => {
        let res = response?.data?.dataList?.result;
        if (res?.length > 0) {
          setIsEdit(true);
        }
        setHotelList(res);
        setAtt(response?.data);
        let res1 = response?.data?.dataList;
        setGetTotalAmount(res1);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getHotelListTwo = async (travel_id) => {
    await TravelListingAPI.getHotelListTwo(travel_id)
      .then((response) => {
        let res = response?.data?.dataList?.result;
        setHotelListDataTwo(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteCountry = (id, idx) => {
    let dataId = { id: idx };
    TravelListingAPI.deleteHotel(id, dataId)
      .then((res) => {
        if (res.data.status == true) {
          getHotelList(state?.id);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    getHotelList(state?.id);
    getHotelListTwo(state?.id);
  }, []);

  return (
    <>
      <AddHotel
        hotelDataValue={hotelDataValue}
        isEdit={isEdit}
        hotelList={hotelList}
        deleteHotelData={deleteHotelData}
        isDelete={isDelete}
        getHotelList={getHotelList}
        att={att}
        setIsDelete={setIsDelete}
        getTotalAmt={getTotalAmt}
        dataNew={dataNew}
        setCheckActiveTab={setCheckActiveTab}
      />
   
    </>
  );
};

export default Hotel;
