import React, { useState, useEffect } from "react";
import * as VisitorService from "./visitor_service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import Select from "react-select";

function VisitorDetailsForm() {
  let qry = new URLSearchParams(window.location.search);
  const encodedKey = qry.get("visitor_info_id");
  const visitor_info_id = atob(encodedKey);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [other_remark, setOther_remark] = useState("");
  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState([
    {
      guest_name: "",
      guest_email: "",
      guest_address: "",
      apron_size: "",
      country: "",
      gender: "",
      shoes_size: "",
      shoes_size_value: [],
      food_category: "",
      spice_level: "",
      other_restrictions: "",
    },
  ]);

  const [visiting_date, setVisiting_date] = useState("");
  const [visitingSiteData, setVisitingSiteData] = useState([]);
  const menShoeSizesData = {
    US: [
      { value: "6", label: "6" },
      { value: "6.5", label: "6.5" },
      { value: "7", label: "7" },
      { value: "7.5", label: "7.5" },
      { value: "8", label: "8" },
      { value: "8.5", label: "8.5" },
      { value: "9", label: "9" },
      { value: "9.5", label: "9.5" },
      { value: "10", label: "10" },
      { value: "10.5", label: "10.5" },
      { value: "11", label: "11" },
      { value: "11.5", label: "11.5" },
      { value: "12", label: "12" },
      { value: "13", label: "13" },
      { value: "14", label: "14" },
      { value: "15", label: "15" },
    ],
    UK: [
      { value: "5", label: "5" },
      { value: "5.5", label: "5.5" },
      { value: "6", label: "6" },
      { value: "6.5", label: "6.5" },
      { value: "7", label: "7" },
      { value: "7.5", label: "7.5" },
      { value: "8", label: "8" },
      { value: "8.5", label: "8.5" },
      { value: "9", label: "9" },
      { value: "9.5", label: "9.5" },
      { value: "10", label: "10" },
      { value: "10.5", label: "10.5" },
      { value: "11", label: "11" },
      { value: "12", label: "12" },
      { value: "13", label: "13" },
      { value: "14", label: "14" },
    ],
    EU: [
      { value: "40", label: "40" },
      { value: "41", label: "41" },
      { value: "42", label: "42" },
      { value: "43", label: "43" },
      { value: "44", label: "44" },
      { value: "45", label: "45" },
      { value: "46", label: "46" },
      { value: "47", label: "47" },
      { value: "48", label: "48" },
      { value: "49", label: "49" },
    ],
    Japan: [
      { value: "24.5", label: "24.5" },
      { value: "25", label: "25" },
      { value: "25.5", label: "25.5" },
      { value: "26", label: "26" },
      { value: "26.5", label: "26.5" },
      { value: "27", label: "27" },
      { value: "27.5", label: "27.5" },
      { value: "28", label: "28" },
      { value: "28.5", label: "28.5" },
      { value: "29", label: "29" },
      { value: "30", label: "30" },
    ],
  };

  const womenShoeSizesData = {
    US: [
      { value: "4", label: "4" },
      { value: "4.5", label: "4.5" },
      { value: "5", label: "5" },
      { value: "5.5", label: "5.5" },
      { value: "6", label: "6" },
      { value: "6.5", label: "6.5" },
      { value: "7", label: "7" },
      { value: "7.5", label: "7.5" },
      { value: "8", label: "8" },
      { value: "8.5", label: "8.5" },
      { value: "9", label: "9" },
      { value: "9.5", label: "9.5" },
      { value: "10", label: "10" },
      { value: "10.5", label: "10.5" },
      { value: "11", label: "11" },
      { value: "11.5", label: "11.5" },
      { value: "12", label: "12" },
      { value: "12.5", label: "12.5" },
      { value: "13", label: "13" },
      { value: "13.5", label: "13.5" },
      { value: "14", label: "14" },
      { value: "15", label: "15" },
    ],
    UK: [
      { value: "2", label: "2" },
      { value: "2.5", label: "2.5" },
      { value: "3", label: "3" },
      { value: "3.5", label: "3.5" },
      { value: "4", label: "4" },
      { value: "4.5", label: "4.5" },
      { value: "5", label: "5" },
      { value: "5.5", label: "5.5" },
      { value: "6", label: "6" },
      { value: "6.5", label: "6.5" },
      { value: "7", label: "7" },
      { value: "7.5", label: "7.5" },
      { value: "8", label: "8" },
      { value: "8.5", label: "8.5" },
      { value: "9", label: "9" },
      { value: "9.5", label: "9.5" },
      { value: "10", label: "10" },
      { value: "10.5", label: "10.5" },
      { value: "11", label: "11" },
      { value: "11.5", label: "11.5" },
      { value: "12", label: "12" },
      { value: "13", label: "13" },
    ],
    EU: [
      { value: "35", label: "35" },
      { value: "36", label: "36" },
      { value: "37", label: "37" },
      { value: "38", label: "38" },
      { value: "39", label: "39" },
      { value: "40", label: "40" },
      { value: "41", label: "41" },
      { value: "42", label: "42" },
      { value: "43", label: "43" },
      { value: "45", label: "45" },
      { value: "45.5", label: "45.5" },
      { value: "46", label: "46" },
      { value: "47", label: "47" },
    ],
    Japan: [
      { value: "22.5", label: "22.5" },
      { value: "23", label: "23" },
      { value: "23.5", label: "23.5"},
      { value: "24", label: "24" },
      { value: "24.5", label: "24.5"},
      { value: "25", label: "25" },
      { value: "25.5", label: "25.5"},
      { value: "26", label: "26" },       
    ],
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    
    VisitorService.get_response_submission_status(visitor_info_id).then(
      (response) => {
        if (response?.data?.response_submitted === true) {
          navigate("/thankyou-page");
          dispatch(setDisplayLoader("Hide"));
        } else {
          setVisiting_date(response?.data?.visiting_date);
          setVisitingSiteData(response?.data?.visiting_sites)
          setInputs([
            {
              guest_name: response?.data?.contact_person,
              guest_email: response?.data?.email,
              guest_address: "",
              apron_size: "",
              country: "",
              gender: "",
              shoes_size: "",
              food_category: "",
              spice_level: "",
              other_restrictions: "",
            },
          ]);
          navigate(`/visitor-details?visitor_info_id=${encodedKey}`);
          dispatch(setDisplayLoader("Hide"));
        }
      }
    );
  }, []);

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        guest_name: "",
        guest_email: "",
        guest_address: "",
        apron_size: "",
        country: "",
        gender: "",
        shoes_size: "",
        food_category: "",
        spice_level: "",
        other_restrictions: "",
      },
    ]);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const handleChange = (event, index, passed_value, passed_name) => {
    let onChangeValue = [...inputs];
    if (passed_name === "shoes_size") {
      onChangeValue[index]["shoes_size_value"] = event;
      onChangeValue[index][passed_name] = passed_value;
      setInputs(onChangeValue);
      // setErrors({ ...errors, [name]: "" });
    } else if (
      passed_name === "food_category" ||
      passed_name === "spice_level" ||
      passed_name === "other_restrictions" ||
      passed_name === "gender"
    ) {
      let { name, value } = event.target;
      onChangeValue[index][passed_name] = passed_value;
      setInputs(onChangeValue);
      setErrors({ ...errors, [name]: "" });
    } else {
      let { name, value } = event.target;
      onChangeValue[index][name] = value;
      setInputs(onChangeValue);
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validateInputs = () => {
    const newErrors = {};

    inputs.forEach((input, index) => {
      Object.keys(input).forEach((key) => {
        if (typeof input[key] == "string" && input[key].trim() === "") {
          newErrors[`${key}_${index}`] = `${key
            .split("_")
            .join(" ")} is required`;
        }
      });
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
    // Returns true if no errors
  };

  const saveVisitorResponse = (is_draft) => {
    if (validateInputs()) {
      let obj = {
        data: inputs,
        is_draft: is_draft,
        other_remark: other_remark,
        visitor_info_id: visitor_info_id,
      };
      VisitorService.save_visitor_response(obj).then((response) => {
        toast.success("Response Saved succcessfully !!");
        navigate("/thankyou-page");
      });
    }
  };

  return (
    <div className="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="content-wrapper-inner pt-0 border">
            <div class="innerheadsec p-0">
              <div class="row surveyviewquestions">
                <div class="col-12">
                  <h3 className="policyhead policyheadasssubhead text-center">
                    Visitor Details Form
                    {/* <div className="row align-item-center">
                        <div className="col-sm-2 text-center text-sm-center">
                        <img src={curAssess?.banner?.url} class="mob-logoass" />
                        </div>
                        <div className={"col-sm-10 survey-title-web"}>
                        <span
                        dangerouslySetInnerHTML={{
                          __html: curAssess?.title,
                        }}
                        ></span>
                        </div>
                      </div> */}
                  </h3>
                  <>
                    <h3 className="policyhead policyheadasssubhead">
                      Instructions
                      <ol
                        className="ml-3"
                        style={{
                          lineHeight: "2",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        <li>
                          Safety of peoples, environment is of utmost important
                          for us.
                        </li>
                        <li>
                          For Safety reasons, laptop, mobile phones and other
                          electronic devices are allowed up to the
                          administrative building (non-plant area) only.
                        </li>
                        <li>
                          Photography is prohibited in the manufacturing plants.
                        </li>
                        <li>
                          As per the safety requirements, Personal protective
                          equipment’s (PPE’s) shall be provided to the visitors
                          (Apron, Helmet, safety googles and safety shoes).
                        </li>
                      </ol>
                    </h3>
                    <h3 className="policyhead policyheadasssubhead">
                    Visitor Site Details                   
                    </h3>
                    <div class="table-responsive">
                    
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Visiting Site</th>
                        <th>From Visiting Date</th>
                        <th>To Visiting Date</th>
                        <th>Distance From Delhi</th>
                        <th>Distance From Mumbai</th>
                        <th>Nearest Airport</th>
                        <th>Nearest City</th>
                        <th>Distance of site from City</th>
                        <th>Site Operating hours</th>
                        <th>Site Climatic Condition</th>
                        <th>Local city Attraction</th>
                      </tr>
                    </thead>
                    <tbody>
                      {visitingSiteData?.map((data, index)=>(
                      <tr>
                        <td>{index + 1}</td>
                        <td>{data?.visiting_site_details}</td>
                        <td>{data?.from_visiting_date}</td>
                        <td>{data?.to_visiting_date}</td>
                        <td>{data?.distance_from_delhi}</td>
                        <td>{data?.distance_from_mumbai}</td>
                        <td>{data?.nearest_airport}</td>
                        <td>{data?.nearest_city}</td>
                        <td>{data?.site_distance_from_city}</td>
                        <td>{data?.site_operating_hours}</td>
                        <td>{data?.site_climatic_condition}</td>
                        <td>{data?.local_city_attraction}</td>
                      </tr>
                    ))}
                    
                       
                    </tbody>
                  </table>
                </div>
                    {/* <div className="row ml-3">
                      <p style={{ fontWeight: "600", fontSize: "15px" }}>
                        Visiting Date:{" "}
                        {moment(visiting_date).format("DD-MM-YYYY")}
                      </p>
                    </div> */}
                    {inputs.map((item, index) => (
                      <div className="mx-sm-3 mt-sm-3 mx-2 mt-2" key={index}>
                        { index !== 0 && (<div style={{borderTop : '1px solid blue', paddingBottom: 30}}></div>)}
                        <div class="row viewformaftersubmit">
                          <div class="col-sm-4">
                            <div class="form-group innergroup">
                              <label>Guest's Name  { index === 0 && (<span class="text-danger">*</span>)}</label>
                              <input
                                type="text"
                                className="form-control"
                                name="guest_name"
                                placeholder="Enter name of the Guest"
                                value={item?.guest_name}
                                onChange={(event) => handleChange(event, index)}
                              />
                              {errors["guest_name_" + index] && (
                                <div className="small text-danger">
                                  {" "}
                                  {errors["guest_name_" + index]}{" "}
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="form-group innergroup">
                              <label>Guest's Email{ index === 0 && (<span class="text-danger">*</span>)}</label>
                              <input
                                type="text"
                                className="form-control"
                                name="guest_email"
                                placeholder="Enter email of the Guest"
                                value={item?.guest_email}
                                onChange={(event) => handleChange(event, index)}
                              />
                              {index === 0 && (
                                <div className="small text-danger">
                                  {" "}
                                  {errors["guest_email_" + index]}{" "}
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="form-group innergroup">
                              <label>Guest's Address<span class="text-danger">*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                name="guest_address"
                                placeholder="Enter address of the Guest"
                                value={item?.guest_address}
                                onChange={(event) => handleChange(event, index)}
                              />
                              {index === 0 && (
                                <div className="small text-danger">
                                  {" "}
                                  {errors["guest_address_" + index]}{" "}
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group innergroup">
                              <label>Size of the Apron<span class="text-danger">*</span></label>
                              <select
                                class="form-control newbgselect"
                                name="apron_size"
                                value={item?.apron_size}
                                onChange={(event) => handleChange(event, index)}
                              >
                                <option value={0}>Select</option>
                                <option value={"32"}>32</option>
                                <option value={"36"}>36</option>
                                <option value={"40"}>40</option>
                                <option value={"42"}>42</option>
                                <option value={"44"}>44</option>
                              </select>

                              {errors["apron_size_" + index] && (
                                <div className="small text-danger">
                                  {" "}
                                  {errors["apron_size_" + index]}{" "}
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div className="row">
                              <div class="form-group innergroup col-sm-3 col-md-4">
                                <label htmlFor="country">Select Country:<span class="text-danger">*</span></label>
                                <select
                                  class="form-control newbgselect"
                                  name="country"
                                  value={item?.country}
                                  onChange={(event) =>
                                    handleChange(event, index, "country")
                                  }
                                >
                                  <option value={"0"}>Select</option>
                                  <option value={"US"}>US</option>
                                  <option value={"UK"}>UK</option>
                                  <option value={"EU"}>EU</option>
                                  <option value={"Japan"}>Japan</option>
                                </select>

                                {errors['shoes_size_'+index] && (
                                <div className="small text-danger">
                                  {" "}
                                  {errors['shoes_size_'+index]}{" "}
                                </div>
                              )}
                              </div>
                              <div class="form-group innergroup col-sm-3 col-md-4">
                                <label className="d-block mb-2">
                                  Gender<span class="text-danger">*</span>
                                </label>
                                <label class="logCheck d-inline-block mr-4">
                                  Male
                                  <input
                                    type="radio"
                                    name={`gender${index}`}
                                    checked={item?.gender === "Male"}
                                    onChange={(event) =>
                                      handleChange(
                                        event,
                                        index,
                                        "Male",
                                        "gender"
                                      )
                                    }
                                  />
                                  <span class="checkmark"></span>
                                </label>
                                <label class="logCheck d-inline-block">
                                  Female
                                  <input
                                    type="radio"
                                    name={`gender${index}`}
                                    checked={item?.gender === "Female"}
                                    onChange={(event) =>
                                      handleChange(
                                        event,
                                        index,
                                        "Female",
                                        "gender"
                                      )
                                    }
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="form-group innergroup col-sm-3 col-md-4">
                                {item?.gender === "Male" && (
                                  <>
                                    <label htmlFor="menShoeSize">
                                      Shoe Size:<span class="text-danger">*</span>
                                    </label>
                                    <Select
                                      options={
                                        item?.country == "US"
                                          ? menShoeSizesData?.US
                                          : item?.country == "UK"
                                          ? menShoeSizesData?.UK
                                          : item?.country == "EU"
                                          ? menShoeSizesData?.EU
                                          : menShoeSizesData?.Japan
                                      }
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      placeholder="Select Size"
                                      name="shoes_size"
                                      value={item?.shoes_size_value}
                                      onChange={(event, action) =>
                                        handleChange(
                                          event,
                                          index,
                                          event.value,
                                          action.name
                                        )
                                      }
                                    />
                                  </>
                                )}
                                {item?.gender === "Female" && (
                                  <>
                                    <label htmlFor="womenShoeSize">
                                      Shoe Size:<span class="text-danger">*</span>
                                    </label>
                                    <Select
                                      options={
                                          item?.country == "US"
                                          ? womenShoeSizesData?.US
                                          : item?.country == "UK"
                                          ? womenShoeSizesData?.UK
                                          : item?.country == "EU"
                                          ? womenShoeSizesData?.EU
                                          : womenShoeSizesData?.Japan
                                      }
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      placeholder="Select Size"
                                      name="shoes_size"
                                      value={item?.shoes_size_value}
                                      onChange={(event, action) =>
                                        handleChange(
                                          event,
                                          index,
                                          event.value,
                                          action.name
                                        )
                                      }
                                    />
                                </>
                                )}
                              
                              </div>
                              
                            </div>
                           
                          </div>
                          <div class="col-md-6 mt-3">
                            <div class="form-group innergroup">
                              <label className="d-block mb-2">
                                Food Category<span class="text-danger">*</span>
                              </label>
                              <label class="logCheck d-inline-block mr-4">
                                Vegetarian
                                <input
                                  type="radio"
                                  name={`food_category${index}`}
                                  checked={item?.food_category === "vegetarian"}
                                  onChange={(event) =>
                                    handleChange(
                                      event,
                                      index,
                                      "vegetarian",
                                      "food_category"
                                    )
                                  }
                                  // onClick={() => {
                                  //     onSelectStatus(true, "status");
                                  // }}
                                  // checked={values?.status === true}
                                />
                                <span class="checkmark"></span>
                              </label>
                              <label class="logCheck d-inline-block mr-4">
                                Non-Vegetarian
                                <input
                                  type="radio"
                                  name={`food_category${index}`}
                                  checked={
                                    item?.food_category === "nonvegetarian"
                                  }
                                  onChange={(event) =>
                                    handleChange(
                                      event,
                                      index,
                                      "nonvegetarian",
                                      "food_category"
                                    )
                                  }
                                  // onClick={() => {
                                  //     onSelectStatus(false, "status");
                                  // }}
                                  // checked={values?.status === false}
                                />
                                <span class="checkmark"></span>
                              </label>
                              <label class="logCheck d-inline-block">
                                Vegan
                                <input
                                  type="radio"
                                  name={`food_category${index}`}
                                  checked={item?.food_category === "vegan"}
                                  onChange={(event) =>
                                    handleChange(
                                      event,
                                      index,
                                      "vegan",
                                      "food_category"
                                    )
                                  }
                                  // onClick={() => {
                                  //     onSelectStatus(false, "status");
                                  // }}
                                  // checked={values?.status === false}
                                />
                                <span class="checkmark"></span>
                              </label>
                            </div>

                            {errors["food_category_" + index] && (
                              <div className="small text-danger">
                                {" "}
                                {errors["food_category_" + index]}{" "}
                              </div>
                            )}
                          </div>
                          <div class="col-md-6 mt-3">
                            <div class="form-group innergroup">
                              <label className="d-block mb-2">
                                Spice Level<span class="text-danger">*</span>
                              </label>
                              <label class="logCheck d-inline-block mr-4">
                                Normal Indian Spice
                                <input
                                  type="radio"
                                  name={`spice_level${index}`}
                                  checked={item?.spice_level === "indian"}
                                  onChange={(event) =>
                                    handleChange(
                                      event,
                                      index,
                                      "indian",
                                      "spice_level"
                                    )
                                  }
                                  // onClick={() => {
                                  //     onSelectStatus(true, "status");
                                  // }}
                                  // checked={values?.status === true}
                                />
                                <span class="checkmark"></span>
                              </label>
                              <label class="logCheck d-inline-block">
                                No Spice
                                <input
                                  type="radio"
                                  name={`spice_level${index}`}
                                  checked={item?.spice_level === "nospice"}
                                  onChange={(event) =>
                                    handleChange(
                                      event,
                                      index,
                                      "nospice",
                                      "spice_level"
                                    )
                                  }
                                  // onClick={() => {
                                  //     onSelectStatus(false, "status");
                                  // }}
                                  // checked={values?.status === false}
                                />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                            {errors["spice_level_" + index] && (
                              <div className="small text-danger">
                                {" "}
                                {errors["spice_level_" + index]}{" "}
                              </div>
                            )}
                          </div>
                          <div class="col-md-6 mt-3">
                            <div class="form-group innergroup">
                              <label className="d-block mb-2">
                                Any restrictions like Gluten free/ allergies to
                                be taken care<span class="text-danger"></span>
                              </label>
                              <label class="logCheck d-inline-block mr-4">
                                Yes
                                <input
                                  type="radio"
                                  name={`other_restrictions${index}`}
                                  checked={item?.other_restrictions === true}
                                  onChange={(event) =>
                                    handleChange(
                                      event,
                                      index,
                                      true,
                                      "other_restrictions"
                                    )
                                  }
                                  // onClick={() => {
                                  //     onSelectStatus(true, "status");
                                  // }}
                                  // checked={values?.status === true}
                                />
                                <span class="checkmark"></span>
                              </label>
                              <label class="logCheck d-inline-block">
                                No
                                <input
                                  type="radio"
                                  name={`other_restrictions${index}`}
                                  checked={item?.other_restrictions === false}
                                  onChange={(event) =>
                                    handleChange(
                                      event,
                                      index,
                                      false,
                                      "other_restrictions"
                                    )
                                  }
                                  // onClick={() => {
                                  //     onSelectStatus(false, "status");
                                  // }}
                                  // checked={values?.status === false}
                                />
                                <span class="checkmark"></span>
                              </label>
                              {item?.other_restrictions === true && (
                                <label class="d-inline-block ml-5">
                                  Enter Details
                                  <span class="text-danger">*</span>
                                  <span>
                                    <input
                                      type="text"
                                      required={
                                        item?.other_restrictions === true
                                      }
                                      className="form-control"
                                      name="other_restrictions_details"
                                      placeholder="Please Enter Details"
                                      onChange={(event) =>
                                        handleChange(event, index)
                                      }
                                    />
                                  </span>
                                </label>
                              )}
                            </div>
                            {errors["other_restrictions_details_" + index] && (
                              <div className="small text-danger">
                                {" "}
                                {errors["other_restrictions_details_" + index]}{" "}
                              </div>
                            )}
                            
                          </div>
                          <div className="col-md-6">
                            {index === inputs.length - 1 && (
                              // <div className="col-md-3">
                              <button
                                onClick={() => handleAddInput()}
                                className="btn btn-primary-inner bpi-main float-right mt-4"
                                type="button"
                              >
                                Add Guest <i class="fas fa-user-plus"></i>
                              </button>
                              // </div>
                            )}
                            {inputs.length > 1 && index !== 0 && (
                              <button
                                type="button"
                                onClick={() => {
                                  handleDeleteInput(index);
                                }}
                                className="btn btn-blanktd text-danger mr-2 float-right mt-4"
                              >
                                <i className="far fa-trash-alt text-danger"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </div>
            <div class="border-top"></div>
            <div className="col-md-4 mt-3">
              <label
                className="d-block mb-2"
                style={{ fontSize: 14, color: "#959595", fontWeight: 600 }}
              >
                Other Remarks
              </label>
              <textarea
                className="form-control"
                name="other_remark"
                placeholder="Enter any other remarks"
                onChange={(event) => {
                  setOther_remark(event.target.value);
                }}
              ></textarea>
            </div>

            <div className="col-md-4 mt-3">
              <button
                className="btn btn-primary-inner bpi-main mr-3"
                type="button"
                onClick={(e) => {
                  saveVisitorResponse(true);
                }}
              >
                Save
              </button>
              <button
                className="btn btn-primary-inner bpi-main"
                type="button"
                onClick={(e) => {
                  saveVisitorResponse(false);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisitorDetailsForm;
