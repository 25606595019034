import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import { Breadcrumb, Dropdown, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import * as VendorService from "../../service/cashbookService";
import AddCashBookVendor from "./AddCashBookVendor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllUrlsCashbook from "./AllUrlsCashbook";
import * as moment from "moment";
import AddCashBookDemand from "./AddCashbookDemand.";
import ViewCashbookDemand from "./ViewCashbookDemand";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ConfirmModal from "../TravelMaster/ConfirmModal";
import * as searchPoService from "../../service/searchpo";

function CashDemand() {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [editMode, setEditMode] = useState(false);
  const [editModuleData, setEditModuleData] = useState(null);
  const [addnewshow, setAddNewShow] = useState(false);
  const [showView, setShowView] = useState(false);
  const [demandList, setDemandList] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const [nextSapSyncingTime, setNextSapSyncingTime] = useState('')
  const [show, filtersetShow] = useState(false);
  const userDetails = useSelector(selectUserData);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sendIdData, setSendIdData] = useState();
  const [finalData, setFinalData] = useState();
  // const addnewopen = () => addnewsetShow(true);
  // const addnewclose = () => addnewsetShow(false);

  const getDemandList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id
  ) => {
    VendorService.getDemandList(
      handleKey(search, page_no, page_size, sort_by, paginate, user_id)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setDemandList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const editDemand = (eData) => {
    setAddNewShow(true);
    setEditMode(true);
    setEditModuleData(eData);
  };

  const viewDemand = (eData) => {
    setShowView(true);
    setEditMode(true);
    setEditModuleData(eData);
  };
  const getExportDetails = (search, page_no, page_size, sort_by, paginate) => {
    VendorService.getExportValueDemand(
      handleKey(search, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookDemand.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const onConfirmClose = () => {
    setConfirmModal(false);
  };

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }

    return queryParm;
  };
  const onClose = () => {
    setEditModuleData(null);
    setAddNewShow(false);
  };
  const onCloseView = () => {
    setEditModuleData(null);
    setAddNewShow(false);
  };
  const onAddCallBack = (response) => {
    setAddNewShow(false);
    getDemandList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id
    );
  };
  const addNewVendorCashbook = (eData) => {
    setAddNewShow(true);
    setEditMode(false);
    setEditModuleData(null);
  };
  const getMasterData = (data) => {
    VendorService.getMasterData(data)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setMasterData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getDemandList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id
      );
      setSearchStr(e.target.value);
    }
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getDemandList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id
    );
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getDemandList(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id
      );
    }
  };
  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };
  const sendId = (id, data) => {
    setSendIdData(id);
    setFinalData(data);
    setConfirmModal(true);
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getDemandList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id
    );
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getMasterData(userDetails?.id);
    getDemandList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id
    );
    getSapSchedule()
  }, [pageSizeNo]);

  const getSapSchedule = () =>{
    searchPoService.get_sap_schedule('sync_post_cashbook_cash_demand').then((response)=>{
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    searchPoService.get_sap_schedule('sync_confirm_cash_demand_sap_posting').then((response)=>{
      let next_sap_syncing_time = response?.data?.dataList?.result
      setNextSapSyncingTime(next_sap_syncing_time)
    })
  }

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        {/* <AllUrlsCashbook /> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Cash Demand</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Amount, <br />
                            Status
                            {/* , <br /> Doc No, <br />{" "}
                            Remark, <br /> status */}
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Amount, Status..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            disabled={demandList.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                searchStr,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      class="btn btn-primary-inner"
                      onClick={() => addNewVendorCashbook()}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add Cash Demand
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{fontSize:"13px"}}>Next SAP Posting:</b> <span className="mr-3" style={{fontSize:"13px"}}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{fontSize:"13px"}}>Next SAP Confirmation Posting:</b> <span className="" style={{fontSize:"13px"}}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>

              <div class="tickethistorbg mt-4 mb-0">
                <div class="row viewformaftersubmit">
                  <div class="col-md-4">
                    <div class="innergroup">
                      <p>Cashbook </p>
                      <label>{masterData[0]?.desc}</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="innergroup">
                      <p>Current Balance</p>
                      <label>
                        <i class="fas fa-rupee-sign mr-1"></i> INR{" "}
                        {masterData[0]?.current_balance.toFixed(2)}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="innergroup">
                      <p>Provision Balance</p>
                      <label>
                        <i class="fas fa-rupee-sign mr-1"></i> INR{" "}
                        {masterData[0]?.provision_balance.toFixed(2)}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th
                      onClick={(e) => {
                        handlerData("id");
                      }}
                      className={
                        sortData["id"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["id"]
                      }
                    >
                      Request ID
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("status");
                      }}
                      className={
                        sortData["status"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["status"]
                      }
                    >
                      Status
                    </th>
                    <th>Sap Demand No</th>
                    <th>Sap Message</th>
                    <th>Receipt No</th>
                    <th>Sap Demand CRN No</th>
                    <th>Sap Conf No</th>
                    <th>Sap Conf CRN No</th>
                    <th>SAP Confirmation Message</th>
                    <th>Cashbook</th>
                    <th
                      onClick={(e) => {
                        handlerData("amount");
                      }}
                      className={
                        sortData["amount"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["amount"]
                      }
                    >
                      Amount
                    </th>

                    <th>Requester</th>
                    <th
                      onClick={(e) => {
                        handlerData("created_at");
                      }}
                      className={
                        sortData["created_at"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["created_at"]
                      }
                    >
                      Requested On
                    </th>
                    <th>Beneficiary</th>
                    <th>ID Doc</th>
                    <th>Payable Branch</th>
                    <th>Agency</th>
                    <th>Branch</th>

                    {/* <th
                      onClick={(e) => {
                        handlerData("updated_at");
                      }}
                      className={
                        sortData["updated_at"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["updated_at"]
                      }
                    >
                      Last Action On
                    </th> */}

                    <th>Delivery Code</th>
                    <th>Delivery Address</th>
                    <th>Approver</th>
                  </tr>
                </thead>
                <tbody>
                  {demandList?.length ? (
                    demandList?.map((data, index) => (
                      <tr>
                        <td>
                          {data?.sap_doc_no !== null &&
                          data?.sap_doc_no !== undefined &&
                          data?.status !== "C" &&
                          data?.status !== "P" ? (
                            <button
                              class="btn btn-success bpi-main px-3 py-1 f-14"
                              onClick={() => {
                                sendId(data?.id, data);
                              }}
                            >
                              Confirm
                            </button>
                          ) : data?.status == "P" || data?.status == null ? (
                            <button class="btn btn-blanktd text-primary f-12">
                              <i
                                class="far fa-edit"
                                onClick={() => {
                                  editDemand(data);
                                }}
                              ></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                        <td
                          class="fw-bold text-theme cursor-pointer"
                          onClick={() => {
                            onView(data);
                          }}
                        >
                          PICASHDE{data?.id}
                        </td>
                        <td>
                          {data?.status == "A" ? (
                            <span class="badge bad-status badge-success">
                              Approved{" "}
                            </span>
                          ) : data?.status == "P" || data?.status == null ? (
                            <span class="badge bad-status badge-warning">
                              Pending{" "}
                            </span>
                          ) : data?.status == "R" ? (
                            <span class="badge bad-status badge-danger">
                              Reject{" "}
                            </span>
                          ) : data?.status == "C" ? (
                            <span class="badge bad-status badge-success">
                              Confirmed{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{data?.sap_doc_no ?? "-"}</td>
                        <td>
                          <Tooltip title={data?.sap_message} position="bottom">
                            {data?.sap_message}
                          </Tooltip>
                        </td>
                        <td> {data?.receipt_no ?? "-"}</td>
                        <td>
                          {data?.demand_financial_year === null ||
                          data?.company_code === null ||
                          data?.sap_confirmation_no === null
                            ? "--"
                            : data?.company_code +
                              data?.sap_doc_no +
                              data?.demand_financial_year}
                        </td>
                        <td>{data?.sap_confirmation_no ?? "-"}</td>
                        <td>
                          {data?.demand_financial_year === null ||
                          data?.company_code === null ||
                          data?.sap_confirmation_no === null
                            ? "--"
                            : data?.company_code +
                              data?.sap_confirmation_no +
                              data?.demand_financial_year}
                        </td>
                        <td>
                          <Tooltip
                            title={data?.sap_confirmation_message}
                            position="bottom"
                          >
                            {data?.sap_confirmation_message ?? "--"}
                          </Tooltip>
                        </td>
                        <td>{data?.cashbook_desc ?? "-"}</td>

                        <td>INR {data?.amount}</td>
                        <td>{data?.created_by}</td>
                        <td>
                          {moment
                            .utc(data?.created_at)
                            .format("DD-MM-YYYY,HH:mm")}
                        </td>
                        <td>{data?.beneficiary_code}</td>
                        <td>
                          {data?.doc_type == "P"
                            ? "Pancard"
                            : data?.doc_type == "A"
                            ? "AdharCard"
                            : "Driving License"}
                        </td>
                        <td>{data?.payable_branch ?? "-"}</td>
                        <td>{data?.agency_name ?? "-"}</td>
                        <td>{data?.branch_code ?? "-"}</td>
                        {/* <td>
                          {moment
                            .utc(data?.updated_at)
                            .format("DD-MM-YYYY,HH:mm")}
                        </td> */}
                        <td>{data?.cash_delivery_code ?? "-"}</td>
                        <td>
                          <Tooltip
                            title={data?.delivery_address_code}
                            position="left"
                          >
                            {data?.delivery_address_code}
                          </Tooltip>
                        </td>
                        <td>
                          {`${userData?.reporting_to_fname} ${userData?.reporting_to_lname} `}
                          ({userData?.reporting_to_name})
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={22}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {demandList?.length == 0 ? (
              <td colSpan={12}> No Record Found</td>
            ) : (
              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control main-pointer"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {viewForm ? (
        <>
          <ViewCashbookDemand
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}

      {addnewshow && (
        <AddCashBookDemand
          edit={editMode}
          addnewshow={addnewshow}
          onClose={onClose}
          onAddCallBack={onAddCallBack}
          editModuleData={editModuleData}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          demandList={demandList}
          finalData={finalData}
          confirmModal={confirmModal}
          onConfirmClose={onConfirmClose}
          sendIdData={sendIdData}
          getDemandList={getDemandList}
        />
      )}
    </>
  );
}

export default CashDemand;
