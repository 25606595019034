import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";


export const getOverallDropdown = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/overall-strategy/overall-objective/drop-down`);
}


export const getOverallMonthDropdown = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/overall-strategy/overall-month/drop-down`);
}

export const getLastOverallStrategy = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/overall-strategy/last-strategy-plan${query}`)
}

export const putOverallStrategy = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/overall-strategy`,body);
}

export const getOverallStrategy = async (body) =>{
    return axios.get(`${AUTH_BASE_URL}/overall-strategy`);
}

export const postOverallStrategy = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/overall-strategy`,body);
}
export const getTerritory = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/territory/drop-down`);
}
export const getCurrentFY = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/current-fy`);
}

export const getOverallStrategyApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/overall-strategy/approver-overall-strategy-logs${query}`);
}

export const postOverallAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/overall-strategy/action-overall-strategy`,body);
}





