import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import Sort from "../../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import SelectedTypeUser from "./SelectedTypeUser";
import ReactPaginate from "react-paginate";
import * as surveyService from "../../service/Survey";

function SurveySurveys() {
  const navigate = useNavigate();

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [pageCount, setPageCount] = useState(1);
  const [apiresp, setApiResp] = useState([]);
  const userData = useSelector(selectUserData);
  const [subMenuCounts, setSubMenuCounts] = useState(0);
  useEffect(() => {
    surveyService
      .getSurvey("assessment_type_id=3" + `&sort_by=-id&client=${true}`)
      .then((response) => {
        // console.log(response?.data?.dataList?.result);
        // getAllCategoryType();
        setApiResp(response?.data?.dataList?.result);
        setSubMenuCounts(response?.data?.dataList?.result.length);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  }, []);

  return (
    <>
      <div class="row">
        <SelectedTypeUser current={"Survey"} />
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Survey</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Survey & Polls</Breadcrumb.Item>
                    <Breadcrumb.Item active>Survey</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Published At</th>
                    <th>Last Date</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {console.log("poll..", apiresp)}
                  {apiresp?.map((dataItem, idx) => {
                    return (
                      <tr>
                        <td class="fw-bold text-theme">{dataItem.title}</td>
                        <td>{dataItem.start_date}</td>
                        <td>{dataItem.end_date}</td>
                        <td>{dataItem.description}</td>
                        {dataItem?.status === "Expired" ? (
                          <td>
                            <span class="badge bad-status badge-danger">
                              Expired
                            </span>
                          </td>
                        ) : dataItem?.status === "Pending" ? (
                          <td>
                            <span class="badge bad-status badge-warning">
                              Pending
                            </span>
                          </td>
                        ) : dataItem?.status === "Submitted" ? (
                          <td>
                            <span class="badge bad-status badge-success">
                              Submitted
                            </span>
                          </td>
                        ) : (
                          ""
                        )}
                        {dataItem?.status === "Expired" ||
                          (dataItem?.status === "Submitted" &&
                            dataItem?.permission_type === "SO") ? (
                          ""
                        ) : (
                          <td>
                            <span
                              class="cursor-pointer"
                              onClick={() =>
                                navigate("/viewmyassignment", {
                                  state: {
                                    targetAsmnt: {
                                      id: dataItem.id,
                                      type_id: dataItem.assessment_type_id,
                                      user_id: userData.id,
                                      current: "Survey",
                                    },
                                    flag: "true",
                                  },
                                })
                              }
                            >
                              <img src="images/eye.png" alt="" />
                            </span>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select class="form-control">
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                {/* <Pagination>
                  <Pagination.Prev className="previpag" />
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item >{2}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{9}</Pagination.Item>
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Next className="previpag" />
                </Pagination> */}
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  // onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Published Date <span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div>
          <div class="form-group innergroup">
            <label>
              Last Date <span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div>

          <div class="form-group innergroup">
            <label>
              Type <span class="text-danger">*</span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Status <span class="text-danger">*</span>
            </label>
            <select class="form-control">
              <option>Select</option>
              <option>Pending</option>
              <option>Submitted</option>
              <option>Expired</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SurveySurveys;
