import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import Sidemenu from "../include/sidemenu";
import * as BrandService from "../../service/brandService";
import * as CapexService from "../../service/CapexService";
import * as MdoService from "../../service/MdoService";
// import * as LoanDataService from "../../service/loansalary";
import * as UserService from "../../service/userService";

import Header from "../include/header";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ModalTitle,
} from "react-bootstrap";
import Select from "react-select";
// import CapexAddForm from "./CapexAddForm";
import * as Yup from "yup";
import * as CmsNewsService from "../../service/Cms-newsService";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function MdoPage() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const options = [
    { value: "News", label: "News" },
    { value: "Notice", label: "Notice" },
    { value: "Popup", label: "Popup" },
  ];
  const [filterdata, setfilterData] = useState({
    Type: "",
    emp_id: "",
    from_date: "",
    to_date: "",
    status: "",
  });
  const [editData, seteditNotice] = useState({});
  const [branchcopy, setBranchDatacopy] = useState([]);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [noticeData, setNoticeData] = useState([]);
  const [capexData, setCapexData] = useState([]);
  const [usersLocalCache, setUsersLocalCache] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);

  const [editValue, setEditValue] = useState([]);
  const [editFlag, setEditFlag] = useState(false);
  const [hasFormErrors, set_hasFormErrors] = useState(false);
  const [mdoAccessTO, set_mdoAccessTO] = useState("");

  const [mdoFormData, setMdoFormData] = useState({
    access_to: "",
    is_active: true,
  });

  const [editForm, setEditForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [viewnewshow, viewnewsetShow] = useState(false);
  const viewnewopen = () => viewnewsetShow(true);
  const viewnewclose = () => viewnewsetShow(false);

  const userData = useSelector(selectUserData);

  const getData = (
    currentpage,
    pagesize,
    sort_by,
    search,
    paginate,
    type,
    from_date,
    to_date,
    status
  ) => {
    dispatch(setDisplayLoader("Display"));

    CmsNewsService.getNotices(
      currentpage,
      pagesize,
      sort_by,
      search,
      paginate,
      type,
      from_date,
      to_date,
      status
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let data = response?.data?.result;
        setNoticeData(response?.data?.result);
        setpageCount(response?.data?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getBranch = async () => {
    await BrandService.getBranch(false)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data2 = {
            value: x.id,
            label: x.name + " (" + x.code + ")",
            organisation:
              x?.organisation_name + " (" + x.organisation_code + ")",
          };
          data.push(data2);
        });
        setBranchDatacopy(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const editNotice = (data) => {
    seteditNotice(data);
    setEditForm(true);
    addnewopen();
  };
  const addnewopenForm = () => {
    addnewsetShow(true);
    setEditForm(false);
  };

  const onClose = () => {
    setEditValue(null);
    addnewclose();
    set_mdoAccessTO("");
    set_hasFormErrors(false);
    setEditFlag(false);
  };

  const setmdoAccessTO = (data) => {
    console.log(`setting`, data);
    let mdoUser = usersDropdown.filter((x) => {
      return x.value == data;
    });
    //   setMdoFormData({
    //     ...mdoFormData,
    //     access_to:data.access_to,
    //     is_active:data.is_active
    // })
    setMdoFormData((prev) => ({
      ...prev,
      access_to: data,
    }));
    set_mdoAccessTO(mdoUser);
  };

  const editMdoData = (data) => {
    setMdoFormData({
      ...mdoFormData,
      access_to: data.access_to,
      is_active: data.is_active,
    });
    addnewsetShow(true);
    setEditFlag(true);
    setEditValue(data);
    setmdoAccessTO(data.access_to);

    console.log(`editing`, data);
  };

  const getCapexExport = (
    search,
    // assessment_type_id,
    // category_id,
    // status,
    // start_date,
    // end_date,
    // page_no,
    // page_size,
    sort_by
    // paginate
  ) => {
    MdoService.getCapexExport(`search=` + search + `&sort_by=` + sort_by)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "MDO_Export.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const isMdoExist = (iid, data) => {
    MdoService.isMdoExist({
      access_to: data.access_to,
    })
      .then((response) => {
        if (response.data.dataList.is_exist) {
          toast.error("User already exists.");
          return;
        } else if (iid == -1) {
          createCapex(data);
        } else {
          updateCapex(iid, data);
        }

        return response.data.dataList.is_exist;
      })
      .catch((error) => {
        toast.error("Error in checking record.");
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteCapex = (id) => {
    MdoService.deleteCapex(id)
      .then((response) => {
        toast.success("Deleted successfully.");
        getCapex(
          searchStr,
          1,
          pageSizeNo,
          sortData.sort_by,
          true,
          "",
          "",
          "",
          "",
          ""
        );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateCapex = (id, dataa) => {
    MdoService.updateCapex(id, dataa)
      .then((response) => {
        toast.success("Updated successfully.");
        getCapex(
          searchStr,
          1,
          pageSizeNo,
          sortData.sort_by,
          true,
          "",
          "",
          "",
          "",
          ""
        );
        onClose();
      })
      .catch((error) => {
        toast.error("User already exist.");
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const createCapex = (data) => {
    MdoService.postCapex(data)
      .then((response) => {
        toast.success("Created successfully.");
        getCapex(
          searchStr,
          1,
          pageSizeNo,
          sortData.sort_by,
          true,
          "",
          "",
          "",
          "",
          ""
        );
        onClose();
      })
      .catch((error) => {
        toast.error("User already exist.");
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const submitMdoForm = () => {
    console.log(`sub..`, mdoFormData);

    if (!mdoFormData.access_to || mdoFormData.is_active === "") {
      set_hasFormErrors(true);
      return;
    }

    if (editFlag) {
      let dataa = {};
      dataa["access_to"] = mdoFormData.access_to;
      dataa["is_active"] = mdoFormData.is_active;
      updateCapex(editValue.id, dataa);
      // isMdoExist(editValue.id,dataa)
    } else {
      // createCapex(mdoFormData)
      isMdoExist(-1, mdoFormData);
    }
  };

  const getApproverList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result
          ?.filter((each) => {
            if (each?.username !== "admin" && each?.username !== "super_pi") {
              return true;
            }
          })
          .map((x) => {
            return {
              value: x.username,
              label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
            };
          }),
      ];
      data.push({
        value: userData?.username,
        label: `${userData?.first_name} ${userData?.last_name} ( ${userData.username} )`,
      });
      setUsersDropdown(data);

      const data2 = [
        ...res?.data?.dataList?.result
          ?.filter((each) => {
            if (each?.username !== "admin" && each?.username !== "super_pi") {
              return true;
            }
          })
          ?.map((x) => {
            return {
              username: x.username,
              label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
            };
          }),
      ];
      data2.push({
        username: userData?.username,
        label: `${userData?.first_name} ${userData?.last_name} ( ${userData.username} )`,
      });
      setUsersLocalCache(data2);
    });
  };

  const getUserFixedData = (createdby_id) => {
    // // console.log(`user local data..`,usersLocalCache);

    // for (let i = 0; i < usersLocalCache.length; i++) {
    //   // console.log('log..',usersLocalCache[i],createdby_id);

    //   if (Number(usersLocalCache[i].username) == Number(createdby_id)) {
    //     console.log(usersLocalCache[i], createdby_id);
    //     return usersLocalCache[i].label;
    //   }
    // }

    // return "";
    let value = usersLocalCache?.filter((obj) => {
      if (obj?.username === createdby_id) {
        return true;
      }
    });
    if (value?.length > 0) {
      return value[0]?.label;
    } else {
      return "";
    }
  };

  const getCapex = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    type,
    from_date,
    to_date,
    status,
    user_id
  ) => {
    MdoService.getCapex(
      `search=` +
        search +
        `&page_no=` +
        page_no +
        `&page_size=` +
        page_size +
        `&sort_by=` +
        sort_by +
        `&paginate=` +
        paginate +
        `&Type=${type}&from_date=${from_date}&to_date=${to_date}${
          status == undefined || status == "" || status == null
            ? ""
            : `&status=${status}`
        }` +
        "&user_id=" +
        (user_id === "" ? "" : user_id?.value)
    )
      .then((response) => {
        setCapexData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        // toast.error("Please fill mandatory data first.");

        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    getApproverList();
  }, []);

  useEffect(() => {
    getCapex(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      ""
    );
  }, [usersLocalCache]);

  //   useEffect(() => {

  //     getBranch();
  //     getData(
  //       currentPage,
  //       pageSizeNo,
  //       sortData.sort_by,
  //       searchStr,
  //       true,
  //       filterdata.Type,
  //       filterdata.from_date,
  //       filterdata.to_date,
  //       filterdata.status
  //     );
  //   }, [pageSizeNo]);
  const deleteNotice = (id) => {
    // let data = { id: id };
    CmsNewsService.deleteNotice(id)
      .then((res) => {
        getData(currentPage, pageSizeNo, "-id", "", true, "", "", "", "");
        if (res.data.message.includes("can't")) {
          toast.warning(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleShow = (e) => {
    // getData(
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   searchStr,
    //   true,
    //   filterdata.Type,
    //   filterdata.from_date,
    //   filterdata.to_date
    // );
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };
  const submitDelete = (id, type) => {
    confirmAlert({
      title: "Delete " + type,
      message: `Are you sure to delete ` + type + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteNotice(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getCapex(
      searchStr,
      activePage,
      pageSizeNo,
      "-id",
      // "",
      true,
      filterdata?.Type,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.status,
      filterdata?.emp_id
    );
    setCurrentPage(activePage);
  };
  const mainDataFilter = () => {
    console.log(" filterdata?.status", filterdata?.status);
    // getData(
    //   currentPage,
    //   pageSizeNo,
    //   "-id",
    //   "",
    //   true,
    //   filterdata?.Type,
    //   filterdata?.from_date,
    //   filterdata?.to_date,
    //   filterdata?.status
    // );
    getCapex(
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata?.Type,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.status,
      filterdata?.emp_id
    );

    filterclose();
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      // getData(1, pageSizeNo, sortData.sort_by, "", true, "", "", "", "");
      getCapex(
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        "",
        "",
        "",
        ""
      );
    }
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getData(
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      searchStr,
      true,
      filterdata.Type,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.status
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      // getData(
      //   currentPage,
      //   pageSizeNo,
      //   "-id",
      //   e.target.value.toLowerCase(),
      //   true,
      //   "",
      //   "",
      //   "",
      //   ""
      // );
      getCapex(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        "",
        "",
        "",
        ""
      );
      setSearchStr(e.target.value);
    }
  };
  const clearfilter = () => {
    filterclose();
    // getData(currentPage, pageSizeNo, "-id", "", true, "", "", "", "");
    getCapex(
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      ""
    );
    setfilterData({
      Type: "",
      from_date: "",
      to_date: "",
      status: "",
      emp_id: "",
    });
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Mdo</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/home">CMS</Breadcrumb.Item>
                <Breadcrumb.Item active>News, Notice & Popup</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              {/* <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Title,
                        <br />
                        Display On
                      </strong>
                    </div>
                  </Tooltip>
                }
              > */}
              <div class="input-group table-search">
                <span class="input-group-text">
                  <img src="images/search.png" alt="" />
                </span>
                <input
                  id="searchbar"
                  type="text"
                  class="form-control"
                  placeholder="Search on Access To..."
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                />
                <span class="input-group-text border-0">
                  {searchStr !== "" ? (
                    <i
                      class="far fa-times-circle cursor-pointer"
                      onClick={() => {
                        closeButtonCallBack();
                      }}
                    ></i>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              {/* </OverlayTrigger> */}
            </div>

            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <img src="images/export.png" alt="" class="mr-2" /> Export
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <button
                        style={{ border: 0, background: "none" }}
                        onClick={() => {
                          getCapexExport(searchStr, sortData.sort_by);
                        }}
                      >
                        <i className="far fa-file-excel"></i>Excel
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button onClick={filteropen} class="btn btn-secondary-inner">
                  <img src="images/filter.png" alt="" class="mr-3" />
                  Filter
                </button>
                {/* {finalUrlValue?.A ? ( */}
                <button class="btn btn-primary-inner" onClick={addnewopenForm}>
                  <img src="images/upload.png" alt="" class="mr-3" />
                  Add New
                </button>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                {/* <th
                  onClick={(e) => {
                    handlerData("Type");
                  }}
                  className={
                    sortData["Type"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["Type"]
                  }
                >
                  Type
                </th> */}
                <th>Sr.</th>
                <th>Access To</th>
                {/* <th>Urltype</th> */}
                {/* <th>Status</th> */}

                <th>Created by</th>
                <th>Created on</th>
                <th>Updated on</th>
                {/* <th>Approver</th> */}
                <th
                  // onClick={(e) => {
                  //   handlerData("status");
                  // }}
                  className={
                    sortData["status"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["status"]
                  }
                >
                  Status
                </th>
                {/* <th>Approval Status</th>  */}

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {/* {console.log(`capp`,capexData)} */}
              {capexData?.length ? (
                capexData?.map((x, cap_index) => (
                  <tr>
                    {/* <td class="text-theme fw-bold">{x.Type}</td> */}
                    <td>{cap_index + 1}</td>
                    <td>{getUserFixedData(x?.access_to)}</td>
                    {/* <td>
                      {x?.display_on != null
                        ? x?.display_on?.map((x) => x + ",")
                        : ""}
                    </td> */}

                    {/* <td>
                      {moment.utc(x?.updated_at).format("DD-MM-YYYY,HH:mm")}
                    </td> */}
                    <td>{getUserFixedData(x?.created_by)}</td>

                    <td>
                      {" "}
                      {moment.utc(x?.created_at).format("DD-MM-YYYY,HH:mm")}
                    </td>
                    <td>
                      {" "}
                      {moment.utc(x?.updated_at).format("DD-MM-YYYY,HH:mm")}
                    </td>
                    {x.is_active ? (
                      <td>
                        <span className="badge bad-status badge-success">
                          Active
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span class="badge bad-status badge-danger">
                          InActive
                        </span>
                      </td>
                    )}
                    <td>
                      <button
                        class="btn btn-blanktd text-primary ml-2"
                        onClick={() => editMdoData(x)}
                      >
                        <i class="far fa-edit"></i>
                      </button>
                      {/* <button class="btn btn-blanktd text-danger ml-2">
                                <i
                                  class="far fa-trash-alt text-danger"
                                  onClick={() => {
                                    deleteCapex(x?.id)
                                  }}
                                ></i>
                        </button> */}
                    </td>

                    {finalUrlValue?.E || finalUrlValue?.R ? "" : ""}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}>NO Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {capexData?.length ? (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control"
                  onChange={(e) => {
                    handleShow(e);
                  }}
                  style={{ textAlign: "center" }}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>100</option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div class="form-group innergroup">
            <label>Url Type</label>
            <select
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  Type: e.target.value,
                }))
              }
              value={filterdata?.Type}
              name="Type"
              class="form-control newbgselect"
            >
              <option value={null}>Select Type</option>
              <option value="News">News</option>
              <option value="Notice">Notice</option>
              <option value="Popup">Popup</option>
            </select>
          </div> */}
          {/* <div class="form-group innergroup">
            <label>From Date</label>
            <input
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  from_date: e.target.value,
                }))
              }
              value={filterdata?.from_date}
              max={moment(filterdata.to_date).format("YYYY-MM-DD")}
              name="from_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          <div class="form-group innergroup">
            <label>To Date</label>
            <input
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  to_date: e.target.value,
                }))
              }
              value={filterdata?.to_date}
              min={moment(filterdata.from_date).format("YYYY-MM-DD")}
              name="to_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div> */}
          <div class="form-group innergroup">
            <label>Status</label>
            <select
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  status: e.target.value,
                }))
              }
              value={filterdata?.status}
              name="status"
              class="form-control newbgselect"
            >
              <option value="">Select Type</option>
              <option value={true}>Active</option>
              <option value={false}>In-Active</option>
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Employee</label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setfilterData((prev) => ({
                  ...prev,
                  emp_id: e,
                }));
              }}
              value={filterdata?.emp_id}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => clearfilter()}>
            Clear
          </button>
          <button
            type="submit"
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose maxwidth90 modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Mdo </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div className="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Access To <span class="text-danger">*</span>
                    </label>
                    <Select
                      options={usersDropdown}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setmdoAccessTO(e.value);
                      }}
                      isDisabled={editFlag}
                      value={mdoAccessTO}
                    />
                    {hasFormErrors && !mdoFormData.access_to ? (
                      <div className="small text-danger">
                        This field is mandatory
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Url type</label>
                    <input
                      type="text"
                      class="form-control"
                      // value={currState.username}
                      placeholder="Enter urltype"
                      
                    />
                  </div>
                </div> */}

                <div class="col-md-4">
                  {/* <div class=" border-bottom mb-3"> */}
                  <h6 class="fw-bold mb-1">Status:</h6>
                  <label class="logCheck d-block">
                    Active:
                    <input
                      // disabled={isViewing}
                      type="radio"
                      checked={mdoFormData.is_active == true}
                      onClick={() =>
                        setMdoFormData({
                          ...mdoFormData,
                          is_active: true,
                        })
                      }
                      name="askdjaks"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="logCheck d-block">
                    Inactive:
                    <input
                      // disabled={isViewing}
                      type="radio"
                      checked={mdoFormData.is_active == false}
                      onClick={() =>
                        setMdoFormData({
                          ...mdoFormData,
                          is_active: false,
                        })
                      }
                      name="askdjaks"
                    />
                    <span class="checkmark"></span>
                  </label>
                  {/* </div> */}
                  {hasFormErrors && mdoFormData.is_active === "" ? (
                    <div className="small text-danger">Status is mandatory</div>
                  ) : (
                    ""
                  )}
                </div>

                <div class="col-md-12 mt-2">
                  <button
                    class="btn btn-outline-secondary mr-3"
                    onClick={() => onClose()}
                  >
                    <i class="far fa-times-circle"></i> Cancel
                  </button>

                  <button
                    // hidden={isViewing}
                    class="btn btn-primary-inner bpi-main"
                    onClick={() => submitMdoForm()}
                  >
                    <i class="far fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={viewnewshow}
        onHide={viewnewclose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>News</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row news-detail">
                <div class="col-md-12 mb-3 mb-sm-4">
                  <h2>
                    PI Industries Vice Chairman and MD Mayank Singhal's formula
                    for Consistent Growth
                  </h2>
                  <h4>
                    Mayank Singhal speaks as fast as he thinks. It reflects on
                    his company's growth
                  </h4>
                </div>
                <div class="col-md-5">
                  <img src="images/new-detail.jpg" alt="" />
                </div>
                <div class="col-md-7">
                  <p>
                    If you think you speak fast, you should meet Mayank Singhal.
                    The Vice Chairman and MD of PI Industries, a major player in
                    agrochemicals, can dumbfound you by his speed of thought and
                    speech. And his company's growth is a worthy match. More
                    important is the consistency. Despite the Covid-19 pandemic,
                    PI Industries has grown its revenue and profits each year
                    since 2018.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MdoPage;
