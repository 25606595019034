import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";


export const getCropScenario = async () => {
    return axios.get(`${AUTH_BASE_URL}/crop-scenario`);
};

export const getCropScenarioDropDown = async (id)=>{
    return axios.get(`${AUTH_BASE_URL}/crop-scenario/crop/drop-down?territory_code=${id}`);
}

export const getCropStagesDropDown = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/stages/drop-down${query}`);
}

export const getProductCategory = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/product-category/drop-down`);
}

export const getPestData = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/pests/drop-down${query}`);
}

export const getCompanyProduct = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/company-product/drop-down${query}`);
}

export const getGenricProduct = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/molecules/drop-down${query}`);
}

export const getStagesDates = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/stages/calculate-duration${query}`)
}

export const getCurrentFy = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/current-fy`);
}

export const postCropScenario = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/crop-scenario`,body);
}
export const putCropScenario = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/crop-scenario`,body);
}


export const fetchCropScenario = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/crop-scenario/territory-crops-scenario${query}`)
}

export const getCropScenarioApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/crop-scenario/approver-crop-scenario-logs${query}`);
}

export const postCropScenarioAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/crop-scenario/action-crop-scenario`,body);
}

export const getCropPlanDropdownCrops = async (code)=>{
    return axios.get(`${AUTH_BASE_URL}/crop-plan/crop/drop-down?territory_code=${code}`);
}