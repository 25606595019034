import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentFy } from "../../../../service/AgriPortalService/CropScenarioService";
import { getExpensePlan } from "../../../../service/AgriPortalService/ExpensePlanService";
import { getFieldWork } from "../../../../service/AgriPortalService/FieldWorkService";
import {
  getLastMarketSize,
  getMarketSize,
  getTerritory,
} from "../../../../service/AgriPortalService/MarketSizeService";
import {
  getRegionDropDown,
  getTerritoryDropDown,
  getZoneDropDown,
} from "../../../../service/AgriPortalService/CommonService";
import styles from "./Root.module.css";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { setDisplayLoader } from "../../../redux/piDataStore";
import moment from "moment";

const Root = (props) => {
  //HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //To contain territory dropdown state
  const [territory, setTerritory] = useState();
  const [territories, setTerritories] = useState();

  //To contain market player data
  const [marketSizeData, setMarketSizeData] = useState([]);

  const [showAddNew, setShowAddNew] = useState(true);
  const [zonePopData, setZonePopData] = useState([]);
  const [regionPopData, setRegionPopData] = useState([]);
  const [territoryPopData, setTerritoryPopData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [territoryData, setTerritoryData] = useState([]);
  const [cfy, setCfy] = useState("");
  const [loader, setLoader] = useState(true);
  // useEffect(() => {
  //     getCurrentFy().then(res => setCfy(res.data.dataList.fy)).catch(err => console.log(err));
  // }, []);

  // //Load territory dropdown data
  // useEffect(() => {
  //     getTerritory().then(res => {

  //         if (!res.data.dataList || res.data.dataList.length == 0) return;

  //         setTerritory(res.data.dataList[0]);
  //         setTerritories(res.data.dataList);
  //     })
  //         .catch(err => {
  //             toast.error("Fetching territory failed!");
  //             console.log(err);
  //         });
  // }, []);

  // //Load market player data
  // //Store territory to local storage
  // useEffect(() => {
  //     localStorage.setItem("territory", JSON.stringify(territory));

  //     getExpensePlan().then(res => {
  //         setMarketSizeData(res.data.dataList);

  //         getCurrentFy().then(r => {
  //             setLoader(false);
  //             let flag = true;

  //             for (let i = 0; i < res.data.dataList.length; i++) {
  //                 if (res.data.dataList[i].cfy == r.data.dataList.fy && res.data.dataList[i].created_by_me) {
  //                     flag = false;
  //                     break;
  //                 }
  //             }

  //             setShowAddNew(flag);
  //         })
  //         setLoader(false);
  //     })
  //         .catch(err => {
  //             toast.error("Fetching market size failed!");
  //             console.log(err);
  //         })
  // }, [territory]);

  // useEffect(() => {
  //     let code = -1
  //     getZoneDropDown(false).then((res) => {
  //         setZoneData((state) => {
  //             let newData = [...state]
  //             newData = res.data.dataList
  //             return newData
  //         })
  //         code = res.data.dataList[0].code
  //         setZonePopData((state) => {
  //             let newData = [...state]
  //             if (code == -1) newData = []
  //             else newData = marketSizeData.filter(el => el.zone_code == code && el.region_code == null && el.territory_code == null)
  //             return newData
  //         })
  //     }).catch((err) => {
  //         console.log("ZONE", err)
  //     })
  // }, [marketSizeData])

  // useEffect(() => {
  //     let code = -1
  //     getRegionDropDown(zoneData[0]?.id).then((res) => {
  //         setRegionData((state) => {
  //             let newData = [...state]
  //             newData = res.data.dataList
  //             return newData
  //         })
  //         code = res.data.dataList[0].code
  //         setRegionPopData((state) => {
  //             let newData = [...state]
  //             if (code == -1) newData = []
  //             else newData = marketSizeData.filter(el => el.region_code == code && el.territory_code == null)
  //             return newData
  //         })
  //     }).catch((err) => {
  //         console.log("ZONE", err)
  //     })
  // }, [zoneData])

  // useEffect(() => {
  //     getTerritoryDropDown(regionData[0]?.id).then((res) => {
  //         setTerritoryData((state) => {
  //             let newData = [...state]
  //             newData = res.data.dataList
  //             return newData
  //         })
  //         territoryChangeHandler(res.data.dataList[0]?.code)
  //     }).catch((err) => {
  //         console.log("ZONE", err)
  //     })
  // }, [regionData])

  // const zoneChangeHandler = (e) => {
  //     let code = -1
  //     getRegionDropDown(e.target.value).then((res) => {
  //         setRegionData((state) => {
  //             let newData = [...state]
  //             newData = res.data.dataList
  //             return newData
  //         })
  //         code = zoneData[zoneData.findIndex(el => el.id == e.target.value)].code
  //         setZonePopData((state) => {
  //             let newData = [...state]
  //             if (code == -1) newData = []
  //             else newData = marketSizeData.filter(el => el.zone_code == code && el.region_code == null && el.territory_code == null)
  //             return newData
  //         })
  //     }).catch((err) => {
  //         console.log("ZONE", err)
  //     })
  // }

  // const regionChangeHandler = (e) => {
  //     let code = -1
  //     getTerritoryDropDown(e.target.value).then((res) => {
  //         console.log("territory", res.data.dataList, e.target.id)
  //         setTerritoryData((state) => {
  //             let newData = [...state]
  //             newData = res.data.dataList
  //             return newData
  //         })
  //         code = regionData[regionData.findIndex(el => el.id == e.target.value)].code
  //         setRegionPopData((state) => {
  //             let newData = [...state]
  //             if (code == -1) newData = []
  //             else newData = marketSizeData.filter(el => el.region_code == code && el.territory_code == null)
  //             return newData
  //         })
  //         territoryChangeHandler(res.data.dataList[0]?.code)
  //     }).catch((err) => {
  //         console.log("ZONE", err)
  //     })
  // }

  // const territoryChangeHandler = (code) => {
  //     setTerritoryPopData((state) => {
  //         let newData = [...state]
  //         if (code == undefined) newData = []
  //         else newData = marketSizeData.filter(el => el.territory_code == code)
  //         console.log("channelData=?", newData, code)
  //         return newData
  //     })
  // }

  // function handleLoading() {
  //     if (loader) {
  //         dispatch(setDisplayLoader("Display"));
  //     }
  //     else {
  //         dispatch(setDisplayLoader("Hide"));
  //         if (territoryPopData.length == 0 && regionPopData.length == 0 && zonePopData.length == 0) {
  //             return <div className={styles["nodata"]}><h4 class="inner-page-title m-0 " style={{ margin: "0" }}>NO DATA YET! PLEASE ADD</h4></div>
  //         }
  //     }
  // }

  //MARKUP JSX
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["agri-portal-controls"]}
          style={{ paddingBottom: "0" }}
        >
          <div>
            <h4 class="inner-page-title m-0 " style={{ margin: "0" }}>
              Rolling Plan
            </h4>
          </div>
          <div>
            {/* <div>
                        <div
                            style={{
                                zIndex: 0,
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                // marginBottom: "10px",
                            }}
                        >
                            <div className={styles["agri-portal-label"]}>
                                <b>CURRENT FY</b>
                            </div>
                            <div className={styles["valuebox"]}>{cfy}</div>
                        </div>
                    </div> */}
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div className={styles["agri-portal-controls"]}>
          <div className={styles["left"]}>
            {/* <div>
                        <div>
                            <Dropdown show>
                            <Dropdown.Toggle
                                variant="primary-inner dropdownbtn"
                                id="dropdown-basic"
                            >
                               {territory?territory.name:"Territory"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    territories && territories.map(el=>{
                                        return <Dropdown.Item href="#" onClick={e=>setTerritory(el)}>
                                           {el.name}
                                        </Dropdown.Item>
                                    })  
                                }
                            </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div> */}
            <div style={{ display: "flex", gap: "10px" }}>
              <select
                className="form-control newbgselect"
                name="business_unit_id"
                style={{
                  cursor: "pointer",
                  border: "1px solid #0195D4",
                  color: "#0195DA",
                  minWidth: "120px",
                }}
                // classNamePrefix="select"
                onChange={(e) => {}}
              >
                <option>Plant 1</option>
                <option>Plant 2</option>
                <option>Plant 3</option>
              </select>
            </div>
          </div>
          {showAddNew && (
            <div className={styles["right"]}>
              <div>
                <Link
                  to="/myplan/rollingplan/table?mode=create"
                  class="btn btn-primary-inner"
                >
                  Add New
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Zone Code</th>
                    <th>Zone Name</th>
                    <th>Submitted On</th>
                    <th>Submitted By</th>
                    <th>Status</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>T1</td>
                    <td>T1</td>
                    <td>1-March-2022</td>
                    <td>Raj</td>
                    <td>Approved</td>
                    <td>All good</td>
                    <td>View</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Root;
