import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import * as userGl from "../../service/userGl";

const AssignGiMapping = (props) => {
  const { show, onCloseButtonClick, edit, editUserGlData, onAddCallBack } =
    props;
  const [allEmployee, setAllEmployee] = useState([]);
  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const [allGiEmployeeData, setAllGIEmployeeData] = useState([]);
  const [giMappingData, setGiMappingData] = useState({
    user_id: 0,
    gi_user_id: 0,
    is_active: "",
  });
  const [formValidation, setFormValidation] = useState({
    user_id: "",
    gi_user_id: "",
    is_active: "",
  });

  const getUsers = (paginate) => {
    userGl
      .getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveGiUserTable = (data) => {
    userGl
      .saveGiUserTable(data)
      .then((response) => {
        onAddCallBack();
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateGiUserTable = (id, data) => {
    userGl
      .updateGiUserTable(id, data)
      .then((response) => {
        onAddCallBack();
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const pushUsers = (e, name) => {
    console.log(e, name, "giMappingData");

    if (name === "user_id") {
      setAllEmployeeData(e);
      handleChange(e, name);
    } 

    else {
      setAllGIEmployeeData(e);
      handleChange(e, name);
    }
  };

  const handleChange = (e, name) => {
    switch (name) {
      case "user_id":
        setGiMappingData((prev) => ({
          ...prev,
          user_id: e.value,
        }));
        break;
      case "gi_user_id":
        setGiMappingData((prev) => ({
          ...prev,
          gi_user_id: e.value,
        }));
        break;
      case "gi_user_id":
        setGiMappingData((prev) => ({
          ...prev,
          is_active: e.value,
        }));
        break;
      default:
    }
  };

  const validateRecords = () => {
    let isError = false;

    const valid = { ...formValidation };
    if (!giMappingData.user_id) {
      isError = true;
      valid.user_id = "*User Name is required ";
      setFormValidation(valid);
    }
    if (!giMappingData.gi_user_id) {
      isError = true;
      valid.gi_user_id = "*User GI Name is required ";
      setFormValidation(valid);
    }

    if (!(giMappingData.is_active == true || giMappingData.is_active == false)) {
      isError = true;
      valid.is_active = "*Status is required ";
      setFormValidation(valid);
    }
    return isError;
  };

  const handleSubmit = () => {
    if (validateRecords()) return false;
    if (giMappingData?.id == undefined) {
      saveGiUserTable(giMappingData);
    } else {
      updateGiUserTable(giMappingData?.id, giMappingData);
    }
  };

  useEffect(() => {
    if (editUserGlData) {
      let userCode = {
        value: editUserGlData.user_id,
        label:
          editUserGlData?.user_name +
          " " +
          "(" +
          editUserGlData?.user_code +
          ")",
      };
      setAllEmployeeData(userCode);
      let userCodeGi = {
        value: editUserGlData.gi_user_id,
        label:
          editUserGlData?.gi_user_name +
          " " +
          "(" +
          editUserGlData?.gi_user_code +
          ")",
      };
      setAllGIEmployeeData(userCodeGi);

      Object.keys(editUserGlData).forEach((item) => {
        giMappingData[item] = editUserGlData[item];
      });
    }
  }, [editUserGlData]);

  useEffect(() => {
    getUsers(false);
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={onCloseButtonClick}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{edit === true ? "Edit" : "Add"} GI Mapping</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row justify-content-center">
            <ToastContainer autoClose={1000} />
            <div class="col-md-11">
              <div class="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Username<span className="text-danger">*</span>
                    </label>
                    <Select
                      name="user_id"
                      options={allEmployee}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        pushUsers(newValue, action?.name);
                      }}
                      value={allEmployeeData}
                    />
                    <div className="small text-danger">
                      {formValidation.user_id}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Username GI<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={allEmployee}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="gi_user_id"
                      onChange={(newValue, action) => {
                        pushUsers(newValue, action?.name);
                      }}
                      value={allGiEmployeeData}
                    />
                    <div className="small text-danger">
                      {formValidation.gi_user_id}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label className="d-block">Status</label>
                    <label
                      className="logCheck d-inline-block mr-4"
                      htmlFor="active"
                    >
                      Active
                      <input
                        type="radio"
                        name="is_active"
                        id="active"
                        value={giMappingData.is_active}
                        onChange={() => {
                          setGiMappingData({
                            ...giMappingData,
                            is_active: true,
                          });
                        }}
                        checked={giMappingData.is_active === true}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      className="logCheck d-inline-block"
                      htmlFor="Inactive"
                    >
                      Inactive
                      <input
                        type="radio"
                        name="is_active"
                        id="Inactive"
                        value={giMappingData.is_active}
                        onChange={(e) => {
                          setGiMappingData({
                            ...giMappingData,
                            is_active: false,
                          });
                        }}
                        checked={giMappingData.is_active === false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="small text-danger">
                      {formValidation.is_active}
                  </div>
                  {/* { 
                         (userGlForm?.attachment_path?.match(new RegExp('[^.]+$')) != "pdf" || userGlForm?.attachment_path?.match(new RegExp('[^.]+$')) != "msg")
                          ?                         
                           <img src={userGlForm?.attachment_path} /> 
                          : ""
                      

                     } */}
                </div>



              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <>
            <button
              class="btn btn-outline-secondary mr-2"
              onClick={() => onCloseButtonClick()}
            >
              Cancel
            </button>
            <button
              class="btn btn-primary-inner bpi-main"
              type="submit"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignGiMapping;
