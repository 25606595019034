import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as ShiftService from "./shiftservice";
import Select from "react-select";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";

function AddChangeShift(props) {
  const {
    showForm,
    edit,
    onClose,
    changeShiftData,
    onAddCallBack,
    appliedShift,
    editId,
  } = props;
  const dispatch = useDispatch();
  let condition = "true";
  const [addnewshow, addnewsetShow] = useState(false);
  const [shiftList, setShiftList] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [reasonData, setReasonData] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [previousShiftList, setPreviousShiftList] = useState([]);
  const [approverDetails, setApproverDetails] = useState([]);
  const [checkPrevShiftId, setCheckPrevShiftId] = useState([]);
  const [shiftChangeDetails, setShiftChangeDetails] = useState([]);
  const [approverName, setApproverName] = useState("");
  const [shiftvalue, setShiftValue] = useState([]);
  const [reasonvalue, setReasonValue] = useState([]);
  const [reasonError, setReasonError] = useState([]);
  const [shiftchange, setShiftChange] = useState({
    newShiftId: "",
    reasonId: "",
    requestedTo_id: "",
    assignedShift: "",
    shift_date: "",
  });
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    GetShiftList();
    GetReasonList();
    GetPreviousShiftList();
  }, []);

  useEffect(() => {
    if (edit == true) {
      let obj = {
        value: changeShiftData?.change_to_id,
        label: `${changeShiftData?.shift_name}
           (${
             changeShiftData?.shift_time_in?.slice(0, 2) >= 12
               ? changeShiftData?.shift_time_in?.slice(0, 2) - 12 + " PM"
               : changeShiftData?.shift_time_in?.slice(0, 5) + " AM"
           } to
           ${
             changeShiftData?.shift_time_out.slice(0, 2) >= 12
               ? changeShiftData?.shift_time_out?.slice(0, 2) - 12 + " PM"
               : changeShiftData?.shift_time_out?.slice(0, 5) + " AM"
           })`,
      };
      setShiftData(obj);
      let obj1 = {
        value: changeShiftData?.reason_id,
        label: changeShiftData?.reasons,
      };
      setReasonData(obj1);
    }
  }, []);

  const GetShiftList = () => {
    ShiftService.getShiftList().then((response) => {
      let res = response?.data?.dataList?.result
        ?.filter((obj) => {
          if (obj.shift_name != "OFF") {
            return true;
          }
        })
        .map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.shift_name}
           (${
             obj?.shift_time_in.slice(0, 2) >= 12
               ? obj?.shift_time_in.slice(0, 2) - 12 + " PM"
               : obj?.shift_time_in.slice(0, 5) + " AM"
           } to
           ${
             obj?.shift_time_out.slice(0, 2) >= 12
               ? obj?.shift_time_out.slice(0, 2) - 12 + " PM"
               : obj?.shift_time_out.slice(0, 5) + " AM"
           })`,
          };
        });
      setShiftList(res);
    });
  };

  const GetReasonList = () => {
    ShiftService.getReasonList().then((response) => {
      let res = response?.data?.dataList?.result?.map((obj) => {
        return {
          value: obj.id,
          label: `${obj?.reason}`,
        };
      });
      setReasonList(res);
    });
  };

  const GetPreviousShiftList = () => {
    ShiftService.getPreviousShiftList().then((response) => {
      dispatch(setDisplayLoader("Hide"));
      let temp = [];
      if (edit == false) {
        let res = response?.data?.dataList?.result?.map((obj) => {
          if (
            obj.attendance_date >=
            new Date(Date.now() - 12 * 24 * 60 * 60 * 1000).toJSON().slice(0, 10)
          ) {
            appliedShift?.map((x) => {
              if (x.shift_date == obj.attendance_date && x.status !== "D") {
                obj.disableField = true;
              }
              if (x.shift_date == obj.attendance_date && x.status === "A") {
                obj.disableField = false;
              }
            });
            temp.push(obj);
            return obj;
          }
        });
      } else {
        let res = response?.data?.dataList?.result?.map((obj) => {
          if (
            obj.attendance_date >=
              new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)
                .toJSON()
                .slice(0, 10) &&
            obj.attendance_date == changeShiftData?.shift_date
          ) {
            temp.push(obj);
            return obj;
          }
        });
      }
      setPreviousShiftList(temp);
      setApproverDetails(response?.data?.dataList?.reporting_user_details);
      let name =
        response?.data?.dataList?.reporting_user_details[0].first_name +
        " " +
        response?.data?.dataList?.reporting_user_details[0].last_name +
        " (" +
        response?.data?.dataList?.reporting_user_details[0].username +
        ")";
      setApproverName(name);
    });
  };

  const handleChange = (value, name, prevShift, index) => {
    if (value.value === prevShift.prev_shift_id && name === "shift") {
      toast.error("Assigned Shift and Requested Shift can not be same");
    } else {
      let exist = checkPrevShiftId.includes(prevShift.id);
      if (exist === false) {
        let dict = {};
        let temp = {};
        if (name === "shift") {
          setShiftValue((prev) => ({
            ...prev,
            [index]: value,
          }));
          setShiftData(value);
          checkPrevShiftId.push(prevShift.id);
          dict.change_to_id = value.value;
        } else {
          setReasonValue((prev) => ({
            ...prev,
            [index]: value,
          }));
          setReasonData(value);
          dict.reason_id = value.value;
        }
        dict.assigned_shift = prevShift.prev_shift_id;
        dict.requested_to_id = approverDetails[0]?.id;
        dict.shift_date = prevShift.attendance_date;
        shiftChangeDetails.push(dict);
      } else {
        let newData = shiftChangeDetails;
        if (name === "shift") {
          newData.map((res) => {
            if (res.shift_date == prevShift.attendance_date) {
              res.change_to_id = value.value;
              setShiftValue((prev) => ({
                ...prev,
                [index]: value,
              }));
            }
          });
          setShiftChangeDetails(newData);
        } else {
          newData.map((res) => {
            if (res.shift_date == prevShift.attendance_date) {
              res.reason_id = value.value;
              setReasonData(value);
            }
          });
          setShiftChangeDetails(newData);
        }
      }
    }
  };
  // const handleSubmit = () => {
  //   if (edit == false) {
  //     let data = { data: shiftChangeDetails };
  //     ShiftService.saveShiftChangeData(data).then((response) => {
  //       toast.success("Shift Change Request Submitted Successfully");
  //     });
  //   }
  // }

  const handleSubmit = () => {
    if (condition == "true") {
      condition = "false";
      if (edit == false) {
        if (shiftChangeDetails.length > 0) {
          let count = 0;
          shiftChangeDetails?.map((res, index) => {
            previousShiftList?.map((data, index1) => {
              if ("reason_id" in res) {
                setReasonError((prev) => ({
                  ...prev,
                  [index1]: false,
                }));
              } else if (res.shift_date == data.attendance_date) {
                count = count + 1;
                setReasonError((prev) => ({
                  ...prev,
                  [index1]: true,
                }));
              }
            });
          });
          if (count > 0) {
            toast.error("Please Select Reason First");
          } else {
            let data = { data: shiftChangeDetails };
            ShiftService.saveShiftChangeData(data).then((response) => {
              condition = "true";
              // toast.success(response?.data?.message)
              onAddCallBack(response);
              onClose();
            });
          }
        }
      }
      if (edit == true) {
        let data = { data: shiftChangeDetails };
        ShiftService.updateShiftChangeData(editId, data).then((response) => {
          toast.success("Updated Successfully");
          onAddCallBack();
          onClose();
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={showForm}
        onHide={onClose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose maxwidth90 modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Shift Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Approver Name <span class="text-danger"></span>
                    </label>
                    <input
                      type="text"
                      disabled
                      value={approverName}
                      class="form-control"
                      placeholder="Enter Approver Name"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured popuptableselectwidth">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Date</th>
                          <th>Previous Shift</th>
                          <th>Select Shift</th>
                          <th>Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {previousShiftList?.length > 0 ?
                        previousShiftList?.map((res, index) => {
                          return (
                            <>
                              {/* {res?.attendance_date < new Date().toJSON().slice(0, 10)} */}
                              <tr>
                                <td>{index + 1}</td>
                                <td class="fw-bold text-theme">
                                  {moment(res?.attendance_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                {res?.shift != "OFF" ? (
                                  <>
                                    <td>
                                      {res?.shift} (
                                      {res?.in_time.slice(0, 5) + " hrs"} to{" "}
                                      {res?.out_time.slice(0, 5) + " hrs"})
                                    </td>
                                    <td
                                      style={{
                                        overflow: "visible",
                                      }}
                                    >
                                      <Select
                                        options={shiftList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        id={"shift" + index}
                                        name="shift"
                                        isDisabled={res?.disableField}
                                        // isDisabled={res?.attendance_date < new Date().toJSON().slice(0, 10)}
                                        onChange={(newValue, action) => {
                                          handleChange(
                                            newValue,
                                            action.name,
                                            res,
                                            index
                                          );
                                        }}
                                        value={
                                          edit == false
                                            ? shiftvalue[index]
                                            : shiftData
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        options={reasonList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        id={"reason" + index}
                                        name="reason"
                                        isDisabled={res?.disableField}
                                        // isDisabled={res?.attendance_date < new Date().toJSON().slice(0, 10)}
                                        // isDisabled={editRecord}
                                        onChange={(newValue, action) => {
                                          handleChange(
                                            newValue,
                                            action.name,
                                            res
                                          );
                                        }}
                                        value={
                                          edit == false
                                            ? reasonvalue[index]
                                            : reasonData
                                        }
                                      />
                                      {reasonError[index] ? (
                                        <div className="small text-danger">
                                          **Kindly select reason
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <span class="text-danger">OFF</span>
                                    </td>
                                    <td>
                                      <span class="text-danger">OFF</span>
                                    </td>
                                    <td>
                                      <span class="text-danger">OFF</span>
                                    </td>
                                  </>
                                )}
                              </tr>
                            </>
                          );
                        })
                        :
                        <tr>
                          <td colSpan={7}>Loading please wait...</td>
                        </tr>
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={(e) => {
              onClose();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary-inner bpi-main"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddChangeShift;
