import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as TravelListingAPI from "../../service/travelService";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import ReactPaginate from "react-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

function MyOtherAdvance(props) {
  const { backDateData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, filtersetShow] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageRecord, setpageRecord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [travelList, setTravelList] = useState([]);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [userDataNew, setUserDataNew] = useState(false);
  const userDetails = useSelector(selectUserData);

  const getBackDateList = async (data) => {
    await TravelListingAPI.getBackDateList(data)
      .then((response) => {
        setTravelList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAdvanceDetails = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    from_date,
    to_date
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getAdvanceDetails(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        status,
        from_date,
        to_date
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        let data = response?.data?.dataList?.result.map((each) => {
          // let rejected = each?.approval_list?.filter((x) => {
          //   if (x?.status === "R") {
          //     return true;
          //   }
          // });
          // if (rejected?.length > 0) {
          //   each.rejected_by = `${rejected[0]?.full_name} (${rejected[0]?.username})`;
          // }

          each.rejected_by = '';
          let j = each?.approval_list.length - 1;
          while (each.rejected_by.length == 0 && j >= 0) {
            if (each?.approval_list[j].status === 'R') each.rejected_by = `${each?.approval_list[j].full_name} (${each?.approval_list[j].username})`;
            j--;
          }
          return each;
        });
        setUserDataNew(data);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
        // dispatch(setMessage(response?.data?.dataList?.result));
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    from_date,
    to_date
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    return queryParm;
  };

  // const getUserDetails = async () => {
  //   await TravelListingAPI.getAdvanceDetails()
  //     .then((response) => {
  //       let res = response?.data?.dataList?.result;
  //       setUserDataNew(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getAdvanceDetails(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getAdvanceDetails(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id
      );
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getAdvanceDetails(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id
      );
    }
  };

  useEffect(() => {
    // getBackDateList(userDetails?.id);
    // getUserDetails();
    getAdvanceDetails(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "",
      "",
      "",
      ""
    );
  }, []);

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12 d-flex align-items-start">
                  <div class="col-md-4" style={{ float: "left" }}>
                    {" "}
                    <h5 class="inner-page-title">My Other Advance</h5>
                  </div>
                  <div className="col-md-8 text-right" style={{ float: "right" }}>
                    <span
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      *Transactions approved between date 1st to 5th of every
                      month, may delay due to posting period not open.
                    </span>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
                <div class="col-md-12">
                  <div class="col-md-4" style={{ float: "left" }}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Employee Code,
                              <br />
                              Employee Name, <br />
                              Request Id <br />
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          class="form-control"
                          placeholder="Search on Requested Id..."
                          onChange={(e) => {
                            handleSearch(e);
                            setCurrentPage(1);
                          }}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>

                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8" style={{ float: "right" }}>
                    <div class="inline-spacing btn-betweenspaing">
                      <button
                        className="btn btn-primary-inner"
                        onClick={() => {
                          navigate("/otheradvance/applyadvance");
                        }}
                      >
                        <img src="images/upload.png" alt="" className="mr-3" />
                        Add Other Advance
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured travelintim">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th> Requested Id </th>
                    <th> Requested On </th>
                    <th> Advance Reason</th>
                    <th> Advance Amount</th>
                    <th> Settelment Date</th>
                    <th style={{ width: 250 }}> Status</th>
                    <th>Remarks</th>
                    <th>SAP Doc No.</th>
                    <th>SAP Message</th>
                  </tr>
                </thead>
                <tbody>
                  {userDataNew?.length ? (
                    userDataNew?.map((data, index) => (
                      <tr>
                        <td>
                          {data?.status === "R" ? (
                            <button
                              class="btn btn-blanktd text-primary"
                              onClick={() => {
                                navigate("/otheradvance/applyadvance", {
                                  state: { data: data, flag: true },
                                });
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                        <td><Link to={`/otheradvance/view-other-advance?${data?.id}`}>{`${data?.user_branch}OA${data?.id}`}</Link></td>
                        <td>{moment(data?.created_at).format("DD-MM-YYYY")}</td>
                        <td>{data?.reason_advance_amount ?? "-"}</td>
                        <td> {data?.advance_amount ?? "-"}</td>
                        <td>
                          {moment(data?.advance_amount_date).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td className="d-flex justify-content-center">
                          <Tooltip
                            position="bottom"
                            title={
                              data?.status === "A"
                                ? <span>Approved</span>
                                : data?.status === "P"
                                  ? ` Pending at ${data?.approve} ${data?.approve_username}`
                                  : data?.status === "R"
                                    ? `Rejected at ${data?.rejected_by}`
                                    : data?.status === "D"
                                      ? "Draft"
                                      : "-"
                            }
                          >
                            {
                              data?.status === "A" ? (
                                <span class="badge bad-status badge-success">
                                  Approved
                                </span>
                              )
                                : data?.status === "P" ? (
                                  <span class="badge bad-status badge-warning" style={{ whiteSpace: 'pre-wrap', minWidth: 150 }}>
                                    Pending at {`${data.approve} (${data.approve_username})`}
                                  </span>
                                )
                                  : data?.status === "R" ? (
                                    <span class="badge bad-status badge-danger" style={{ whiteSpace: 'pre-wrap', minWidth: 150 }}>
                                      Rejected by {data?.rejected_by}
                                    </span>
                                  )
                                    : data?.status === "D" ? (
                                      <span class="badge bad-status badge-danger">
                                        Draft
                                      </span>
                                    )
                                      : (
                                        "-"
                                      )
                            }
                            {/* {data?.approve_username === null ||
                              data?.approve_username === ""
                              ? ""
                              : data?.approve_username} */}
                          </Tooltip>
                        </td>
                        <td>{data?.remark}</td>
                        <td>{data?.sap_doc_no}</td>
                        <td>{data?.sap_message?.length > 25 ? <Tooltip
                          position="bottom"
                          title={data?.sap_message}
                        >{`${data?.sap_message.substring(0, 25)}...`}</Tooltip> : data?.sap_message}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {userDataNew?.length == 0 ? (
              ""
            ) : (
              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control main-pointer"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-12 text-center">
                  <i class="fas fa-check-circle fa-2x text-success mt-3 mb-3"></i>
                  <p class="fa-20 text-muted mb-4">
                    You are generating SAP Trip No, After this you will not be
                    able to change tour
                  </p>

                  <div class="mb-3">
                    <Link to="/claim-listing" class="mr-2">
                      <button class="btn btn-primary-inner bpi-main f-14 py-1">
                        Yes
                      </button>
                    </Link>
                    <button
                      class="btn btn-outline-primary f-14 py-1"
                      onClick={addnewclose}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default MyOtherAdvance;
