import React, { useEffect, useMemo, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import CreatePrForm from "./create-pr-from";
import * as PrService from "../../service/prService";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
/* formik */
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { prMasterCodes } from "../../constants/Constants";
import { setDisplayLoader, selectFinalUrlPermission, setTracker } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import { useDispatch, useSelector } from "react-redux";
import { changeprstatus, getPrTransactions, getPrTransactionsApproval } from "../../service/prService";


let createFlag = false;
let createFlagByQry = false;
function CreatePR() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [DraftFlag, setDraftFlag] = useState(false);
  const [errorForHeader, setErrorForHeader] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [rejectFlag, setRejectFlag] = useState(false);
  const [rejectedItemId, setRejectedItemId] = useState("");
  const [viewPrFlag, setViewPrFlag] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [subItemIndex, setSubItemIndex] = useState(null);
  const [itemNO, setItemNo] = useState("");
  const [sapPrNo, setSapPrNo] = useState("");
  const [subItemFlag, setSubItemFlag] = useState(false)
  const [createSubItemFlag, seCreatetSubItemFlag] = useState(false)
  const [checkedId, setCheckedId] = useState([]);
  const [trackerData, setTrackerData] = useState({
    permission_id: 0,
    transaction_id: 0,
    attachment_id: 0,
  });
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const accountAssignmentValues = {
    costCenter: "K",
    asset: "A",
    order: "F",
    network: "N",
  };
  const navigate = useNavigate();
  const [prTypeOptions, setPrTypeOptions] = useState([]);
  const [prTypes, setPrTypes] = useState([]);
  const [prHeaderText, setPrHeaderText] = useState("");
  const [prSource, setPrSource] = useState("");
  const [imgSize, setImgSize] = useState(false);
  const [attachment, setAttachment] = useState({});
  const [image, setImage] = useState([])
  const [price, setPrice] = useState(null);
  const [Status, setstatus] = useState({
    id: "",
    status: "",
    remarks: "",
  });

  const SUB_ITEM_INITIAL = [
    {
      // line: '',
      service_no: "",
      short_text: "",
      quantity: "",
      unit: "",
      unit_name: "",
      gross_price: "",
      currency: "",
      net_value: "",
      gl_account_id: "",
      order_no: "",
      network_no_id: "",
      cost_center_id: "",
    },
  ];

  const INITIAL = {
    pr_type: "",
    // item_no: '',
    item_category: "",
    account_assignment: "",
    plant_id: "",
    plant_name: "",
    material_group: "",
    material_code: "",
    material_code_name: "",
    material_desc: "",
    purchase_group_id: "",
    purchase_group_name: "",
    purchase_org_id: "",
    suggested_vendor_id: "",
    hsn_code: "",
    cas: "",
    header_text: "",
    requisition_quantity: "",
    price_per_unit: "",
    valuation: "",
    valuation_type: "",
    gl_account_id: "",
    network_no_id: "",
    pr_unit_id_name: "",
    pr_unit_id: "",
    cost_center_id: "",
    asset_code_id: "",
    is_capex: "",
    tracking_no: "",
    order_category: "",
    order_no_id: "",
    delivery_date: moment(new Date).format("YYYY-MM-DD"),
    wbs_element_id: "",
    remarks: "",
    sub_transaction_data: SUB_ITEM_INITIAL,
  };
  const initialValues =
    location?.state?.from?.items != undefined
      ? location?.state?.from?.items
      : [INITIAL];

  const validationSchema = Yup.array()
    .of(
      Yup.object().shape({
        pr_type: Yup.string().required("PR type is required"),
        // item_no: Yup.number().when('pr_type', {
        //   is: pr_type => [prMasterCodes.servicePr].includes(pr_type),
        //   then: Yup.number().typeError('Price must be a valid number').required("Required")
        // }),
        item_category: Yup.string().when("pr_type", {
          is: (pr_type) => [prMasterCodes.servicePr].includes(pr_type),
          then: Yup.string().required("Required"),
        }),
        account_assignment: Yup.string().when("pr_type", {
          is: (pr_type) =>
            [
              prMasterCodes.servicePr,
              prMasterCodes.textPr,
              prMasterCodes.projectPr,
              prMasterCodes.assetPr,
            ].includes(pr_type),
          then: Yup.string().required("Required"),
        }),

        // hsn_code: Yup.string().when("pr_type", {
        //   is: (pr_type) =>
        //     [
        //       prMasterCodes.standardPr,
        //       prMasterCodes.assetPr,
        //       prMasterCodes.textPr,
        //       prMasterCodes.projectPr,
        //     ].includes(pr_type),
        //   then: Yup.string().required("Required"),
        // }),
        // cas: Yup.string().when('pr_type', {
        //   is: pr_type => [prMasterCodes.standardPr].includes(pr_type),
        //   then: Yup.string().required("Required")
        // }),
        // requisition_quantity: Yup.number().when("pr_type", {
        //   is: (pr_type) =>
        //     [
        //       prMasterCodes.standardPr,
        //       prMasterCodes.assetPr,
        //       prMasterCodes.textPr,
        //       prMasterCodes.projectPr,
        //     ].includes(pr_type),
        //   then: Yup.number()
        //     .typeError("Price must be a valid number")
        //     .required("Required"),
        // }),
        // price_per_unit: Yup.number().when("pr_type", {
        //   is: (pr_type) =>
        //     [
        //       prMasterCodes.standardPr,
        //       prMasterCodes.assetPr,
        //       prMasterCodes.textPr,
        //       prMasterCodes.projectPr,
        //     ].includes(pr_type),
        //   then: Yup.number()
        //     .typeError("Price must be a valid number")
        //     .required("Required"),
        // }),
        // valuation_type: Yup.string().when('pr_type', {
        //   is: pr_type => [prMasterCodes.standardPr].includes(pr_type),
        //   then: Yup.string().required("Required")
        // }),
        // gl_account_id: Yup.string().when("pr_type", {
        //   is: (pr_type) =>
        //     [
        //       prMasterCodes.textPr,
        //       prMasterCodes.projectPr,
        //       prMasterCodes.assetPr,
        //     ].includes(pr_type),
        //   then: Yup.string().required("Required"),
        // }),
        // wbs_element_id: Yup.string().when("pr_type", {
        //   is: (pr_type) => [prMasterCodes.projectPr].includes(pr_type),
        //   then: Yup.string().required("Required"),
        // }),

        // pr_unit_id_name: Yup.string().when("pr_type", {
        //   is: (pr_type) =>
        //     [
        //       prMasterCodes.standardPr,
        //       prMasterCodes.assetPr,
        //       prMasterCodes.textPr,
        //       prMasterCodes.projectPr,
        //     ].includes(pr_type),
        //   then: Yup.string().required("Required"),
        // }),

        // material_code: Yup.string().when("pr_type", {
        //   is: (pr_type) =>
        //     [
        //       prMasterCodes.standardPr,
        //       prMasterCodes.assetPr,
        //       prMasterCodes.projectPr,
        //     ].includes(pr_type),
        //   then: Yup.string().required("Required"),
        // }),
        material_group: Yup.string().typeError("Required").required("Required"),
        material_desc: Yup.string().required("Required"),

        plant_id: Yup.number().typeError("Required").required("Required"),
        purchase_group_id: Yup.number()
          .typeError("Required")
          .required("Required"),
        purchase_org_id: Yup.number()
          .typeError("Required")
          .required("Required"),

        // suggested_vendor_id: Yup.number()
        //   .typeError("Required")
        //   .required("Required"),
        // cost_center_id: Yup.string().when("pr_type", {
        //   is: (pr_type) => [prMasterCodes.textPr].includes(pr_type),
        //   then: Yup.string().required("Required"),
        // }),
        // is_capex: Yup.string().required("Required"),
        tracking_no: Yup.string().required("Required"),
        // order_category: Yup.string().test(
        //   "order_category",
        //   "Required",
        //   (value, validationContext) => {
        //     const {
        //       parent: { pr_type, account_assignment },
        //     } = validationContext;
        //     if (
        //       [prMasterCodes.textPr, prMasterCodes.servicePr].includes(
        //         pr_type
        //       ) &&
        //       account_assignment == accountAssignmentValues.order &&
        //       !value
        //     ) {
        //       return false;
        //     }
        //     return true;
        //   }
        // ),
        // order_no_id: Yup.string().test(
        //   "order_no_id",
        //   "Required",
        //   (value, validationContext) => {
        //     const {
        //       parent: { pr_type, account_assignment },
        //     } = validationContext;
        //     if (
        //       [prMasterCodes.textPr].includes(pr_type) &&
        //       account_assignment == accountAssignmentValues.order &&
        //       !value
        //     ) {
        //       return false;
        //     }
        //     return true;
        //   }
        // ),
        // asset_code_id: Yup.string().test(
        //   "asset_code_id",
        //   "Required",
        //   (value, validationContext) => {
        //     const {
        //       parent: { pr_type, account_assignment },
        //     } = validationContext;
        //     if (pr_type == prMasterCodes.assetPr && !value) return false;
        //     if (
        //       pr_type == prMasterCodes.textPr &&
        //       account_assignment == accountAssignmentValues.asset &&
        //       !value
        //     ) {
        //       return false;
        //     }
        //     return true;
        //   }
        // ),
        delivery_date: Yup.date().required("Required"),
        // remarks: Yup.string().required("Required"),
        // network_no_id: Yup.number().test(
        //   "network_no_id",
        //   "Required",
        //   (value, validationContext) => {
        //     const {
        //       parent: { pr_type, account_assignment },
        //     } = validationContext;
        //     if (
        //       pr_type == prMasterCodes.textPr &&
        //       account_assignment == accountAssignmentValues.network &&
        //       !value
        //     ) {
        //       return false;
        //     }
        //     return true;
        //   }
        // ),
        sub_transaction_data: Yup.array(),
      })
    )
    .required();

  /* deleting unnessary field from payload according to pr type */
  const handleSubmitKeys = (item) => {
    switch (item.pr_type) {
      case prMasterCodes.standardPr:
        delete item.item_no;
        delete item.item_category;
        delete item.account_assignment;
        // delete item.material_group;
        // delete item.material_desc;
        item.material_id = item.material_id;
        delete item.material_code;
        delete item.cas;
        // delete item.valuation_type;
        delete item.gl_account_id;
        delete item.network_no_id;
        // delete item.pr_unit_id_name;
        delete item.cost_center_id;
        delete item.asset_code_id;
        delete item.order_category;
        delete item.order_no_id;
        delete item.wbs_element_id;
        delete item.sub_transaction_data;
        break;

      case prMasterCodes.textPr:
        delete item.item_no;
        delete item.item_category;
        if (item.account_assignment == accountAssignmentValues.asset) {
          delete item.network_no_id;
          delete item.order_category;
          delete item.order_no_id;
        } else if (item.account_assignment == accountAssignmentValues.network) {
          delete item.asset_code_id;
          delete item.order_category;
          delete item.order_no_id;
        } else if (item.account_assignment == accountAssignmentValues.order) {
          delete item.asset_code_id;
          delete item.network_no_id;
        } else if (
          item.account_assignment == accountAssignmentValues.costCenter
        ) {
          delete item.asset_code_id;
          delete item.network_no_id;
          delete item.order_category;
          delete item.order_no_id;
        }
        // item.material_id = item.material_group;
        // item.material_id = item.material_group;
        // delete item.material_group;
        delete item.material_code;
        delete item.cas;
        delete item.valuation_type;
        delete item.wbs_element_id;
        delete item.sub_transaction_data;
        break;

      case prMasterCodes.projectPr:
        delete item.item_no;
        delete item.item_category;
        item.material_id = item.material_id;
        delete item.material_group;
        delete item.material_code;
        // delete item.material_desc;
        // delete item.pr_unit_id_name;
        delete item.cas;
        delete item.cost_center_id;
        delete item.order_category;
        delete item.order_no_id;
        delete item.valuation_type;
        delete item.asset_code_id;
        delete item.network_no_id;
        delete item.sub_transaction_data;
        break;

      case prMasterCodes.assetPr:
        delete item.item_no;
        delete item.item_category;
        item.material_id = item.material_id;
        delete item.material_group;
        delete item.material_code;
        // delete item.material_desc;
        // delete item.pr_unit_id_name;
        delete item.cas;
        // delete item.hsn_code;
        delete item.cost_center_id;
        delete item.order_category;
        delete item.order_no_id;
        delete item.valuation_type;
        delete item.wbs_element_id;
        delete item.network_no_id;
        delete item.sub_transaction_data;
        break;

      default:
        /* service pr case */
        if (item.account_assignment != accountAssignmentValues.order) {
          delete item.order_category;
        }
        delete item.cas;
        delete item.gl_account_id;
        delete item.order_no_id;
        delete item.material_code;
        delete item.network_no_id;
        delete item.asset_code_id;
        delete item.wbs_element_id;
        delete item.cost_center_id;
        delete item.valuation_type;
        delete item.price_per_unit;
        delete item.requisition_quantity;
        delete item.pr_unit_id_name;
        delete item.hsn_code;
        break;
    }
    delete item.pr_type;
    return item;
  };
  const formik = useFormik({
    initialValues,
    validationSchema:
      location?.state?.from?.items == undefined && validationSchema,
    onSubmit: async (values, { resetForm }) => {
      dispatch(setDisplayLoader("Display"));
      setTrackerData({
        ...trackerData,
        // transaction_id: j.pk,
        // attachment_id: at.id,
        permission_id: finalUrlValue?.A?.id,
      });
      const valuesTemp = JSON.parse(JSON.stringify(values));
      if (valuesTemp[0].pr_type == prMasterCodes.servicePr) {
        for (const item of valuesTemp) {
          for (const subItem of item.sub_transaction_data) {
            const temp = { ...subItem };
            if (item.account_assignment === "F") {
              delete temp.net_value;
              delete temp.unit_name;
              delete temp.cost_center_id;
              delete temp.order_name;
              delete temp.order_desc;
              if (!temp.order_no) {
                delete temp.order_no;
                // return toast.warn("Please completed sub item form");
              }
            } else {
              delete temp.order_no;
            }
            if (item.account_assignment === "N") {
              delete temp.net_value;
              delete temp.unit_name;
              delete temp.cost_center_id;
              delete temp.order_name;
              delete temp.order_desc;
              if (!temp.network_no_id) {
                // return toast.warn("Please completed sub item form");
              }
            } else {
              delete temp.network_no_id;
            }
            if (item.account_assignment === "K") {
              delete temp.cost_center_code;
              delete temp.cost_center_desc;
              delete temp.cost_center_name;
              delete temp.net_value;
              delete temp.unit_name;
              delete temp.order_name;
              delete temp.order_desc;
            }
            if (temp.service_no === "") {
              delete temp.service_no
            }
            console.log("iugf=======", temp);
            const notFilledFeilds = Object.values(temp).filter((e) => !e);
            if (notFilledFeilds.length > 0) {
              dispatch(setDisplayLoader("Hide"));
              return toast.warn("Kindly  add sub line items before Submit/Save PR");
            }
          }
        }
      }
      const payload = {
        pr_type_id: prTypes.find(
          ({ pr_code }) => pr_code == valuesTemp[0].pr_type
        ).id,
        transaction_data: [],
      };

      /* pr_type_id will same in all forms */
      for (let item of valuesTemp) {
        item = handleSubmitKeys(item);
        payload.transaction_data.push(item);
      }
      if (DraftFlag) {
        payload.DraftFlag = true;
      } else {
        payload.DraftFlag = false;
      }
      if (prHeaderText !== "") {
        payload.header_text = prHeaderText
      }
      if (attachment !== {}) {
        payload.attachment = attachment
      }
      if ((DraftFlag == true || (prHeaderText !== "" && DraftFlag == false))) {
        if (location?.state?.from?.items == undefined) {
          PrService.addPrTransaction(payload)
            .then((response) => {
              if (response.data.code === 200) {
                dispatch(setDisplayLoader("Hide"));
                setDraftFlag(false);
                resetForm();
                toast.success(response.data.message);
                navigate("/pr-list");
              }
            })
            .catch((error) => {
              console.log(error);
              toast.warn(error.message);
            });
        } else {
          payload.id = location?.state?.from?.id;
          PrService.updatePrTransaction(payload)
            .then((response) => {
              if (response.data.code === 200) {
                dispatch(setDisplayLoader("Hide"));
                setDraftFlag(false);
                resetForm();
                toast.success(response.data.message);
                navigate("/pr-list");
              }
            })
            .catch((error) => {
              console.log(error);
              toast.warn("Kindly Fill current form");
              dispatch(setDisplayLoader("Hide"));
            });
        }
      }
      else {
        if (prHeaderText === "") {
          toast.error("Kindly Enter Header Text")
          dispatch(setDisplayLoader("Hide"));
          setErrorForHeader(true)
        }
        else {
          setErrorForHeader(false)
        }
      }

    },
  });

  const { values, handleSubmit, setValues, errors, setFieldValue } = formik;

  const isFormCompleted = (form, showToast = false) => {
    if (form.pr_type == prMasterCodes.servicePr) {
      for (const subItem of form.sub_transaction_data) {
        const temp = { ...subItem };
        if (form.account_assignment != "Order") {
          delete temp.order_no;
        }
        const notFilledFeilds = Object.values(temp).filter((e) => !e);
        if (notFilledFeilds.length > 0) {

          // showToast && toast.warn("Please completed sub item form");
          // return false;
        }
      }
    }
    if (Object.keys(errors).length > 0) {
      showToast && toast.warn("Please complete current form before add new");
      return false;
    }
    return true;
  };

  const handleAddForm = () => {
    setEditMode(false)
    setViewPrFlag(true)
    for (const item of values) {
      if (!isFormCompleted(item, true)) {
        return;
      }
    }
    const temp = { ...INITIAL };
    temp.pr_type = values[0].pr_type;
    setValues((prev) => [...prev, temp]);
  };

  const deletePR = (pr_id) => {
    PrService.deletePR(pr_id)
      .then((response) => {
        if (response?.data?.code === 200) {
          // const temp = [...values];
          // temp.splice(index, 1);
          // setValues(temp, false);
          toast.success(response?.data?.message);
          window.history.go(-1);
        }
      })
  }

  const handleRemove = (index, id, pr_id) => {
    confirmAlert({
      title: "Confirm to proceed",
      message: "Are you sure to delete this Item and it's Sub-Items?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await PrService.deletePrItem(id)
              .then((response) => {
                if (response?.data?.code === 200) {
                  const temp = [...values];
                  temp.splice(index, 1);
                  setValues(temp, false);
                  toast.success(response?.data?.message);
                  if (validForms.length === 1) {
                    deletePR(pr_id)
                  }
                }
              })
              .catch((error) => {
                toast.error(error?.response?.data?.message ?? error.message);
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("bye"),
        },
      ],
    });
  };

  const handleSubItemCloseModal = () => {
    setSubItemFlag(false)
    // window.history.go(-1)
  }

  const handleEditPR = (index, currentApprover, item_id) => {
    setViewPrFlag(true)
    if (location?.state?.viewPRFlag !== true) {
      if (Object.keys(errors).length !== 0) {
        values.pop()
        // const temp = [...values];
        // temp.pop()
        // setValues(temp, false);
      }
    }
    setEditIndex(index)
    setEditMode(true)
    if (currentApprover?.length > 0) {
      if (currentApprover[index]?.status === "D") {
        setRejectFlag(true)
        setRejectedItemId(item_id)
      }
      else {
        setRejectFlag(false)
      }
    }
    // const temp = [...values];
    // temp.splice(index, 1);
    // setValues(temp, false);
  };

  const getPrTypes = () => {
    PrService.getPrTypes("paginate=false")
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.pr_code,
            label: item?.pr_code + "(" + item?.pr_desc + ")",
          }));
          setPrTypeOptions(options);
          setPrTypes(list);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.message);
      });
  };

  const validForms = useMemo(() => {
    return values.filter((item, i) => {
      if ((Object.keys(errors).length === 0 || !errors[i]))
        return true;
      if (errors[i] && Object.keys(errors[i]).length === 0) return true;
    });
  }, [values]);

  useEffect(() => {
    let qry = new URLSearchParams(window.location.search)
    createFlagByQry = qry.get("createFlag")
    getPrTypes();
    if (location?.state?.header_text !== undefined && location?.state?.header_text !== null) {
      setPrHeaderText(location?.state?.header_text)
    }
    if (location?.state?.from?.sap_pr_no !== undefined && location?.state?.from?.sap_pr_no !== null) {
      setSapPrNo(location?.state?.from?.sap_pr_no)
    }
    if (location?.state?.from?.attachment !== undefined && location?.state?.from?.attachment !== null && location?.state?.from?.attachment !== []) {
      setImage(location?.state?.from?.attachment)
    }
    if (location?.state?.source !== undefined && location?.state?.source !== null) {
      setPrSource(location?.state?.source)
    }
    if (location?.state?.currentApprover !== undefined && location?.state?.currentApprover !== null && location?.state?.currentApprover?.length > 0) {
      let obj = location?.state?.currentApprover[0]?.currentApprover?.map((res) => {
        return res?.status
      })
      if (location?.state?.currentApprover[0]?.user_status === "A" || location?.state?.currentApprover[0]?.status === "A" || (obj.includes('A') || location?.state?.from?.sap_pr_no === undefined || location?.state?.from?.sap_pr_no === null)) {
        setIsApproved(true)
      }
      if (obj.includes('D')) {
        setIsRejected(true)
      }
    }
  }, []);

  const onProfileChange = (e) => {
    Object.values(e.target.files)?.map((res) => {
      const files = res;
      if (res) {
        image.push(URL.createObjectURL(res));
      }
      if (files.size < 1033415 || files.size === 1033415) {
        setImgSize(false);
        const fileData = new FormData();
        fileData.append("uploaded_file", files);
        PrService.uploadImages(fileData).then((response) => {
          setAttachment(response.data)
          if(response?.data?.status === false){
            toast.error(response?.data?.message)
          }
        });
      } else {
        setImgSize(true);
      }
    })
  };

  const handleRemoveImage = (index) => {
    let imageData = JSON.parse(JSON.stringify(image));
    imageData.splice(index, 1);
    setImage([...imageData]);
  }

  const changestatus = async (status, id) => {
    let id_obj = values?.map((res) => {
      return res?.id
    })
    Status.status = status
    Status.itemId = id_obj
    Status.id = id
    try {
      const response = await changeprstatus(Status);
      if (response.data.code === 200) {

        if (status == "A") {
          toast.success("PR Item Approved")
          window.history.go(-1);
        } else if (status == "D") {
          toast.warning("PR Rejected")

        }
        // window.history.go(-1);
        // return false;
      } else {
        toast.warn(response.data.message);
      }
    } catch (error) {
      console.log("error", error);
      toast.warn(error.message);
    }
  };

  const reSubmitPR = () => {
    PrService.reSubmitPR(rejectedItemId).then((response) => {
      if (response.data.code === 200) {
        toast.success(response.data.message);
        window.history.go(-1)
      }
    })
  }

  const calculateGross = (value) => {
    let result = 0;
    value?.map((res) => {
      result += (res?.gross_price * res?.quantity)
    });
    return result
  }

  useEffect(() => {
    dispatch(setTracker(trackerData));
  }, [trackerData]);

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link to="/create-pr">Create PR</Link>
            </li>
            <li>
              <Link to="/pr-list">My PR List</Link>
            </li>
           
            <li>
              <Link to="/pr-unit-master">Masters</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-4">
                  <h4 class="inner-page-title">Add New PR</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transaction</Breadcrumb.Item>
                    <Breadcrumb.Item active>Add New</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div className="col-md-4 mt-3">
                  <span className="fw-bold" style={{ color: "#0195d4" }}>SAPPR No- {location?.state?.from?.sap_pr_no}</span>
                </div>
                <div className="col-md-4 mt-3">
                  <span className="fw-bold" style={{ color: "#0195d4" }}>PR No- PRNO{location?.state?.from?.id}</span>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              {prSource !== "E" && location?.state?.from?.sap_pr_no !== undefined && location?.state?.from?.sap_pr_no !== null && location?.state?.from?.sap_pr_no !== "" &&
                <div style={{ marginLeft: "83%" }} hidden={isApproved}>
                  <button onClick={() => changestatus("A")} class="btn btn-primary-inner bpi-main">
                    <i class="far fa-check-square"></i> Submit for Approval
                  </button>
                </div>
              }
              <Tabs defaultActiveKey="Create" id="approvaltabs">
                <Tab
                  eventKey="Create"
                  title={<React.Fragment>PR</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured viewpo">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Item</th>
                          <th>Sub-Item</th>
                          <th>I</th>
                          <th>A</th>
                          <th>Plant</th>
                          <th>Material</th>
                          <th>Mat Desc</th>
                          <th>Unit</th>
                          <th>Qty.</th>
                          <th>Price/ Unit</th>
                          <th>Total Price</th>
                          <th>Del.Date</th>
                          <th>Val.Type</th>
                          <th>Pur.Grp.</th>
                          <th>Track. No.</th>
                          <th>Pending At</th>
                          <th>Status</th>
                          <th>SAP Status</th>
                          <th>Remarks</th>
                          {/* {prSource !== "E" &&
                          <th>Approval</th>
                          } */}
                        </tr>
                      </thead>
                      <tbody>
                        {values.length > 0 && values[0].pr_type && validForms.map((item, i) => (
                          <tr key={`pr-from-${i}`}>
                            <td>
                              {(location?.state?.isDraft === true || location?.state?.createFlag === true || (item?.currentApprover?.length > 0 && item?.currentApprover[0]?.status === "D")) && (
                                <button class="btn btn-blanktd text-primary f-12" onClick={() => handleEditPR(i, item?.currentApprover, item.id)}>
                                  <i class="far fa-edit"></i>
                                </button>
                              )}
                              {
                                (location?.state?.isDraft === true || location?.state?.createFlag === true) && (
                                  <span
                                    class="btn btn-blanktd text-danger ml-2 f-12"
                                    onClick={() => handleRemove(i, item.id, item.pr_id)}
                                  >
                                    <i class="far fa-trash-alt text-danger"></i>
                                  </span>
                                )}
                            </td>
                            <td>
                              <Link
                                to="/view-pr-approval"
                                state={{
                                  from: item?.id,
                                  // subId: currentsubType,
                                  Itemsflag: true,
                                  // flag: false,
                                }}
                              >
                                <span className="badge badge-primary">
                                  {item?.item_no}
                                </span>
                              </Link>
                            </td>
                            {item.sub_transaction_data.length > 0 ?
                              <td>
                                {item.pr_type == prMasterCodes.servicePr && item?.delete_indicator !== "X" ? (
                                  <a href="#" class="badge badge-primary"
                                    onClick={() => {
                                      setSubItemFlag(true);
                                      setSubItemIndex(i);
                                      setItemNo(item?.item_no);
                                      setEditIndex(i)
                                      setEditMode(true)
                                    }}>
                                    {item.sub_transaction_data.length}
                                  </a>
                                ) : item?.delete_indicator === "X" ? (
                                  <span className="badge badge-primary">
                                    {item.sub_transaction_data.length}
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              :
                              <td>
                                {item.pr_type == prMasterCodes.servicePr && item?.delete_indicator !== "X" ? (
                                  <a href="#" class="badge badge-primary" onClick={() => { setSubItemFlag(true); seCreatetSubItemFlag(true) }}>
                                    <i class="far fa-plus-square mr-2"></i>
                                  </a>
                                ) : (
                                  "--"
                                )}
                              </td>
                            }
                            <td>{item?.item_category}</td>
                            <td>{item?.account_assignment}</td>
                            <td>{item.plant_name}</td>
                            <td>{item.material_code_name}</td>
                            {item?.material_id === null ?
                            <td>{item?.material_desc}</td>
                            :
                            <td>{item?.mat_desc2}</td>
                            }
                            {values.length > 0 && values[0]?.pr_type !== "S" ?
                              <>
                                <td>{item?.pr_unit_id_name}</td>
                                <td>{item?.requisition_quantity}</td>
                                <td>{Number(item?.valuation).toFixed(2)}</td>
                                <td>{Number(item?.requisition_quantity * item?.valuation).toFixed(2)}</td>
                              </>
                              :
                              <>
                                <td>AU</td>
                                <td>1</td>
                                {item?.sum_price !== undefined ?
                                  <>
                                    <td>{Number(item?.sum_price).toFixed(2)}</td>
                                    <td>{Number(item?.sum_price).toFixed(2)}</td>
                                  </>
                                  :
                                  <>
                                    <td>
                                      {calculateGross(values[i]?.sub_transaction_data)}
                                    </td>
                                    <td>
                                      {calculateGross(values[i]?.sub_transaction_data)}
                                    </td>
                                  </>
                                }
                              </>
                            }
                            <td>{moment(item.delivery_date).format("DD-MM-YYYY")}</td>
                            <td>{item?.valuation_type}</td>
                            <td>{item.purchase_group_name}</td>
                            <td>{item.tracking_no}</td>
                            {item?.currentApprover?.length > 0 ?
                              item?.currentApprover[0]?.approver_type === "Approver 2" ?
                                <>
                                  {item?.currentApprover[0]?.status === "P" ?
                                    <>
                                      <td>
                                        <Tooltip title={item?.currentApprover[0]?.approver_name} position="bottom">
                                          {item?.currentApprover[0]?.approver_name} - C2
                                        </Tooltip>
                                      </td>
                                      {item?.delete_indicator !== "X" ?
                                        <td>Pending</td>
                                        :
                                        <td>Deleted</td>
                                      }
                                    </>
                                    : item?.currentApprover[0]?.status === "A" ?
                                      <>
                                        <td>
                                          <Tooltip title={item?.currentApprover[0]?.approver_name} position="bottom">
                                            {item?.currentApprover[0]?.approver_name} - C2
                                          </Tooltip>
                                        </td>
                                        {item?.delete_indicator !== "X" ?
                                          <td>Approved</td>
                                          :
                                          <td>Deleted</td>
                                        }
                                      </>
                                      : item?.currentApprover[0]?.status === "D" ?
                                        <>
                                          <td>C1</td>
                                          {item?.delete_indicator !== "X" ?
                                            <td>Rejected (By {item?.currentApprover[0]?.approver_name} - C2)</td>
                                            :
                                            <td>Deleted</td>
                                          }
                                        </>
                                        :
                                        <td></td>
                                  }
                                  {item?.currentApprover[0]?.sap_status === true ?
                                    <td>Synced</td>
                                    : item?.currentApprover[0]?.sap_status === false ?
                                      <td>Pending to Sync</td>
                                      :
                                      <td></td>
                                  }
                                </>
                                : item?.currentApprover[0]?.approver_type === "Approver 3" ?
                                  <>
                                    {item?.currentApprover[0]?.status === "P" ?
                                      <>
                                        <td>
                                          <Tooltip title={item?.currentApprover[0]?.approver_name} position="bottom">
                                            {item?.currentApprover[0]?.approver_name} - C3
                                          </Tooltip>
                                        </td>
                                        {item?.delete_indicator !== "X" ?
                                          <td>Pending</td>
                                          :
                                          <td>Deleted</td>
                                        }
                                      </>
                                      : item?.currentApprover[0]?.status === "A" ?
                                        <>
                                          <td>
                                            <Tooltip title={item?.currentApprover[0]?.approver_name} position="bottom">
                                              {item?.currentApprover[0]?.approver_name} - C3
                                            </Tooltip>
                                          </td>
                                          {item?.delete_indicator !== "X" ?
                                            <td>Approved</td>
                                            :
                                            <td>Deleted</td>
                                          }
                                        </>
                                        : item?.currentApprover[0]?.status === "D" ?
                                          <>
                                            <td>C1</td>
                                            {item?.delete_indicator !== "X" ?
                                              <td>Rejected (By {item?.currentApprover[0]?.approver_name} - C3)</td>
                                              :
                                              <td>Deleted</td>
                                            }
                                          </>
                                          :
                                          <td></td>
                                    }
                                    {item?.currentApprover[0]?.sap_status === true ?
                                      <td>Synced</td>
                                      : item?.currentApprover[0]?.sap_status === false ?
                                        <td>Pending to Sync</td>
                                        :
                                        <td></td>
                                    }
                                  </>
                                  :
                                  <>
                                    {item?.currentApprover[0]?.status === "P" ?
                                      <>
                                        <td>
                                          <Tooltip title={item?.currentApprover[0]?.approver_name} position="bottom">
                                            {item?.currentApprover[0]?.approver_name} - C1
                                          </Tooltip>
                                        </td>
                                        {item?.delete_indicator !== "X" ?
                                          <td>Pending</td>
                                          :
                                          <td>Deleted</td>
                                        }
                                      </>
                                      : item?.currentApprover[0]?.status === "A" ?
                                        <>
                                          <td>
                                            <Tooltip title={item?.currentApprover[0]?.approver_name} position="bottom">
                                              {item?.currentApprover[0]?.approver_name} - C1
                                            </Tooltip>
                                          </td>
                                          {item?.delete_indicator !== "X" ?
                                            <td>Approved</td>
                                            :
                                            <td>Deleted</td>
                                          }
                                        </>
                                        : item?.currentApprover[0]?.status === "D" ?
                                          <>
                                            <td>{item?.currentApprover[0]?.approver_name} - C1</td>
                                            {item?.delete_indicator !== "X" ?
                                              <td>Rejected</td>
                                              :
                                              <td>Deleted</td>
                                            }
                                          </>
                                          :
                                          <td></td>
                                    }
                                    {item?.currentApprover[0]?.sap_status === true ?
                                      <td>Synced</td>
                                      : item?.currentApprover[0]?.sap_status === false ?
                                        <td>Pending to Sync</td>
                                        :
                                        <td></td>
                                    }
                                  </>
                              :
                              <>
                                <td></td>
                                {item?.delete_indicator !== "X" ?
                                  <td></td>
                                  :
                                  <td>Deleted</td>
                                }
                                <td></td>
                              </>
                            }
                            <td>
                              {item?.remarks?.length > 25 ? (
                                <Tooltip title={item?.remarks} position="bottom">
                                  {item?.remarks?.substr(0, 25)}...
                                </Tooltip>
                              ) : (
                                item?.remarks
                              )}
                            </td>
                            {/* <td>{item?.remarks}</td> */}
                            {/* {prSource !== "E" && (
                            <td>
                            {item?.currentApprover?.length > 0 && item?.currentApprover[0]?.approver_type === "Approver 1" && item?.currentApprover[0]?.status === "P"  ? (
                            <button onClick={()=>changestatus("A", item.id)} class="btn btn-primary-inner bpi-main">
                              <i class="far fa-check-square"></i> Approve
                            </button>
                            )
                            :
                            (
                            ""
                            )
                          }
                            </td>
                            )
                          } */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div class="px-4 pb-2">
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <h6 class="f-14 mt-2"></h6>
                      </div>
                      <div class="col-md-6 text-sm-right">
                        {values[0]?.pr_type && (location?.state?.isDraft === true || location?.state?.createFlag === true) && (
                          <button
                            class="btn btnblanktd text-primary"
                            onClick={handleAddForm}
                          >
                            <i class="far fa-plus-square mr-2"></i> Add Item More
                          </button>
                        )}
                      </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                      {console.log("location?.state?.isDraft", location?.state?.isDraft)}
                      {editMode === false && (location?.state?.viewPRFlag !== true || viewPrFlag) ?
                        values.map((item, i) => (
                          i == values.length - 1 && (
                            <CreatePrForm
                              accountAssignmentValues={accountAssignmentValues}
                              formik={formik}
                              createFlag={createFlag}
                              formIndex={i}
                              SUB_ITEM_INITIAL={SUB_ITEM_INITIAL}
                              key={`add-pr-form-${i + 1 * values.length}-${values[i].pr_type
                                }`}
                              prTypeOptions={prTypeOptions}
                              subitemflag={subItemFlag}
                              subItemIndex={subItemIndex}
                              handleSubItemCloseModal={handleSubItemCloseModal}
                              createSubItemFlag={createSubItemFlag}
                              price={price}
                              setPrice={setPrice}
                              editMode={editMode}
                              sapPrNo={sapPrNo}
                              itemNO={itemNO}
                            />
                          )
                        )) : editMode === true && (location?.state?.viewPRFlag !== true || viewPrFlag) ?
                          values.map((item, i) => (
                            i == editIndex && (
                              <CreatePrForm
                                accountAssignmentValues={accountAssignmentValues}
                                formik={formik}
                                createFlag={createFlag}
                                subItemIndex={subItemIndex}
                                subitemflag={subItemFlag}
                                formIndex={i}
                                SUB_ITEM_INITIAL={SUB_ITEM_INITIAL}
                                key={`add-pr-form-${i + 1 * values.length}-${values[i].pr_type
                                  }`}
                                prTypeOptions={prTypeOptions}
                                handleSubItemCloseModal={handleSubItemCloseModal}
                                price={price}
                                rejectFlag={rejectFlag}
                                setPrice={setPrice}
                                editMode={editMode}
                                editIndex={editIndex}
                                sapPrNo={sapPrNo}
                                itemNO={itemNO}
                              />
                            )
                          )) :
                          <>
                            <div className="row">
                              {location?.state?.isDraft === true && (
                                <div className="col-md-6">
                                  <div class="form-group innergroup position-relative modaldefaultclose ">
                                    <label>
                                      Attachment <span class="text-danger"></span>
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control bg-white"
                                      disabled
                                      placeholder="(Image, PDF Format)"
                                    />
                                    <div class="upload-btn-wrapper up-loposition">
                                      <button class="uploadBtn">Browse</button>
                                      <input
                                        type="file"
                                        title=""
                                        name="attachment_path"
                                        accept="image/png, image/jpeg"
                                        multiple
                                        onChange={(e) => {
                                          onProfileChange(e);
                                          // setAttachment(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <span>
                                      (Allowed only pdf,png,jpeg,jpg with size: upto 4MB)
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div className="col-md-6 mt-3">
                                <p>
                                  Selected files&nbsp;
                                  <span>
                                    {image?.length > 0 && image !== null && image !== undefined && image !== []
                                      ?
                                      <>
                                        {image?.map((url, index) => {
                                          return (
                                            <>
                                              <a href={url} target="_blank">
                                                {url
                                                  .split("/")
                                                  .pop()
                                                  .includes(".pdf") ? (
                                                  <i class="fas fa-file-pdf fa-2x" style={{ color: "red", marginLeft: "40px" }}></i>
                                                ) : url
                                                  .split("/")
                                                  .pop()
                                                  .includes(".JPG") || url
                                                    .split("/")
                                                    .pop()
                                                    .includes(".jpg") || (url
                                                      .split("/")?.length > 0 && url
                                                        .split("/")[0]
                                                        .includes("blob")) ? (
                                                  <img src={url} style={{ width: "50px", height: "40px", marginLeft: "40px" }} />
                                                ) : ""
                                                }
                                              </a>
                                              {location?.state?.isDraft === true && (
                                                <span
                                                  class="btn btn-blanktd text-danger ml-2 f-12"
                                                  onClick={() => handleRemoveImage(index)}
                                                >
                                                  <i class="far fa-trash-alt text-danger"></i>
                                                </span>
                                              )}
                                            </>
                                          )
                                        })}
                                      </>
                                      : ""}
                                  </span>
                                </p>
                              </div>
                            </div>

                            {/* {location?.state?.isDraft === true && ( */}
                            <div class="col-md-6">
                              <div class="form-group innergroup">
                                <label>
                                  PR Header Text<span class="text-danger"></span>
                                </label>
                                <textarea
                                  name="header_text"
                                  disabled={location?.state?.isDraft !== true}
                                  class="form-control"
                                  placeholder="Enter PR Header Text"
                                  value={prHeaderText}
                                  onChange={(e) => {
                                    setPrHeaderText(e.target.value)
                                    setErrorForHeader(false)
                                  }
                                  }
                                ></textarea>
                                <div className="small text-danger">
                                  {errorForHeader &&
                                    "* Kindly Enter PR Header Text."}
                                </div>
                              </div>
                            </div>
                            {/* )} */}
                          </>
                      }
                      <center>
                        {(location?.state?.isDraft === true || location?.state?.createFlag === true ||  Boolean(createFlagByQry)) && (
                          <>
                            {values.length > 0 && values[0].pr_type && (
                              <button
                                style={{ marginRight: "4%" }}
                                type="submit"
                                class="btn btn-primary-inner bpi-main"
                                onClick={() => setDraftFlag(true)}
                              >
                                Save as Draft
                              </button>
                            )}
                            {location?.state?.createFlag !== true && viewPrFlag !== true && (
                              <button
                                type="submit"
                                class="btn btn-primary-inner bpi-main"
                              >
                                Submit
                              </button>
                            )}
                          </>
                        )}
                        {rejectFlag && (
                          <button
                            type="button"
                            class="btn btn-primary-inner bpi-main"
                            onClick={reSubmitPR}
                          >
                            Re-Submit
                          </button>
                        )}
                        {/* <div class="col-md-12 pb-3"> */}
                        <button
                          type="button"
                          class="btn btn-outline-secondary ml-2"
                          onClick={() => {
                            window.history.go(-1);
                            return false;
                          }}
                        >
                          <i class="fas fa-arrow-circle-left"></i> Back
                        </button>
                        {/* </div> */}
                      </center>
                    </form>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePR;
