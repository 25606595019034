import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getRequests = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/material_creation?${queryParm}`);
};
export const getRequestsExport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/material_creation/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
}
export const changestatus = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/material_creation/action`,data);
};
export const changebulkmatstatus = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/material_creation/action_bulk_material`,data);
};

export const getUserData = async (bussiness_unit_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get-user-details?bussiness_unit_id=${bussiness_unit_id}`
  );
};

export const materialApprovals = async (data) => {
  return axios.put(
    `${AUTH_BASE_URL}/material_creation/material_approvals`,
    data
  );
};

export const getmaterialApprovals = async () => {
  return axios.get(`${AUTH_BASE_URL}/material_creation/material_approvals`);
};

export const get_HSN = async () => {
  return axios.get(`${AUTH_BASE_URL}/material_creation/hsn/list`);
};

export const pullMatCodeFromSap = async (values) => {
  return axios.post(`${AUTH_BASE_URL}/material_creation/sync_material`, values);
};