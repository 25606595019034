import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { CustomBreadcrumb } from "./custom-breadcrump";
import * as MediclaimService from "../../service/medicalService";
import AddMediclamPremiumTopupModal from "./modals/add-mediclaim-premium-topup";
import { OverlayTrigger, Dropdown } from "react-bootstrap";
import { Tooltip } from "react-tippy";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import Select from "react-select";
import { getAllOrganisation } from "../../service/organisation";
import ReactPaginate from "react-paginate";

const CRUMB_ITEMS = [
  { to: "/home", title: "Home" },
  { to: "/", title: "Me@PI" },
  { active: true, title: "Medical Premuim Top up" },
];

const TOPUP_TYPE = [
  {
    label: "Single",
    value: "Single",
  },
  {
    label: "Double",
    value: "Double",
  },
];

const MediclaimPreiumTopUp = () => {
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [filter, setFilter] = useState({ sort_by: "id" });
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [orgList, setOrgList] = useState([]);
  const [filterValue, setFilterValue] = useState({
    topUpType: "",
    organisation: "",
    user_status: "",
    fin_year: ""
  });
  const [topUp, setTopUp] = useState("");
  const [filterOrg, setFilterOrg] = useState("");

  const [addEditModal, setAddEditModal] = useState(false);
  const dispatch = useDispatch();
  const [editData, setEditData] = useState(null);

  const handleSearch = (e) => {
    getData(e.target.value.toLowerCase(), filter, 1, pageSizeNo, "id", true);
    setSearchStr(e.target.value);
  };

  const handlePageClick = (action) => {
    const activePage = action.selected + 1;
    setCurrentPage(activePage);
    getData(searchStr, filter, activePage, pageSizeNo, "id", true);
  };

  const handleKey = (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate,
    organisation,
    topUpType
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      filter !== "" &&
      filter !== undefined &&
      filter !== null &&
      filter !== {}
    ) {
      queryParm = queryParm;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      organisation !== "" &&
      organisation !== undefined &&
      organisation !== null
    ) {
      queryParm = queryParm + "&org=" + organisation;
    }
    if (topUpType !== "" && topUpType !== undefined && topUpType !== null) {
      queryParm = queryParm + "&topUpType=" + topUpType;
    }
    if (filterValue?.user_status !== "" && filterValue?.user_status !== undefined && filterValue?.user_status !== null) {
      queryParm = queryParm + "&user_status=" + filterValue?.user_status;
    }
    if (filterValue?.fin_year !== "" && filterValue?.fin_year !== undefined && filterValue?.fin_year !== null) {
      queryParm = queryParm + "&fin_year=" + filterValue?.fin_year;
    }
    queryParm = queryParm + '&from_admin=' + true;
    return queryParm;
  };

  const exportData = (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate,
    organisation,
    topUpType
  ) => {
    dispatch(setDisplayLoader("Display"));
    MediclaimService.exportMediclaimPremiumTopup(
      handleKey(search, filter, page_no, page_size, sort_by, paginate, organisation, topUpType)
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Mediclaim Premium Topup.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const getData = async (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate,
    organisation,
    topUptype
  ) => {
    await MediclaimService.getMediclaimPremiumsTopUp(
      handleKey(
        search,
        filter,
        page_no,
        page_size,
        sort_by,
        paginate,
        organisation,
        topUptype
      )
    )
      .then((response) => {
        if (response.data.code === 200) {
          setList(response?.data?.dataList?.result);
          setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshData = () => {
    getData(
      searchStr,
      filter,
      currentPage,
      pageSizeNo,
      filter.sort_by,
      true,
      filterValue?.organisation,
      filterValue?.topUpType
    );
  };

  const handleModal = () => {
    setAddEditModal((prev) => !prev)
    setEditData(null)
  }

  useEffect(() => {
    getData(
      searchStr,
      filter,
      currentPage,
      pageSizeNo,
      filter.sort_by,
      true,
      filterValue?.organisation,
      filterValue?.topUpType
    );
    getOrgList();
  }, [pageSizeNo]);

  const getOrgList = () => {
    getAllOrganisation(false).then((response) => {
      if (response?.status === 200) {
        setOrgList(
          response?.data?.dataList?.result?.map((each) => {
            return {
              label: `${each?.name} (${each?.code})`,
              value: each?.id,
            };
          })
        );
      }
    });
  };

  const clearFilter = () => {
    setFilterValue((prev) => ({
      ...prev,
      organisation: "",
      topUpType: "",
    }));
    setTopUp("");
    setFilterOrg("");
    filterclose();
    getData(
      searchStr,
      filter,
      currentPage,
      pageSizeNo,
      filter.sort_by,
      true,
      "",
      ""
    );
  };

  const mainDataFilter = () => {
    getData(
      searchStr,
      filter,
      currentPage,
      pageSizeNo,
      filter.sort_by,
      true,
      filterValue?.organisation,
      filterValue?.topUpType
    );
    filterclose();
  };

  const editForm = (data) => {
    setAddEditModal(true);
    setEditData(data)
  }


  return (
    <>
      {/* Modals */}
      <AddMediclamPremiumTopupModal
        isOpen={addEditModal}
        edit={editData}
        handleModalClose={handleModal}
        refreshData={refreshData}
      />
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Mediclaim Premium Topup</h4>
                  <CustomBreadcrumb items={CRUMB_ITEMS} />
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on <br />
                            <strong>
                              Sum assured, <br />
                              Premium, Status
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search on Sum Assured, Status..."
                        onChange={(e) => handleSearch(e)}
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              exportData(
                                searchStr,
                                filter,
                                currentPage,
                                pageSizeNo,
                                filter.sort_by,
                                true,
                                filterValue?.organisation,
                                filterValue?.topUpType
                              )
                            }
                          >
                            <i class="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button class="btn btn-primary" onClick={handleModal}>
                      Add More
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Type</th>
                      <th>Sum Insured</th>
                      <th>Lower Age Limit</th>
                      <th>Upper Age Limit</th>
                      <th>Organisation Name</th>
                      <th>Premium</th>
                      <th>Updated At</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item?.top_up_type}</td>
                          <td>{item?.sum_insured}</td>
                          <td>{item?.lower_age_limit}</td>
                          <td>{item?.upper_age_limit}</td>
                          <td>
                            {item?.organisation_name === null
                              ? ""
                              : `${item?.organisation_name} (${item?.organisation_code})`}
                          </td>
                          <td>{item?.premium}</td>
                          <td>
                            {item?.updated_at &&
                              moment
                                .utc(item?.updated_at)
                                .format("Do MMM YYYY, h:mm:ss A")}
                          </td>
                          {item?.is_active == true ? (
                            <td>
                              <span class="badge bad-status badge-success">
                                Active
                              </span>
                            </td>
                          ) : (
                            <td>
                              <span class="badge bad-status badge-danger">
                                InActive
                              </span>
                            </td>
                          )}
                          <td>
                            <button
                              class="btn btn-blanktd text-primary ml-2"
                              onClick={() => {
                                editForm(item)
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div className="col-sm-5">
                  <div className="sortinglist">
                    Show
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Select Organisation</label>
            <Select
              options={orgList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  organisation: e?.value,
                }));
                setFilterOrg(e);
              }}
              value={filterOrg}
            />
          </div>
          <div class="form-group innergroup">
            <label>Select Top-Up Type</label>
            <Select
              options={TOPUP_TYPE}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  topUpType: e?.value,
                }));
                setTopUp(e);
              }}
              value={topUp}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Status <span class="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect"
              name="status"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  user_status: e?.target?.value
                }));
              }}
              value={filterValue.user_status}
            >
              <option value="" label="Select" disabled />
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Year</label>
            <input
              type="text"
              class="form-control"
              name="year"
              onChange={(e) =>
                setFilterValue((prev) => ({
                  ...prev,
                  fin_year: e.target.value,
                }))
              }
              value={filterValue?.fin_year}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MediclaimPreiumTopUp;
