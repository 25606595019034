import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModularTable from "../../../../elements/ModularTable";
import { getCurrentFy } from "../../../../service/AgriPortalService/CropScenarioService";
import {
  getDetailedSalesPlan,
  getDetailedSalesPlanDropdown,
  getDetailedSalesPlanApprovalLogs,
  getLastDetailedSalesPlan,
  getLastDetailedSalesPlanByCrop,
  postLastDetailedSalesPlan,
  putLastDetailedSalesPlan,
  postDetailedSalesPlanAction,
} from "../../../../service/AgriPortalService/DetailedSalesPlanService";
import {
  getLastMarketSize,
  getMarketSizeApprovalLogs,
  postMarketSize,
  putMarketSize,
} from "../../../../service/AgriPortalService/MarketSizeService";
import {
  getLastSalesPlan,
  getSalesPlanApprovalLogs,
  postSalesPlan,
  putSalesPlan,
} from "../../../../service/AgriPortalService/SalesPlanService";
import styles from "./Table.module.css";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch,useSelector } from "react-redux";
import { setDisplayLoader ,selectUserData} from "../../../redux/piDataStore";

const Root = (props) => {
  const userData = useSelector(selectUserData);
  //HOOKS
  const [rawData, setRawData] = useState({});
  const [existingData, setExistingData] = useState([]);
  const [tableData, setTableData] = useState({});
  const [tableHeadData, setTableHeadData] = useState([]);

  const [updatedTableData, setUpdatedTableData] = useState({});

  const [tableData2, setTableData2] = useState({});
  const [tableHeadData2, setTableHeadData2] = useState([]);

  const [updatedTableData2, setUpdatedTableData2] = useState({});

  const [tableData3, setTableData3] = useState({});
  const [tableHeadData3, setTableHeadData3] = useState([]);

  const [updatedTableData3, setUpdatedTableData3] = useState({});

  const [mode, setMode] = useState();

  const [freezeCTA, setFreezeCTA] = useState(false);

  const [approvalList, setApprovalList] = useState([]);

  const [productDropdown, setProductDropdown] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});

  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [locationData, setLocationData] = useState({});
  const [cfy, setCfy] = useState("");
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (!mode) return;
    if (mode == "edit" || mode == "view" || mode == "approve") {
      getLastDetailedSalesPlan(`?detailed_sale_plan_id=${pageId}`)
        .then((res) => {
          console.log("RESPONSE=>", res.data.dataList.product_data);
          setExistingData(res.data.dataList.product_data);
        })
        .catch((err) => { });
    }
  }, [mode]);

  useEffect(() => {
    invokeLoader();
  }, [loader]);

  useEffect(() => {
    setLoader(true);
  }, []);

  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    } else {
      getDetailedSalesPlan()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [mode]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("mode") != "create") setCfy(params.get("fy"));
    else
      getCurrentFy()
        .then((res) => setCfy(res.data.dataList.fy))
        .catch((err) => console.log(err));
    getDetailedSalesPlanDropdown(params.get("id") ? params.get("id") : 0)
      .then((res) => {
        if (!res.data.dataList || res.data.dataList.length == 0) return;
        setProductDropdown(res.data.dataList);
        setSelectedProduct(res.data.dataList[0]);
      })
      .catch((err) => {
        if (err.response.data.code == 409) {
          toast.error(
            err?.response?.data?.detail
          );
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        }
      });
  }, [mode, pageId]);

  useEffect(() => {
    if (!selectedProduct) return;

    loadAllData()
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }, [selectedProduct]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!mode) return;
    let query = "/myplan/detailedsalesplan";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/myplan/detailedsalesplan");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  const onTable2DataChange = (data) => {
    if (!data) return;
    const totals = [{ value: "Planning Year Total Qty" }];

    for (let i = 0; i < data?.length; i++) {
      let t = 0;
      for (let j = 2; j < 14; j++) {
        if (!parseInt(data[i][j].value[0])) t += 0;
        else t += parseInt(data[i][j].value[0]);
      }
      data[i][1].value[0] = t;
    }

    for (let i = 1; i < data[0]?.length; i++) {
      let total = 0;
      for (let j = 0; j < data.length - 1; j++) {
        if (!parseInt(data[j][i].value[0])) total += 0;
        else total += parseInt(data[j][i].value[0]);
      }
      totals.push({ value: [total] });
    }

    data[data.length - 1] = totals;
    setUpdatedTableData2((state) => {
      const newState = { ...state };
      newState[selectedProduct?.sale_product_id] = data;
      return newState;
    });
  };
  const onTable3DataChange = (data) => {
    if (!data) return;
    setUpdatedTableData3((state) => {
      const newState = { ...state };
      console.log("UPDATED", data);
      let total = 0;
      for (let i = 0; i < data?.length - 1; i++) {
        if (!parseInt(data[i][3].value[0])) total += 0;
        else total += parseInt(data[i][3].value[0]);
      }
      if (data) {
        data[data?.length - 1][3].value[0] = total;
      }
      newState[selectedProduct?.sale_product_id] = data;
      return newState;
    });
  };

  const onTableDataChange = (data) => {
    const totals = [{ value: "Total" }, { value: "-" }, { value: "-" }];

    for (let i = 3; i < data[0]?.length; i++) {
      let total = 0;
      for (let j = 0; j < data.length - 1; j++) {
        total += parseInt(data[j][i].value[0]);
      }
      totals.push({ value: [total] });
    }

    data[data.length - 1] = totals;

    setUpdatedTableData(data);
  };

  const convertCategoryData = (data, data2) => {
    console.log(data);
    let existing = Object.keys(data);
    let temp = existingData.filter(
      (el) => !existing.includes(String(el.sale_product_id))
    );
    // temp.map((element) => {
    //     let index = productDropdown.findIndex(ele => ele.sale_product_id == element.sale_product_id)
    //     data[element.sale_product_id] = tableData2[index]
    //     data2[element.sale_product_id] = tableData3[index]
    // })
    let ans = [];
    console.log("CHECK", temp);
    if (!data) return;

    ans = Object.keys(data)?.map((key) => {
      if (key != "undefined") {
        let innerObj = {
          id: 0,
          sale_product_id: key,
          packs_data: [],
          customers_data: [],
        };

        let packs_data = [];
        data[key]?.forEach((row) => {
          if (row[0].value != "Planning Year Total Qty") {
            for (let i = 2; i < 14; i++) {
              if (row[0]?.meta?.raw?.pack_id) {
                packs_data.push({
                  id: 0,
                  pack_id: row[0]?.meta?.raw?.pack_id,
                  month_id: i - 1,
                  value: row[i].value[0] ? row[i].value[0] : 0,
                });
              }
            }
          }
        });

        innerObj.packs_data = packs_data;

        let customers_data = [];
        console.log(data2[key], key, "cases");
        data2[key]?.forEach((row) => {
          if (row[0].value != "Total") {
            customers_data.push({
              id: 0,
              customer_id: row[0]?.meta?.raw?.customer_id,
              value: row[3]?.value[0] ? row[3]?.value[0] : 0,
            });
          }
        });

        innerObj.customers_data = customers_data;

        return innerObj;
      }
    });
    temp.map((ele) => {
      let innerObj = {
        id: 0,
        sale_product_id: ele.sale_product_id,
        packs_data: [],
        customers_data: [],
      };
      innerObj.sale_product_id = ele.sale_product_id;
      ele.packs_data.map((packs) => {
        packs.month_data.map((month) => {
          innerObj.packs_data.push({
            id: 0,
            pack_id: packs.pack_id,
            month_id: month.month_id,
            value: month.value,
          });
        });
      });
      ele.customers_data.map((customer) => {
        innerObj.customers_data.push({
          id: 0,
          customer_id: customer.customer_id,
          value: customer.value,
        });
      });
      console.log("innerObj", ans, ele);
      ans.push(innerObj);
    });
    const DataId = [];
    for (let i = 0; i < ans.length; i++) {
      let check = false;
      for (let j = 0; j < ans[i].customers_data.length; j++) {
        if (parseInt(ans[i].customers_data[j].value) > 0) {
          DataId.push(ans[i].sale_product_id);
          check = true;
          break;
        }
      }
      if (check) continue;
      for (let j = 0; j < ans[i].packs_data.length; j++) {
        if (parseInt(ans[i].packs_data[j].value) > 0) {
          DataId.push(ans[i].sale_product_id);
          check = true;
          break;
        }
      }
      if (check) continue;
    }
    ans = ans.filter((el) => DataId.includes(el.sale_product_id));
    console.log("TABLEDATA+>", ans);
    return ans.filter((el) => el != undefined);
  };

  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setPageId(params.get("id"));

    let query = "";
    if (params.get("mode") != "create") {
      query = "?detailed_sale_plan_id=" + params.get("id");
    }

    if (params.get("mode") != "create") {
      getDetailedSalesPlanApprovalLogs(query)
        .then((res) => setApprovalList(res.data.dataList))
        .catch((err) => console.log(err));
    }
  }, []);

  const loadAllData = async () => {
    setLoader(true);
    // for (let i = 0; i < productDropdown?.length; i++) {
    await setTableDataForSelectedProduct(selectedProduct?.sale_product_id);
    setLoader(false);
    // }
  };

  const setTableDataForSelectedProduct = async (id) => {
    if (id && !tableData[id]) {
      const params = new URLSearchParams(window.location.search);
      let query =
        "?sale_product_id=" +
        id +
        "&last_year_data=true" +
        "&detailed_sale_plan_id=" +
        (params.get("id") ? params.get("id") : 0);
      let data = await getLastDetailedSalesPlanByCrop(query);

      if (!data || !data?.data?.dataList) {
        return toast.error("Data fetching fail for given product");
      }

      const tdata = data?.data?.dataList?.packs_data?.map((el) => {
        const row = [
          {
            value: [el?.pack_name],
            meta: { raw: el },
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
        ];

        let total = 0;

        for (let i = 0; i < el?.month_data?.length; i++) {
          row[1 + parseInt(el?.month_data[i]?.month_id)].value = [
            el?.month_data[i]?.value,
          ];
          total += el?.month_data[i]?.value;
        }
        row[1].value = [total];

        return row;
      });

      let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < tdata?.length; i++) {
        for (let j = 2; j < tdata[i]?.length; j++) {
          arr[j - 2] += tdata[i][j]?.value[0];
        }
      }

      let total = 0;

      for (let i = 0; i < 12; i++) total += arr[i];

      let row = [
        {
          value: ["Previous Year Total Qty"],
          meta: {},
        },
        {
          value: [total],
          meta: {},
        },
      ];

      for (let i = 0; i < 12; i++) {
        row.push({
          value: [arr[i]],
          meta: {},
        });
      }

      tdata.push(row);

      // tableData[id]=tdata;
      setTableData((state) => {
        const newState = { ...state };
        newState[id] = tdata;
        return newState;
      });
    }

    if (!tableData2[id]) {
      const params = new URLSearchParams(window.location.search);
      let query =
        "?sale_product_id=" +
        id +
        "&last_year_data=false" +
        "&detailed_sale_plan_id=" +
        (params.get("id") ? params.get("id") : 0);
      let data = await getLastDetailedSalesPlanByCrop(query);

      if (!data || !data?.data?.dataList) {
        return toast.error("Data fetching fail for given product");
      }

      const tdata = data?.data?.dataList?.packs_data?.map((el) => {
        const row = [
          {
            value: [el?.pack_name],
            meta: { raw: el },
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
          {
            value: [0],
            meta: {},
          },
        ];

        let total = 0;

        for (let i = 0; i < el?.month_data?.length; i++) {
          row[1 + parseInt(el?.month_data[i]?.month_id)].value = [
            el?.month_data[i]?.value,
          ];
          total += el?.month_data[i]?.value;
        }
        row[1].value = [total];

        return row;
      });

      let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < tdata?.length; i++) {
        for (let j = 2; j < tdata[i]?.length; j++) {
          arr[j - 2] += tdata[i][j]?.value[0];
        }
      }

      let total = 0;

      for (let i = 0; i < 12; i++) total += arr[i];

      let row = [
        {
          value: ["Previous Year Total Qty"],
          meta: {},
        },
        {
          value: [total],
          meta: {},
        },
      ];

      for (let i = 0; i < 12; i++) {
        row.push({
          value: [arr[i]],
          meta: {},
        });
      }

      tdata.push(row);

      // tableData[id]=tdata;
      setTableData2((state) => {
        const newState = { ...state };
        newState[id] = tdata;
        return newState;
      });
    }

    if (!tableData3[id]) {
      const params = new URLSearchParams(window.location.search);

      let lastyear =
        params.get("fy")?.split("-")[0].slice(0, 3) +
        (parseInt(params.get("fy")?.split("-")[0][3]) - 1) +
        "-" +
        params.get("fy")?.split("-")[0];
      console.log("lastyear", lastyear);
      let query =
        "?sale_product_id=" +
        id +
        "&last_year_data=false&cfy=" +
        params.get("fy") +
        "&detailed_sale_plan_id=" +
        (params.get("id") ? params.get("id") : 0);
      let query2 =
        "?sale_product_id=" +
        id +
        "&last_year_data=false&cfy=" +
        lastyear +
        "&detailed_sale_plan_id=" +
        (params.get("id") ? params.get("id") : 0);
      let data = await getLastDetailedSalesPlanByCrop(query);
      let data2 = await getLastDetailedSalesPlanByCrop(query2);

      if (!data || !data?.data?.dataList) {
        return toast.error("Data fetching fail for given product");
      }

      if (!data2 || !data2?.data?.dataList) {
        return toast.error("Data fetching fail for given product");
      }

      let tdata = data?.data?.dataList?.customers_data?.map((el, idx) => {
        let row = [
          {
            value: [`${el?.customer_name}`],
            meta: { raw: el },
          },
          {
            value: [`${el?.customer_code}`],
            meta: { raw: el },
          },
          {
            value: [el?.city_name],
            meta: {},
          },
          {
            value: [el?.value],
            meta: {},
          },
          {
            value: [el?.value],
            meta: {},
          },
        ];

        return row;
      });

      tdata.push([
        {
          value: [`Total`],
          meta: { raw: "total" },
        },
        {
          value: [`-`],
          meta: {},
        },
        {
          value: [`-`],
          meta: {},
        },
        // {
        //   value: [`-`],
        //   meta: {},
        // },
        {
          value: [0],
          meta: {},
        },
      ]);

      console.log({ tdata });

      setTableData3((state) => {
        const newState = { ...state };
        newState[id] = tdata;
        return newState;
      });
    }
  };

  useEffect(() => {
    if (!rawData) return;

    // setTableDataForSelectedProduct().then(res=>console.log(res)).catch(err=>console.log(err));
  }, [selectedProduct]);

  //Set table data after past data is fetched
  useEffect(() => {
    if (!rawData.sale_plan_data) return;

    setTableHeadData((state) => {
      if (!rawData) return [];

      let headData;

      if (mode != "view")
        headData = [
          {
            name: "Category",
            type: "T",
            isEditable: false,
          },
          {
            name: "Product Group",
            type: "T",
            isEditable: false,
          },
          {
            name: "Crop Wise Plan " + rawData?.sale_plan_data[0]?.fy,
            type: "N",
            isEditable: false,
          },
          {
            name: "Molecule Market Size",
            type: "N",
            isEditable: true,
          },
          {
            name: "Projection " + rawData?.sale_plan_data[0]?.fy,
            type: "N",
            isEditable: true,
          },
          {
            name: "Projection " + rawData?.sale_plan_data[0]?.fy_p_1,
            type: "N",
            isEditable: true,
          },
          {
            name: "Projection " + rawData?.sale_plan_data[0]?.fy_p_2,
            type: "N",
            isEditable: true,
          },
        ];

      if (mode == "view")
        headData = [
          {
            name: "Category",
            type: "T",
            isEditable: false,
          },
          {
            name: "Product Group",
            type: "T",
            isEditable: false,
          },
          {
            name: "Crop Wise Plan " + rawData?.sale_plan_data[0]?.fy,
            type: "N",
            isEditable: false,
          },
          {
            name: "Molecule Market Size",
            type: "N",
            isEditable: false,
          },
          {
            name: "Projection " + rawData?.sale_plan_data[0]?.fy,
            type: "N",
            isEditable: false,
          },
          {
            name: "Projection " + rawData?.sale_plan_data[0]?.fy_p_1,
            type: "N",
            isEditable: false,
          },
          {
            name: "Projection " + rawData?.sale_plan_data[0]?.fy_p_2,
            type: "N",
            isEditable: false,
          },
        ];

      return headData;
    });

    setTableData((state) => {
      const newTableData = [];

      let val1 = 0,
        val2 = 0,
        val3 = 0,
        val4 = 0,
        val5 = 0;
      rawData?.sale_plan_data?.forEach((el, idx) => {
        let rowData;

        val1 += parseInt(el.fy_projection);
        val2 += parseInt(el.fy_p_1_projection);
        val3 += parseInt(el.fy_p_2_projection);
        // val4+=parseInt(el.fy_p_1_value);
        // val5+=parseInt(el.fy_p_1_value);

        let category = "";

        if (el.pi_product_category == "M") category = "Marketing";
        else if (el.pi_product_category == "S") category = "Star";
        else if (el.pi_product_category == "T") category = "Trading";
        else if (el.pi_product_category == "N") category = "New";
        else if (el.pi_product_category == "G") category = "Generic";
        else if (el.pi_product_category == "F") category = "Future";
        else if (el.pi_product_category == "B") category = "BIOLOGICALS";

        rowData = [
          {
            value: [category],
            meta: {
              id: "",
            },
          },
          {
            value: [el.pi_product_name],
            meta: {
              id: el.pi_product_id,
            },
          },
          {
            value: [el.crop_wise_plan],
            meta: {},
          },
          {
            value: [el.mol_market_size],
            meta: {},
          },
          {
            value: [el.fy_projection],
            meta: {},
          },
          {
            value: [el.fy_p_1_projection],
            meta: {},
          },
          {
            value: [el.fy_p_2_projection],
            meta: {},
          },
        ];

        newTableData.push(rowData);
      });

      newTableData.push([
        {
          value: ["Total"],
          meta: {
            id: "",
          },
        },
        {
          value: ["-"],
          meta: {
            id: "",
          },
        },
        {
          value: ["-"],
          meta: {},
        },
        {
          value: ["-"],
          meta: {},
        },
        {
          value: [val1],
          meta: {},
        },
        {
          value: [val2],
          meta: {},
        },
        {
          value: [val3],
          meta: {},
        },
      ]);

      return newTableData;
    });
  }, [rawData]);

  function checkRule(body, dropdownData) {
    var totalError = [];
    var customerError = [];
    for (let i = 0; i < dropdownData?.length; i++) {
      const idx = body?.findIndex(
        (row) => row?.sale_product_id == dropdownData[i]?.sale_product_id
      );
      console.log({idx})
      if (idx == -1 && dropdownData[i]?.product_qty == 0) continue;

      let totals = 0;
      body[idx]?.packs_data?.forEach((el) => {
        if (typeof el?.value == "string") totals += parseInt(el?.value);
        else totals += el?.value;
      });

      let customerTotals = 0;
      body[idx]?.customers_data?.forEach((el) => {
        if (typeof el?.value == "string") customerTotals += parseInt(el?.value);
        else customerTotals += el?.value;
      });
      if (totals != dropdownData[i]?.product_qty) {
        totalError.push(dropdownData[i]?.product_name);
      }
      if (customerTotals != dropdownData[i]?.product_qty) {
        customerError.push(dropdownData[i]?.product_name);
      }
    }
    console.log("ERROR", customerError);
    if (totalError.length == 0 && customerError.length == 0) {
      return { error: false };
    } else {
      return { error: true, data: totalError, customerError: customerError };
    }
  }

  //EVENT HANDLERS
  const onSaveHandler = (event) => {
    setLoader(true);

    if (freezeCTA) return;

    setFreezeCTA(true);

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "D",
    };
    const product_data = convertCategoryData(
      updatedTableData2,
      updatedTableData3
    );

    if (!product_data) return;
    body.product_data = product_data;
    if (mode == "create")
      postLastDetailedSalesPlan(body)
        .then((res) => {
          toast.success("Save Succesfull!");
          setLoader(false);
          setTimeout(() => {
            navigate("/myplan/detailedsalesplan");
          }, 1000);
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Save Failed!");
          console.log(err);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      body.cfy = params.get("fy");
      putLastDetailedSalesPlan(body)
        .then((res) => {
          toast.success("Save Succesfull!");
          setLoader(false);
          setTimeout(() => {
            navigate("/myplan/detailedsalesplan");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save Failed!");
          setLoader(false);
          console.log(err);
        });
    }
  };

  const prepareData = async () => {
    if (freezeCTA) return;

    setFreezeCTA(true);

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "P",
    };

    const product_data = convertCategoryData(
      updatedTableData2,
      updatedTableData3
    );

    if (!product_data) return;
    body.product_data = product_data;
    const check = checkRule(product_data, productDropdown);
    if (check.error) {
      setFreezeCTA(false);
      return errorDialog(check.data.join(", "), check.customerError.join(", "));
    } else {
      confirmDialog("P", body);
    }
  };

  const errorDialog = (data, customer) => {
    let string = "";
    if (data.length > 0)
      string += `Sum of pack quantities is not equal to product quantity for the following product: ${data}`;
    if (data.length > 0 && customer.length > 0) string += ` and `;
    if (customer.length > 0)
      string += `Customer wise segregation are not equal for the following product: ${customer}`;
    confirmAlert({
      title: "Sum of quantity and planning year should be equal:",
      message: string,
      buttons: [
        {
          label: "Okay",
        },
      ],
    });
  };

  const confirmDialog = (type, body) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler(body) : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  const onSubmitHandler = (body) => {
    setLoader(true);

    if (mode == "create")
      postLastDetailedSalesPlan(body)
        .then((res) => {
          toast.success("Submit Succesfull!");
          setLoader(false);
          setTimeout(() => {
            navigate("/myplan/detailedsalesplan");
          }, 1000);
        })
        .catch((err) => {
          if (err?.response?.data?.code === 409) {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error("Submit Failed!");
          }
          setLoader(false);
          console.log(err);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      body.cfy = params.get("fy");
      putLastDetailedSalesPlan(body)
        .then((res) => {
          toast.success("Submit Succesfull!");
          setLoader(false);
          setTimeout(() => {
            navigate("/myplan/detailedsalesplan");
          }, 1000);
        })
        .catch((err) => {
          if (err?.response?.data?.code === 409) {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error("Submit Failed!");
          }
          setLoader(false);
          console.log(err);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/myplan/detailedsalesplan");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      detailed_sale_plan_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postDetailedSalesPlanAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };

  const previousYearColumns = [
    {
      name: "Product Pack",
      type: "T",
      isEditable: false,
    },
    {
      name: "Actual 2018-2019",
      type: "N",
      isEditable: false,
    },
    {
      name: "Apr",
      type: "N",
      isEditable: false,
    },
    {
      name: "May",
      type: "N",
      isEditable: false,
    },
    {
      name: "Jun",
      type: "N",
      isEditable: false,
    },
    {
      name: "Jul",
      type: "N",
      isEditable: false,
    },
    {
      name: "Aug",
      type: "N",
      isEditable: false,
    },
    {
      name: "Sep",
      type: "N",
      isEditable: false,
    },
    {
      name: "Oct",
      type: "N",
      isEditable: false,
    },
    {
      name: "Nov",
      type: "N",
      isEditable: false,
    },
    {
      name: "Dec",
      type: "N",
      isEditable: false,
    },
    {
      name: "Jan",
      type: "N",
      isEditable: false,
    },
    {
      name: "Feb",
      type: "N",
      isEditable: false,
    },
    {
      name: "Mar",
      type: "N",
      isEditable: false,
    },
  ];

  let planningYearColumns;
  if (mode != "view")
    planningYearColumns = [
      {
        name: "Product Pack",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty Planned",
        type: "N",
        isEditable: false,
      },
      {
        name: "Apr",
        type: "N",
        isEditable: true,
      },
      {
        name: "May",
        type: "N",
        isEditable: true,
      },
      {
        name: "Jun",
        type: "N",
        isEditable: true,
      },
      {
        name: "Jul",
        type: "N",
        isEditable: true,
      },
      {
        name: "Aug",
        type: "N",
        isEditable: true,
      },
      {
        name: "Sep",
        type: "N",
        isEditable: true,
      },
      {
        name: "Oct",
        type: "N",
        isEditable: true,
      },
      {
        name: "Nov",
        type: "N",
        isEditable: true,
      },
      {
        name: "Dec",
        type: "N",
        isEditable: true,
      },
      {
        name: "Jan",
        type: "N",
        isEditable: true,
      },
      {
        name: "Feb",
        type: "N",
        isEditable: true,
      },
      {
        name: "Mar",
        type: "N",
        isEditable: true,
      },
    ];
  else
    planningYearColumns = [
      {
        name: "Product Pack",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty Planned",
        type: "N",
        isEditable: false,
      },
      {
        name: "Apr",
        type: "N",
        isEditable: false,
      },
      {
        name: "May",
        type: "N",
        isEditable: false,
      },
      {
        name: "Jun",
        type: "N",
        isEditable: false,
      },
      {
        name: "Jul",
        type: "N",
        isEditable: false,
      },
      {
        name: "Aug",
        type: "N",
        isEditable: false,
      },
      {
        name: "Sep",
        type: "N",
        isEditable: false,
      },
      {
        name: "Oct",
        type: "N",
        isEditable: false,
      },
      {
        name: "Nov",
        type: "N",
        isEditable: false,
      },
      {
        name: "Dec",
        type: "N",
        isEditable: false,
      },
      {
        name: "Jan",
        type: "N",
        isEditable: false,
      },
      {
        name: "Feb",
        type: "N",
        isEditable: false,
      },
      {
        name: "Mar",
        type: "N",
        isEditable: false,
      },
    ];

  let customerPlanColumns;
  if (mode != "view")
    customerPlanColumns = [
      {
        name: "Customer Name",
        type: "T",
        isEditable: false,
      },
      {
        name: "Customer Code",
        type: "T",
        isEditable: false,
      },
      {
        name: "City",
        type: "T",
        isEditable: false,
      },
      // {
      //   name: "Actual 2022-2023",
      //   type: "N",
      //   isEditable: false,
      // },
      {
        name: `Plan ${cfy}`,
        type: "N",
        isEditable: true,
      },
    ];
  else
    customerPlanColumns = [
      {
        name: "Customer Name",
        type: "T",
        isEditable: false,
      },
      {
        name: "Customer Code",
        type: "T",
        isEditable: false,
      },
      {
        name: "City",
        type: "T",
        isEditable: false,
      },
      // {
      //   name: "Actual 2022-2023",
      //   type: "N",
      //   isEditable: false,
      // },
      {
        name: "Plan 2023-2024",
        type: "N",
        isEditable: false,
      },
    ];
  function invokeLoader() {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
    }
  }

  //JSX MARKUP
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Detailed Sales Plan
          </h4>
          <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            QTY IN LT/KG
          </p>
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{cfy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>REGION</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.region_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>TERRITORY</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.territory_name}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["agri-portal-controls"]}>
          <div className={styles["left"]}>
            <div>
              <div>
                <div style={{ zIndex: 1010, position: "relative" }}>
                  <div className={styles["agri-portal-label"]}>
                    <b>Product Group</b>
                  </div>
                </div>
              </div>
              <div
                style={{
                  zIndex: 1010,
                  position: "relative",
                  marginRight: "1rem",
                }}
              >
                {/* <Dropdown show>
                            <Dropdown.Toggle
                                variant="primary-inner dropdownbtn"
                                id="dropdown-basic"
                            >
                                {selectedProduct?.product_name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                    {
                                        productDropdown && productDropdown.map(el=>{
                                            return <Dropdown.Item href="#" onClick={()=>{setSelectedProduct(el)}}>
                                            {el.product_name}
                                        </Dropdown.Item>
                                        })
                                    }
                            </Dropdown.Menu>
                            </Dropdown> */}
                <div>
                  <select
                    className="form-control newbgselect"
                    name="business_unit_id"
                    style={{
                      cursor: "pointer",
                      border: "1px solid #0195D4",
                      color: "#0195DA",
                      minWidth: "120px",
                    }}
                    // classNamePrefix="select"
                    value={selectedProduct?.product_name}
                    onChange={(e) => {
                      setSelectedProduct(
                        productDropdown.find(
                          (el) => el.product_name == e.target.value
                        )
                      );
                    }}
                  >
                    {productDropdown &&
                      productDropdown?.map((el, idx) => {
                        return (
                          <option
                            value={el.product_name}
                            label={el.product_name}
                          />
                        );
                      })}
                  </select>
                </div>
              </div>
              {/* <div>
                            <div style={{zIndex:1010,position:"relative"}}>
                                <div className={styles["valuebox"]}>2902</div>
                            </div>
                        </div> */}
            </div>
          </div>
          <div className={styles["right"]}>
            <div>
              <div style={{ zIndex: 1010, position: "relative" }}>
                <div className={styles["agri-portal-label"]}>
                  <b>Product Qty</b>
                </div>
                <div className={styles["valuebox"]}>
                  {selectedProduct?.product_qty}
                </div>
              </div>
            </div>
          </div>
        </div>

        {existingData.length > 0 &&
          (mode == "edit" || mode == "view" || mode == "approve") && (
            <div className={styles["table_contain"]}>
              <div style={{ zIndex: 1010, position: "relative" }}>
                <div className={styles["agri-portal-label"]}>
                  <b>Data Entered For</b>
                </div>
              </div>
              <div className={styles["agri-portal-table"]}>
                <div
                  class="table-responsive"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <table
                    class="table table-striped table-bordered tablecured"
                    style={{ maxHeight: "200px" }}
                  >
                    <thead style={{ position: "sticky", top: 0 }}>
                      <tr style={{ textAlign: "center" }}>
                        <th>Product Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {existingData.map((ele) => {
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{ele?.product_name}</td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSelectedProduct(
                                  productDropdown.find(
                                    (el) => el.product_name == ele?.product_name
                                  )
                                );
                              }}
                            >
                              {mode == "view" ? "View" : "Edit"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

        {/* <div className={styles["table_contain"]}>

                <div>
                    <div style={{ zIndex: 0, position: "relative" }}>
                        <div className={styles["label"]}><b>PREVIOUS YEAR ACTUAL</b></div>
                    </div>
                </div>
                <div className={styles["table"]}>
                    <ModularTable showTotal={true} columns={previousYearColumns} data={tableData[selectedProduct?.sale_product_id]} />
                </div>
            </div> */}

        <div className={styles["table_contain"]}>
          <div>
            <div style={{ zIndex: 1010, position: "relative" }}>
              <div className={styles["agri-portal-label"]}>
                <b>PLANNING YEAR</b>
                <span className="ml-2"
                >
                  (QTY IN LT/KG)
                </span>
              </div>
            </div>
          </div>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              showTotal={true}
              columns={planningYearColumns}
              data={tableData2[selectedProduct?.sale_product_id]}
              onTableDataChange={onTable2DataChange}
            />
          </div>
        </div>

        <div className={styles["table_contain"]}>
          <div style={{
            display: 'flex'
          }}>
            <div style={{ zIndex: 1010, position: "relative" }}>
              <div className={styles["agri-portal-label"]}>
                <b>CUSTOMER WISE SALES PLAN</b>
                <span className="ml-2"
                >
                  (QTY IN LT/KG)
                </span>
              </div>
            </div>
          </div>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              showTotal={true}
              columns={customerPlanColumns}
              data={tableData3[selectedProduct?.sale_product_id]}
              onTableDataChange={onTable3DataChange}
            />
          </div>
        </div>
        {/* <div className={styles["instructions"]}>
                <p><b>INSTRUCTIONS</b></p>
                <p>You would find a list of product when you click this form</p>
                <p>These are the products for which you have planned sales for current F year in the previous form</p>
                <p>Select a product and complete monthwise/packwise/customerwise detailing</p>
                <p>Previous year actual sales monthwise/productwise breakup is given in 1st table just for</p>
            </div> */}
        {approvalList?.approvals && approvalList?.approvals.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>Action At</th>
                      <th>Action By</th>
                      <th>Action User</th>
                      <th>Action Status</th>
                      <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList?.approvals &&
                      approvalList?.approvals.map((el, i) => {
                        let status;

                        const findPendingIndex =
                          approvalList.approvals.findIndex(
                            (ele) => ele.status == "P" && ele.discarded == false
                          );

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P" && el.discarded == true)
                          status = "Auto Discarded";
                        else if (el.status == "P") status = "Pending";
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr>
                            <td>
                              {status == "Pending"
                                ? "-"
                                : moment(el.action_at)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm:ss")}
                            </td>
                            <td>{el.action_by_id}</td>
                            <td>{el.action_user_name}</td>
                            <td>
                              {findPendingIndex == -1
                                ? status
                                : i > findPendingIndex
                                  ? "-"
                                  : status}
                            </td>
                            <td>{el.remarks}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {userData.organisation_code === "PIIL" ?
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  setFreezeCTA(false);
                  prepareData();
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  setFreezeCTA(false);
                  confirmDialog("D");
                }}
              >
                Save
              </div>
            </div>
          )}
          {mode == "approve" || approvalList?.for_approval ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
          )}
        </div>
        :
        <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
        }
      </div>
    </div>
  );
};
export default Root;
