import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const isDataExists = async (travel_id, isData) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/exists${
      travel_id == undefined || travel_id == "" || travel_id == null
        ? ""
        : `?travel_id=${travel_id}`
    }`,
    isData
  );
};

export const isAddDataExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/exists_2`, isData);
};

export const getModePermission = async () => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/travel_mode_permissions`);
};

export const isLeaveExists = async (isData) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_leave_exists`,
    isData
  );
};

export const getUsersDetails = async (employee_code) => {
  return axios.get(`${AUTH_BASE_URL}/users?employee_code=${employee_code}`);
};

export const getOutstandingAmount = async (employee_code, travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/get_outstanding_amount?user_id=${employee_code}&travel_id=${travel_id}`
  );
};

export const deleteDataFromMain = async (id) => {
  return axios.delete(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_detail_2/${id}`
  );
};
export const getRemarksValue = async (travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_approval_remark_log?travel_id=${travel_id}`
  );
};

export const getBackDateData = async (queryParam) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_back_date?${queryParam}`
  );
};
