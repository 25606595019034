import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown, Spinner } from "react-bootstrap";
import Select from "react-select";
import AddSkillQuaterly from "./AddSkillQuaterly";
import * as SkillQuarterService from "../../service/skillquaterly";
import * as SkillService from "../../service/skill";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import QuaterlyBulkImport from "./QuaterlyBulkImport";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { injectStyle } from "react-toastify/dist/inject-style";
if (typeof window !== "undefined") {
  injectStyle();
}

function SkillQuaterly() {
  const dispatch = useDispatch();

  const [importshow, importsetShow] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);

  const [skillList, setSkillList] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [skillData, setSkillData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [permissionAccess, setPermissionAccess] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [downloadButton, setDownloadButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exDataDown, setExDataDown] = useState({});
  const [allSkillData, setAllSkillData] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [allEmployee, setAllEmployee] = useState([]);
  const [dataEmp, setDataEmp] = useState([]);
  const [dataRev, setDataRev] = useState([]);
  const [dataApp, setDataApp] = useState([]);
  const [allYear, setAllYear] = useState([]);
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [showSkillTab, setShowSkillTab] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    year: "",
    quarter: "",
    employee_id: "",
    skill_id: "",
    reviewed_by: "",
    approved_by: ""
  });

  const handleKey = (
    year,
    quarter,
    employee_id,
    skill_id,
    reviewed_by,
    approved_by,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (year !== "" && year !== undefined && year !== null) {
      queryParm = queryParm + "&year=" + year;
    }
    if (quarter !== "" && quarter !== undefined && quarter !== null) {
      queryParm = queryParm + "&quarter=" + quarter;
    }
    if (
      employee_id !== "" &&
      employee_id !== undefined &&
      employee_id !== null
    ) {
      queryParm = queryParm + "&employee_id=" + employee_id;
    }
    if (skill_id !== "" && skill_id !== undefined && skill_id !== null) {
      queryParm = queryParm + "&skill_id=" + skill_id;
    }
    if (reviewed_by !== "" && reviewed_by !== undefined && reviewed_by !== null) {
      queryParm = queryParm + "&reviewed_by=" + reviewed_by;
    }
    if (approved_by !== "" && approved_by !== undefined && approved_by !== null) {
      queryParm = queryParm + "&approved_by=" + approved_by;
    }
    return queryParm;
  };

  const getSkillUser = (
    year,
    quarter,
    employee_id,
    skill_id,
    reviewed_by,
    approved_by,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));

    SkillQuarterService.getSkillsQuarter(
      handleKey(
        year,
        quarter,
        employee_id,
        skill_id,
        reviewed_by,
        approved_by,
        pageNo,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setSkillList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetails = (
    year,
    quarter,
    employee_id,
    skill_id,
    reviewed_by,
    approved_by,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    setIsExcelLoading(true);
    SkillQuarterService.getExportValue(
      handleKey(
        year,
        quarter,
        employee_id,
        skill_id,
        reviewed_by,
        approved_by,
        pageNo,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        setIsExcelLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "skill-quaterly-reports.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const addSkills = () => {
    setShowForm(true);
    setEditMode(false);
    setSkillData(null);
  };

  const editSkill = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setSkillData(eData);
  };

  const onClose = () => {
    setShowForm(false);
    setSkillData(null);
  };

  const onAddCallBack = () => {
    setShowForm(false);
    getSkillUser("", "", "", "", "", "", currentPage, pageSizeNo, "-id", true);
  };
  const filterCallBack = () => {
    setShowFilter(false);
    getSkillUser("", "", "", "", "", "", currentPage, pageSizeNo, "-id", true);
  };

  const openFilter = () => {
    setShowFilter(true);
    setIsLoading(false);
    getAllYear();
    getAllSkills(false);
    getEmployee(false);
  };
  const onCloseFilter = () => {
    setShowFilter(false);
  };

  const deleteSkillUser = (idx) => {
    SkillQuarterService.deleteSkillQuarter(idx)
      .then((res) => {
        if (res.data.status === true) {
          getSkillUser("", "", "", "", "", "", currentPage, pageSizeNo, "-id", true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id) => {
    confirmAlert({
      title: "Delete Quarterly Reports",
      message: `Are you sure to delete?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSkillUser(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSkillUser(
      dataFilter?.year,
      dataFilter?.quarter,
      dataFilter?.employee_id,
      dataFilter?.skill_id,
      dataFilter?.reviewed_by,
      dataFilter?.approved_by,
      activePage,
      pageSizeNo,
      "-id",
      true
    );
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
    const year = dataFilter?.year;
    const quarter = dataFilter?.quarter;
    const employee_id = dataFilter?.employee_id;
    const skill_id = dataFilter?.skill_id;
    const reviewed_by = dataFilter?.reviewed_by;
    const approved_by = dataFilter?.approved_by;

    getSkillUser(
      year,
      quarter,
      employee_id,
      skill_id,
      reviewed_by,
      approved_by,
      currentPage,
      pageSizeNo,
      "-id",
      true
    );
    onCloseFilter();
  };

  const getSkillPermission = () => {
    let query = "/skill-tab";
    SkillService.getPermissions(query)
      .then((res) => {
        let findIndex = res.data.dataList.findIndex(el => el.name == "Can Add Skill Tab Access")
        if (findIndex != -1) {
          setShowSkillTab(true)
        }
      })
      .catch((err) => console.log(err));
    SkillService.getSkillPermission()
      .then((response) => {
        setPermissionAccess(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const closeModal = () => {
    importsetShow(false);
    setDownloadButton(false);
  };

  const importExel = (values) => {
    setIsLoading(true);
    SkillQuarterService.importExel(values)
      .then((response) => {
        setExDataDown(response.data);
        if (response.status === 200) {
          toast.success("Quarterly Reports Bulk Uploaded Successfully");
          getSkillUser("", "", "", "", "", "", 1, pageSizeNo, "-id", true);

          setDownloadButton(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const onProfileChange = (e) => {
    const files = e.target.files[0];
    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    SkillService.uploadImages(fileData).then((response) => {
      let data = response.data;
      if (response.data.status === true) {
        importExel(data);
      }
    });
  };

  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "SkillQuarterly.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    getSkillPermission();
    getSkillUser("", "", "", "", "", "", currentPage, pageSizeNo, "-id", true);
  }, [pageSizeNo]);

  const getAllSkills = (paginate) => {
    SkillQuarterService.getAllSkills(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = { value: x.id, label: x.skill_id };
          data.push(data1);
        });
        setAllSkillData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllYear = () => {
    SkillQuarterService.getAllYear()
      .then((response) => {
        setAllYear(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option key={`opt${index}`} value={`${item}`} label={`${item}`} />
    ));
  };

  const getEmployee = (paginate) => {
    SkillQuarterService.getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = {
            value: x.id,
            label: x.first_name + " " + x.last_name + "(" + x.username + ")",
          };
          data.push(data1);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(dataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDataFilter({
      year: "",
      quarter: "",
      employee_id: "",
      skill_id: "",
      reviewed_by: "",
      approved_by: "",
    });
    setDataEmp([]);
    setDataSkill([]);
    setDataRev([])
    setDataApp([])
    onCloseFilter();
    filterCallBack();
  };

  const data = ["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"];

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div className="row">
          <div className="col-md-3">
            <ul class="policylistnames">
              <li>
                <Link to="/skill">Home</Link>
              </li>
              {permissionAccess?.report_view === true && (
                <li>
                  <Link to="/skill-report">Report</Link>
                </li>
              )}
              {permissionAccess?.skill_department_view === true && (
                <li>
                  <Link to="/skill-department">Department Master</Link>
                </li>
              )}
              {permissionAccess?.skill_plant_view === true && (
                <li>
                  <Link to="/skill-plant">Plant Master</Link>
                </li>
              )}
              {permissionAccess?.skill_list_view === true && (
                <li>
                  <Link to="/skill-master">Skill Master</Link>
                </li>
              )}
              {permissionAccess?.skill_group_view === true && (
                <li>
                  <Link to="/skill-group">Skill Group Master</Link>
                </li>
              )}
              {permissionAccess?.skill_sub_group_view === true && (
                <li>
                  <Link to="/skill-sub-group">Sub Group Master</Link>
                </li>
              )}
              {permissionAccess?.group_owner_mapping_view === true && (
                <li>
                  <Link to="/skill-group-mapping">Skill Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.quarterly_report_view === true && (
                <li class="active">
                  <Link to="/skill-quarterly">Quarterly Reports</Link>
                </li>
              )}
              {permissionAccess?.skill_matrix_usage_view === true && (
                <li>
                  <Link to="/skill-usage">Skill Matrix Usage</Link>
                </li>
              )}
              {permissionAccess?.user_group_mapping_view === true && (
                <li>
                  <Link to="/skill-user">User Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.skill_location_view === true && (
                <li>
                  <Link to="/skill-location">Skill Location Access</Link>
                </li>
              )}
              {
                showSkillTab && (
                  <li>
                    <Link to="/skill-tab">Skill Tab Access</Link>
                  </li>
                )
              }
            </ul>
          </div>
          <div className="col-md-9">
            <div className="content-wrapper-inner content-wrapper-inner2">
              <div className="innerheadsec">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <h4 className="inner-page-title">Quarterly Reports</h4>
                  </div>
                  <div className="col-md-12">
                    <div className="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img
                            src="images/export.png"
                            alt=""
                            className="mr-2"
                          />
                          {isExcelLoading ? "Please wait..." : "Export"}
                          {isExcelLoading && (
                            <Spinner
                              animation="border"
                              variant="primary"
                              as="span"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={skillList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  dataFilter.year,
                                  dataFilter.quarter,
                                  dataFilter.employee_id,
                                  dataFilter.skill_id,
                                  dataFilter.reviewed_by,
                                  dataFilter.approved_by,
                                  1,
                                  pageSizeNo,
                                  "-id",
                                  true
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={() => openFilter()}
                        className="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" className="mr-3" />
                        Filter
                      </button>
                      {permissionAccess?.quarterly_report_update === true && (
                        <>
                          <button
                            className="btn btn-danger"
                            onClick={importuser}
                          >
                            <img
                              src="images/upload.png"
                              alt=""
                              className="mr-3"
                            />
                            Import Data
                          </button>
                          <button
                            className="btn btn-primary-inner"
                            onClick={() => {
                              addSkills();
                            }}
                          >
                            <img
                              src="images/upload.png"
                              alt=""
                              className="mr-3"
                            />
                            Add New
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Year</th>
                      <th>Month</th>
                      <th>Skill ID</th>
                      <th>Location</th>
                      <th>Actual Level</th>
                      <th>Employee ID</th>
                      <th>Employee Name</th>
                      <th>Current Level</th>
                      <th>Planned Level</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Last Modified On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skillList?.length ? (
                      skillList?.map((data, index) => (
                        <>
                          <tr key={`desg${index}`}>
                            <td>
                              {permissionAccess?.quarterly_report_update ===
                                true ? (
                                <>
                                  <button
                                    className="btn btn-blanktd text-primary f-12"
                                    type="button"
                                    onClick={() => {
                                      editSkill(data);
                                    }}
                                  >
                                    <i className="far fa-edit"></i>
                                  </button>
                                  <button
                                    className="btn btn-blanktd text-danger ml-2 f-12"
                                    type="button"
                                    onClick={() => {
                                      submit(data?.id);
                                    }}
                                  >
                                    <i className="far fa-trash-alt text-danger"></i>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <b>Not Allowed</b>
                                </>
                              )}
                            </td>
                            <td className="text-theme fw-bold">{data?.year}</td>
                            <td>{data?.quarter}</td>
                            <td>{data?.skill_Id}</td>
                            <td>{data?.location_ID}</td>
                            <td>{data?.skill_level}</td>
                            <td>{data?.employee_Id}</td>
                            <td>{data?.employee_name}</td>
                            <td>{data?.previous_level}</td>
                            <td>{data?.required_level}</td>
                            <td>
                              {
                                // data.approved_by_id ?
                                  data?.status 
                                  // :
                                  // "Planned"
                              }
                            </td>
                            <td>{data?.created_at}</td>
                            <td>{data?.updated_at}</td>
                          </tr>
                        </>
                      ))
                    ) :
                      <tr>
                        <td colSpan={16}> No Record Found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>

              {skillList?.length == 0 ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-sm-5">
                    <div className="sortinglist">
                      Show
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showForm ? (
        <>
          <AddSkillQuaterly
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editSkills={skillData}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      <Modal
        show={showFilter}
        onHide={onCloseFilter}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="form-group innergroup">
              <label>Year</label>
              <select
                name="year"
                className="form-control newbgselect"
                onChange={(e) => {
                  setDataFilter({
                    ...dataFilter,
                    year: e.target.value,
                  });
                }}
                value={dataFilter?.year}
              >
                <option value="">Select</option>
                {renderOptions(allYear)}
              </select>
            </div>
            <div className="form-group innergroup">
              <label>Quarter</label>
              <select
                className="form-control newbgselect"
                name="quarter"
                onChange={(e) => {
                  setDataFilter({
                    ...dataFilter,
                    quarter: e.target.value,
                  });
                }}
                value={dataFilter?.quarter}
              >
                <option value="">Select</option>
                <option value="Jan-Mar">January-March</option>
                <option value="Apr-Jun">April-June</option>
                <option value="Jul-Sep">July-September</option>
                <option value="Oct-Dec">October-December</option>
                <option value={data}>All</option>
              </select>
            </div>
            <div className="form-group innergroup">
              <label>Employee ID</label>
              <Select
                name="employee_id"
                id="employee_ids"
                options={allEmployee}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setDataEmp(e);
                  setDataFilter({
                    ...dataFilter,
                    employee_id: e.value,
                  });
                }}
                value={dataEmp}
              />
            </div>
            <div className="form-group innergroup">
              <label>Skill ID</label>
              <Select
                name="skill_id"
                id="skill_ids"
                options={allSkillData}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setDataSkill(e);
                  setDataFilter({
                    ...dataFilter,
                    skill_id: e.value,
                  });
                }}
                value={dataSkill}
              />
              <div className="form-group innergroup">
                <label>Review By ID</label>
                <Select
                  name="review_id"
                  id="review_ids"
                  options={allEmployee}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setDataRev(e);
                    setDataFilter({
                      ...dataFilter,
                      reviewed_by: e.value,
                    });
                  }}
                  value={dataRev}
                />
              </div>
              <div className="form-group innergroup">
                <label>Approve By ID</label>
                <Select
                  name="review_id"
                  id="review_ids"
                  options={allEmployee}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setDataApp(e);
                    setDataFilter({
                      ...dataFilter,
                      approved_by: e.value,
                    });
                  }}
                  value={dataApp}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                clearFilter();
              }}
            >
              Clear
            </button>
            <button
              className="btn btn-primary-inner bpi-main"
              type="submit"
              onClick={(e) => SubmitHandler(e)}
            >
              Apply
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {importsetShow && (
        <QuaterlyBulkImport
          importshow={importshow}
          importclose={importclose}
          getSkillUser={getSkillUser}
        />
      )}
    </>
  );
}

export default SkillQuaterly;
