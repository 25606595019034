import React, { useState, useEffect } from "react";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import * as deptServices from "../../../service/department";
import { Formik } from "formik";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Spinner,
} from "react-bootstrap";
import AddEmployee from "./AddEmployee";
import * as EmpService from "../../../service/employee";
import { confirmAlert } from "react-confirm-alert";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader
} from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { injectStyle } from "react-toastify/dist/inject-style";
import ViewEmployee from "./ViewEmployee";
import ReactPaginate from "react-paginate";
import EmployeeDir from "../employee-dir";
import Select from "react-select";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

if (typeof window !== "undefined") {
  injectStyle();
}

function Employee() {
  const dispatch = useDispatch();
  // let viewFlag=true
  const [viewFlag, setviewFlag] = useState(true);
  const userData = useSelector(selectUserData);
  const [showForm, setShowForm] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [dropDept, setDropDept] = useState([]);
  const [dropBranch, setDropBranch] = useState([]);
  const [dropDesig, setDropDesig] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "id" });
  const [excelList, setExcelList] = useState([]);
  const [excelListImport, setExcelListImport] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [pageCount, setpageCount] = useState(1);

  const [department1, setDepartment1] = useState("");
  const [empDesg1, setempDesg1] = useState("");

  const [subPersonalArea, setSubPersonalAreaData] = useState([]);
  const [empLocations, setEmpLocations] = useState([]);
  const [location_id, setLocation_id] = useState([]);
  const [deptFil, setDeptFil] = useState("");
  const [empForCard, setEmpForCard] = useState([]);
  const [cardPage, setCardPage] = useState(1);

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [departmentDataFilter, setDepartmentDataFilter] = useState({
    designation_id: "",
    department_id: "",
    branch_id: "",
  });
  const [departmentDataForExport, setDepartmentDataForExport] = useState({
    business_unit_id: "",
    department_id: "",
    designation_id: "",
  });

  const [printable, setPrintable] = useState(false);
  const [printableData, setPrintableData] = useState([]);
  const [printableError, setPrintableError] = useState(false);

  const getEmployee = (
    pageNo,
    sort_by,
    search,
    paginate,
    dept,
    branchId,
    desigId,
    locationid
  ) => {
    EmpService.getEmployee(
      pageNo,
      pageSizeNo,
      sort_by,
      search,
      paginate,
      dept,
      branchId,
      desigId,
      locationid === ""
        ? []
        : location_id?.map((obj) => {
          return obj?.value;
        })
    )
      .then((response) => {
        let recordsForCard = response?.data?.dataList?.result
          .filter((obj) => {
            if (
              obj?.name === "" ||
              obj?.email === "" ||
              obj?.mobile === "" ||
              obj?.name === null ||
              obj?.email === null ||
              obj?.mobile === null
            ) {
              return false;
            } else {
              return true;
            }
          })
          .map((x) => {
            return x;
          });
        setEmpForCard(response?.data?.dataList?.result);
        if (recordsForCard?.length % pageSizeNo == 0) {
          setCardPage(recordsForCard?.length / pageSizeNo);
        } else {
          setCardPage(Math.floor(recordsForCard?.length / pageSizeNo) + 1);
        }
        setEmpList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const exportHandleKey = (
    search,
    designation_id,
    department_id,
    branch_id,
    filetype,
    locationid
  ) => {
    let queryParm = "";

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (filetype !== "" && filetype !== undefined && filetype !== null) {
      queryParm = queryParm + "&filetype=" + filetype;
    }
    if (branch_id !== "" && branch_id !== undefined && branch_id !== null) {
      queryParm = queryParm + "&branch_id=" + branch_id;
    }
    if (
      department_id !== "" &&
      department_id !== undefined &&
      department_id !== null
    ) {
      queryParm = queryParm + "&department_id=" + department_id;
    }
    if (
      designation_id !== "" &&
      designation_id !== undefined &&
      designation_id !== null
    ) {
      queryParm = queryParm + "&designation_id=" + designation_id;
    }
    if (
      locationid !== "" &&
      locationid !== undefined &&
      locationid !== null
    ) {
      queryParm = queryParm + "&locationId=" + location_id?.map((obj) => {
        return obj?.value;
      });
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    designation_id,
    department_id,
    branch_id,
    locationid
  ) => {
    dispatch(setDisplayLoader("Display"));
    EmpService.getExportValue(
      exportHandleKey(
        search,
        designation_id,
        department_id,
        branch_id,
        "custom",
        locationid
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "employee-dir.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const deleteEmployee = (idx) => {
    EmpService.deleteEmployee(idx)
      .then((res) => {
        if (res.data.status == true) {
          getEmployee(
            currentPage,
            sortData.sort_by,
            searchStr,
            true,
            departmentDataFilter.department_id,
            departmentDataFilter.branch_id,
            departmentDataFilter.designation_id,
            empLocations
          );

          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getEmployee(
        currentPage,
        sortData.sort_by,
        data,
        true,
        departmentDataFilter.department_id,
        departmentDataFilter.branch_id,
        departmentDataFilter.designation_id,
        empLocations
      );
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getEmployee(
      activePage,
      sortData.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      empLocations
    );
    setCurrentPage(activePage);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getEmployee(
        1,
        sortData.sort_by,
        e.target.value.toLowerCase(),
        true,
        departmentDataFilter.department_id,
        departmentDataFilter.branch_id,
        departmentDataFilter.designation_id,
        empLocations
      );
      setSearchStr(e.target.value);
    }
  };

  const addNewEmp = () => {
    setEditMode(false);
    setShowForm(true);
    setEmployee(null);
  };
  const editEmp = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setEmployee(eData);
  };
  const onClose = () => {
    setShowForm(false);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getEmployee(
      currentPage,
      sortData.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      empLocations
    );
  };

  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };
  const submit = (id, EmpName) => {
    confirmAlert({
      title: "Delete Employee",
      message: `Are you sure to delete ${EmpName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteEmployee(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const DesignationFilter = (location_ids, department_id) => {
    EmpService.DesignationFilter(location_ids, department_id)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropDesig(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const DepartmentFilter = (location_id) => {
    EmpService.DepartmentFilter(location_id)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropDept(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getBranch = (paginate) => {
    EmpService.getAllBranch(paginate)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropBranch(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const printableExport = () => {
    setPrintable(true);
    LocationFilterPrint();
  };
  const closePrintable = () => {
    setPrintable(false);
    setPrintableData([]);
    setPrintableError(false);
  };

  const PrintExport = (location_name) => {
    EmpService.getExportPrint(location_name)
      .then((response) => {
        if (response.status === 200) {
          setPrintable(false);
        }
        const link = document.createElement("a");
        link.href = response?.data?.dataList?.file_url;
        link.setAttribute("download", "Print-Export.xls");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const ValidateExport = () => {
    let data = printableData.value;
    if (printableData.length === 0) {
      setPrintableError(true);
    } else {
      PrintExport(data);
    }
  };

  const filterShow = () => {
    filteropen();
    DepartmentFilter();
    DesignationFilter();
    LocationFilter();
  };

  const onFilterSubmit = () => {
    filterclose();
  };

  // const clearFilter = () => {
  //   let ClearData = JSON.parse(JSON.stringify(departmentDataFilter));
  //   Object.entries(ClearData).map((type) => {
  //     if (
  //       type[1].constructor.name.toLowerCase() === "string" ||
  //       type[1].constructor.name.toLowerCase() === "array"
  //     ) {
  //       if (type[1].length > 0) {
  //         ClearData[type[0]] = "";
  //       }
  //     } else {
  //       if (type[1].length > 0) {
  //         ClearData[type[0]] = "";
  //       }
  //     }
  //   });
  //   // setempdesgid("")
  //   // setdepartment_id1([])
  //   // setbusinessunitid1("")
  //   // setempDesg1([])
  //   // setDepartment1("")
  //   setDepartmentDataFilter(ClearData);
  //   getEmployee(currentPage, sortData.sort_by, searchStr, true, "", "", "");
  //   filterClose();
  // };

  const mainDataFilter = (values) => {
    setFilterApplied(true);
    setDepartmentDataForExport({
      ...departmentDataForExport,
      department_id: values.department_id,
      branch_id: values.branch_id,
      designation_id: values.designation_id,
    });
    getEmployee(
      currentPage,
      sortData.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      empLocations
    );
  };
  const filterClose = () => {
    filterclose();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(departmentDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDepartmentDataFilter(ClearData);
    // setemploc();
    setLocation_id("");
    setDepartment1("");
    setempDesg1("");
    setLocation_id([]);
    getEmployee(currentPage, sortData.sort_by, searchStr, true, "", "", "", "");
    filterClose();
  };
  // const clearFilter = () => {
  //   let ClearData = JSON.parse(JSON.stringify(departmentDataFilter));
  //   Object.entries(ClearData).map((type) => {
  //     if (
  //       type[1].constructor.name.toLowerCase() === "string" ||
  //       type[1].constructor.name.toLowerCase() === "array"
  //     ) {
  //       if (type[1].length > 0) {
  //         ClearData[type[0]] = "";
  //       }
  //     } else {
  //       if (type[1].length > 0) {
  //         ClearData[type[0]] = "";
  //       }
  //     }
  //   });
  //   setempdesgid("")
  //   setdepartment_id1([])
  //   setbusinessunitid1("")
  //   setempDesg1([])
  //   setDepartment1("")
  //   setDepartmentDataFilter(ClearData);
  //   getEmployee(currentPage, sortData.sort_by, searchStr, true, "", "", "");
  //   filterClose();
  // };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getEmployee(
      currentPage,
      sdata.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      empLocations
    );
  };

  const getDirectoryDetails = (flag) => {
    EmpService.getDirectoryDetails()
      .then((response) => {
        if (flag === "flg") {
          setExcelList(response?.data?.dataList);
        } else {
          setExcelListImport(response?.data?.dataList);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const LocationFilter = () => {
    EmpService.LocationFilter()
      .then((response) => {
        let locationData = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res.description + "(" + res?.name + ")",
          };
        });
        setSubPersonalAreaData(locationData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const LocationFilterPrint = () => {
    EmpService.LocationFilter()
      .then((response) => {
        let locationData = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.name,
            label: res.description + "(" + res?.name + ")",
          };
        });
        setSubPersonalAreaData(locationData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getEmployee(
      currentPage,
      sortData.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      empLocations
    );
    getDirectoryDetails("flg");
  }, [pageSizeNo]);

  const handleShow = (e) => {
    getEmployee(
      currentPage,
      sortData.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      empLocations
    );
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };
  const changeTab = (tab) => {
    if (tab == "G") {
      setviewFlag(true);
    } else if (tab == "L") {
      setviewFlag(false);
    }
  };

  const PushLocation = (e) => {
    setLocation_id(e);
    let ValueFilter = e?.map((data) => {
      return data?.value;
    });
    setDeptFil(ValueFilter);
    setEmpLocations(Number(e.value));
    DepartmentFilter(ValueFilter);
    DesignationFilter(ValueFilter, "");
  };

  // useEffect(() => {
  //   if (userData?.first_name !== "Admin") {
  //     setDepartmentDataForExport({
  //       ...departmentDataForExport,
  //       business_unit_id: userData.personal_sub_area_id,
  //     });
  //     setLocation_id([
  //       {
  //         label:
  //           userData?.personal_sub_area_description +
  //           "" +
  //           userData?.personal_sub_area_name,
  //         value: userData?.personal_sub_area_id,
  //       },
  //     ]);
  //     setEmpLocations(userData.personal_sub_area_id);
  //   }
  // }, [userData]);

  useEffect(() => {
    if (empLocations?.length !== 0) {
      getEmployee(
        currentPage,
        sortData.sort_by,
        searchStr,
        true,
        departmentDataFilter.department_id,
        departmentDataFilter.branch_id,
        departmentDataFilter.designation_id,
        empLocations
      );
    }
  }, [empLocations]);

  return (
    <>
      <div class="text-right d-flex justify-content-end mt-3">
        <Dropdown show>
          <Dropdown.Toggle
            variant="primary-inner dropdownbtn bg-white"
            id="dropdown-basic"
          >
            <img src="images/export.png" alt="" className="mr-2" /> View
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {viewFlag === false && (
              <Dropdown.Item href="#">
                <button
                  type="button"
                  style={{ border: 0, background: "none" }}
                  onClick={() => changeTab("G")}
                >
                  <img src="images/card-view.png" width="20px" /> Card view
                </button>
              </Dropdown.Item>
            )}
            {viewFlag === true && (
              <Dropdown.Item href="#">
                <button
                  type="button"
                  style={{ border: 0, background: "none" }}
                  onClick={() => changeTab("L")}
                >
                  <img src="images/list-view.png" width="20px" /> List view
                </button>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {!viewFlag && (
        <>
          <div className="content-wrapper-inner mt-3">
            <ToastContainer autoClose={1000} />
            <div className="innerheadsec">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="inner-page-title">Employee Directory</h4>
                </div>
                <div className="col-md-12">
                  <div className="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Employee Code,
                            <br />
                            Employee Name <br />
                            Email Id <br />
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Employee Code, Employee Name, Email Id..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>

                <div className="col-lg-8">
                  <div className="inline-spacing btn-betweenspaing">
                    <button
                      type="button"
                      onClick={() => {
                        printableExport();
                      }}
                      className="btn btn-secondary-inner"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />
                      Printable Export
                    </button>
                    {/* {finalUrlValue?.D ? ( */}
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" className="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                searchStr,
                                departmentDataFilter.designation_id,
                                departmentDataFilter.department_id,
                                departmentDataFilter.branch_id,
                                "id",
                                empLocations
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* ) : (
                      ""
                    )} */}
                    <button
                      type="button"
                      onClick={() => {
                        filterShow();
                      }}
                      className="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" className="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th style={{ width: "20px" }}>S.No.</th>
                    {/* <th
                      onClick={(e) => {
                        handlerData("code");
                      }}
                      className={
                        sortData["code"] === undefined
                          ? "unsortcls"
                          : sortData["code"]
                      }
                      width="150"
                    >
                      Employee Code
                    </th> */}
                    <th
                      onClick={(e) => {
                        handlerData("name");
                      }}
                      className={
                        sortData["name"] === undefined
                          ? "unsortcls"
                          : sortData["name"]
                      }
                      width="150"
                    >
                      Name
                    </th>

                    <th
                      onClick={(e) => {
                        handlerData("designation");
                      }}
                      className={
                        sortData["designation"] === undefined
                          ? "unsortcls"
                          : sortData["designation"]
                      }
                      width="150"
                    >
                      Designation
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("department");
                      }}
                      className={
                        sortData["department"] === undefined
                          ? "unsortcls"
                          : sortData["department"]
                      }
                      width="150"
                    >
                      Department
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("sub_personal_area");
                      }}
                      className={
                        sortData["sub_personal_area"] === undefined
                          ? "unsortcls"
                          : sortData["sub_personal_area"]
                      }
                      width="150"
                    >
                      Location
                    </th>
                    <th width="150">Mobile No.</th>
                    <th
                      onClick={(e) => {
                        handlerData("telephone_ext");
                      }}
                      className={
                        sortData["telephone_ext"] === undefined
                          ? "unsortcls"
                          : sortData["telephone_ext"]
                      }
                      width="150"
                    >
                      Landline No.
                    </th>
                    {/* <th>Office Address</th> */}
                    {/* <th>Geo Location</th> */}
                  </tr>
                </thead>
                <tbody>
                  {empList.length ? (
                    empList?.map((data, index) => (
                      <>
                        <tr key={`Empp${index}`}>
                          <td>
                            {pageSizeNo * (currentPage - 1) + (index + 1)}
                          </td>
                          {/* <td className="text-theme fw-bold">{data?.code}</td> */}
                          <td className="text-theme fw-bold">
                            <div className="d-flex align-items-center">
                              <div className="teamtxt ml-0">
                                <Tooltip title={data?.name} position="bottom">
                                  <h5 className="mb-0 text-dark">
                                    {data?.name}
                                  </h5>
                                </Tooltip>
                                <p>{data?.email}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <Tooltip
                              title={data?.designation_name}
                              position="bottom"
                            >
                              {data?.designation_name}
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip
                              title={data?.department_name}
                              position="bottom"
                            >
                              {data?.department_name}
                            </Tooltip>
                          </td>
                          <td>
                            {data?.sub_personal +
                              "(" +
                              data?.sub_personal_name +
                              ")"}
                          </td>

                          <td>
                            {data?.country_code} {data?.mobile}
                          </td>
                          <td>{data?.telephone_ext}</td>
                          {/* <td>{data?.office_address}</td> */}
                          {/* <td>{data?.geo_location}</td> */}
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {empList?.length == 0 ? (
              ""
            ) : (
              <div className="row">
                <div className="col-sm-5">
                  <div className="sortinglist">
                    Show
                    <select
                      className="form-control"
                      style={{ textAlign: "center" }}
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-7 main-pointer">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {viewFlag && (
        <EmployeeDir
          data={empForCard}
          paginated={cardPage}
          getEmp={getEmployee}
          pageCount={pageCount}
        />
      )}

      <Formik
        initialValues={departmentDataFilter}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group innergroup">
                  <label>Select Location</label>
                  <Select
                    name="business_unit_id"
                    isMulti
                    options={subPersonalArea}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      PushLocation(e);
                      setCurrentPage(1);
                    }}
                    value={location_id}
                  />
                </div>

                <div className="form-group innergroup main-pointer">
                  <label>Select Department</label>
                  <Select
                    name="department_id"
                    options={dropDept}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      // handleChange(e);
                      // setdepartment_id1(e.value)
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        department_id: e.value,
                      });
                      DesignationFilter(deptFil, e.value);
                      setCurrentPage(1);
                      setDepartment1(e);
                    }}
                    value={department1}
                  />
                </div>
                <div className="form-group innergroup">
                  <label>Select Designation</label>
                  <Select
                    name="designation_id"
                    options={dropDesig}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      // console.log("EE",e)
                      // setempdesgid(e.value)
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        designation_id: e.value,
                      });
                      setCurrentPage(1);
                      setempDesg1(e);
                    }}
                    value={empDesg1}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => {
                    clearFilter();
                    setCurrentPage(1);
                  }}
                >
                  CLEAR
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    onFilterSubmit();
                  }}
                  className="btn btn-primary-inner bpi-main"
                >
                  Apply
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>

      <Modal
        show={printable}
        onHide={closePrintable}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Printable Export</Modal.Title>
        </Modal.Header>
        <form
        // onSubmit={handleSubmit}
        >
          <Modal.Body>
            <div className="form-group innergroup">
              <label>
                Select Location<span class="text-danger">*</span>
              </label>
              <Select
                name="business_unit_id"
                options={subPersonalArea}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
                onChange={(e) => {
                  setPrintableData(e);
                  setPrintableError(false);
                }}
                value={printableData}
              />
              {printableError && (
                <div className="small text-danger">
                  *Select Location For Printable Export
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                closePrintable();
              }}
            >
              Close
            </button>
            <button
              type="button"
              onClick={() => {
                ValidateExport();
              }}
              className="btn btn-primary-inner bpi-main"
            >
              Print Export
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Employee;
