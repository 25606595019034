import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const AddSuccessFactor = (props) => {
  const { showFrom, onClose, myData } = props;
  return (
    <Modal
      show={showFrom}
      onHide={onClose}
      size=""
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {myData.label}({myData.count})
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <table class="table table-striped table-bordered tablecured">
          {myData?.childLink?.map((data) => (
            <>
              <tr>
                <td class="py-2" style={{ whiteSpace: "normal" }}>
                  <Link to={data?.url} target="_blank" class="text-dark">
                    {data?.label}
                  </Link>
                </td>
              </tr>
            </>
          ))}
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default AddSuccessFactor;
