import React, { useState, useEffect } from "react";
import * as HeaderService from "../../service/jv_service";
import { Dropdown } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import * as moment from "moment";
import { Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { setDisplayLoader, selectFinalUrlPermission, setTracker } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData } from "../redux/piDataStore";

function ViewJV() {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [jvViewData, setJvViewData] = useState([]);
  const [remarksData, setRemarksData] = useState({
    status: "",
    remarks: "",
  });
  const [formValidation, setFormValidation] = useState({
    remarks: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [imgSize, setImgSize] = useState(false);
  const [errorAttachment, setErrorAttachment] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [file, setfiles] = useState([]);
  const [flag, setflag] = useState(false);
  const [approvalType, setApprovalType] = useState();
  const [approveUploadData, setApproveUploadData] = useState({
    attachments: [{}],
  });
  const location = useLocation();
  const { state } = useLocation();
  const [id, setid] = useState(location?.state?.from);
  let navigate = useNavigate();
  const pathname = window.location.pathname;
  const pathId = pathname.split("/")[2];

  const getViewData = (id) => {
    dispatch(setDisplayLoader("Display"));
    HeaderService.getViewData(id)
      .then((response) => {
        setJvViewData(response?.data?.dataList);
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (status, jv_id) => {
    let queryParm = "jv_id=" + jv_id;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    return queryParm;
  };

  const getExportDetails = (status, jv_id) => {
    dispatch(setDisplayLoader("Display"));
    HeaderService.getViewExportDetails(handleKey(status, jv_id))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Jv.xlsx");
        document.body.appendChild(link);
        link.click();
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const saveRemarksData = (value) => {
    setIsLoading(true);
    let data = remarksData;
    data.status = value;
    data.remarks = remarksData.remarks;
    HeaderService.saveRemarksData(jvViewData.my_pending_approval_id, data)
      .then((response) => {
        toast.success(response.data.message);
        getViewData(id);
        setIsLoading(false);
        let obj = {
          permission_id: finalUrlValue?.A_P?.id,
          transaction_id: jvViewData?.my_pending_approval_id
        }
        if(finalUrlValue?.A_P?.id !== null && finalUrlValue?.A_P?.id !== undefined){
          dispatch(setTracker(obj))
        }
        if (location.state?.flag === true) {
          navigate("/jv", { state: { data: location?.state?.data } });
        } else {
          navigate(`/common-approve/${location?.state?.subId}`, {
            state: {
              subtypeid: location?.state?.subId,
              subTypeName: "JV",
            },
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onProfileChange = async (e, flag) => {
    let file = e.target.files;
    if (
      file[0].type === "image/docx" ||
      file[0].type === "image/xls" ||
      file[0].type === "image/xlsx" ||
      file[0].type === "image/XLSX" ||
      file[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file[0].type === "text/csv" ||
      file[0].type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file[0].type ===
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12" ||
      file[0].type === "application/pdf" ||
      file[0].name.includes("msg")
    ) {
      setfiles(file);
      let files = [];
      let filename = [];
      let myAllPic = [];
      for (let i = 0; i < file.length; i++) {
        let data = e.target.files[i];
        let myUrl = URL.createObjectURL(e.target.files[i]);
        myAllPic.push(myUrl);
        const fileData = new FormData();
        fileData.append("uploaded_file", data);
        await HeaderService.uploadImages(fileData).then((response) => {
          let name = { name: data.name };
          filename.push(name);
          files.push(response.data);
          if(response?.data?.status === false){
            toast.error(response?.data?.message)
          }
        });
      }
      setErrorAttachment(false);
      setAttachment([]);
      setflag(true);
      setUploadDataIm(files);
    } else {
      toast.error("Only xls,xlsx,xlsb,docx and doc files are supported");
    }
  };

  const addAttachmentData = (id, value, approveUploadData) => {
    HeaderService.addAttachmentData(id, value, approveUploadData)
      .then((response) => {
        setAttachment(response.data.message);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    setApproveUploadData({ ...approveUploadData, attachments: uploadDataIm });
  }, [uploadDataIm]);

  useEffect(() => {
    if (uploadDataIm.length > 0) {
      addAttachmentData(pathId, true, approveUploadData);
    }
  }, [approveUploadData]);

  const deleteAttachment = (id) => {
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    // if (attachments == undefined) {
    //   let obj = {};
    //   Object.keys(file)
    //     .filter((del) => {
    //       console.log(file[del].name);

    //       if (file[del].name !== id) {
    //         return true;
    //       }
    //     })
    //     .map((item) => {
    //       obj[item] = file[item];
    //     });
    //   document.getElementById("attachmentsFile").value = "";
    //   setfiles(obj);
    // } else {
    //   let deleteId = [];
    //   if (attachment.length != 0) {
    //     let data2 = [];
    //     attachment.filter((del) => {
    //       if (del.attachment?.split("/")[4] !== id) {
    //         data2.push(del);
    //       } else {
    //         deleteattachment.push(del.id);
    //       }
    //     });
    //     setAttachment(data2);
    //     document.getElementById("attachmentsFile").value = "";
    //   } else {
    //     let obj = {};
    //     Object.keys(file)
    //       .filter((del) => {
    //         if (file[del].name !== id) {
    //           return true;
    //         }
    //       })
    //       .map((item) => {
    //         obj[item] = file[item];
    //       });
    //     document.getElementById("attachmentsFile").value = "";
    //     setfiles(obj);
    //   }
    // }
  };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...formValidation };
    if (!remarksData.remarks) {
      isError = true;
      formerr.remarks = "Remarks Required";
      setFormValidation(formerr);
    }
    return isError;
  };

  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"/images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"/images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"/images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"/images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ||
                data2.name.includes(".csv") ? (
                <img
                  src={"/images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"/images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"/images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name.includes(".msg") ? (
                <img
                  src={"/images/policy-icon/msg.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      );
    }
    return data;
  };

  const handleSaveValue = (value) => {
    if (handleValidate()) return false;
    saveRemarksData(value);
  };

  useEffect(() => {
    let jsonObj = {};
    jvViewData?.approval?.map((item, i) => {
      jsonObj[i] = item;
    });
    if (jsonObj !== undefined || jsonObj !== {}) {
      setApprovalType(jsonObj);
    }
  }, [jvViewData]);

  useEffect(() => {
    if (id == undefined) {
      getViewData(pathId);
    } else {
      getViewData(id);
    }
  }, [id, pathId]);

  return (
    <>
      <div class="row">
        <ToastContainer autoClose={1000} />
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">JV</h4>
                  <Dropdown
                    show
                    style={{ float: "right", marginBottom: "10px" }}
                  >
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" class="mr-2" />
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          style={{ border: 0, background: "none" }}
                          onClick={() => getExportDetails("", pathId)}
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/home">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>JV</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
              </div>

              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">View JV: {jvViewData?.id}</h3>
                </div>
              </div>

              <div class="row viewformaftersubmit">
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Document No.</label>
                    <p>{jvViewData?.doc_no}</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Company Code</label>
                    <p>
                      {jvViewData?.company_code?.name} (
                      {jvViewData?.company_code?.code})
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Document Type</label>
                    <p>
                      {jvViewData?.document_type?.document_desc} (
                      {jvViewData?.document_type?.document_type})
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Document Date</label>
                    <p>
                      {moment(jvViewData?.document_date).format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Posting Date</label>
                    <p>
                      {moment(jvViewData?.posting_date).format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Document Header Text</label>
                    <p
                      style={{
                        maxWidth: "130px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {jvViewData?.document_header}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Reference</label>
                    <p>{jvViewData?.reference}</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Created By</label>
                    <p>
                      {jvViewData?.created_by?.first_name}{" "}
                      {jvViewData?.created_by?.last_name} (
                      {jvViewData?.created_by?.username})
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Sap Doc. No.</label>
                    <p>{jvViewData?.sap_doc_no}</p>
                  </div>
                </div>
                <div class="col-md-3 viewformaftersubmit">
                  <div class="form-group innergroup">
                    <label>Download Attachments</label>
                    <p>
                      {jvViewData?.attachment_list
                        ?.filter((value) => value.type === "JV")
                        ?.map((item, idx) => (
                          <>
                            <br />
                            <span class="mr-3">
                              <i class="fas fa-file-download"></i>
                              <a
                                href={item?.attachment}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item?.attachment?.includes(".pdf") ? (
                                  <img
                                    src={"/images/policy-icon/pdf.png"}
                                    alt="image"
                                    width="70px"
                                    height="70px"
                                  />
                                ) : item?.attachment?.includes(".xlsb") ||
                                  item?.attachment?.includes(".xlsx") ||
                                  item?.attachment?.includes(".XLSX") ||
                                  item?.attachment?.includes(".xls") ||
                                  item?.attachment?.includes(".csv") ? (
                                  <img
                                    src={"/images/policy-icon/xlsx.png"}
                                    alt="image"
                                    width="70px"
                                    height="70px"
                                  />
                                ) : item?.attachment?.includes(".doc") ||
                                  item?.attachment?.includes(".docs") ||
                                  item?.attachment?.includes(".docx") ? (
                                  <img
                                    src={"/images/policy-icon/docs.png"}
                                    alt="image"
                                    width="70px"
                                    height="70px"
                                  />
                                ) : item?.attachment?.includes(".msg") ? (
                                  <img
                                    src={"/images/policy-icon/msg.png"}
                                    alt="image"
                                    width="70px"
                                    height="70px"
                                  />
                                ) : (
                                  ""
                                )}
                              </a>
                            </span>
                          </>
                        ))}
                    </p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>Remarks</label>
                    <p
                      style={{
                        maxWidth: "130px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {jvViewData?.remarks}
                    </p>
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <h6 class="text-theme mb-0">
                    <strong>Document Line Items</strong>
                  </h6>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured viewpo">
                <thead>
                  <tr>
                    <th>Posting Key</th>
                    <th>GL Code</th>
                    <th>Cost Center</th>
                    <th>Profit Center</th>
                    <th>Business Area</th>
                    <th>Internal Order</th>
                    <th>Amount</th>
                    <th>Assignment</th>
                    <th>Plant</th>
                    <th>Item Text</th>
                    <th>Currency</th>
                    <th>Rate</th>
                    <th>Tax Code</th>
                    <th>Business Place</th>
                  </tr>
                </thead>
                <tbody>
                  {jvViewData?.items?.map((data, idx) => (
                    <tr key={`jvData${idx}`}>
                      <td>{data?.posting_key}</td>
                      <td>
                        {data?.gl_code !== null &&
                          `${data?.gl_code?.short_desc} (${data?.gl_code?.gl_account})`}
                      </td>
                      <td>
                        {data?.cost_center !== null &&
                          `${data?.cost_center?.name} (${data?.cost_center?.code})`}
                      </td>
                      <td>
                        {data?.profit_center !== null &&
                          `${data?.profit_center?.description} (${data?.profit_center?.code})`}
                      </td>
                      <td>
                        {data?.business_area !== null &&
                          `${data?.business_area?.description} (${data?.business_area?.code})`}
                      </td>
                      <td>{data?.internal_order}</td>
                      <td>{data?.amount}</td>
                      <td>{data?.assignment}</td>
                      <td>
                        {data?.plant !== null &&
                          `${data?.plant?.description} (${data?.plant?.code})`}
                      </td>
                      <td>{data?.item_text}</td>
                      <td>
                        {data?.currency !== null &&
                          `${data?.currency?.code} (${data?.currency?.description})`}
                      </td>
                      <td>{data?.rate}</td>
                      <td>{data?.tax_code}</td>
                      <td>
                        {data?.business_place !== null &&
                          `${data?.business_place?.code} (${data?.business_place?.description})`}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div class="col-md-12 mt-2">
              <h6 class="text-theme mb-0">
                <strong>Approver List</strong>
              </h6>
            </div>
            <br />
            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured viewpo">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {jvViewData?.approval?.map((data, idx) => (
                    <tr key={`jvVal${idx}`}>
                      <td>{idx + 1}</td>
                      <td>{data?.approve}</td>
                      <td>
                        {data?.approve_type === "rm"
                          ? "Reporting Manager"
                          : data?.approve_type === "PE"
                          ? "Provisional Entries"
                          : data?.approve_type === "GJ"
                          ? "Gurgaon Jr.Voucher"
                          : data?.approve_type === "SU"
                          ? "Adjustment Document"
                          : data?.approve_type === "cb"
                          ? "Creator"
                          : data?.approve_type === "doctype"
                          ? "Document Approver"
                          : data?.approve_type}
                      </td>
                      {data?.on_hold === true ?
                      <td>On Hold</td>
                      :
                      <td>{data?.status}</td>
                      }
                      <td>
                        {(data?.status === "APPROVED" ||
                          data?.status === "SUBMITTED") &&
                          moment(data?.approved_at)
                            .utc()
                            .format("DD-MM-YYYY hh:mm a")}
                      </td>
                      <td>{data?.remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {approvalType &&
            approvalType[1]?.approve === userData?.full_name ? (
              <div class="col-md-4">
                <div class="form-group innergroup position-relative">
                  <label>Attachment</label>
                  <input
                    type="text"
                    name="attachments"
                    class="form-control bg-white"
                    placeholder="(xls, xlsx,xlsb, docx and doc files Format only)"
                    disabled
                  />
                  {errorAttachment ? (
                    <div className="small text-danger">
                      *Attachment is required
                    </div>
                  ) : null}

                  <div class="upload-btn-wrapper up-loposition">
                    <button class="uploadBtn">Browse</button>
                    <input
                      multiple
                      type="file"
                      title=""
                      name="attachments"
                      id="attachmentsFile"
                      accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                      onChange={(e) => onProfileChange(e, "attachments")}
                    />
                  </div>
                  {imgSize ? (
                    <div className="small text-danger">
                      *File size should not exceed 1mb
                    </div>
                  ) : null}
                  <p>
                    Selected files
                    {[file].map((a) => (
                      <span>
                        <br />
                        {a.name}
                      </span>
                    ))}
                  </p>
                  <div>
                    {Object.keys(file).length !== 0 ? (
                      <div className="d-flex">{attachments123()}</div>
                    ) : attachment != undefined && attachment.length != 0 ? (
                      <>
                        <div class="d-flex">
                          {attachment?.map((Data) => (
                            <div class="uploaded-image mr-1">
                              {console.log(Data, "attachment")}
                              <i class="" style={{ width: "20px" }}>
                                <a
                                  href={Data?.attachment}
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  {Data?.attachment?.includes(".pdf") ? (
                                    <img
                                      src={"/images/policy-icon/pdf.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : Data?.attachment.includes(".xlsx") ? (
                                    <img
                                      src={"/images/policy-icon/xlsx.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : Data?.attachment.includes(".xls") ? (
                                    <img
                                      src={"/images/policy-icon/xlsx.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : Data?.attachment.includes(".doc") ? (
                                    <img
                                      src={"/images/policy-icon/docs.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : Data?.attachment.includes(".docs") ||
                                    Data?.attachment.includes(".csv") ? (
                                    <img
                                      src={"/images/policy-icon/docs.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : Data?.attachment.includes(".pptx") ? (
                                    <img
                                      src={"/images/policy-icon/ppt.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : Data.attachment.includes(".mp4") ? (
                                    <img
                                      src={"/images/policy-icon/video.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : Data?.attachment.includes(".msg") ? (
                                    <img
                                      src={"/images/policy-icon/msg.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : (
                                    <img
                                      src={Data.attachment}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  )}
                                </a>
                              </i>
                              <button
                                type="button"
                                onClick={(e) => {
                                  deleteAttachment(
                                    Data?.attachment?.split("/")[4]
                                  );
                                }}
                                className="btn btn-blanktd text-danger ml-2"
                              >
                                <i className="far fa-trash-alt text-danger"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-md-3 viewformaftersubmit">
                <div class="form-group innergroup">
                  <label>SSC Attachments</label>
                  <p>
                    {jvViewData?.attachment_list
                      ?.filter((value) => value.type === "SSC")
                      ?.map((item, idx) => (
                        <>
                          <br />
                          <span class="mr-3">
                            <i class="fas fa-file-download"></i>
                            <a
                              href={item?.attachment}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item?.attachment?.includes(".pdf") ? (
                                <img
                                  src={"/images/policy-icon/pdf.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : item?.attachment?.includes(".xlsb") ||
                                item?.attachment?.includes(".xlsx") ||
                                item?.attachment?.includes(".csv") ? (
                                <img
                                  src={"/images/policy-icon/xlsx.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : item?.attachment?.includes(".doc") ||
                                item?.attachment?.includes(".docs") ||
                                item?.attachment?.includes(".docx") ? (
                                <img
                                  src={"/images/policy-icon/docs.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : item?.attachment?.includes(".msg") ? (
                                <img
                                  src={"/images/policy-icon/msg.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : (
                                ""
                              )}
                            </a>
                          </span>
                        </>
                      ))}
                  </p>
                </div>
              </div>
            )}
            {jvViewData?.my_pending_approval === true && (
              <div class="col-md-12">
                <div class="form-group innergroup">
                  <label>
                    Add Comments<span class="text-danger">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    placeholder="Enter Comments here..."
                    name="remarks"
                    onChange={(e) => {
                      setRemarksData({
                        ...remarksData,
                        remarks: e.target.value,
                      });
                      setFormValidation({
                        ...formValidation,
                        remarks: "",
                      });
                    }}
                    value={remarksData.remarks}
                  ></textarea>
                  <div className="small text-danger">
                    {formValidation.remarks}
                  </div>
                </div>
              </div>
            )}

            <div class="col-md-12 pb-3">
              <button
                class="btn btn-outline-secondary mr-2"
                onClick={() => {
                  location.state?.flag === true
                    ? navigate("/jv", {
                        state: { data: location?.state?.data },
                      })
                    : location.state?.flag === "1"
                    ? navigate("/jv-report", {
                        state: { data: location?.state?.data },
                      })
                    : navigate(`/common-approve/${location?.state?.subId}`, {
                        state: {
                          subtypeid: location?.state?.subId,
                          subTypeName: "JV",
                        },
                      });
                }}
              >
                <i class="fas fa-arrow-circle-left"></i> Cancel
              </button>
              {isLoading ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  as="span"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              ) : (
                jvViewData?.my_pending_approval === true && (
                  <>
                    <button
                      class="btn btn-outline-danger mr-2"
                      onClick={() => handleSaveValue("REJECTED")}
                    >
                      <i class="far fa-times-circle"></i> Reject
                    </button>
                    <button
                      class="btn btn-primary-inner bpi-main"
                      onClick={() => handleSaveValue("APPROVED")}
                    >
                      <i class="far fa-check-square"></i> Approve
                    </button>
                    {(jvViewData?.approval?.length > 1 && jvViewData?.on_hold === false) && (
                      <button
                      class="btn btn-primary-inner bpi-main ml-2"
                      onClick={() => handleSaveValue("OnHold")}
                    >
                      <i class="far fa-pause-circle"></i> On Hold
                    </button>
                    )}
                  </>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewJV;
