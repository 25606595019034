import React, { useState, useEffect } from "react";
import * as moment from "moment";
import {
  Modal,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import Select from "react-select";
import * as AttendanceService from "../../../service/AttendanceService";
import * as UserService from "../../../service/userService";
import { getUserRmList } from "../../../service/jv_service";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import {
  setDisplayLoader,
  selectFinalUrlPermission,
  selectUserData,
} from "../../redux/piDataStore";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap";
import { each } from "jquery";
import {getAllOrganisation} from "../../../service/organisation"
import { uploadfile } from "../../../service/Survey";

function AbscondingReport() {

  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userDetails = useSelector(selectUserData);
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const [moduleList, setModuleList] = useState([]);
  const [userList, setUserList] = useState([]);
  const userData = useSelector(selectUserData)
  const [orgValue, setOrgValue]=useState([]);
  const [buValue, setBUValue] = useState([]);
  const [locationValue, setLocationValue] = useState([]);
  const [hrValue, setHRValue] = useState([]);

  const [status, setStatus] = useState([]);
  const [currentTabType, setCurrentTabType] = useState("P");

  const [filterdata, setfilterData] = useState({
    organisation_id: "",
    bussiness_unit_id: "",
    location_id: "",
    hr_id: ""
  });
  const [attendanceDataForExport, setAttendanceDataForExport] = useState({
    from_date: "",
    to_date: "",
    user_id: "",
  });

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [importshow, importsetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [abscondingData, setAbscondingData] = useState([]);
  const [notAbscondingData, setNotAbscondingData] = useState([]);
  const [abscondedData, setAbscondedData] = useState([]);
  const [isAbsconding, setIsAbsconding] = useState('');
  const[hROptions, setHROptions] = useState([]);
  const [personalSubAreaList, setPersonalSubAreaList] = useState([]);
  const [orgOptions, setOrgOptions] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [remarksModal, setRemarksModal] = useState(false);
  const [absId, setAbsId] = useState('');
  const [remarks, setRemarks] = useState("");
  const [daBillDetail, setDaBillDetail] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [imageLocalPreviewList, set_imageLocalPreviewList] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const getUserData = (value) => {
    getUserRmList(false)
          .then((response) => {
            let users = [];
            response?.data?.dataList?.result?.map((x) => {
              let data = {
                value: x?.id,
                label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
              };
              users.push(data);
            });
            setHROptions(users);
          })
          .catch((error) => {
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          });
  };


  const getAbsconding = (
    search,
    organisation_id,
    bussiness_unit_id,
    location_id,
    hr_id,
    page_no,
    page_size,
    sort_by,
    paginate,
    is_admin
  ) => {
    AttendanceService.getAbsconding(
      handleKey(
        search,
        organisation_id,
        bussiness_unit_id,
        location_id,
        hr_id,
        page_no,
        page_size,
        sort_by,
        paginate,
        is_admin
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let absconded = []
        let not_absconded = []
        let pending_abs = []
        response?.data?.dataList?.result?.map((each)=>{
          if(each?.is_absconding === 'Y'){
            absconded.push(each)
          }
          else if(each?.is_absconding === 'N'){
            not_absconded.push(each)
          }
          else{
            pending_abs.push(each)
          }
        })
        setAbscondingData(pending_abs);
        setAbscondedData(absconded);
        setNotAbscondingData(not_absconded);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getExportDetails = (
    search,
    from_date,
    to_date,
    user_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    is_admin
  ) => {
    dispatch(setDisplayLoader("Display"));
    AttendanceService.getAbscondingExport(
      handleKey(
        search,
        from_date,
        to_date,
        user_id,
        status,
        page_no,
        page_size,
        sort_by,
        paginate,
        is_admin
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "AbscondingEmployees.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleKey = (
    search,
    organisation_id,
    bussiness_unit_id,
    location_id,
    hr_id,
    page_no,
    page_size,
    sort_by,
    paginate,
    is_admin
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (hr_id !== "" && hr_id !== undefined && hr_id !== null) {
      queryParm = queryParm + "&hr_id=" + hr_id;
    }
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (location_id !== "" && location_id !== undefined && location_id !== null) {
      queryParm = queryParm + "&location_id=" + location_id;
    }
    if (bussiness_unit_id !== "" && bussiness_unit_id !== undefined && bussiness_unit_id !== null) {
      queryParm = queryParm + "&bussiness_unit_id=" + bussiness_unit_id;
    }
    if (organisation_id !== "" && organisation_id !== undefined && organisation_id !== null) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getAbsconding(
        e.target.value.toLowerCase(),
        filterdata.organisation_id,
        filterdata.bussiness_unit_id,
        filterdata.location_id,
        filterdata.hr_id,
        currentPage,
        pageSizeNo,
        "-id",
        true
      );
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = (e) => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getAbsconding(
        "",
        "",
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };
 
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getAbsconding(
      searchStr,
      filterdata.organisation_id,
      filterdata.bussiness_unit_id,
      filterdata.location_id,
      filterdata.hr_id,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const filterShow = () => {
    getUserData();
    getLocationData();
    getBuData();
    getOrgData();
    filteropen();
  };

  const filterClose = () => {
    filterclose();
  };

  const getLocationData = () =>{
     UserService.getSubPersonalArea(0)
      .then((response) => {
        if (response?.status === 200) {
          let areaList = response?.data?.dataList?.result?.map((obj) => {
            return {
              label: obj.description + "(" + obj.name + ")",
              value: obj.id,
            };
          });
          setPersonalSubAreaList(areaList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getBuData = () =>{
    UserService.getBranchFilter()
    .then((response) => {
      let bulist = response?.data?.dataList?.result?.map((obj) => {
        return {
          label: obj.name + "(" + obj.code + ")",
          value: obj.id,
        };
      });
      setBranchData(bulist);
    })
    .catch((error) => {
      console.log(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        "failed"
      );
    });
  }

  const getOrgData = () => {
    let paginate = false;
    getAllOrganisation(paginate).then((response)=>{
      let obj = response?.data?.dataList?.result?.map((res)=>{
        return {
          value: res.id,
          label: res.code
        }
      })
      setOrgOptions(obj)
    })
  }

  const mainDataFilter = () => {
    setFilterApplied(true);
    dispatch(setDisplayLoader("Display"));
    setAttendanceDataForExport({
      ...attendanceDataForExport,
      from_date: filterdata.from_date,
      to_date: filterdata.to_date,
      user_id: filterdata.user_id,
      status: filterdata?.status
    });
    const organisation_id = filterdata?.organisation_id;
    const bussiness_unit_id = filterdata?.bussiness_unit_id;
    const location_id = filterdata?.location_id;
    const hr_id = filterdata?.hr_id;
    getAbsconding(
      searchStr,
      organisation_id,
      bussiness_unit_id,
      location_id,
      hr_id,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    setOrgValue([]);
    setBUValue([]);
    setLocationValue([]);
    setHRValue([]);
    filterClose();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    if (
      filterdata.from_date === moment(new Date()).format("YYYY-MM-DD") &&
      filterdata.to_date === moment(new Date()).format("YYYY-MM-DD") &&
      filterdata.user_id === 0
    ) {
      filterClose();
    } else {
      setUserList([]);
      setStatus([]);
      let ClearData = JSON.parse(JSON.stringify(filterdata));
      Object.entries(ClearData).map((type) => {
        if (
          type[1].constructor.name.toLowerCase() === "string" ||
          type[1].constructor.name.toLowerCase() === "array"
        ) {
          if (type[1]?.length > 0) {
            ClearData[type[0]] = "";
          }
        } else {
          if (type[1]?.length > 0) {
            ClearData[type[0]] = "";
          }
        }
      });
      setfilterData(ClearData);
      filterClose();
      const d = new Date();
      let month = d.getMonth();
      let currMonth = month + 1;
      let from_date = moment(new Date()).format("YYYY-MM-DD");
      let to_date = moment(new Date()).format("YYYY-MM-DD");
      dispatch(setDisplayLoader("Display"));
      getAbsconding(
        searchStr,
        "",
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };
  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getAbsconding(
      searchStr,
      "",
      "",
      "",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      true
    );

  }, [pageSizeNo]);

  const handleActionChange = (value, index, data) => {
    let copydata = JSON.parse(JSON.stringify(abscondingData))
    copydata[index].is_absconding = value
    setAbscondingData(copydata)
  }

  const actionOnAbsconding = () => {
    let copydata = JSON.parse(JSON.stringify(abscondingData));
    let errorFound = false;

    for (let i = 0; i < copydata.length; i++) {
      const each = copydata[i];
      if (['Y', 'N', 'NA'].includes(each.is_absconding) && each?.is_abs_user === null) {
        errorFound = false;
        break;
      }
      errorFound = true;
    }
    if (errorFound) {
      toast.error('Kindly mark a user');
    }
    let datalist=[]
    abscondingData?.filter((res)=>{
      if(res?.is_absconding !== undefined && res?.is_absconding !== null && res?.is_absconding !== 'NA' && res?.is_abs_user === null)return true})
      .map((each)=>{
        datalist.push(each)
      })
    let obj = { 'data': datalist }
    if (datalist?.length > 0) {
      dispatch(setDisplayLoader("Display"));
      AttendanceService.actionOnAbsconding(obj).then((response) => {
        toast.success('Record Submitted Successfully.')
        getAbsconding(
          searchStr,
          "",
          "",
          "",
          "",
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          true
        );
      })
    }
  }

  const stopAbscondingProcess = async (id) => {

    let attachments2 = [];
    let payload = {}

    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      // continue;
      await uploadfile(filesToUpload[afilee])
        .then((response) => {

          attachments2.push(response?.data);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));

          toast.error(`${error} `, {
            autoClose: 2000,
          });
          return;
        });
    }

    payload.attachment_list = [...attachments2];
    payload.remarks = remarks

    AttendanceService.stopAbsconding(id, payload).then((response)=>{
      toast.success('Record Updated Successfully')
      setRemarksModal(false)
      setRemarks("")
      setFilesToUpload([])
      setAbsId('')
      set_imageLocalPreviewList([])
      setselectedFiles([])
      getAbsconding(
        searchStr,
        "",
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true
      );
    })
  }

  const stopRemarksModal = (id) => {
    setAbsId(id)
    setRemarksModal(true)
  }

  function getFileSize_KB(size_bytes = 0) {
    return Math.ceil((size_bytes * 10) / 1024) / 10;
  }

  const onProfileChange = (e, flag) => {
    const MAX_FILE_SIZE = 4 * 1024; // 4MB
    const SUPPORTED_FILE_TYPES = ["application/pdf"];

    let allfiles = e.target.files;

    let urls = [...imageLocalPreviewList];
    let tmp_filesToUpload = [...filesToUpload];

    for (let i = 0; i < allfiles.length; i++) {
      const afile = e.target.files[i];
      console.log(`file`, afile.type);

      if (getFileSize_KB(afile.size) > MAX_FILE_SIZE) {
        toast.error("File size can't exceed 4MB", {
          autoClose: 2000,
        });
        continue;
      }
      let myUrl = URL.createObjectURL(afile);
      urls.push(myUrl);

      const fileData = new FormData();
      fileData.append("uploaded_file", afile);
      tmp_filesToUpload.push(fileData);
    }

    set_imageLocalPreviewList(urls);
    setFilesToUpload(tmp_filesToUpload);

  };

  const removeImageByIdx = (idx) => {

    let newIList = [...imageLocalPreviewList];
    newIList.splice(idx, 1);

    let newFList = [...filesToUpload];
    newFList.splice(idx, 1);

    set_imageLocalPreviewList(newIList);
    setFilesToUpload(newFList);
  };

  return (
    <>
      <div>
        <div class="content-wrapper-inner">
          <div class="innerheadsec">
            <div className="row">
              <div class="col-md-12">
                <h4 class="inner-page-title">
                  Absconding Report
                </h4>
              </div>
              <div class="col-md-12">
                <div class="border-top mt-2 mt-md-4 mb-3"></div>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-4">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip
                      className="bs-tooltip-end tooltip"
                      id="overlay-example"
                    >
                      <div class="tooltip-inner text-left">
                        Search on{" "}
                        <strong>
                          {" "}
                          Employee Code,
                          <br />
                          Employee Name
                        </strong>
                      </div>
                    </Tooltip>
                  }
                >
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      id="searchbar"
                      class="form-control"
                      placeholder="Search on Employee Code, Employee Name..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                    <span class="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          class="far fa-times-circle cursor-pointer"
                          onClick={(e) => {
                            closeButtonCallBack(e);
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
              <div class="col-lg-8">
                <div class="inline-spacing btn-betweenspaing">
                  {/* {finalUrlValue?.D ? ( */}
                  <button
                    class="btn btn-primary-inner bpi-main"
                    type="button"
                    onClick={actionOnAbsconding}
                  >
                    Submit
                  </button>
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" class="mr-2" /> Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          disabled={abscondingData?.length !== 0 ? false : true}
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              filterdata.from_date,
                              filterdata.to_date,
                              filterdata.user_id,
                              "",
                              1,
                              pageSizeNo,
                              sortData.sort_by,
                              true,
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* ) : (
                    ""
                  )} */}
                  <button
                    onClick={() => {
                      filterShow();
                    }}
                    class="btn btn-secondary-inner"
                    style={{
                      backgroundColor: filterApplied && "yellow",
                      color: filterApplied && userData?.dark_mode === false && "#000"
                    }}
                  >
                    <img src="images/filter.png" alt="" class="mr-3" />
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs defaultActiveKey="P" id="approvaltabs" onSelect={(e) => setCurrentTabType(e)}>
                <Tab
                  eventKey="P"
                  title={
                    <React.Fragment>
                      Pending
                    </React.Fragment>
                  }
                >
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                    <th>Employee Name</th>
                    <th>Employee Code</th>
                    <th>Company</th>
                    <th>Business Unit</th>
                    <th>Location</th>
                    <th>HR Manager</th>
                    <th>Reporting Manager</th>
                    <th>Absenteeism Start Date</th>
                    <th>Absenteeism Dates</th>
                    <th>Absconding Process Intimation</th>
                    <th>Absconding Process Completed Dates</th>
                </tr>
              </thead>
              <tbody>
                {abscondingData?.length ? (
                  abscondingData?.map((data, parent_index) => (
                    <>
                      <tr 
                    //   style={{
                    //     backgroundColor: data?.is_abs_user === 'Y' ? 'rgb(242 121 121)': data?.is_abs_user === 'NA' ? 'rgb(92 229 92)': ""
                    // }}
                      >
                        <td>{data?.employee_name}</td>
                        <td>{data?.employee_code}</td>
                        <td>{data?.organisation}</td>
                        <td>{data?.bussiness_unit}</td>
                        <td>{data?.location}</td>
                        <td>{data?.hr_manager}</td>
                        <td>{data?.reporting_manager}</td>
                        <td>{moment(data?.absent_users_start_date).format('DD-MM-YYYY')}</td>
                        <td>
                            {data?.attendance_date?.map((date, index)=>{return(
                                <React.Fragment key={index}>
                                {`${moment(date).format('DD-MM-YYYY')} ${index === data.attendance_date.length - 1 ? "" : ','} `}
                                {(index + 1) % 2 === 0 && <br />}
                              </React.Fragment>
                            )
                          })}
                        </td>
                        {data?.num_of_absent_days >= 10 ?
                        <td>
                        <select class="form-control newbgselect"
                         onChange={(e)=>{handleActionChange(e.target.value, parent_index, data)}}
                         value={data?.is_absconding}
                         disabled={data?.is_abs_user !== null && data?.is_absconding !== 'N'}
                         >
                          <option value={0}>Select</option>
                          <option value={'Y'}>Yes</option>
                          <option value={'N'}>No</option>
                          <option value={'NA'}>Not Applicable</option>
                        </select>
                        </td>
                        :
                        <td>
                          Actionable after 10 days of <br /> Absent
                          Absent Days - {data?.num_of_absent_days}
                        </td>
                        }
                        <td>
                          {data?.is_first_letter_sended && "1st Letter Sent At: " + moment(data?.first_letter_sent_at).format("DD-MM-YYYY")}<br />
                          {data?.is_second_letter_sended && "2nd Letter Sent At: " + moment(data?.second_letter_sent_at).format("DD-MM-YYYY")}<br />
                          {data?.is_third_letter_sended && "3rd Letter Sent At: " + moment(data?.third_letter_sent_at).format("DD-MM-YYYY")}
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}> No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          </Tab>
              <Tab
                eventKey="Y"
                title={
                  <React.Fragment>
                    Absconded
                  </React.Fragment>
                }
              >
                <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                    <th>Employee Name</th>
                    <th>Employee Code</th>
                    <th>Company</th>
                    <th>Business Unit</th>
                    <th>Location</th>
                    <th>HR Manager</th>
                    <th>Reporting Manager</th>
                    <th>Absenteeism Start Date</th>
                    <th>Absenteeism Dates</th>
                    <th>Absconding Process Intimation</th>
                    <th>Absconding Process Completed Dates</th>
                    <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {abscondedData?.length ? (
                  abscondedData?.map((data, parent_index) => (
                    <>
                      <tr 
                    //   style={{
                    //     backgroundColor: data?.is_abs_user === 'Y' ? 'rgb(242 121 121)': data?.is_abs_user === 'NA' ? 'rgb(92 229 92)': ""
                    // }}
                      >
                        <td>{data?.employee_name}</td>
                        <td>{data?.employee_code}</td>
                        <td>{data?.organisation}</td>
                        <td>{data?.bussiness_unit}</td>
                        <td>{data?.location}</td>
                        <td>{data?.hr_manager}</td>
                        <td>{data?.reporting_manager}</td>
                        <td>{moment(data?.absent_users_start_date).format('DD-MM-YYYY')}</td>
                        <td>
                            {data?.attendance_date?.map((date, index)=>{return(
                                <React.Fragment key={index}>
                                {`${moment(date).format('DD-MM-YYYY')} ${index === data.attendance_date.length - 1 ? "" : ','} `}
                                {(index + 1) % 2 === 0 && <br />}
                              </React.Fragment>
                            )
                          })}
                        </td>
                        <td>
                        <select class="form-control newbgselect"
                         onChange={(e)=>{handleActionChange(e.target.value, parent_index, data)}}
                         value={data?.is_absconding}
                         disabled={data?.is_abs_user !== null && data?.is_absconding !== 'N'}
                         >
                          <option value={0}>Select</option>
                          <option value={'Y'}>Yes</option>
                          <option value={'N'}>No</option>
                          <option value={'NA'}>Not Applicable</option>
                        </select>
                        </td>
                        <td>
                          {data?.is_first_letter_sended && "1st Letter Sent At: " + moment(data?.first_letter_sent_at).format("DD-MM-YYYY")}<br />
                          {data?.is_second_letter_sended && "2nd Letter Sent At: " + moment(data?.second_letter_sent_at).format("DD-MM-YYYY")}<br />
                          {data?.is_third_letter_sended && "3rd Letter Sent At: " + moment(data?.third_letter_sent_at).format("DD-MM-YYYY")}
                        </td>
                        {data?.is_absconding === 'Y' ?
                        <td>
                          <i 
                          style={{color:"red"}} 
                          class="far fa-times-circle cursor-pointer"
                          onClick={()=>{stopRemarksModal(data?.abs_id)}}
                          // onClick={()=>{stopAbscondingProcess(data?.abs_id)}}
                          ></i>
                        </td>
                        :
                        <td></td>
                        }
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}> No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
              </Tab>
              <Tab
                eventKey="N"
                title={
                  <React.Fragment>
                    Resumed Duty
                  </React.Fragment>
                }
              >
                <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                    <th>Employee Name</th>
                    <th>Employee Code</th>
                    <th>Company</th>
                    <th>Business Unit</th>
                    <th>Location</th>
                    <th>HR Manager</th>
                    <th>Reporting Manager</th>
                    <th>Absenteeism Start Date</th>
                    <th>Absenteeism Dates</th>
                    <th>Absconding Process Intimation</th>
                    <th>Absconding Process Completed Dates</th>
                    <th>Remarks</th>
                    <th>Attachments</th>
                </tr>
              </thead>
              <tbody>
                {notAbscondingData?.length ? (
                  notAbscondingData?.map((data, parent_index) => (
                    <>
                      <tr 
                    //   style={{
                    //     backgroundColor: data?.is_abs_user === 'Y' ? 'rgb(242 121 121)': data?.is_abs_user === 'NA' ? 'rgb(92 229 92)': ""
                    // }}
                      >
                        <td>{data?.employee_name}</td>
                        <td>{data?.employee_code}</td>
                        <td>{data?.organisation}</td>
                        <td>{data?.bussiness_unit}</td>
                        <td>{data?.location}</td>
                        <td>{data?.hr_manager}</td>
                        <td>{data?.reporting_manager}</td>
                        <td>{moment(data?.absent_users_start_date).format('DD-MM-YYYY')}</td>
                        <td>
                            {data?.attendance_date?.map((date, index)=>{return(
                                <React.Fragment key={index}>
                                {`${moment(date).format('DD-MM-YYYY')} ${index === data.attendance_date.length - 1 ? "" : ','} `}
                                {(index + 1) % 2 === 0 && <br />}
                              </React.Fragment>
                            )
                          })}
                        </td>
                        <td>
                        <select class="form-control newbgselect"
                         onChange={(e)=>{handleActionChange(e.target.value, parent_index, data)}}
                         value={data?.is_absconding}
                         disabled={data?.is_abs_user !== null && data?.is_absconding !== 'N'}
                         >
                          <option value={0}>Select</option>
                          <option value={'Y'}>Yes</option>
                          <option value={'N'}>No</option>
                          <option value={'NA'}>Not Applicable</option>
                        </select>
                        </td>
                        <td>
                          {data?.is_first_letter_sended && "1st Letter Sent At: " + moment(data?.first_letter_sent_at).format("DD-MM-YYYY")}<br />
                          {data?.is_second_letter_sended && "2nd Letter Sent At: " + moment(data?.second_letter_sent_at).format("DD-MM-YYYY")}<br />
                          {data?.is_third_letter_sended && "3rd Letter Sent At: " + moment(data?.third_letter_sent_at).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          {data?.stop_remarks?.length > 12 ?
                          <Tooltip position="bottom" title={data?.stop_remarks}>
                          {data?.stop_remarks?.substr(0,12)}...
                          </Tooltip>
                          :
                          data?.stop_remarks
                          }
                        </td>
                        <td>
                          {data?.attachments?.length > 0 && data?.attachments?.map((url)=>(
                            <>
                            <a
                              href={url.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i class="fas fa-paperclip"></i>{" "}
                              View Doc
                            </a>
                            <br />
                            </>
                          ))}
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}> No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
              </Tab>
          </Tabs>
          </div>
          {abscondingData?.length == 0 ? (
            ""
          ) : (
            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control main-pointer"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        backdrop="static"
        keyboard={false}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Company<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={orgOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Company"
              value={orgValue}
              onChange={(e) => {
                setOrgValue(e);
                setfilterData({
                  ...filterdata,
                  organisation_id: e
                    .map((obj) => {
                      return obj.value;
                    })
                    .toString(),
                });
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Business Unit<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={branchData}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Business Unit"
              value={buValue}
              onChange={(e) => {
                setBUValue(e);
                setfilterData({
                  ...filterdata,
                  bussiness_unit_id: e
                    .map((obj) => {
                      return obj.value;
                    })
                    .toString(),
                });
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Location<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={personalSubAreaList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Location"
              value={locationValue}
              onChange={(e) => {
                setLocationValue(e);
                setfilterData({
                  ...filterdata,
                  location_id: e
                    .map((obj) => {
                      return obj.value;
                    })
                    .toString(),
                });
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              HR<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={hROptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select HR"
              value={hrValue}
              onChange={(e) => {
                setHRValue(e);
                setfilterData({
                  ...filterdata,
                  hr_id: e
                    .map((obj) => {
                      return obj.value;
                    })
                    .toString(),
                });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={remarksModal}
        onHide={() => setRemarksModal(false)}
        size="lg"
        centered
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>Remarks</Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group innergroup">
                    <label>Remarks</label>
                    <span class="text-danger">*</span>
                    <textarea
                      type="text"
                      name="remarks"
                      class="form-control"
                      placeholder="Enter Remarks here..."
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>

                <div className="col-md-8">
                      <div class="form-group innergroup position-relative modaldefaultclose ">
                        <label>
                          Attachment <span class="text-danger"></span>
                        </label>
                        <input
                          type="text"
                          class="form-control bg-white"
                          disabled
                          placeholder="(Image, PDF Format)"
                        />
                        <div class="upload-btn-wrapper up-loposition">
                          <button class="uploadBtn">Browse</button>
                          <input
                            type="file"
                            title=""
                            name="attachment_path"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) => {
                              onProfileChange(e);
                              // setAttachment(e.target.value);
                            }}
                          />
                          {selectedFiles.map((dfile) => (
                          <small class="mr-2">{dfile.name}</small>
                        ))}
                        </div>
                        <span>
                          (Allowed only pdf,png,jpeg,jpg with size: upto 4MB)
                        </span>
                        {imageLocalPreviewList.length ? (
                        <div className="row">
                          {imageLocalPreviewList.map((idata, idx) => (
                            <>
                              {filesToUpload[idx]
                                .get("uploaded_file")
                                .name.includes(".pdf") ? (
                                <div class="col-md-4 text-center">
                                  <img
                                    src={"images/policy-icon/pdf.png"}
                                    width="70px"
                                    height="70px"
                                  />
                                  <i
                                    role="button"
                                    onClick={() => removeImageByIdx(idx)}
                                    class=" fas fa-trash-alt text-danger"
                                  />
                                </div>
                              ) : filesToUpload[idx]
                                  .get("uploaded_file")
                                  .name.includes(".xls") ? (
                                <div class="col-md-4 text-center">
                                  <img
                                    src={"images/policy-icon/xlsx.png"}
                                    width="70px"
                                    height="70px"
                                  />
                                  <i
                                    role="button"
                                    onClick={() => removeImageByIdx(idx)}
                                    class=" fas fa-trash-alt text-danger"
                                  />
                                </div>
                              ) : (
                                <div class="col-md-4 text-center">
                                  <img src={idata} class="h-100" />
                                  <i
                                    role="button"
                                    onClick={() => removeImageByIdx(idx)}
                                    class=" fas fa-trash-alt text-danger"
                                  />
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      </div>
                    </div>

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              // setRemarksForRejection("");
              setRemarksModal(false);
            }}
          >
            Cancel
          </button>
          <button
            // disabled={remarksForRejection === ""}
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={()=>{stopAbscondingProcess(absId)}}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default AbscondingReport;
