import axios from "../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";




  export const getTransporter = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_transporter?${queryParm}`);
    
  };


  export const saveTransporter = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/lr_gr_transporter`, data);
  };
  
  export const updateTransporter = async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/lr_gr_transporter/${id}`, data);
  };
  
  export const deleteTransporter = async (id) => {
    return axios.delete(`${AUTH_BASE_URL}/lr_gr_transporter/${id}`);
  };

  export const getBranch = async (paginate) => {
    return axios.get(
      `${AUTH_BASE_URL}/business_units?paginate=${paginate}&sort_by=name`
    );
  };
  
  export const isTransporterExists = async (isData) => {
    return axios.post(`${AUTH_BASE_URL}/lr_gr_transporter/exists`, isData);
  };
  
  export const getAllTransporter = async (paginate) => {
    return axios.get(
      `${AUTH_BASE_URL}/lr_gr_vendor?paginate=${paginate}&sort_by=name`
    );
  };
  
  export const getExportValue = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/lr_gr_transporter/export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };
  