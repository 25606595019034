import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  ProgressBar,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import Logo from "../../assets/images/logo.png";

const options = [
  { value: "Option1", label: "Option1" },
  { value: "Option2", label: "Option2" },
  { value: "Option3", label: "Option3" },
  { value: "Option4", label: "Option4" },
  { value: "Option5", label: "Option5" },
];

function UploadSigma() {
  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/create-pr">Create PR</Link>
            </li>
            <li>
              <Link to="/pr-list">My PR List</Link>
            </li>
            <li>
              <Link to="/pr-approval">For Approval</Link>{" "}
              <span class="numberpurchse float-right mr-2">05</span>
            </li>
            <li>
              <Link to="/pr-reports">PR Reports</Link>
            </li>
            <li>
              <Link to="/sync-pr">Sync PR Sap</Link>
            </li>
            <li class="active">
              <Link to="/upload-cigma">Upload Bulk Cigma Capex</Link>
            </li>
            <li>
              <Link to="/pr-unit-master">Masters</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Upload Bulk Cigma Capex</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      Upload Bulk Cigma Capex
                    </Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-11">
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i class="fas fa-file-upload fa-2x"></i>
                    <p>Upload the template file with updated data</p>
                    <button class="btn btn-primary-inner">
                      DRAG AND DROP HERE OR CHOOSE FILE
                    </button>
                    <div className="mt-3 downloadtemplate">
                      <button class="editbtn">
                        <i class="far fa-file-excel"></i> DOWNLOAD TEMPLATE
                      </button>
                    </div>
                    <input
                      type="file"
                      accept=".xlsx, .xls, .csv"
                      className="form-control"
                    />
                  </div>

                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <span class="badge bad-status mb-3 badge-warning">
                      employee_user.csv <i class="far fa-times-circle"></i>
                    </span>
                    <button class="btn btn-primary-inner">
                      Proceed to Upload
                    </button>
                  </div>

                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <span class="badge bad-status mb-3 badge-warning">
                      employee_user.csv <i class="far fa-times-circle"></i>
                    </span>
                    <button class="btn btn-primary-inner" disabled>
                      Proceed to Upload
                    </button>
                    <div class="w-75 mt-3">
                      <ProgressBar
                        striped
                        variant="success"
                        animated
                        now={45}
                        label="40%"
                      />
                    </div>
                  </div>

                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
                    <p>
                      The system was unable to read few records from the
                      uploaded file.
                      <br />
                      Please correct or remove those records from the file and
                      upload again.
                    </p>
                    <span class="badge bad-status mb-3 badge-success">
                      Records Succeded: 230
                    </span>
                    <span class="badge bad-status mb-3 badge-danger">
                      Records Failed: 50
                    </span>
                    <div className="mt-3 downloadtemplate">
                      <button class="editbtn">
                        <i class="fas fa-chevron-circle-left"></i> Upload again
                      </button>
                    </div>
                  </div>

                  <table class="table uploadrecordstab">
                    <thead>
                      <tr>
                        <th>Upload Date</th>
                        <th>Uploaded By</th>
                        <th>File Name</th>
                        <th>Records Uploaded</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-theme">01-12-2022</td>
                        <td>Aniket Singh</td>
                        <td>employee_user.csv</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td class="text-theme">01-12-2022</td>
                        <td>Aniket Singh</td>
                        <td>employee_user.csv</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td class="text-theme">01-12-2022</td>
                        <td>Aniket Singh</td>
                        <td>employee_user.csv</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td class="text-theme">01-12-2022</td>
                        <td>Aniket Singh</td>
                        <td>employee_user.csv</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td class="text-theme">01-12-2022</td>
                        <td>Aniket Singh</td>
                        <td>employee_user.csv</td>
                        <td>500</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-md-12">
                  <div class="border-top my-3"></div>
                </div>

                <div class="col-md-12 mt-3 text-center">
                  <button class="btn btn-outline-primary mr-2">Save</button>
                  <button class="btn btn-primary-inner bpi-main">Submit</button>
                </div>

                <div class="col-md-12">
                  <div class="travel-note">
                    <h4>Notes :-</h4>
                    <ul>
                      <li>
                        Format - Please download import format by clicking{" "}
                        <a href="#">here</a>.
                      </li>
                      <li>
                        First row in csv or excel must be header or blank, and
                        columns sequence are capex_id, sap_code, business_area,
                        project_title, fiscal_year, cost,site, department,
                        purgrp.
                      </li>
                      <li>
                        Please make sure capex id is unique, if capex id match
                        from db then it will be skipped.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadSigma;
