import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
// import * as vendorInvoiceService from "../../service/vendorInvoiceService";
import * as GLService from "../../service/glService";
import * as HeaderService from "../../service/jv_service";
import * as userGl from "../../service/userGl";
import { useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
const BulkGLUserImport = (props) => {
  const dispatch = useDispatch();
  const { importshow, importclose } = props;
  const [uploadData, setUploadData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [exDataDown, setExDataDown] = useState({});
  const [excelListImport, setExcelListImport] = useState([]);
  const [userGlMap, setUserGlMap] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [showImport, setShowImport] = useState({
    downloadFlag: false,
    importFlag: false,
  });
  const handleKey = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    gl_type,
    organisation_id,
    user_id,
    gl_id,
    is_active
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (gl_type !== "" && gl_type !== undefined && gl_type !== null) {
      queryParm = queryParm + "&gl_type=" + gl_type;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (is_active !== "" && is_active !== undefined && is_active !== null) {
      queryParm = queryParm + "&is_active=" + is_active;
    }
    if (gl_id !== "" && gl_id !== undefined && gl_id !== null) {
      queryParm = queryParm + "&gl_id=" + gl_id;
    }
    return queryParm;
  };
  // const getUserGll = (
  //   search,
  //   pageNo,
  //   page_size,
  //   sort_by,
  //   paginate,
  //   gl_type,
  //   organisation_id,
  //   user_id,
  //   gl_id,
  //   is_active
  // ) => {
  //   dispatch(setDisplayLoader("Display"));

  //   userGl
  //     .getUserGl(
  //       handleKey(
  //         search,
  //         pageNo,
  //         page_size,
  //         sort_by,
  //         paginate,
  //         gl_type,
  //         organisation_id,
  //         user_id,
  //         gl_id,
  //         is_active
  //       )
  //     )
  //     .then((response) => {
  //       dispatch(setDisplayLoader("Hide"));

  //       setUserGlMap(response?.data?.dataList?.result);
  //       setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };
  const onProfileChange = (e, flag) => {
    console.log(e);
    setShowImport({ ...showImport, downloadFlag: false });
    const files = e.target.files[0];
    if (
      files.type === "text/csv" ||
      files.type === "image/xls" ||
      files.type === "image/XLSX" ||
      files.type === "image/xlsx" ||
      files.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const fileData = new FormData();
      fileData.append("uploaded_file", files);
      HeaderService.uploadImages(fileData).then((response) => {
        if (flag === "import_glUser_data") {
          setUploadData(response.data);
          setShowImport({ ...showImport, downloadFlag: true });
        }
      });
    } else {
      toast.error("Only csv, xls and xlsx files are supported");
      setUploadData({});
    }
  };

  const importExel = (values) => {
    setShowImport({ ...showImport, importFlag: false });
    setIsLoading(true);
   
    GLService
      .importExel(values)
      .then((response) => {
        console.log(response);
        setExDataDown(response.data);
        if (response.status === 200) {
          setShowImport({ ...showImport, importFlag: true });
          setIsLoading(false);
          getDirectoryDetails();
         
        }
      })
      .catch((error) => {
        toast.error("Invalid Format");
        setUploadData({});
        setIsLoading(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getDirectoryDetails = () => {
    GLService
      .getDirectoryDetails()
      .then((response) => {
        console.log(response);
        setExcelListImport(response?.data?.dataList);
        if (response?.data?.dataList[0]?.records_uploaded > 0) {
          toast.success(
            `${response?.data?.dataList[0]?.records_uploaded} Record sucessfull`
          );
        } else {
          toast.warn("No Record Found");
        }
        if (response?.data?.dataList[0]?.records_failed > 0) {
          toast.error(
            `${response?.data?.dataList[0]?.records_failed} Record unsucessfull`
          );
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ImportErrors.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    setShowImport({
      ...showImport,
      importFlag: false,
      downloadFlag: false,
    });   
   
  }, [importclose]);

  return (
    <Modal
      show={importshow}
      onHide={importclose}
      size="lg"
      backdrop="static"
      keyboard={false}
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Bulk Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="row">
              <div className="drapdroparea upload-btn-wrapper text-center">
                <i class="fas fa-file-upload fa-2x"></i>
                <p>Upload the template file with updated data</p>
                <button class="btn btn-primary-inner">
                  DRAG AND DROP HERE OR CHOOSE FILE
                </button>
                <div className="mt-3 downloadtemplate">
                  <button class="editbtn">
                    <i class="far fa-file-excel"></i>
                    <a href="\format_for_bulk_upload_GLUser.xlsx" download>
                      DOWNLOAD TEMPLATE
                    </a>
                  </button>
                </div>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  className="form-control"
                  onChange={(e) => onProfileChange(e, "import_glUser_data")}
                />
              </div>
              {showImport.downloadFlag === true ? (
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <span class="badge bad-status mb-3 badge-warning">
                    {uploadData?.original_name}{" "}
                    <i
                      class="far fa-times-circle"
                      onClick={() => {
                        setUploadData({});
                        setShowImport({
                          ...showImport,
                          importFlag: false,
                          downloadFlag: false,
                        });
                      }}
                    ></i>
                  </span>

                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    showImport.importFlag === false && (
                      <button
                        className="btn btn-primary-inner"
                        type="button"
                        onClick={() => importExel(uploadData)}
                      >
                        Proceed to Upload
                      </button>
                    )
                  )}
                </div>
              ) : (
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <h2>Notes :</h2>
                  <h5 style={{ "font-size": "15px" }}>
                    * This Feature is only for Petty Expense         
                    
                  </h5>
                 
                </div>
              )}
              {showImport.importFlag === true && (
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
                  <p>
                    The system was unable to read few records from the uploaded
                    file.
                    <br />
                    Please correct or remove those records from the file and
                    upload again.
                  </p>
                  <span className="badge bad-status mb-3 badge-success">
                    Records Succeded:
                    {excelListImport[0]?.records_uploaded}
                  </span>
                  <span className="badge bad-status mb-3 badge-danger">
                    Records Failed:
                    {excelListImport[0]?.records_failed}
                  </span>
                  <div className="mt-3 downloadtemplate">
                    <button
                      class="editbtn"
                      type="button"
                      onClick={() => {
                        downloadError();
                      }}
                    >
                      <i class="fas fa-chevron-circle-left"></i> Download
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BulkGLUserImport;
