import React, { useState, useEffect } from "react";
import { Modal, Dropdown, Pagination } from "react-bootstrap";
import SearchLoader from "../../common/SearchLoader";
import Select from 'react-select'
import { getOrganisation, uploadImages } from "../../service/leaveService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { getEssPolicy, removeEssPolicy, submitEssPolicy, updateEssPolicy } from "../../service/mediclaim_brokerservice";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";


function EssPolicyMaster() {
  const dispatch = useDispatch()
  const [loaderActive, setLoaderActive] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [sortBy, setSortBy] = useState({
    colName: "id", sort_by: "-id"
  })
  const [showForm, setShowForm] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filename, setfilename] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [filterData, setFilterData] = useState({
    status: true,
  })
  const [formObj, setFormObj] = useState({
    name: "",
    organisation: "",
    is_active: true,
    attachment: {}
  });
  const [organisationList, setOrganisationList] = useState([]);
  const [policies, setPolicies] = useState([])
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    getOrganisationList();
    getPolicies(searchStr, 1, 10, sortBy?.sort_by, true, true);
  }, []);

  const getPolicies = (search, page_no, page_size, sort_by, paginate, status) => {
    getEssPolicy(handleKey(
      search, page_no, page_size, sort_by, paginate, status
    )).then((response) => {
      if (response?.status === 200) {
        setPolicies(response?.data?.dataList?.result);
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    if (editData) {
      setShowForm(true);
      setFormObj((prev) => ({
        ...prev,
        name: editData?.name,
        attachment: {},
        is_active: editData?.is_active,
        organisation: {
          value: editData?.organisation_id,
          label: `${editData?.organisation__name} (${editData?.organisation__code})`
        }
      }));
      setfilename(editData?.attachment);
    }
  }, [editData])

  const onProfileChange = (e, name) => {
    const files = e.target.files[0];
    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    dispatch(setDisplayLoader("Display"));
    uploadImages(fileData).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      if (name === "attachments") {
        setFormObj((prev) => ({
          ...prev,
          attachment: response.data,
        }));
        setfilename(response.data.original_name);
      }
    });
  };

  const handleKey = (search, page_no, page_size, sort_by, paginate, status) => {
    let queryParm =
      "search=" +
      search +
      "&page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate +
      "&status=" +
      status;

    return queryParm;
  }

  const getOrganisationList = () => {
    getOrganisation(false).then((response) => {
      let data = response?.data?.dataList?.result.map((x) => {
        return { value: x.id, label: `${x.name} (${x?.code})` };
      });
      setOrganisationList(data)
    });
  };

  const submitForm = () => {
    if (!editData) {
      let obj = formObj
      obj.organisation = formObj.organisation === "" ? "" : formObj?.organisation?.value;
      submitEssPolicy(formObj).then((response) => {
        if (response?.status === 200) {
          toast.success("Policy created successfully.");
          setShowForm(false);
          getPolicies(searchStr, currentPage, pageSizeNo, sortBy?.sort_by, true, filterData?.status)
        }
      }).catch((err) => {
        console.log(err);
      })
    }
    else {
      let obj = formObj;
      obj.organisation = formObj.organisation === "" ? "" : formObj?.organisation?.value;
      updateEssPolicy(editData?.id, obj).then((response) => {
        if (response?.status === 200) {
          toast.success("Policy updated successfully.");
          setShowForm(false);
          getPolicies(searchStr, currentPage, pageSizeNo, sortBy?.sort_by, true, filterData?.status)
        }
      }).catch((err) => {
        console.log(err);
      })
    }
  }

  const deleteRecord = (id) => {
    removeEssPolicy(id).then((response) => {
      if (response?.status === 200) {
        toast.success("Policy Deleted successfully.")
        getPolicies(searchStr, currentPage, pageSizeNo, sortBy?.sort_by, true, filterData?.status)
      }
    })
  }

  const removePolicy = (id) => {
    confirmAlert({
      title: "Remove Policy",
      message: "Are you sure you want to remove this policy ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteRecord(id)
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const mainDataFilter = () => {
    getPolicies(searchStr, currentPage, pageSizeNo, sortBy?.sort_by, true, filterData?.status);
    setShowFilter(false);
  }

  console.log(policies);

  return (
    <div className="content-wrapper-inner">
      <div className="innerheadsec">
        <div className="row">
          <div className="col-md-12">
            <h4 className="inner-page-title">ESS Policy Master</h4>
          </div>
          <div className="col-md-12">
            <div className="border-top mt-2 mt-md-4 mb-3"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="row">
              <div className="col-lg-10">
                <div className="input-group table-search">
                  <span className="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </div>
              <div className="col-lg-2">
                {loaderActive ? <SearchLoader /> : ""}
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="inline-spacing btn-betweenspaing">
              <button
                onClick={() => {
                  setShowFilter(true);
                }}
                className="btn btn-secondary-inner"
              >
                <img src="images/filter.png" alt="" className="mr-3" />
                Filter
              </button>
              <button
                className="btn btn-primary-inner"
                onClick={() => {
                  setShowForm(true);
                }}
              >
                <img src="images/upload.png" alt="" className="mr-3" />
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered tablecured">
          <thead>
            <tr>
              <th>Status</th>
              <th>Policy Name</th>
              <th>Organisation Name</th>
              <th>Attachment</th>
              <th>Created By</th>
              <th>Created At</th>
              <th>Updated By</th>
              <th>Updated At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {policies?.length > 0 && (
              policies?.map((each) => (
                <tr>
                  <td>{each?.is_active ? (
                    <span class="badge bad-status badge-success">Active</span>
                  ) : (
                    <span class="badge bad-status badge-danger">Inactive</span>
                  )}</td>
                  <td>{each.name}</td>
                  <td>{`${each?.organisation__name} (${each?.organisation__code})`}</td>
                  <td>{each?.attachment !== "" && (
                    <a
                      href={
                        each?.attachment
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="fas fa-paperclip"></i>{" "}
                    </a>
                  )}</td>
                  <td>{each?.created}</td>
                  <td>{each?.created_at}</td>
                  <td>{each?.updated}</td>
                  <td>{each?.updated_at}</td>
                  <td>
                    <button
                      className="btn btn-blanktd text-primary"
                      onClick={() => {
                        setEditData(each)
                      }}
                    >
                      <i className="far fa-edit"></i>
                    </button>
                    <button
                      className="btn btn-blanktd text-danger"
                      onClick={() => {
                        removePolicy(each?.id)
                      }}
                    >
                      <i className="far fa-trash-alt text-danger"></i>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Modal
        show={showForm}
        onHide={() => {
          setShowForm(false);
          setFormObj((prev) => ({
            ...prev,
            name: "",
            is_active: true,
            attachment: {},
            organisation: ""
          }));
          setfilename("");
          setEditData(null)
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add ESS Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="form-group innergroup position-relative">
                <label>
                  Policy Name
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="policy_name"
                  className="form-control bg-white"
                  placeholder="Enter Policy Name"
                  onChange={(e) => {
                    setFormObj((prev) => ({
                      ...prev,
                      name: e.target.value
                    }));
                  }}
                  value={formObj?.name}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group innergroup">
                <label>
                  Organisation
                  <span className="text-danger">*</span>
                </label>
                <Select
                  name="org"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={true}
                  options={organisationList}
                  onChange={(e) => {
                    setFormObj((prev) => ({
                      ...prev,
                      organisation: e
                    }))
                  }}
                  value={formObj?.organisation}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group innergroup position-relative">
                <label>Attachment</label>
                {filename != undefined ? (
                  <input
                    type="text"
                    name="attachments"
                    class="form-control bg-white"
                    placeholder="(Image, PDF Format)"
                    disabled
                    value={filename}
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control bg-white"
                    disabled
                    placeholder="(Image, PDF Format)"
                  />
                )}

                <div class="upload-btn-wrapper up-loposition">
                  <button class="uploadBtn">Browse</button>
                  <input
                    type="file"
                    title=""
                    name="attachments"
                    id="attachmentsFile"
                    accept="image/*, application/pdf"
                    onChange={(e) =>
                      onProfileChange(e, "attachments")
                    }
                  />
                </div>
                {filename != undefined && (
                  <p>
                    Selected file
                    <span> {filename}</span>
                  </p>
                )}

              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group innergroup">
                <label className="d-block mb-2">Status</label>
                <label
                  className="logCheck d-inline-block mr-4"
                  htmlFor="active"
                >
                  Active
                  <input
                    type="radio"
                    name="is_active"
                    id="active"
                    onChange={() => {
                      setFormObj((prev) => ({
                        ...prev,
                        is_active: true
                      }));
                    }}
                    value={formObj?.is_active}
                    checked={formObj?.is_active}
                  />
                  <span className="checkmark"></span>
                </label>
                <label
                  className="logCheck d-inline-block"
                  htmlFor="Inactive"
                >
                  Inactive
                  <input
                    type="radio"
                    name="is_active"
                    id="Inactive"
                    onChange={() => {
                      setFormObj((prev) => ({
                        ...prev,
                        is_active: false
                      }));
                    }}
                    value={formObj?.is_active}
                    checked={formObj?.is_active === false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                setShowForm(false)
                setFormObj((prev) => ({
                  ...prev,
                  name: "",
                  is_active: true,
                  attachment: {},
                  organisation: ""
                }));
                setfilename("");
                setEditData(null)

              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary-inner bpi-main"
              onClick={submitForm}
            >
              Submit
            </button>
          </>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showFilter}
        onHide={() => {
          setShowFilter(false);
          setFilterData((prev) => ({
            ...prev,
            status: true
          }))
        }}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group innergroup">
            <label>
              Select Status <span className="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect"
              name="organisation_id"
              onChange={(e) => {
                setFilterData((prev) => ({
                  ...prev,
                  status: e.target.value === "1" ? true : false,
                }));
              }}
              value={filterData?.status ? "1" : "0"}
            >
              <option value="" label="Select Status" />
              <option value='1' label='Active' />
              <option value='0' label='Inactive' />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={() => {
              setFilterData((prev) => ({
                ...prev,
                status: true
              }));
              getPolicies(searchStr, 1, 10, sortBy?.sort_by, true, true);
              setShowFilter(false)
            }}
          >
            <i className="fa fa-undo"></i>Clear
          </button>
          <button
            className="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default EssPolicyMaster;