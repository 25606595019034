import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as TransDocService from "../../service/DocSurvice";
import { Formik } from "formik";
import * as Yup from "yup";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object({
  document_type: Yup.string().required("*Document Type is required"),
  document_desc: Yup.string().required("*Document Desc is required"),
  approver: Yup.string().required("*Select Approver").nullable(),
});

const AddDocType = (props) => {
  const nameForm = "Document Master";
  const { edit, showForm, onClose, editDocument, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState(null);
  const dispatch = useDispatch();
  const [allUserType, setAllUserType] = useState([]);

  const [docTypeData, setDocTypeData] = useState({
    document_type: "",
    document_desc: "",
    approver: "",
  });

  const saveTransDoc = (values) => {
    setIsLoading(true);
    TransDocService.saveTransDoc(values)
      .then((response) => {
        if (response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateTransDoc = (values, id) => {
    setIsLoading(true);
    TransDocService.updateTransDoc(values, id)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getallApproveruser = (paginate) => {
    TransDocService.getallApproveruser(paginate)
      .then((response) => {
        setAllUserType(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`doc${index}`}
        value={`${item?.id}`}
        label={`${item?.first_name}`}
      />
    ));
  };

  useEffect(() => {
    getallApproveruser(false);
  }, []);

  useEffect(() => {
    if (editDocument) {
      setDocTypeData(editDocument);
      Object.keys(editDocument).forEach((item) => {
        docTypeData[item] = editDocument[item];
      });
    }
  }, [editDocument]);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Formik
        initialValues={docTypeData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (values.id) {
            updateTransDoc(values, values.id);
            // AuditLog.addAuditLog(dispatch, nameForm);
          } else {
            saveTransDoc(values);
            // AuditLog.addAuditLog(dispatch, nameForm);
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit" : "Add"} TransDoc
              </Modal.Title>
            </Modal.Header>

            <form onSubmit={handleSubmit}>
              <>
                <Modal.Body>
                  <div class="row justify-content-center">
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Document Type
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control nonumbdrop"
                              placeholder="Enter Document Type"
                              name="document_type"
                              // maxLength={5}
                              // min="1"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.document_type}
                            />
                            {touched.document_type && errors.document_type ? (
                              <div className="small text-danger">
                                {errors.dial_code}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Document Desc<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Document Desc"
                              maxLength={30}
                              name="document_desc"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.document_desc}
                            />
                            {touched.document_desc && errors.document_desc ? (
                              <div className="small text-danger">
                                {errors.document_desc}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Select Approver
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control newbgselect"
                              classnameprefix="select"
                              style={{ cursor: "pointer" }}
                              name="approver"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setDocTypeData({
                                  ...docTypeData,
                                  approver: e.target.value,
                                });
                              }}
                              value={values.approver}
                            >
                              <option
                                value=""
                                label="Select Approver"
                                disabled
                              />

                              {renderOrgOptions(allUserType)}
                            </select>
                            {touched.approver && errors.approver ? (
                              <div className="small text-danger">
                                {errors.approver}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        // onClick={() => submitProfile()}
                        className="btn btn-primary-inner bpi-main"
                      >
                        Submit
                      </button>
                    </>
                  )}
                </Modal.Footer>
              </>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddDocType;
