import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import * as vehicleService from "../../service/vehiclelog";

const AddNewVehicle = (props) => {
  const { show, onCloseButtonClick, edit, editVehicleData, onAddCallBack } =
    props;

  const [vehicleData, setVehicleData] = useState({
    vehicle_no: "",
    name: "",
    is_active: true,
  });

  const [formValidation, setFormValidation] = useState({
    vehicle_no: "",
  });

  const saveVehicleMaster = (data) => {
    vehicleService
      .saveVehicleMaster(data)
      .then((response) => {
        onAddCallBack();
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateVehicleMaster = (id, data) => {
    vehicleService
      .updateVehicleMaster(id, data)
      .then((response) => {
        onAddCallBack();
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const validateRecords = () => {
    let isError = false;

    const valid = { ...formValidation };
    if (!vehicleData.vehicle_no) {
      isError = true;
      valid.vehicle_no = "*Vehicle Number is required ";
      setFormValidation(valid);
    }
    return isError;
  };

  const regex = new RegExp('^[a-zA-Z0-9]*$');
  const handleChange = (e, name) => {
    let type = name !== undefined ? name : e.target.name;
    switch (type) {
      case "vehicle_no":
        if(regex.test(e.target.value)) setVehicleData((prev) => ({...prev, vehicle_no: e.target.value,}));
        break;
      case "name":
        setVehicleData((prev) => ({
          ...prev,
          name: e.target.value,
        }));
        break;
      case "is_active":
        setVehicleData((prev) => ({
          ...prev,
          is_active: e,
        }));
        break;
      default:
    }
  };

  const handleSubmit = () => {
    if (validateRecords()) return false;
    if (vehicleData?.id == undefined) {
      saveVehicleMaster(vehicleData);
    } else {
      updateVehicleMaster(vehicleData?.id, vehicleData);
    }
  };

  useEffect(() => {
    if (editVehicleData) {
      setVehicleData(editVehicleData);
      Object.keys(editVehicleData).forEach((item) => {
        vehicleData[item] = editVehicleData[item];
      });
    }
  }, [editVehicleData]);

  return (
    <>
      <Modal
        show={show}
        onHide={onCloseButtonClick}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {edit === true ? "Edit" : "Add"} New Vehicle
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row justify-content-center">
            <ToastContainer autoClose={1000} />
            <div class="col-md-11">
              <div class="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Vehicle Registration Number
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Vehicle No"
                      name="vehicle_no"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      value={vehicleData?.vehicle_no}
                    />
                    <div className="small text-danger">
                      {formValidation.vehicle_no}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Vehicle Registration Location</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Vehicle Location"
                      name="name"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      value={vehicleData.name}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label className="d-block">Status</label>
                    <label
                      className="logCheck d-inline-block mr-4"
                      htmlFor="active"
                    >
                      Activate
                      <input
                        type="radio"
                        name="is_active"
                        id="active"
                        value={vehicleData.is_active}
                        onChange={(newValue, action) => {
                          handleChange(true, "is_active");
                        }}
                        checked={vehicleData.is_active === true}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      className="logCheck d-inline-block"
                      htmlFor="Inactive"
                    >
                      Terminate
                      <input
                        type="radio"
                        name="is_active"
                        id="Inactive"
                        value={vehicleData.is_active}
                        onChange={(newValue, action) => {
                          handleChange(false, "is_active");
                        }}
                        checked={vehicleData.is_active === false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <>
            <button
              class="btn btn-outline-secondary mr-2"
              onClick={() => onCloseButtonClick()}
            >
              Cancel
            </button>
            <button
              class="btn btn-primary-inner bpi-main"
              type="submit"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewVehicle;
