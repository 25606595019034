import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import Select from "react-select";
import * as ApproverServices from "../../service/approver";
import { toast } from "react-toastify";

const AddContractApprover = (props) => {
  const { closeForm, showForm, edit, editGrp, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [allGroups, setAllGroups] = useState([]);
  const [allGroupsData, setAllGroupsData] = useState([]);
  const [grpError, setGrpError] = useState(false);

  const [allStrat, setAllStrat] = useState([]);
  const [allStratData, setAllStratData] = useState([]);
  const [stratError, setStratError] = useState(false);

  const [allCode, setAllCode] = useState([]);
  const [allCodeData, setAllCodeData] = useState([]);
  const [codeError, setCodeError] = useState(false);

  const [allPurOrg, setAllPurOrg] = useState([]);
  const [allPurOrgData, setAllPurOrgData] = useState([]);
  const [purOrgError, setPurOrgError] = useState(false);

  const [allPurGroup, setAllPurGroup] = useState([]);
  const [allPurGroupData, setAllPurGroupData] = useState([]);
  const [purGroupError, setPurGroupError] = useState(false);

  const [allApproverId, setAllApproverId] = useState([]);
  const [allApproverIdData, setAllApproverIdData] = useState([]);
  const [approverError, setApproverError] = useState(false);

  const [isExist, setIsExist] = useState(false);
  const [groupID, setGroupID] = useState("");

  const [approverData, setApproverData] = useState({
    release_group_id: "",
    release_strtgy_id: "",
    release_code_id: "",
    approver_id: "",
    purchase_org_id: "",
    purchase_group_id: "",
    category: "CONTRACT",
  });

  let condition = "false";

  const saveApprover = () => {
    setIsLoading(true);
    let data = {};
    data["release_group_id"] = approverData?.release_group_id;
    data["release_strtgy_id"] = approverData?.release_strtgy_id;
    data["release_code_id"] = approverData?.release_code_id;
    data["purchase_org_id"] = approverData?.purchase_org_id;
    data["purchase_group_id"] = approverData?.purchase_group_id;
    data["approver_id"] = approverData?.approver_id;
    data["category"] = "CONTRACT";
    ApproverServices.saveApprover(data)
      .then((response) => {
        condition = "false";
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateApprover = async (values) => {
    setIsLoading(true);
    let data = {};
    data["release_group_id"] = approverData?.release_group_id;
    data["release_strtgy_id"] = approverData?.release_strtgy_id;
    data["release_code_id"] = approverData?.release_code_id;
    data["purchase_org_id"] = approverData?.purchase_org_id;
    data["purchase_group_id"] = approverData?.purchase_group_id;
    data["approver_id"] = approverData?.approver_id;
    data["category"] = "CONTRACT";
    await ApproverServices.updateApprover(data, approverData.id)
      .then((response) => {
        condition = "false";

        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllGroups = (paginate) => {
    ApproverServices.getAllGroups(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let transformData = {
            value: x.id,
            label: `${x?.code === null ? "" : x?.code} - ${
              x?.desc === null ? "" : x?.desc
            }`,
          };
          data.push(transformData);
        });
        setAllGroups(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllStrat = (paginate) => {
    ApproverServices.getAllStrat(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let transformData = {
            value: x.id,
            label: `${x?.code === null ? "" : x?.code} - ${
              x?.desc === null ? "" : x?.desc
            }`,
          };
          data.push(transformData);
        });
        setAllStrat(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllCode = (group_id, strategy_id) => {
    ApproverServices.getAllCode(group_id, strategy_id)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let transformData = {
            value: x.id,
            label: `${x?.code === null ? "" : x?.code} - ${
              x?.desc === null ? "" : x?.desc
            }`,
          };
          data.push(transformData);
        });
        setAllCode(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPurOrg = (sort_by, paginate) => {
    ApproverServices.getPurOrg(sort_by, paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let transformData = {
            value: x.id,
            label: `${x?.code === null ? "" : x?.code} - ${
              x?.desc === null ? "" : x?.desc
            }`,
          };
          data.push(transformData);
        });
        setAllPurOrg(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPurGroup = (sort_by, paginate) => {
    ApproverServices.getPurGroup(sort_by, paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let transformData = {
            value: x.id,
            label: `${x?.pgcode === null ? "" : x?.pgcode} - ${
              x?.pgdesc === null ? "" : x?.pgdesc
            }`,
          };
          data.push(transformData);
        });
        setAllPurGroup(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getApproverId = () => {
    ApproverServices.getApproverId()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let transformData = {
            value: x.id,
            label: `${x?.first_name === null ? "" : x?.first_name} ${
              x?.last_name === null ? "" : x?.last_name
            } (${x?.username === null ? "" : x?.username})`,
          };
          data.push(transformData);
        });
        setAllApproverId(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const AllCheck = () => {
    let error = false;
    if (edit) {
      if (editGrp?.release_group_id !== approverData?.release_group_id) {
        error = true;
      }
      if (editGrp?.release_strtgy_id !== approverData?.release_strtgy_id) {
        error = true;
      }
      if (editGrp?.release_code_id !== approverData?.release_code_id) {
        error = true;
      }
      if (editGrp?.approver_id !== approverData?.approver_id) {
        error = true;
      }
      if (editGrp?.purchase_org_id !== approverData?.purchase_org_id) {
        error = true;
      }
      if (editGrp?.purchase_group_id !== approverData?.purchase_group_id) {
        error = true;
      }
    }
    if (error === false && editGrp?.id !== undefined) {
      updateApprover();
    }
    if (error === true && editGrp?.id !== undefined) {
      isDataExists();
    }
    if (error === false && editGrp?.id === undefined) {
      isDataExists();
    }
  };
  const isDataExists = () => {
    let data = {};
    data["release_group_id"] = approverData?.release_group_id;
    data["release_strtgy_id"] = approverData?.release_strtgy_id;
    data["release_code_id"] = approverData?.release_code_id;
    data["purchase_org_id"] = approverData?.purchase_org_id;
    data["purchase_group_id"] = approverData?.purchase_group_id;
    data["approver_id"] = approverData?.approver_id;
    data["category"] = "CONTRACT";
    if (condition == "false") {
      condition = "true";
      ApproverServices.isDataExists(data)
        .then((res) => {
          setIsExist(res?.data?.dataList?.is_exist);
          if (res?.data?.dataList?.is_exist === false && editGrp?.id) {
            updateApprover();
          }
          if (
            res?.data?.dataList?.is_exist === false &&
            editGrp?.id === undefined
          ) {
            saveApprover();
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };
  // const isDataExists = async (values, id) => {
  //   let error = false;
  //   setIsExist(false);
  //   let data = {};
  //   data["release_group_id"] = values.release_group_id;
  //   data["release_strtgy_id"] = values.release_strtgy_id;
  //   data["release_code_id"] = values.release_code_id;
  //   data["purchase_org_id"] = values.purchase_org_id;
  //   data["purchase_group_id"] = values.purchase_group_id;
  //   data["category"] = "CONTRACT";

  //   if (editGrp !== null) {
  //     Object?.keys(editGrp)?.map((key) => {
  //       if (editGrp[key] !== values[key] && key !== "approver_id") {
  //         error = true;
  //       }
  //     });
  //   }
  //   // console.log(error);
  //   if (error === true) {
  //     await ApproverServices.isDataExists(data)
  //       .then((response) => {
  //         setIsExist(response?.data?.dataList?.is_exist);
  //         if (response?.data?.dataList?.is_exist !== true) {
  //           if (id !== undefined) {
  //             updateApprover(values);
  //           } else {
  //             saveApprover(values);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           (error.response &&
  //             error.response.data &&
  //             error.response.data.message) ||
  //             "failed"
  //         );
  //       });
  //   } else {
  //     if (id !== undefined) {
  //       updateApprover(values);
  //     } else {
  //       await ApproverServices.isDataExists(data)
  //         .then((response) => {
  //           setIsExist(response?.data?.dataList?.is_exist);
  //           if (response?.data?.dataList?.is_exist !== true) {
  //             saveApprover(values);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(
  //             (error.response &&
  //               error.response.data &&
  //               error.response.data.message) ||
  //               "failed"
  //           );
  //         });
  //     }
  //   }
  // };

  useEffect(() => {
    getAllGroups(false);
    getApproverId();
    getPurOrg("id", false);
    getPurGroup("id", false);
  }, []);

  useEffect(() => {
    if (editGrp) {
      getAllCode(editGrp?.release_group_id, editGrp?.release_strtgy_id);
      getAllStrat(false);
      let grpData = {
        value: editGrp?.release_group_id,
        label: editGrp?.release_group_code,
      };
      setAllGroupsData(grpData);
      let stratData = {
        value: editGrp?.release_strtgy_id,
        label: editGrp?.release_strtgy_code,
      };
      setAllStratData(stratData);
      let CodeData = {
        value: editGrp?.release_code_id,
        label: editGrp?.release_code_code,
      };
      setAllCodeData(CodeData);
      let purOrData = {
        value: editGrp?.purchase_org_id,
        label: editGrp?.purchase_org_code,
      };
      setAllPurOrgData(purOrData);
      let purgrData = {
        value: editGrp?.purchase_group_id,
        label: editGrp?.purchase_group_code,
      };
      setAllPurGroupData(purgrData);
      let userData = {
        value: editGrp?.approver_id,
        label: `${editGrp?.approver_name} (${editGrp?.approver_emp_id})`,
      };
      setAllApproverIdData(userData);

      setApproverData(editGrp);
      Object.keys(editGrp).forEach((item) => {
        approverData[item] = editGrp[item];
      });
    }
  }, [editGrp]);

  const DataPush = (event, flag) => {
    if (flag === "release_group_id") {
      setApproverData({
        ...approverData,
        release_group_id: event.value,
      });
      setAllGroupsData(event);
    }
    if (flag === "release_strtgy_id") {
      setApproverData({
        ...approverData,
        release_strtgy_id: event.value,
      });
      setAllStratData(event);
    }
    if (flag === "release_code_id") {
      setApproverData({
        ...approverData,
        release_code_id: event.value,
      });
      setAllCodeData(event);
    }
    if (flag === "purchase_org_id") {
      setApproverData({
        ...approverData,
        purchase_org_id: event.value,
      });
      setAllPurOrgData(event);
    }
    if (flag === "purchase_group_id") {
      setApproverData({
        ...approverData,
        purchase_group_id: event.value,
      });
      setAllPurGroupData(event);
    }
    if (flag === "approver_id") {
      setApproverData({
        ...approverData,
        approver_id: event.value,
      });
      setAllApproverIdData(event);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let error = false;
    if (allGroupsData.length === 0) {
      setGrpError(true);
      error = true;
    }
    if (allStratData.length === 0) {
      setStratError(true);
      error = true;
    }
    if (allCodeData.length === 0) {
      setCodeError(true);
      error = true;
    }
    if (allPurOrgData.length === 0) {
      setPurOrgError(true);
      error = true;
    }
    if (allPurGroupData.length === 0) {
      setPurGroupError(true);
      error = true;
    }
    if (allApproverIdData.length === 0) {
      setApproverError(true);
      error = true;
    }
    if (error === false) {
      AllCheck();
    }
  };

  return (
    <>
      <Modal
        show={showForm}
        onHide={closeForm}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{edit ? "Edit" : "Add"} Assign Approver</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div class="row justify-content-center">
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Release Group<span class="text-danger">*</span>
                      </label>

                      <Select
                        name="release_group_id"
                        id="release_group_id"
                        options={allGroups}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "release_group_id");
                          getAllStrat(false);
                          setGroupID(e.value);

                          setGrpError(false);
                        }}
                        value={allGroupsData}
                      />
                      {grpError && (
                        <div className="small text-danger">
                          *Release Group is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Release Strategy<span class="text-danger">*</span>
                      </label>

                      <Select
                        name="release_strtgy_id"
                        id="release_strtgy_id"
                        options={allStrat}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "release_strtgy_id");
                          setStratError(false);
                          getAllCode(groupID, e.value);
                        }}
                        value={allStratData}
                      />
                      {stratError && (
                        <div className="small text-danger">
                          *Release Strategy is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Release Code<span class="text-danger">*</span>
                      </label>

                      <Select
                        name="release_code_id"
                        id="release_code_id"
                        options={allCode}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "release_code_id");
                          setCodeError(false);
                        }}
                        value={allCodeData}
                      />
                      {codeError && (
                        <div className="small text-danger">
                          *Release Code is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Purchasing Organization
                        <span class="text-danger">*</span>
                      </label>

                      <Select
                        name="purchase_org_id"
                        id="purchase_org_id"
                        options={allPurOrg}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "purchase_org_id");
                          setPurOrgError(false);
                        }}
                        value={allPurOrgData}
                      />
                      {purOrgError && (
                        <div className="small text-danger">
                          *Purchasing Organization is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Purchasing Group<span class="text-danger">*</span>
                      </label>

                      <Select
                        name="purchase_group_id"
                        id="purchase_group_id"
                        options={allPurGroup}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "purchase_group_id");
                          setPurGroupError(false);
                        }}
                        value={allPurGroupData}
                      />
                      {purGroupError && (
                        <div className="small text-danger">
                          *Purchasing Group is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Approver Id<span class="text-danger">*</span>
                      </label>

                      <Select
                        name="approver_id"
                        id="approver_id"
                        options={allApproverId}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "approver_id");
                          setApproverError(false);
                        }}
                        value={allApproverIdData}
                      />
                      {approverError && (
                        <div className="small text-danger">
                          *Approver ID is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {isExist && (
            <Alert variant="danger" className="mb-5 mt-2">
              <i className="fa fa-exclamation-triangle"></i> This Combination
              already exists for an approver.
            </Alert>
          )}
          <Modal.Footer>
            {isLoading ? (
              <Spinner
                animation="border"
                variant="primary"
                as="span"
                role="status"
                aria-hidden="true"
              ></Spinner>
            ) : (
              <>
                <button
                  class="btn btn-outline-danger"
                  type="button"
                  onClick={closeForm}
                >
                  Cancel
                </button>
                <button
                  class="btn btn-primary-inner bpi-main"
                  type="button"
                  onClick={(e) => submitHandler(e)}
                >
                  Submit
                </button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddContractApprover;
