import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ModularTable from "../../../../elements/ModularTable";
import { useNavigate } from "react-router-dom";
import {
  getCompanyProduct,
  getMarketPlayerDropdown,
  getCompetitiveMolecules,
  getCurrentFy,
  getCompetingMolecule,
  getLastCompititionMolecule,
  putCompititionMolecule,
  postCompititionMolecule,
  getCompetitionMoleculesApprovalLogs,
  postCompetitionMoleculeAction,
} from "../../../../service/AgriPortalService/CompetitionMoleculeMappingService";
import styles from "./Table.module.css";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";

const Root = (props) => {
  const [companyProduct, setCompanyProduct] = useState([]);
  const [players, setPlayers] = useState([]);
  const [competitiveMolecule, setCompetitiveMolecule] = useState([]);
  const [currentfy, setCurrentfy] = useState({});

  const [rawData, setRawData] = useState({});

  const [tableHeadData, setTableHeadData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [updatedTableData, setUpdatedTableData] = useState([[]]);
  const [approvalList, setApprovalList] = useState([]);

  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");

  const [freezeCTA, setFreezeCTA] = useState(false);
  const [locationdata, setLocationData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [mode, setMode] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onTableDataChange = (data) => {
    let row = [
      {
        value: ["Total"],
        meta: {
          raw: "total",
        },
      },
      {
        value: ["-"],
        meta: {
          // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

          raw: "",
        },
        // selected:companyProduct[i]?.molecule_name
      },
      {
        value: ["-"],
        meta: {
          // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

          raw: "",
        },
      },
      {
        value: ["-"],
        meta: {
          // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

          raw: "",
        },
      },
      {
        value: [0],
        meta: {
          // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

          raw: "",
        },
      },
      {
        value: ["-"],
        meta: {
          id: "",
        },
      },
      {
        value: ["-"],
        meta: {
          // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

          raw: "",
        },
      },
      {
        value: ["-"],
        meta: {
          // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

          raw: "",
        },
      },
      {
        value: ["-"],
        meta: {
          // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

          raw: "",
        },
      },
      {
        value: ["-"],
        meta: {
          // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

          raw: "",
        },
      },
      {
        value: ["-"],
        meta: {
          // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

          raw: "",
        },
      },
    ];

    for (let i = 1; i < data[0]?.length; i++) {
      let total = 0;
      for (let j = 0; j < data.length - 1; j++) {
        if (data[j][4]) {
          if (!parseInt(data[j][4].value[0])) total += 0;
          else total += parseInt(data[j][4].value[0]);
        }
      }
      row[4] = { value: [total] };
    }
    if(mode === 'create'){
      data[data.length-1] = row;
    }
    else{
      data[data.length] = row;
    }
    // data[data.length - 1][3].value = [total];
    setUpdatedTableData(data);
  };



  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getCompetingMolecule()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mode]);



  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));

    getCompanyProduct()
      .then((res) => {
        if (!res?.data?.dataList) return;
        setCompanyProduct(res.data.dataList);

        for (let i = 0; i < res.data.dataList.length; i++) {
          let query = "?company_product_id=" + res.data.dataList[i].id;

          getCompetitiveMolecules(query).then((r) => {
            if (!r?.data?.dataList) return;

            setCompetitiveMolecule((state) => {
              let newState = { ...state };
              newState[res.data.dataList[i].id] = r.data.dataList;
              return newState;
            });
          });
        }
      })
      .catch((err) => console.log(err));

    getMarketPlayerDropdown()
      .then((r) => {
        setPlayers(r.data.dataList);
      })
      .catch((err) => console.log(err));
  }, []);

  // To fetch cfy data

  useEffect(() => {
    let query = "";

    getCurrentFy(query)
      .then((res) => {
        setCurrentfy(res.data.dataList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!mode) return;
    let query = "/mymarket/competitionmoleculemapping";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/mymarket/competitionmoleculemapping");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setPageId(params.get("id"));

    let query = "";
    if (params.get("mode") != "create") {
      query = "?competing_molecule_id=" + params.get("id");
    }

    getLastCompititionMolecule(query)
      .then((res) => {
        setRawData(res.data.dataList);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        if (err.response.data.code == 409) {
          toast.error("Molecule Mapping already exists!");
          setTimeout(() => {
            navigate("/mymarket/competitionmoleculemapping");
          }, 1000);
        }
      });
    if (params.get("mode") != "create") {
      getCompetitionMoleculesApprovalLogs(query)
        .then((res) => setApprovalList(res.data.dataList))
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (mode == "create") {
      setTableData((state) => {
        let data = [];

        for (let i = 0; i < companyProduct.length; i++) {
          let row = [
            {
              value: companyProduct[i]?.name,
              meta: {
                raw: companyProduct[i],
              },
            },
            {
              value: [companyProduct[i]?.molecule_name],
              meta: {
                raw: [
                  {
                    id: companyProduct[i].molecule_id,
                    name: companyProduct[i].molecule_name,
                  },
                ],

                // raw:[companyProduct[i]]
              },
              selected: companyProduct[i]?.molecule_name,
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [players.find((el) => el.code == "PIIL")?.name],
              meta: {
                raw: [players.find((el) => el.code == "PIIL")],
              },
              selected: players.find((el) => el.code == "PIIL")?.name,
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
          ];

          // const playerDropdown = dropwdownPlayers.map(el=>el.name);
          let row2 = [
            {
              value: companyProduct[i]?.name,
              meta: {
                raw: companyProduct[i],
              },
            },
            {
              value: competitiveMolecule[companyProduct[i].id]?.map(
                (element) => {
                  return element.name;
                }
              ),
              meta: {
                raw: competitiveMolecule[companyProduct[i].id],
              },
              selected:
                competitiveMolecule[companyProduct[i].id]?.length > 0
                  ? competitiveMolecule[companyProduct[i].id][0]?.name
                  : "",
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: players
                ?.filter((el) => el.code != "PIIL")
                .map((el) => el.name),
              meta: {
                raw: players,
              },
              selected: players?.filter((el) => el.code != "PIIL")[0]?.name,
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
          ];
          let row3 = [
            {
              value: companyProduct[i]?.name,
              meta: {
                raw: companyProduct[i],
              },
            },
            {
              value: competitiveMolecule[companyProduct[i].id]?.map(
                (element) => {
                  return element.name;
                }
              ),
              meta: {
                raw: competitiveMolecule[companyProduct[i].id],
              },
              selected:
                competitiveMolecule[companyProduct[i].id]?.length > 0
                  ? competitiveMolecule[companyProduct[i].id][1]?.name
                  : "",
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },

            {
              value: players
                ?.filter((el) => el.code != "PIIL")
                .map((el) => el.name),
              meta: {
                raw: players,
              },
              selected: players?.filter((el) => el.code != "PIIL")[1]?.name,
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
            {
              value: [0],
              meta: {
                id: "",
              },
            },
          ];
          data.push(row);
          data.push(row2);
          data.push(row3);
        }

        let row = [
          {
            value: ["Total"],
            meta: {
              raw: "total",
            },
          },
          {
            value: ["-"],
            meta: {
              // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

              raw: "",
            },
            // selected:companyProduct[i]?.molecule_name
          },
          {
            value: ["-"],
            meta: {
              // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

              raw: "",
            },
          },
          {
            value: ["-"],
            meta: {
              // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

              raw: "",
            },
          },
          {
            value: [0],
            meta: {
              // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

              raw: "",
            },
          },
          {
            value: ["-"],
            meta: {
              id: "",
            },
          },
          {
            value: ["-"],
            meta: {
              // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

              raw: "",
            },
          },
          {
            value: ["-"],
            meta: {
              // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

              raw: "",
            },
          },
          {
            value: ["-"],
            meta: {
              // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

              raw: "",
            },
          },
          {
            value: ["-"],
            meta: {
              // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

              raw: "",
            },
          },
          {
            value: ["-"],
            meta: {
              // raw:[{id:companyProduct[i].molecule_id , name:companyProduct[i].molecule_name}]

              raw: "",
            },
          },
        ];
        data.push(row);

        return data;
      });
    } else {
      setTableData((state) => {
        let data = [];

        for (let i = 0; i < rawData?.competing_data?.length; i++) {
          const findIndex = companyProduct.findIndex(
            (el) => el.name == rawData?.competing_data[i]?.pi_product_name
          );
          let row = [
            {
              value: companyProduct[findIndex]?.name,
              meta: {
                raw: companyProduct[findIndex],
              },
            },
            {
              value:
                (i + 1) % 3 == 1
                  ? [rawData?.competing_data[i].pi_molecule_name]
                  : competitiveMolecule[
                    rawData?.competing_data[i].pi_product_id
                  ]?.map((element) => {
                    return element.name;
                  }),
              meta: {
                raw:
                  (i + 1) % 3 == 1
                    ? [
                      {
                        id: rawData?.competing_data[i].molecule_id,
                        name: rawData?.competing_data[i].molecule_name,
                      },
                    ]
                    : competitiveMolecule[
                    rawData?.competing_data[i].pi_product_id
                    ],
              },
              selected:
                (i + 1) % 3 == 1
                  ? rawData?.competing_data[i]?.pi_molecule_name
                  : rawData?.competing_data[i]?.molecule_name,
            },
            {
              value: [rawData?.competing_data[i]?.market_size],
              meta: {
                id: "",
              },
            },
            {
              value:
                rawData?.competing_data[i]?.player_code == "PIIL"
                  ? [rawData?.competing_data[i]?.player_name]
                  : players
                    ?.filter((el) => el.code != "PIIL")
                    .map((el) => el.name),
              meta: {
                raw: players,
              },
              selected: rawData?.competing_data[i]?.player_name,
            },
            {
              value: [rawData?.competing_data[i]?.qty],
              meta: {
                id: "",
              },
            },
            {
              value: [rawData?.competing_data[i]?.coa],
              meta: {
                id: "",
              },
            },
            {
              value: [rawData?.competing_data[i]?.field_work],
              meta: {
                id: "",
              },
            },
            {
              value: [rawData?.competing_data[i]?.promotion],
              meta: {
                id: "",
              },
            },
            {
              value: [rawData?.competing_data[i]?.cr_policy],
              meta: {
                id: "",
              },
            },
            {
              value: [rawData?.competing_data[i]?.dist_outlets],
              meta: {
                id: "",
              },
            },
            {
              value: [rawData?.competing_data[i]?.service],
              meta: {
                id: "",
              },
            },
          ];
          data.push(row);
        }

        return data;
      });
    }
  }, [companyProduct, players, competitiveMolecule, mode, rawData]);

  useEffect(() => {
    let colData = [];

    if (mode != "view" && mode != "approve")
      colData = [
        {
          name: "PI Brand",
          type: "T",
          isEditable: false,
        },
        {
          name: "Competing Molecule",
          type: "D",
          isEditable: true,
        },
        {
          name: "Market Size (Ltr/Kg)",
          type: "N",
          isEditable: true,
        },
        {
          name: "Player",
          type: "D",
          isEditable: true,
        },
        {
          name: "Qty (Ltr/Kg)",
          type: "N",
          isEditable: true,
        },
        {
          name: "COA",
          type: "N",
          isEditable: true,
          isRating: true,
        },
        {
          name: "Field Work",
          type: "N",
          isEditable: true,
          isRating: true,
        },
        {
          name: "Promotion",
          type: "N",
          isEditable: true,
          isRating: true,
        },
        {
          name: "Cr Policy",
          type: "N",
          isEditable: true,
          isRating: true,
        },
        {
          name: "Dist Outlets",
          type: "N",
          isEditable: true,
          isRating: true,
        },
        {
          name: "Service",
          type: "N",
          isEditable: true,
          isRating: true,
        },
      ];
    if (mode == "view" || mode == "approve")
      colData = [
        {
          name: "PI Brand",
          type: "T",
          isEditable: false,
        },
        {
          name: "Competing Molecule",
          type: "D",
          isEditable: false,
        },
        {
          name: "Market Size (Ltr/Kg)",
          type: "N",
          isEditable: false,
        },
        {
          name: "Player",
          type: "D",
          isEditable: false,
        },
        {
          name: "Qty (Ltr/Kg)",
          type: "N",
          isEditable: false,
        },
        {
          name: "COA",
          type: "N",
          isEditable: false,
          isRating: true,
        },
        {
          name: "Field Work",
          type: "N",
          isEditable: false,
          isRating: true,
        },
        {
          name: "Promotion",
          type: "N",
          isEditable: false,
          isRating: true,
        },
        {
          name: "Cr Policy",
          type: "N",
          isEditable: false,
          isRating: true,
        },
        {
          name: "Dist Outlets",
          type: "N",
          isEditable: false,
          isRating: true,
        },
        {
          name: "Service",
          type: "N",
          isEditable: false,
          isRating: true,
        },
      ];

    setColumnData(colData);
  }, [mode]);

  //Called to format data for output body
  const convertCategoryData = (data) => {
    const ans = [];

    if (!data) return;

    data.forEach((row, idx) => {
      if (row[0].value[0] != "Total") {
        let player_id = row[3]?.meta?.raw?.find(
          (el) => el?.name == row[3]?.selected
        )?.id;
        let product_id = row[1]?.meta?.raw?.find(
          (el) => el?.name == row[1]?.selected
        )?.id;
        if (player_id) {
          ans.push({
            id: 0,
            pi_product_id: row[0]?.meta?.raw?.id,

            player_id: player_id,

            molecule_id: product_id,

            market_size: row[2]?.value[0] ? row[2]?.value[0] : 0,
            qty: row[4]?.value[0] ? row[4]?.value[0] : 0,
            coa: row[5]?.value[0] ? row[5]?.value[0] : 0,
            field_work: row[6]?.value[0] ? row[6]?.value[0] : 0,
            promotion: row[7]?.value[0] ? row[7]?.value[0] : 0,
            cr_policy: row[8]?.value[0] ? row[8]?.value[0] : 0,
            dist_outlets: row[9]?.value[0] ? row[9]?.value[0] : 0,
            service: row[10]?.value[0] ? row[10]?.value[0] : 0,
          });
        }
      }

      // }
    });
    // if(row[3].meta.raw&&row[1].meta.raw){
    return ans;
  };

  const prepareData = (type) => {
    if (freezeCTA) return;

    setFreezeCTA(true);
    // let cfy = rawData?.cfy.split("-")[1];

    // if(cfy.length!==4) return;

    // cfy=rawData?.cfy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy: currentfy.fy,
      territory_id,
      status: type,
    };
    const molecule_data = convertCategoryData(updatedTableData);

    if (!molecule_data) return;
    body.molecule_data = molecule_data;

    var playerId = [];
    var playerList = [];
    for (let i = 1; i <= molecule_data.length; i++) {
      if (i % 3 == 0) {
        if (playerId.includes(molecule_data[i - 1].player_id)) {
          let playerIndex = companyProduct.findIndex(
            (ele) => ele.id == molecule_data[i - 1].pi_product_id
          );
          playerList.push(companyProduct[playerIndex]?.name);
        }
        playerId = [];
      } else {
        playerId.push(molecule_data[i - 1].player_id);
      }
    }
    if (playerList.length > 0) {
      let text = `Kindly select different player for following PI Brand: ${playerList.join(
        ", "
      )}`;
      errorDialog(text, type);
      setFreezeCTA(false);
      return;
    } else {
      confirmDialog(type, body);
    }
  };

  //EVENT HANDLERS
  const submitData = (type, body) => {
    if (mode == "create")
      postCompititionMolecule(body)
        .then((res) => {
          toast.success(
            type == "P" ? "Submit Succesfull!" : "Save Succesfull!"
          );
          setTimeout(() => {
            navigate("/mymarket/competitionmoleculemapping");
          }, 1000);
        })
        .catch((err) => {
          toast.error(type == "P" ? "Submit Failed!" : "Save Failed!");
          setTimeout(() => {
            navigate("/mymarket/competitionmoleculemapping");
          }, 1000);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putCompititionMolecule(body)
        .then((res) => {
          toast.success(
            type == "P" ? "Submit Succesfull!" : "Save Succesfull!"
          );
          setTimeout(() => {
            navigate("/mymarket/competitionmoleculemapping");
          }, 1000);
        })
        .catch((err) => {
          toast.error(type == "P" ? "Submit Failed!" : "Save Failed!");
          setTimeout(() => {
            navigate("/mymarket/competitionmoleculemapping");
          }, 1000);
        });
    }
  };

  const errorDialog = (data) => {
    confirmAlert({
      title: "Duplicate Player",
      message: data,
      buttons: [
        {
          label: "Okay",
        },
      ],
    });
  };

  const confirmDialog = (type, body) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            submitData(type, body);
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  const onSubmitHandler = (event) => {
    if (freezeCTA) return;

    setFreezeCTA(true);

    // let cfy = rawData?.cfy.split("-")[1];
    // if(cfy.length!==4) return;

    // cfy=rawData?.cfy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy: currentfy.fy,
      territory_id,
      status: "P",
    };
    let molecule_data = convertCategoryData(updatedTableData);

    if (!molecule_data) return;
    body.molecule_data = molecule_data;
    var playerId = [];
    var playerList = [];

    for (let i = 1; i <= molecule_data.length; i++) {
      if (i % 3 == 0) {
        if (playerId.includes(molecule_data[i - 1].player_id)) {
          let playerIndex = companyProduct.findIndex(
            (ele) => ele.id == molecule_data[i - 1].pi_product_id
          );
          playerList.push(companyProduct[playerIndex]?.name);
        }
        playerId = [];
      } else {
        playerId.push(molecule_data[i - 1].player_id);
      }
    }
    if (playerList.length > 0) {
      alert(
        `Kindly select different player for following PI Brand: ${playerList.join(
          ", "
        )}`
      );
      setFreezeCTA(false);
      return;
    }
    if (mode == "create")
      postCompititionMolecule(body)
        .then((res) => {
          setFreezeCTA(false);
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/mymarket/competitionmoleculemapping");
          }, 1000);
        })
        .catch((err) => {
          setFreezeCTA(false);
          toast.error("Submit Failed!");
          console.log(err);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putCompititionMolecule(body)
        .then((res) => {
          setFreezeCTA(false);
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/mymarket/competitionmoleculemapping");
          }, 1000);
        })
        .catch((err) => {
          setFreezeCTA(false);
          toast.error("Submit Failed!");
          console.log(err);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/mymarket/competitionmoleculemapping");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      competing_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postCompetitionMoleculeAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };

  // const columnSettings = [
  //     {
  //         name:"PI Product",
  //         type:"T",
  //         isEditable:false,
  //     },
  //     {
  //         name:"Competing Molecule",
  //         type:"D",
  //         isEditable:true,
  //     },
  //     {
  //         name:"Market Size",
  //         type:"N",
  //         isEditable:true,
  //     },
  //     {
  //         name:"Player",
  //         type:"D",
  //         isEditable:true,
  //     },
  //     {
  //         name:"Qty",
  //         type:"N",
  //         isEditable:true,
  //     },
  //     {
  //         name:"ODA",
  //         type:"N",
  //         isEditable:true,
  //     },
  //     {
  //         name:"FIELD",
  //         type:"N",
  //         isEditable:true,
  //     },
  //     {
  //         name:"FIELD",
  //         type:"N",
  //         isEditable:true,
  //     },
  //     {
  //         name:"FIELD",
  //         type:"N",
  //         isEditable:true,
  //     },
  //     {
  //         name:"FIELD",
  //         type:"N",
  //         isEditable:true,
  //     },
  //     {
  //         name:"FIELD",
  //         type:"N",
  //         isEditable:true,
  //     }
  // ];

  (function () {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
    }
  })();
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Competition Molecule Mapping
          </h4>
          <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            Rating in 1-5 Scale, 5 Excellent 1 Poor
          </p>
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{currentfy?.fy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationdata?.zone_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>REGION</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationdata?.region_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>TERRITORY</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationdata?.territory_name}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              showTotal={true}
              columns={columnData}
              data={tableData}
              onTableDataChange={onTableDataChange}
            />
          </div>
        </div>
        {approvalList?.approvals && approvalList?.approvals.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>Action At</th>
                      <th>Action By</th>
                      <th>Action User</th>
                      <th>Action Status</th>
                      <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList?.approvals &&
                      approvalList?.approvals.map((el, i) => {
                        let status;

                        const findPendingIndex =
                          approvalList.approvals.findIndex(
                            (ele) => ele.status == "P" && ele.discarded == false
                          );

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P" && el.discarded == true)
                          status = "Auto Discarded";
                        else if (el.status == "P") status = "Pending";
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr>
                            <td>
                              {status == "Pending"
                                ? "-"
                                : moment(el.action_at)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm:ss")}
                            </td>
                            <td>{el.action_by_id}</td>
                            <td>{el.action_user_name}</td>
                            <td>
                              {findPendingIndex == -1
                                ? status
                                : i > findPendingIndex
                                  ? "-"
                                  : status}
                            </td>
                            <td>{el.remarks}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  prepareData("P");
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  prepareData("D");
                }}
              >
                Save
              </div>
            </div>
          )}
          {mode == "approve" || approvalList?.for_approval ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Root;
