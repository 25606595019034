import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Sort from "../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import {
  getUsersListDataByOrg,
  submitOtdetails,
  getOttdetails,
  ottuserlistexportfile,
  uploadfile,
} from "../../service/otservice";
import { useSelector } from "react-redux";
import { selectUserData } from "../../pages/redux/piDataStore";
// import{otservice}from "../../service/otservice"
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from "react-toastify";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as EmpService from "../../service/employee";
//import Tooltip from "react-bootstrap/Tooltip";

function OTHOur() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [filename, setfilename] = useState("");

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [userList, setUserList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [viewFormData, setViewFormData] = useState(null);
  const [user, setUser] = useState(null);
  const [date, setDate] = useState("");
  const [branchData, setBranchData] = useState([]);
  const [personalAreaData, setPersonalAreaData] = useState([]);
  const [userOttDetails, setuserOttDetails] = useState([]);
  const [reason, setReason] = useState("");
  const [othours, setOthours] = useState(null);
  const [sap_status, setsap_status] = useState(false);
  const [file, setfile] = useState({});
  const [fileurl, setfileurl] = useState("");
  const [username, setUsername] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("")
  const [psa, setPsa] = useState(null);
  const [psaList, setPsaList] = useState([]);
  const [psaId, setPsaId] = useState("");
  const userData = useSelector(selectUserData);
  const [filterApplied, setFilterApplied] = useState(false);

  useEffect(() => {
    getUserList();
    getottuserlist("", "", currentPage, pageSizeNo, "-id", true, "", "");

    // setDate(moment(new Date()).format("YYYY-MM-DD"))
  }, [pageSizeNo]);

  const handleKey = (
    search,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate,
    user_id,
    sap_status,
    from_date,
    to_date,
    location
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (sap_status !== "" && sap_status !== undefined && sap_status !== null) {
      queryParm = queryParm + "&sap_status=" + sap_status;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (location !== "" && location !== undefined && location !== null) {
      queryParm = queryParm + "&location=" + location;
    }

    return queryParm;
  };

  const exportHandleKey = (searchStr, user_id, sap_status, sort_by) => {
    let queryParm = "";

    if (searchStr !== "" && searchStr !== undefined && searchStr !== null) {
      queryParm = queryParm + "&SearchStr=" + searchStr;
    }
    if (sap_status !== "" && sap_status !== undefined && sap_status !== null) {
      queryParm = queryParm + "&sap_status=" + sap_status;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }

    return queryParm;
  };

  const openAdduser = () => {
    addnewopen();
    getUserList();
  };

  const closeAdduser = () => {
    setUser(null);
    setReason("");
    setDate("");
    setOthours("");
    addnewclose();
  };
  const submitdata = () => {
    if (
      user === null ||
      othours === null ||
      date === "" ||
      reason === "" ||
      fileurl === ""
    ) {
      toast.error("Please Select Required Field");
    } else {
      if (othours == "0") {
        toast.error("OT hour Should be greater than zero");
      } else {
        submitedOtdetails();
      }
    }
  };
  const [formValidationNew, setFormValidationNew] = useState({
    user_id: "",
    ot_hours: "",
    ot_date: "",
    reason: "",
    attachment: "",
  });

  const cancelottdatasubmit = () => {
    filtersetShow(false);
    setUser(null);
    setOthours("");
    setReason("");
    setDate("");
    setfilename("");
    setfile({});
    addnewclose();
  };
  const submitedOtdetails = () => {
    // console.log("File",file)
    let data = {
      user_id: user,
      ot_hours: othours,
      ot_date: date,
      reason: reason,
      attachment: file,
    };
    submitOtdetails(data)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          // console.log("submitedOtdetails", response)
          setUser(null);
          setOthours("");
          setReason("");
          setDate("");
          addnewclose();
          setfilename("");
          setfileurl("");
          setfile({});

          //   onAddCallBack(response);
          //   setIsLoading(false);
          getottuserlist("", "", currentPage, pageSizeNo, "-id", true, "", "");
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        dispatch(setDisplayLoader("Hide"));
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getottuserlist = (
    search,
    activePage,
    pageSizeNo,
    paginate,
    pageNo,
    page_size,
    sort_by,
    user,
    sap_status,
    from_date,
    to_date,
    location
  ) => {
    if (userData?.organisation_id != undefined) {
      getOttdetails(
        handleKey(
          search,
          activePage,
          pageSizeNo,
          paginate,
          pageNo,
          page_size,
          sort_by,
          user,
          sap_status,
          from_date,
          to_date,
          location
        )
      ).then((response) => {
        // console.log("getottuserlist", response)
        dispatch(setDisplayLoader("Hide"));
        if (response?.status === 200) {
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          response?.data?.dataList?.result.map((val) => {
            var d = moment(val.ot_date).toDate();
            val["ot_month"] = monthNames[d.getMonth()];
            var dat = val.created_at.split("T")[0];
            // console.log("data>>>>>>>>>>",dat)
            val["created_at"] = dat;
          });

          setuserOttDetails(response?.data?.dataList?.result);
          filtersetShow(false);
          setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
          // let userDropdown = response?.data?.dataList?.result.map((obj) => {
          //   return {
          //     label: `${obj.username} (${obj.full_name})`,
          //     value: obj.id,
          //   };
          // });
          // setUserList(userDropdown);
        }
      });
    }
  };

  const ottuserlistexport = (searchStr, user_id, sap_status, sort_by) => {
    if (userData?.organisation_id != undefined) {
      dispatch(setDisplayLoader("Display"));
      ottuserlistexportfile(
        exportHandleKey(searchStr, user_id, sap_status, sort_by)
      )
        .then((response) => {
          // console.log("getottuserlistexporst", response)
          if (response?.status === 200) {
            dispatch(setDisplayLoader("Hide"));
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "ott_users.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  };

  const handleSearch = (e) => {
    // console.log("E value ",e.target.value)
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      // console.log("eeee>>>>>",e.target.value)
      setSearchStr(e.target.value);
      getottuserlist(
        e.target.value.toLowerCase(),
        "",
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        ""
      );
    }
  };

  const getUserList = async () => {
    if (userData?.organisation_id != undefined) {
      dispatch(setDisplayLoader("Display"));
      await getUsersListDataByOrg(0).then(
        (response) => {
          dispatch(setDisplayLoader("Hide"));
          if (response?.status === 200) {
            let userDropdown = response?.data?.dataList?.result.map((obj) => {
              return {
                label: `${obj.full_name} (${obj.username})`,
                value: obj.id,
              };
            });
            setUserList(userDropdown);
          }
        }
      );
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getottuserlist("", "", activePage, pageSizeNo, "-id", true, "", "");
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      getottuserlist("", "", 1, pageSizeNo, "-id", true, "", "");
      setSearchStr("");
    }
  };
  const fileUploadHandler = (e) => {
    // console.log("GIleeea",e.target.files)

    // setfile(e.target.files)
    let file = e.target.files;
    // setfile(file);
    let files = [];
    let filename = [];
    let myfile = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      //   console.log("Datat",data)
      let myUrl = URL.createObjectURL(e.target.files[i]);
      //   console.log("URs",myUrl)
      setfileurl(myUrl);
      myfile.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      uploadfile(fileData).then((response) => {
        // console.log("Fileresponse",response.data)
        if (response.data.status == true) {
          setfilename(e.target.files[0].name);
          setfile(response.data);
        }
      });
    }
  };

  const openfilter = () => {
    filtersetShow(true);
    getUserList();
    LocationFilter();
  };

  const submitfilterdata = () => {
    setFilterApplied(true);
    // console.log("e value",user,sap_status)
    // if (user?.length > 0) {
    getottuserlist(
      "",
      "",
      currentPage,
      pageSizeNo,
      "-id",
      true,
      user,
      sap_status,
      fromDate,
      toDate,
      psaId
    );
    // }
  };

  useEffect(() => {
    getottuserlist(
      "", "", currentPage, pageSizeNo, "-id", true, "", ""
    );
  }, [pageSizeNo]);

  const closefilter = () => {
    setFilterApplied(false);
    getottuserlist("", "", currentPage, pageSizeNo, "-id", true, "", "");
    filterclose();
    setUser("");
    setsap_status("");
    setUsername("");
    setPsa(null);
    setPsaId("");
  };

  const ignoreIntValue = (event, data) => {
    if (event.key == "-") {
      toast.warning("you cannot enter negative number.");
      setOthours("");
      let val = document.getElementById("othour");
      val.value = "";
    }
  };

  const LocationFilter = () => {
    EmpService.LocationFilter()
      .then((response) => {
        let locationData = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res.description + "(" + res?.name + ")",
          };
        });
        setPsaList(locationData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">OT Hour Automated Process</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/home">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      OT Hour Automated Process
                    </Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>

                <div class="col-md-12">
                  <div class="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on <strong> Employee Name</strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Employee Name..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              ottuserlistexport(searchStr, "", "", "id")
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={openfilter}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button onClick={openAdduser} class="btn btn-primary-inner">
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="purchaseinnertabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Emp Code</th>
                      <th>Emp Name</th>
                      <th>OT Hours</th>
                      <th>OT Month</th>
                      <th>Reason</th>
                      <th>SAP Status</th>
                      <th>SAP Response</th>
                      <th>Attachment</th>
                      <th>Created BY</th>
                      <th>Created Date</th>
                      <th>Last Synced Date</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {userOttDetails.map((val) => {
                      return (
                        <tr>
                          <td class="">{val.user_code}</td>
                          <td>{val.user_name}</td>
                          <td>{val.ot_hours}</td>
                          <td>{val.ot_month}</td>
                          <td>{val.reason}</td>
                          {val.sap_status === true ? (
                            <td>
                              <span class="">Synced</span>
                            </td>
                          ) : (
                            <td>
                              <span class="">Pending to Sync</span>
                            </td>
                          )}
                          <td>{val.sap_message}</td>
                          <td>
                            <a
                              href={val.attachment}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i class="fas fa-paperclip"></i> View Doc
                            </a>
                          </td>
                          <td>{val.created_by}</td>
                          <td>{moment(val.created_at).format("DD-MM-YYYY")}</td>
                          {val?.sync_date !== null ? (
                            <td>
                              {moment
                                .utc(val?.sync_date)
                                .format("DD-MM-YYYY hh:mm")}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          {/* <td>
                                                        <button class="btn btn-blanktd text-primary"><i class="far fa-edit"></i></button>
                                                        <button class="btn btn-blanktd text-danger ml-2"><i class="far fa-trash-alt text-danger"></i></button>
                                                    </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Employee Code<span class="text-danger"></span>
            </label>
            <Select
              options={userList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setUser(e.value);
                setUsername(e);
              }}
              value={username}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Select SAP Status<span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              onChange={(e) => {
                setsap_status(e.target.value);
              }}
              value={sap_status}
            >
              {" "}
              <option value="" label="Select" disabled selected>
                Select
              </option>
              <option value={true} label={"Yes"}>
                Yes
              </option>
              <option value={false} label={"No"}>
                No
              </option>
            </select>
          </div>
          <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value)
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value)
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Location
            </label>
            <Select
              options={psaList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setPsa(e)
                setPsaId(e.value)
              }}
              value={psa}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={closefilter}>
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={submitfilterdata}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit OT Hours</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Employee Code<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={userList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setUser(e.value);
                      }}
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      OT Hours (Single)<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      class="form-control"
                      id="othour"
                      placeholder="Enter OT Hours"
                      onKeyUp={(event) => {
                        ignoreIntValue(event, "amount");
                      }}
                      onChange={(e) => {
                        if (e.target.value.length < 5) {
                          setOthours(e.target.value);
                        }
                      }}
                      value={othours}
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      OT Date<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      value={date}
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Reason of Payment<span class="text-danger">*</span>
                    </label>
                    <select
                      class="form-control newbgselect"
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                    >
                      <option value="" selected="selected">
                        Select
                      </option>
                      <option value="HOD approved after data process">
                        HOD approved after data process
                      </option>
                      <option value="OT entry not done on time">
                        OT entry not done on time
                      </option>
                      <option value="Department MIS match">
                        Department MIS match
                      </option>
                      <option value="Technical Reason">Technical Reason</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup position-relative">
                    <label>
                      Attachment<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control bg-white"
                      disabled
                      placeholder={
                        filename?.length > 0
                          ? filename
                          : "JPG,PNG,PDF Format Only"
                      }
                    />

                    <div class="upload-btn-wrapper up-loposition">
                      <button class="uploadBtn">Browse</button>
                      <input
                        type="file"
                        // accept="image/*"
                        accept="application/pdf,image/png,image/jpeg"
                        // value={filename}

                        onChange={(e) => fileUploadHandler(e)}
                      />
                      {/* {console.log("Fileurl",fileurl)} */}
                    </div>
                    <p>
                      Selected files{" "}
                      <span>
                        {fileurl != "" ? (
                          <a href={fileurl} target="_blank" rel="noreferrer">
                            {filename}
                          </a>
                        ) : (
                          ""
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={cancelottdatasubmit}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main" onClick={submitdata}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OTHOur;
