import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Modal, Alert } from "react-bootstrap";
import * as TravelListingAPI from "../../service/travelService";
import * as otherExpService from "../../service/otherExpService";
import * as userGl from "../../service/userGl";

import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddExpenseTravel = (props) => {
  const {
    showLeaveForm,
    onViewCloseEdit,
    viewLeaveData,
    isEdit,
    handleClose,
    onAddCallBack,
  } = props;
  const navigate = useNavigate();
  const optionsTravel = [
    { value: "", label: "Select" },
    { value: "Bike", label: "Bike" },
    { value: "Car", label: "Car" },
    { value: "Ola", label: "Ola" },
    { value: "Uber", label: "Uber" },
    { value: "Taxi", label: "Taxi" },
    { value: "Bus", label: "Bus" },
    { value: "Auto", label: "Auto" },
    { value: "Public Transport", label: "Public Transport" },
  ];
  const [glData, setGlData] = useState([]);
  const [costCenterData, setCostCenterData] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [glValue, setGlValue] = useState();
  const [isExist, setIsExist] = useState(false);
  const userData = useSelector(selectUserData);
  let condition = "false";
  const [attachment, setAttachment] = useState([]);
  const [Errorattachment, setErrorattachment] = useState(false);
  const [orgListData, setOrgListData] = useState([]);
  const [file, setfiles] = useState([]);
  const [flag, setflag] = useState(false);
  const [codeFlag, setCodeFlag] = useState(false);
  const [codeFlagCost, setCodeFlagCost] = useState(false);
  const [codeFlagTravel, setCodeFlagTravel] = useState(false);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [costCenterValue, setCostCenterValue] = useState([]);
  const [travelModeName, setTravelModeName] = useState([]);
  const [userBackDate, setUserBackDate] = useState([]);
  const [modeList, setModeList] = useState([]);
  const [pettyUser, setPettyUser] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [deleteattachment, setDeleteAttachment] = useState([]);
  const [showExistsError, setShowExistsError] = useState("");
  const [pettyOrgList, setpettyOrgList] = useState([]);
  // const [otherExpUserProfileData, setotherExpUserProfileData] = useState([]);
  const [totalDraftRecords, set_totalDraftRecords] = useState({
    total: 0,
    expenseType: null,
    pettyCard: "",
    pettyCardVendor: "",
  });

  const dispatch = useDispatch();

  const [lastRecentBill, setlastRecentBill] = useState({
    back_date_id: "",
    back_date_from: "",
    back_date_to: "",
    back_date_opened_from: "",
    back_date_opened_to: "",
    max_bill_date: "",
    other: {},
    petty: {},
  });
  const [expenseData, setExpenseData] = useState([
    {
      gl_id: "",
      // gl_account: null,
      // gl_desc: null,
      machine_no: undefined,
      bill_number: "",
      bill_date: "",
      bill_amount: null,
      remarks: "",
      cost_center_id: undefined,
      cost_center_id_name: undefined,
      mode_id: undefined,
      mode_id_name: undefined,
      bill_desc: undefined,
      for_month_of: null,
      travel_by: undefined,
      travel_by_name: undefined,
      travel_by_id: undefined,
      paid_by: undefined,
    },
  ]);
  const [formData, setFormData] = useState({
    expense_type: "Other",
    attachment_path: {},
    organisation_id: "",
    expense_data: [
      {
        gl_id: 0,
        bill_number: "",
        bill_date: "",
        bill_amount: 0,
        remarks: "",
        cost_center_id: "",
        bill_desc: "",
        for_month_of: "",
        machine_no: "",
        mode_id: "",
        travel_by: undefined,
      },
    ],
  });
  const [formValidation, setFormValidation] = useState({
    gl_id: "",
    bill_number: "",
    bill_date: "",
    bill_amount: "",
    remarks: "",
    cost_center_id: "",
    bill_desc: "",
    for_month_of: "",
    machine_no: "",
    mode_id: "",
    travel_by: "",
    paid_by: "",
  });

  const validate2 = () => {
    if (handleValidate()) {
      return;
    } else {
      confirmDialog();
    }
  };

  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    expenseData?.map((expenseData, i) => {
      if (!expenseData.gl_id) {
        isError = true;
        formerr.gl_id = "*Gl Id is required ";
        setFormValidation(formerr);
      }
      if (!expenseData.bill_number) {
        formerr.bill_number = "*Bill Number is required";
        setFormValidation(formerr);
      }
      if (!expenseData.bill_date) {
        isError = true;
        formerr.bill_date = "*Bill Date is required";
        setFormValidation(formerr);
      }
      if (!expenseData.bill_amount) {
        isError = true;
        formerr.bill_amount = "*Bill Amount is required";
        setFormValidation(formerr);
      } else if (expenseData.bill_amount < 0) {
        isError = true;
        formerr.bill_amount = "Bill Amount cannot be less then zero ";
        setFormValidation(formerr);
      }
      if (!expenseData.remarks) {
        isError = true;
        formerr.remarks = "*Remarks is required";
        setFormValidation(formerr);
      }
      if (formData?.expense_type === "petty" && !expenseData.paid_by) {
        isError = true;
        formerr.paid_by = "*Paid by is required";
        setFormValidation(formerr);
      }
      if (formData?.expense_type === "petty" && !expenseData.cost_center_id) {
        isError = true;
        formerr.cost_center_id = "*Cost Center Id is required ";
        setFormValidation(formerr);
      }
      if (formData?.expense_type === "petty" && !expenseData.for_month_of) {
        isError = true;
        formerr.for_month_of = "*For Month Of is required ";
        setFormValidation(formerr);
      }
      if (formData?.expense_type === "petty" && !expenseData.bill_desc) {
        isError = true;
        formerr.bill_desc = "*Bill Desc is required ";
        setFormValidation(formerr);
      }
    });

    return isError;
  };
  const setValue = (id, value) => {
    return {
      value: id,
      label: value,
    };
  };

  const onProfileChange = async (e, flag) => {
    let valid_files_index = [];
    let _file = [];
    let valid_mime = [
      "image/jpeg", // for JPEG
      "image/png", // for PNG
      "application/pdf", // for PDF
      // MSG files don't have a standard MIME type;  might need custom handling
      "application/msword", // for DOC
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for DOCX
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // for XLSX
      "application/vnd.ms-excel.sheet.binary.macroEnabled.12", // for XLSB
    ];

    for (let i = 0; i < e.target.files.length; i++) {
      console.log(`file type`, e.target.files[i].name);

      if (
        valid_mime.includes(e.target.files[i].type) ||
        e.target.files[i].name.toLowerCase().lastIndexOf(".xls") ||
        e.target.files[i].name.toLowerCase().lastIndexOf(".xlsx")
      ) {
        valid_files_index.push(i);
        _file.push(e.target.files[i]);
      }
      // else{
      //   toast.error("Invalid file found")
      // }
    }

    // return

    dispatch(setDisplayLoader("Display"));
    let ooold = [...file, ..._file];

    setfiles(ooold);
    let files = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < valid_files_index.length; i++) {
      let data = e.target.files[valid_files_index[i]];
      let myUrl = URL.createObjectURL(e.target.files[valid_files_index[i]]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      await TravelListingAPI.uploadFile(fileData).then((response) => {
        let name = { name: data.name };
        filename.push(name);
        files.push(response.data);
      });
    }
    setflag(true);
    setErrorattachment(false);
    // setAttachment([]);
    setUploadDataIm(files);
    dispatch(setDisplayLoader("Hide"));
  };

  const ItemsData = expenseData?.map((items, i) => ({
    gl_id: items?.gl_id,
    bill_number: items?.bill_number,
    // bill_date: moment.parseZone(items?.bill_date).format("YYYY-MM-DD"),
    bill_date: items?.bill_date,
    bill_amount: items?.bill_amount,
    approved_amount: items?.bill_amount,
    remarks: items?.remarks,
    cost_center_id: items?.cost_center_id,
    machine_no: items?.machine_no,
    travel_by: items?.travel_by,
    bill_desc: items?.bill_desc,
    for_month_of: items?.for_month_of
      ? moment(items?.for_month_of).format("YYYY-MM-DD")
      : null,
    // for_month_of: items?.for_month_of ? moment(items?.for_month_of).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
    paid_by: items?.paid_by,
  }));

  const allJvData = (value, id) => {
    if (
      !isEdit &&
      value != "P" &&
      totalDraftRecords?.total &&
      totalDraftRecords?.expenseType?.toLowerCase() == "other"
    ) {
      toast.error(
        `Draft record already exists for ${formData?.expense_type} expense`,
        {
          autoClose: 3500,
        }
      );
      return;
    }
    if (isEdit == undefined) {
      if (handleValidate()) return false;
      let dataSave = {
        organisation_id: formData?.organisation_id,
        expense_type: formData?.expense_type,
        attachment_path: uploadDataIm,
        expense_data: ItemsData,
        status: value,
        total_bill_amount: totalAmount,
      };
      saveOtherExpense(dataSave);
    } else {
      let dataSave = {
        organisation_id: formData?.organisation_id,
        expense_type: formData?.expense_type,
        attachment_path: uploadDataIm,
        attachment_delete: deleteattachment,
        expense_data: ItemsData,
        status: value,
        total_bill_amount: totalAmount,
      };
      updateDemand(dataSave, viewLeaveData.id);
    }
  };

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    gl_type,
    organisation_id,
    is_active = ""
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (gl_type !== "" && gl_type !== undefined && gl_type !== null) {
      queryParm = queryParm + "&gl_type=" + gl_type;
    }
    if (is_active !== "" && is_active !== undefined && is_active !== null) {
      queryParm = queryParm + "&is_active=" + is_active;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    return queryParm;
  };

  const getGlList = async (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    gl_type,
    organisation_id,
    is_active = ""
  ) => {
    await TravelListingAPI.getGlListOtherExpNew(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        gl_type,
        organisation_id,
        is_active
      )
    )
      .then((response) => {
        // let res = response?.data?.dataList?.result[0]?.gl_id?.map((obj) => {
        //   return {
        //     value: obj.gl_id,
        //     label: `${obj?.gl_desc} (${obj?.gl_account})`,
        //     code: obj?.gl_account,
        //   };
        // });
        // let newRes = response?.data?.dataList?.result[0]?.cost_center_id?.map(
        //   (obj) => {
        //     return {
        //       value: obj.id,
        //       label: `${obj?.cost_center_name} (${obj?.cost_center_code})`,
        //     };
        //   }
        // );
        // setCostCenterData(newRes);
        // setGlData(res);

        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.organisation_id,
            label: `${obj?.organisation_name} (${obj?.organisation_code})`,
            code: obj?.organisation_code,
            gl_ids: obj?.gl_id,
            cost_ids: obj?.cost_center_id,
            max_bill_date: obj?.max_bill_date,
          };
        });
        let pp = res.filter(
          (ele, ind) =>
            ind === res.findIndex((elem) => elem.label === ele.label)
        );
        setpettyOrgList(pp);
      })
      .catch((error) => {});
  };

  // const getEmployee = (empid) => {
  //   TravelListingAPI.getEmployee2(`employee_code=` + empid)
  //     .then((response) => {
  //       setotherExpUserProfileData(response?.data?.dataList?.result[0]);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getModeList = async () => {
    await TravelListingAPI.getModeList()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.mode}`,
          };
        });
        setModeList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getPettyUser = async (id) => {
    await TravelListingAPI.getPettyUser(id)
      .then((response) => {
        let res = response?.data?.dataList?.is_exist;
        setPettyUser(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getCostList = async (data, org_id, userName) => {
  //   await TravelListingAPI.getGlListOtherExp(data, org_id, userName)
  //     .then((response) => {
  //       let res = response?.data?.dataList?.result[0]?.cost_center_id?.map(
  //         (obj) => {
  //           return {
  //             value: obj.id,
  //             label: `${obj?.cost_center_name}`,
  //           };
  //         }
  //       );
  //       setCostCenterData(res);
  //     })
  //     .catch((error) => {});
  // };

  const getGlListOtherExp = async (gl_type, organisation_id, user_id) => {
    await TravelListingAPI.getGlListOtherExp(gl_type, organisation_id, user_id)
      .then((response) => {
        let res = response?.data?.dataList?.gl_id?.map((obj) => {
          return {
            value: obj.gl_id,
            label:
              `${obj?.gl_desc} (${obj?.gl_account})` +
              (obj?.gl_is_blocked ? "(Blocked)" : ""),
            code: obj?.gl_account,
            gl_is_blocked: obj?.gl_is_blocked,
          };
        });
        setGlData(res);
      })
      .catch((error) => {});
  };

  const ListExceptionMaster = () =>
    // status,
    // is_picked,
    // pageNo,
    // pageSize,
    // sort_by,
    // paginate,
    // emp_code,
    // org,
    // reqid,
    // search
    {
      // dispatch(setDisplayLoader("Display"));

      otherExpService
        .getExceptionMaster("")
        .then((ress) => {
          // console.log(`otherexp...`,response);
          // dispatch(setDisplayLoader("Hide"));
          // let data = response?.data?.result;
          // setVehicleData(response?.data?.dataList?.result);
          // let ress=null
          // console.log(`-->`,userGroup);
          // switch (userGroup._userGroup){
          //     case "ssc user":
          //         ress=response?.data?.dataList?.result.filter((data)=>{
          //             return !data.ssc_user_status
          //         })
          //         break
          //     case "ssc manager":
          //         ress=response?.data?.dataList?.result.filter((data)=>{
          //             return data.ssc_user_status==="A" && !data.ssc_manager_status
          //         })
          //         break
          //     default:
          //         break

          // }
          // postUserData["user_id"]=0
          // postUserData["opened_from"]=""
          // postUserData["opened_to"]=""
          // postUserData["from_date"]=""
          // postUserData["to_date"]=""

          console.log(`log..`, ress);
          toast.success("Data fetched successfully");
          // setotherExpData(ress?.data?.dataList?.result);
          // setCurrentPage(ress?.data?.dataList?.paginated_data?.currentPage)
          // setPageCount(ress?.data?.dataList?.paginated_data?.totalPages);
        })
        .catch((error) => {
          // dispatch(setDisplayLoader("Hide"));

          toast.error(error.response.data);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    };

  const getOrgList = async (organisation_id) => {
    await TravelListingAPI.getOrgList(organisation_id)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.name}`,
          };
        });
        setOrgList(res);
      })
      .catch((error) => {});
  };
  const todayDate = new Date();
  const previous = new Date(todayDate.getTime());
  previous.setDate(todayDate.getDate() - 1);
  const currentDate = moment(previous).format("YYYY-MM-DD");

  const pushCityId = (e, i) => {
    changeHandler(e.value, "gl_id", i);
    if (
      expenseData[i]?.gl_account != "754001" ||
      expenseData[i]?.gl_desc != "LOCAL CONVEYANCE" ||
      !["721008", "722010", "766044", "766045", "766046"].includes(
        String(expenseData[i]?.gl_account)
      )
    ) {
      expenseData[i].travel_by = "";
      expenseData[i].machine_no = "";
    }
    expenseData[i].gl_account = e.code;
    expenseData[i].gl_desc = e.desc;
  };
  const pushCostId = (e, i) => {
    changeHandler(e.value, "cost_center_id", i);
  };
  const pushTravelMode = (e, i) => {
    changeHandler(e.value, "travel_by", i);
  };

  const validateSSCFeature = () => {
    let errorExist = false;
    expenseData?.map((item, i) => {
      if (
        item?.bill_number === 0 ||
        item?.bill_date === "" ||
        item?.bill_amount === 0 ||
        item?.remarks === ""
      ) {
        errorExist = true;
      }
      return item;
    });
    return errorExist;
  };
  const addMoreExpense = (ele, i) => {
    // setAlertForAmount(false);

    if (handleValidate()) {
    } else {
      if (expenseData.length < 90) {
        const copyValue = JSON.parse(JSON.stringify(expenseData));
        const ele = {};
        ele.gl_id = "";
        // ele.gl_account= "";
        // ele.gl_desc= "";
        ele.bill_number = 0;
        ele.bill_date = "";
        ele.bill_amount = null;
        ele.remarks = "";
        copyValue.push(ele);
        setExpenseData(copyValue);
      } else {
        toast.error("max");
      }
    }
  };
  const saveOtherExpense = async (data) => {
    if (condition == "false") {
      condition = "true";
      await TravelListingAPI.saveOtherExpense(data)
        .then((response) => {
          if (response?.data.code == 200) {
            condition = "false";
            // resetState();
            navigate("/other-listing");
            toast.success("Travel Other Expenses Added Successfully");
            handleClose();
            onAddCallBack();
            // getReceiptDataList(false);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const updateDemand = async (id, data) => {
    if (condition == "false") {
      condition = "true";
      await TravelListingAPI.updateOtherExpense(id, data)
        .then((response) => {
          if (response.data.code == 200) {
            navigate("/other-listing");
            toast.success("Other Expense Updated Successfully");
            handleClose();
            onAddCallBack();
            condition = "false";
            // onAddCallBack();
            // onClose();
            // getAllCashbookList(false);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const isDataExist = (userId, type) => {
    // if(!otherExpUserProfileData?.profit_center ){

    //   toast.error("You can not submit the request as profit center is not available",{
    //     autoClose:5000
    //   })
    //   return

    // }

    if (lastRecentBill?.back_date_id) {
      TravelListingAPI.updateTravelBackDateInfo(
        {
          is_active: false,
          user_id: userData?.id,
          check_type: "other",
          from_date: lastRecentBill?.back_date_from,
          to_date: lastRecentBill?.back_date_to,
          opened_from: lastRecentBill?.back_date_opened_from,
          opened_to: lastRecentBill?.back_date_opened_to,
        },
        lastRecentBill?.back_date_id
      )
        .then((resp) => {
          // toast.success("back date deactivated");
          allJvData(type);
        })
        .catch((err) => {
          toast.error("back date err");
        });
    } else {
      TravelListingAPI.isExistsValue(userId)
        .then((res) => {
          let data = res?.data?.dataList?.is_exist;
          setIsExist(data);
          if (
            formData?.status == "R" ||
            data == false ||
            (data == true && formData?.expense_type == "petty")
          ) {
            allJvData(type);
          } else {
            setShowExistsError(res?.data?.message);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };
  const removeJvDetailRow = (idx) => {
    const newExpDetails = expenseData.filter((item, i) => i !== idx);
    setExpenseData(newExpDetails);
  };

  const changeHandler = (value, name, index) => {
    if (name == "bill_date") {
      console.log(`selected bill date=>`, value);
      // value=value?.toISOString()?.split('T')[0]
      value = moment(value)?.format("YYYY-MM-DD");
      // console.log(`selected bill date as iso=>`,value.toISOString().split('T')[0]);
      // console.log(`selected bill date with moment parse=>`,moment.parseZone(value).format("YY-MM-DDDD"));
      // console.log(`selected bill date without moment parse=>`,moment(value).format("YY-MM-DDDD"));
    }

    if (name == "bill_amount" && value >= 1000000) {
    } else {
      let fd = [...expenseData];
      fd[index][name] = value;
      setExpenseData(fd);
    }
  };

  function limitDecimalPlaces(input) {
    let value = input.target.value.toString();

    if (value.includes(".")) {
      let decimalPart = value.split(".")[1];
      if (decimalPart.length > 2) {
        value = parseFloat(value).toFixed(2);
        input.target.value = value;
      }
    }
    return input.target.value;
  }

  function jvDetail() {
    const arrayGST = [];

    if (viewLeaveData?.expense_data) {
      for (let i = 0; i < viewLeaveData?.expense_data.length; i++) {
        arrayGST.push({
          bill_amount: viewLeaveData?.expense_data[i]?.bill_amount,
          bill_desc: viewLeaveData?.expense_data[i]?.bill_desc,
          for_month_of: viewLeaveData?.expense_data[i]?.for_month_of,
          bill_date: viewLeaveData?.expense_data[i].bill_date,
          bill_number: viewLeaveData?.expense_data[i].bill_number,
          remarks: viewLeaveData?.expense_data[i]?.remarks,
          paid_by: viewLeaveData?.expense_data[i].paid_by,
          cost_center_code:
            viewLeaveData?.expense_data[i]?.cost_center_code == null ||
            viewLeaveData?.expense_data[i].cost_center_code === undefined
              ? "0"
              : viewLeaveData?.expense_data[i].cost_center_code,
          cost_center_id:
            viewLeaveData?.expense_data[i]?.cost_center_id == null ||
            viewLeaveData?.expense_data[i].cost_center_id === undefined
              ? null
              : viewLeaveData?.expense_data[i].cost_center_id,
          cost_center_id_name:
            viewLeaveData?.expense_data[i]?.cost_center_code == null ||
            viewLeaveData?.expense_data[i].cost_center_code === undefined
              ? "0"
              : viewLeaveData?.expense_data[i].cost_center_name,
          travel_by:
            viewLeaveData?.expense_data[i]?.travel_by == null ||
            viewLeaveData?.expense_data[i].travel_by === undefined
              ? expenseData.travel_by
              : viewLeaveData?.expense_data[i].travel_by,
          travel_by_name:
            viewLeaveData?.expense_data[i]?.travel_by == null ||
            viewLeaveData?.expense_data[i].travel_by === undefined
              ? "0"
              : viewLeaveData?.expense_data[i].travel_by_name,
          gl_id:
            viewLeaveData?.expense_data[i]?.gl_id == null ||
            viewLeaveData?.expense_data[i].gl_id === undefined
              ? "0"
              : viewLeaveData?.expense_data[i].gl_id,
          gl_desc:
            viewLeaveData?.expense_data[i]?.gl_id == null ||
            viewLeaveData?.expense_data[i].gl_id === undefined
              ? "0"
              : viewLeaveData?.expense_data[i].gl_desc,
          machine_no: viewLeaveData?.expense_data[i].machine_no,
          gl_account: viewLeaveData?.expense_data[i].gl_account,
          //   viewLeaveData?.expense_data[i]?.gl_id == null ||
          //   viewLeaveData?.expense_data[i].gl_id === undefined
          //     ? "0"
          //     : viewLeaveData?.expense_data[i].gl_account,
          glValue: {
            value:
              viewLeaveData?.expense_data[i]?.gl_id == null ||
              viewLeaveData?.expense_data[i].gl_id === undefined
                ? "0"
                : viewLeaveData?.expense_data[i].gl_id,
            label:
              viewLeaveData?.expense_data[i]?.gl_id == null ||
              viewLeaveData?.expense_data[i].gl_id === undefined
                ? "0"
                : `${viewLeaveData?.expense_data[i].gl_desc} `,
          },
          costCenterValue: {
            value:
              viewLeaveData?.expense_data[i]?.cost_center_code == null ||
              viewLeaveData?.expense_data[i].cost_center_code === undefined
                ? "0"
                : viewLeaveData?.expense_data[i].cost_center_code,
            label:
              viewLeaveData?.expense_data[i]?.cost_center_code == null ||
              viewLeaveData?.expense_data[i].cost_center_code === undefined
                ? "0"
                : viewLeaveData?.expense_data[i].cost_center_name,
          },
          // orgListData: {
          //   value:
          //     viewLeaveData?.organisation_id == null ||
          //     viewLeaveData?.organisation_id === undefined
          //       ? "0"
          //       : viewLeaveData?.organisation_id,
          //   label:
          //     viewLeaveData?.organisation_name == null ||
          //     viewLeaveData?.organisation_name === undefined
          //       ? "0"
          //       : `${viewLeaveData?.organisation_name} (${viewLeaveData?.organisation_code})`,
          // },
        });
      }
      setExpenseData(arrayGST);
    }
  }

  const confirmDialog = (type, body) => {
    confirmAlert({
      title: `Note:`,
      message: (
        <table>
          <tr>
            <td className="d-flex align-items-top mr-1">1.</td>
            <td>
              Ensure submitting all claims up to last invoice date in this
              request ID, you will not able to claim old claims in next request
              ID except an exception.
            </td>
          </tr>
          <tr>
            <td className="d-flex align-items-top mr-1">2.</td>
            <td>Attach myPi expense summary report with supporting invoices</td>
          </tr>
        </table>
      ),
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            isDataExist(userData?.id, "P");
          },
        },
        {
          label: "No",
        },
      ],
      // onClickOutside: () => {
      //   setFreezeCTA(false);
      // },
      // onKeypressEscape: () => {
      //   setFreezeCTA(false);
      // },
    });
  };

  const onSelectPettyOrg = async (
    cdata = "",
    idx = "",
    org_id = "",
    eVall = ""
  ) => {
    formData["organisation_id"] = eVall;
    //  let index = expenseData?.length -1
    //  let data = expenseData;
    //  data[index].glValue = ""
    if (expenseData.length > 0) {
      let data = JSON.parse(JSON.stringify(expenseData));
      let ndat = data?.map((_data, i) => {
        _data.glValue = "";
        _data["costCenterValue"] = "";
        _data["gl_id"] = null;
        _data.cost_center_id = null;

        // return true
        return _data;
      });
      setExpenseData(ndat);
    }

    pettyOrgList?.every((data) => {
      // setExpenseData([])
      if (data?.value == parseInt(eVall)) {
        let res = data?.gl_ids?.map((obj) => {
          return {
            value: obj.gl_id,
            desc: obj.gl_desc,
            label:
              `${obj?.gl_desc} (${obj?.gl_account})` +
              (obj?.gl_is_blocked ? "(Blocked)" : ""),
            code: obj?.gl_account,
            gl_is_blocked: obj?.gl_is_blocked,
          };
        });

        let newRes = data?.cost_ids?.map((obj) => {
          return {
            value: obj.cost_center_id,
            label:
              `${obj?.cost_center_name} (${obj?.cost_center_code})` +
              (obj?.cost_center_is_blocked ? "(Blocked)" : ""),
            cost_center_is_blocked: obj?.cost_center_is_blocked,
          };
        });
        setCostCenterData(newRes);
        setGlData(res);
        return false;
      }

      return true;
    });

    //  if(expenseData.length>0){
    //     let ndat=expenseData?.map((_data,i)=>{

    //         console.log(`onselect..`);

    //         _data.glValue=""
    //         _data["costCenterValue"] =""
    //         _data["gl_id"]=""
    //         // _data.cost_center_id=undefined
    //         // _data.cost_center_id_name=undefined

    //         return true
    //       })

    //     // setExpenseData(ndat)

    //   }
  };

  function getCurrentFiscalYear() {
    //get current date
    var today = new Date();

    //get current month
    var curMonth = today.getMonth();

    var fiscalYr = "";
    if (curMonth >= 3) {
      //
      var nextYr1 = (today.getFullYear() + 1).toString();
      fiscalYr =
        today.getFullYear().toString() +
        "-" +
        nextYr1.charAt(2) +
        nextYr1.charAt(3);
    } else {
      var nextYr2 = today.getFullYear().toString();
      fiscalYr =
        (today.getFullYear() - 1).toString() +
        "-" +
        nextYr2.charAt(2) +
        nextYr2.charAt(3);
    }

    console.log(
      `fiscal year`,
      `${fiscalYr.split("-")[0]}-04-01`,
      `20${fiscalYr.split("-")[1]}-03-31`
    );

    // return fiscalYr.split("-")[0];
    return [
      `${fiscalYr.split("-")[0]}-04-01`,
      `20${fiscalYr.split("-")[1]}-03-31`,
    ];
  }

  const getStartDateExpense = (
    type = "",
    userDetails,
    backDatedException,
    billDate
  ) => {
    console.log(
      `newfunc=>max date`,
      billDate,
      `newfunc=>backDatedException`,
      backDatedException,
      `new func user doj=>`,
      userData?.joiningDate.date_of_joining
    );
    const userId = userDetails.id;
    const currentMonth = new Date().getMonth() + 1;
    const currentDate = new Date();
    let year = currentDate.getFullYear();
    if (currentMonth < 4 || currentDate <= new Date(`${year}-04-02`)) {
      year -= 1;
    }

    let startDate = new Date(`${year}-04-01`);
    let endDate = new Date();
    if (currentDate.getDate() === 2 && currentMonth === 4) {
      endDate = currentDate;
    } else {
      endDate.setDate(endDate.getDate() - 1);
    }

    if (billDate) {
      //max bill date
      const expenseDate = new Date(billDate);
      expenseDate.setDate(expenseDate.getDate() + 1);

      if (expenseDate > startDate) {
        startDate = expenseDate;
      }
    } else {
      // no max bill date

      if (
        moment(
          moment(userData?.joiningDate.date_of_joining).format("YYYY-MM-DD")
        ).isSameOrAfter(getCurrentFiscalYear()[0]) &&
        moment(
          moment(userData?.joiningDate.date_of_joining).format("YYYY-MM-DD")
        ).isSameOrBefore(getCurrentFiscalYear()[1])
      ) {
        startDate = new Date(userData?.joiningDate.date_of_joining);
      }
    }

    if (backDatedException && backDatedException.back_date_from) {
      startDate = new Date(backDatedException.back_date_from);
      endDate = new Date(backDatedException.back_date_to);
      if (endDate < new Date()) {
        // if (billDate) {
        let startexpenseDate = startDate;
        // startexpenseDate.setDate(startexpenseDate.getDate() + 1);
        startexpenseDate.setDate(startexpenseDate.getDate());
        startDate = startexpenseDate;
        // }
        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
      }
    }

    if (type === "Bulk") {
      return {
        startdate: startDate.toISOString().split("T")[0],
        enddate: endDate.toISOString().split("T")[0],
      };
    }

    // return `${startDate.toISOString().split('T')[0]}__${endDate.toISOString().split('T')[0]}`;

    return [
      `${startDate.toISOString().split("T")[0]}`,
      `${endDate.toISOString().split("T")[0]}`,
    ];
  };

  const getOtherExpensePetty = (
    search,

    paginate
  ) => {
    userGl
      .getOtherExpensePetty(`search=${search}&paginate=${paginate}`)
      .then((response) => {
        set_totalDraftRecords((pre) => ({
          ...pre,
          pettyCard:
            response?.data?.dataList?.result?.length > 0
              ? response?.data?.dataList?.result[0].card_no
              : null,
          pettyCardVendor:
            response?.data?.dataList?.result?.length > 0
              ? response?.data?.dataList?.result[0].vendor_name
              : null,
        }));
        // setUserCardMap(response?.data?.dataList?.result);
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    // getCostList("petty", userData?.organisation_id, userData?.id);
    // getEmployee(userData?.username);
    getModeList();
    getOtherExpensePetty(userData?.username, true);

    TravelListingAPI.getBackDateList(
      `user_id=${userData?.id}&check_type=other&sort_by=-id&is_active=true`
    )
      .then((resp) => {
        setUserBackDate(resp?.data?.dataList?.result);
        if (
          resp?.data?.dataList?.result?.length > 0 &&
          resp?.data?.dataList?.result[0].is_active
        ) {
          setlastRecentBill((pre) => ({
            ...pre,
            back_date_id: resp?.data?.dataList?.result[0]?.id,
            back_date_from: resp?.data?.dataList?.result[0]?.from_date,
            back_date_to: resp?.data?.dataList?.result[0]?.to_date,
            back_date_opened_from: resp?.data?.dataList?.result[0]?.opened_from,
            back_date_opened_to: resp?.data?.dataList?.result[0]?.opened_to,
          }));
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });

    // TravelListingAPI.getBackDateList(
    //   `user_id=${userData?.id}&check_type=other&sort_by=-id`
    // )
    //   .then((resp) => {
    //     setlastRecentBill((pre) => ({
    //       ...pre,
    //       back_date_id: resp?.data?.dataList?.result[0]?.id,
    //       back_date_from: resp?.data?.dataList?.result[0]?.from_date,
    //       back_date_to: resp?.data?.dataList?.result[0]?.to_date,
    //       back_date_opened_from: resp?.data?.dataList?.result[0]?.opened_from,
    //       back_date_opened_to: resp?.data?.dataList?.result[0]?.opened_to,
    //     }));
    //   })
    //   .catch((error) => {
    //     console.log(
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       "failed"
    //     );
    //   });

    TravelListingAPI.getOtherExpenseList(
      `sort_by=-id&user_id=${userData?.id}&expense_type=Other`
    )
      .then((response) => {
        // lastRecentBill["max_bill_date"] =
        //   response?.data?.dataList?.max_bill_date;
        setlastRecentBill((pre) => ({
          ...pre,
          max_bill_date: response?.data?.dataList?.max_bill_date,
        }));
        // console.log('fiskk setting max bill date.', response?.data?.dataList?.max_bill_date,lastRecentBill?.max_bill_date);

        response?.data?.dataList?.result.every((data) => {
          if (
            data?.expense_type?.toLowerCase() == "other" &&
            data?.status == "A"
          ) {
            // lastRecentBill["other"] = data;
            setlastRecentBill((pre) => ({
              ...pre,
              other: data,
            }));

            return false;
          }
          return true;
        });

        response?.data?.dataList?.result.every((data) => {
          if (data?.expense_type.toLowerCase() == "petty") {
            // lastRecentBill["petty"] = data;

            setlastRecentBill((pre) => ({
              ...pre,
              petty: data,
            }));

            return false;
          }
          return true;
        });

        // setlastRecentBill(response.data)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  }, []);

  useEffect(() => {
    TravelListingAPI.getOtherExpenseList(
      `user_id=${userData?.id}&status=D&sort_by=-id&expense_type=${formData?.expense_type}`
    )
      .then((response) => {
        set_totalDraftRecords((pre) => ({
          ...pre,
          total: response?.data?.dataList?.result?.length,
          expenseType: response?.data?.dataList?.result[0]?.expense_type,
        }));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });

    if (viewLeaveData) {
    } else if (formData?.expense_type == "Other") {
      formData["organisation_id"] = userData?.organisation_id;

      getGlListOtherExp("", "", Number(userData?.id));
    } else if (formData?.expense_type == "petty") {
      setGlData([]);
      formData["organisation_id"] = "";
      getGlList(
        "",
        1,
        10,
        "-id",
        true,
        Number(userData?.id),
        "Petty",
        "",
        true
      );
    }
  }, [formData]);

  useEffect(() => {
    if (viewLeaveData) {
      // let dataGl = viewLeaveData?.expense_data?.map((value, i) => {
      //   return {
      //     value: value?.gl_id,
      //     label: `${value?.gl_desc} (${value?.gl_account})`,
      //   };
      // });
      // setGlValue(dataGl);
      Object.keys(viewLeaveData).forEach((item) => {
        formData[item] = viewLeaveData[item];
        expenseData[item] = viewLeaveData.expense_data[item];
      });

      jvDetail();

      if (viewLeaveData?.expense_type == "Other") {
        // viewLeaveData["organisation_id"]=userData?.organisation_id

        if (glData?.length > 0) {
        } else getGlListOtherExp("", "", Number(userData?.id));
      } else if (viewLeaveData?.expense_type == "petty") {
        // setGlData([]);
        // viewLeaveData["organisation_id"]=""

        if (pettyOrgList?.length > 0 && glData?.length === 0) {
          pettyOrgList?.every((data) => {
            if (data?.code == viewLeaveData?.organisation_code) {
              let res = data?.gl_ids?.map((obj) => {
                return {
                  value: obj.gl_id,
                  desc: obj.gl_desc,
                  label:
                    `${obj?.gl_desc} (${obj?.gl_account})` +
                    (obj?.gl_is_blocked ? "(Blocked)" : ""),
                  code: obj?.gl_account,
                  gl_is_blocked: obj?.gl_is_blocked,
                };
              });
              setGlData(res);

              let newRes = data?.cost_ids?.map((obj) => {
                return {
                  value: obj.cost_center_id,
                  label:
                    `${obj?.cost_center_name} (${obj?.cost_center_code})` +
                    (obj?.cost_center_is_blocked ? "(Blocked)" : ""),
                  cost_center_is_blocked: obj?.cost_center_is_blocked,
                };
              });
              setCostCenterData(newRes);
              return false;
            }

            return true;
          });
        } else {
          if (glData?.length != 0) {
          } else
            getGlList("", 1, 10, "-id", true, Number(userData?.id), "", "");
        }
      }
    }
  }, [viewLeaveData, pettyOrgList, glData]);

  // const getGlListMain = async (
  //   search,
  //   page_no,
  //   page_size,
  //   sort_by,
  //   paginate,
  //   user_id,
  //   gl_type,
  //   organisation_id
  // ) => {
  //   await TravelListingAPI.getGlListOtherExpNew(
  //     handleKey(
  //       search,
  //       page_no,
  //       page_size,
  //       sort_by,
  //       paginate,
  //       user_id,
  //       gl_type,
  //       organisation_id
  //     )
  //   )
  //     .then((response) => {

  //       //
  //       console.log(`last_`,response?.data?.dataList?.result[0]?.start_date      );

  //       setlastRecentBill((pre)=>({
  //         ...pre,

  //         back_date_from:response?.data?.dataList?.result[0]?.start_date      ,
  //         back_date_to:response?.data?.dataList?.result[0]?.end_date
  //       }))

  //       console.log('last_rec',lastRecentBill);

  //     })
  //     .catch((error) => {

  //     });
  // };

  useEffect(() => {
    // getGlListMain("", 1, 10, "-id", true, Number(userData?.id), "", userData?.organisation_id);

    getPettyUser(userData?.id);
    getOrgList(userData?.organisation_id);
  }, []);

  const deleteAttachment = (id) => {
    console.log(`att`, id);
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (viewLeaveData == undefined) {
      let obj = file.filter((ele) => ele.name !== id);
      // let obj = {};
      // Object.keys(file)
      //   .filter((del) => {
      //     if (file[del].name !== id) {
      //       return true;
      //     }
      //   })
      //   .map((item) => {
      //     obj[item] = file[item];
      //   });
      document.getElementById("policyattachment").value = "";
      setfiles(obj);
    } else {
      let deleteId = [];
      // if (attachment.length != 0) {
      let data2 = [];
      attachment.filter((del) => {
        if (
          del.attachment_path?.substring(
            del.attachment_path.lastIndexOf("/") + 1
          ) !== id
        ) {
          data2.push(del);
        } else {
          deleteattachment.push(del.id);
        }
      });
      setAttachment(data2);
      document.getElementById("policyattachment").value = "";
      // } else {
      let obj = file.filter((ele) => ele.name !== id);
      // let obj = {};
      // Object.keys(file)
      //   .filter((del) => {
      //     console.log(`delllll`, del);
      //     if (file[del].name !== id) {
      //       return true;
      //     }
      //   })
      //   .map((item) => {
      //     obj[item] = file[item];
      //   });
      document.getElementById("policyattachment").value = "";
      setfiles(obj);
      // }
    }
  };

  useEffect(() => {
    let sum = 0;
    expenseData?.map((value, i) => {
      sum = Number(value?.bill_amount) + Number(sum);
    });
    setTotalAmount(sum?.toFixed(2));
  }, [expenseData]);

  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ||
                data2.name.includes(".csv") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name.includes(".msg") ? (
                <img
                  src={"/images/policy-icon/msg.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            {/* {uploadDataIm.map((imdata)=>(
                          imdata?.original_name
              ))}
               */}
            <div style={{ wordBreak: "break-all" }}>{data2?.name}</div>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      );
    }
    return data;
  };

  useEffect(() => {
    setAttachment(viewLeaveData?.attachment);
  }, [viewLeaveData]);

  return (
    <div>
      {showExistsError !== "" && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>
          {showExistsError}
        </Alert>
      )}
      <div class="px-4 pb-2">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h6 class="f-14 mt-2"></h6>
          </div>
          <div class="col-md-6">
            {/* <h6 class="f-14" style={{ marginLeft: "25px" }}>
              *Dear user please add less than or equal to 90 rows in one bill.
            </h6> */}
          </div>
          <div class="col-md-6">
            <h6 class="f-14 mt-2"></h6>
          </div>
        </div>

        <div class="bg-light px-3 pt-3 mb-3">
          <div class="row">
            <div class="col-md-12 form-group answerboxpollsuy">
              <div className="row">
                <div className="col-md-12"></div>
                <div className="col-md-1" style={{ marginRight: "17px" }}>
                  <label
                    class="logCheck d-inline-block mr-4"
                    style={{ fontSize: "12px" }}
                  >
                    Others
                    <input
                      type="radio"
                      name="expense_type_one"
                      disabled={!viewLeaveData ? false : true}
                      onChange={() => {
                        setFormData({
                          ...formData,
                          expense_type: "Other",
                        });
                        setExpenseData([
                          {
                            gl_id: "",
                            bill_number: "",
                            bill_date: "",
                            bill_amount: "",
                            remarks: "",
                            gl_code: 0,
                            gl_id_name: "",
                            gl_account: "",
                          },
                        ]);
                      }}
                      defaultChecked={true}
                      checked={formData?.expense_type === "Other"}
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div className="col-md-2 pr-0">
                  {pettyUser === true ? (
                    <label
                      class="logCheck d-inline-block"
                      style={{ fontSize: "12px" }}
                    >
                      Petty Expense
                      <input
                        type="radio"
                        name="expense_type_one"
                        disabled={!viewLeaveData ? false : true}
                        onChange={() => {
                          setFormData({
                            ...formData,
                            expense_type: "petty",
                          });
                          setGlValue();
                          setExpenseData([
                            {
                              gl_id: "",
                              bill_number: "",
                              bill_date: "",
                              bill_amount: 0,
                              remarks: "",
                              gl_id_name: "",
                              gl_code: 0,
                              gl_account: "",
                            },
                          ]);
                        }}
                        checked={formData?.expense_type === "petty"}
                      />
                      <span class="checkmark"></span>
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-8">
                  {formData?.expense_type == "petty" ? (
                    <div class="form-group innergroup">
                      <div className="row">
                        <div
                          className="col-md-6"
                          style={{ marginLeft: "-60px" }}
                        >
                          <Select
                            isDisabled={isEdit}
                            options={pettyOrgList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder={<div>Select Organisation</div>}
                            name="org_id"
                            onChange={(e) => {
                              onSelectPettyOrg("", "", "", e.value);
                              setOrgListData(e);
                              // pushCityId(e, i);
                              // setGlValue(e);
                              // setCodeFlag(true);
                              // setFormValidation({
                              //   ...formValidation,
                              //   organisation_id: "",
                              // });
                              // getGlList("Petty", e.value, userData?.id);
                            }}
                            value={
                              viewLeaveData
                                ? setValue(
                                    viewLeaveData.organisation_id,
                                    viewLeaveData.organisation_name
                                  )
                                : orgListData
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <table>
          {expenseData?.length > 0
            ? expenseData.map((expData, i) => {
                return (
                  <tr>
                    <td className="pr-3 d-flex justify-content-start">{`${
                      i + 1
                    }.`}</td>
                    <td>
                      <div class="bg-light px-3 pt-3 mb-3">
                        <div class="row">
                          {/* {`${i + 1}.`}  */}
                          {formData?.expense_type == "petty" ? (
                            <div class="col-md-4">
                              <div class="form-group innergroup d-flex flex-column">
                                <label>
                                  For Month Of
                                  <span class="text-danger">*</span>
                                </label>
                                {/* <input
                                type="date"
                                class="form-control"
                                name="for_month_of"
                                onChange={(e) => {
                                  changeHandler(e.target.value, "for_month_of", i);
                                  setFormValidation({
                                    ...formValidation,
                                    for_month_of: "",
                                  });
                                }}
                                value={expenseData[i]?.for_month_of}
                              /> */}
                                <DatePicker
                                  selected={
                                    expenseData[i]?.for_month_of &&
                                    expenseData[i]?.for_month_of !== ""
                                      ? new Date(expenseData[i]?.for_month_of)
                                      : ""
                                  }
                                  onChange={(date) => {
                                    changeHandler(date, "for_month_of", i);
                                    setFormValidation({
                                      ...formValidation,
                                      for_month_of: "",
                                    });
                                  }}
                                  maxDate={
                                    new Date(
                                      getStartDateExpense(
                                        formData?.expense_type,
                                        userData,
                                        lastRecentBill,
                                        formData?.expense_type
                                          ?.toLowerCase()
                                          ?.includes("other")
                                          ? lastRecentBill?.max_bill_date
                                          : orgListData?.max_bill_date
                                      )[1]
                                    )
                                    // moment(
                                    //   moment(todayDate).format("YYYY-MM-DD")
                                    // ).isSameOrAfter(
                                    //   lastRecentBill?.back_date_opened_from
                                    // ) &&
                                    // moment(
                                    //   moment(todayDate).format("YYYY-MM-DD")
                                    // ).isSameOrBefore(
                                    //   lastRecentBill?.back_date_opened_to
                                    // )
                                    //   ? new Date(lastRecentBill?.back_date_to)
                                    //   : new Date(currentDate)
                                  }
                                  minDate={
                                    new Date(
                                      getStartDateExpense(
                                        formData?.expense_type,
                                        userData,
                                        lastRecentBill,
                                        formData?.expense_type
                                          ?.toLowerCase()
                                          ?.includes("other")
                                          ? lastRecentBill?.max_bill_date
                                          : orgListData?.max_bill_date
                                      )[0]
                                    )
                                    // formData?.expense_type
                                    //   ?.toLowerCase()
                                    //   ?.includes("petty") &&
                                    // userBackDate?.length < 0 &&
                                    // orgListData?.max_bill_date
                                    //   ? new Date(
                                    //       moment
                                    //         .parseZone(
                                    //           orgListData?.max_bill_date
                                    //         )
                                    //         .add(1, "days")
                                    //         .format("YYYY-MM-DD")
                                    //     )
                                    //   : // moment(lastRecentBill?.back_date_opened_to).isSameOrBefore(moment(todayDate).format('YYYY-MM-DD'))
                                    //   moment(
                                    //       moment(todayDate).format("YYYY-MM-DD")
                                    //     ).isSameOrAfter(
                                    //       lastRecentBill?.back_date_opened_from
                                    //     ) &&
                                    //     moment(
                                    //       moment(todayDate).format("YYYY-MM-DD")
                                    //     ).isSameOrBefore(
                                    //       lastRecentBill?.back_date_opened_to
                                    //     )
                                    //   ? // new Date(lastRecentBill?.back_date_opened_from) <=
                                    //     //   new Date(todayDate) &&
                                    //     //   new Date(todayDate) <=
                                    //     //   new Date(lastRecentBill?.back_date_opened_to)

                                    //     new Date(lastRecentBill?.back_date_from)
                                    //   : formData?.expense_type
                                    //       ?.toLowerCase()
                                    //       ?.includes("other") &&
                                    //     lastRecentBill?.max_bill_date
                                    //   ? new Date(
                                    //       moment
                                    //         .parseZone(
                                    //           lastRecentBill?.max_bill_date
                                    //         )
                                    //         .add(1, "days")
                                    //         .format("YYYY-MM-DD")
                                    //     )
                                    //   : moment(
                                    //       moment(
                                    //         userData?.joiningDate
                                    //           .date_of_joining
                                    //       ).format("YYYY-MM-DD")
                                    //     ).isSameOrAfter(
                                    //       getCurrentFiscalYear()[0]
                                    //     ) &&
                                    //     moment(
                                    //       moment(
                                    //         userData?.joiningDate
                                    //           .date_of_joining
                                    //       ).format("YYYY-MM-DD")
                                    //     ).isSameOrBefore(
                                    //       getCurrentFiscalYear()[1]
                                    //     )
                                    //   ? new Date(
                                    //       userData?.joiningDate.date_of_joining
                                    //     )
                                    //   : new Date(getCurrentFiscalYear()[0])
                                  }
                                  placeholderText="DD-MM-YYYY"
                                  className="form-control"
                                  dateFormat="dd-MM-yyyy"
                                />

                                <div className="small text-danger">
                                  {i == Number(expenseData.length - 1) &&
                                    formValidation.for_month_of}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {formData?.expense_type == "petty" ? (
                            <div class="col-md-4">
                              <div class="form-group innergroup">
                                <label>
                                  Bill Description
                                  <span class="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  maxLength={30}
                                  class="form-control"
                                  name="bill_desc"
                                  onChange={(e) => {
                                    changeHandler(
                                      e.target.value,
                                      "bill_desc",
                                      i
                                    );
                                    setFormValidation({
                                      ...formValidation,
                                      bill_desc: "",
                                    });
                                  }}
                                  value={expenseData[i]?.bill_desc}
                                />

                                <div className="small text-danger">
                                  {i == Number(expenseData.length - 1) &&
                                    formValidation.bill_desc}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                {/* {formData?.expense_type != "petty" ? `${i + 1}. ` : ""} */}
                                GL Account<span class="text-danger">*</span>
                              </label>
                              <Select
                                options={glData}
                                isOptionDisabled={(option) =>
                                  option.gl_is_blocked
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="gl_id"
                                disabled={viewLeaveData?.status == "P"}
                                id={"gl_id" + i}
                                // openMenuOnClick=
                                onMenuOpen={() =>
                                  glData?.length < 1 &&
                                  formData?.expense_type?.toLocaleLowerCase() ==
                                    "petty" &&
                                  toast.error("Please select organisation")
                                }
                                // onClick={(e)=>
                                //   {
                                //     console.log(`runn`);

                                // }
                                onChange={(e) => {
                                  pushCityId(e, i);
                                  setGlValue(e);
                                  setCodeFlag(true);
                                  setFormValidation({
                                    ...formValidation,
                                    gl_id: "",
                                  });
                                }}
                                value={
                                  expenseData && codeFlag == false
                                    ? expenseData[i].gl_account
                                      ? setValue(
                                          expenseData[i].gl_id,
                                          expenseData[i].gl_desc +
                                            ` (${expenseData[i].gl_account})`
                                        )
                                      : ""
                                    : glData?.filter((each) => {
                                        if (
                                          each?.value === expenseData[i]?.gl_id
                                        ) {
                                          return true;
                                        }
                                      })?.length > 0
                                    ? glData?.filter((each) => {
                                        if (
                                          each?.value === expenseData[i]?.gl_id
                                        ) {
                                          return true;
                                        }
                                      })[0]
                                    : ""
                                }
                              />
                              <div className="small text-danger">
                                {i == Number(expenseData.length - 1) &&
                                  formValidation.gl_id}
                              </div>
                            </div>
                          </div>
                          {formData?.expense_type == "petty" ? (
                            <div class="col-md-4">
                              <div class="form-group innergroup">
                                <label>
                                  Cost Center<span class="text-danger">*</span>
                                </label>
                                <Select
                                  options={costCenterData}
                                  isOptionDisabled={(option) =>
                                    option.cost_center_is_blocked
                                  }
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  name="cost_center_id"
                                  onMenuOpen={() =>
                                    costCenterData?.length < 1 &&
                                    formData?.expense_type?.toLocaleLowerCase() ==
                                      "petty" &&
                                    toast.error("Please select organisation")
                                  }
                                  onClick={(e) => {
                                    costCenterData?.length < 1 &&
                                      toast.error("Please select organisation");
                                  }}
                                  onChange={(e) => {
                                    pushCostId(e, i);
                                    setCostCenterValue(e);
                                    setCodeFlagCost(true);
                                    setFormValidation({
                                      ...formValidation,
                                      cost_center_id: "",
                                    });
                                  }}
                                  // formData?.status == 'P' disabled
                                  value={
                                    expenseData && codeFlagCost == false
                                      ? expenseData[i].cost_center_code
                                        ? setValue(
                                            expenseData[i].cost_center_id,
                                            expenseData[i].cost_center_id_name +
                                              ` (${expenseData[i].cost_center_code})`
                                          )
                                        : ""
                                      : costCenterData.filter((each) => {
                                          if (
                                            each?.value ===
                                            expenseData[i]?.cost_center_id
                                          ) {
                                            return true;
                                          }
                                        })?.length > 0
                                      ? costCenterData.filter((each) => {
                                          if (
                                            each?.value ===
                                            expenseData[i]?.cost_center_id
                                          ) {
                                            return true;
                                          }
                                        })[0]
                                      : ""
                                  }
                                />
                                <div className="small text-danger">
                                  {i == Number(expenseData.length - 1) &&
                                    formValidation.cost_center_id}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {[
                            "721008",
                            "722010",
                            "766044",
                            "766045",
                            "766046",
                          ].includes(String(expenseData[i]?.gl_account)) && (
                            <div class="col-md-4">
                              <div class="form-group innergroup">
                                <label>
                                  Machine No.<span class="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  maxLength={20}
                                  class="form-control"
                                  name="machine_no"
                                  placeholder="Enter Machine No ..."
                                  onChange={(e) => {
                                    let num =
                                      e.target.value !== ""
                                        ? e.target.value
                                        : "";
                                    changeHandler(num, "machine_no", i);
                                    setFormValidation({
                                      ...formValidation,
                                      machine_no: "",
                                    });
                                  }}
                                  value={expenseData[i]?.machine_no}
                                />
                                <div className="small text-danger">
                                  {i == Number(expenseData.length - 1) &&
                                    formValidation.machine_no}
                                </div>
                              </div>
                            </div>
                          )}
                          {console.log(
                            `exp i`,
                            expenseData[i].gl_account,
                            expenseData[i].gl_desc
                          )}
                          {expenseData[i]?.gl_account == "754001" ||
                          expenseData[i]?.gl_desc == "LOCAL CONVEYANCE" ? (
                            <div class="col-md-4">
                              <div class="form-group innergroup">
                                <label>
                                  Travel By<span class="text-danger">*</span>
                                </label>
                                <Select
                                  options={optionsTravel}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  name="travel_by"
                                  onChange={(e) => {
                                    pushTravelMode(e, i);
                                    setCodeFlagTravel(true);
                                    setTravelModeName(e);
                                    setFormValidation({
                                      ...formValidation,
                                      travel_by: "",
                                    });
                                  }}
                                  value={
                                    expenseData && codeFlagTravel == false
                                      ? setValue(
                                          expenseData[i].travel_by,
                                          expenseData[i].travel_by
                                        )
                                      : expenseData[i]?.travelModeName
                                  }
                                />
                                <div className="small text-danger">
                                  {i == Number(expenseData.length - 1) &&
                                    formValidation.travel_by}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Bill No.<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                autoComplete="off"
                                maxlength="20"
                                class="form-control"
                                name="bill_number"
                                placeholder="Enter Bill No ..."
                                onChange={(e) => {
                                  changeHandler(
                                    e.target.value.trim(),
                                    "bill_number",
                                    i
                                  );
                                  setFormValidation({
                                    ...formValidation,
                                    bill_number: "",
                                  });
                                }}
                                value={expenseData[i]?.bill_number}
                              />
                              <div className="small text-danger">
                                {i == Number(expenseData.length - 1) &&
                                  formValidation.bill_number}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup d-flex flex-column">
                              <label>
                                Bill Date<span class="text-danger">*</span>
                              </label>
                              {/* {formData?.expense_type != "petty" ? ( */}
                              {/* <input
                              type="date"
                              maxLength={40}
                              class="form-control"
                              name="bill_date"
                              placeholder="Enter Bill No ..."
                              max={
                                new Date(lastRecentBill?.back_date_opened_from) <=
                                  new Date(todayDate) &&
                                  new Date(todayDate) <=
                                  new Date(lastRecentBill?.back_date_opened_to)
                                  ? lastRecentBill?.back_date_to
                                  : currentDate
                              }
                              min={
                                new Date(lastRecentBill?.back_date_opened_from) <=
                                  new Date(todayDate) &&
                                  new Date(todayDate) <=
                                  new Date(lastRecentBill?.back_date_opened_to)
                                  ? lastRecentBill?.back_date_from
                                  : lastRecentBill?.max_bill_date
                                    ? moment
                                      .parseZone(lastRecentBill?.max_bill_date)
                                      .add(1, "days")
                                      .format("YYYY-MM-DD")
                                    : new Date(
                                      userData?.joiningDate.date_of_joining
                                    ).getFullYear() == getCurrentFiscalYear()
                                      ? userData?.joiningDate.date_of_joining
                                      : `${getCurrentFiscalYear()}-04-01`
                              }
                              // min={

                              //   lastRecentBill?.back_date_from
                              //   ? lastRecentBill?.back_date_from

                              //   :
                              //   (todayDate.getMonth() == 4 &&
                              //   todayDate.getDate() < 3
                              //     ? todayDate.getDate() == 2
                              //       ? // ? lastRecentBill?.other
                              //         moment
                              //           .parseZone(
                              //             lastRecentBill?.other?.created_at
                              //           )
                              //           .format("YYYY-MM-DD")
                              //       : userData?.joiningDate.date_of_joining
                              //     : // : ""
                              //     todayDate.getMonth() >= 4 &&
                              //       todayDate.getDate() >= 3
                              //     ? // ? lastRecentBill?.other
                              //       lastRecentBill?.max_bill_date
                              //       ? moment
                              //           .parseZone(lastRecentBill?.max_bill_date)
                              //           .add(1, "days")
                              //           .format("YYYY-MM-DD")
                              //       : `${getCurrentFiscalYear()}-04-01`
                              //     : new Date(
                              //         userData?.joiningDate.date_of_joining
                              //       ).getMonth() >= 4 &&
                              //       Date(
                              //         userData?.joiningDate.date_of_joining
                              //       ).getDate() >= 1
                              //     ? userData?.joiningDate.date_of_joining
                              //     : "")

                              //   // : ""
                              // }

                              onChange={(e) => {
                                changeHandler(
                                  e.target.value.trim(),
                                  "bill_date",
                                  i
                                );
                                setFormValidation({
                                  ...formValidation,
                                  bill_date: "",
                                });
                              }}
                              value={expenseData[i]?.bill_date}
                            /> */}
                              {/* viewLeaveData
                                      ? setValue(
                                        viewLeaveData.organisation_id,
                                        viewLeaveData.organisation_name
                                      )
                                      : orgListData */}
                              {/* {console.log('eee', orgListData)}
                            {console.log('f10', lastRecentBill,"today",todayDate)}
                            {console.log('f10', new Date(lastRecentBill?.back_date_opened_from) <=
                                    new Date(todayDate) &&
                                    new Date(todayDate) <=
                                    new Date(lastRecentBill?.back_date_opened_to))}
                            {console.log("Compare dates = ",moment(moment(todayDate).format('YYYY-MM-DD')).isSameOrBefore(lastRecentBill?.back_date_opened_to))} */}

                              {/* {console.log(`fiskk`,getCurrentFiscalYear())}
                            {console.log(`fiskk lastRecentBill?.max_bill_date`,lastRecentBill?.max_bill_date)} */}

                              <DatePicker
                                selected={
                                  expenseData[i]?.bill_date !== ""
                                    ? new Date(expenseData[i]?.bill_date)
                                    : ""
                                }
                                onChange={(date) => {
                                  changeHandler(date, "bill_date", i);
                                  setFormValidation({
                                    ...formValidation,
                                    bill_date: "",
                                  });
                                }}
                                maxDate={
                                  new Date(
                                    getStartDateExpense(
                                      formData?.expense_type,
                                      userData,
                                      lastRecentBill,
                                      formData?.expense_type
                                        ?.toLowerCase()
                                        ?.includes("other")
                                        ? lastRecentBill?.max_bill_date
                                        : orgListData?.max_bill_date
                                    )[1]
                                  )
                                  // moment(
                                  //   moment(todayDate).format("YYYY-MM-DD")
                                  // ).isSameOrAfter(
                                  //   lastRecentBill?.back_date_opened_from
                                  // ) &&
                                  // moment(
                                  //   moment(todayDate).format("YYYY-MM-DD")
                                  // ).isSameOrBefore(
                                  //   lastRecentBill?.back_date_opened_to
                                  // )
                                  //   ? new Date(lastRecentBill?.back_date_to)
                                  //   : new Date(currentDate)
                                }
                                minDate={
                                  new Date(
                                    getStartDateExpense(
                                      formData?.expense_type,
                                      userData,
                                      lastRecentBill,
                                      formData?.expense_type
                                        ?.toLowerCase()
                                        ?.includes("other")
                                        ? lastRecentBill?.max_bill_date
                                        : orgListData?.max_bill_date
                                    )[0]
                                  )
                                  // formData?.expense_type
                                  //   ?.toLowerCase()
                                  //   ?.includes("petty") &&
                                  // userBackDate?.length < 0 &&
                                  // orgListData?.max_bill_date
                                  //   ? new Date(
                                  //       moment
                                  //         .parseZone(orgListData?.max_bill_date)
                                  //         .add(1, "days")
                                  //         .format("YYYY-MM-DD")
                                  //     )
                                  //   : // moment(lastRecentBill?.back_date_opened_to).isSameOrBefore(moment(todayDate).format('YYYY-MM-DD'))
                                  //   moment(
                                  //       moment(todayDate).format("YYYY-MM-DD")
                                  //     ).isSameOrAfter(
                                  //       lastRecentBill?.back_date_opened_from
                                  //     ) &&
                                  //     moment(
                                  //       moment(todayDate).format("YYYY-MM-DD")
                                  //     ).isSameOrBefore(
                                  //       lastRecentBill?.back_date_opened_to
                                  //     )
                                  //   ? // new Date(lastRecentBill?.back_date_opened_from) <=
                                  //     //   new Date(todayDate) &&
                                  //     //   new Date(todayDate) <=
                                  //     //   new Date(lastRecentBill?.back_date_opened_to)

                                  //     new Date(lastRecentBill?.back_date_from)
                                  //   : formData?.expense_type
                                  //       ?.toLowerCase()
                                  //       ?.includes("other") &&
                                  //     lastRecentBill?.max_bill_date
                                  //   ? new Date(
                                  //       moment
                                  //         .parseZone(
                                  //           lastRecentBill?.max_bill_date
                                  //         )
                                  //         .add(1, "days")
                                  //         .format("YYYY-MM-DD")
                                  //     )
                                  //   : moment(
                                  //       moment(
                                  //         userData?.joiningDate.date_of_joining
                                  //       ).format("YYYY-MM-DD")
                                  //     ).isSameOrAfter(
                                  //       getCurrentFiscalYear()[0]
                                  //     ) &&
                                  //     moment(
                                  //       moment(
                                  //         userData?.joiningDate.date_of_joining
                                  //       ).format("YYYY-MM-DD")
                                  //     ).isSameOrBefore(
                                  //       getCurrentFiscalYear()[1]
                                  //     )
                                  //   ? new Date(
                                  //       userData?.joiningDate.date_of_joining
                                  //     )
                                  //   : new Date(getCurrentFiscalYear()[0])
                                }
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                                dateFormat="dd-MM-yyyy"
                              />

                              {/* ) */}
                              {/* //  : ( */}
                              {/* <input
                                  type="date"
                                  maxLength={40}
                                  class="form-control"
                                  name="bill_date"
                                  placeholder="Enter Bill No ..."
                                  max={lastRecentBill?.back_date_to 
                                    ? lastRecentBill?.back_date_to
                                    
                                    : currentDate}

                                  min={

                                    lastRecentBill?.back_date_from 
                                    ? lastRecentBill?.back_date_from                               
                                    : 
                                    (todayDate.getMonth() == 4 &&
                                    todayDate.getDate() < 3
                                      ? todayDate.getDate() == 2
                                        ? // ? lastRecentBill?.petty
                                          moment
                                            .parseZone(
                                              lastRecentBill?.petty?.created_at
                                            )
                                            .format("YYYY-MM-DD")
                                        : ""
                                      : // : ""
                                      todayDate.getMonth() >= 4 &&
                                        todayDate.getDate() >= 3
                                      ? // ? lastRecentBill?.petty
                                        lastRecentBill?.max_bill_date
                                        ? moment
                                            .parseZone(lastRecentBill?.max_bill_date)
                                            .add(1, "days")
                                            .format("YYYY-MM-DD")
                                        : `${getCurrentFiscalYear()}-04-01`
                                      : new Date(
                                          userData?.joiningDate.date_of_joining
                                        ).getMonth() >= 4 &&
                                        Date(
                                          userData?.joiningDate.date_of_joining
                                        ).getDate() >= 1
                                      ? userData?.joiningDate.date_of_joining
                                      : "")
                                    // : ""
                                  }
                                  onChange={(e) => {
                                    changeHandler(
                                      e.target.value.trim(),
                                      "bill_date",
                                      i
                                    );
                                    setFormValidation({
                                      ...formValidation,
                                      bill_date: "",
                                    });
                                  }}
                                  value={expenseData[i]?.bill_date}
                                />
                              )} */}

                              <div className="small text-danger">
                                {i == Number(expenseData.length - 1) &&
                                  formValidation?.bill_date}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Bill Amount <span class="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                // pattern="\d*"
                                maxlength="6"
                                class="form-control"
                                name="bill_amount"
                                onWheel={(e) => e.target.blur()}
                                // onKeyDown={blockInvalidChar}
                                placeholder="Enter Bill Amount..."
                                onChange={(e) => {
                                  changeHandler(
                                    limitDecimalPlaces(e),
                                    "bill_amount",
                                    i
                                  );
                                  setFormValidation({
                                    ...formValidation,
                                    bill_amount: "",
                                  });
                                }}
                                value={expenseData[i]?.bill_amount}
                              />
                              <div className="small text-danger">
                                {i == Number(expenseData.length - 1) &&
                                  formValidation.bill_amount}
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Reason for Expense</label>
                              <input
                                type="text"
                                autoComplete="off"
                                maxLength={500}
                                class="form-control"
                                name="remarks"
                                placeholder="Enter Reason For Expense..."
                                onChange={(e) => {
                                  changeHandler(e.target.value, "remarks", i);
                                  setFormValidation({
                                    ...formValidation,
                                    remarks: "",
                                  });
                                }}
                                value={expenseData[i]?.remarks}
                              />
                              <div className="small text-danger">
                                {i == Number(expenseData.length - 1) &&
                                  formValidation.remarks}
                              </div>
                            </div>
                          </div>
                          {formData?.expense_type == "petty" && (
                            <div class="col-md-4">
                              <div class="form-group ">
                                <label>Paid By</label>
                                <select
                                  className="form-control newbgselect"
                                  value={expenseData[i].paid_by}
                                  onChange={(e) => {
                                    changeHandler(e.target.value, "paid_by", i);
                                    setFormValidation({
                                      ...formValidation,
                                      paid_by: "",
                                    });
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="self">Self</option>

                                  {totalDraftRecords?.pettyCard ? (
                                    <option value="company">
                                      Company ({totalDraftRecords?.pettyCard})
                                    </option>
                                  ) : (
                                    ""
                                  )}
                                </select>
                                <div className="small text-primary">
                                  {expenseData[i].paid_by === "company" &&
                                  formData?.expense_type == "petty" &&
                                  totalDraftRecords?.pettyCard
                                    ? totalDraftRecords?.pettyCardVendor
                                    : " "}
                                </div>
                                <div className="small text-danger">
                                  {i == Number(expenseData.length - 1) &&
                                    formValidation.paid_by}
                                </div>
                              </div>
                            </div>
                          )}

                          {expenseData?.length > 1 && (
                            <button
                              class="btn btnblankth text-danger p-0"
                              onClick={() => {
                                removeJvDetailRow(i);
                                setFormValidation({
                                  gl_id: "",
                                  bill_number: "",
                                  bill_date: "",
                                  bill_amount: "",
                                  remarks: "",
                                });
                              }}
                            >
                              <i class="far fa-trash-alt"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            : ""}
        </table>

        <div className="container">
          <span className="font-weight-bold">
            Total Bill Amount: {totalAmount}
          </span>
        </div>

        <div class="text-right">
          <button
            class="btn btnblanktd text-primary"
            onClick={() => {
              addMoreExpense(expenseData);
              handleValidate();
            }}
          >
            <i class="far fa-plus-square mr-2"></i> Add More
          </button>
        </div>

        <div class="row">
          <div class="col-md-4 d-flex flex-column mt-3">
            <h5 class="text-theme pb-2 mb-3">
              <strong>
                Attachments<span class="text-danger">*</span>
              </strong>
            </h5>

            <div class="form-group mt-auto mb-0 innergroup position-relative modaldefaultclose ">
              <label>
                Attach File<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control bg-white"
                disabled
                value={
                  // edit === true
                  // ? editPolicy?.logo
                  uploadDataIm?.logo?.original_name
                }
                placeholder="(jpeg, png, jpg, pdf, msg, doc, docx, xlsx, xlsb)"
              />
              <div class="upload-btn-wrapper up-loposition">
                <button class="uploadBtn">Browse</button>
                <input
                  type="file"
                  title=""
                  id="policyattachment"
                  multiple
                  name="attachment_path"
                  // accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                  onChange={(e) => onProfileChange(e, "attachment_path")}
                />
              </div>
              <p>
                Selected files{" "}
                <span>{/* {uploadDataIm[0]?.original_name} */}</span>
              </p>
            </div>
            <div>
              {attachment != undefined && attachment.length != 0 ? (
                <>
                  <div class="d-flex">
                    {attachment?.map((Data) => (
                      <div class="uploaded-image mr-1">
                        <i class="" style={{ width: "20px" }}>
                          <a
                            href={Data?.attachment_path}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            {Data?.attachment_path?.includes(".pdf") ? (
                              <img
                                src={"/images/policy-icon/pdf.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".xlsx") ? (
                              <img
                                src={"/images/policy-icon/xlsx.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".xls") ? (
                              <img
                                src={"/images/policy-icon/xlsx.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".doc") ? (
                              <img
                                src={"/images/policy-icon/docs.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".docs") ||
                              Data?.attachment_path.includes(".csv") ? (
                              <img
                                src={"/images/policy-icon/docs.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".pptx") ? (
                              <img
                                src={"/images/policy-icon/ppt.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data.attachment_path.includes(".mp4") ? (
                              <img
                                src={"/images/policy-icon/video.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".msg") ? (
                              <img
                                src={"/images/policy-icon/msg.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : (
                              <img
                                src={Data.attachment_path}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            )}
                          </a>
                        </i>

                        <div style={{ wordBreak: "break-all" }}>
                          {Data?.attachment_path?.substring(
                            Data?.attachment_path?.lastIndexOf("/") + 1
                          )}
                        </div>
                        <button
                          type="button"
                          onClick={(e) => {
                            deleteAttachment(
                              Data?.attachment_path?.substring(
                                Data?.attachment_path?.lastIndexOf("/") + 1
                              )
                            );
                          }}
                          className="btn btn-blanktd text-danger ml-2"
                        >
                          <i className="far fa-trash-alt text-danger"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )}

              {Object.keys(file).length !== 0 && (
                <div className="d-flex">{attachments123()}</div>
              )}
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="row">
            <div class="col-md-12 mt-3 text-center">
              <button
                class="btn btn-outline-secondary mr-2"
                onClick={() => {
                  window.location.href = "/other-listing";
                }}
              >
                Back
              </button>
              {console.log(
                "oo",
                viewLeaveData,
                viewLeaveData?.status?.toLowerCase()
              )}
              {viewLeaveData !== undefined &&
              !(
                viewLeaveData?.status
                  ?.toLowerCase()
                  .includes("ssc sent back") || viewLeaveData?.status == "R"
              ) &&
              isEdit == true ? (
                <button
                  class="btn btn-outline-primary mr-2"
                  type="submit"
                  onClick={() => {
                    allJvData("D");
                  }}
                >
                  Update
                </button>
              ) : (
                !viewLeaveData &&
                !viewLeaveData?.status
                  ?.toLowerCase()
                  .includes("ssc sent back") && (
                  <button
                    class="btn btn-outline-primary mr-2"
                    type="submit"
                    onClick={() => {
                      allJvData("D");
                    }}
                  >
                    Save
                  </button>
                )
              )}

              {showLeaveForm == true && isEdit == false ? (
                ""
              ) : (
                <button
                  class="btn btn-primary-inner bpi-main"
                  onClick={validate2}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpenseTravel;
