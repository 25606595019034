import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getBusinessList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/business_units?${queryParm}`);
};

// export const getBranchFormList = async (queryParm) => {
//   return axios.get(`${AUTH_BASE_URL}/business_units?${queryParm}`);
// };
// export const getBusinessList = async (

//   search,
//   pageNo,
//   pageSize,
//   sortBy,
//   paginate
// ) => {
//   return axios.get(
//     `${AUTH_BASE_URL}/business_units?search=${search}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}`
//   );
// };
export const getBranchFormList = async (
  pageNo,
  pageSize,
  sortBy,
  search,
  paginate,
  org,
  dept,
  stats
) => {
  return axios.get( 
    `${AUTH_BASE_URL}/business_units?search=${search}${
      org == undefined || org == "" || org == null
        ? ""
        : `&organisation_id=${org}`
    }${
      dept == undefined || dept == "" || dept == null
        ? ""
        : `&department_id=${dept}`
    }${
      stats == undefined || stats == "" || stats == null
        ? ""
        : `&status=${stats}`
    }&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}`
  );
};
export const deleteBranch = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/business_units`,
    method: "DELETE",
    data: id,
  });
};

export const saveBranch = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/business_units`, data);
};
export const updateBranch = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/business_units`, data);
};
export const getOrganisation = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/organisations?paginate=${paginate}&sort_by=name`
  );
};
export const getAllPermission = async (data) => {
  return axios.get(
    `${AUTH_BASE_URL}/permissions/get-my-url-permission?module_end_point=${data}`
  );
};
export const getCountryList = async () => {
  return axios.get(`${AUTH_BASE_URL}/country`);
};

export const getStateList = async (country_id) => {
  return axios.post(`${AUTH_BASE_URL}/state/list/${country_id}`);
};
export const getCityList = async (state_id) => {
  return axios.post(`${AUTH_BASE_URL}/city/drop-down/city/${state_id}`);
};
export const isBranchExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/business_units/exists`, isData);
};

export const getAllBranch = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/business_units?paginate=${paginate}&sort_by=name`
  );
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/business_units/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getEmployeeList = async (abortSignal) => {
  return axios.get(`${AUTH_BASE_URL}/users/get-user-details-new?bussiness_unit_id=0`, {signal: abortSignal});
};
