import React, { useState, useEffect } from "react";
import Sort from "../../assets/images/sort.png";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import * as CreService from "../../service/creServices.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCreInstrument = (props) => {
  const { addnewshow, addnewclose, onAddCallBack } = props;
  const [creData, setCreData] = useState([]);
  const [instrumentType, setInstrumentType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [instrumentValue, setInstrumentValue] = useState();
  const [locationType, setLocationType] = useState([]);
  const [locationValue, setLocationValue] = useState();
  const [makingType, setMakingType] = useState([]);
  const [makingValue, setMakingValue] = useState();
  const [buildingValue, setBuildingValue] = useState();

  let condition = "true";

  const [creInstrumentData, setCreInstrumentData] = useState({
    instrument_id: 0,
    manufacturer_id: 0,
    location_id: 0,
    building: "",
    asset_code: "",
    issue_problem: "",
    status: "WIP",
  });
  const [formValidation, setFormValidation] = useState({
    instrument_id: "",
    manufacturer_id: "",
    location_id: "",
    building: "",
    asset_code: "",
    issue_problem: "",
    // status: "",
  });
  const options = [
    { value: "AKRC", label: "AKRC" },
    { value: "PPSRC", label: "PPSRC" },
  ];
  const pushInst = (e) => {
    setInstrumentValue(e);
    setCreInstrumentData({
      ...creInstrumentData,
      instrument_id: e.value,
    });
    getMakingList(e.value);
  };
  const pushInstLocation = (e) => {
    setLocationValue(e);
    setCreInstrumentData({
      ...creInstrumentData,
      location_id: e.value,
    });
  };
  const pushManufactureId = (e) => {
    setMakingValue(e);
    setCreInstrumentData({
      ...creInstrumentData,
      manufacturer_id: e.value,
    });
  };
  const pushBuildingId = (e) => {
    setBuildingValue(e);
    setCreInstrumentData({
      ...creInstrumentData,
      building: e.value,
    });
  };

  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };

    if (!creInstrumentData.instrument_id) {
      isError = true;
      formerr.instrument_id =
        "*Type of the Instrument/Utilities No. is required ";
      setFormValidation(formerr);
    }
    if (!creInstrumentData.manufacturer_id) {
      isError = true;
      formerr.manufacturer_id =
        "*Make of the Instrument/Utilities is required ";
      setFormValidation(formerr);
    }
    if (!creInstrumentData.location_id) {
      isError = true;
      formerr.location_id = "Location is required";
      setFormValidation(formerr);
    }
    if (!creInstrumentData.building) {
      isError = true;
      formerr.building = "*Building is required";
      setFormValidation(formerr);
    }
    if (!creInstrumentData.asset_code) {
      isError = true;
      formerr.asset_code = "*Asset Code is required";
      setFormValidation(formerr);
    }
    if (!creInstrumentData.issue_problem) {
      isError = true;
      formerr.issue_problem = "*Document Problem is Description required";
      setFormValidation(formerr);
    }
    return isError;
  };

  const getInstrumentType = async () => {
    await CreService.getInstrumentType()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.description}`,
          };
        });
        setInstrumentType(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getMakingList = async (id) => {
    await CreService.getMakingList(id)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.manufacturer_id,
            label: `${obj?.manufacturer_name}`,
          };
        });
        setMakingType(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getInstrumentLocation = async () => {
    await CreService.getInstrumentLocation()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.location}`,
          };
        });
        setLocationType(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCreList = (paginate) => {
    CreService.getCreList(paginate)
      .then((response) => {
        setCreData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveCreInstrument = async () => {
    if (condition == "true") {
      setIsLoading(true);
      condition = "false";
      await CreService.saveCreInstrument(creInstrumentData)
        .then((response) => {
          setIsLoading(false);
          condition = "true";
          if (response.data.code == 200) {
            toast.success("Cre Instrument Saved Successfully");
            onAddCallBack();
            addnewclose();
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setIsLoading(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const handleValidateForm = () => {
    if (handleValidate()) return false;
    saveCreInstrument();
  };
  useEffect(() => {
    getInstrumentType();
    getInstrumentLocation();
  }, []);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Service Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Type of the Instrument/Utilities
                      <span class="text-danger">*</span>
                    </label>
                    <Select
                      options={instrumentType}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="instrument_id"
                      onChange={(e) => {
                        pushInst(e);
                        setFormValidation({
                          ...formValidation,
                          instrument_id: "",
                        });
                      }}
                      value={instrumentValue}
                    />
                    <div className="small text-danger">
                      {formValidation.instrument_id}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Make of the Instrument/Utilities
                      <span class="text-danger">*</span>
                    </label>
                    <Select
                      options={makingType}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="manufacturer_id"
                      onChange={(e) => {
                        pushManufactureId(e);
                        setFormValidation({
                          ...formValidation,
                          manufacturer_id: "",
                        });
                      }}
                      value={makingValue}
                    />
                    <div className="small text-danger">
                      {formValidation.manufacturer_id}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Asset Code of the Instrument
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Asset Code of the Instrument"
                      name="asset_code"
                      maxLength={15}
                      onChange={(e) => {
                        setCreInstrumentData({
                          ...creInstrumentData,
                          asset_code: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          asset_code: "",
                        });
                      }}
                      // onBlur={(e) => handleValidate(e)}
                    />
                    <div className="small text-danger">
                      {formValidation.asset_code}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Building<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="building"
                      onChange={(e) => {
                        pushBuildingId(e);
                        setFormValidation({
                          ...formValidation,
                          building: "",
                        });
                      }}
                      value={buildingValue}
                    />
                    <div className="small text-danger">
                      {formValidation.building}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Location of the Instrument/Utilities
                      <span class="text-danger">*</span>
                    </label>
                    <Select
                      options={locationType}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="location_id"
                      value={locationValue}
                      onChange={(e) => {
                        pushInstLocation(e);
                        setFormValidation({
                          ...formValidation,
                          location_id: "",
                        });
                      }}
                    />
                    <div className="small text-danger">
                      {formValidation.location_id}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Problem Descripion<span class="text-danger">*</span>
                    </label>
                    <textarea
                      class="form-control"
                      placeholder="Enter Problem Descripion here..."
                      name="issue_problem"
                      maxLength={200}
                      onChange={(e) => {
                        setCreInstrumentData({
                          ...creInstrumentData,
                          issue_problem: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          issue_problem: "",
                        });
                      }}

                      // onBlur={(e) => handleValidate(e)}
                    ></textarea>
                    <div className="small text-danger">
                      {formValidation.issue_problem}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <button
                class="btn btn-outline-danger"
                type="button"
                onClick={addnewclose}
              >
                Cancel
              </button>
              <button
                class="btn btn-primary-inner bpi-main"
                type="submit"
                onClick={() => {
                  handleValidateForm();
                }}
              >
                Submit
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCreInstrument;
