import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import JivaTab from "../../assets/images/loginjiva.png";
import MyPiTab from "../../assets/images/loginmypi.png";
import Jivasmall from "../../assets/images/jivasmall.png";
import { Formik } from "formik";
import { ChangePassword } from "../../service/login";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResetPassword() {
  let navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Please enter valid username."),
    new_password: Yup.string().required("Please enter valid username.").min(10, "*Password must be atleast 10 character long.").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    confirm_password: Yup.string().required("Please enter valid username."),
  });
  const [changePassword, setchangePassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
    return;
  };
  const [passwordType2, setPasswordType2] = useState("password");
  const togglePassword2 = () => {
    if (passwordType === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
    return;
  };
  const [passwordType1, setPasswordType1] = useState("password");
  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
    return;
  };
  const submitPassword = (values) => {
    ChangePassword(values)
      .then((response) => {
        navigate("/");
        console.log(response);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const [logoValue, setLogoValue] = useState("");

  const handleLogo = (e) => {
    setLogoValue(e);
  };
  return (
    <>
      <div className={logoValue === "jiva" ? "login-bg login-bg2" : "login-bg"}>
        <div className="container">
          <ToastContainer autoClose={1000} />
          <div class="row justify-content-between my-3">
            <div class="col-md-6 col-lg-6">
              <img src={Logo} alt="" />
              <h1>
                Somewhere,
                <br />
                something
              </h1>
              <h2>incredible is waiting to be known.</h2>
            </div>
            <div class="col-md-6 col-lg-5">
              <div className="loginTabs">
                <Tabs
                  defaultActiveKey="mypi"
                  id="Logintabs"
                  className="nav-fill"
                  onSelect={(e) => handleLogo(e)}
                >
                  <Tab
                    disabled
                    eventKey="jiva"
                    title={
                      <React.Fragment>
                        {/* <img src={JivaTab} alt="" /> */}
                      </React.Fragment>
                    }
                  >
                    <div class="tabfliping">
                      <h3>Reset Your Password!!</h3>
                      <form>
                        <div class="form-group position-relative">
                          <label>New Password</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter your New Password"
                          />
                          <i class="far fa-eye eyepassword"></i>
                        </div>
                        <div class="form-group position-relative">
                          <label>Confirm Password</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter your New Password"
                          />
                          <i class="far fa-eye-slash eyepassword"></i>
                        </div>
                        <Link to="/mandatory" class="btn btn-block btn-primary">
                          Reset Password
                        </Link>
                        <div className="text-center mt-4">
                          <Link to="/" class="forgettxt">
                            <i class="fas fa-chevron-left f-12"></i> Back to
                            Login
                          </Link>
                        </div>
                      </form>
                    </div>
                  </Tab>

                  <Tab
                    eventKey="mypi"
                    title={
                      <React.Fragment>
                        <img src={MyPiTab} alt="" />
                      </React.Fragment>
                    }
                  >
                    <div class="tabfliping">
                      <h3>Reset Your Password!!</h3>
                      <Formik
                        initialValues={changePassword}
                        validationSchema={validationSchema}
                        onSubmit={async (values) => {
                          submitPassword(values);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div class="form-group position-relative">
                              <label>Old Password</label>
                              <input
                                onBlur={handleBlur}
                                name="old_password"
                                value={values.old_password}
                                onChange={handleChange}
                                type={passwordType2}
                                class="form-control"
                                placeholder="Enter Old Password"
                              />
                              <i
                                className={
                                  passwordType2 === "password"
                                    ? "far fa-eye-slash eyepassword"
                                    : " far fa-eye eyepassword"
                                }
                                onClick={() => togglePassword2()}
                              ></i>
                              {touched.old_password && errors.old_password ? (
                                <div className="small text-danger">
                                  {errors.old_password}
                                </div>
                              ) : null}
                            </div>
                            <div class="form-group position-relative">
                              <label>New Password</label>
                              <input
                                onBlur={handleBlur}
                                name="new_password"
                                value={values.new_password}
                                onChange={handleChange}
                                type={passwordType}
                                class="form-control"
                                placeholder="Enter New Password"
                              />
                              <i
                                className={
                                  passwordType === "password"
                                    ? "far fa-eye-slash eyepassword"
                                    : " far fa-eye eyepassword"
                                }
                                onClick={() => togglePassword()}
                              ></i>
                              {touched.new_password && errors.new_password ? (
                                <div className="small text-danger">
                                  {errors.new_password}
                                </div>
                              ) : null}
                            </div>
                            <div class="form-group position-relative">
                              <label>Confirm Password</label>
                              <input
                                onBlur={handleBlur}
                                name="confirm_password"
                                type={passwordType1}
                                value={values.confirm_password}
                                onChange={handleChange}
                                class="form-control"
                                placeholder="Enter Confirm Password"
                              />
                              <i
                                className={
                                  passwordType1 === "password"
                                    ? "far fa-eye-slash eyepassword"
                                    : " far fa-eye eyepassword"
                                }
                                onClick={() => togglePassword1()}
                              ></i>
                              {touched.confirm_password &&
                              errors.confirm_password ? (
                                <div className="small text-danger">
                                  {errors.confirm_password}
                                </div>
                              ) : null}
                            </div>
                            <button
                              type="submit"
                              class="btn btn-block btn-primary"
                            >
                              Reset Password
                            </button>

                            <div className="text-center mt-4">
                              <Link to="/" class="forgettxt">
                                <i class="fas fa-chevron-left f-12"></i> Back to
                                Login
                              </Link>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
