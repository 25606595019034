import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import InputBoxAdd from "../inputBoxAdd/InputBoxAdd";
import { toast, ToastContainer } from "react-toastify";
import MultiOption from "./MultiOption";

const AddQuestion = (props) => {
  const {
    sectionsData,
    edit,
    setQuestionData,
    questionData,
    dataSelect,
    setDataSelect,
    categoryName,
    isViewingorPublished,
    set_imageLocalPreviewDict,
    imageLocalPreviewDict,
    QuestionDataChange,
    setMultiOptQues,
    surveyData
  } = props;
  const [asectionsData, setSectionsData] = useState([]);
  const [sectionDates, setsectionDates] = useState([]);
  const [curMultiOptQues, setCurMultiOptQues] = useState([]);
  const [aquestionData, asetQuestionData] = useState([]);

  const [showForm, setShowForm] = useState(false);

  const [dataOptionValue, setDataOptionValue] = useState({
    id: 0,
    option: "",
    min: 0,
    max: 0,
    optWidth: ""
  });

  let inputELe = null;
  let optWidth = ""


  const HandleDataRow = (data, i) => {
    const copyValue = [...questionData];
    if (data.option_type == "file" || data.option_type == "old_file") {
      copyValue[i]["options"].push({
        id: 0,
        // option_type:data["option_type"],
        option_type: "file",
        option: "",
        min: 0,
        max: 0,
      });
    } else if (inputELe) {
      dataOptionValue.option = inputELe.target.value;
      if (optWidth !== "") {
        dataOptionValue.optWidth = optWidth;
      }
      if (copyValue[i]["options"].length == 16) return;

      copyValue[i]["options"].push(dataOptionValue);

      // const dataOptionsArr = [...dataOptionValue];
      // dataOptionsArr.push( {
      //   id: 0,
      //   option: "",
      //   min: 0,
      //   max: 0,
      // });

      inputELe.target.value = "";
      optWidth = "";
      document.getElementById(`opt-width-${i}`).value = ''
    }
    setQuestionData(copyValue);
    // asetQuestionData(copyValue);

    setDataOptionValue({
      id: 0,
      option: "",
      option_type: data["option_type"],
      min: 0,
      max: 0,
    });
    setShowForm(true);
  };

  function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
  }

  const validateSSCFeature = () => {
    let errorExist = false;
    let multiOpt = JSON.parse(JSON.stringify(curMultiOptQues))
    // if(questionData.length<=0){
    //   errorExist = true;
    // }
    questionData.map((item, i) => {
      if (item?.multiOptionFlag) {
        if ((item?.question === "" && multiOpt[i]?.question === "") || multiOpt[i]?.options?.length <= 0) {
          errorExist = true;
          return errorExist
        }
        // item.options = multiOpt[i]?.options
      } else {
        if (surveyData?.section && (item?.section === null || item?.section === '')) {
          errorExist = null;
          return errorExist
        }
        console.log(item, "NEWNEW");
        if (item?.type !== "") {
          if (
            item.question === "" ||
            item.type === "" ||
            item.option_type === "" ||
            item.options.length <= 0
          ) {
            errorExist = true;
            return errorExist;
          }
          item.options.map((opItem, idx) => {
            if (item.type === "Rating") {
              if (!opItem.max) {
                errorExist = true;
                return errorExist;
              }
            } else if (item.type === "Short Answer") {
              if (
                !containsOnlyNumbers(opItem.min) ||
                !containsOnlyNumbers(opItem.max) ||
                Number(opItem.min) >= Number(opItem.max)
              ) {
                errorExist = true;

                return errorExist;
              }
            } else if (opItem.option === "" && (item.type !== "Long Answer" && item?.type !== "Date" && item?.type !== "Emoji" && item?.type !== "Slider")) {
              errorExist = true;
              return errorExist;
            }
          });
        }
      }
    });
    return errorExist;
  };

  const addMoreInputField = () => {
    let flag = validateSSCFeature()
    if (flag) {
      toast.error("All fields are necesssary without White Spaces in starting");
    } else if (flag === null) {
      toast.error("Kindly select section in all questions")
    } else {
      let copyValue = JSON.parse(JSON.stringify(questionData)).map((each, i) => {
        if (each?.multiOptionFlag) {
          each.options = curMultiOptQues[i]?.options;
          return each;
        } else {
          return each
        }
      });
      copyValue.push({
        id: 0,
        question: "",
        type: "",
        option_type: "",
        options: [],
        sequence: null,
        section: null,
        multiOptionFlag: false,
        allow_feedback: false,
        selectFeedbackOption: ''
      })
      setQuestionData(copyValue);
      // asetQuestionData(copyValue);
    }
  };

  const removeLeaveRow = (idx) => {
    let sektionData = [...sectionsData];

    for (let kk = 0; kk < sektionData.length; kk++) {
      if (sektionData[kk].name == questionData[idx].section) {
        delete sektionData[kk]["created_values"][questionData[idx].sequence];
        setSectionsData(sektionData);
      }
    }
    const newLeaveDetails = questionData.filter((item, i) => i !== idx);
    setQuestionData(newLeaveDetails);

    // const imgLocalPreviewDict = imageLocalPreviewDict.filter((itemm, ii) => ii !== idx);
    // console.log(`now quesData`, newLeaveDetails);

    let imgLocalPreviewDict = {};

    for (const kkey in imageLocalPreviewDict) {
      if (kkey != idx) {
        imgLocalPreviewDict[kkey] = imageLocalPreviewDict[kkey];
      }
    }

    set_imageLocalPreviewDict(imgLocalPreviewDict);
  };

  let genOptions = [
    {
      value: 1,
      label: (
        <div>
          <i class="far fa-dot-circle"></i> 1
        </div>
      ),
    },
  ];
  const getSectionOptions = (qItem) => {
    sectionsData?.map((secItem, idx) => {
      if (secItem.name === qItem.section) {
        Array.apply(null, { length: secItem.num_ques }).map((e, i) => {
          if (!secItem.created_values.hasOwnProperty(i + 1)) {
            genOptions.push({
              value: i + 1,
              label: (
                <div>
                  <i class="far fa-dot-circle"></i> i+1
                </div>
              ),
            });
          }
        });
      }
    });
    return genOptions;
  };

  const updateValue = (field, i, e) => {
    let copyValue = JSON.parse(JSON.stringify(questionData));

    let optArr = [...dataSelect];

    if (field == "is_dropdown") {
      copyValue[i][field] = e;
    }

    copyValue[i].multiOptionFlag = questionData[i]?.multiOptionFlag

    if (
      field === "type" &&
      (e.value === "Short Answer" ||
        e.value === "Rating" || e.value === "Slider" || e?.value === "Emoji" ||
        e.value === "Long Answer" || e?.value === "Date")
    ) {
      copyValue[i][field] = e.value;
      copyValue[i]["option_type"] = "txt";
      copyValue[i]["options"].push({
        id: 0,
        option: "",
        option_type: copyValue[i]["option_type"],
        min: 0,
        max: 0,
      });
      optArr[i] = {
        value: e.value,
        label: (
          <div>
            <i class="fas fa-caret-down"></i> {e.value}
          </div>
        ),
      };
      setDataSelect(optArr);
    } else if (field === "option_type" && e === "file") {
      // copyValue[i][field] = e;
      copyValue[i]["option_type"] = e;
      copyValue[i]["options"].push({
        id: 0,
        option: "",
        option_type: copyValue[i]["option_type"],
        min: 0,
        max: 0,
      });
      // optArr[i]={
      //   value: e,
      //   label: (
      //     <div>
      //       <i class="fas fa-caret-down"></i> {e}
      //     </div>
      //   ),
      // }
      // setDataSelect(optArr);
    } else if (field === "type") {
      copyValue[i]["options"].splice(0, copyValue[i]["options"].length);
      copyValue[i][field] = e.value;
      optArr[i] = e;
      setDataSelect(optArr);
    } else if (field === "selectFeedbackOption") {
      console.log(e, "con");
      copyValue[i][field] = e?.map((each) => each?.value);
      console.log(copyValue[i], "con");
    } else {
      // update create_values in sectionData to skip them in dropdown
      if (field === "sequence") {
        let csectionData = [...sectionsData];
        for (let kk = 0; kk < csectionData.length; kk++) {
          if (csectionData[kk]["name"] === copyValue[i]["section"]) {
            delete csectionData[kk]["created_values"][copyValue[i]["sequence"]];

            csectionData[kk]["created_values"][e] = e;
            break;
          }
        }
        console.log({ csectionData });
        setSectionsData(csectionData);
      }

      copyValue[i][field] = e;

      if (field === "section") {
        let csectionData = [...sectionsData];
        let sectionDatesLocal = [];
        for (let i_secname = 0; i_secname < csectionData.length; i_secname++) {
          if (csectionData[i_secname]["name"] === copyValue[i]["section"]) {
            for (let j = 0; j < csectionData[i_secname]["num_ques"]; j++) {
              sectionDatesLocal.push({
                value: j + 1,
                label: `${j + 1}`,
              });
            }
          }
        }
        setsectionDates(sectionDatesLocal);
      }
    }
    // asetQuestionData(copyValue);
    setQuestionData(copyValue);
    setShowForm(true);
  };

  const selectquestion = [
    {
      value: "Multiple Choice",
      label: (
        <div>
          <i class="far fa-dot-circle"></i> Multiple Choice
        </div>
      ),
    },
    {
      value: "Single Select",
      label: (
        <div>
          <i class="fas fa-caret-down"></i> Single Select
        </div>
      ),
    },
    {
      value: "Short Answer",
      label: (
        <div>
          <i class="fas fa-align-left"></i> Short Answer
        </div>
      ),
    },
    {
      value: "Long Answer",
      label: (
        <div>
          <i class="fas fa-align-left"></i> Long Answer
        </div>
      ),
    },
    {
      value: "Rating",
      label: (
        <div>
          <i class="fas fa-star"></i> Rating
        </div>
      ),
    },
    {
      value: "Slider",
      label: (
        <div>
          <i class="fas fa-sliders-h"></i> Slider
        </div>
      ),
    },
    {
      value: "Emoji",
      label: (
        <div>
          <i class="fas fa-smile"></i> Emoji
        </div>
      ),
    },
    {
      value: "Date",
      label: (
        <div>
          <i class="fas fa-calendar"></i> Date
        </div>
      ),
    },
  ];

  const updateNew = (e, idx) => {
    inputELe = e;
  };

  useEffect(() => {
    console.log(dataSelect);
  }, [dataSelect]);

  const isMultiOptionType = (index, checked) => {
    let data = JSON.parse(JSON.stringify(questionData));
    data[index].multiOptionFlag = checked;
    setQuestionData(data)
    // asetQuestionData(data);
  }

  const minMax = (index, min_max, e) => {
    let copyValue = JSON.parse(JSON.stringify(questionData));
    switch (copyValue[index]?.type) {
      case "Short Answer":
      case "Slider":
      case "Emoji":
      case "Rating":
        if (min_max === "is_dropdown" || min_max === "allow_feedback" || min_max === "feedback_mandatory") {
          copyValue[index]["options"][0][min_max] = e
        } else {
          copyValue[index]["options"][0][min_max] = e?.target?.value
        }
        break;
    }
    // setQuestions(copyValue);
    setQuestionData(copyValue)
  };


  return (
    <div class="col-md-8">
      {/* <ToastContainer autoClose={1000} /> */}

      {questionData?.map((item, index) => (
        <div class="question-box">
          <div class="row align-items-end">
            {sectionsData?.length > 0 ? (
              <div className="col-md-12 mb-3">
                <div className="row align-items-end">
                  <div className="col-md-4 innergroup">
                    {/* <span>
                      Select a section
                    </span> */}
                    <select
                      onChange={(e) => {
                        updateValue("section", index, e.target.value);
                      }}
                      class="form-control newbgselect"
                      value={item?.section}
                    >
                      <option disabled selected>
                        Select section
                      </option>

                      {sectionsData?.map((secItem, idx) =>
                        secItem.num_ques > 0 ? (
                          <option>{secItem.name}</option>
                        ) : (
                          ""
                        )
                      )}
                    </select>
                  </div>

                  {
                    <div className="col-md-4">
                      {/* <Select
                        options={sectionDates}
                        class="form-control newbgselect"
                        classNamePrefix="select"
                        placeholder="Select sequence"
                        onChange={(e) => {
                          updateValue("sequence", index, e.value);
                        }}
                        // value={item.sequence}
                        // value={item.sequence}
                      /> */}

                      {/* {item.sequence} */}
                      <select
                        onChange={(e) => {
                          updateValue("sequence", index, e.target.value);
                        }}
                        class="form-control newbgselect"
                        name={`sequencer${index}`}
                        id={`sequencer${index}`}
                        value={item.sequence}
                      >
                        <option disabled selected>
                          Select sequence
                        </option>

                        {sectionsData?.map((secItem, idx) =>
                          edit === undefined && secItem.name === item.section
                            ? Array.apply(null, {
                              length: secItem.num_ques,
                            }).map((e, i) =>
                              i + 1 in secItem.created_values ? (
                                <option
                                  value={i + 1}
                                  selected={i + 1 == item.sequence}
                                  disabled
                                >
                                  {i + 1}
                                </option>
                              ) : (
                                <option value={i + 1}>{i + 1}</option>
                              )
                            )
                            : secItem.name === item.section
                              ? Array.apply(null, {
                                length: secItem.num_ques,
                              }).map((e, i) => (
                                <option value={i + 1}>{i + 1}</option>
                              ))
                              : ""
                        )}
                      </select>
                    </div>
                  }
                  <div className="col-md-4 form-group innergroup text-center">
                    <label>
                      Multiple Option Type<span class="text-danger"></span>
                    </label><br />
                    <input type="checkbox" onChange={(e) => {
                      isMultiOptionType(index, e.target.checked)
                    }}
                      checked={item?.multiOptionFlag === undefined ? false : item?.multiOptionFlag}
                      disabled={item?.id !== 0}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="col-md-4 innergroup mb-3">
                  <input
                    hidden={isViewingorPublished}
                    type="number"
                    class="form-control mt-2"
                    placeholder="Enter sequence"
                    name="sequence"
                    onChange={(e) => {
                      updateValue("sequence", index, e.target.value);
                    }}
                    value={item?.sequence}
                  />
                </div>
                <div className="col-md-3 form-group innergroup text-center">
                  <label>
                    Multiple Option Type<span class="text-danger"></span>
                  </label><br />
                  <input type="checkbox" onChange={(e) => {
                    isMultiOptionType(index, e.target.checked)
                  }}
                    checked={item?.multiOptionFlag === undefined ? false : item?.multiOptionFlag}
                    disabled={item?.id !== 0}
                  />
                </div>
              </>
            )}

            <div class="col-md-10">
              <h4>
                Question {index + 1}
                <p
                  class="float-right text-danger"
                  hidden={isViewingorPublished}
                >
                  <span
                    onClick={() => {
                      removeLeaveRow(index);
                    }}
                  >
                    <i class="far fa-trash-alt"></i>
                  </span>
                </p>
              </h4>
            </div>
            <div className="col-md-2 mb-3">
              Mandatory <input type="checkbox" onChange={(e) => {
                let data = JSON.parse(JSON.stringify(questionData));
                data[index].is_mandatory = e.target.checked
                setQuestionData(data)
              }} value={item?.is_mandatory} checked={item?.is_mandatory}></input>
            </div>
          </div>

          <div class="row align-items-end">
            <div class="col-md-12 mb-3">
              <CKEditor
                disabled={isViewingorPublished}
                editor={ClassicEditor}
                name="question"
                onBlur={(event, editor) => {
                  const data = editor.getData();
                  updateValue("question", index, data);
                }}
                onReady={(editor) => {
                  // console.log("Editor is ready to use!", editor);
                }}
                placeholder={questionData ? questionData[index]?.question : ""}
                data={questionData ? questionData[index]?.question : ""}
              />
            </div>
            <div class="col-md-4 mb-5 text-center">
              <label>Question Width (Web)</label>
              <select
                disabled={isViewingorPublished}
                class="form-control newbgselect"
                name="max"
                onChange={(e) => {
                  updateValue("quesWidth", index, e.target.value)
                }}
                value={questionData[index]?.quesWidth}
              >
                <option value="">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
            <div class="col-md-4 mb-5 text-center">
              <label>Question Width (Mobile)</label>
              <select
                disabled={isViewingorPublished}
                class="form-control newbgselect"
                name="max"
                onChange={(e) => {
                  updateValue("quesWidthMob", index, e.target.value)
                }}
                value={questionData[index]?.quesWidthMob}
              >
                <option value="">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
            <div class="col-md-4 mb-5 text-center">
              <label>Question Width (Tablet)</label>
              <select
                disabled={isViewingorPublished}
                class="form-control newbgselect"
                name="max"
                onChange={(e) => {
                  updateValue("quesWidthTab", index, e.target.value)
                }}
                value={questionData[index]?.quesWidthTab}
              >
                <option value="">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
            {item?.multiOptionFlag === true ? (
              <MultiOption
                key={index}
                isViewingorPublished={isViewingorPublished}
                selectquestion={selectquestion}
                aquestionData={questionData}
                dataSelect={dataSelect}
                index={index}
                edit={edit}
                categoryName={categoryName}
                setCurMultiOptQues={setCurMultiOptQues}
                setMultiOptQues={setMultiOptQues}
                setQuestionData={setQuestionData}
              />
            ) : (
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <Select
                    // isDisabled={aquestionData[index]["options"].length > 0}
                    isDisabled={isViewingorPublished}
                    options={selectquestion}
                    className="basic-multi-select"
                    classNamePrefix="select mr-3"
                    placeholder="Select answer style"
                    onChange={(e) => {
                      updateValue("type", index, e);
                    }}
                    value={dataSelect[index]}
                  />
                </div>
              </div>
            )}
            {(questionData[index].type === "Multiple Choice" ||
              questionData[index].type === "Single Select") && questionData[index]?.multiOptionFlag === false ? (
              <>
                <div
                  class={
                    questionData[index].type === "Single Select"
                      ? "col-md-3"
                      : "col-md-6"
                  }
                >
                  <div class="form-group innergroup">
                    <select
                      class="form-control newbgselect"
                      name="option_type"
                      disabled={questionData[index]["options"].length > 0}
                      value={
                        questionData ? questionData[index].option_type : ""
                      }
                      onChange={(e) => {
                        updateValue("option_type", index, e.target.value);
                      }}
                    >
                      <option value="">Select Answer Type</option>
                      <option value="txt">Text</option>
                      <option value="file">Image</option>
                      {/* <option value="video">Video</option> */}
                    </select>
                  </div>
                </div>
                {(questionData[index].type === "Single Select") && (
                  <div className="col-md-3 form-group innergroup">
                    <label>
                      Options as Dropdown<span class="text-danger"></span>
                    </label>
                    <div className="">
                      <label
                        class="logCheck d-inline-block mr-4"
                        for={`dropYes${index}`}
                      >
                        <input
                          id={`dropYes${index}`}
                          type="radio"
                          name={`dropdown${index}`}
                          onClick={(e) => {
                            updateValue("is_dropdown", index, true);
                          }}
                          checked={
                            dataSelect[index]?.is_dropdown ||
                            questionData[index]?.is_dropdown === true
                          }
                        />
                        Yes
                        <span class="checkmark"></span>
                      </label>
                      <label
                        class="logCheck d-inline-block"
                        for={`dropNo${index}`}
                      >
                        <input
                          id={`dropNo${index}`}
                          type="radio"
                          name={`dropdown${index}`}
                          onClick={(e) => {
                            updateValue("is_dropdown", index, false);
                          }}
                          checked={
                            dataSelect[index]?.is_dropdown ||
                            questionData[index]?.is_dropdown === false
                          }
                        />
                        No
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                )}
              </>
            ) : (
              (questionData[index]?.type === "Rating" || questionData[index]?.type === "Slider") && questionData[index]?.multiOptionFlag === false && (
                <>
                  {questionData[index]?.type === "Rating" && (
                    <div className="col-md-3 form-group innergroup">
                      <label>
                        Options as Dropdown<span class="text-danger"></span>
                      </label>
                      <div className="">
                        <label
                          class="logCheck d-inline-block mr-4"
                          for={`dropYes${index}`}
                        >
                          <input
                            id={`dropYes${index}`}
                            type="radio"
                            name={`dropdown${index}`}
                            onClick={(e) => {
                              updateValue("is_dropdown", index, true);
                            }}
                            checked={dataSelect[index]?.is_dropdown ||
                              questionData[index]?.is_dropdown === true} />
                          Yes
                          <span class="checkmark"></span>
                        </label>
                        <label
                          class="logCheck d-inline-block"
                          for={`dropNo${index}`}
                        >
                          <input
                            id={`dropNo${index}`}
                            type="radio"
                            name={`dropdown${index}`}
                            onClick={(e) => {
                              updateValue("is_dropdown", index, false);
                            }}
                            checked={dataSelect[index]?.is_dropdown ||
                              questionData[index]?.is_dropdown === false} />
                          No
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="col-md-3 form-group innergroup">
                    <br />
                    <div className="row" style={{
                      marginTop: '-14px'
                    }}>
                      <div className="col-md-6">
                        <label>
                          User Feedback<span class="text-danger"></span>
                        </label>
                        <div className="">
                          <label
                            class="logCheck d-inline-block mr-4"
                            for={`feedYes${0}q${index}`}
                          >
                            <input
                              id={`feedYes${0}q${index}`}
                              type="radio"
                              name={`feedback${0}q${index}`}
                              onClick={(e) => {
                                minMax(index, "allow_feedback", true);
                              }}
                              checked={questionData[index]?.options[0]?.allow_feedback === true} />
                            Yes
                            <span class="checkmark"></span>
                          </label>
                          <label
                            class="logCheck d-inline-block"
                            for={`feedNo${0}q${index}`}
                          >
                            <input
                              id={`feedNo${0}q${index}`}
                              type="radio"
                              name={`feedback${0}q${index}`}
                              onClick={(e) => {
                                minMax(index, "allow_feedback", false);
                              }}
                              checked={questionData[index]?.options[0]?.allow_feedback === false} />
                            No
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {questionData[index]?.options[0]?.allow_feedback && (
                          <>
                            <label>Feedback Mandatory</label>
                            <label
                              class="logCheck d-inline-block mr-4"
                              for={`feedMandatYes${0}q${index}`}
                            >
                              <input
                                id={`feedMandatYes${0}q${index}`}
                                type="radio"
                                name={`feedbackMandat${0}q${index}`}
                                onClick={(e) => {
                                  minMax(index, "feedback_mandatory", true);
                                }}
                                checked={questionData[index]?.options[0]?.feedback_mandatory === true} />
                              Yes
                              <span class="checkmark"></span>
                            </label>
                            <label
                              class="logCheck d-inline-block"
                              for={`feedMandatNo${0}q${index}`}
                            >
                              <input
                                id={`feedMandatNo${0}q${index}`}
                                type="radio"
                                name={`feedbackMandat${0}q${index}`}
                                onClick={(e) => {
                                  minMax(index, "feedback_mandatory", false);
                                }}
                                checked={questionData[index]?.options[0]?.feedback_mandatory === false} />
                              No
                              <span class="checkmark"></span>
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div></>
              )
            )}
            {questionData[index]?.multiOptionFlag === false && (
              <div class="col-md-12">
                {
                  <InputBoxAdd
                    setQuestionData={setQuestionData}
                    questionData={questionData}
                    qid={index}
                    categoryName={categoryName}
                    isViewingorPublished={isViewingorPublished}
                    set_imageLocalPreviewDict={set_imageLocalPreviewDict}
                    imageLocalPreviewDict={imageLocalPreviewDict}
                    QuestionDataChange={QuestionDataChange}

                  // dataOptionValue={dataOptionValue}
                  // setDataOptionValue={setDataOptionValue}
                  />
                }
                <div class="innergroup">
                  {questionData[index].type === "Multiple Choice" ||
                    questionData[index].type === "Single Select" ? (
                    <div class="row">
                      {questionData[index].option_type === "txt" ? (
                        <>
                          <div class="col-md-7 pr-3 pr-sm-0">
                            <input
                              type="text"
                              name="option"
                              class="form-control"
                              placeholder="Enter Answer"
                              onChange={(e) => {
                                updateNew(e, index);
                              }}
                            />
                          </div>
                          <div className="col-md-3">
                            <select
                              id={`opt-width-${index}`}
                              disabled={isViewingorPublished}
                              class="form-control newbgselect"
                              name="max"
                              onChange={(e) => {
                                optWidth = e.target.value;
                                let elem = document.getElementById(`opt-width-${index}`)
                                elem.value = optWidth
                                // minMax(value, idx, "optWidth", e);
                              }}
                            >
                              <option value="">Option Width</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div
                        class="col-md-2 mt-2 mt-sm-0"
                        hidden={isViewingorPublished}
                      >
                        <button
                          type="button"
                          class="btn btn-blanktd"
                          onClick={() => {
                            HandleDataRow(item, index);
                          }}
                        >
                          <span class="text-theme cursor-pointer">
                            <i class="fas fa-plus-circle"></i> Add Option
                          </span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div class="row mt-5">
                    <div class="col-md-6">
                      <div className="row">
                        <div class="col-md-6 form-group innergroup savetemplate mt-2 mb-0">
                          <label class="logCheck d-inline-block mr-4">
                            Add Other option with Remarks text
                            <input type="checkbox" name="savetemplate" onChange={(e) => {
                              updateValue('selectFeedback', index, e.target.checked)
                            }} value={questionData[index]?.selectFeedback} checked={questionData[index]?.selectFeedback} />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        {questionData[index]?.selectFeedback && (
                          <div class="form-group innergroup col-md-6 ">
                            <Select
                              isDisabled={isViewingorPublished}
                              options={questionData[index]?.options.map((each) => {
                                return {
                                  label: each?.option,
                                  value: each?.option
                                }
                              })}
                              className="basic-multi-select"
                              classNamePrefix="select mr-3"
                              placeholder="Select Options"
                              onChange={(e) => {
                                updateValue("selectFeedbackOption", index, e.value);
                              }}
                              value={{
                                label: questionData[index]?.selectFeedbackOption,
                                value: questionData[index]?.selectFeedbackOption
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <p class="mt-2 mb-0 f-12 text-dark text-sm-right">
                        <span class="text-danger">*Note:</span> Please select the
                        right answers
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            )}
            <div class="col-md-12">
              <div class="row mt-5">
                <div class="col-md-6">
                  <div className="row">
                    <div class="col-md-6 form-group innergroup savetemplate mt-2 mb-0">
                      <label class="logCheck d-inline-block mr-4">
                        Add Other option with Remarks text
                        <input type="checkbox" name="savetemplate" onChange={(e) => {
                          updateValue('selectFeedback', index, e.target.checked)
                        }} value={questionData[index]?.selectFeedback} checked={questionData[index]?.selectFeedback} />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    {questionData[index]?.selectFeedback && (
                      <div class="form-group innergroup col-md-6 ">
                        {console.log(questionData[index], edit)}
                        <Select
                          isMulti={true}
                          isDisabled={isViewingorPublished}
                          options={questionData[index]?.multiOptionFlag ?
                            Array.from(Array(10).keys()).map((each) => {
                              return ({
                                label: each + 1,
                                value: each + 1
                              })
                            })
                            :
                            (questionData[index]?.type === "Rating" || questionData[index]?.type === "Slider") ?
                              Array.from(Array(questionData[index]?.options[0]?.max).keys()).map((each) => {
                                return ({
                                  label: each + 1,
                                  value: each + 1
                                })
                              })
                              :
                              []
                          }
                          className="basic-multi-select"
                          classNamePrefix="select mr-3"
                          placeholder="Select Options"
                          onChange={(e) => {
                            if (questionData[index]?.multiOptionFlag) {
                              updateValue("selectFeedbackOption", index, e);
                            } else {
                              updateValue("selectFeedbackOption", index, e);
                            }
                          }}
                          value={edit ?
                            typeof (questionData[index]?.selectFeedbackOption) == "string" ?
                              questionData[index]?.selectFeedbackOption.split(',').filter((each) => {
                                if (each !== "") {
                                  return true;
                                }
                              }).map((each) => {
                                return ({
                                  label: each,
                                  value: each
                                })
                              }) : questionData[index]?.selectFeedbackOption.map((each) => {
                                return ({
                                  label: each,
                                  value: each
                                })
                              })
                            : questionData[index]?.selectFeedbackOption?.map((each) => {
                              return ({
                                label: each,
                                value: each
                              })
                            })}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  <p class="mt-2 mb-0 f-12 text-dark text-sm-right">
                    <span class="text-danger">*Note:</span> Please select the
                    right answers
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div class="text-right" hidden={isViewingorPublished}>
        <button
          class="btn btnblanktd text-primary"
          onClick={() => {
            addMoreInputField();
            let data = {
              value: "",
              label: "Select answer style",
            };
            const optionSelectArray = [...dataSelect];
            optionSelectArray.push(data);
            setDataSelect(optionSelectArray);
          }}
        >
          <i class="far fa-plus-square mr-2"></i> Add More
        </button>
      </div>
    </div>
  );
};

export default AddQuestion;
