import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as TravelListingAPI from "../../service/travelService";
import { confirmAlert } from "react-confirm-alert";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const ConfirmModal = (props) => {
  const { confirmModal, onConfirmClose, sendIdData, getTravelList } = props;
  let condition = "false";
  const [isLoading, setIsLoading] = useState(false);
  const [cashBookDemand, setCashBookDemand] = useState({
    cancel_reason: "",
  });
  const userDetails = useSelector(selectUserData);

  const [formValidation, setFormValidation] = useState({
    cancel_reason: "",
  });
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    if (!cashBookDemand.cancel_reason) {
      isError = true;
      formerr.cancel_reason = "Cancel Reason is required";
      setFormValidation(formerr);
    } else if (cashBookDemand.cancel_reason.length <= 13) {
      isError = true;
      formerr.cancel_reason = "*Atlest 20 characters is Required";
      setFormValidation(formerr);
    }
    return isError;
  };

  const deleteOnDuty = (id) => {
    if (condition == "false") {
      condition = "true";
      setIsLoading(true);
      TravelListingAPI.cancelOnDuty(id, cashBookDemand?.cancel_reason)
        .then((res) => {
          condition = "false";
          if (res.data.status == true) {
            getTravelList("", 1, 10, "-id", true, userDetails?.id, "False");
            onConfirmClose();
            toast.success(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const handleValidateForm = (sendIdData) => {
    if (handleValidate()) return false;
    deleteOnDuty(sendIdData);
  };
  return (
    <Modal
      show={confirmModal}
      onHide={onConfirmClose}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label style={{ textAlign: "center" }}>
                      Are you sure you want to cancel this tour?&nbsp;
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control nonumbdrop"
                      placeholder="Enter Reason For Cancellation"
                      name="cancel_reason "
                      // maxLength={30}
                      value={cashBookDemand?.cancel_reason}
                      onChange={(e) => {
                        setCashBookDemand({
                          ...cashBookDemand,
                          cancel_reason: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          cancel_reason: "",
                        });
                      }}
                    />
                    <div className="small text-danger">
                      {formValidation.cancel_reason}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  onConfirmClose();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleValidateForm(sendIdData);
                }}
                // onClick={() => {
                //   deleteOnDuty(sendIdData);
                // }}
                className="btn btn-primary-inner bpi-main"
              >
                Ok
              </button>
            </>
          )}
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default ConfirmModal;
