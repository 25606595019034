import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import * as TravelListingAPI from "../../service/travelService";
import * as ClaimTravel from "../../service/claimTravel";
import moment from "moment/moment";

import { uploadfile } from "../../service/Survey";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { updateVehicleLog } from "../../service/vehiclelog";
import * as otherExpService from "../../service/otherExpService";
import * as userGl from "../../service/userGl";
import { Button } from "react-bootstrap";

function OtherExpCheckExpens() {
  let location = useLocation();
  const [userGroup, setuserGroup] = useState({
    _userGroup: "",
  });

  const dispatch = useDispatch();

  let navigate = useNavigate();
  const [backpath, set_backPath] = useState(null);
  const [glAcc, setglAcc] = useState([]);
  const [costCAcc, setCostCAcc] = useState([]);
  const [fixMachines, set_fixMachines] = useState([
    721008, 722010, 766044, 766045, 766046,
  ]);

  const [lastRecentBill, setlastRecentBill] = useState({
    max_bill_date: "",
    other: {},
    petty: {},
  });

  const [overallSums, setoverallSums] = useState({
    claimAmountsum: 0,
    apprAmountsum: 0,
  });

  const optionsTravel = [
    { value: "", label: "Select" },
    { value: "Bike", label: "Bike" },
    { value: "Car", label: "Car" },
    { value: "Ola", label: "Ola" },
    { value: "Uber", label: "Uber" },
    { value: "Taxi", label: "Taxi" },
    { value: "Bus", label: "Bus" },
    { value: "Auto", label: "Auto" },
    { value: "Public Transport", label: "Public Transport" },
  ];

  const [traveluserData, setTraveluserData] = useState({});

  const [addnewshow, addnewsetShow] = useState(false);
  const [ticketDetails, setTicketDetails] = useState([]);

  const [traveluserDetails, setTraveluserDetails] = useState([]);

  const [inputReason, set_inputReason] = useState("");
  const [reasonshows, setReasonShow] = useState(false);

  const [travelDetails, setTravelDetails] = useState([]);
  const [newticketDetails, set_newTicketDetails] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [hotelDetails, setHotelDetails] = useState([]);
  const [daDetails, setDADetails] = useState([]);
  const [conveyanceDetails, setConveyanceDetails] = useState([]);

  const [totalHotelValue, setTotalHotelValue] = useState(0.0);
  const [totalHotelValue2, setTotalHotelValue2] = useState(0.0);
  const [totalTicketValue, setTotalTicketValue] = useState(0.0);
  const [totalTicketValue2, setTotalTicketValue2] = useState(0.0);
  const [totalDaValue, setTotalDaValue] = useState(0.0);
  const [totalDaValue2, setTotalDaValue2] = useState(0.0);
  const [totalVehLogValue, setTotalVehLogValue] = useState(0.0);
  const [totalVehLogValue2, setTotalVehLogValue2] = useState(0.0);
  const [totalConveValue, setTotalConveValue] = useState(0.0);
  const [totalConveValue2, setTotalConveValue2] = useState(0.0);
  const [imageLocalPreviewList, set_imageLocalPreviewList] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [approvedDisable, setApprovedDisable] = useState(false);

  const [pick_item_id, set_pickItemId] = useState(null);

  const [taxRatesMenu, set_taxRatesMenu] = useState([]);
  const [airlinesMenu, set_AirlinesMenu] = useState([]);
  const [gstState, setGstState] = useState([]);

  const [selectedFiles, setselectedFiles] = useState([]);

  const [post_reasons, set_post_reasons] = useState({});

  const [delete_tickets_ids, set_delete_tickets_ids] = useState([]);
  // const [new_added_tickets,set_new_added_tickets] = useState([])
  const [updated_tickets, set_updated_tickets] = useState([]);
  const [updated_hotels, set_updated_hotels] = useState([]);
  const [updated_da, set_updated_da] = useState([]);
  const [updated_vehLogs, set_updated_vehLogs] = useState([]);
  const [updated_conveyance, set_updated_conveyance] = useState([]);
  // const [isErr,setisErr] = useState(false)
  const [apprErrs, setapprErrs] = useState({});
  const [globalErrCheck, setGlobalErrCheck] = useState({
    isSet: false,
  });

  const [finalDetails, set_finalDetails] = useState({
    final_claim: 0,
    final_approvedCompExp: 0,
    final_approvedSelfExp: 0,
    final_approvedTotal: 0,
    final_netAmt: 0,
  });

  // const [vehicleLogSums,set_vehicleLogSums] = useState({
  //   total_kms:0,
  //   total_fuelqty:0,
  //   total_fuelamt:0,
  //   total_apprfuelamt:0,
  //   total_dailywage:0,
  //   total_apprdailywage:0,
  //   total_daval:0,
  //   total_apprdaval:0,
  //   total_other:0,
  //   total_approther:0,
  //   total_washing:0,
  //   total_apprwashing:0,
  //   total_total:0,
  //   total_apprtotal:0,
  // })

  const [vehicleLogSums, set_vehicleLogSums] = useState({
    id: 381,
    travel_id: 8253,
    travel_date: "2023-08-23",
    start_kms: 0,
    is_exception: null,
    mode_id: 4,
    close_kms: 0,
    total_kms: 0,
    fuel_quantity: 0,
    fuel_amount: 0,
    driver_da_value: 0,
    other_expenses: 0,
    total_amount: 0,
    driver_daily_wages: 0,
    user_id: 5590,
    washing_amount: 0,
    appr_driver_daily_wages: 0,
    appr_fuel_amount: 0,
    appr_other_expenses: 0,
    appr_washing_amount: 0,
    appr_driver_da_value: null,
    approved_amount: 0,
    vehicle_log_id: null,
    user_name: "Ravi Kumar",
    modee_name: "Car",
  });

  const [daDataList, setDaDataList] = useState([]);
  const [approvedSendBack_payload, set_approvedSendBack_payload] = useState({
    pick_list_id: [],
    status: "",
    reason: [
      {
        applied_amount: 234.2,
        approved_amount: 123.3,
        expense_type: "TICKET",
        reason: "",
      },
      {
        applied_amount: 111.4,
        approved_amount: 222.5,
        expense_type: "HOTEL",
        reason: "",
      },
      {
        applied_amount: 333.6,
        approved_amount: 444.7,
        expense_type: "DA",
        reason: "",
      },
      {
        applied_amount: 555.8,
        approved_amount: 666.9,
        expense_type: "VEHICLE_LOG",
        reason: "",
      },
      {
        applied_amount: 777.9,
        approved_amount: 888.78,
        expense_type: "LOCAL_CONVEYANCE",
        reason: "",
      },
    ],
  });

  const [docsModel, set_docsModel] = useState({
    is_visible: false,
    modelData: null,
  });

  const [travelchats, setTravelChats] = useState([]);
  const [uploadData, setUploadData] = useState({});

  const finalUser = useSelector(selectUserData);

  const [travelPostData, set_travelPostData] = useState({
    travel_id: null,
    u_type: "SSC",
    message: "",
    attachment_path: null,
    attachment_list: [],
  });

  const [approveData, setApproveData] = useState({
    travel_id: null,
    amount: null,
  });

  const userDetails = useSelector(selectUserData);

  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  const getAssignedGLDropDown = (gl_type, user_id,ress) => {
    dispatch(setDisplayLoader("Display"));

    otherExpService
      .getAssignedGLDropDown(
        `gl_type=` +
          gl_type[0].toUpperCase() +
          gl_type.slice(1) +
          `&user_id=` +
          user_id+
          (gl_type?.includes("etty")?`&organisation_id=${ress.organisation_id}`:``)
      )
      .then((response) => {
        // console.log(`otherexp...`,response);
        dispatch(setDisplayLoader("Hide"));

        let gl_acc = [];
        let cost_acc = [];
        if (gl_type.toLowerCase() == "petty") {
          gl_acc = response?.data?.dataList?.result[0]?.gl_id;
          cost_acc = response?.data?.dataList?.result[0]?.cost_center_id;
        } else {
          gl_acc = response?.data?.dataList?.gl_id;
        }
        gl_acc = gl_acc.map((obj) => ({
          gl_id: obj.gl_id,
          label: `${obj.gl_account}-${obj.gl_desc}`,
          value: obj.gl_account,
        }));
        cost_acc = cost_acc.map((obj) => ({
          cost_center_id: obj.cost_center_id,
          label: `${obj.cost_center_name}-${obj.cost_center_code}`,
          value: obj.cost_center_code,
        }));
        setCostCAcc(cost_acc);
        setglAcc(gl_acc);
        // let data = response?.data?.result;
        // setVehicleData(response?.data?.dataList?.result);
        // setotherExpData(response?.data?.dataList?.result);

        // setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    console.log("files", files.type);
    selectedFiles.push(files);

    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    console.log(`...`, fileData);

    uploadfile(fileData).then((response) => {
      // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
      setUploadData(response.data);
      console.log(`response`, response.data);
      set_travelPostData({
        ...travelPostData,
        attachment_path: response.data,
      });
    });
  };

  const onProfileChange2 = (e, flag) => {
    let allfiles = e.target.files;

    let urls = [...imageLocalPreviewList];
    let tmp_filesToUpload = [...filesToUpload];

    for (let i = 0; i < allfiles.length; i++) {
      const afile = e.target.files[i];

      let myUrl = URL.createObjectURL(afile);
      urls.push(myUrl);

      // console.log("files", afile.type);

      const fileData = new FormData();
      fileData.append("uploaded_file", afile);
      tmp_filesToUpload.push(fileData);

      // console.log(`...`,fileData);

      // uploadfile(fileData).then((response) => {
      //   // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
      //   setUploadData(response.data)
      //   console.log(`response`,response.data);
      //   setPostData({
      //     ...postData,
      //     attachment_path:response.data
      //   })

      // })
    }

    set_imageLocalPreviewList(urls);
    setFilesToUpload(tmp_filesToUpload);

    console.log(`files...`, filesToUpload);
  };

  async function approveCheckExpense() {
    let errExist = false;
    dispatch(setDisplayLoader("Display"));

    for (const [k, v] of Object.entries(apprErrs)) {
      errExist = errExist | v.isErr | v.emptyreason;
    }

    if (errExist) {
      toast.error("Errors exist ");
      return;
    }

    // return

    let apprPayload = {
      approval_data: [
        {
          id: traveluserDetails?.id,
          status: "A",
          remark: "",
        },
      ],
    };

    console.log("updated_hotels..", apprPayload);

    // return

    // newly added objects
    // traveluserDetails.expense_data.forEach((itemm)=>{
    //   if(!itemm.id){
    // itemm["updated_by_ssc_user_id"]=finalUser?.id

    // console.log(`new...`,itemm);

    let _payload = {
      expense_type: "",
      organisation_id: 0,
      status: "",
      total_bill_amount: 0,
      attachment_path: [],
      expense_data: [
        {
          gl_id: 0,
          mode_id: 0,
          cost_center_id: 0,
          bill_number: "",
          bill_desc: "",
          machine_no: "",
          bill_date: "2023-09-27",
          for_month_of: "2023-09-27",
          bill_amount: 0,
          remarks: "",
          travel_by: "",
          reason_for_expense: "",
          reason_for_deduction: "",
          approved_amount: 0,
        },
      ],
    };

    let payload = {
      expense_type: traveluserDetails.expense_type,
      status: traveluserDetails.status,
      total_bill_amount: traveluserDetails.total_bill_amount,
      attachment_path: [],
      expense_data: traveluserDetails.expense_data,
    };

    switch (traveluserDetails.expense_type.toLowerCase()) {
      case "other":
        payload["user_organisation_id"] =
          traveluserDetails?.user_organisation_id;
        break;
      case "petty":
        payload["organisation_id"] = traveluserDetails?.organisation_id;
        break;
      default:
        break;
    }

    console.log(`saving...`, payload);

    await updateOtherExpense(traveluserDetails?.id, payload);
    
    await delay(2000); // Delay for 2 seconds

    
    //   }
    // })

    // updateHotelExpense(travelDetails?.travel_id,updated_hotels)

    console.log(`line 2....`, apprPayload);

    try{
      dispatch(setDisplayLoader("Display"));
      await  userGl
      .glCostCenterVerify(
        `id=${traveluserDetails?.id}&exptype=other`
      )
      .then((response) => {
        console.log(`otherexp...`,response.data);
        dispatch(setDisplayLoader("Hide"));
        if(response.data==null){

          // toast.success(`response.data`)
          
          // return

        }
        else{
          toast.error(response.data)
          throw new Error("Some error")
        }
      
      })
      .catch((error) => {
        toast.error(error)
        console.log("error response=>",error);

        throw error
        
      });
    }catch(error){
      // toast.error(error.message)
      console.log(`inside catch error response`);

      return null;
    }
    // return

    for (let i = 0; i < finalUser.groups.length; i++) {
      // const payloa={
      //   "pick_list_id": [
      //     `${pick_item_id}`
      //   ],
      //   "status": "APPROVED",
      //   "reason": [
      //     {
      //       "applied_amount":"",
      //       "approved_amount":"",
      //       "expense_type":"",
      //       "reason":""

      //     }

      //   ]
      // }

      
      dispatch(setDisplayLoader("Display"));

     

        // return
        // console.log(`error response not exit return error....`);
      
      

      if (finalUser.groups[i].name.toLowerCase() == "ssc user") {
        await otherExpService
          .otherExpSSCAppr(finalUser.groups[i].name, apprPayload)
          .then((resp) => {
            toast.success("Expense approved successfully");
          })
          .catch((error) => {
            toast.error(error.response.data?.message);
            console.log(`error approve check!`, error);
          });

        navigate(`/otherexp-check`);
        break;
      } else if (finalUser.groups[i].name.toLowerCase() == "ssc manager") {
        let resp_result = {
          response: {},
          is_error: false,
          is_success: false,
          reqid:
            (traveluserDetails?.expense_type == "petty"
              ? `${traveluserDetails?.organisation_code}PE`
              : `${traveluserDetails?.user_organisation_code}OE`) +
            traveluserDetails?.id,
        };

        await otherExpService
          .otherExpSSCAppr(finalUser.groups[i].name, apprPayload)
          .then((resp) => {
            resp_result["response"] =
              resp?.data?.dataList?.res_list[0]?.sap_no_self +
              " " +
              resp?.data?.dataList?.res_list[0]?.sap_no_company;
            resp_result["is_success"] = true;

            toast.success("Expense approved successfully");
          })
          .catch((error) => {
            resp_result["response"] = error?.response?.data?.message;
            resp_result["is_error"] = true;
            toast.error(error.response.data?.message);
            console.log(`error approve check!`, error);
          });

        console.log(`navigati..`, resp_result);

        navigate(`/otherexp-check`, { state: { sapdata: resp_result } });
        break;
      }
      // else if(finalUser.groups[i].name.toLowerCase()=="ssc head" ){
      //   await TravelListingAPI.headapprovePickList(apprPayload).then((resp)=>{

      //     toast.success("success!")

      //   })
      //    .catch((error)=>{
      //     toast.error("fail!")
      //     console.log(`error approve check!`,error);
      //   })

      //   navigate(`/otherexp-check`)
      //   break
      // }
    }

    // })
    // .catch((error)=>{
    //   console.log(`error approve check!`,error);
    // })
  }

  const handleShow = () => setReasonShow(true);
  const handleClose = () => {
    set_inputReason("");
    setReasonShow(false);
  };

  const handleReasonView = () => {
    handleShow();
  };

  const sendBackToUser2 = async () => {
    console.log(`other`, traveluserDetails?.id);

    // let inputReason = prompt("Reason to send back:-")
    // if(inputReason==null || inputReason==""){

    // }
    // else{

    console.log(`reason..`, inputReason);
    let ssc_user = true;
    let msg = "";
    msg = "Send back to User success.";

    if (userGroup._userGroup == "ssc manager") {
      ssc_user = false;
      msg = "Send back to SSC Executive success.";
    }

    console.log(
      `payl`,
      `other_exp_id=` +
        traveluserDetails?.id +
        `&ssc_user=` +
        ssc_user +
        `&remark=` +
        inputReason
    );

    // return

    await TravelListingAPI.otherExpSendBack(
      `other_exp_id=` +
        traveluserDetails?.id +
        `&ssc_user=` +
        ssc_user +
        `&remark=` +
        inputReason
    )
      .then((resp) => {
        toast.success(msg);
        navigate(`/otherexp-check`);
      })
      .catch((error) => {
        toast.error("fail!");
        console.log(`error approve check!`, error);
      });

    // }
  };

  /** travel code  * */
  async function sendBackToUser() {
    approvedSendBack_payload["status"] = "SEND BACK TO USER";

    for (let i = 0; i < finalUser.groups.length; i++) {
      // const payloa={
      //   "pick_list_id": [
      //     `${pick_item_id}`
      //   ],
      //   "status": "SEND BACK TO USER"
      // }

      if (finalUser.groups[i].name.toLowerCase() == "ssc user") {
        await TravelListingAPI.approvePickList(approvedSendBack_payload)
          .then((resp) => {
            toast.success("success!");
          })
          .catch((error) => {
            toast.error("fail!");
            console.log(`error approve check!`, error);
          });

        navigate(`/travel-expense`);
        break;
      } else if (finalUser.groups[i].name.toLowerCase() == "ssc manager") {
        await TravelListingAPI.managerapprovePickList(approvedSendBack_payload)
          .then((resp) => {
            toast.success("success!");
          })
          .catch((error) => {
            toast.error("fail!");
            console.log(`error approve check!`, error);
          });

        navigate(`/travel-approve-expense`);
        break;
      } else if (finalUser.groups[i].name.toLowerCase() == "ssc head") {
        await TravelListingAPI.headapprovePickList(approvedSendBack_payload)
          .then((resp) => {
            toast.success("success!");
          })
          .catch((error) => {
            toast.error("fail!");
            console.log(`error approve check!`, error);
          });

        navigate(`/travel-approve-expense`);
        break;
      }
    }
  }

  const getGstState = (paginate) => {
    ClaimTravel.getGstState(paginate)
      .then((response) => {
        setGstState(response?.data?.dataList?.result);
        let data = [];
        // response?.data?.dataList?.result.map((item) => {
        //   let data1 = {
        //     value: item?.id,
        //     label: `${item?.gst_state_code}-${item?.name}`,
        //   };
        //   data.push(data1);
        // });
        // setGstState(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  // async function viewAttachments(){

  //   await TravelListingAPI.viewCheckExpenseAttachments(travelPostData.travel_id).then((response)=>{

  //     console.log(`docs resp=>`,response?.data);

  //   })
  //   .catch((error)=>{
  //     console.log(`error viewing docs!`,error);
  //   })

  // }

  const modifyExpense = async (data, _idx, _key, vall) => {
    let _expense_data = [...traveluserDetails.expense_data];
    console.log(vall, _idx, data.bill_amount);

    if (_key == "approved_amount") {
      vall = parseFloat(vall);

      if (vall > _expense_data[_idx].bill_amount) {
        apprErrs[`${_idx}`] = {
          isErr: true,
          emptyreason: false,
        };
      } else if (_expense_data[_idx].bill_amount - vall > 5) {
        apprErrs[`${_idx}`] = {
          isErr: false,
          isLessAmt: true,
          emptyreason:
            _expense_data[_idx].reason_for_deduction?.length > 0 ? false : true,
        };
      } else if (_expense_data[_idx].bill_amount - vall <= 5) {
        apprErrs[`${_idx}`] = {
          isErr: false,
          isLessAmt: false,
          emptyreason: false,
        };
      }
      // else{
      //    apprErrs[`${_idx}`]={
      //     isErr: false,
      //     emptyreason:_expense_data[_idx].reason_for_deduction ? false:true

      //    }
      // }
    }

    if (_key == "gl_id") {
      glAcc?.every((gdata, idx) => {
        if (gdata?.gl_id == vall) {
          _expense_data[_idx]["gl_id"] = vall;
          _expense_data[_idx]["gl_account"] = gdata?.gl_account;
          _expense_data[_idx]["gl_desc"] = gdata?.gl_desc;

          return false;
        }
        return true;
      });
    } else {
      _expense_data[_idx][_key] = vall;
    }

    if (_key == "reason_for_deduction") {
      if (Object.keys(apprErrs).includes(`${_idx}`)) {
        apprErrs[`${_idx}`]["emptyreason"] = _expense_data[_idx]
          .reason_for_deduction
          ? false
          : true;
      } else {
        apprErrs[`${_idx}`] = {
          emptyreason: _expense_data[_idx].reason_for_deduction ? false : true,
        };
      }
    }

    setTraveluserDetails({
      ...traveluserDetails,
      expense_data: _expense_data,
    });

    // setoverallSums({
    //   claimAmountsum:0,
    //   apprAmountsum:0
    // })

    calcSums(_expense_data, "y");
  };

  const addnewDetail = async (data, idx) => {
    let _expense_data = [...traveluserDetails.expense_data];
    _expense_data.splice(idx + 1, 0, _expense_data[idx]);

    setTraveluserDetails({
      ...traveluserDetails,
      expense_data: _expense_data,
    });
    overallSums["claimAmountsum"] += data?.bill_amount;
  };

  const removenewDetail = async (data, idx) => {
    let _expense_data = [...traveluserDetails.expense_data];
    _expense_data.splice(idx, 1);

    setTraveluserDetails({
      ...traveluserDetails,
      expense_data: _expense_data,
    });

    overallSums["claimAmountsum"] -= data?.bill_amount;
  };

  const updateOtherExpense = async (id, payload) => {
    otherExpService
      .updateOtherExpense(id, payload)
      .then((resp) => {
        // toast.success("updated")
      })
      .catch((err) => {
        toast.error(`error updating`);
      });
  };
  
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));


  const calcSums = async (_data, edit = "") => {
    overallSums.apprAmountsum = 0;
    if (edit) {
      _data?.map((data) => {
        overallSums.apprAmountsum += data?.approved_amount;
      });
      return;
    }

    _data?.map((data) => {
      overallSums.claimAmountsum += data?.bill_amount;
      overallSums.apprAmountsum += data?.approved_amount;
    });
  };

  // useEffect(() => {

  //   let localGroup=""

  //   finalUser.groups.every(
  //       (data) =>
  //         {
  //           if(data?.name.toLowerCase() === "ssc user" || data?.name.toLowerCase() === "ssc manager"){
  //               localGroup=data?.name.toLowerCase()
  //               console.log(`-->`,localGroup);
  //               userGroup._userGroup=localGroup
  //               return false
  //           }
  //           return true
  //         }
  //     )

  //     let travel_id = location?.state?.travel_id;
  //     set_backPath(location?.state?.backpath);
  //     let predata = location?.state?.predata
  //     calcSums(predata?.expense_data)
  //     console.log(`check..`,predata);
  //     getAssignedGLDropDown(predata.expense_type,predata.user_id)
  //     setTraveluserDetails(predata)
  //     travelPostData.travel_id=travel_id

  //     getTravelChat(travel_id)

  //   // getConveyenceList(travel_id);

  // getTraveluserDetailsById(travel_id);
  //   // getTravelDetailsById(travel_id);

  //   // getTicketExpense(travel_id, 1578);

  //   // getVehicleExpense(travel_id, user_id);

  //   // getHotelExpense(travel_id, user_id);
  //   // getDAExpense(travel_id, user_id);
  // }, []);

  function setTwoNumberDecimal(event) {
    event.target.value = parseFloat(event.target.value).toFixed(2);
  }

  const handleKey1 = (
    status,
    is_picked,
    page_no,
    page_size,
    sort_by,
    paginate,
    emp_code,
    org,
    reqid,
    search,
    ssc_user_status
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (emp_code !== "" && emp_code !== undefined && emp_code !== null) {
      queryParm = queryParm + "&emp_code=" + emp_code;
    }
    if (is_picked !== "" && is_picked !== undefined && is_picked !== null) {
      queryParm = queryParm + "&is_picked=" + is_picked;
    }
    if (
      ssc_user_status !== "" &&
      ssc_user_status !== undefined &&
      ssc_user_status !== null
    ) {
      queryParm = queryParm + "&ssc_user_status=" + ssc_user_status;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (reqid !== "" && reqid !== undefined && reqid !== null) {
      queryParm = queryParm + "&id=" + reqid;
    }
    if (org !== "" && org !== undefined && org !== null) {
      queryParm = queryParm + "&org=" + org;
    }
    return queryParm;
  };

  const getUserDetail = (userid) => {
    console.log(`call`, userid);
    otherExpService
      .getUserDetail(`user_id=` + userid)
      .then((resp) => {
        setTraveluserData(resp?.data?.dataList?.result[0]);
      })
      .catch((err) => {
        toast.error("Error getting user details");
      });
  };


  const getGlCode = (organisation_id, value) => {
    userGl
      .getGlCode(organisation_id, value)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let dataGl = {
            value: x?.gl_account,
            gl_id: x?.id,
            label: `${x?.gl_account}-${x?.short_desc} ${x?.is_blocked?"- Blocked":""}`,
          };
          data.push(dataGl);
        });
        let pp = data.filter(
          (ele, ind) =>
            ind === data.findIndex((elem) => elem.label === ele.label)
        );
        setglAcc(pp);
        // getPersonalArea();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };


  // const glCostCenterVerify=async(id)=>{
   


  // }

  const getOtherExp = (
    status,
    is_picked,
    pageNo,
    pageSize,
    sort_by,
    paginate,
    emp_code,
    org,
    reqid,
    search,
    ssc_user_status
  ) => {
    dispatch(setDisplayLoader("Display"));

    otherExpService
      .getOtherExp(
        handleKey1(
          status,
          is_picked,
          pageNo,
          pageSize,
          sort_by,
          paginate,
          emp_code,
          org,
          reqid,
          search,
          ssc_user_status
        )
      )
      .then((response) => {
        // console.log(`otherexp...`,response);
        dispatch(setDisplayLoader("Hide"));
        // let data = response?.data?.result;
        // setVehicleData(response?.data?.dataList?.result);
        let ress = null;
        // console.log(`-->`,userGroup);
        switch (userGroup._userGroup) {
          case "ssc user":
            ress = response?.data?.dataList?.result.filter((data) => {
              return !data.ssc_user_status;
            });
            break;
          case "ssc manager":
            ress = response?.data?.dataList?.result.filter((data) => {
              return data.ssc_user_status === "A" && !data.ssc_manager_status;
            });
            break;
          default:
            break;
        }
        console.log(`log..`, ress, ress[0]?.user_id);
        // setotherExpData(ress);

        // getUserDetail(ress?.user_id)

        TravelListingAPI.getBackDateList(
          `user_id=${ress[0]?.user_id}&check_type=other`
        )
          .then((resp) => {
            setlastRecentBill((pre) => ({
              ...pre,

              back_date_from: resp?.data?.dataList?.result[0]?.from_date,
              back_date_to: resp?.data?.dataList?.result[0]?.to_date,
              back_date_opened_from:
                resp?.data?.dataList?.result[0]?.opened_from,
              back_date_opened_to: resp?.data?.dataList?.result[0]?.opened_to,
            }));
          })
          .catch((error) => {
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });

        setTraveluserDetails(ress[0]);
        calcSums(ress[0]?.expense_data);
        
        //for other
        if (ress[0]?.expense_type?.includes("ther")) {
          getGlCode("", 7);
        }
        else{

          getAssignedGLDropDown(ress[0]?.expense_type, ress[0].user_id,ress[0]);
        }
        
        getTravelChat(reqid);
        // setCurrentPage(response?.data?.dataList?.paginated_data?.currentPage)
        // setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const removeImageByIdx = (idx) => {
    console.log(`images bef`, imageLocalPreviewList);

    let newIList = [...imageLocalPreviewList];
    newIList.splice(idx, 1);

    // console.log(`images aft`,newIList);

    let newFList = [...filesToUpload];
    newFList.splice(idx, 1);

    set_imageLocalPreviewList(newIList);
    setFilesToUpload(newFList);
  };

  const todayDate = new Date();
  const previous = new Date(todayDate.getTime());
  previous.setDate(todayDate.getDate() - 1);
  const currentDate = moment(previous).format("YYYY-MM-DD");

  function getCurrentFiscalYear() {
    //get current date
    var today = new Date();

    //get current month
    var curMonth = today.getMonth();

    var fiscalYr = "";
    if (curMonth > 3) {
      //
      var nextYr1 = (today.getFullYear() + 1).toString();
      fiscalYr =
        today.getFullYear().toString() +
        "-" +
        nextYr1.charAt(2) +
        nextYr1.charAt(3);
    } else {
      var nextYr2 = today.getFullYear().toString();
      fiscalYr =
        (today.getFullYear() - 1).toString() +
        "-" +
        nextYr2.charAt(2) +
        nextYr2.charAt(3);
    }

    return fiscalYr.split("-")[0];
  }

  useEffect(() => {
    let qry = new URLSearchParams(window.location.search);
    let transaction_id = qry.get("id");

    let localGroup = "";

    finalUser.groups.every((data) => {
      if (
        data?.name.toLowerCase() === "ssc user" ||
        data?.name.toLowerCase() === "ssc manager"
      ) {
        localGroup = data?.name.toLowerCase();
        console.log(`-->`, localGroup);
        userGroup._userGroup = localGroup;
        return false;
      }
      return true;
    });

    if (localGroup == "ssc manager") {
      getOtherExp(
        "",
        "",
        "1",
        10,
        "-id",
        true,
        "",
        "",
        transaction_id,
        "",
        "A"
      );
    } else {
      travelPostData.travel_id = transaction_id;

      getOtherExp(
        "A",
        true,
        "1",
        10,
        "-id",
        true,
        "",
        "",
        transaction_id,
        "",
        ""
      );
    }

    TravelListingAPI.getOtherExpenseList(`sort_by=-id&user_id=${finalUser?.id}`)
      .then((response) => {
        lastRecentBill["max_bill_date"] =
          response?.data?.dataList?.max_bill_date;

        response?.data?.dataList?.result.every((data) => {
          if (
            data?.expense_type.toLowerCase() == "other" &&
            data?.status == "A"
          ) {
            console.log(`datt other`, data);

            lastRecentBill["other"] = data;
            setlastRecentBill({
              ...lastRecentBill,
              other: data,
            });

            return false;
          }
          return true;
        });

        response?.data?.dataList?.result.every((data) => {
          if (data?.expense_type.toLowerCase() == "petty") {
            lastRecentBill["petty"] = data;

            setlastRecentBill({
              ...lastRecentBill,
              petty: data,
            });

            return false;
          }
          return true;
        });

        // setlastRecentBill(response.data)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  }, []);

  const getTravelChat = (id) => {
    dispatch(setDisplayLoader("Display"));

    otherExpService
      .getOtherExpChat(id)
      .then((response) => {
        console.log("chat->", response?.data?.dataList?.result);
        setTravelChats(response?.data?.dataList?.result);
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((err) => {
        // toast.error("")
      });
  };

  const saveTicket = (payload) => {
    ClaimTravel.saveTicket(payload)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          toast.success("created!");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateTicket = (payload, id) => {
    ClaimTravel.updateTicket(payload, id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          toast.success("updated");
          // getTicketData(state?.id);
          // setCheckActiveTab();
          // navigate("/expense-listing");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteTicket = (del_id) => {
    // let payload = {
    //   travel_id: state?.id,
    //   attachment_path: uploadAttachments.attachment,
    //   ticket_data: removeNullVlaue(newData),
    // };
    ClaimTravel.deleteTicket(del_id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          toast.success("deleted..");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  // const postTravelChat = (id) => {
  //   TravelListingAPI.postTravelChat(id).then((response) => {
  //     console.log("posted chat->",response?.data?.dataList?.result);
  //   });
  // };

  const postTravelChat = async () => {
    // dispatch(setDisplayLoader("Display"));
    if (!travelPostData.message) {
      toast.error("Please enter query for sending it to ssc.");
      return;
    }
    let payl = { ...travelPostData };

    delete payl["travel_id"];

    if (
      userGroup._userGroup == "ssc user" ||
      userGroup._userGroup == "ssc manager"
    ) {
      payl["u_type"] = "SSC";
    } else {
      payl["u_type"] = "User";
    }

    console.log("chatter pay->", payl, "and", userGroup);

    let attachments2 = [];
    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      // continue;
      await uploadfile(filesToUpload[afilee])
        .then((response) => {
          console.log(`uploaded for index`, afilee);

          attachments2.push(response?.data);
          // console.log(`arrres..`,attachments2);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));

          toast.error(`${error} `);
          return;
        });
    }

    payl.attachment_list = [...attachments2];

    otherExpService
      .postOtherExpChat(travelPostData.travel_id, payl)
      .then((response) => {
        // console.log("posted chat->",payl);
        getTravelChat(travelPostData.travel_id);
        set_travelPostData({
          ...travelPostData,
          message: "",
          attachment_path: null,
          attachment_list: [],
        });
        document.getElementById("querytext").value = "";
        dispatch(setDisplayLoader("Hide"));
        setselectedFiles([]);
        setFilesToUpload([]);
        set_imageLocalPreviewList([]);
      })
      .catch((err) => {
        dispatch(setDisplayLoader("Hide"));

        toast.error("Unable to post data.");
      });
  };

  const deleteTicketData = (_idx) => {
    let _travelDetails = [];
    console.log(`del...`, _idx);
    console.log(`deta`, travelDetails);

    newticketDetails.forEach((itemm, idx) => {
      if (_idx != idx) {
        _travelDetails.push(itemm);
      } else {
        if (itemm.id) {
          delete_tickets_ids.push(itemm?.id);
        }
      }
    });

    console.log(`_trav...`, delete_tickets_ids);

    set_newTicketDetails(_travelDetails);
  };

  const updateReasons = (_idx, _reason) => {
    let _approvedSendBack_payload = { ...approvedSendBack_payload };
    _approvedSendBack_payload.reason[_idx]["reason"] = _reason;
    set_approvedSendBack_payload(_approvedSendBack_payload);
  };

  const updateHotelExpense = async (trav_id, data) => {
    let payload = {
      attachment_path: {},
      hotel_data: data,
    };

    await TravelListingAPI.updateHotelExpense(trav_id, payload)
      .then((resp) => {
        toast.success("hotel updated");
      })
      .catch((error) => {
        toast.error("fail hotel!");
        console.log(`error approve check!`, error);
      });
  };

  const updateDAExpense = async (trav_id, data) => {
    let payload = {
      // "maximum_entitlement": 0,
      // "total_amount": 0,
      attachment_path: [],
      da_data: data,
    };
    await TravelListingAPI.updateDa(payload, trav_id)
      .then((resp) => {
        toast.success("da updated");
      })
      .catch((error) => {
        toast.error("fail da update!");
        console.log(`error approve check!`, error);
      });
  };

  const updateVehiLog = async (trav_id, data) => {
    let payload = {
      attachment_path: [],
      vehicle_data: data,
    };

    await updateVehicleLog(trav_id, payload)
      .then((resp) => {
        toast.success("vehicle log updated");
      })
      .catch((error) => {
        toast.error("fail vehicle!");
        console.log(`error approve check!`, error);
      });
  };

  const updateConveyanceExpense = async (trav_id, data) => {
    let payload = {
      attachment_path: {},
      conveyance_data: data,
    };

    await TravelListingAPI.updateConveyanceExpense(trav_id, payload)
      .then((resp) => {
        toast.success("Locaonv updated");
      })
      .catch((error) => {
        toast.error("fail Loconvey!");
        console.log(`error approve check!`, error);
      });
  };

  const modifyFinalSumDetails = (_Lkey = "", _Lval = "", _Rkey = "") => {
    switch (_Lkey) {
      case "totalTicketValue":
        finalDetails[_Rkey] += _Lval;

        finalDetails.final_approvedTotal = finalDetails.final_claim;
        finalDetails.final_netAmt = finalDetails.final_approvedTotal;

        break;

      case "totalTicketValue2":
        break;

      default:
        break;
    }
  };

  function calcApprovedTicket(_ndata) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0;
    _ndata?.map((data) => {
      totalticketvalue += data?.tax_bill_amount;
      totalticketvalue2 += data?.appr_total_bill_amount;
    });

    setTotalTicketValue(totalticketvalue);
    setTotalTicketValue2(totalticketvalue2);

    finalDetails.final_approvedSelfExp =
      totalDaValue +
      totalticketvalue +
      totalHotelValue +
      totalConveValue +
      totalVehLogValue;
    finalDetails.final_netAmt =
      totalDaValue2 +
      totalticketvalue2 +
      totalHotelValue2 +
      totalConveValue2 +
      totalVehLogValue2;
    finalDetails.final_claim =
      finalDetails.final_approvedCompExp + finalDetails.final_approvedSelfExp;
    finalDetails.final_approvedTotal = totalticketvalue2 + totalConveValue2;
  }

  function calcApprovedConveyance(_ndata) {
    let totalConveValue = 0,
      totalConveValue2 = 0;

    _ndata?.map((data) => {
      totalConveValue += data?.amount;
      totalConveValue2 += data?.approved_amount;
    });

    setTotalConveValue(totalConveValue);
    setTotalConveValue2(totalConveValue2);

    finalDetails.final_approvedTotal = totalTicketValue2 + totalConveValue2;
  }

  function calcApprovedDA(_ndata) {
    let da = 0,
      da2 = 0;

    _ndata?.map((data) => {
      da += data?.gross_total;
      // totalConveValue2+=data?.approved_amount
    });

    setTotalDaValue(da);
    setTotalDaValue2(da);

    finalDetails.final_approvedTotal = totalTicketValue2 + totalConveValue2;
  }

  function calcApprovedHotel(_ndata) {
    let totalhotval = 0,
      totalhotval2 = 0;

    _ndata?.map((data) => {
      totalhotval += data?.tax_bill_amount;
      totalhotval2 += data?.appr_amount;
    });

    setTotalHotelValue(totalhotval);
    setTotalHotelValue2(totalhotval2);

    // finalDetails.final_approvedTotal=totalTicketValue2+totalConveValue2
  }

  const modifyTicket = (_dataObj, iidx = -1, _k = "", val = "") => {
    let vall = parseInt(val);
    console.log(`typing..`, iidx, vall);
    let _travelDetails = [...newticketDetails];

    if (iidx >= 0 && _k) {
      switch (_k) {
        case "appr_tax_rate":
          taxRatesMenu?.every((data) => {
            if (data?.pi_tax_id == vall) {
              _travelDetails[iidx]["appr_tax_amount"] =
                _travelDetails[iidx]?.appr_taxable_amount *
                0.01 *
                parseFloat(data?.name);
              _travelDetails[iidx]["appr_total_bill_amount"] =
                _travelDetails[iidx]?.appr_tax_amount +
                _travelDetails[iidx]?.appr_taxable_amount +
                _travelDetails[iidx]?.appr_other_charges;
              // modifyFinalSumDetails()
              // setTotalTicketValue2()
              return false;
            }
            return true;
          });
          break;

        case "appr_other_charges":
          let temp =
            _travelDetails[iidx]["appr_total_bill_amount"] -
            newticketDetails[iidx][_k];
          _travelDetails[iidx]["appr_total_bill_amount"] = temp + vall;
          break;

        default:
          _travelDetails[iidx][_k] = val;
          break;
      }

      set_newTicketDetails(_travelDetails);
      calcApprovedTicket(_travelDetails);
    }

    if (_dataObj?.id) {
      for (let _i = 0; _i < updated_tickets.length; _i++) {
        if (updated_tickets[_i]["id"] == _dataObj?.id) {
          updated_tickets[_i] = _dataObj;
          return;
        }
      }

      updated_tickets.push(_dataObj);
    }
  };

  const modifyHotel = (_dataObj, iidx = -1, _k = "", val = "") => {
    let vall = parseInt(val);
    console.log(`typing..`, iidx, vall, _dataObj?.id);
    let _hotelDetails = [...hotelDetails];

    if (iidx >= 0 && _k) {
      // _hotelDetails[iidx][_k]=vall

      switch (_k) {
        case "appr_tax_rate":
          console.log(`sent`, vall);
          taxRatesMenu?.every((data) => {
            if (data?.pi_tax_id == vall) {
              console.log(`got 12`);
              _hotelDetails[iidx]["appr_tax_amount"] =
                _hotelDetails[iidx]?.appr_claim_amount *
                0.01 *
                parseFloat(data?.name);
              _hotelDetails[iidx]["appr_amount"] =
                _hotelDetails[iidx]?.appr_claim_amount +
                _hotelDetails[iidx]?.appr_tax_amount +
                _hotelDetails[iidx]?.appr_other_charges;
              // modifyFinalSumDetails()
              // setTotalTicketValue2()
              return false;
            }
            return true;
          });
          break;

        case "appr_other_charges":
          let temp =
            _hotelDetails[iidx]["appr_amount"] - hotelDetails[iidx][_k];
          _hotelDetails[iidx]["appr_amount"] = temp + vall;
          break;

        default:
          _hotelDetails[iidx][_k] = val;
          break;
      }
      _hotelDetails[iidx]["updated_by_ssc_user_id"] = `${finalUser?.id}`;
      setHotelDetails(_hotelDetails);
    }

    // if(_dataObj?.id){

    for (let _i = 0; _i < updated_hotels.length; _i++) {
      if (updated_hotels[_i]["id"] == _dataObj?.id) {
        updated_hotels[_i] = _dataObj;
        return;
      }
    }

    updated_hotels.push(_hotelDetails[iidx]);
    calcApprovedHotel(_hotelDetails);
    // }
  };

  const modifyDA = (_dataObj, iidx = -1, _k = "", vall = "") => {
    console.log(`typing..`, iidx, vall);
    let _daDetails = [...daDetails];

    if (iidx >= 0 && _k) {
      _daDetails[iidx][_k] = vall;
      if (_k == "appr_da_remarks")
        _dataObj.SelectedKey = {
          // value: 50,
          value: vall,
          label: vall,
        };

      _daDetails[iidx]["updated_by_ssc_user_id"] = `${finalUser?.id}`;
      setDADetails(_daDetails);
    }

    // if(_dataObj?.id){

    for (let _i = 0; _i < updated_da.length; _i++) {
      if (updated_da[_i]["id"] == _dataObj?.id) {
        updated_da[_i][_k] = _dataObj.remarks;
        return;
      }
    }

    updated_da.push(_daDetails[iidx]);

    // updated_da.push({
    //   "id":_dataObj?.id,
    //   "gross_total":_dataObj?.gross_total,
    //   "remarks":_dataObj.remarks,
    //   "bill_data": [ ],
    //   "updated_by_ssc_user_id":`${finalUser?.id}`
    // })
    // }
  };

  const modifyConveyance = (_dataObj, iidx = -1, _k = "", val = "") => {
    let vall = parseFloat(val);
    console.log(`typing..`, iidx, vall);
    let _conveyanceDetails = [...conveyanceDetails];

    let temp = 0;

    if (iidx >= 0 && _k) {
      _conveyanceDetails[iidx]["updated_by_ssc_user_id"] = `${finalUser?.id}`;
      setTotalConveValue2(
        totalConveValue2 - _conveyanceDetails[iidx][_k] + vall
      );
      _conveyanceDetails[iidx][_k] = vall;

      setConveyanceDetails(_conveyanceDetails);
    }

    // if(_dataObj?.id){

    for (let _i = 0; _i < updated_conveyance.length; _i++) {
      if (updated_conveyance[_i]["id"] == _dataObj?.id) {
        updated_conveyance[_i]["approved_amount"] = _dataObj.approved_amount;
        return;
      }
    }
    updated_conveyance.push(_conveyanceDetails[iidx]);
    // }
  };

  const modifyVehicle = (_dataObj, iidx = -1, _k = "", vall = "") => {
    console.log(`typing..`, iidx, vall);
    let _vehicleDetails = [...vehicleDetails];

    if (iidx >= 0 && _k) {
      _vehicleDetails[iidx][_k] = vall;
      _vehicleDetails[iidx]["updated_by_ssc_user_id"] = `${finalUser?.id}`;
      setVehicleDetails(_vehicleDetails);
    }

    // if(_dataObj?.id){

    for (let _i = 0; _i < updated_vehLogs.length; _i++) {
      if (updated_vehLogs[_i]["id"] == _dataObj?.id) {
        updated_vehLogs[_i] = _dataObj;

        return;
      }
    }

    updated_vehLogs.push(_vehicleDetails[iidx]);
    // }
  };

  const addNewTickets = (_idx) => {
    let _travelDetails = [...newticketDetails];

    let itemm = {
      ...newticketDetails[_idx],
    };

    itemm["id"] = "";
    _travelDetails.push(itemm);

    // console.log(`_trav...`,_travelDetails);

    set_newTicketDetails(_travelDetails);
  };

  const getTraveluserDetailsById = (id) => {
    TravelListingAPI.getTravelById(id).then((response) => {
      setTraveluserDetails(response?.data?.dataList?.result);
    });
  };

  const getTravelDetailsById = (id) => {
    TravelListingAPI.trav_detail(id)
      .then((resp) => {
        resp?.data?.dataList?.ssc_data.forEach((itemm, idx) => {
          if (itemm.current_status == "Documents Received")
            set_pickItemId(itemm.id);
          approvedSendBack_payload["pick_list_id"].push(`${itemm.id}`);
        });

        let dadata = resp?.data?.dataList?.da_expense;
        let timeCalc = dadata?.map((dd, i) => {
          let tt = dd?.hours;
          dd.SelectedKey = {
            // value: 50,
            value: dd?.appr_da_remarks,
            label: dd?.appr_da_remarks,
          };
          // dd.dropDownKey = [{ value: 0, label: "No DA Claim" }];

          if (tt > 16) {
            dd.dropDownKey = [
              // {
              //   //  value: 0,
              //    value: "No DA Claim" ,
              //    label: "No DA Claim"
              //   },
              {
                // value: 20,
                label: "All Food Provided by the company",
                value: "All Food Provided by the company",
              },
              {
                // value: 40,
                label: "Lunch and Dinner provided by the company",
                value: "Lunch and Dinner provided by the company",
              },
              {
                //   // value: 50,
                value: "Breakfast and Lunch/Dinner provided by the company",
                label: "Breakfast and Lunch/Dinner provided by the company",
              },
              {
                // value: 70,
                label: "Lunch/Dinner provided by the company",
                value: "Lunch/Dinner provided by the company",
              },
              {
                // value: 80,
                label: "Breakfast provided by the company",
                value: "Breakfast provided by the company",
              },
              {
                // value: 100,
                label: "All food expenses paid by the employee",
                value: "All food expenses paid by the employee",
              },
            ];
          } else if (tt > 8 && tt <= 16) {
            dd.dropDownKey = [
              {
                //  value: 0,
                vale: "No DA Claim",
                label: "No DA Claim",
              },
              {
                // value: 50,
                label: "All food expenses paid by the employee",
                label: "All food expenses paid by the employee",
              },
            ];
          } else if (tt > 4 && tt <= 8) {
            dd.dropDownKey = [
              {
                //  value: 0,
                value: "No DA Claim",
                label: "No DA Claim",
              },
              {
                // value: 30,
                label: "All food expenses paid by the employee",
                label: "All food expenses paid by the employee",
              },
            ];
          }

          // else if (tt > 4) {
          //   dd.dropDownKey = [
          //     { value: 0, label: "No DA Claim" },
          //     {
          //       value: 30,
          //       label: "All food expenses paid by the employee",
          //     },
          //   ];
          // }
          return dd;
        });

        // let totalticketvalue=0,totalticketvalue2=0
        let totalHotelValue = 0,
          totalHotelValue2 = 0;
        let totalDaValue = 0,
          totalDaValue2 = 0;
        let totalVehLogValue = 0,
          totalVehLogValue2 = 0;
        // let totalConveValue=0,totalConveValue2=0

        setTravelDetails(resp?.data?.dataList);

        // modifyFinalSumDetails("",totalTicketValue,"final_claim")

        set_newTicketDetails(resp?.data?.dataList?.ticket_expense);
        calcApprovedTicket(resp?.data?.dataList?.ticket_expense);

        // resp?.data?.dataList?.hotel_expense?.map((data)=>{
        //   totalHotelValue+=data?.total_claim_amount
        //   totalHotelValue2+=data?.total_claim_amount

        // })

        // setTotalHotelValue(totalHotelValue)
        // setTotalHotelValue2(totalHotelValue2)
        setHotelDetails(resp?.data?.dataList?.hotel_expense);
        calcApprovedHotel(resp?.data?.dataList?.hotel_expense);

        setConveyanceDetails(resp?.data?.dataList?.conveyance_expense);
        calcApprovedConveyance(resp?.data?.dataList?.conveyance_expense);

        resp?.data?.dataList?.vehicle_expense?.map((data) => {
          for (const [key, value] of Object.entries(data)) {
            console.log(`${key}: ${value}`);
            if (typeof value == "number") {
              vehicleLogSums[key] += value;
            }
          }
        });

        setVehicleDetails(resp?.data?.dataList?.vehicle_expense);
        // setDADetails(resp?.data?.dataList?.da_expense)

        setDADetails(timeCalc);
        calcApprovedDA(timeCalc);
        // console.log(`...=>`,timeCalc);
      })
      .catch((error) => {
        toast.error("Error! in detail");
      });
  };

  const getConveyenceList = (id) => {
    TravelListingAPI.getConveyenceList(id).then((response) => {
      setTotalConveValue(response?.data?.dataList?.final_amount);

      setConveyanceDetails(response?.data?.dataList?.result);
    });
  };

  const getTicketExpense = (id, user_id) => {
    TravelListingAPI.getTicketExpense(id, user_id).then((response) => {
      let value = 0;
      response?.data?.dataList?.result.map((obj) => {
        value += obj?.tax_bill_amount;
      });
      setTotalTicketValue(value);
      setTicketDetails(response?.data?.dataList?.result);
    });
  };

  const getVehicleExpense = (id, user_id) => {
    TravelListingAPI.getVehicleExpense(id, user_id).then((response) => {
      let value = 0;
      response?.data?.dataList?.result.map((obj) => {
        value += obj?.total_amount;
      });
      setTotalVehLogValue(value);
      setVehicleDetails(response?.data?.dataList?.result);
    });
  };

  const getHotelExpense = (id, user_id) => {
    TravelListingAPI.getHotelExpense(id, user_id).then((response) => {
      let value = 0;
      response?.data?.dataList?.result.map((obj) => {
        value += obj?.total_claim_amount;
      });
      setTotalHotelValue(value);
      setHotelDetails(response?.data?.dataList?.result);
    });
  };

  const getDAExpense = (id, user_id) => {
    TravelListingAPI.getDAExpense(id, user_id).then((response) => {
      let value = 0;
      // response?.data?.dataList?.result.map((obj) => {
      //   value += obj?.gross_total;
      // });
      setTotalDaValue(response?.data?.dataList?.final_gross_total);
      setDADetails(response?.data?.dataList?.result);
    });
  };

  const handleChange = (e, name) => {};

  //   const Validate=(approvedAmount,billAmount)=>{
  //   let diff=0
  //   diff=approvedAmount-billAmount
  //   if (diff>5){
  //     return true && isErr
  //   }
  //   else{
  //     // setisErr(false)
  //     return false
  //   }

  // }

  useEffect(() => {
    traveluserDetails?.expense_data?.forEach((data, index) => {
      if (data?.gl_account == "" && data?.gl_account == null) {
        setApprovedDisable(true);
      } else {
        setApprovedDisable(false);
      }
    });
  }, [traveluserDetails]);
  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <div class="defaultchatgptquest text-right">
                      <Link
                        to={
                          location?.state?.subId
                            ? `/common-approve/${location?.state?.subId}`
                            : "/otherexp-check"
                        }
                        state={
                          location?.state?.subId
                            ? {
                                subtypeid: location?.state?.subId,
                                subTypeName: location?.state?.type,
                              }
                            : {}
                        }
                        class="badge badge-secondary"
                      >
                        <i class="fas fa-arrow-circle-left"></i> Back
                      </Link>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <h3 class="policyhead">Approve Expenses</h3>
                  </div>
                </div>

                <div class="row viewformaftersubmit">
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Emp No</label>
                      <p>{traveluserDetails?.user_code}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Name</label>
                      <p>{traveluserDetails?.user_name}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Status</label>
                      <p>{traveluserDetails?.user_status}ACTIVE</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>VENDOR CODE</label>
                      <p>{traveluserDetails?.user_vendor_code}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>REQUEST ID.</label>
                      {/* <p> {traveluserDetails?.expense_type=="petty"?`${traveluserDetails?.organisation_code}PE`:`${traveluserDetails?.user_organisation_code}OE`}{traveluserDetails?.id}</p> */}
                      <p>
                        {traveluserDetails?.request_id
                          ? traveluserDetails?.request_id
                          : traveluserDetails?.expense_type == "petty"
                          ? `${traveluserDetails?.organisation_code}PE`
                          : `${traveluserDetails?.user_organisation_code}OE` +
                            traveluserDetails?.id}
                      </p>
                    </div>
                  </div>
                  {/* <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>ZONE</label>
                        <p>{traveluserDetails?.zone}-</p>
                      </div>
                    </div> */}
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>LOCATION</label>
                      <p>{traveluserDetails?.user_location}</p>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>TYPE</label>
                      <p>{traveluserDetails?.expense_type}</p>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Grade</label>
                      <p>{traveluserDetails?.user_grade}</p>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Applied on</label>
                      <p>
                        {moment
                          .parseZone(traveluserDetails?.created_at)
                          .format("DD-MM-YYYY hh:mm A")}
                      </p>
                    </div>
                  </div>

                  {/* <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Status</label>
                        <p>Active</p>
                      </div>
                    </div> */}

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Reporting Manager</label>
                      <p>
                        {traveluserDetails?.rm_name +
                          `(${traveluserDetails?.rm_username})`}
                      </p>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Exception Approved By</label>
                      <p>
                        {traveluserDetails?.is_exception
                          ? traveluserDetails?.exception_approver
                              ?.approver_name +
                            `(${traveluserDetails?.exception_approver?.approver_username})`
                          : "-"}
                      </p>
                    </div>
                  </div>

                  {/* <div class="col-md-6 viewformaftersubmit">
                  <div class="form-group innergroup">
                    <label>Download Attachments</label>
                    <p>
                      <span class="mr-3">
                        <i class="fas fa-file-download"></i> file.pdf
                      </span>{" "}
                      <span class="mr-3">
                        <i class="fas fa-file-download"></i> file-01.pdf
                      </span>
                    </p>
                  </div>
                </div> */}
                </div>
              </div>

              {traveluserDetails?.expense_data?.map((data, idx) => (
                <div class="row viewformaftersubmit m-0">
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        <span>{idx + 1}. </span>GL ACCOUNT*
                      </label>
                      <p>
                        {/* <select class="form-control newbgselect" 
                        disabled={userGroup._userGroup=="ssc manager"}

                            onChange={(e)=>modifyExpense(data,idx,"gl_id",e.target.value)}

                        >
                            {
                                glAcc?.map((gdata,idx)=>(

                                    <option value={gdata?.gl_id} selected={data?.gl_account===gdata?.gl_account}>{gdata?.gl_account}-{gdata?.gl_desc}</option>
                                ))
                            }
                        </select> */}
                        {/* <option value="">op1</option> */}
                        <Select
                          options={glAcc}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) =>
                            modifyExpense(data, idx, "gl_id", e.gl_id)
                          }
                          
                          disabled={userGroup._userGroup == "ssc manager"}
                          value={glAcc.find(
                            (obj) => data?.gl_account === obj?.value
                          )}
                        />
                      </p>
                    </div>
                  </div>

                  {/* {console.log(`glacc`,traveluserDetails?.expense_data)} */}
                  {data?.gl_account == "754001" && (
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>TRAVEL BY*</label>
                        <p>
                          <select
                            class="form-control newbgselect"
                            disabled={userGroup._userGroup == "ssc manager"}
                            onChange={(e) =>
                              modifyExpense(
                                data,
                                idx,
                                "travel_by",
                                e.target.value
                              )
                            }
                          >
                            {optionsTravel?.map((optravl, idx) => (
                              <option
                                value={optravl?.value}
                                selected={data?.travel_by === optravl?.value}
                              >
                                {optravl?.label}
                              </option>
                            ))}
                          </select>
                        </p>
                      </div>
                    </div>
                  )}

                  {fixMachines.includes(parseInt(data?.gl_account)) && (
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>MACHINE NO.*</label>
                        <p>
                          <input
                            class="form-control"
                            type="text"
                            value={data?.machine_no}
                            onChange={(e) =>
                              modifyExpense(
                                data,
                                idx,
                                "machine_no",
                                e.target.value
                              )
                            }
                          />
                        </p>
                      </div>
                    </div>
                  )}

                  {traveluserDetails?.expense_type?.toLowerCase() ==
                    "petty" && (
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          <span></span>COST CENTER*
                        </label>
                        <p>
                          {/* <select class="form-control newbgselect" 
                        disabled={userGroup._userGroup=="ssc manager"}
                        

                            onChange={(e)=>modifyExpense(data,idx,"cost_center_id",e.target.value)}

                        >
                            {
                                costCAcc?.map((ccdata,idx)=>(

                                    <option value={ccdata?.cost_center_id} selected={data?.cost_center_code===ccdata?.cost_center_code}>{ccdata?.cost_center_name}-{ccdata?.cost_center_code}</option>
                                ))
                              }
                        </select> */}
                          {/* <option value={data?.cost_center_id} selected>{data?.cost_center_name}-{data?.cost_center_code}</option> */}
                          <Select
                            options={costCAcc}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) =>
                              modifyExpense(
                                data,
                                idx,
                                "cost_center_id",
                                e.cost_center_id
                              )
                            }
                            disabled={userGroup._userGroup == "ssc manager"}
                            value={costCAcc.find(
                              (obj) =>
                                data?.cost_center_id == obj.cost_center_id
                            )}
                          />
                        </p>
                      </div>
                    </div>
                  )}

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>BILL NO.*</label>
                      <p>
                        <input
                          disabled={userGroup._userGroup == "ssc manager"}
                          class="form-control"
                          type="text"
                          onChange={(e) =>
                            modifyExpense(
                              data,
                              idx,
                              "bill_number",
                              e.target.value
                            )
                          }
                          value={data?.bill_number}
                        />
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>BILL DATE*</label>
                      <p>
                        <input
                          disabled={userGroup._userGroup == "ssc manager"}
                          class="form-control"
                          type="date"
                          // max={
                          //   new Date(lastRecentBill?.back_date_opened_from) <= new Date (currentDate) && new Date (currentDate)   <= new Date (lastRecentBill?.back_date_opened_to)
                          // ? lastRecentBill?.back_date_to

                          // : currentDate}

                          // min={

                          //   new Date(lastRecentBill?.back_date_opened_from) <= new Date (currentDate) && new Date (currentDate)   <= new Date (lastRecentBill?.back_date_opened_to)
                          //   ? lastRecentBill?.back_date_from

                          //   :(

                          //     lastRecentBill?.max_bill_date
                          //           ? moment.parseZone(lastRecentBill?.max_bill_date)
                          //                     .add(1, "days")
                          //                     .format("YYYY-MM-DD")

                          //           : (
                          //             new Date(traveluserData?.joiningDate.date_of_joining).getFullYear() ==getCurrentFiscalYear()
                          //             ? (traveluserData?.joiningDate.date_of_joining)
                          //             :(`${getCurrentFiscalYear()}-04-01`)
                          //           )

                          //   )

                          // }

                          onChange={(e) =>
                            modifyExpense(
                              data,
                              idx,
                              "bill_date",
                              e.target.value
                            )
                          }
                          value={data?.bill_date}
                        />
                      </p>
                    </div>
                  </div>

                  {traveluserDetails?.expense_type
                    ?.toLowerCase()
                    ?.includes("petty") && (
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>FOR MONTH OF</label>
                        <p>
                          <input
                            disabled
                            class="form-control"
                            // type="number"

                            value={moment
                              .parseZone(data?.for_month_of)
                              .format("MMMM")}
                          />
                        </p>
                      </div>
                    </div>
                  )}

                  {traveluserDetails?.expense_type
                    ?.toLowerCase()
                    ?.includes("petty") && (
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>PAID BY</label>
                        <p>
                          <input
                            disabled
                            class="form-control"
                            // type="number"

                            value={data?.paid_by}
                          />
                        </p>
                      </div>
                    </div>
                  )}

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>BILL AMOUNT*</label>
                      <p>
                        <input
                          disabled
                          class="form-control"
                          type="number"
                          onChange={(e) =>
                            modifyExpense(
                              data,
                              idx,
                              "bill_amount",
                              e.target.value
                            )
                          }
                          value={data?.bill_amount}
                        />
                      </p>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>REASON FOR EXPENSE</label>
                      <p>
                        <input
                          disabled={userGroup._userGroup == "ssc manager"}
                          class="form-control"
                          type="text"
                          onChange={(e) =>
                            modifyExpense(
                              data,
                              idx,
                              "remarks",
                              e.target.value
                            )
                          }
                          value={data?.remarks}
                        />
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        APPROVED AMOUNT <span class="text-danger">*</span>
                      </label>
                      <p>
                        <input
                          disabled={userGroup._userGroup == "ssc manager"}
                          // step="0.25"
                          class="form-control"
                          type="number"
                          onChange={(e) => {
                            // setTwoNumberDecimal(e)
                            modifyExpense(
                              data,
                              idx,
                              "approved_amount",
                              parseFloat(e.target.value)?.toFixed(2)
                            );
                          }}
                          value={data?.approved_amount}
                        />
                        {console.log(
                          { apprErrs },
                          Object.keys(apprErrs)?.length > 0
                        )}
                        {/* {Validate(data?.approved_amount,data?.bill_amount) && <span class="text-danger">Approved amount cannot be greater than claimed amount.</span>} */}
                        {Object.keys(apprErrs)?.length > 0 &&
                          apprErrs[idx]?.isErr && (
                            <span class="text-danger">
                              Approved amount cannot be greater than claimed
                              amount.
                            </span>
                          )}
                      </p>
                    </div>
                  </div>

                  {/* { (Object.keys(apprErrs)?.length>0 && !apprErrs[idx]?.isErr  && traveluserDetails?.expense_data[idx]?.bill_amount!=data?.approved_amount) && <div class="col-md-3"> */}
                  {/* {    (Object.keys(apprErrs)?.length>0  && apprErrs[idx]?.isLessAmt) &&  */}
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        REASON FOR DEDUCTION <span class="text-danger">*</span>
                      </label>
                      <p>
                        <input
                          disabled={userGroup._userGroup == "ssc manager"}
                          class="form-control"
                          type="text"
                          onChange={(e) =>
                            modifyExpense(
                              data,
                              idx,
                              "reason_for_deduction",
                              e.target.value
                            )
                          }
                          value={data?.reason_for_deduction}
                        />
                        {Object.keys(apprErrs)?.length > 0 &&
                          apprErrs[idx]?.emptyreason && (
                            <span class="text-danger">
                              Reason for deduction can not be empty.
                            </span>
                          )}
                      </p>
                    </div>
                  </div>
                  {/* */}
                  <div class="col-md-3">
                    {idx == traveluserDetails?.expense_data?.length - 1 && (
                      <div class="form-group innergroup">
                        <button
                          hidden={userGroup._userGroup == "ssc manager"}
                          type="button"
                          class="btn btn-primary bpi-main my-4 py-2 f-14"
                          style={{ marginLeft: "3px" }}
                          onClick={() => addnewDetail(data, idx)}
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                        {traveluserDetails?.expense_data?.length > 1 && (
                          <button
                            hidden={userGroup._userGroup == "ssc manager"}
                            class="btn btn-primary bpi-main my-4 py-2 f-14"
                            style={{ marginLeft: "3px" }}
                            onClick={() => removenewDetail(data, idx)}
                          >
                            <i class="fas fa-minus"></i>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <div className="col-md-12 py-3">
                <div class=" float-right font-weight-bold">
                  Claim Amount: ₹{overallSums?.claimAmountsum?.toFixed(2)}{" "}
                  &nbsp;&nbsp;&nbsp;{" "}
                  {/* {traveluserDetails?.ssc_user_status
                    ? `Approved Total: ₹` +
                      overallSums.apprAmountsum?.toFixed(2)
                    : ""} */}
                    {`Approved Total: ₹` +
                      overallSums.apprAmountsum?.toFixed(2)}
                </div>
              </div>
              <div class="col-md-12 py-3 ">
                <div className="float-right p-3">
                  <button
                    class="btn btn-primary-inner bpi-main mr-2"
                    onClick={() => approveCheckExpense()}
                    disabled={approvedDisable == true}
                  >
                    APPROVE
                  </button>

                  <button
                    class="btn btn-primary-inner mr-2"
                    onClick={() =>
                      set_docsModel({
                        ...docsModel,
                        is_visible: true,
                      })
                    }
                    // onClick={()=>viewAttachments()}
                  >
                    VIEW ATTACHMENT
                  </button>
                  <button
                    class="btn btn-primary-inner mr-2"
                    onClick={addnewopen}
                  >
                    QUERY MANAGER
                  </button>
                  <button
                    class="btn btn-primary-inner mr-2"
                    // onClick={addnewopen}
                    // onClick={sendBackToUser2}
                    onClick={handleReasonView}
                  >
                    {userGroup?._userGroup == "ssc user" && "SEND BACK TO USER"}
                    {userGroup?._userGroup == "ssc manager" &&
                      "SEND BACK TO SSC EXECUTIVE"}
                  </button>

                  {/* <button class="btn btn-outline-danger mr-2"> */}

                  {console.log(`prev state`, location.state)}

                  {location?.state?.subId ? (
                    <Link
                      class="btn btn-outline-danger mr-2"
                      to={`/common-approve/${location?.state?.subId}`}
                      state={{
                        subtypeid: location?.state?.subId,
                        subTypeName: location?.state?.type,
                      }}
                    >
                      <i class="fas fa-arrow-circle-left"></i> Back
                    </Link>
                  ) : (
                    <Link
                      to="/otherexp-check"
                      class="btn btn-outline-danger mr-2"
                    >
                      <i class="fas fa-arrow-circle-left"></i> Back
                    </Link>
                  )}
                  {/* </button> */}

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Query to Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "scroll",
          }}
        >
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row chtgptbox agent">
                {travelchats?.length ? (
                  travelchats.map((cdata, idx) =>
                    // cdata?.created_by_id != userDetails?.id || userGroup?._userGroup == "ssc user" ?
                    // cdata?.created_by_id == userDetails?.id && (userGroup?._userGroup == "ssc user" ||userGroup?._userGroup == "ssc manager") ?
                    cdata?.u_type?.toLowerCase() == "ssc" ? (
                      <div
                        style={{ borderRight: "solid 2px #2e6da4" }}
                        class={"mb-3 col-md-12 text-right"}
                      >
                        <b>
                          SSC (
                          {cdata?.created_by_user_name +
                            " " +
                            cdata?.created_by_user_code}
                          )
                        </b>

                        <p>
                          {cdata?.message}
                          <br />
                          {/* {cdata?.attachment && <Link target="_blank" to={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></Link>} */}
                          {cdata?.attachment_list?.map((attdata, aidx) => (
                            <Link
                              target="_blank"
                              class="ml-2"
                              to={attdata?.attachment_path}
                            >
                              <i class="fas fa-paperclip text-theme"></i>
                            </Link>
                          ))}

                          <small className="ml-2">
                            {moment
                              .parseZone(cdata?.created_at)
                              .format("DD-MM-YYYY hh:mm A")}
                          </small>
                        </p>
                      </div>
                    ) : (
                      <div
                        style={{ borderLeft: "solid 2px #eea236" }}
                        class={"mb-3 col-md-12 text-left "}
                      >
                        <b>
                          User (
                          {cdata?.created_by_user_name +
                            " " +
                            cdata?.created_by_user_code}
                          )
                        </b>

                        <p>
                          {cdata?.message}
                          <br />
                          {/* {cdata?.attachment && <Link target="_blank" to={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></Link>} */}
                          {cdata?.attachment_list?.map((attdata, aidx) => (
                            <Link
                              target="_blank"
                              class="ml-2"
                              to={attdata?.attachment_path}
                            >
                              <i class="fas fa-paperclip text-theme"></i>
                            </Link>
                          ))}

                          <small className="ml-2">
                            {moment
                              .parseZone(cdata?.created_at)
                              .format("DD-MM-YYYY hh:mm A")}
                          </small>
                        </p>
                      </div>
                    )
                  )
                ) : (
                  <div class="col-md-12">
                    <div class="text-center">No queries to show.</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        {userGroup._userGroup != "ssc manager" && (
          <Modal.Footer>
            <div class="col-md-12">
              <div
                style={{ width: "244px" }}
                class="form-group innergroup position-relative modaldefaultclose "
              >
                <label>Upload files</label>
                <input
                  type="text"
                  class="form-control bg-white"
                  disabled
                  placeholder="(JPG, PNG Format only)"
                />
                <div class="upload-btn-wrapper up-loposition">
                  <button class="uploadBtn">Browse</button>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => onProfileChange2(e, "import_user")}
                  />
                </div>
                {selectedFiles.map((dfile) => (
                  <small class="mr-2">{dfile.name}</small>
                ))}
              </div>

              {imageLocalPreviewList.length ? (
                <div className="row">
                  {imageLocalPreviewList.map((idata, idx) => (
                    <>
                      <div class="col-md-4 text-center">
                        <img src={idata} class="h-100" />
                        <i
                          role="button"
                          onClick={() => removeImageByIdx(idx)}
                          class=" fas fa-trash-alt text-danger"
                        />
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>

            <div class="col-md-12">
              <div class="form-group innergroup">
                <label>
                  Comment <span class="text-danger">*</span>
                </label>
                <textarea
                  id="querytext"
                  class="form-control"
                  placeholder="Enter Comment"
                  onChange={(e) =>
                    set_travelPostData({
                      ...travelPostData,
                      message: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
            <button class="btn btn-outline-danger" onClick={addnewclose}>
              Cancel
            </button>
            <button
              class="btn btn-primary-inner bpi-main"
              onClick={postTravelChat}
            >
              Submit
            </button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal
        show={docsModel.is_visible}
        onHide={() =>
          set_docsModel({
            ...docsModel,
            is_visible: false,
          })
        }
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Attachments</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          <div class="table-responsive mb-3">
            {traveluserDetails?.attachment?.length ? (
              <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                <thead>
                  <tr>
                    <th>FILE NAME </th>
                    <th>VIEW</th>
                  </tr>
                </thead>
                <tbody>
                  {traveluserDetails?.attachment?.map((adata, idx) => (
                    <tr>
                      <td>
                        {adata?.attachment_path?.substring(
                          adata?.attachment_path?.lastIndexOf("/") + 1
                        )}
                      </td>
                      <td>
                        <Link target="_Blank" to={adata?.attachment_path}>
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              "No attachment yet."
            )}
          </div>

          {/* <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row chtgptbox agent">
                
                
              {traveluserDetails?.attachment?.length?
                  traveluserDetails?.attachment?.map((adata,idx)=>(
                                      
                    <div className="col-md-12">
                        {adata.attachment_path}
                    </div>
                    
                  ))
                 :"No attachment yet. "}
                
              </div>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>

      <Modal show={reasonshows} onHide={handleClose}>
        <Modal.Header
          style={{ "background-color": "#0195d4" }}
          className="text-white"
          closeButton
        >
          <Modal.Title>Prompt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-sm-12">
              <p> Enter reason to send back</p>
              <input
                onChange={(e) => set_inputReason(e.target.value)}
                class="form-control"
                type="text"
                value={inputReason}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ "background-color": "#0195d4" }}
            class="btn "
            onClick={sendBackToUser2}
          >
            Ok
          </Button>
          <Button
            style={{ "background-color": "#0195d4" }}
            class="btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OtherExpCheckExpens;
