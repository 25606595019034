import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import Select from "react-select";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import { getExportValueRecovery, getReimbRecovery } from "./claimReimbServices";
import { setDisplayLoader } from "../redux/piDataStore";
import { getAllUserDataNew } from "../../service/AttendanceService";
import { get_sap_schedule } from "../../service/searchpo";

function ReimburseRecoveryReport(props) {
  const dispatch = useDispatch();
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [recoveryFilter, setRecoveryFilter] = useState({
    username: "",
    dor_from: "",
    dor_to: "",
    sap_status: ""
  });
  const [filterUser, setFilterUser] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [recoveryData, setRecoveryData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const SAPSTATUS = [
    {
      label: "Posted",
      value: "Yes"
    },
    {
      label: "Not Posted",
      value: "No"
    }
  ]

  const handleKey = (
    search,
    username,
    dor_from,
    dor_to,
    sap_status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "search=" +
      search +
      "&page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (username !== "" && username !== undefined && username !== null) {
      queryParm = queryParm + "&username=" + username;
    }
    if (dor_to !== "" && dor_to !== undefined && dor_to !== null) {
      queryParm = queryParm + "&dor_to=" + dor_to;
    }
    if (dor_from !== "" && dor_from !== undefined && dor_from !== null) {
      queryParm = queryParm + "&dor_from=" + dor_from;
    }
    if (sap_status !== "" && sap_status !== undefined && sap_status !== null) {
      queryParm = queryParm + "&sap_status=" + sap_status?.value;
    }
    return queryParm;
  };

  useEffect(() => {
    getUserList();
    getSapSchedule();
  }, []);

  const getSapSchedule = () => {
    get_sap_schedule('reimbursement_recovery_cron').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }

  const getUserList = () => {
    getAllUserDataNew(0, false).then((response) => {
      if (response?.status === 200) {
        let res = response?.data?.dataList?.result
          .filter((obj) => {
            if (obj?.username !== "admin" && obj?.username !== "super_pi") {
              return true;
            }
          })
          .map((obj) => {
            return {
              label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
              value: obj?.id,
            };
          });
        setUserList(res);
      }
    });
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getRecoveryReportData(
      searchStr,
      recoveryFilter?.username,
      recoveryFilter?.dor_from,
      recoveryFilter?.dor_to,
      recoveryFilter?.sap_status,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  }, [pageSizeNo]);

  const getRecoveryReportData = (
    search,
    username,
    dor_from,
    dor_to,
    sap_status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    getReimbRecovery(
      handleKey(
        search,
        username,
        dor_from,
        dor_to,
        sap_status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      if (response?.status === 200) {
        setRecoveryData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      }
    });
  };

  const exportData = (
    search,
    username,
    dor_from,
    dor_to,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    getExportValueRecovery(
      handleKey(
        search,
        username,
        dor_from,
        dor_to,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Reimbursement Recovery.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const filterRecoveryData = () => {
    getRecoveryReportData(
      searchStr,
      recoveryFilter?.username,
      recoveryFilter?.dor_from,
      recoveryFilter?.dor_to,
      recoveryFilter?.sap_status,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
    filterclose();
  };

  const handleSearch = (e) => {
    getRecoveryReportData(
      e.target.value.toLowerCase(),
      recoveryFilter?.username,
      recoveryFilter?.dor_from,
      recoveryFilter?.dor_to,
      recoveryFilter?.sap_status,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
    setSearchStr(e.target.value);
  };

  const resetFilter = () => {
    setRecoveryFilter({
      username: "",
      dor_from: "",
      dor_to: "",
    });
    setFilterUser("");
    getRecoveryReportData(
      searchStr,
      "",
      "",
      "",
      "",
      1,
      10,
      sortData?.sort_by,
      true
    );
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getRecoveryReportData(
      searchStr,
      recoveryFilter?.username,
      recoveryFilter?.dor_from,
      recoveryFilter?.dor_to,
      recoveryFilter?.sap_status,
      activePage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getRecoveryReportData(
      searchStr,
      recoveryFilter?.username,
      recoveryFilter?.dor_from,
      recoveryFilter?.dor_to,
      recoveryFilter?.sap_status,
      currentPage,
      pageSizeNo,
      sdata?.sort_by,
      true
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="content-wrapper-inner content-wrapper-inner2">
            <div className="innerheadsec">
              <div className="row">
                <div className="col-md-9">
                  <h4 className="inner-page-title">
                    Reimbursement Recovery Report
                  </h4>
                </div>
                <div className="col-md-12">
                  <div className="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="input-group table-search">
                    <span className="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" className="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            style={{ border: 0, background: "none" }}
                            onClick={() => {
                              exportData(
                                searchStr,
                                recoveryFilter?.username,
                                recoveryFilter?.dor_from,
                                recoveryFilter?.dor_to,
                                currentPage,
                                pageSizeNo,
                                "-id",
                                true
                              );
                            }}
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      className="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" className="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>
            <div className="purchaseinnertabs">
              <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th
                        onClick={(e) => {
                          handlerData("user__username");
                        }}
                        className={
                          sortData["user__username"] === undefined
                            ? "unsortcls"
                            : sortData["user__username"]
                        }
                      >
                        EMP CODE
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("user__first_name");
                        }}
                        className={
                          sortData["user__first_name"] === undefined
                            ? "unsortcls"
                            : sortData["user__first_name"]
                        }
                      >
                        Name of Employee
                      </th>
                      <th>Reimbursed Amount</th>
                      <th>Requested On</th>
                      <th>DOR</th>
                      <th
                        onClick={(e) => {
                          handlerData("recovery_days");
                        }}
                        className={
                          sortData["recovery_days"] === undefined
                            ? "unsortcls"
                            : sortData["recovery_days"]
                        }
                      >
                        Recovery Days
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("recovery_amount");
                        }}
                        className={
                          sortData["recovery_amount"] === undefined
                            ? "unsortcls"
                            : sortData["recovery_amount"]
                        }
                      >
                        Recovery Amount
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("sap_status");
                        }}
                        className={
                          sortData["sap_status"] === undefined
                            ? "unsortcls"
                            : sortData["sap_status"]
                        }
                      >
                        SAP Status
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("sap_error");
                        }}
                        className={
                          sortData["sap_error"] === undefined
                            ? "unsortcls"
                            : sortData["sap_error"]
                        }
                      >
                        SAP Error
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("sap_posted_date");
                        }}
                        className={
                          sortData["sap_posted_date"] === undefined
                            ? "unsortcls"
                            : sortData["sap_posted_date"]
                        }
                      >
                        Sap Posted Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {recoveryData?.length > 0 &&
                      recoveryData?.map((obj, i) => (
                        <tr key={i + 1}>
                          <td>{obj?.username}</td>
                          <td>{obj?.emp_name}</td>
                          <td>{obj?.reimburse_amount}</td>
                          <td>
                            {moment(obj?.requested_at).format("DD-MM-YYYY")}
                          </td>
                          <td>{moment(obj?.dor).format("DD-MM-YYYY")}</td>
                          <td>{obj?.recovery_days}</td>
                          <td>{obj?.recovery_amount.toFixed(2)}</td>
                          <td>{obj?.sap_status}</td>
                          <td>
                            <Tooltip title={obj?.sap_error} position="bottom">
                              {obj?.sap_error?.substr(0, 12)}...
                            </Tooltip>
                          </td>
                          <td>
                            {obj?.sap_status !== "No"
                              ? moment(obj?.sap_posted_date).format(
                                "DD-MM-YYYY"
                              )
                              : ""}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {recoveryData?.length == 0 ? (
        ""
      ) : (
        <div className="row">
          <div className="col-sm-5">
            <div className="sortinglist">
              Show
              <select
                className="form-control"
                onChange={(e) => {
                  setPageSizeNo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value={10} label={10}>
                  10
                </option>
                <option value={20} label={20}>
                  20
                </option>
                <option value={30} label={30}>
                  30
                </option>
                <option value={100} label={100}>
                  100
                </option>
              </select>
            </div>
          </div>
          <div className="col-sm-7">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-left"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      )}
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>User</label>
            <Select
              options={userList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setRecoveryFilter((prev) => ({
                  ...prev,
                  username: e?.value,
                }));
                setFilterUser(e);
              }}
              value={filterUser}
            />
          </div>
          <div className="form-group innergroup">
            <label>
              DOR From<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => {
                setRecoveryFilter((prev) => ({
                  ...prev,
                  dor_from: event.target.value,
                }));
              }}
              value={recoveryFilter.dor_from}
            />
          </div>
          <div className="form-group innergroup">
            <label>
              DOR To<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => {
                setRecoveryFilter((prev) => ({
                  ...prev,
                  dor_to: event.target.value,
                }));
              }}
              value={recoveryFilter.dor_to}
            />
          </div>
          <div className="form-group innergroup">
            <label>
              SAP Status<span className="text-danger">*</span>
            </label>
            <Select
              options={SAPSTATUS}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setRecoveryFilter((prev) => ({
                  ...prev,
                  sap_status: e,
                }));
              }}
              value={recoveryFilter?.sap_status}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            onClick={() => {
              filterclose();
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-inner bpi-main"
            onClick={filterRecoveryData}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReimburseRecoveryReport;
