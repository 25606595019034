import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import avatarImg from "../../../assets/images/avatarImg.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import * as UserService from "../../../service/userService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import ViewUser from "./ViewUser";
import AddUser from "./AddUser";
import { Formik } from "formik";
import ReactPaginate from "react-paginate";
import * as AttendanceService from "../../../service/AttendanceService";
import { getAllBranch } from "../../../service/employee";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader,
} from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function User() {
  const dispatch = useDispatch();
  let location = useLocation();
  const userData = useSelector(selectUserData);
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [pageCount, setpageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [viewForm, setViewForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [userList, setUserList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [userCount, setUserCount] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [viewFormData, setViewFormData] = useState(null);
  const [roleDrop, setRoleDrop] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [rolesDataSelected, setRolesDataSelected] = useState([]);
  const [rmDataSelected, setRmDataSelected] = useState([]);
  const [hrDataSelected, setHrDataSelected] = useState([]);
  const [headDataSelected, setHeadDataSelected] = useState([]);
  const [userDrop, setUserDrop] = useState([]);
  const [user, setUser] = useState(null);
  const [branchData, setBranchData] = useState([]);
  const [personalAreaData, setPersonalAreaData] = useState([]);
  const [subPersonalAreaData, setSubPersonalAreaData] = useState([]);
  const [allDesignation, setAllDesignation] = useState([]);
  const [departmentDataFilter, setDepartmentDataFilter] = useState({
    is_sf: "",
    business_units_id: "",
    personal_area_id: "",
    sub_personal_area_id: "",
    designation_id: "",
    status: true,
    role: [],
    rm_id: [],
    hr_id: [],
    head_id: [],
    joining_date_from: "",
    joining_date_to: "",
    leaving_date_from: "",
    leaving_date_to: ""
  });

  const [valueBusiness, setValueBusiness] = useState("");
  const [valuePersonalArea, setValuePersonalArea] = useState("");
  const [valueSubPersonalArea, setValueSubPersonalArea] = useState("");
  const [valueDesignation, setValueDesignation] = useState("");

  const [departmentDataForExport, setDepartmentDataForExport] = useState({
    business_units_id: "",
    personal_area_id: "",
    sub_personal_area_id: "",
    designation_id: "",
    status: "",
  });

  const handleKey = (
    search,
    is_sf,
    business_units_id,
    personal_area_id,
    sub_personal_area_id,
    designation_id,
    status,
    role,
    pageNo,
    page_size,
    sort_by,
    paginate,
    rm,
    hr,
    head,
    joining_date_from,
    joining_date_to,
    leaving_date_from,
    leaving_date_to
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      personal_area_id !== "" &&
      personal_area_id !== undefined &&
      personal_area_id !== null
    ) {
      queryParm = queryParm + "&personal_area_id=" + personal_area_id;
    }
    if (
      sub_personal_area_id !== "" &&
      sub_personal_area_id !== undefined &&
      sub_personal_area_id !== null
    ) {
      queryParm = queryParm + "&sub_personal_area_id=" + sub_personal_area_id;
    }
    if (
      designation_id !== "" &&
      designation_id !== undefined &&
      designation_id !== null
    ) {
      queryParm = queryParm + "&designation_id=" + designation_id;
    }
    if (
      business_units_id !== "" &&
      business_units_id !== undefined &&
      business_units_id !== null
    ) {
      queryParm = queryParm + "&business_units_id=" + business_units_id;
    }
    if (
      role !== "" &&
      role !== undefined &&
      role !== null &&
      role.length !== 0
    ) {
      console.log(role, "rolessss");
      queryParm = queryParm + "&role=" + role;
    }
    if (
      is_sf !== "" &&
      is_sf !== undefined &&
      is_sf !== null &&
      is_sf.length !== 0
    ) {
      queryParm = queryParm + "&is_sf=" + is_sf;
    }
    if (rm !== "" && rm !== undefined && rm !== null && rm.length !== 0) {
      queryParm = queryParm + "&rm=" + rm;
    }
    if (hr !== "" && hr !== undefined && hr !== null && hr.length !== 0) {
      queryParm = queryParm + "&hr=" + hr;
    }
    if (head !== "" && head !== undefined && head !== null && head.length !== 0) {
      queryParm = queryParm + "&head=" + head;
    }
    if (joining_date_from !== "" && joining_date_from !== undefined && joining_date_from !== null) {
      queryParm = queryParm + "&joining_date_from=" + joining_date_from;
    }
    if (joining_date_to !== "" && joining_date_to !== undefined && joining_date_to !== null) {
      queryParm = queryParm + "&joining_date_to=" + joining_date_to;
    }
    if (leaving_date_from !== "" && leaving_date_from !== undefined && leaving_date_from !== null) {
      queryParm = queryParm + "&leaving_date_from=" + leaving_date_from;
    }
    if (leaving_date_to !== "" && leaving_date_to !== undefined && leaving_date_to !== null) {
      queryParm = queryParm + "&leaving_date_to=" + leaving_date_to;
    }
    return queryParm;
  };

  const pushDept = (e) => {
    let collectData = e?.map((value) => {
      return value.value;
    });
    console.log(collectData);
    // setDepartmentDataFilter(collectData);

    setDepartmentDataFilter({
      ...departmentDataFilter,
      role: collectData,
    });
    setRolesDataSelected(e);
  };

  const loadUserMasterList = (
    search,
    is_sf,
    business_units_id,
    personal_area_id,
    sub_personal_area_id,
    designation_id,
    status,
    role,
    pageNo,
    page_size,
    sort_by,
    paginate,
    rm,
    hr,
    head,
    joining_date_from,
    joining_date_to,
    leaving_date_from,
    leaving_date_to
  ) => {
    console.log(role, "role");
    // dispatch(setDisplayLoader("Display"));
    UserService.getUserList(
      handleKey(
        search,
        is_sf,
        business_units_id,
        personal_area_id,
        sub_personal_area_id,
        designation_id,
        status,
        role,
        pageNo,
        page_size,
        sort_by,
        paginate,
        rm,
        hr,
        head,
        joining_date_from,
        joining_date_to,
        leaving_date_from,
        leaving_date_to
      )
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        setUserList(response.data.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setShowForm(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      loadUserMasterList(
        e.target.value.toLowerCase(),
        departmentDataFilter.is_sf,
        departmentDataFilter.business_units_id,
        departmentDataFilter.personal_area_id,
        departmentDataFilter.sub_personal_area_id,
        departmentDataFilter.designation_id,
        departmentDataFilter.status,
        departmentDataFilter?.role,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };
  console.log("searchstr", searchStr);

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      loadUserMasterList(
        data,
        departmentDataFilter.is_sf,
        departmentDataFilter.business_units_id,
        departmentDataFilter.personal_area_id,
        departmentDataFilter.sub_personal_area_id,
        departmentDataFilter.designation_id,
        departmentDataFilter.status,
        departmentDataFilter?.role,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const deleteUser = (idx) => {
    let dataId = { id: idx };
    UserService.deleteUser(dataId)
      .then((res) => {
        if (res.data.status === true) {
          toast.success(res.data.message);
          loadUserMasterList(
            "",
            departmentDataFilter.is_sf,
            departmentDataFilter.business_units_id,
            departmentDataFilter.personal_area_id,
            departmentDataFilter.sub_personal_area_id,
            departmentDataFilter.designation_id,
            departmentDataFilter.status,
            departmentDataFilter?.role,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error?.response?.data?.detail) ||
          "failed"
        );
      });
  };
  const submit = (id, userName) => {
    confirmAlert({
      title: "Delete User",
      message: `Are you sure to delete ${userName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUser(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addUser = () => {
    setShowForm(true);
    setEditMode(false);
    setUser(null);
  };

  const editUser = (eData) => {
    setEditMode(true);
    setShowForm(true);
    setUser(eData);
  };

  const onClose = () => {
    setShowForm(false);
    setSearchStr("");
    loadUserMasterList(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    window.scrollTo(0, 0);
  };
  const onView = (list) => {
    setViewForm(true);
    setViewFormData(list);
  };
  const onViewClose = () => {
    setViewForm(false);
    setSearchStr("");
    loadUserMasterList(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    getUsersCount(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    loadUserMasterList(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const filterShow = () => {
    filteropen();
    getBranchFilter();
    getPersonalArea("");
    getSubPersonalArea("", "");
    getDesignationFilter("", "", "");
    getRolesList("", "", "", "");
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    loadUserMasterList(
      searchStr,
      departmentDataFilter.is_sf,
      departmentDataFilter.business_units_id,
      departmentDataFilter.personal_area_id,
      departmentDataFilter.sub_personal_area_id,
      departmentDataFilter.designation_id,
      departmentDataFilter.status,
      departmentDataFilter?.role,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  const getBranchFilter = () => {
    UserService.getBranchFilter()
      .then((response) => {
        setBranchData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const renderBranchOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name} (${item?.code})`}
      />
    ));
  };
  const getPersonalArea = (business_unit_id) => {
    UserService.getPersonalFilter(business_unit_id)
      .then((response) => {
        setPersonalAreaData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const renderPersonalAreaOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name} (${item?.description})`}
      />
    ));
  };
  const getSubPersonalArea = (business_unit_id, personal_area_id) => {
    UserService.getSubPersonalFilter(business_unit_id, personal_area_id)
      .then((response) => {
        setSubPersonalAreaData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const renderSubPersonalAreaOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        label={`${item?.name} (${item?.description})`}
        value={`${item?.id}`}
      />
    ));
  };

  const getDesignationFilter = (
    bussiness_unit_id,
    personal_area_id,
    sub_personal_area_id
  ) => {
    UserService.getDesignationFilter(
      bussiness_unit_id,
      personal_area_id,
      sub_personal_area_id
    )
      .then((response) => {
        setAllDesignation(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const renderDesigOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        label={`${item?.name} (${item?.code})`}
        value={`${item?.id}`}
      />
    ));
  };

  const getRolesList = (
    business_unit_id,
    personal_area_id,
    sub_personal_area_id,
    designation_id
  ) => {
    UserService.getRolesFilter(
      business_unit_id,
      personal_area_id,
      sub_personal_area_id,
      designation_id
    )
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result?.map((x) => {
          let data2 = { value: x.id, label: x.name };
          data.push(data2);
        });
        setRoleDrop(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const mainDataFilter = (values) => {
    setFilterApplied(true);
    const sf = values.is_sf;
    const buId = values.business_units_id;
    const paId = values.personal_area_id;
    const subId = values.sub_personal_area_id;
    const desigId = values.designation_id;
    const status = values.status;
    const role = departmentDataFilter?.role;
    const rm_id = departmentDataFilter?.rm_id;
    const hr_id = departmentDataFilter?.hr_id;
    const head_id = departmentDataFilter?.head_id;
    const joining_date_from = departmentDataFilter?.joining_date_from
    const joining_date_to = departmentDataFilter?.joining_date_to
    const leaving_date_from = departmentDataFilter?.leaving_date_from
    const leaving_date_to = departmentDataFilter?.leaving_date_to
    loadUserMasterList(
      searchStr,
      sf,
      buId,
      paId,
      subId,
      desigId,
      status,
      role,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      rm_id,
      hr_id,
      head_id,
      joining_date_from,
      joining_date_to,
      leaving_date_from,
      leaving_date_to
    );
    getUsersCount(
      searchStr,
      sf,
      buId,
      paId,
      subId,
      desigId,
      status,
      role,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      rm_id,
      hr_id,
      head_id,
      joining_date_from,
      joining_date_to,
      leaving_date_from,
      leaving_date_to
    )
    filterclose();
  };

  const mainGridDataFilter = (flag) => {
    const sf = departmentDataFilter.is_sf;
    const buId = departmentDataFilter.business_units_id;
    const paId = departmentDataFilter.personal_area_id;
    const subId = departmentDataFilter.sub_personal_area_id;
    const desigId = departmentDataFilter.designation_id;
    const status = flag;
    const role = departmentDataFilter?.role;
    loadUserMasterList(
      searchStr,
      sf,
      buId,
      paId,
      subId,
      desigId,
      status,
      role,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterclose();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(departmentDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDepartmentDataFilter(ClearData);
    setRolesData([]);
    setRolesDataSelected([]);
    setRmDataSelected([]);
    setHrDataSelected([]);
    setHeadDataSelected([]);
    filterclose();
    loadUserMasterList(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    getUsersCount(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    setDepartmentDataFilter({
      ...departmentDataFilter,
      is_sf: "",
      business_units_id: "",
      personal_area_id: "",
      sub_personal_area_id: "",
      designation_id: "",
      status: "",
      role: [],
      rm_id: [],
      hr_id: [],
      head_id: [],
      joining_date_from: "",
      joining_date_to: "",
      leaving_date_from: "",
      leaving_date_to: ""
    });
  };

  const getExportDetails = (
    search,
    is_sf,
    business_units_id,
    personal_area_id,
    sub_personal_area_id,
    designation_id,
    status,
    role,
    pageNo,
    page_size,
    sort_by,
    paginate,
    rm,
    hr,
    head,
    joining_date_from,
    joining_date_to,
    leaving_date_from,
    leaving_date_to
  ) => {
    dispatch(setDisplayLoader("Display"));
    UserService.getExportValue(
      handleKey(
        search,
        is_sf,
        business_units_id,
        personal_area_id,
        sub_personal_area_id,
        designation_id,
        status,
        role,
        pageNo,
        page_size,
        sort_by,
        paginate,
        rm,
        hr,
        head,
        joining_date_from,
        joining_date_to,
        leaving_date_from,
        leaving_date_to
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "users.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getUsersCount = (
    search,
    is_sf, 
    business_units_id, 
    personal_area_id, 
    sub_personal_area_id, 
    designation_id, 
    status, 
    role, 
    pageNo, 
    page_size, 
    sort_by, 
    paginate, 
    rm, 
    hr, 
    head, 
    joining_date_from,
    joining_date_to,
    leaving_date_from,
    leaving_date_to
    ) => {
    UserService.getUsersCount(
      handleKey(
        search,
        is_sf,
        business_units_id,
        personal_area_id,
        sub_personal_area_id,
        designation_id,
        status,
        role,
        pageNo,
        page_size,
        sort_by,
        paginate,
        rm,
        hr,
        head,
        joining_date_from,
        joining_date_to,
        leaving_date_from,
        leaving_date_to
      )
    )
      .then((response) => {
        setUserCount(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    // console.log(action);
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    loadUserMasterList(
      searchStr,
      departmentDataFilter.is_sf,
      departmentDataFilter.business_units_id,
      departmentDataFilter.personal_area_id,
      departmentDataFilter.sub_personal_area_id,
      departmentDataFilter.designation_id,
      departmentDataFilter.status,
      departmentDataFilter?.role,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  useEffect(() => {
    loadUserMasterList(
      searchStr,
      departmentDataFilter.is_sf,
      departmentDataFilter.business_units_id,
      departmentDataFilter.personal_area_id,
      departmentDataFilter.sub_personal_area_id,
      departmentDataFilter.designation_id,
      departmentDataFilter.status,
      departmentDataFilter?.role,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [pageSizeNo]);

  useEffect(() => {
    getUsersCount(
      searchStr,
      departmentDataFilter.is_sf,
      departmentDataFilter.business_units_id,
      departmentDataFilter.personal_area_id,
      departmentDataFilter.sub_personal_area_id,
      departmentDataFilter.designation_id,
      departmentDataFilter.status,
      departmentDataFilter?.role,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    // getRolesList(false);
    getUserData()
  }, []);

  const getUserData = () => {
    AttendanceService.getUserDataNew(0)
      .then((response) => {
        let obj = response?.data?.dataList?.result
          ?.map((res) => {
            return {
              value: res?.id,
              label: res?.first_name + ' ' + res?.last_name + "(" + res?.username + ")"
            };
          });
        setUserDrop(obj);
        // setModuleList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      {showForm ? (
        <>
          <AddUser
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editUser={user}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : viewForm ? (
        <>
          <ViewUser
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        <>
          <div className="content-wrapper-inner">
            <ToastContainer autoClose={1000} />
            <div className="innerheadsec">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="inner-page-title">User</h4>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 col-6" style={{ cursor: "pointer" }} onClick={() => { mainGridDataFilter('') }}>
                      <div className="card userboxes">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">
                            <h5>{userCount?.total_users}</h5>
                            <p>Total Users </p>
                          </div>
                          <div className="d-none d-sm-block">
                            <div className="user-avtar">
                              <i className="far fa-user"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6" style={{ cursor: "pointer" }} onClick={() => { mainGridDataFilter(true) }}>
                      <div className="card userboxes avtarsuccess">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">
                            <h5>{userCount?.active_users}</h5>
                            <p>Active Users</p>
                          </div>
                          <div className="d-none d-sm-block">
                            <div className="user-avtar">
                              <i className="fas fa-user-check"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6" style={{ cursor: "pointer" }} onClick={() => { mainGridDataFilter(false) }}>
                      <div className="card userboxes avtardanger">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">
                            <h5>{userCount?.inactive_users}</h5>
                            <p>InActive Users</p>
                          </div>
                          <div className="d-none d-sm-block">
                            <div className="user-avtar">
                              <i className="fas fa-user-minus"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card userboxes avtarwarning">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">
                            <h5>{userCount?.blocked_users}</h5>
                            <p>Blocked Users</p>
                          </div>
                          <div className="d-none d-sm-block">
                            <div className="user-avtar">
                              <i className="fas fa-user-lock"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Employee Code,
                            <br />
                            User
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Employee Code, User..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="col-lg-8">
                  <div className="inline-spacing btn-betweenspaing">
                    {finalUrlValue?.D ? (
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img
                            src="images/export.png"
                            alt=""
                            className="mr-2"
                          />{" "}
                          Export
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={userList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,
                                  departmentDataFilter.is_sf,
                                  departmentDataFilter.business_units_id,
                                  departmentDataFilter.personal_area_id,
                                  departmentDataFilter.sub_personal_area_id,
                                  departmentDataFilter.designation_id,
                                  departmentDataFilter.status,
                                  departmentDataFilter?.role,
                                  1,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true,
                                  departmentDataFilter.rm_id,
                                  departmentDataFilter.hr_id,
                                  departmentDataFilter.head_id,
                                  departmentDataFilter?.joining_date_from,
                                  departmentDataFilter?.joining_date_to,
                                  departmentDataFilter?.leaving_date_from,
                                  departmentDataFilter?.leaving_date_to
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                    <button
                      onClick={() => filterShow()}
                      className="btn btn-secondary-inner"
                      type="button"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" className="mr-3" />
                      Filter
                    </button>
                    {finalUrlValue?.A ? (
                      <button
                        type="button"
                        className="btn btn-primary-inner"
                        onClick={() => {
                          addUser();
                        }}
                      >
                        <img src="images/upload.png" alt="" className="mr-3" />
                        Add New
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-bordered tablecured sort-overlap">
                <thead>
                  <tr>
                    <th>Employee Code</th>
                    <th
                      width="250"
                      onClick={(e) => {
                        handlerData("first_name");
                      }}
                      className={
                        sortData["first_name"] === undefined
                          ? "unsortcls"
                          : sortData["first_name"]
                      }
                    >
                      Employee Name
                    </th>
                    {finalUrlValue?.V ||
                      finalUrlValue?.E ||
                      finalUrlValue?.R ? (
                      <th>Actions</th>
                    ) : (
                      ""
                    )}
                    <th>Role</th>
                    <th
                      onClick={(e) => {
                        handlerData("is_active");
                      }}
                      className={
                        sortData["is_active"] === undefined
                          ? "unsortcls"
                          : sortData["is_active"]
                      }
                    >
                      Status
                    </th>
                    <th>Last Login at</th>
                    <th>Business Unit</th>
                    <th>User Type</th>
                    <th>Personal Sub Area</th>
                    <th
                      onClick={(e) => {
                        handlerData("updated_by");
                      }}
                      className={
                        sortData["updated_by"] === undefined
                          ? "unsortcls"
                          : sortData["updated_by"]
                      }
                    >
                      Last Updated by
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("updated_at");
                      }}
                      className={
                        sortData["updated_at"] === undefined
                          ? "unsortcls"
                          : sortData["updated_at"]
                      }
                    >
                      Last Updated on
                    </th>                  
                  </tr>
                </thead>
                <tbody>
                  {userList?.length ? (
                    userList?.map((list, index) => (
                      <>
                        <tr key={`ll${index}`}>
                          <td>{list?.username}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="team-profile">
                                  {list?.image === "" ? (
                                    <img src={avatarImg} alt="" />
                                  ) : (
                                    <img src={`data:image/gif;base64,${list?.image}`} alt="" />
                                  )}
                                </div>
                              </div>
                              <div className="teamtxt">
                                {list?.first_name}&nbsp;{list?.last_name}
                              </div>
                            </div>
                          </td>
                          {finalUrlValue?.V ||
                            finalUrlValue?.E ||
                            finalUrlValue?.R ? (
                            <td>
                              {finalUrlValue?.V ? (
                                <button
                                  type="button"
                                  className="btn btn-blanktd text-primary"
                                  onClick={() => {
                                    onView(list);
                                  }}
                                >
                                  <img src="images/eye.png" alt="" />
                                </button>
                              ) : (
                                ""
                              )}
                              {finalUrlValue?.E ? (
                                <button
                                  className="btn btn-blanktd text-primary "
                                  onClick={() => {
                                    editUser(list);
                                  }}
                                >
                                  <i className="far fa-edit"></i>
                                </button>
                              ) : (
                                ""
                              )}

                              {finalUrlValue?.R &&
                                list?.is_sf_user === false ? (
                                <button
                                  className="btn btn-blanktd text-danger "
                                  onClick={() => {
                                    submit(list?.id, list?.username);
                                  }}
                                >
                                  <i className="far fa-trash-alt text-danger"></i>
                                </button>
                              ) : (
                                ""
                              )}
                            </td>
                          ) : (
                            ""
                          )}
                                                    <td>
                            {list?.groups?.length !== 0 ? (
                              list?.groups.map((x) => (
                                <Badge bg="secondary">{x.name}</Badge>
                              ))
                            ) : (
                              <p>N/A</p>
                            )}
                          </td>
                          <td>
                            {list?.is_active ? (
                              <span className="badge bad-status badge-success">
                                Active
                              </span>
                            ) : (
                              <span className="badge bad-status badge-danger">
                                InActive
                              </span>
                            )}
                          </td>
                          <td>
                            {moment
                              .utc(list?.last_login_at)
                              .format("DD-MM-YYYY,HH:mm")}
                          </td>
                          <td>{list?.business_unit_name}</td>
                          <td>
                            {list?.is_sf_user === true
                              ? "SF User"
                              : "Non SF User"}
                          </td>
                          <td>{list?.sub_personal_area_desc}</td>
                          <td>{list?.updated_by}</td>
                          <td>
                            {moment
                              .utc(list?.updated_at)
                              .format("DD-MM-YYYY,HH:mm")}
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {userList?.length == 0 ? (
              ""
            ) : (
              <div className="row">
                <div className="col-sm-5">
                  <div className="sortinglist">
                    Show
                    <select
                      className="form-control"
                      style={{ textAlign: "center" }}
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <Formik
        initialValues={departmentDataFilter}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({ values, handleChange, handleSubmit, resetForm }) => (
          <Modal
            show={show}
            onHide={filterclose}
            backdrop="static"
            keyboard={false}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group innergroup">
                  <label>Business Unit</label>
                  <select
                    className="form-control newbgselect"
                    name="business_units_id"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        business_units_id: e.target.value,
                      });
                      setValueBusiness(e.target.value);
                      getPersonalArea(e.target.value);
                      getSubPersonalArea(e.target.value, "");
                      getDesignationFilter(e.target.value, "", "");
                      getRolesList(e.target.value, "", "", "");
                    }}
                    style={{ cursor: "pointer" }}
                    value={departmentDataFilter?.business_units_id}
                  >
                    <option value="" label="Select Business Unit" disabled />
                    {renderBranchOptions(branchData)}
                  </select>
                </div>
                <div className="form-group innergroup">
                  <label>Personal Area</label>
                  <select
                    className="form-control newbgselect"
                    style={{ cursor: "pointer" }}
                    value={departmentDataFilter?.personal_area_id}
                    name="personal_area_id"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        personal_area_id: e.target.value,
                      });
                      setValuePersonalArea(e.target.value);
                      getSubPersonalArea(valueBusiness, e.target.value);
                      getDesignationFilter(valueBusiness, e.target.value, "");
                      getRolesList(valueBusiness, e.target.value, "", "");
                    }}
                  >
                    <option value="" label="Select Personal Area" disabled />
                    {renderPersonalAreaOptions(personalAreaData)}
                  </select>
                </div>
                <div className="form-group innergroup">
                  <label>Sub-Personal Area</label>
                  <select
                    className="form-control newbgselect"
                    style={{ cursor: "pointer" }}
                    value={departmentDataFilter?.sub_personal_area_id}
                    name="sub_personal_area_id"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        sub_personal_area_id: e.target.value,
                      });
                      setValueSubPersonalArea(e.target.value);
                      getDesignationFilter(
                        valueBusiness,
                        valuePersonalArea,
                        e.target.value
                      );
                      getRolesList(
                        valueBusiness,
                        valuePersonalArea,
                        e.target.value,
                        ""
                      );
                    }}
                  >
                    <option
                      value=""
                      label="Select Sub-Personal Area"
                      disabled
                    />
                    {renderSubPersonalAreaOptions(subPersonalAreaData)}
                  </select>
                </div>
                <div className="form-group innergroup">
                  <label>Designation</label>
                  <select
                    className="form-control newbgselect"
                    style={{ cursor: "pointer" }}
                    value={departmentDataFilter?.designation_id}
                    name="designation_id"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        designation_id: e.target.value,
                      });
                      setValueDesignation(e.target.value);
                      getRolesList(
                        valueBusiness,
                        valuePersonalArea,
                        valueSubPersonalArea,
                        e.target.value
                      );
                    }}
                  >
                    <option value="" label="Select Designation" disabled />

                    {renderDesigOptions(allDesignation)}
                  </select>
                </div>
                <div className="form-group innergroup">
                  <label>Roles</label>
                  <Select
                    name="role"
                    id="role-user"
                    isMulti
                    options={roleDrop}
                    placeholder={
                      <div
                        className="select-placeholder-text"
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        Select
                      </div>
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    style={{ cursor: "pointer" }}
                    onChange={(e) => {
                      pushDept(e);
                    }}
                    value={rolesDataSelected}
                  />
                </div>

                <div className="form-group innergroup">
                  <label>Select Status</label>
                  <select
                    className="form-control newbgselect"
                    style={{ cursor: "pointer" }}
                    value={departmentDataFilter?.status}
                    name="status"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        status: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value={""}>All Users</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </div>
                <div className="form-group innergroup">
                  <label>User Type</label>
                  <select
                    className="form-control newbgselect"
                    style={{ cursor: "pointer" }}
                    value={departmentDataFilter?.is_sf}
                    name="is_sf"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        is_sf: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value={true}>SF User</option>
                    <option value={false}>Non-SF User</option>
                  </select>
                </div>
                <div className="form-group innergroup">
                  <label>Select Reporting Manager</label>
                  <Select
                    name="rm"
                    id="rm-user"
                    isMulti
                    options={userDrop}
                    placeholder={
                      <div
                        className="select-placeholder-text"
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        Select
                      </div>
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    style={{ cursor: "pointer" }}
                    onChange={(e) => {
                      let rm_data = e?.map((value) => {
                        return value.value;
                      });
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        rm_id: rm_data,
                      });
                      setRmDataSelected(e);
                    }}
                    value={rmDataSelected}
                  />
                </div>
                <div className="form-group innergroup">
                  <label>Select HRBP</label>
                  <Select
                    name="hr"
                    id="hr-user"
                    isMulti
                    options={userDrop}
                    placeholder={
                      <div
                        className="select-placeholder-text"
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        Select
                      </div>
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    style={{ cursor: "pointer" }}
                    onChange={(e) => {
                      let rm_data = e?.map((value) => {
                        return value.value;
                      });
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        hr_id: rm_data,
                      });
                      setHrDataSelected(e);
                    }}
                    value={hrDataSelected}
                  />
                </div>
                <div className="form-group innergroup">
                  <label>Select Functional Head</label>
                  <Select
                    name="head"
                    id="head-user"
                    isMulti
                    options={userDrop}
                    placeholder={
                      <div
                        className="select-placeholder-text"
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        Select
                      </div>
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    style={{ cursor: "pointer" }}
                    onChange={(e) => {
                      let rm_data = e?.map((value) => {
                        return value.value;
                      });
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        head_id: rm_data,
                      });
                      setHeadDataSelected(e);
                    }}
                    value={headDataSelected}
                  />
                </div>
                <div class="form-group innergroup">
                  <label>
                    Select Date of Joining
                  </label> <br />
                  <div className="row">
                    <div className="col-md-6">
                      <label>
                        From Date
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        value={departmentDataFilter?.joining_date_from}
                        onChange={(e) => {
                          setDepartmentDataFilter({
                            ...departmentDataFilter,
                            joining_date_from: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>
                        To Date
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        value={departmentDataFilter?.joining_date_to}
                        onChange={(e) => {
                          setDepartmentDataFilter({
                            ...departmentDataFilter,
                            joining_date_to: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group innergroup">
                  <label>
                    Select Date of Leaving
                  </label> <br />
                  <div className="row">
                    <div className="col-md-6">
                      <label>
                        From Date
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        value={departmentDataFilter?.leaving_date_from}
                        onChange={(e) => {
                          setDepartmentDataFilter({
                            ...departmentDataFilter,
                            leaving_date_from: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>
                        To Date
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        value={departmentDataFilter?.leaving_date_to}
                        onChange={(e) => {
                          setDepartmentDataFilter({
                            ...departmentDataFilter,
                            leaving_date_to: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => {
                    clearFilter();
                    setCurrentPage(1);
                    resetForm();
                  }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                >
                  Apply{" "}
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
}

export default User;
