import React, { useState, useEffect } from "react";
import AddHotel from "./AddHotel";
import * as moment from "moment";
import * as TravelListingAPI from "../../service/travelService";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { injectStyle } from "react-toastify/dist/inject-style";
import AddConveyence from "./AddConveyence";
import { setUserAgent } from "react-device-detect";
if (typeof window !== "undefined") {
  injectStyle();
}
const Conveyance = (props) => {
  const { checkActiveTab, setCheckActiveTab, att } = props;
  const { state } = useLocation();
  const [conveyanceValue, setConveyanceValue] = useState([]);
  const [formView, setFormView] = useState(false);
  const [isEdit, setIsEdit] = useState("false");
  const [conveyanceList, setConveyanceList] = useState([]);
  const [conveyanceAmount, setConveyanceAmount] = useState([]);
  // const [att, setAtt] = useState([]);

  const getConveyenceList = async (data) => {
    await TravelListingAPI.getConveyenceList(data)
      .then((response) => {
        let res = response?.data?.dataList?.result;
        setConveyanceList(res);
        setConveyanceAmount(response?.data?.dataList);
        // setAtt(response?.data?.dataList?.attachment_path);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteConveyenceList = (id) => {
    confirmAlert({
      title: "Delete Conveyence",
      message: `Are you sure to delete Conveyence Listing?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteConveyence(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteConveyence = (idx) => {
    TravelListingAPI.deleteConveyence(idx)
      .then((res) => {
        if (res.data.status == true) {
          // getReasonList(
          //   searchStr,
          //   "",
          //   currentPage,
          //   pageSizeNo,
          //   sortData.sort_by,
          //   true
          // );
          getConveyenceList(state?.id);
        }
        toast.success(res.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleAddMore = () => {
    setIsEdit(true);
  };
  const editHotel = (data) => {
    setIsEdit(true);
    setConveyanceValue([data]);
    setFormView(true);
  };
  useEffect(() => {
    getConveyenceList(state?.id);
  }, []);

  return (
    <>
      <div class="table-responsive">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h6 class="f mt-2">
              <strong>
                Total :{" "}
                <span class="text-muted">
                  <i class="fas fa-rupee-sign f-14"></i>{" "}
                  {conveyanceAmount?.final_amount}
                </span>
              </strong>{" "}
            </h6>
          </div>
        </div>
        <table class="table table-striped table-bordered tablecured viewpo">
          <thead>
            <tr>
              <th>Action</th>
              <th>Travel Date</th>
              <th>From</th>
              <th>Destination</th>
              <th>Vendor Name</th>
              <th>Vendor State Name</th>
              <th>Amount</th>
              <th>Travel Mode</th>
              <th>Exception</th>
            </tr>
          </thead>
          <tbody>
            {conveyanceList?.length ? (
              conveyanceList?.map((data, index) => (
                <tr>
                  <td
                    style={data?.is_exception === true ? { color: "red" } : {}}
                  >
                    <button
                      class="btn btn-blanktd text-success"
                      onClick={() => {
                        editHotel(data);
                      }}
                    >
                      <i class="far fa-edit"></i>{" "}
                    </button>
                    <button
                      class="btn btn-blanktd text-danger ml-2"
                      onClick={() => {
                        deleteConveyenceList(data?.id, data?.name);
                      }}
                    >
                      <i class="far fa-trash-alt text-danger"></i>
                    </button>
                  </td>
                  <td
                    class="fw-bold text-theme"
                    style={data?.is_exception === true ? { color: "red" } : {}}
                  >
                    {moment.utc(data?.conveyance_date).format("DD-MM-YYYY")}
                  </td>
                  <td
                    style={data?.is_exception === true ? { color: "red" } : {}}
                  >
                    {data?.from_location_name}
                  </td>
                  <td
                    style={data?.is_exception === true ? { color: "red" } : {}}
                  >
                    {data?.to_location_name}
                  </td>
                  <td
                    style={data?.is_exception === true ? { color: "red" } : {}}
                  >
                    {
                      // data?.mode_name === "Rickshaw(Cycle/Auto)" ||
                      // data?.mode_name === "Public Transport" ||
                      // data?.mode_name === "OLA/Uber pool"
                      //   ? "-"
                      //   :

                      data?.vendor_name
                    }
                  </td>
                  <td
                    style={data?.is_exception === true ? { color: "red" } : {}}
                  >
                    {data?.vendor_state_name ?? "-"}
                  </td>
                  <td
                    style={data?.is_exception === true ? { color: "red" } : {}}
                  >
                    <strong>{data?.amount}</strong>
                  </td>
                  <td
                    style={data?.is_exception === true ? { color: "red" } : {}}
                  >
                    {data?.mode_name ?? "-"}
                  </td>
                  <td
                    style={data?.is_exception === true ? { color: "red" } : {}}
                  >
                    {data?.is_exception === true ? (
                      <i
                        class="fa fa-flag"
                        aria-hidden="true"
                        style={{ color: "red" }}
                      ></i>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={12}> No Record Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isEdit && (
        <AddConveyence
          conveyanceValue={conveyanceValue}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          conveyanceList={conveyanceList}
          getConveyenceList={getConveyenceList}
          conveyanceAmount={conveyanceAmount}
          setCheckActiveTab={setCheckActiveTab}
          formView={formView}
          setFormView={setFormView}
          att={att}
          handleAddMore={handleAddMore}
        />
      )}
    </>
  );
};

export default Conveyance;
