
import axios from "../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";





  export const getSubtransporter = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_sub_transporter${queryParm}`);
    
  };


  export const getSubtransporterList = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_sub_transport_request/get_request_data`);
    
  };

 

  export const getSubtransById = async (id) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_sub_transport_request/get_data_by_request_id${id}`);
    
  };
 
 
  export const PutSubtrans = async (data) => {
    return axios.put(`${AUTH_BASE_URL}/lr_gr_sub_transport_request/update_data_by_request_id`, data);
  };
  
  export const PostSubtransadd = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/lr_gr_sub_transport_request/`, data);
  };
  
  
  export const getSubapprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/lr_gr_sub_transport_request/request_approval_history${query}`)
}

  export const postApproverAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/lr_gr_sub_transport_request/get_approver_action`, body);
}

  export const updateSubtransporter = async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/lr_gr_sub_transporte/${id}`, data);
  };
  
  export const deleteSubtransporter = async (id) => {
    return axios.delete(`${AUTH_BASE_URL}/lr_gr_sub_transporte/${id}`);
  };

  export const getBranch = async (paginate) => {
    return axios.get(
      `${AUTH_BASE_URL}/business_units?paginate=${paginate}&sort_by=name`
    );
  };
  
  export const isSubtransporterExists = async (isData) => {
    return axios.post(`${AUTH_BASE_URL}/lr_gr_sub_transporte/exists`, isData);
  };
  
  export const getAllSubtransporter = async (paginate) => {
    return axios.get(
      `${AUTH_BASE_URL}/lr_gr_sub_vendor?paginate=${paginate}&sort_by=name`
    );
  };
  
  export const getExportValue = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/lr_gr_sub_transporte/export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };
  