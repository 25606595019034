import { Tooltip } from "react-tippy";
import { OverlayTrigger } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import * as OrganisationService from "../../service/organisation";
import * as otherExpService from "../../service/otherExpService";

import { useDispatch, useSelector } from "react-redux";

import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";

function OtherExpCheck() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const finalUser = useSelector(selectUserData);
  const [userGroup, setuserGroup] = useState({
    _userGroup: "",
  });

  const [sapResp, set_sapResp] = useState({
    data: "",
    reqid: "",
    is_visible: false,
    is_error: false,
    is_success: false,
  });

  const back_fromapproved = useLocation();

  const [locationState, setLocationState] = useState(
    back_fromapproved.state || null
  );

  const [show, filtersetShow] = useState(false);
  const [otherExpData, setotherExpData] = useState([]);

  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(200);
  const [pageCount, setPageCount] = useState(1);
  const [totalRecord, setTotalRecord] = useState();
  const [searchStr, setSearchStr] = useState("");
  const [travelList, setTravelList] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [orgDropDown, setorgDropDown] = useState([]);
  const [organisation_User, setOrganisation_User] = useState({
    company: "",
    user_id: "",
  });

  const [filterdata, setfilterData] = useState({
    user_id: { value: undefined },
    id: "",
    organisation: { value: undefined },
    from_date: "",
    to_date: "",
  });

  const handleKey1 = (
    status,
    is_picked,
    is_picked_by,
    page_no,
    page_size,
    sort_by,
    paginate,
    emp_code,
    org,
    reqid,
    search,
    ssc_user_status,
    ssc_manager_status_none
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (emp_code !== "" && emp_code !== undefined && emp_code !== null) {
      queryParm = queryParm + "&user_id=" + emp_code;
    }
    if (is_picked !== "" && is_picked !== undefined && is_picked !== null) {
      queryParm = queryParm + "&is_picked=" + is_picked;
    }
    if (
      is_picked_by !== "" &&
      is_picked_by !== undefined &&
      is_picked_by !== null
    ) {
      queryParm = queryParm + "&is_picked_by_id=" + is_picked_by;
    }
    if (
      ssc_user_status !== "" &&
      ssc_user_status !== undefined &&
      ssc_user_status !== null
    ) {
      queryParm = queryParm + "&ssc_user_status=" + ssc_user_status;
    }
    if (
      ssc_manager_status_none !== "" &&
      ssc_manager_status_none !== undefined &&
      ssc_manager_status_none !== null
    ) {
      queryParm =
        queryParm + "&ssc_manager_status_none=" + ssc_manager_status_none;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (reqid !== "" && reqid !== undefined && reqid !== null) {
      queryParm = queryParm + "&id=" + reqid;
    }
    if (org !== "" && org !== undefined && org !== null) {
      queryParm = queryParm + "&organisation=" + org;
    }
    return queryParm;
  };

  const getOtherExp = (
    status,
    is_picked,
    is_picked_by,
    pageNo,
    pageSize,
    sort_by,
    paginate,
    emp_code,
    org,
    reqid,
    search,
    ssc_user_status,
    ssc_manager_status_none
  ) => {
    dispatch(setDisplayLoader("Display"));

    otherExpService
      .getOtherExp(
        handleKey1(
          status,
          is_picked,
          is_picked_by,
          pageNo,
          pageSize,
          sort_by,
          paginate,
          emp_code,
          org,
          reqid,
          search,
          ssc_user_status,
          ssc_manager_status_none
        )
      )
      .then((response) => {
        // console.log(`otherexp...`,response);
        dispatch(setDisplayLoader("Hide"));
        // let data = response?.data?.result;
        // setVehicleData(response?.data?.dataList?.result);
        let ress = response?.data?.dataList?.result;
        // console.log(`-->`,userGroup);
        // switch (userGroup._userGroup){
        //     case "ssc user":
        //         ress=response?.data?.dataList?.result.filter((data)=>{
        //             return !data.ssc_user_status
        //         })
        //         break
        //     case "ssc manager":
        //         ress=response?.data?.dataList?.result.filter((data)=>{
        //             return data.ssc_user_status==="A" && !data.ssc_manager_status
        //         })
        //         break
        //     default:
        //         break

        // }
        console.log(`log..`, ress);
        setotherExpData(ress);
        setCurrentPage(response?.data?.dataList?.paginated_data?.currentPage);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    // getOtherExp("A",true,
    //   activePage,
    //   pageSizeNo,
    //   "-id",
    //   true,
    //   "","","",""
    // );

    if (userGroup._userGroup == "ssc manager") {
      getOtherExp(
        "",
        "",
        "",
        activePage,
        pageSizeNo,
        "-id",
        true,
        "",
        "",
        "",
        "",
        "A",
        "true"
      );
    } else {
      getOtherExp(
        "A",
        true,
        finalUser?.id,
        activePage,
        pageSizeNo,
        "-id",
        true,
        "",
        "",
        "",
        "",
        "",
        ""
      );
    }
  };

  const handleSapModal = () => {
    setLocationState(null);

    set_sapResp((prev) => ({
      ...prev,
      is_visible: false,
    }));
  };

  useEffect(() => {
    // let qry = new URLSearchParams(window.location.search)
    // let sapdata = qry.get("sap")

    let apppr_back = back_fromapproved?.state?.sapdata;

    console.log(`appr`, apppr_back);
    if (
      apppr_back !== undefined &&
      Object.keys(apppr_back?.response)?.length > 0
    ) {
      set_sapResp({
        data: apppr_back?.response,
        reqid: apppr_back?.reqid,
        is_error: apppr_back?.is_error,
        is_success: apppr_back?.is_success,
        is_visible: true,
      });

      setTimeout(() => {
        handleSapModal();
      }, 3000);
    }

    let localGroup = "";

    finalUser.groups.every((data) => {
      if (
        data?.name.toLowerCase() === "ssc user" ||
        data?.name.toLowerCase() === "ssc manager"
      ) {
        localGroup = data?.name.toLowerCase();
        console.log(`-->`, localGroup);
        userGroup._userGroup = localGroup;
        return false;
      }
      return true;
    });

    if (localGroup == "ssc manager") {
      getOtherExp(
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        "",
        "",
        "",
        "A",
        "true"
      );
    } else {
      getOtherExp(
        "A",
        true,
        finalUser?.id,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        "",
        "",
        "",
        ""
      );
    }

    getUsersList();
    // getAllOrganisation()
    getBusinessUnits();
  }, []);

  useEffect(() => {
    if (userGroup._userGroup == "ssc manager") {
      getOtherExp(
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        "",
        "",
        "",
        "A",
        "true"
      );
    } else {
      getOtherExp(
        "A",
        true,
        finalUser?.id,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        "",
        "",
        "",
        ""
      );
    }
  }, [pageSizeNo]);

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      // getOtherExp("A",true,
      //     currentPage,
      //     pageSizeNo,
      //     "-id",
      //     true,
      //     "","","",
      //     e.target.value
      // );

      if (userGroup._userGroup == "ssc manager") {
        getOtherExp(
          "",
          "",
          "",
          currentPage,
          pageSizeNo,
          "-id",
          true,
          "",
          "",
          "",
          e.target.value,
          "A",
          "true"
        );
      } else {
        getOtherExp(
          "A",
          true,
          finalUser?.id,
          currentPage,
          pageSizeNo,
          "-id",
          true,
          "",
          "",
          "",
          e.target.value,
          ""
        );
      }

      setSearchStr(e.target.value);
    }
  };

  const mainDataFilter = () => {
    console.log(" filterdata:", filterdata);

    // getOtherExp("A",true,
    //     currentPage,
    //     pageSizeNo,
    //     "-id",
    //     true,
    //     filterdata?.user_id.value,
    //     filterdata?.organisation.value,
    //     filterdata?.id,
    //     searchStr,ssc_user_status="A"
    //     );

    if (userGroup._userGroup == "ssc manager") {
      getOtherExp(
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        "-id",
        true,
        filterdata?.user_id.value,
        filterdata?.organisation.value,
        filterdata?.id,
        searchStr,
        "A",
        "true"
      );
    } else {
      getOtherExp(
        "A",
        true,
        finalUser?.id,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        filterdata?.user_id.value,
        filterdata?.organisation.value,
        filterdata?.id,
        searchStr,
        ""
      );
    }

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    getOtherExp(
      "A",
      true,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      "",
      "",
      searchStr
    );

    if (userGroup._userGroup == "ssc manager") {
      getOtherExp(
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        "",
        "",
        "",
        "A",
        "true"
      );
    } else {
      getOtherExp(
        "A",
        true,
        finalUser?.id,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        "",
        "",
        "",
        ""
      );
    }

    setfilterData({
      organisation: { value: undefined },
      id: "",
      user_id: { value: undefined },
      from_date: "",
      to_date: "",
    });
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  const getAllOrganisation = () => {
    OrganisationService.getAllOrganisation(false).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name} ( ${x.code} )`,
          };
        }),
      ];
      setorgDropDown(data);
    });
  };

  const getBusinessUnits = () => {
    otherExpService.getBusinessUnits().then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name}( ${x.code} )`,
          };
        }),
      ];
      setorgDropDown(data);
    });
  };

  const getChcekExpenseList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status
  ) => {
    TravelListingAPI.getCheckExpenseList(
      handleKey(search, page_no, page_size, sort_by, paginate, status)
    ).then((response) => {
      setTravelList(response?.data?.dataList?.result);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
    });
  };

  const handleKey = (search, page_no, page_size, sort_by, paginate, status) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    return queryParm;
  };

  const getExportValue = (
    search,
    page_no,
    page_Size,
    sort_by,
    paginate,
    status
  ) => {
    // dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getCheckExpenseExportValue(
      handleKey(search, page_no, page_Size, sort_by, paginate, status)
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CheckExpense.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          {/* <div class="col-md-3">
                                    <ul class="policylistnames">
                                        <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                        <li class="active"><Link to="/travel-expense">Check Expense</Link></li>
                                        <li><Link to="/travel-bills">All Bills</Link></li>
                                        <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                        <li><Link to="/travel-km-report">KM Report</Link></li>
                                        <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                        <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                        <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                        <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                        <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                        <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                    </ul>
                                </div> */}

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Approve Other Expense SSC</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong> Name, Employee ID, Expense ID</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      {/* <Dropdown show>
                                                            <Dropdown.Toggle variant="primary-inner dropdownbtn" id="dropdown-basic">
                                                                <img src="images/export.png" alt="" class="mr-2" /> Export
                                                            </Dropdown.Toggle>
    
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#">
                                                                <button
                                                                type="button"
                                                                disabled={travelList.length !== 0 ? false : true}
                                                                style={{ border: 0, background: "none" }}
                                                                onClick={() =>
                                                                    getExportValue(searchStr, currentPage, pageSizeNo, sortData.sort_by, true, "pending", )
                                                                }
                                                                >
                                                                <i className="far fa-file-excel"></i>Excel
                                                                </button>
                                                                    </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown> */}
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <Tabs defaultActiveKey="draft" id="approvaltabs">
                  <Tab
                    eventKey="draft"
                    title={
                      ""
                      // <React.Fragment>
                      //     In Process
                      //     <span class="numberpurchse">{totalRecord}</span>
                      // </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>SR. NO.</th>
                            <th>REQUEST ID</th>
                            <th>ACTION</th>
                            <th>EMPLOYEE ID</th>
                            <th>EMP NAME</th>
                            <th>FOR MONTH OF</th>
                            <th>REQUESTED ON</th>
                            <th>DOC REC ON</th>
                            <th>CLAIM AMOUNT</th>
                            {userGroup?._userGroup == "ssc manager" && (
                              <th>APPR. AMOUNT</th>
                            )}
                            <th>PROCESSED ON</th>
                            <th>SSC EXE DELAY DAYS</th>
                            <th>PROCESSED BY</th>
                            {userGroup._userGroup == "ssc manager" && (
                              <th>SAP ERROR</th>
                            )}
                            <th>REMARKS</th>

                            {/* <th></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {otherExpData?.length ? (
                            otherExpData?.map((data, index) => {
                              return (
                                <tr

                                style={{
                                  backgroundColor:
                                    data?.chat_status > 0
                                      ? "yellow"
                                      : (data?.send_back_remark||data?.send_back_ssc_remark)
                                      ? "#FFBF00"
                                      : data?.is_exception
                                      ? "#FFA895"
                                      : "",
                                }}                                
                                >
                                  <td>{index + 1}</td>
                                  <td>
                                    {data?.request_id
                                      ? data?.request_id
                                      : data?.expense_type == "petty"
                                      ? `${data?.organisation_code}PE`
                                      : `${data?.user_organisation_code}OE` +
                                        data?.id}
                                  </td>
                                  <td>
                                    <Link
                                      // to="/otherexp-check-expense"
                                      to={
                                        "/otherexp-check-expense?id=" + data?.id
                                      }
                                      state={{
                                        travel_id: data?.id,
                                        predata: data,
                                      }}
                                    >
                                      <button class="btn btn-primary-inner bpi-main px-2 mr-2 py-1 f-14">
                                        <i class="far fa-check-circle"></i>{" "}
                                        Check
                                      </button>
                                    </Link>
                                    <Link
                                      to="/otherexp-printview"
                                      state={{
                                        travel_id: data?.id,
                                        backpath: "/otherexp-check",
                                        predata: data,
                                        showPrintData: false,
                                      }}
                                    >
                                      <button class="btn btn-blanktd text-primary">
                                        <i class="fas fa-print"></i>
                                      </button>
                                    </Link>
                                  </td>
                                  <td>{data?.user_code}</td>
                                  <td>{data?.user_name}</td>
                                  <td>
                                    {data?.expense_type
                                      ?.toLowerCase()
                                      ?.includes("petty")
                                      ? data?.expense_data[0]?.for_month_of &&
                                        moment
                                          .parseZone(
                                            data?.expense_data[0]?.for_month_of
                                          )
                                          .format("MMMM")
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {data?.created_at &&
                                      moment
                                        .parseZone(data?.created_at)
                                        .format("DD-MM-YYYY")}
                                  </td>
                                  <td>
                                    {data?.is_picked_at &&
                                      moment
                                        .parseZone(data?.is_picked_at)
                                        .format("DD-MM-YYYY")}
                                  </td>
                                  <td>{data?.total_bill_amount?.toFixed(2)}</td>
                                  {userGroup?._userGroup == "ssc manager" && (
                                    <td>{data?.total_approved?.toFixed(2)}</td>
                                  )}
                                  <td>
                                    {data?.doc_rec_on &&
                                      moment
                                        .parseZone(data?.doc_rec_on)
                                        .format("DD-MM-YYYY")}
                                  </td>
                                  <td>
                                    {moment
                                      .parseZone(new Date())
                                      .diff(
                                        moment.parseZone(
                                          new Date(data?.doc_rec_on)
                                        ),
                                        "days"
                                      )}
                                  </td>
                                  <td>
                                    {data?.is_picked_by_user_name} (
                                    {data?.is_picked_by_user_code})
                                  </td>
                                  {userGroup._userGroup == "ssc manager" && (
                                    <td
                                      style={{ overflow: "auto" }}
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      data-html="true"
                                      title={
                                        data?.sap_error_self +
                                        " " +
                                        data?.sap_error_company
                                      }
                                    >
                                      {data?.sap_error_self}{" "}
                                      {data?.sap_error_company}
                                    </td>
                                  )}
                                  <td>{data?.send_back_remark}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={12}>No Records Found...</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                            value={pageSizeNo}
                            class="form-control"
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={50} label={50}>
                              50
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                            <option value={200} label={200}>
                              200
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          disableInitialCallback={true}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  user_id: e,
                })
              }
              value={filterdata.user_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Business unit <span class="text-danger"></span>
            </label>
            <Select
              options={orgDropDown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  organisation: e,
                })
              }
              value={filterdata.organisation}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Req ID <span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  id: e.currentTarget.value,
                })
              }
              value={filterdata.id}
            />
          </div>

          {/* <div class="form-group innergroup">
                            <label>Trip Number <span class="text-danger"></span></label>
                            <Select  
                                options={options}
                                className="basic-multi-select"
                                classNamePrefix="select" 
                            />
                        </div> */}

          {/* <div class="form-group innergroup">
                        <label>
                            Start Date <span class="text-danger"></span>
                        </label>
                        <input type="date" class="form-control"
                            onChange={(e) => setfilterData({
                                ...filterdata,
                                from_date: e.currentTarget.value
                            })}
                            value={filterdata.from_date}
                        />
                    </div>

                    <div class="form-group innergroup">
                        <label>
                            End Date <span class="text-danger"></span>
                        </label>
                        <input type="date" class="form-control"
                            onChange={(e) => setfilterData({
                                ...filterdata,
                                to_date: e.currentTarget.value

                            })}
                            value={filterdata.to_date}
                        />
                    </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={sapResp.is_visible}
        // show={true}
        onHide={handleSapModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            SAP Response <br />
            <h5>(Request ID- {sapResp?.reqid})</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          <div class="container-fluid">
            {sapResp?.is_success ? (
              <p>
                Document number is generated successfully
                <br />
              </p>
            ) : (
              ""
            )}
            {sapResp?.is_success
              ? sapResp?.data?.split()?.map((vall) => <>{vall}</>)
              : sapResp?.data}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OtherExpCheck;
