import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import * as DashboardService from "../service/dashboard";
import * as moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import avtar from "../assets/images/avtar.png";

const reviewValidationSchema = Yup.object({
  wish_remarks: Yup.string().required("Remarks is required"),
});

const AnniversaryModal = (props) => {
  const {
    anniversaryReviewData,
    setShowAnniversaryForm,
    getWorkAnniversaryList,
    formopen,
    formclose,
  } = props;
  const [reviewData, setReviewData] = useState({
    user_id: anniversaryReviewData.user_id,
    wish_remarks: "",
  });

  const saveWorkAnniversaryRemarks = async (values) => {
    DashboardService.saveWorkAnniversaryRemarks(values)
      .then((response) => {
        getWorkAnniversaryList();
        setShowAnniversaryForm(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <Modal
      show={formopen}
      onHide={formclose}
      backdrop="static"
      keyboard={false}
      className="filter-popup modaldefaultclose"
    >
      <Modal.Header closeButton>
        <Modal.Title>Work Anniversary</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={reviewData}
        validationSchema={reviewValidationSchema}
        onSubmit={async (values) => {
          // saveWorkAnniversaryRemarks(values);
          // await new Promise((resolve) => setTimeout(resolve, 500));
          try {
            const response = await DashboardService.saveWorkAnniversaryRemarks(
              values
            );
            getWorkAnniversaryList();
            setShowAnniversaryForm(false);
          } catch (error) {
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          }
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div class="form-group innergroup">
                  <div class="d-flex align-items-center pb-3 border-bottom">
                    <div>
                      <div class="team-profile">
                        <img
                          src={
                            anniversaryReviewData?.image === ""
                              ? avtar
                              : `data:image/gif;base64,${anniversaryReviewData?.image}`
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="teamtxt">
                      <h5 class="mb-0 wishname">
                        <strong>{anniversaryReviewData?.name}</strong>
                      </h5>
                      {/* <p class="text-dark">
                        <strong>DOJ:</strong>
                        {moment
                          .utc(anniversaryReviewData?.dob)
                          .format("Do MMM YYYY")}
                      </p> */}
                      <p class="text-dark"><strong>Dept.:</strong> {anniversaryReviewData?.department_name}</p>
                      <p class="text-dark"><strong>Desig.:</strong> {anniversaryReviewData?.designation_name}</p>
                      <p class="text-dark"><strong>Location.:</strong> {anniversaryReviewData?.location_name}</p>
                      {anniversaryReviewData?.mobile !== null ?
                      <p class="text-dark"><strong>Mob.:</strong> {anniversaryReviewData?.mobile}</p>
                      :
                      <p class="text-dark"><strong>Mob.:</strong> N/A</p>
                      }
                    </div>
                  </div>
                </div>
                <div class="form-group innergroup mb-4">
                  <label>
                    Your Message<span class="text-danger">*</span>
                  </label>
                  <textarea
                    type="text"
                    name="wish_remarks"
                    class="form-control"
                    placeholder="Enter Message here..."
                    onBlur={handleBlur}
                    onChange={handleChange}
                    values={reviewData}
                  ></textarea>
                  {touched.wish_remarks && errors.wish_remarks ? (
                    <div className="small text-danger">
                      {errors.wish_remarks}
                    </div>
                  ) : null}
                </div>

                <button
                  class="btn btn-outline-danger btn-xs"
                  onClick={formclose}
                >
                  Cancel
                </button>
                <button
                  class="btn btn-primary-inner bpi-main btn-xs"
                  type="Submit"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AnniversaryModal;
