import axios from "./../../../utils/axios";
import { AUTH_BASE_URL } from "../../../constants/URL";

export const getAGSList = async () => {
  return axios.get(`${AUTH_BASE_URL}/ag-sales/get_sales_data`);
}

export const getAGSListID = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/ag-sales/get_data_by_request_id?${queryParam}`);
}

export const postAGSList = async (body, queryParam) => {
  return axios.post(`${AUTH_BASE_URL}/ag-sales/create_Request?${queryParam}`, body);
}

export const putAGSList = async (body, queryParam) => {
  return axios.post(`${AUTH_BASE_URL}/ag-sales/update_request?${queryParam}`, body);
}

export const getAGCFY = async () => {
  return axios.get(`${AUTH_BASE_URL}/ag-sales/current_fy_router`);
}

export const getAGReqList = async () => {
  return axios.get(`${AUTH_BASE_URL}/ag-sales/Request_Listing`);
}

export const getaproveraction = async (body) => {
  return axios.post(`${AUTH_BASE_URL}/ag-sales/approver_action`, body);
}


export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/ag-sales/export_to_excel?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getaprvhisList = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/ag-sales/get_approver_history?${queryParam}`);
}

export const getTerritory = async () => {
  return axios.get(`${AUTH_BASE_URL}/territory/drop-down`);
}

export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
}


export const getDirectoryDetail = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/ag-sales/sales_directory_details?${queryParam}`);
};

export const importExelAg = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/ag-sales/User_bulk_import`,
    method: "POST",
    responseType: "blob",
    data: data,
  })
}

