import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as SkillService from "../../service/skill";
import * as SkillDepartmentService from "../../service/skillDepartment";
import * as SkillPlantService from "../../service/skillPlant";
import * as SkillGroupService from "../../service/skillGroup";
import Select from "react-select";

const validationSchema = Yup.object({
  desc: Yup.string()
    .required("*Description is required")
    .matches(
      /[^\s*].*[^\s*]/g,
      "* This field cannot contain only blank spaces"
    ),
});

const AddSkill = (props) => {
  const nameForm = "Skill Master";
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const { edit, showForm, onClose, editSkills, onAddCallBack } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isSkillExist, setIsSkillExist] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [dataLocation, setDataLocation] = useState([]);
  const [locationError, setLocationError] = useState(false);
  const [departmentList, setDepartmentList] = useState([])
  const [departmentOption, setDepartmentOption] = useState({})
  const [departmentError, setDepartmentError] = useState(false)
  const [plantList, setPlantList] = useState([])
  const [plantOption, setPlantOption] = useState({})
  const [plantError, setPlantError] = useState(false)
  const [groupList, setGroupList] = useState([])
  const [groupOption, setGroupOption] = useState({})
  const [groupError, setGroupError] = useState(false)
  const [subGroupList, setSubGroupList] = useState([])
  const [subGroupOption, setSubGroupOption] = useState({})
  const [subGroupError, setSubGroupError] = useState(false)


  const [skillData, setSkillData] = useState({
    desc: "",
    is_active: true,
  });

  const getSubPersonal = (id) => {
    SkillService.getSubPersonal(id)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = {
            value: x.id,
            label: `${x?.name} ${x?.description}`,
          };
          data.push(data1);
        });
        setLocationData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleKey = (search, pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getDepartment = (search, pageNo, page_size, sort_by, paginate) => {
    SkillDepartmentService.getDepartment(
      handleKey(search, "1", "1000", "-id", true)
    )
      .then((response) => {
        let deptList = []
        response?.data?.dataList?.result.map((element) => {
          deptList.push({
            value: element.department_code,
            label: element.department_name
          })
        })
        setDepartmentList(deptList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getPlant = (search, pageNo, page_size, sort_by, paginate) => {
    SkillPlantService.getPlant(
      handleKey(search, "1", "1000", "-id", true)
    )
      .then((response) => {
        let plList = []
        response?.data?.dataList?.result.map((element) => {
          plList.push({
            value: element.plant_code,
            label: element.plant_name
          })
        })
        setPlantList(plList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getParentGroup = (search, pageNo, page_size, sort_by, paginate) => {
    SkillGroupService.getSkillGroup(
      handleKey(search, "1", "1000", "-id", true)
    )
      .then((response) => {
        let plList = []
        response?.data?.dataList?.result.map((element) => {
          plList.push({
            value: element.id,
            label: element.group_name
          })
        })
        setGroupList(plList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getSubGroup = (e, isEdit) => {
    SkillGroupService.getSkillSubGroupByParent(e.value)
      .then((response) => {
        let plList = []
        response?.data?.dataList?.result.map((element) => {
          plList.push({
            value: element.id,
            label: element.group_name
          })
        })
        setSubGroupList(plList);
        if (isEdit) {
          setSubGroupOption({
            value: editSkills?.sub_group?.id,
            label: editSkills?.sub_group?.sub_group_name
          })
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  console.log("dataLocation", dataLocation)

  const saveSkills = (values) => {
    let data = dataLocation.map((x) => {
      return x.value
    });
    values.location = data;
    values.department_code = departmentOption.value
    values.plant_code = plantOption.value
    values["group"] = {
      "id": groupOption.value,
      "group_name": groupOption.label
    }
    if (Object.keys(subGroupOption).length > 0) {
      values["sub_group"] = {
        "id": subGroupOption.value,
        "sub_group_name": subGroupOption.label
      }
    }
    setIsLoading(true);
    SkillService.saveSkills(values)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updateSkills = (values) => {
    let data = dataLocation.map((x) => {
      return {
        loc_id: x.value,
        loc_name: x.label
      };
    });
    values.location = data;
    values.department_code = departmentOption.value
    values.plant_code = plantOption.value
    values["group"] = {
      "id": groupOption.value,
      "group_name": groupOption.label
    }
    if (Object.keys(subGroupOption).length > 0) {
      values["sub_group"] = {
        "id": subGroupOption.value,
        "sub_group_name": subGroupOption.label
      }
    }
    console.log(values)
    setIsLoading(true);
    SkillService.updateSkills(values, values.id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const isDataExists = (e, fieldName) => {
    let data = {};
    data[fieldName] = e.target.value;
    if (editSkills?.name === e.target.value) {
      setIsSkillExist(false);
      return;
    } else {
      SkillService.isDataExists(data)
        .then((res) => {
          if (e.target.name === "skill_id") {
            setIsSkillExist(res?.data?.dataList?.is_exist);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const locationPush = (e) => {
    setDataLocation(e);
  };

  useEffect(() => {
    if (editSkills) {
      let locationData = editSkills.location.map((x) => {
        return { value: x.loc_id, label: x.loc_name };
      });
      setGroupOption({
        value: editSkills?.group?.id,
        label: editSkills?.group?.group_name
      })
      let deptOption = departmentList.findIndex(el => editSkills.department_code == el.value)
      setDepartmentOption(departmentList[deptOption])
      let plOption = plantList.findIndex(el => editSkills.plant_code == el.value)
      setPlantOption(plantList[plOption])
      getSubGroup({ value: editSkills?.group?.id }, true)
      setDataLocation(locationData);
      setSkillData(editSkills);
      Object.keys(editSkills).forEach((item) => {
        skillData[item] = editSkills[item];
      });
    }
  }, [editSkills, departmentList, plantList]);

  useEffect(() => {
    getSubPersonal(0);
    getDepartment();
    getPlant();
    getParentGroup()
  }, []);

  useEffect(() => {
    console.log("auditValueLog", auditValueLog);
  }, [auditValueLog]);

  const submitHandler = () => {
    if (dataLocation.length === 0) {
      setLocationError(true);
    }
    if (!editSkills?.id && Object.keys(departmentOption).length == 0) {
      setDepartmentError(true)
    }
    if (!editSkills?.id && Object.keys(plantOption).length == 0) {
      setPlantError(true)
    }
    if (Object.keys(groupOption).length == 0) {
      setGroupError(true)
    }
  };

  return (
    <>
      <Formik
        initialValues={skillData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (isSkillExist !== true && !locationError) {
            if (values.id) {
              updateSkills(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            } else {
              saveSkills(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <ToastContainer autoClose={1000} />
            <Modal.Header closeButton>
              <Modal.Title> {edit === true ? "Edit" : "Add"} Skill</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <div className="row">
                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Department<span class="text-danger">*</span>
                          </label>
                          <Select
                            name="department"
                            id="department"
                            options={departmentList}
                            className="basic-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              setDepartmentOption(e);
                              setDepartmentError(false);
                            }}
                            value={departmentOption}
                          />
                          {departmentError && (
                            <div className="small text-danger">
                              *Department is required
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Plant<span class="text-danger">*</span>
                          </label>
                          <Select
                            name="plant"
                            id="plant"
                            options={plantList}
                            className="basic-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              setPlantOption(e);
                              setPlantError(false);
                            }}
                            value={plantOption}
                          />
                          {plantError && (
                            <div className="small text-danger">
                              *Plant is required
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Description
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="desc"
                            className="form-control"
                            placeholder="Enter Description"
                            maxLength={400}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.desc}
                          />
                          {touched.desc && errors.desc ? (
                            <div className="small text-danger">
                              {errors.desc}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Location<span class="text-danger">*</span>
                          </label>
                          <Select
                            name="location"
                            id="locations"
                            isMulti
                            options={locationData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            onChange={(e) => {
                              locationPush(e);
                              setLocationError(false);
                            }}
                            value={dataLocation}
                          />
                          {locationError && (
                            <div className="small text-danger">
                              *Location is required
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Parent Group<span class="text-danger">*</span>
                          </label>
                          <Select
                            name="parent_group"
                            id="parent_group"
                            options={groupList}
                            className="basic-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              setGroupOption(e);
                              setGroupError(false);
                              getSubGroup(e)
                              setSubGroupOption({})
                            }}
                            value={groupOption}
                          />
                          {groupError && (
                            <div className="small text-danger">
                              *Parent Group is required
                            </div>
                          )}
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Sub Group
                          </label>
                          <Select
                            name="sub_group"
                            id="sub_group"
                            options={subGroupList}
                            className="basic-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              setSubGroupOption(e);
                              setSubGroupError(false);
                            }}
                            value={subGroupOption}
                          />
                          {subGroupError && (
                            <div className="small text-danger">
                              *Sub Group is required
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label className="d-block">
                            Status <span className="text-danger"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="active"
                          >
                            Active
                            <input
                              type="radio"
                              name="is_active"
                              id="active"
                              value={values.is_active}
                              onChange={() => {
                                setFieldValue("is_active", true);
                              }}
                              checked={values.is_active === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="Inactive"
                          >
                            Inactive
                            <input
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={values.is_active}
                              onChange={(e) => {
                                setFieldValue("is_active", false);
                              }}
                              checked={values.is_active === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-inner bpi-main"
                      onClick={() => submitHandler()}
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddSkill;
