import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import AddLeaveAllocation from "./AddLeaveAllocation";
import * as LeaveService from "../../service/leaveService";
import EditLeaveAllocation from "./EditLeaveAllocation";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { setDisplayLoader } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Select from "react-select";

if (typeof window !== "undefined") {
  injectStyle();
}
const LeaveAllocation = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [leavemanage, setLeaveManage] = useState(null);
  const [leaveManageList, setLeaveManageList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [tableData, setTableData] = useState([]);
  const [allLeaveType, setAllLeaveType] = useState([]);
  const [allOrg, setAllOrg] = useState([]);
  const [allBusiness, setAllBusiness] = useState([]);
  const [leaveRuleList, setLeaveRuleList] = useState([]);
  const [editData, setEditData] = useState(null);
  const [filterApplied, setFilterApplied] = useState(false);
  const [leaveAllocationDataForExport, setLeaveAllocationDataForExport] =
    useState({
      leave_type_id: "",
      branch_id: "",
      organisation_id: "",
      allocate_to_id: "",
    });
  const [leaveAllocationDataFilter, setLeaveAllocationDataFilter] = useState({
    leave_type_id: 0,
    branch_id: "",
    organisation_id: "",
    allocate_to_id: "",
  });

  const onClose = () => {
    setShowForm(false);
    setShowEditForm(false);
  };
  const addNewBranch = () => {
    setEditMode(false);
    setShowForm(true);
    setLeaveManage(null);
  };
  const editNewBranch = (id, item) => {
    setEditMode(true);
    setShowEditForm(true);
    setLeaveManage(item);
    getUserDataList(id);
  };
  const editRecord = (data) => {
    setEditMode(true);
    setShowForm(true);
    setLeaveManage(data);
  };

  const getOrganisation = (paginate) => {
    LeaveService.getOrganisation(paginate)
      .then((response) => {
        let org = response?.data?.dataList?.result?.map((each) => {
          return {
            label: `${each?.name} (${each?.code})`,
            value: each?.id,
          };
        });
        setAllOrg(org);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getAllBranch = (paginate) => {
    LeaveService.getAllBranch(paginate)
      .then((response) => {
        setAllBusiness(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getLeaveManageListData = () => {
    let query = `sort_by=code&paginate=${false}`;
    LeaveService.getLeaveManageListData(query)
      .then((response) => {
        setAllLeaveType(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getRecords(searchStr, sdata?.sort_by);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    setShowEditForm(false);
    getRecords("", "-id");
  };
  const deleteLeave = (idx) => {
    LeaveService.deleteLeaveAllocate(idx)
      .then((res) => {
        if (res.data.status == true) {
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const submit = (id, policyName) => {
    confirmAlert({
      title: "Delete Leave Allocate",
      message: `Are you sure to delete ${policyName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteLeave(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const delRule = (id) => {
    confirmAlert({
      title: "Delete Leave Rule",
      message: `Are you sure to delete this rule ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => delLeaveRule(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const delLeaveRule = async (id) => {
    await LeaveService.deleteLeaveRule(id).then((response) => {
      if (response?.status === 200) {
        toast.success("Leave Rule deleted Successfully.");
        getRecords("", "");
      }
    });
  };

  const getUserDataList = (id) => {
    LeaveService.getUserDetails(id)
      .then((response) => {
        setUserData(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const renderDeptOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`business${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getRecords(e.target.value, sortData?.sort_by);
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getRecords("", sortData?.sort_by);
    }
  };
  const mainDataFilter = () => {
    if (
      leaveAllocationDataFilter?.leave_type_id !== "" ||
      leaveAllocationDataFilter?.organisation_id !== ""
    ) {
      setFilterApplied(true);
    }
    const leave_type_id = leaveAllocationDataFilter.leave_type_id;
    const organisation_id = leaveAllocationDataFilter?.organisation_id?.value;
    getRecords(searchStr, sortData?.sort_by, leave_type_id, organisation_id);
    filterClose();
  };
  const filterClose = () => {
    filterclose();
  };
  const filterShow = () => {
    getOrganisation(false);
    filteropen();
    getLeaveManageListData();
    getAllBranch(false);
  };
  const clearFilter = () => {
    setFilterApplied(false);
    setLeaveAllocationDataFilter({
      leave_type_id: 0,
      branch_id: "",
      organisation_id: "",
      allocate_to_id: "",
    });
    getRecords("", sortData?.sort_by);
    filterClose();
  };
  const renderOrgLeaveTypeOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`leaveType${index}`}
        value={`${item?.id}`}
        label={`${item?.code} (${item?.name})`}
      >{`${item?.code} (${item?.name})`}</option>
    ));
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getRecords("", sortData?.sort_by);
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getRecords("", "-id", leaveAllocationDataFilter?.leave_type_id);
  }, [pageSizeNo]);

  const getRecords = async (search, sortBy, leaveType, org) => {
    let queryParam = `sort_by=${sortBy}&search=${search}`;
    if (leaveType !== undefined) {
      queryParam = queryParam + "&leave_type_id=" + leaveType;
    }
    if (org !== undefined) {
      queryParam = queryParam + "&org=" + org;
    }
    await LeaveService.getLeaveRules(queryParam).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      if (response?.status === 200) {
        setLeaveRuleList(response?.data?.dataList.result);
      }
    });
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="row">
          {/* <div class="col-md-3">
            <ul class="policylistnames">
              <li>
                <Link to="/manage-leave">Leave Master</Link>
              </li>
              <li class="active">
                <Link to="/leave-rule">Leave Rule</Link>
              </li>
              <li>
                <Link to="/allocation-leave">Leave Allocation</Link>
              </li>
              <li>
                <Link to="/my-leave">My Leaves</Link>
              </li>
              <li>
                <Link to="/bulk-leave">Leave Bulk Upload</Link>
              </li>
              <li>
                <Link to="/leave-approval">For Approval Leaves</Link>{" "}
              </li>
              <li>
                <Link to="/leave-backdate">Back Date Entry Exception</Link>
              </li>
            </ul>
          </div> */}
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Leave Rule</h4>
                    {/* <Breadcrumb>
                      <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                      <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                      <Breadcrumb.Item active>Leave Allocation</Breadcrumb.Item>
                    </Breadcrumb> */}
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on <strong> Leave Type</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="Leave Type"
                          onChange={(e) => handleSearch(e)}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <button
                        onClick={() => {
                          filterShow();
                        }}
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                      {/* {finalUrlValue?.A ? ( */}
                      <button
                        class="btn btn-primary-inner"
                        onClick={() => {
                          addNewBranch();
                        }}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add New
                      </button>
                      {/* ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured newSpace">
                  <thead>
                    <tr>
                      {/* {finalUrlValue?.E || finalUrlValue?.R ? ( */}
                      <th>Actions</th>
                      {/* ) : (
                        ""
                      )} */}
                      <th
                        onClick={(e) => {
                          handlerData("is_active");
                        }}
                        className={
                          sortData["is_active"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["is_active"]
                        }
                      >
                        Status
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("organisation_id");
                        }}
                        className={
                          sortData["organisation_id"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["organisation_id"]
                        }
                      >
                        Organisation
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("branch_id");
                        }}
                        className={
                          sortData["branch_id"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["branch_id"]
                        }
                      >
                        Business Unit
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("personal_sub_area_id");
                        }}
                        className={
                          sortData["personal_sub_area_id"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["personal_sub_area_id"]
                        }
                      >
                        Personal Sub Area
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("leave_type_id");
                        }}
                        className={
                          sortData["leave_type_id"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["leave_type_id"]
                        }
                      >
                        Leave Type
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("allocate_type");
                        }}
                        className={
                          sortData["allocate_type"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["allocate_type"]
                        }
                      >
                        Allocate Type
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("gender");
                        }}
                        className={
                          sortData["gender"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["gender"]
                        }
                      >
                        Gender
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("leave_days");
                        }}
                        className={
                          sortData["leave_days"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["leave_days"]
                        }
                      >
                        Leave Days
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("max_leave");
                        }}
                        className={
                          sortData["max_leave"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["max_leave"]
                        }
                      >
                        Max Leaves
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("year_max");
                        }}
                        className={
                          sortData["year_max"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["year_max"]
                        }
                      >
                        Max Leaves (Yearly)
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("joining_days");
                        }}
                        className={
                          sortData["joining_days"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["joining_days"]
                        }
                      >
                        Joining Days
                      </th>
                      <th>Notice Period</th>
                      <th
                        onClick={(e) => {
                          handlerData("approver");
                        }}
                        className={
                          sortData["approver"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["approver"]
                        }
                      >
                        Approver
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("updated_by_id");
                        }}
                        className={
                          sortData["updated_by_id"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["updated_by_id"]
                        }
                      >
                        Last Updated By
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("updated_at");
                        }}
                        className={
                          sortData["updated_at"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["updated_at"]
                        }
                      >
                        Last Updated On
                      </th>
                      {/* {Object?.keys(tableData).map((allData, i) => {
                        return <th> {allData}</th>;
                      })} */}
                    </tr>
                  </thead>
                  {leaveRuleList.map((items) => (
                    <tbody>
                      <tr>
                        {/* {finalUrlValue?.E || finalUrlValue?.R ? ( */}
                        <td>
                          {/* {finalUrlValue?.E ? ( */}
                          <button
                            onClick={() => editRecord(items)}
                            class="btn btn-blanktd text-primary"
                          >
                            <i class="far fa-edit"></i>{" "}
                          </button>
                          {/* ) : (
                              ""
                            )} */}
                        </td>
                        {/* ) : (
                          ""
                        )} */}
                        <td>
                          {items?.is_active ? (
                            <span className="badge bad-status badge-success">
                              Active
                            </span>
                          ) : (
                            <span className="badge bad-status badge-danger">
                              InActive
                            </span>
                          )}
                        </td>
                        <td>{`${items?.organization[0]?.name} (${items?.organization[0]?.code})`}</td>
                        <td>
                          {items?.branch?.length > 1
                            ? "ALL"
                            : `${items?.branch[0]?.name} (${items?.branch[0]?.code})`}
                        </td>
                        <td>
                          {items?.personalSubArea?.length > 1 ||
                            items?.personalSubArea?.length === 0 ||
                            items?.branch?.length > 1
                            ? "ALL"
                            : `${items?.personalSubArea[0]?.name}`}
                        </td>
                        <td>{items?.leave_type__name}</td>
                        <td>
                          {items?.allocate_type === "A"
                            ? "ALL"
                            : items?.allocate_type}
                        </td>
                        <td>
                          {items?.leave_gender === "M"
                            ? "MALE"
                            : items?.leave_gender === "F"
                              ? "Female"
                              : "ALL"}
                        </td>
                        <td>{items?.leave_allocated_days}</td>
                        <td>{items?.leave_max_in_month}</td>
                        <td>{items?.year_max}</td>
                        <td>{items?.joining_day}</td>
                        <td>{items?.notice_period ? "Yes" : "No"}</td>
                        <td>{items?.approver_name.join(", ")}</td>
                        <td>{items?.updated_by}</td>
                        <td>
                          {moment
                            .utc(items?.updated_at)
                            .format("DD-MM-YYYY, h:mm:ss a")}
                        </td>
                        {/* <td>
                          <span class="text-dark fw-bold">
                            {items?.username}
                          </span>
                        </td>
                        {Object?.keys(tableData).map((allData, i) => {
                          return <td> {tableData[allData]}</td>;
                        })} */}
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
              {leaveRuleList?.length == 0 ? (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              ) : (
                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                        class="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option class=" main-pointer" value={10} label={10} />
                        <option class=" main-pointer" value={20} label={20} />
                        <option class=" main-pointer" value={30} label={30} />
                        <option class="main-pointer" value={100} label={100} />
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Select Leave Type</label>
            <select
              className="form-control newbgselect main-pointer"
              name="leave_type_id"
              onChange={(e) => {
                setLeaveAllocationDataFilter({
                  ...leaveAllocationDataFilter,
                  leave_type_id: e.target.value,
                });
              }}
              value={leaveAllocationDataFilter?.leave_type_id}
            >
              <option value="" label="Select Leave Type" hidden>
                Select Leave Type
              </option>

              {renderOrgLeaveTypeOptions(allLeaveType)}
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Select Organisation</label>
            <Select
              options={allOrg}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setLeaveAllocationDataFilter((prev) => ({
                  ...prev,
                  organisation_id: e,
                }))
              }
              value={leaveAllocationDataFilter?.organisation_id}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showEditForm ? (
        <>
          <EditLeaveAllocation
            edit={editMode}
            showEditForm={showEditForm}
            userData={userData}
            leaveManageList={leaveManageList}
            onClose={onClose}
            editLeaveManage={leavemanage}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {showForm ? (
        <>
          <AddLeaveAllocation
            ruleList={leaveRuleList}
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editLeaveManage={leavemanage}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default LeaveAllocation;
