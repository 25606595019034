import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

/* dropdowns list */
export const getHsnCodes = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/cashbook_hsn?${queryParm}`);
};
export const getExpensesGl = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/cashbook_expense_gl?${queryParm}`
  );
};
export const getBeneficiaryData = async (data, id, paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/cashbook_beneficiary?cashbook_id=${data}&user_id=${id}&paginate=${paginate}`
  );
};
export const getVendorList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/cashbook_vendors?${queryParm}`);
};
export const getDemandList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/cashbook_add_cash?${queryParm}`);
};

export const getDocDetails = async (user_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/cashbook_beneficiary_info?user_id=${user_id}`
  );
};
export const getAllStateList = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/state?paginate=${paginate}`);
};
/* dropdowns list */

export const getCreData = async () => {
  return axios.get(`${AUTH_BASE_URL}/buss-transactions/master-gl-code/list`);
};
export const updateVendor = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/cashbook/cashbook_vendors/${id}`, data);
};

export const saveVendor = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/cashbook/cashbook_vendors`, data);
};
export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/cashbook/cashbook_vendors/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const getExportValueDemand = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/cashbook/cashbook_add_cash/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const deleteVendor = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/cashbook/cashbook_vendors/${id}`,
    method: "DELETE",
  });
};
export const getAllCashbookList = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/cashbook_vendors?paginate=${paginate}`
  );
};
/* eslinit-disable-next-line */
export const getCostCenterList = async () => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/cashbook_cost_center`);
};

export const getCashbookList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/cashbook`, { params: queryParm });
};

export const addCashbook = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/cashbook/cashbook`, data);
};

export const updateCashbook = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/cashbook/cashbook/${id}`, data);
};
export const saveDemand = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/cashbook/cashbook_add_cash`, data);
};
export const updateDemand = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/cashbook/cashbook_add_cash/${id}`, data);
};
//getMasterData

export const getMasterData = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/cashbook_master_data?user_id=${queryParm}`
  );
};

export const getAllCashbookMasterData = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/cashbook_master_data_all`
  );
};

export const getCashbookExpenseData = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/get_cashbook_expense_data?${queryParm}`
  );
};

export const getExportCashExpense = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/cashbook/cashbook_wise_expense_report?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getReports = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/cashbook_add_cash`, {
    params: queryParm,
  });
};

//Receipt service data
export const getReceiptData = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/cashbook_receipt?${queryParm}`);
};
export const getReceiptDataList = async () => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/cashbook_receipt_gl`);
};

export const getExpenceData = async () => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/cashbook_expense_gl`);
};

// export const getReceiptData = async () => {
//   return axios.get(`${AUTH_BASE_URL}/cashbook//cashbook_receipt_gl`);
// };
export const saveReceipt = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/cashbook/cashbook_receipt`, data);
};
export const updateReceipt = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/cashbook/cashbook_receipt/${id}`, data);
};
export const updateStatus = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/cashbook/changeStatus`, data);
};
export const getExportValueReceipt = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/cashbook/cashbook_receipt/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportValueExpenses = async (params) => {
  return axios({
    url: `${AUTH_BASE_URL}/cashbook/cashbook/export`,
    method: "GET",
    responseType: "blob",
    params,
  });
};
export const getExportValueCBReport = async (params) => {
  return axios({
    url: `${AUTH_BASE_URL}/cashbook/cashbook-report?${params}`,
    method: "GET",
    responseType: "blob",
  });
};

//Beneficiary Master
export const getBeneficiaryList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/cashbook_beneficiary?${queryParm}`
  );
};
export const getExportBeneficiary = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/cashbook/cashbook_beneficiary/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const getCashbookMasterDataList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/cashbook_master_data?user_id=${queryParm}`
  );
};
export const getUserList = async () => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/cashbook_users`);
};

export const updateBeneficiary = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/cashbook/cashbook_beneficiary/${id}`,
    data
  );
};

export const saveBeneficiary = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/cashbook/cashbook_beneficiary`, data);
};
export const getAllBeneficiaryData = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/cashbook_beneficiary?paginate=${paginate}`
  );
};
export const deleteBeneficiary = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/cashbook/cashbook_beneficiary/${id}`,
    method: "DELETE",
  });
};
export const handleCashlimitCheck = async (data) => {
  return axios.get(
    `${AUTH_BASE_URL}/cashbook/cashbook_user_limit?user_id=${data}`
  );
};

export const rePostExpense = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/cashbook/repost_cash_expense`, data);
};

export const get_cashbook_gst = async(cashbook_id) => {
  return axios.get(`${AUTH_BASE_URL}/cashbook/get_cashbook_gst?cashbook_id=${cashbook_id}`)
}