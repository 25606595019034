
import { useState,useEffect } from "react";
import styles from "./Root.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useDispatch } from "react-redux";
import * as TransportServices from "../../../../service/LRGRPortal/transportinvoiceservices";
import {getPlant} from "../../../../service/LRGRPortal/plantService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Dropdown } from "react-bootstrap";
import CustomPagination from "../../../CashbookProfile/CustomPagination";
import Select from "react-select";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { setDisplayLoader, selectLoader } from "../../../../pages/redux/piDataStore";


const Root = ()=>{
    const params = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const handleLoader = (show = true) =>{
      dispatch(setDisplayLoader( show ? "Display" : "Hide"));
    }

    const [transportDataForExport, setTransportDataForExport] = useState({
          plant_id: "",
          invoice_number: "",
          status: "",
          start_date:"",
          end_date:"",    
        });
        const multi_status = [];
        const mutli_plant =  [] ;
      const [transportDataFilter, setTransportDataFilter] = useState({
      plant_id: [],
      invoice_number: "",
      status: [],
      start_date:"",
      end_date:"",  
      });

    const statuslis = [
          {value: "1", label: "Pending"},
          { value: "2",label: "Approved"},
          {value: "3", label: "Rejected"},
          {value: "4",label: "Save as Draft"}]

    const statuslis_lm = [
          { value: "1", label: "Pending"},
          {value: "2",label: "Approved"},
          {value: "3",label: "Rejected"}]

    const [searchStr, setSearchStr] = useState("");
    const [plantcode, setPlantcode] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [pageCount, setpageCount] = useState(1);
    const [showAddNew, setShowAddNew] = useState(false)
    const [managerType, setManagerType] = useState();
    const [plantallcode, setPlantallcode] = useState([])
    const [statuslist, setStatuslist] = useState([])
    const [statustype,setStatustype] = useState("");
    const [invoiceReqListData, setInvoiceReqListData] = useState([]);
    const [checkedAll,setCheckedAll] = useState(false);
    // const [loader, setLoader] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const initSortData = { colName: "id", sort_by: "id" };
    const [sortData, setSortData] = useState(initSortData);

    
    const [show, filtersetShow] = useState(false);
    const [filterState, setFilterState] = useState({});
    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);

    const navigate = useNavigate();
    const getTransport = (
      search,
      plant_id,
      invoice_number,
      status,
      start_date,
      end_date,
      pageCount,
      pageSizeNo,
      sort_by
    ) => {
      handleLoader();
      TransportServices
        .getInvoiceReqList(
          handleKey(
            search,
            plant_id,
            invoice_number,
            status,
            start_date,
            end_date,
            pageCount,
            pageSizeNo,
            sort_by

          )
        )
        .then((response) => {
          
          if(response.data.items[0].mesage == "No data found" || response.data.items[0].message == "no data found" || response.data.items[0].message == "Invoice request details not found"){
              setInvoiceReqListData([]);
              if(response.data.manager=="LM"){
                setShowAddNew(true)
                setManagerType(response.data.manager)
              }else if(response.data.manager=="PM"){
                setShowAddNew(false)
                setManagerType(response.data.manager)
              }
          }
           else if(response.data.items[0].message == "You dont have access to view lr gr request"){
            setInvoiceReqListData([]);
            if(response.data.manager=="LM"){
              setShowAddNew(true)
              setManagerType(response.data.manager)
            }else if(response.data.manager=="PM"){
              setShowAddNew(false)
              setManagerType(response.data.manager)
            }
        }
          else{
              setInvoiceReqListData(response.data.items);
             
          if(response?.data?.total > 10){
              const itemcount = (response?.data?.total)/10
              setpageCount(Math.ceil(itemcount));
          }
          else{
              setpageCount(1)
            }
                
          if(response.data.manager=="LM" || response.manager == "LM"){
              setShowAddNew(true)
              setManagerType(response.data.manager)
            }else if(response.data.manager=="PM" || response.manager == "PM"){
              setShowAddNew(false)
              setManagerType(response.data.manager)
            }
           
           
          }
   
        })
        .catch((error) => {
      
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        }).finally(()=>{
          handleLoader(false);
        });
    }

 
    const handleKey = (
      search,
      plant_id,
      invoice_number,
      status,
      start_date,
      end_date,
      pageCount,
      pageSizeNo,
      sort_by,
     
    ) => {
      
      let queryParm =
        "page=" +
        pageCount +
        "&page_size=" +
        pageSizeNo +
        "&sort_by=" +
          sort_by ;

          if (invoice_number !== "" && invoice_number !== undefined && invoice_number !== null) {
            queryParm = queryParm + "&invoice_number=" + invoice_number;
            
    
          }
        if(status.length != 0){
                  status.map((elem,i)=>{
                    if(elem =="A"){
                      queryParm = queryParm + "&status=" + "A";
                    }
                   if(elem =="D"){
                      queryParm = queryParm + "&status=" + "D";
                    }
                    if(elem =="R"){
                      queryParm = queryParm + "&status=" + "R";
                    }
                    if(elem =="P"){
                      queryParm = queryParm + "&status=" + "P";
                    }
                  
                  })
                 
        }else{
          if (status !== "" && status !== undefined && status !== null) {
          //  queryParm = queryParm + "&status=" + status;
          }
        }
    
      if (search !== "" && search !== undefined && search !== null) {
        queryParm = queryParm + "&search=" + search;

      }
      if(plant_id.length != 0){
        plant_id.map((elem)=>{
            queryParm = queryParm + "&plant_id=" + elem.value;
        })
       
      }else{
        if (
          plant_id !== "" &&
          plant_id !== undefined &&
          plant_id !== null
        ) {

        }
      }
    
      if (
        end_date !== "" &&
        end_date !== undefined &&
        end_date !== null
      ) {
        queryParm = queryParm + "&end_date=" + end_date;
      }
      if (
        start_date !== "" &&
        start_date !== undefined &&
        start_date !== null
      ) {
        queryParm = queryParm + "&start_date=" + start_date;
      }
  
      return queryParm;
    }
    const handlePageCountChange = (count) => {
      // const query = { ...data.query, page_size: count };
      // getData(query);
     
      setPageSizeNo(count);
      setCurrentPage(1);
      setSortData({ colName: "id", sort_by: "-id" });
      getTransport(
        searchStr,
        transportDataFilter.plant_id,
        transportDataFilter.invoice_number,
        transportDataFilter.status,
        transportDataFilter.start_date,
        transportDataFilter.end_date,
        currentPage,
        count,
        sortData.sort_by
      );
    };
    const getAllPlant = () => {
      let data = [];
      handleLoader();
      getPlant()
        .then((response) => {
          response?.data?.dataList?.map((x) => {
   
            let plantData = {
              value: x.plant_id,
              label: `${x?.plant_name +"("+ x?.plant_code +")"}`,
            };
            data.push(plantData);
          });
 
          setPlantallcode(data);
     
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        }).finally(()=>{
          handleLoader(false);
        });
    };


   const getStatus = ()=>{
    if(showAddNew){
      setStatuslist(statuslis_lm)
    }else {
      setStatuslist(statuslis)
    }
     

    }
    const filterShow = () => {
      filteropen();
      getStatus();
      getAllPlant();
    };
  

  useEffect(() => {
    
    const params = new URLSearchParams(window.location.search);
   // setMode(params.get("start"));
   let query= "";

   if(params.get("start_date") !=null && params.get("end_date") !=null){
    getTransport(searchStr, "", "", "",params.get("start_date"),params.get("end_date"), 1, pageSizeNo, sortData.sort_by);
  
      }else{
        getTransport(searchStr, "", "", "","","", 1, pageSizeNo,sortData.sort_by);
       
      }

}, []);

  const handleviewclick = ()=>{

    alert("view page will be show soon enough")
  //  navigate("/trans/transport/table?mode=view&id=" + el.id + "&fy=" + el.cfy)
  }

  
  const mainDataFilter = () => {
    filterClose()
    if(transportDataFilter.invoice_number !=""){
     
      setTransportDataForExport({
        ...transportDataForExport,
         plant_id : "",
         invoice_number : transportDataFilter.invoice_number,
         status : "",
         start_date : "",
         end_date : "",
      });
      const plant_id = "";
      const invoice_number = transportDataFilter.invoice_number;
      const status = "";
      const start_date = "";
      const end_date ="";
      getTransport(
        searchStr,
        plant_id,
        invoice_number,
        status,
        start_date,
        end_date,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        
      );
    }else{
    
      setTransportDataForExport({
        ...transportDataForExport,
        plant_id: transportDataFilter.plant_id,
        invoice_number: transportDataFilter.invoice_number,
        status: transportDataFilter.status,
        start_date: transportDataFilter.start_date,
        end_date: transportDataFilter.end_date,
      });
      const plant_id = transportDataFilter.plant_id;
      const invoice_number = transportDataFilter.invoice_number;
      const status = transportDataFilter.status;
      const start_date = transportDataFilter.start_date;
      const end_date = transportDataFilter.end_date;
      getTransport(
        searchStr,
        plant_id,
        invoice_number,
        status,
        start_date,
        end_date,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        
      );
    }
 
    filterClose();
  };


  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
  
      getTransport(
        e.target.value.toLowerCase(),
        transportDataFilter.plant_id,
        transportDataFilter.invoice_number,
        transportDataFilter.status,
        transportDataFilter.start_date,
        transportDataFilter.end_date,
        currentPage,
        pageSizeNo,
        'id',
        
      );
      setSortData(initSortData);
      setSearchStr(e.target.value);
    }
  }

  
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");

    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getTransport(
        data,
        transportDataFilter.plant_id,
        transportDataFilter.invoice_number,
        transportDataFilter.status,
        transportDataFilter.start_date,
        transportDataFilter.end_date,
        1,
        pageSizeNo,
        sortData.sort_by

        
      );
    }
  };

  
      
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getTransport(
      searchStr,
      transportDataFilter.plant_id,
      transportDataFilter.invoice_number,
      transportDataFilter.status,
      transportDataFilter.start_date,
      transportDataFilter.end_date,
      activePage,
      pageSizeNo,
      sortData.sort_by
    );
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
   
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    setCurrentPage(1);
    getTransport(
      searchStr,
      transportDataFilter.plant_id,
      transportDataFilter.invoice_number,
      transportDataFilter.status,
      transportDataFilter.start_date,
      transportDataFilter.end_date,
      1,
      pageSizeNo,
      sdata.sort_by,
      
    );
  };

  // get export data excel
  
  const getExportDetails = (
    search,
    plant_id,
    invoice_number,
    status,
    start_date,
    end_date,
    pageCount,
    page_size,
   
  ) => {
    handleLoader();
    TransportServices
      .getExportValue(
        handleKey(
          search,
          plant_id,
          invoice_number,
          status,
          start_date,
          end_date,
          pageCount,
          page_size,
      
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "InvoiceList.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      }).finally(()=>{
        handleLoader(false);
      });
  };

  const handleClearFilter = () => {
    setFilterState({});
    // getData({
    //   search: data.query.search,
    //   page_size: data.query.page_size,
    //   page_no: 1,
    //   paginate: true,
    // });
    filterclose();
  };

  const filterClose = () => {
    filterclose();
  };
  
            const clearFilter = () => {
              let ClearData = JSON.parse(JSON.stringify(transportDataFilter));
            
              Object.entries(ClearData).map((type) => {
               
                if (
                  type[1].constructor.name.toLowerCase() === "string" ||
                  type[1].constructor.name.toLowerCase() === "array"
                ) {
                 
                  if (type[1].length > 0) {
                    ClearData[type[0]] = "";
                  }
                } else {
              
                if (type[1].length > 0) {
                    ClearData[type[0]] = "";
                   }
                }
              });
              setTransportDataFilter({
                plant_id: "",
                invoice_number: "",
                status: "",
                start_date:"",
                end_date:"", 
              });
              setStatuslist([]);
              setPlantallcode([]);
              setPlantcode("");
              setStatustype("");
              getTransport(searchStr, "", "", "","","", 1, pageSizeNo, sortData.sort_by);
              filterClose();
            };

  
    const toggleItemSelection = (itemId) => {
      if (selectedItems.includes(itemId)) {
        setSelectedItems(selectedItems.filter(id => id !== itemId));
        setSelectAll(false); // Uncheck select all if any item is unchecked
      } else {
        setSelectedItems([...selectedItems, itemId]);
      }
    };
  
    const toggleSelectAll = () => {
      if (selectAll) {
        setSelectedItems([]);
      } else {
        const allItemIds = invoiceReqListData.map(item => item.approval_id);
        console.log(allItemIds);
        setSelectedItems(allItemIds);
      }
      setSelectAll(!selectAll);
    };
    const multiapprove = () => {
      console.log(selectedItems);
      confirmAlert({
        title: "Multiple Approval",
        message: `Are you sure to bulk approve?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => approveMultipleLR(selectedItems, 'approve'),
          },
          {
            label: "No",
          },
        ],
      });
    };
    const approveMultipleLR = (idx, status) => {

      let data = {
        approval_id: idx,        
        status: `${status === "approve" ? "A" : "R"}`        
      };    

       handleLoader();
       TransportServices.
       postMassApproverAction(data)
         .then((res) => {
          
           toast.success(
             `${status === "approve" ? "Approved" : "Rejected"} succesfull!`
           );
           setTimeout(() => {
            getTransport(searchStr, "", "", "","","", 1, pageSizeNo,sortData.sort_by);
             navigate("/transport");
           }, 2000);
         })
         .catch((err) => {
           toast.error(`${status === "approve" ? "Approved" : "Rejected"} failed!`);
           console.log(err);
         }).finally(()=>{
           handleLoader(false);
         });
      
   
 };
    return (
        <>
        <div class="row">
       <div class="col-md-12">
       <div class="content-wrapper-inner content-wrapper-inner2">
       
        <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Invoice Transport</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>My PR List</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Plant name,
                            <br />
                            code
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                     id="searchbar"
                      type="text"
                      class="form-control"
                      placeholder="Search on Plant name, Plant code..."
                      onChange={(e) => handleSearch(e)}
                    />
                    <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                  </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                  { false &&
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img
                            src="images/export.png"
                            alt=""
                            className="mr-2"
                          />
                          Export
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                             // disabled={userList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                               onClick={() =>
                                getExportDetails(
                                      searchStr,
                                      transportDataFilter.plant_id,
                                      transportDataFilter.invoice_number,
                                      transportDataFilter.status,
                                      transportDataFilter.start_date,
                                      transportDataFilter.end_date,
                                      currentPage,
                                      pageSizeNo,
                                      
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                   }
                  {showAddNew && (
                    <button
                    className="btn btn-success"
                    onClick={multiapprove}
                    disabled={selectedItems.length === 0}
                  >
                    Mass Approve
                  </button>
                  )
                    
                   }
                   
                    <button
                       onClick={() => {
                        filterShow();
                      }}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {!showAddNew && (
                    <div>
                    <Link to="/transport/table?mode=create" class="btn btn-primary-inner" >
                    <img src="images/upload.png" alt="" class="mr-3" />
                    Add New
                    </Link>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
        <div class="table-responsive">
        <table class="table table-striped table-bordered tablecured tablcuredlastnew custom-approval-table">
        <thead>
            <tr>
            {showAddNew && (
               <th>
               <input
              type="checkbox"
              checked={selectAll && selectedItems?.length === invoiceReqListData?.length}
              onChange={toggleSelectAll}
            /> Select All
              </th>)}
                <th
                      // width="250"
                      onClick={(e) => {
                        handlerData("plant_id__plant_name");
                      }}
                      className={
                        sortData["plant_id__plant_name"] === undefined
                          ? "unsortcls"
                          : sortData["plant_id__plant_name"]
                      }
                    >
                      Plant Name
                    </th>
                {/* <th>Plant Name</th> */}
               
                <th  onClick={(e) => {
                        handlerData("created_by__username");
                      }}
                      className={
                        sortData["created_by__username"] === undefined
                          ? "unsortcls"
                          : sortData["created_by__username"]
                      }>Submitted By</th>
                {/* <th>Submitted On</th> */}
                <th
                      // width="250"
                      onClick={(e) => {
                        handlerData("created_at");
                      }}
                      className={
                        sortData["created_at"] === undefined
                          ? "unsortcls"
                          : sortData["created_at"]
                      }
                    >
                      Submitted On
                    </th>
                {/* <th>Date Of<br></br> Invoice</th> */}
                <th
                      // width="250"
                      onClick={(e) => {
                        handlerData("date_of_invoice");
                      }}
                      className={
                        sortData["date_of_invoice"] === undefined
                          ? "unsortcls"
                          : sortData["date_of_invoice"]
                      }
                    >
                     Date Of Invoice
                    </th>
                <th>Remark</th>
                <th>Status</th>
                <th>Action</th>
                {/* <th>Split Invoice</th> */}
                
            </tr>
            </thead>
            <tbody>
            
                {invoiceReqListData?.length > 0  ?
                 (invoiceReqListData !=null && invoiceReqListData !=undefined && 
                         (invoiceReqListData.map((el, i)=>{

    return <tr style={{ backgroundColor: el.current_approver =="True" ? "#92ff40" : "", fontWeight: el.for_my_approval ? "bold" : ""}}>
                       {showAddNew && (
                       <td>
                        {el.current_approver !=undefined && el.current_approver=="True" ? 
                        <input
                       type="checkbox"
                       checked={selectedItems.includes(el.approval_id)}
                       onChange={() => toggleItemSelection(el.approval_id)}
                     /> : ""}                      
                        </td>)}
                       <td>{el.plant_name +"("+ el.plant_code +")"}</td>
                      
                       <td>{el.created_by__username}</td>
                       <td>
                        {
                          el.created_at !=undefined ?
                        (moment(el.created_at)).format("DD-MM-YYYY H:mm:ss")
                        : ("")
                        }
                       </td>
                       <td>{moment(el.date_of_invoice).format("DD-MM-YY")}</td>
                       
                       <td>{el.remarks !=""? el.remarks : "-"}</td>
                         {/* { managerType == "PM" ? ( el.status == "D"?(<td>{"Saved"}</td>)
                       :el.status=="R"?(<td>{"Rejected"}</td>)
                       : el.status=="A"?(<td>{"Approved"}</td>)
                        : el.status=="P"?(<td>{`Pending with ${el.pending_at}`}</td>)
                       : el.status=="RP"?(<td>{"Auto Rejected"}</td>)
                       :el.status =="None"?(<td>{"Pending"}</td>)
                       :"") : managerType=="LM" ? (el.request_id_status == "A"? (<td>{"Approved"}</td>)
                       : el.status=="R"?(<td>{"Rejected"}</td>)
                       : el.status=="RP"?(<td>{"Auto Rejected"}</td>):""):"" } */}

                       { el.status == "D"?(<td>{"Saved"}</td>)
                       :el.status=="R"?(<td>{"Rejected"}</td>)
                       : el.status=="A"?(<td>{"Approved"}</td>)
                        : el.status=="P"?(<td>{`Pending with ${el.pending_at}`}</td>)
                       : el.status=="RP"?(<td>{"Auto Rejected"}</td>)
                       :el.status =="None"?(<td>{"Pending"}</td>)
                       :el.status == "AA"?(<td>{"Auto Approved"}</td>)
                       :el.status == null ?(<td>{"Pending"}</td>)
                       :""}

                      {/* {managerType == "PM" ? 
                       ((el.status == "D" || (el.status == "RP")||
                              (el.status == "R")) ?
                               (
                                <td
                                  style={{ cursor: "pointer", fontWeight: "bold" }}
                                  onClick={(e) =>
                                    navigate(
                                        `/transport/table?mode=edit&plantID=${el.plant_id}&date=${el.date_of_invoice}&requestID=${el.request_id}`
                                    )
                                  }
                                >
                                  Edit
                                </td>
                              ):((el.status == "P" || el.status == "A" ))?( <td
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                onClick={(e) =>
                                  navigate(
                                    `/transport/table?mode=view&plantID=${el.plant_id}&date=${el.date_of_invoice}&requestID=${el.request_id}`
                                  ) 
                                }
                              >
                                View
                              </td>  ):"")
                             : managerType == "LM" ? (
                              (el.current_approver !=undefined && el.current_approver=="True") ?(
                                <td
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                onClick={(e) =>
                                  navigate(
                                    `/transport/table?mode=approve&plantID=${el.plant_id}&plantName=${el.plant_name}&plantCode=${el.plant_code}&approvalID=${el.approval_id}&date=${el.date_of_invoice}&requestID=${el.request_id}&c_a=${el.current_approver}`
                                  )
                                }
                              >
                                View
                              </td>
                              )
                              : el.current_approver !=undefined && el.current_approver=="False" ? (
                                <td
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                onClick={(e) =>
                                  navigate(
                                    `/transport/table?mode=view&plantID=${el.plant_id}&date=${el.date_of_invoice}&requestID=${el.request_id}`
                                    )
                                }
                              >
                                View
                              </td>
                               ) : "" 
                             ):""} */}
                           
                       {(el.status == "D" || (el.status == "RP")||
                              (el.status == "R")) && (managerType == "PM" ) &&
                               (
                                <td
                                  style={{ cursor: "pointer", fontWeight: "bold" }}
                                  onClick={(e) =>
                                    navigate(
                                        `/transport/table?mode=edit&plantID=${el.plant_id}&date=${el.date_of_invoice}&requestID=${el.request_id}`
                                    )
                                  }
                                >
                                  Edit
                                </td>
                              )}
                            {((el.status == "P" || el.status == "A" || el.status == "AA") && (managerType == "PM" ) ) ? (
                              <td
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                onClick={(e) =>
                                  navigate(
                                    `/transport/table?mode=view&plantID=${el.plant_id}&date=${el.date_of_invoice}&requestID=${el.request_id}`
                                  ) 
                                }
                              >
                                View3
                              </td>   
                         ): el.current_approver !=undefined && el.current_approver=="True" ? (
                          <td
                          style={{ cursor: "pointer", fontWeight: "bold" }}
                          onClick={(e) =>
                            navigate(
                              `/transport/table?mode=approve&plantID=${el.plant_id}&plantName=${el.plant_name}&plantCode=${el.plant_code}&approvalID=${el.approval_id}&date=${el.date_of_invoice}&requestID=${el.request_id}&c_a=${el.current_approver}`
                            )
                          }
                        >
                          View
                        </td>
                         ) : el.current_approver !=undefined && el.current_approver=="False" ? (
                          <td
                          style={{ cursor: "pointer", fontWeight: "bold" }}
                          onClick={(e) =>
                            navigate(
                              `/transport/table?mode=view&plantID=${el.plant_id}&date=${el.date_of_invoice}&requestID=${el.request_id}`
                              )
                          }
                        >
                          View
                        </td>
                         ) : "" 
                         }


                       </tr>
                         })         
                    
    
    ) ):( <tr>
      <td colSpan={7}> No Record Found</td>
    </tr>)}
     
          

                                    
                                
                            </tbody>
        
        </table>
        </div>
        {invoiceReqListData?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  style={{ textAlign: "center" }}
                  // onChange={(e) => {
                  //   setPageSizeNo(e.target.value);
                  //   // setCurrentPage(1);
                  // }}
                  onChange={(e) => handlePageCountChange(e.target.value)}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}

        </div>
        </div>
       
       </div>

        <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="form-group innergroup">
            <label>
              Invoice No<span class="text-danger"></span>
            </label>
            <input
            type="text"
            class="form-control"
            placeholder="Enter Invoice No"
            value={transportDataFilter.invoice_number}
            onChange={(e) =>
                setTransportDataFilter({
                  ...transportDataFilter,
                  invoice_number: e.target.value,
                })
              
            }
            />
          </div>
          <div class="form-group innergroup"  style={{ justifyContent:"center", textAlign:"center", color:"black", fontWeight: "bold"}}>
            <label style={{color:"black", fontWeight: "bold", fontSize:"20px"}}>
              OR 
            </label>
         
          </div>
          <div class="form-group innergroup">
                <label>Start Date (Date of Invoice)</label>
                <input
                  type="date"
                  class="form-control"
                  name="start_date"
                  onChange={(e) =>
                    setTransportDataFilter({
                      ...transportDataFilter,
                      start_date: e.target.value,
                    })
                  }
                  value={transportDataFilter.start_date}
                />
              </div>
              <div class="form-group innergroup">
                <label>End Date (Date of Invoice)</label>
                <input
                  type="date"
                  class="form-control"
                  name="end_date"
                  onChange={(e) =>
                    setTransportDataFilter({
                      ...transportDataFilter,
                      end_date: e.target.value,
                    })
                  }
                  value={transportDataFilter.end_date}
                />
              </div>
              { !showAddNew && (
                <div className="form-group innergroup">    
                <label>
                    Select Plant <span className="text-danger"></span>
                  </label>
                  <Select
                    name="business_unit_id"
                    isMulti
                     options={plantallcode}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {

                      e?.map((data) => {
                        mutli_plant.push(data)
                        // setTransportDataFilter({
                        //   ...transportDataFilter,
                        //   plant_id: data.value,
                        // });
                      })
                          setTransportDataFilter({
                          ...transportDataFilter,
                          plant_id: mutli_plant,
                        });
                       setPlantcode(e);
                       setCurrentPage(1);
                    }}
                    value={plantcode}
                  />
                </div>
             )}
          
                <div className="form-group innergroup">
                  <label>
                    Select Status <span className="text-danger"></span>
                  </label>
                  <Select
                    name="status_type"
                    isMulti
                    options={statuslist}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      e?.map((data) => {
                        if(data.label=="Approved"){
                          multi_status.push("A")
                          // setTransportDataFilter({
                          //   ...transportDataFilter,
                          //   status: "A",})
                            
                        }
                        if(data.label=="Pending"){
                          multi_status.push("P")
                          // setTransportDataFilter({
                          //   ...transportDataFilter,
                          //   status: "P",})
                        }
                        if(data.label=="Save as Draft"){
                          multi_status.push("D")
                          // setTransportDataFilter({
                          //   ...transportDataFilter,
                          //   status: "D",})
                        }
                        if(data.label=="Rejected"){
                          multi_status.push("R")
                          // setTransportDataFilter({
                          //   ...transportDataFilter,
                          //   status: "R",})
                        }
                       
                      })
                          setTransportDataFilter({
                            ...transportDataFilter,
                            status: multi_status})
                      setStatustype(e)
                      setCurrentPage(1)
                    }}
                    value={statustype}
                  />
                </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      
      
        </>
    )
    
}



export default Root;
