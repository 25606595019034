import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Modal, Dropdown } from "react-bootstrap";
import * as searchPoService from "../../service/searchpo";
import ReactPaginate from "react-paginate";
import ViewPoSearch from "./viewPoSearch";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { injectStyle } from "react-toastify/dist/inject-style";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as SkillUserService from "../../service/skillUser";
import { Tooltip } from "react-tippy";
if (typeof window !== "undefined") {
  injectStyle();
}

function POApprovalReport() {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const [pageCount, setpageCount] = useState(1);
  const [searchPoData, setSearchPoData] = useState([]);
  const [perPageData, setPerPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [viewForm, setViewForm] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [dataView, setDataView] = useState([]);
  const [condition, setCondition] = useState([]);
  const [allEmployee, setAllEmployee] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    fromDate,
    toDate,
    requestedBy,
    category_type
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate +
      "&category_type=" + category_type;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (fromDate !== "" && fromDate !== undefined && fromDate !== null) {
      queryParm = queryParm + "&from_date=" + fromDate;
    }
    if (toDate !== "" && toDate !== undefined && toDate !== null) {
      queryParm = queryParm + "&to_date=" + toDate;
    }
    if (
      requestedBy !== "" &&
      requestedBy !== undefined &&
      requestedBy !== null &&
      requestedBy.length !== 0
    ) {
      queryParm = queryParm + "&requested_by=" + requestedBy;
    }
    return queryParm;
  };

  const getApprovedPo = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    fromDate,
    toDate,
    requestedBy,
    category_type
  ) => {
    dispatch(setDisplayLoader("Display"));

    searchPoService
      .getApprovedPo(
        handleKey(
          search,
          page_no,
          page_size,
          sort_by,
          paginate,
          status,
          fromDate,
          toDate,
          requestedBy,
          category_type
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setSearchPoData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setPerPageData(response?.data?.dataList?.paginated_data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getEmployee = (paginate) => {
    SkillUserService.getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getViewOrder = (id) => {
    searchPoService
      .getViewOrder(id)
      .then((response) => {
        setDataView(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getApprovedPo(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        "-id",
        true,
        dataFilter?.status,
        dataFilter?.from_date,
        dataFilter?.to_date,
        dataFilter?.requested_by,
        dataFilter?.category_type
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getApprovedPo(
        data,
        1,
        pageSizeNo,
        "-id",
        true,
        dataFilter?.status,
        dataFilter?.from_date,
        dataFilter?.to_date,
        dataFilter?.requested_by,
        dataFilter?.category_type
      );
    }
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getApprovedPo(
      searchStr,
      activePage,
      pageSizeNo,
      "-id",
      true,
      dataFilter?.status,
      dataFilter?.from_date,
      dataFilter?.to_date,
      dataFilter?.requested_by,
      dataFilter?.category_type
    );
  };

  const handleKeyExport = (
    search,
    sort_by,
    paginate,
    category,
    status,
    fromDate,
    toDate,
    requestedBy
  ) => {
    let queryParm =
      "sort_by=" + sort_by + "&paginate=" + paginate + "&category_type=PO";

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (fromDate !== "" && fromDate !== undefined && fromDate !== null) {
      queryParm = queryParm + "&from_date=" + fromDate;
    }
    if (toDate !== "" && toDate !== undefined && toDate !== null) {
      queryParm = queryParm + "&to_date=" + toDate;
    }
    if (
      requestedBy !== "" &&
      requestedBy !== undefined &&
      requestedBy !== null &&
      requestedBy.length !== 0
    ) {
      queryParm = queryParm + "&requested_by=" + requestedBy;
    }
    return queryParm;
  };
  const getExportDetails = (
    search,
    sort_by,
    paginate,
    category,
    status,
    fromDate,
    toDate,
    requestedBy
  ) => {
    searchPoService
      .getExportValue(
        handleKeyExport(
          search,
          sort_by,
          paginate,
          category,
          status,
          fromDate,
          toDate,
          requestedBy
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "searchPo.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const openViewForm = (data) => {
    setViewForm(true);
    getViewOrder(data?.id);
    setCondition(data);
  };

  const closeViewForm = (data) => {
    setViewForm(false);
  };

  const onCallBack = () => {
    setViewForm(false);
    getApprovedPo(
      "",
      1,
      pageSizeNo,
      "-id",
      true,
      dataFilter?.status,
      dataFilter?.from_date,
      dataFilter?.to_date,
      dataFilter?.requested_by,
      dataFilter?.category_type
    );
  };

  const sendEmail = (email, type, user_name, po_no, po_id) => {
    searchPoService
      .sendEmail(email, type, user_name, po_no, po_id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getSyncData = () => {
    searchPoService
      .getSyncData()
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          toast.success("PO data synced successfully");
          getApprovedPo("", 1, pageSizeNo, "-id", true, "", "", "", "", "");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getApprovedPo(
      "",
      1,
      pageSizeNo,
      "-id",
      true,
      dataFilter?.status,
      dataFilter?.from_date,
      dataFilter?.to_date,
      dataFilter?.requested_by,
      dataFilter?.category_type
    );
    getEmployee(false);
  }, [pageSizeNo]);

  const changeData = (num) => {
    const result = Number(num).toFixed(2);
    return result;
  };
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const filterShow = () => {
    filteropen();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(dataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDataFilter({ status: "", from_date: "", to_date: "", requested_by: "", category_type: "" });
    setEmployeeData([]);
    getApprovedPo("", 1, pageSizeNo, "-id", true, "", "", "", "", "");
    filterclose();
  };
  const [dataFilter, setDataFilter] = useState({
    status: "",
    from_date: "",
    to_date: "",
    requested_by: "",
    category_type: "",
  });
  const mainDataFilter = () => {
    setFilterApplied(true);
    const status = dataFilter?.status;
    const fromDate = dataFilter?.from_date;
    const toDate = dataFilter?.to_date;
    const requestedBy = dataFilter?.requested_by;
    const category_type = dataFilter?.category_type;
    getApprovedPo(
      searchStr,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      status,
      fromDate,
      toDate,
      requestedBy,
      category_type
    );
    filterclose();
  };

  return (
    <>

      <>
        <div className="row">
          <ToastContainer autoClose={1000} />

          <div className="col-md-12">
            <div className="content-wrapper-inner content-wrapper-inner2">
              <div className="innerheadsec">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="inner-page-title">
                      PO/CONTRACT Approval Data
                    </h4>
                  </div>
                  <div className="col-md-12">
                    <div className="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on <strong>PO Number</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div className="input-group table-search">
                        <span className="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>

                        <input
                          type="text"
                          id="searchbar"
                          className="form-control"
                          placeholder="Search PO Number..."
                          onChange={(e) => {
                            handleSearch(e);
                            setCurrentPage(1);
                          }}
                        />

                        <span className="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              className="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className="col-lg-8">
                    <div className="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img
                            src="images/export.png"
                            alt=""
                            className="mr-2"
                          />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              disabled={
                                searchPoData.length !== 0 ? false : true
                              }
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,
                                  "-id",
                                  true,
                                  "PO",
                                  dataFilter?.status,
                                  dataFilter?.from_date,
                                  dataFilter?.to_date,
                                  dataFilter?.requested_by
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* <button
                          onClick={() => getSyncData()}
                          className="btn btn-primary-inner"
                        >
                          <i className="fas fa-sync-alt mr-2 f-14"></i>PO Sync
                        </button> */}
                      <button
                        onClick={() => {
                          filterShow();
                        }}
                        className="btn btn-secondary-inner"
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                      >
                        <img
                          src="images/filter.png"
                          alt=""
                          className="mr-3"
                        />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>PO NO</th>
                      <th>Creation Date</th>
                      {/* <th>Vendor Name</th> */}
                      <th>Total Price</th>
                      {/* <th>Currency</th> */}
                      <th>Created by</th>
                      {/* <th>Approved By</th> */}
                      {/* <th>Pending With</th> */}
                      {/* <th>Declined From</th> */}
                      <th>Status</th>
                      <th>Sap Log</th>
                      <th>Sap Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchPoData?.length ? (
                      searchPoData?.map((data, index) => (
                        <>
                          <tr key={`po${index}`}>
                            <td>{data?.category}</td>
                            <td
                              // className="text-theme fw-bold"
                              onClick={() => {
                                openViewForm(data);
                              }}
                            >
                              {data?.po_num}
                            </td>
                            <td>
                              {" "}
                              {moment(data?.created_at).format("DD-MM-YYYY")}
                            </td>
                            {/* <td> {data?.vendor_name}</td> */}
                            <td> {changeData(data?.po_totalprice)}</td>
                            {/* <td> {data?.currency_code}</td> */}
                            <td> {data?.created_by_user}</td>

                            {/* <td>
                                <span className="badge badge-secondary">
                                  {data?.approved_by}
                                </span>
                              </td>
                              <td>
                                {" "}
                                {data?.status === "PENDING" && data?.pending}
                              </td>
                              <td>{data?.declined}</td> */}
                            {data?.status === true ?
                              <td>
                                <span class="badge bad-status badge-success">
                                  Approved
                                </span>
                              </td>
                              : data?.status === false ?
                                <td>
                                  <span class="badge bad-status badge-danger">
                                    Rejected
                                  </span>
                                </td>
                                :
                                <td>
                                  <span class="badge bad-status badge-warning">
                                    Pending
                                  </span>
                                </td>
                            }

                            <td>
                              <Tooltip title={data?.log} position="bottom">
                                {data?.log?.substr(0, 12)}...
                              </Tooltip>
                            </td>
                            <td>
                              {data?.sap_status ? "Synced" : "Not Synced"}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {searchPoData?.length == 0 ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-sm-5">
                    <div className="sortinglist">
                      Show
                      <select
                        className="form-control main-pointer"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
            <Modal
              show={show}
              onHide={filterclose}
              className="filter-popup modaldefaultclose"
            >
              <Modal.Header closeButton>
                <Modal.Title>Filter</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group innergroup ">
                  <label>Requestor</label>
                  <Select
                    name="requested_by"
                    options={allEmployee}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(newValue) => {
                      setEmployeeData(newValue);
                      setDataFilter({
                        ...dataFilter,
                        requested_by: newValue.value,
                      });
                    }}
                    value={employeeData}
                  />
                </div>
                <div className="form-group innergroup">
                  <label>From Date</label>
                  <input
                    type="date"
                    name="from_date"
                    className="form-control"
                    onChange={(e) => {
                      setDataFilter({
                        ...dataFilter,
                        from_date: e.target.value,
                      });
                    }}
                    value={dataFilter?.from_date}
                  />
                </div>
                <div className="form-group innergroup">
                  <label>To Date</label>
                  <input
                    type="date"
                    name="to_date"
                    className="form-control"
                    onChange={(e) => {
                      setDataFilter({
                        ...dataFilter,
                        to_date: e.target.value,
                      });
                    }}
                    value={dataFilter?.to_date}
                  />
                </div>
                <div className="form-group innergroup">
                  <label>
                    Select Status <span className="text-danger"></span>
                  </label>
                  <select
                    className="form-control newbgselect main-pointer"
                    name="status"
                    onChange={(e) => {
                      setDataFilter({
                        ...dataFilter,
                        status: e.target.value,
                      });
                    }}
                    value={dataFilter?.status}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="APPROVED" label="Approved" />
                    <option value="DECLINED" label="Declined" />
                    <option value="PENDING" label="Pending" />
                  </select>
                </div>
                <div className="form-group innergroup">
                  <label>
                    Category <span className="text-danger"></span>
                  </label>
                  <select
                    className="form-control newbgselect main-pointer"
                    name="status"
                    onChange={(e) => {
                      setDataFilter({
                        ...dataFilter,
                        category_type: e.target.value,
                      });
                    }}
                    value={dataFilter?.category_type}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="PO" label="PO" />
                    <option value="CONTRACT" label="CONTRACT" />
                  </select>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => {
                    clearFilter();
                    setCurrentPage(1);
                  }}
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-inner bpi-main"
                  onClick={() => mainDataFilter()}
                >
                  Apply
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </>

    </>
  );
}

export default POApprovalReport;
