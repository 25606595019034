import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const saveNps = (data) => {
  return axios.post(`${AUTH_BASE_URL}/nps`, data);
};

export const sumbitNpsGrid = (data) => {
  return axios.post(`${AUTH_BASE_URL}/nps/approve_decline_nps`, data);
};

export const sendToSAP = (data) => {
  return axios.post(`${AUTH_BASE_URL}/nps/send_to_sap`, data);
};

export const getNpsData = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/nps/approval_nps?${queryParm}`);
};

export const getUserNps = (data) => {
  return axios.get(`${AUTH_BASE_URL}/users/get-user-data?user_id=${data}`);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/nps/approval_nps/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getAllEmployeIdName = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/users?paginate=${paginate}`);
};

export const uploadUserGuide = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/nps/user_guide`, data);
};
