import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../../audit/auditlog";
import { selectAudit } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import * as EmpService from "../../../service/employee";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  name: Yup.string()
    .required("*Employee Name is required")
    // .matches(/^[A-Za-z ]*$/, "Insert only character")
    .nullable(),
  code: Yup.string()
    // .required("*Employee Code is required")
    .matches(
      /^([a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'])+$/u,
      "Insert only AlphaNumeric Value"
    ),
  // email: Yup.string().email().required("*Email is required"),
  // country_code: Yup.string().required("Required"),
  mobile: Yup.string()
    // .required("*Mobile Number is required")
    .matches(phoneRegExp, "*Mobile Number is not Valid")
    .min(10, "*Mobile Number is too short")
    .max(10, "*Mobile Number is too long"),
  telephone_ext: Yup.string()
    // .required("*Telephone Ext is required")
    .min(2, "*Telephone Ext is too short")
    .max(5, "*Telephone Ext is too long"),
  office_address: Yup.string()
    .required("*Office Address is required")
    .nullable(),
  geo_location: Yup.string().required("*Geo Location is required").nullable(),
  // .test(
  //   "Is positive?",
  //   "Telephone Ext must be greater than 0!",
  //   (value) => value > 0
  // ),
  // designation_id: Yup.string().required("*Designation is required"),
  // department_id: Yup.string().required("*Department is required"),
  // branch_id: Yup.string().required("*Branch is required"),
  // sequence: Yup.string().max(20, "*Sequence is too long"),
  // sub_personal_area_id: Yup.number().required("*Sub Personal area is required")
  // .nullable(),
});

const AddEmployee = (props) => {
  const nameForm = "Employee Master";
  const { edit, showForm, onClose, editEmployee, onAddCallBack } = props;
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [dropDept, setDropDept] = useState([]);
  const [dropDesig, setDropDesig] = useState([]);
  const [dropBranch, setDropBranch] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [subPersonalArea, setSubPersonalAreaData] = useState([]);
  const [isDeptNameExist, setIsDeptNameExist] = useState(null);
  const [isDirCodeExist, setIsDirCodeExist] = useState(null);
  const [isDirEmailExist, setIsDirEmailExist] = useState(null);
  const [isSequenceExist, setIsSequenceExist] = useState(null);
  const [empData1, setEmpData1] = useState([]);
  const [empDesg1, setempDesg1] = useState([]);
  const [empSubPersonal, setempSubPersonal] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);
  const [empsubpersonalid, setempsubpersonalid] = useState("");
  const [empid, setempid] = useState(null);
  const [empdesgid, setempdesgid] = useState(null);

  const [empData, setEmpData] = useState({
    code: "",
    name: "",
    email: "",
    country_code: "",
    mobile: "",
    telephone_ext: "",
    sequence: 1,
    masking_mobile: true,
    masking_email: true,
    designation_id: 0,
    department_id: 0,
    branch_id: "",
    is_active: true,
    office_address: "",
    geo_location: "",
    sub_personal_area_id: null,
  });

  const saveEmployee = (values) => {
    // console.log("Valuessss",values)
    if (empsubpersonalid === "") {
      setErrorFlag(true);
    } else {
      let data = values;
      data.department_id = empid;
      data.designation_id = empdesgid;
      data.sub_personal_area_id = empsubpersonalid;
      setIsLoading(true);
      EmpService.saveEmployee(data)
        .then((response) => {
          if (response.data.status == true && response.data.code == 200) {
            onAddCallBack(response);
            setIsLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const updateEmployee = (values) => {
    // console.log("Updatevalues",values)
    // console.log("EMp",empid)
    let data = values;
    data.department_id = empid;
    data.designation_id = empdesgid;
    setIsLoading(true);
    EmpService.updateEmployee(values, values.id)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllDepartment = (paginate) => {
    EmpService.getAllDepartment(paginate)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropDept(obj);
      })

      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getAllDesignation = (paginate) => {
    EmpService.getAllDesignation(paginate)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropDesig(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllBranch = () => {
    EmpService.getAllBranch()
      .then((response) => {
        setDropBranch(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllCountry = (paginate) => {
    EmpService.getAllCountry(paginate)
      .then((response) => {
        setCountryData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const countryListData = (optionsCountryList) => {
    return optionsCountryList?.map((item, index) => (
      <option
        key={`country${index}`}
        value={`${item?.dial_code}`}
        label={`${item?.dial_code}`}
      />
    ));
  };

  const isDeptExists = (e, fieldName) => {
    // console.log("e.target.value", e.target.value);
    let data = {};
    data[fieldName] = e.target.value;
    if (editEmployee?.code === e.target.value) {
      setIsDirCodeExist(false);
      return;
    }
    // else if (editEmployee?.name === e.target.value) {
    //   setIsDeptNameExist(false);
    //   return;
    // }
    else if (editEmployee?.branch_id === e.target.value) {
      setIsDirEmailExist(false);
      return;
    } else if (editEmployee?.sequence === e.target.value) {
      setIsSequenceExist(false);
      return;
    } else {
      EmpService.isDeptExists(data)
        .then((res) => {
          if (e.target.name === "code") {
            setIsDirCodeExist(res?.data?.dataList?.is_exist);
          } else if (e.target.name === "branch_id") {
            setIsDeptNameExist(res?.data?.dataList?.is_exist);
          }
          // else if (e.target.name === "email") {
          //   setIsDirEmailExist(res?.data?.dataList?.is_exist);
          // }
          else if (e.target.name === "sequence") {
            setIsSequenceExist(res?.data?.dataList?.is_exist);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  useEffect(() => {
    getAllDepartment(false);
    // getAllBranch();
    EmpService.getSubPersonalArea()
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          // console.log("REsssss")
          return {
            value: res?.id,
            label: res?.name,
          };
        });
        setSubPersonalAreaData(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
    getAllCountry(false);
    getAllDesignation(false);
  }, []);

  useEffect(() => {
    if (edit) {
      if (editEmployee) {
        // console.log("editEmployee",editEmployee)
        setEmpData(editEmployee);
        let obj = {
          value: editEmployee?.department_id,
          label: `${editEmployee?.department_name} (${editEmployee?.department_code})`,
        };
        setEmpData1(obj);
        setempid(editEmployee?.department_id);
        let obj1 = {
          value: editEmployee?.designation_id,
          label: `${editEmployee?.designation_name} (${editEmployee?.designation_code})`,
        };
        setempDesg1(obj1);
        setempdesgid(editEmployee?.designation_id);
        let obj2 = {
          value: editEmployee?.sub_personal_area_id,
          label: editEmployee?.sub_personal_name,
        };
        setempSubPersonal(obj2);

        // empData["sub_personal_area"]=editEmployee["sub_personal"]
        Object.keys(editEmployee).forEach((item) => {
          empData[item] = editEmployee[item];
        });
      }
    }
  }, [editEmployee]);

  // const  setempid =(e) =>{
  //   setEmpData({
  //     ...empData,
  //     department_id: e[0].value,
  //   });
  //   setEmpData1(e)
  // }

  return (
    <>
      <Formik
        initialValues={empData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (isDeptNameExist !== true) {
            if (
              isDeptNameExist !== true &&
              isDirCodeExist !== true &&
              isDirEmailExist !== true &&
              isSequenceExist !== true
            ) {
              if (values.id) {
                updateEmployee(values);
                AuditLog.addAuditLog(dispatch, nameForm);
              } else {
                saveEmployee(values);
                AuditLog.addAuditLog(dispatch, nameForm);
              }
            }
          }

          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter maxwidth90"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit" : "Add"} Employee{" "}
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <>
                <Modal.Body>
                  <div className="row justify-content-center">
                    <div className="col-md-11">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Employee Code
                              {/*<span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              name="code"
                              className="form-control"
                              placeholder="Enter Employee Code"
                              maxLength={6}
                              onChange={handleChange}
                              onBlur={(e) => {
                                isDeptExists(e, "code");
                                handleBlur(e);
                              }}
                              value={values.code}
                            />
                            {touched.code && errors.code ? (
                              <div className="small text-danger">
                                {errors.code}
                              </div>
                            ) : isDirCodeExist === true ? (
                              <div className="small text-danger">
                                Employee Code already exists
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Enter Name"
                              maxLength={30}
                              onChange={handleChange}
                              onBlur={(e) => {
                                isDeptExists(e, "name");
                                handleBlur(e);
                              }}
                              value={values.name}
                            />
                            {touched.name && errors.name ? (
                              <div className="small text-danger">
                                {errors.name}
                              </div>
                            ) : isDeptNameExist === true ? (
                              <div className="small text-danger">
                                Employee Name already exists
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Email Id
                              {/*<span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter Email Id"
                              maxLength={30}
                              onChange={handleChange}
                              onBlur={(e) => {
                                isDeptExists(e, "email");
                                handleBlur(e);
                              }}
                              value={values.email}
                            />
                            {touched.email && errors.email ? (
                              <div className="small text-danger">
                                {errors.email}
                              </div>
                            ) : isDirEmailExist === true ? (
                              <div className="small text-danger">
                                Employee Name already exists
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>Business Mobile No.</label>
                            <div className="mobile-country-drop">
                              <div className="row">
                                <div class="col-3 pr-0">
                                  <select
                                    className="form-control newbgselect"
                                    name="country_code"
                                    type="number"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setEmpData({
                                        ...empData,
                                        country_code: e.target.value,
                                      });
                                    }}
                                    value={values.country_code}
                                  >
                                    <option value="" label="Select" />

                                    {countryListData(countryData)}
                                  </select>
                                  {touched.country_code &&
                                  errors.country_code ? (
                                    <div className="small text-danger">
                                      {errors.country_code}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="col-9 pl-0">
                                  <input
                                    type="number"
                                    name="mobile"
                                    className="form-control"
                                    onKeyDown={(evt) =>
                                      evt.key === "e" && evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}
                                    placeholder="Enter Mobile Number"
                                    maxLength={30}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.mobile}
                                  />
                                </div>
                              </div>
                            </div>
                            {touched.mobile && errors.mobile ? (
                              <div className="small text-danger">
                                {errors.mobile}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {/* <div class="col-3 pr-0">
                          <div className="form-group innergroup">
                            <label>
                              Country Code
                             <span className="text-danger">*</span>
                            </label>

                            <select
                              className="form-control newbgselect"
                              name="country_code"
                              type="number"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setEmpData({
                                  ...empData,
                                  country_code: e.target.value,
                                });
                              }}
                              value={values.country_code}
                            >
                              <option value="" label="Select Country" />

                              {countryListData(countryData)}
                            </select>
                            {touched.country_code && errors.country_code ? (
                              <div className="small text-danger">
                                {errors.country_code}
                              </div>
                            ) : null}
                          </div>
                        </div> */}

                        {/* <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Mobile No.<span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              name="mobile"
                              className="form-control"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              placeholder="Enter Mobile Number"
                              maxLength={30}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mobile}
                            />
                            {touched.mobile && errors.mobile ? (
                              <div className="small text-danger">
                                {errors.mobile}
                              </div>
                            ) : null}
                          </div>
                        </div> */}

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Department
                              {/*<span className="text-danger">*</span> */}
                            </label>
                            {/* <Select
                              // options={options}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                            /> */}
                            <Select
                              name="department_id"
                              // isMulti
                              options={dropDept}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={true}
                              onChange={(e) => {
                                // console.log("EE",e)
                                setempid(e.value);
                                setEmpData({
                                  ...empData,
                                  department_id: e.value,
                                });
                                setEmpData1(e);
                              }}
                              value={empData1}
                            />

                            {/* <select
                              className="form-control newbgselect"
                              name="department_id"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setEmpData({
                                  ...empData,
                                  department_id: e.target.value,
                                });
                              }}
                              value={values.department_id}
                            >
                              <option value="" label="Select Department" />

                              {renderDeptOptions(dropDept)}
                            </select> */}
                            {touched.department_id && errors.department_id ? (
                              <div className="small text-danger">
                                {errors.department_id}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Designation
                              {/*<span className="text-danger">*</span> */}
                            </label>
                            {/* <Select
                              // options={options}
                              className="basic-multi-select"
                              classnameprefix="select"
                              closeMenuOnSelect={false}
                            />
                            */}
                            <Select
                              name="designation_id"
                              // isMulti
                              options={dropDesig}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={true}
                              onChange={(e) => {
                                // console.log("EE",e)
                                setempdesgid(e.value);
                                setEmpData({
                                  ...empData,
                                  designation_id: e.value,
                                });
                                setempDesg1(e);
                              }}
                              value={empDesg1}
                            />

                            {/* <select
                              className="form-control newbgselect"
                              name="designation_id"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setEmpData({
                                  ...empData,
                                  designation_id: e.target.value,
                                });
                              }}
                              value={values.designation_id}
                            >
                              <option value="" label="Select Designation" />
                              {renderDesigOptions(dropDesig)}
                            </select> */}
                            {touched.designation_id && errors.designation_id ? (
                              <div className="small text-danger">
                                {errors.designation_id}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Sub-Personal area
                              <span className="text-danger">*</span>
                            </label>

                            {/* <Select
                              // options={options}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                            /> */}
                            {/* */}
                            <Select
                              name="sub_personal_area_id"
                              // isMulti
                              options={subPersonalArea}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={true}
                              onChange={(e) => {
                                // console.log("EE",e)
                                setempsubpersonalid(e.value);
                                setErrorFlag(false);
                                setEmpData({
                                  ...empData,
                                  sub_personal_area_id: e.value,
                                });
                                setempSubPersonal(e);
                              }}
                              value={empSubPersonal}
                            />
                            {errorFlag === true ? (
                              <div className="small text-danger">
                                *SUb personal area is required
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Telephone Ext.
                              {/*<span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="number"
                              name="telephone_ext"
                              className="form-control"
                              placeholder="Enter Telephone Ext."
                              maxLength={30}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.telephone_ext}
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                            {touched.telephone_ext && errors.telephone_ext ? (
                              <div className="small text-danger">
                                {errors.telephone_ext}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {/* <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>Sequence</label>
                            <input
                              type="number"
                              name="sequence"
                              min="1"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              className="form-control"
                              placeholder="Enter Sequence"
                              onChange={handleChange}
                              onBlur={(e) => {
                                isDeptExists(e, "sequence");
                                handleBlur(e);
                              }}
                              value={values.sequence}
                            />
                            {touched.sequence && errors.sequence ? (
                              <div className="small text-danger">
                                {errors.sequence}
                              </div>
                            ) : isSequenceExist === true ? (
                              <div className="small text-danger">
                                Sequence already exists
                              </div>
                            ) : null}
                          </div>
                        </div> */}

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Office Address
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="office_address"
                              className="form-control"
                              placeholder="Enter office_address"
                              onChange={handleChange}
                              value={values.office_address}
                            />
                            {touched.office_address && errors.office_address ? (
                              <div className="small text-danger">
                                {errors.office_address}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>
                              Geo Location
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="geo_location"
                              className="form-control"
                              placeholder="Enter geo_location"
                              onChange={handleChange}
                              value={values.geo_location}
                            />
                            {touched.geo_location && errors.geo_location ? (
                              <div className="small text-danger">
                                {errors.geo_location}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>Masking Mobile</label>
                            <select
                              className="form-control newbgselect"
                              name="masking_mobile"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.masking_mobile}
                            >
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </select>
                            {touched.masking_mobile && errors.masking_mobile ? (
                              <div className="small text-danger">
                                {errors.masking_mobile}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label>Masking Email</label>
                            <select
                              className="form-control newbgselect"
                              name="masking_email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.masking_email}
                            >
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </select>
                            {touched.masking_email && errors.masking_email ? (
                              <div className="small text-danger">
                                {errors.masking_email}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group innergroup">
                            <label className="d-block">
                              Status<span className="text-danger"></span>
                            </label>
                            <label
                              className="logCheck d-inline-block mr-4 mt-2"
                              htmlFor="active"
                            >
                              Active
                              <input
                                type="radio"
                                name="is_active"
                                id="active"
                                value={values.is_active}
                                onChange={() => {
                                  setFieldValue("is_active", true);
                                }}
                                checked={values.is_active === true}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label
                              className="logCheck d-inline-block"
                              htmlFor="Inactive"
                            >
                              Inactive
                              <input
                                type="radio"
                                name="is_active"
                                id="Inactive"
                                value={values.is_active}
                                onChange={() => {
                                  setFieldValue("is_active", false);
                                }}
                                checked={values.is_active === false}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary-inner bpi-main"
                      >
                        Submit
                      </button>
                    </>
                  )}
                </Modal.Footer>
              </>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddEmployee;
