import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  getTerritory,
} from "../../../../service/AgriPortalService/MarketSizeService";
import styles from "./Root.module.css";
import { getAGCFY, getAGReqList } from "../../../../service/AgriPortalService/AGSales/Agsales";
import {
  getReqList,
  getMonthYear,
  getRole
} from "../../../../service/AgriPortalService/ETReview";

import {
  getRegionDropDown,
  getTerritoryDropDown,
  getZoneDropDown,
} from "../../../../service/AgriPortalService/CommonService";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader, setUserData } from "../../../redux/piDataStore";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getUserDetails } from "../../../../service/header";
import { formatDate } from "@fullcalendar/core";

const Root = (props) => {
  //HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //To contain territory dropdown state
  const [territories, setTerritories] = useState();
  const [territory, setTerritory] = useState();
  //To contain market player data
  const [marketSizeData, setMarketSizeData] = useState([]);
  const [etList, setEtList] = useState([]);
  const [marketSizeSelectedData, setMarketSizeSelectedData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [territoryData, setTerritoryData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showAddNew, setShowAddNew] = useState(true);
  const [selectedZone, setSelectedZone] = useState("ALL");
  const [selectedRegion, setSelectedRegion] = useState("ALL");
  const [selectedTerritory, setSelectedTerritory] = useState("ALL");
  const [curYear, setCurYear] = useState()
  const [curmonth, setCurMonth] = useState()
  const [role, setRole] = useState();

  useEffect(() => {
    localStorage.setItem("territory", JSON.stringify(territory));
    getMonthYear()
      .then((res) => {

        let dupstr = res.data[0];
        setCurMonth(dupstr.slice(0, 3))
        setCurYear(res.data[1]);
      }
      ).catch((err) => {
        toast.error("No data found!");
        console.log(err)
      })

  }, [territory]);

  //Load territory dropdown data
  useEffect(() => {
    getRole()
      .then((res) => {
        console.log("res getrole", res.data)
        if (res.data == "RM") {
          setRole("RM")
        } else if (res.data == "ZM") {
          setRole("ZM")
        } else if (res.data == "TM") {
          setRole("TM")
        } else if (res.data == "ET") {
          setRole("ET")
        }
        if (res.data != undefined && res.data != "ET")
          getTerritory()
            .then((res) => {
              if (!res.data.dataList || res.data.dataList.length == 0) return;
              setTerritory(res.data.dataList[0]);
              setTerritories(res.data.dataList);
            })
            .catch((err) => {
              toast.error("No territory associated with your account!");
              console.log('ter error', err);
            });

      })
      .catch((err) => {
        console.log('ter error', err);
      });





  }, []);


  //Store territory to local storage
  useEffect(() => {

    getReqList().then((res) => {
      setLoader(false);
      if (res.data[0].message != undefined && res.data[0].message.length != 0) {
        setEtList([]);
      } else {
        setEtList(res.data);
      }
      console.log("list", res.data)
    }).catch((err) => {
      setLoader(false);
      toast.error("Fetching et Data failed!");
      console.log(err);
    });
  }, []);

  useEffect(() => {
    getZoneDropDown(false)
      .then((res) => {
        setZoneData((state) => {
          let newData = [
            {
              name: "All",
              code: "ALL",
              id: "ALL",
            },
          ];
          newData = [...newData, ...res.data.dataList];
          setSelectedZone(newData[0].id);
          return newData;
        });
        regionChangeHandler(-1);
      })
      .catch((err) => { });
  }, [etList, etList.length]);
  // }, [marketSizeData, marketSizeData.length]);

  const zoneChangeHandler = (e) => {
    if (e.target.value == "ALL") {
      setSelectedZone("ALL");
      setSelectedRegion("ALL");
      setSelectedTerritory("ALL");
      setRegionData([
        {
          name: "All",
          id: "ALL",
          code: "ALL",
        },
      ]);
    } else {
      setSelectedZone(e.target.value);
      getRegionDropDown(e.target.value)
        .then((res) => {
          setRegionData((state) => {
            let newData = [...state];
            newData = [
              {
                name: "All",
                id: "ALL",
                code: "ALL",
              },
            ];
            newData = [...newData, ...res.data.dataList];
            setSelectedRegion(newData[0].id);
            return newData;
          });
          territoryChangeHandler("ALL");
        })
        .catch((err) => {
          console.log("ZONE", err);
        });
    }
  };

  const regionChangeHandler = (e) => {
    setSelectedTerritory("ALL");
    if (e == -1) {
      setRegionData([
        {
          name: "All",
          code: "ALL",
          id: "ALL",
        },
      ]);
      setSelectedRegion("ALL");
      setTerritoryData((state) => {
        let newData = [...state];
        newData = [
          {
            name: "All",
            code: "ALL",
            id: "ALL",
          },
        ];
        return newData;
      });
      territoryChangeHandler("ALL");
    } else {
      setSelectedRegion(e.target.value);
      getTerritoryDropDown(e.target.value)
        .then((res) => {
          setTerritoryData((state) => {
            let newData = [...state];
            newData = [
              {
                name: "All",
                code: "ALL",
                id: "ALL",
              },
            ];
            newData = [...newData, ...res.data.dataList];
            return newData;
          });
          territoryChangeHandler("ALL");
        })
        .catch((err) => {
          console.log("ZONE", err);
        });
    }
  };

  const territoryChangeHandler = (code) => {
    setSelectedTerritory(code);
    const zone =
      zoneData[zoneData.findIndex((el) => el.id == selectedZone)]?.code;
    const region =
      regionData[regionData.findIndex((el) => el.id == selectedRegion)]?.code;
  };

  function handleLoading() {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
      if (etList.length == 0) {
        return (
          <div className={styles["nodata"]}>
            <h4 class="inner-page-title m-0 " style={{ margin: "0" }}>
              NO DATA YET! PLEASE ADD
            </h4>
          </div>
        );
      }
    }
  }
  const getUserDetail = () => {
    getUserDetails(0)
      .then((response) => {
        // dispatch(setUserData(response.data.dataList));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  useEffect(() => {
    getUserDetail();
  }, []);

  useEffect(() => {
    if (selectedZone == "ALL") {
      setMarketSizeSelectedData(etList);
    } else if (selectedZone != "ALL" && selectedRegion == "ALL") {
      setMarketSizeSelectedData((state) => {
        let newData = [...state];
        let code = zoneData.findIndex((el) => el.id == selectedZone);
        newData = etList.filter(
          (el) => el.zone_code == zoneData[code].code
        );
        return newData;
      });
    } else if (
      selectedZone != "ALL" &&
      selectedRegion != "ALL" &&
      selectedTerritory == "ALL"
    ) {
      setMarketSizeSelectedData((state) => {
        let newData = [...state];
        let code = regionData.findIndex((el) => el.id == selectedRegion);
        newData = etList.filter(
          (el) => el.region_code == regionData[code].code
        );
        console.log(
          "selectedTerritory",
          newData,
          marketSizeData,
          selectedRegion
        );
        return newData;
      });
    } else if (
      selectedZone != "ALL" &&
      selectedRegion != "ALL" &&
      selectedTerritory != "ALL"
    ) {
      setMarketSizeSelectedData((state) => {
        let newData = [...state];
        console.log(territoryData, selectedTerritory);
        let code = territoryData.findIndex(
          (el) => el.code == selectedTerritory
        );
        console.log(code);
        if (code == -1) return [];
        newData = etList.filter(
          (el) => el.territory_id__code == territoryData[code].code
        );
        console.log(
          "selectedTerritory",
          newData,
          etList,
          selectedRegion
        );
        return newData;
      });
    }
  }, [selectedZone, selectedRegion, selectedTerritory, etList]);

  // useEffect(() => {
  //   console.log("DATA", marketSizeSelectedData);
  // }, [marketSizeSelectedData, marketSizeSelectedData.length]);

  //MARKUP JSX
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["agri-portal-controls"]}
          style={{ paddingBottom: "0" }}
        >
          <div>
            <h4 class="inner-page-title m-0 " style={{ margin: "0" }}>
              ET To Customer Mapping
            </h4>
          </div>
          <div>
            <div>
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>For The Month-Year</b>
                </div>
                <div className={styles["valuebox"]}>{curmonth + "-" + curYear}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div className={styles["agri-portal-controls"]}>
          <div style={{ display: "flex", gap: "10px" }}>
            <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                color: "#0195DA",
                minWidth: "120px",
              }}
              value={selectedZone}
              // classNamePrefix="select"
              onChange={(e) => {
                zoneChangeHandler(e);
              }}
            >
              {zoneData &&
                zoneData?.map((el) => {
                  return <option value={el.id} label={el.name} />;
                })}
            </select>
            <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                color: "#0195DA",
                minWidth: "120px",
                opacity: selectedZone == "ALL" ? 0.5 : 1,
              }}
              disabled={selectedZone == "ALL"}
              // classNamePrefix="select"
              onChange={(e) => {
                regionChangeHandler(e);
              }}
            >
              {regionData &&
                regionData?.map((el) => {
                  return <option value={el.id} label={el.name} />;
                })}
            </select>
            <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                color: "#0195DA",
                minWidth: "120px",
                opacity: selectedRegion == "ALL" ? 0.5 : 1,
              }}
              disabled={selectedRegion == "ALL"}
              value={selectedTerritory}
              // classNamePrefix="select"
              onChange={(e) => {
                territoryChangeHandler(e.target.value);
              }}
            >
              {territoryData &&
                territoryData?.map((el) => {
                  return <option value={el.code} label={el.name} />;
                })}
            </select>
          </div>

          {role != undefined && role == "RM" && (
            <div className={styles["right"]}>
              <div>
                <Link
                  to="/etreview/table?mode=create"
                  class="btn btn-primary-inner"
                >
                  Add New
                </Link>
              </div>
            </div>
          )}
        </div>

        {marketSizeSelectedData != undefined && marketSizeSelectedData.length != 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div class="table-responsive">
                <table className="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Territory Code</th>
                      <th>Territory Name</th>
                      <th>Created On</th>
                      <th>Created By</th>
                      <th>Updated On</th>
                      <th>Updated By</th>
                      <th>Month-Year</th>
                      <th>Digital Use Remark</th>
                      <th>Agility Remark</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketSizeSelectedData && marketSizeSelectedData.length != 0 &&
                      marketSizeSelectedData.map((el) => {
                        let status;

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "S")
                          status = `Submitted`;


                        return (
                          <tr
                            style={{
                              backgroundColor: el.for_my_approval
                                ? "#92ff40"
                                : "",
                              fontWeight: el.for_my_approval ? "bold" : "",
                            }}
                          >
                            <td>{el.territory_id__code}</td>
                            <td>{el.territory_id__name}</td>
                            <td>{moment(el?.created_at).format("DD-MM-YYYY")}</td>
                            <td>{el.created_by__first_name + " " + el.created_by__last_name + "(" + el.created_by__username + ")"}</td>
                            <td>{moment(el?.updated_at).format("DD-MM-YYYY")}</td>
                            <td>{el?.updated_by__first_name + " " + el?.updated_by__last_name + "(" + el?.updated_by__username + ")"}</td>
                            <td>{el.month_id__name + " " + el.year}</td>
                            <td>{(el?.digital_remark).slice(0, 20)}</td>
                            <td>{(el?.agility_remark).slice(0, 20)}</td>


                            <td>{status}</td>
                            {el.editable == true ? (
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  navigate(
                                    "/etreview/table?mode=edit&id=" +
                                    el.id +
                                    "&territory_id=" +
                                    el.territory_id +
                                    "&digital_remark=" +
                                    el.digital_remark +
                                    "&agility_remark=" +
                                    el.agility_remark +
                                    "&territory_name=" +
                                    el.territory_id__name +
                                    "&region_name=" +
                                    el.region_name +
                                    "&zone_name=" +
                                    el.zone_name +
                                    "&territory_code=" +
                                    el.territory_id__code +
                                    "&zone_code=" +
                                    el.zone_code +
                                    "&region_code=" +
                                    el.region_code +
                                    "&status=" +
                                    el.status +
                                    "&editable=" +
                                    el.editable

                                  )
                                }
                              >
                                Edit
                              </td>
                            ) : (<td
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                navigate(
                                  "/etreview/table?mode=view&id=" +
                                  el.id +
                                  "&territory_id=" +
                                  el.territory_id +
                                  "&digital_remark=" +
                                  el.digital_remark +
                                  "&agility_remark=" +
                                  el.agility_remark +
                                  "&territory_name=" +
                                  el.territory_id__name +
                                  "&region_name=" +
                                  el.region_name +
                                  "&zone_name=" +
                                  el.zone_name +
                                  "&territory_code=" +
                                  el.territory_id__code +
                                  "&zone_code=" +
                                  el.zone_code +
                                  "&region_code=" +
                                  el.region_code +
                                  "&status=" +
                                  el.status +
                                  "&editable=" +
                                  el.editable
                                )
                              }
                            >
                              View
                            </td>)}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {handleLoading()}
      </div>
    </div>
  );
};
export default Root;


