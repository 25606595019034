import { useState,useEffect } from "react";
// import styles from "./Root.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useDispatch } from "react-redux";

import {getSubtransporterList} from "../../../../service/LRGRPortal/subtransporterService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Dropdown } from "react-bootstrap";
import Select from "react-select";
import { Tooltip } from "react-tippy";

import { setDisplayLoader, selectLoader } from "../../../redux/piDataStore";



const Root = ()=>{
    const params = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const handleLoader = (show = true) =>{
      dispatch(setDisplayLoader( show ? "Display" : "Hide"));
    }

    const [searchStr, setSearchStr] = useState("");
    const [plantcode, setPlantcode] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [pageCount, setpageCount] = useState(1);
    const [showAddNew, setShowAddNew] = useState(false)
    const [managerType, setManagerType] = useState();
 
    const [statustype,setStatustype] = useState("");
 
    const [subtransreqListData, setSubtransreqListData] = useState([]);


    
 

    const navigate = useNavigate();
    const getTransport = (
      search,
      plant_id,
      invoice_number,
      status,
      start_date,
      end_date,
      pageCount,
      page_size,
      sort_by
    ) => {
      handleLoader();
        getSubtransporterList(
          handleKey(
            search,
            plant_id,
            invoice_number,
            status,
            start_date,
            end_date,
            pageCount,
            pageSizeNo,
            sort_by

          )
        )
        .then((response) => {
         
          if(response.data.items[0].mesage == "No data found" || response.data.items[0].message == "no data found"){
            setSubtransreqListData([]);
            if(response.data.manager=="LM"){
              setShowAddNew(true)
              setManagerType(response.data.manager)
            }else if(response.data.manager=="PM"){
              setShowAddNew(false)
              setManagerType(response.data.manager)
            }
          }
           else if(response.data.items[0].message == "You dont have access to view lr gr request"){
            setSubtransreqListData([]);
            if(response.data.manager=="LM"){
              setShowAddNew(true)
              setManagerType(response.data.manager)
            }else if(response.data.manager=="PM"){
              setShowAddNew(false)
              setManagerType(response.data.manager)
            }
        }
          else{
              setSubtransreqListData(response.data.items);
          
             
          if(response?.data?.total > 10){
              const itemcount = (response?.data?.total)/10
              setpageCount(Math.ceil(itemcount));
          }
          else{
              setpageCount(1)
            }
                  
          if(response.data.manager=="LM"){
              setShowAddNew(true)
              setManagerType(response.data.manager)
            }else if(response.data.manager=="PM"){
              setShowAddNew(false)
              setManagerType(response.data.manager)
            }
           
           
          }
   
        })
        .catch((error) => {
        
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        }).finally(()=>{
          handleLoader(false);
        });
    }

 
    const handleKey = (
      search,
      plant_id,
      invoice_number,
      status,
      start_date,
      end_date,
      pageCount,
      pageSizeNo,
      sort_by,
     
    ) => {
    
      let queryParm =
        "page_no=" +
        pageCount +
        "&page_size=" +
        pageSizeNo +
        "&sort_by=" +
          "" ;

          if (invoice_number !== "" && invoice_number !== undefined && invoice_number !== null) {
            queryParm = queryParm + "&invoice_number=" + invoice_number;
           
    
          }
        if(status.length != 0){
                  status.map((elem,i)=>{
                    if(elem =="A"){
                      queryParm = queryParm + "&status=" + "A";
                    }
                   if(elem =="D"){
                      queryParm = queryParm + "&status=" + "D";
                    }
                    if(elem =="R"){
                      queryParm = queryParm + "&status=" + "R";
                    }
                    if(elem =="P"){
                      queryParm = queryParm + "&status=" + "P";
                    }
                  
                  })
                 
        }else{
          if (status !== "" && status !== undefined && status !== null) {
          //  queryParm = queryParm + "&status=" + status;
          }
        }
    
      if (search !== "" && search !== undefined && search !== null) {
        queryParm = queryParm + "&search=" + search;

      }
      if(plant_id.length != 0){
        plant_id.map((elem)=>{
            queryParm = queryParm + "&plant_id=" + elem.value;
        })
       
      }else{
        if (
          plant_id !== "" &&
          plant_id !== undefined &&
          plant_id !== null
        ) {

        }
      }
    
      if (
        end_date !== "" &&
        end_date !== undefined &&
        end_date !== null
      ) {
        queryParm = queryParm + "&end_date=" + end_date;
      }
      if (
        start_date !== "" &&
        start_date !== undefined &&
        start_date !== null
      ) {
        queryParm = queryParm + "&start_date=" + start_date;
      }

      return queryParm;
    }



  useEffect(() => {
    
    const params = new URLSearchParams(window.location.search);
   // setMode(params.get("start"));
   let query= "";

   if(params.get("start_date") !=null && params.get("end_date") !=null){
    getTransport(searchStr, "", "", "",params.get("start_date"),params.get("end_date"), 1, pageSizeNo);
  
      }else{
        getTransport(searchStr, "", "", "","","", 1, pageSizeNo);
       
      }

}, []);


  



  // const handleSearch = (e) => {
  //   if (e.target.value.length > 2 || e.target.value.length === 0) {
  
  //     getTransport(
  //       e.target.value.toLowerCase(),
  //       transportDataFilter.plant_id,
  //       transportDataFilter.invoice_number,
  //       transportDataFilter.status,
  //       transportDataFilter.start_date,
  //       transportDataFilter.end_date,
  //       currentPage,
  //       pageSizeNo,
  //       sortData.sort_by,
        
  //     );
  //     setSearchStr(e.target.value);
  //   }
  // }

  


  
      
  const handlePageClick = (action) => {
    // let activePage = action.selected + 1;
    // setCurrentPage(activePage);
    // getTransport(
    //   searchStr,
    //   transportDataFilter.plant_id,
    //   transportDataFilter.invoice_number,
    //   transportDataFilter.status,
    //   transportDataFilter.start_date,
    //   transportDataFilter.end_date,
    //   activePage,
    //   pageSizeNo,
    //   sortData.sort_by
    // );
  };


  // get export data excel
  





    return (
        <>
        <div class="row">
       <div class="col-md-12">
       <div class="content-wrapper-inner content-wrapper-inner2">
       
        <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">SubTransporter List</h4>
                
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                {/* <div class="col-lg-4">
                <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Plant name,
                            <br />
                            code
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                     id="searchbar"
                      type="text"
                      class="form-control"
                      placeholder="Search on Plant name, Plant code..."
                      onChange={(e) => handleSearch(e)}
                    />
                    <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                  </div>
                  </OverlayTrigger>
                </div> */}
                <div class="col-lg-12">
                  <div class="inline-spacing btn-betweenspaing">
              
                    {!showAddNew && (
                    <div>
                    <Link to="/subtransporter/table?mode=create" class="btn btn-primary-inner" >
                    <img src="images/upload.png" alt="" class="mr-3" />
                    Add New
                    </Link>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
        <div class="table-responsive">
        <table class="table table-striped table-bordered tablecured tablcuredlastnew custom-approval-table">
        <thead>
            <tr>
               
                <th>Plant Name(code)</th>
                <th>Trasporter Name(code)</th>
                <th>SubTransporter Name(code) </th>
                <th>Submitted On </th>
                <th>Submitted By</th>   
                <th>Remark</th>
                <th>Status</th>
                <th>Action</th>
               
                
            </tr>
            </thead>
            <tbody>
            
                {subtransreqListData?.length > 0  ?
                 (subtransreqListData !=null && subtransreqListData !=undefined && 
                         (subtransreqListData.map((el, i)=>{

    return <tr style={{ backgroundColor: el.current_approver =="True" ? "#92ff40" : "", fontWeight: el.for_my_approval ? "bold" : ""}}>
                       
                       
                       <td>{el.plant_name +"("+ el.plant_code +")"}</td>

                       <td>{el.transporter__name +"("+ el.transporter__code +")"}</td>

                       <td>{el.name_of_subtransport +"("+ el.subT_code +")"}</td>
                       
                       <td>
                        {
                          el.created_at !=undefined ?
                        (moment(el.created_at)).format("DD-MM-YYYY H:mm:ss")
                        : ("")
                        }
                       </td>

                       <td>{el.created_by__username}</td>
                       
                       <td>{el.remarks !=""? el.remarks : "-"}</td>
                    

                       { el.status == "D"?(<td>{"Saved"}</td>)
                       :el.status=="R"?(<td>{"Rejected"}</td>)
                       : el.status=="A"?(<td>{"Approved"}</td>)
                        : el.status=="P"?(<td>{`Pending with ${el.pending_at}`}</td>)
                       : el.status=="RP"?(<td>{"Auto Rejected"}</td>)
                       :el.status =="None"?(<td>{"Pending"}</td>)
                       :el.status == "AA"?(<td>{"Auto Approved"}</td>)
                       :el.status == null ?(<td>{"Pending"}</td>)
                       :""}

                      
                           
                       {(el.status == "D" || (el.status == "RP")||
                              (el.status == "R")) && (managerType == "PM" ) &&
                               (
                                <td
                                  style={{ cursor: "pointer", fontWeight: "bold" }}
                                  onClick={(e) =>
                                    navigate(
                                        `/subtransporter/table?mode=edit&requestID=${el.request_id}`
                                    )
                                  }
                                >
                                  Edit
                                </td>
                              )}
                            {((el.status == "P" || el.status == "A" || el.status == "AA") && (managerType == "PM" ) ) ? (
                              <td
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                onClick={(e) =>
                                  navigate(
                                    `/subtransporter/table?mode=view&requestID=${el.request_id}`
                                  ) 
                                }
                              >
                                View
                              </td>   
                         ): el.current_approver !=undefined && el.current_approver=="True" ? (
                          <td
                          style={{ cursor: "pointer", fontWeight: "bold" }}
                          onClick={(e) =>
                            navigate(
                              `/subtransporter/table?mode=approve&requestID=${el.request_id}&c_a=${el.current_approver}&approvalID=${el.approval_id}`
                            )
                          }
                        >
                          View
                        </td>
                         ) : el.current_approver !=undefined && el.current_approver=="False" ? (
                          <td
                          style={{ cursor: "pointer", fontWeight: "bold" }}
                          onClick={(e) =>
                            navigate(
                              `/subtransporter/table?mode=view&requestID=${el.request_id}`
                              )
                          }
                        >
                          View
                        </td>
                         ) : "" 
                         }


                       </tr>
                         })         
                    
    
    ) ):( <tr>
      <td colSpan={8}> No Record Found</td>
    </tr>)}
     
          

                                    
                                
                            </tbody>
        
        </table>
        </div>
        {subtransreqListData?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  style={{ textAlign: "center" }}
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    // setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}

        </div>
        </div>
       
       </div>

   
      
      
        </>
    )
    
}



export default Root;
