import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Alert, Spinner } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import LoginNewLogo from "../../assets/images/MyPI Logo_644 x 297px-01 (2).png";
import Bgimage from "../../assets/images/login-bg.jpg";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import * as LoginService from "../../service/login";
import * as ActionTypes from "../../store/actions";
import * as MandatoryService from "../../service/mandatory";
import { useDispatch } from "react-redux";
import { setDisplayLoader, selectUserData } from "../redux/piDataStore";
import { useMsal } from "@azure/msal-react";
import moment from "moment";
import * as agreement from "../../service/agree-terms";
import { loginRequest, msalConfig } from "../../Config";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const validationSchema = Yup.object({
  username: Yup.string().required("Please enter valid username."),
  password: Yup.string()
    .min(5, "Must be a minimum of 5 characters")
    .max(30, "Must be 30 characters or less")
    .required("Please enter a password"),
});

const CustomLogin = ({ userLogin }) => {
  const userData = useSelector(selectUserData);
  const REACT_CAPTCHA_KEY = "6LchAo8mAAAAAGbJ9QwR_-JcE-LQoIp6n8E4Dg5A";
  const dispatch = useDispatch();
  const [isDataLoading, setDataLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [credentialError, setCredentialError] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();
  const [logoValue, setLogoValue] = useState("");
  const [orgdata, setorgdata] = useState({});
  const [usernameData, setUserameData] = useState({
    username: "",
    password: "",
  });
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const configs = {
    aesKey: "yat@70617373Ram23313233",
  };
  const { instance } = useMsal();

  const [reCaptchaLogin, setReCaptchaLogin] = useState("");

  const YourReCaptchaComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      const token = await executeRecaptcha("yourAction");
      setReCaptchaLogin(token);

      // Do whatever you want with the token
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded

    return (
      <Button
        type="submit"
        className="btn btn-block btn-primary"
        onClick={handleReCaptchaVerify}
      >
        {isDataLoading ? (
          <Spinner
            animation="border"
            variant="primary"
            as="span"
            role="status"
            aria-hidden="true"
          ></Spinner>
        ) : (
          "Login"
        )}
      </Button>
    );
  };

  const getCourses = async (pageNo, pageSize, sortBy, agree) => {
    dispatch(setDisplayLoader("Display"));
    await MandatoryService.getCourses(pageNo, pageSize, sortBy)
      .then((response) => {
        let res = response?.data?.dataList;
        if (res?.cyber === true && res?.posh === true && res?.coc === true) {
          dispatch(setDisplayLoader("Hide"));
        }
        if (!agree) {
          navigate("/agree-terms", {
            state: {
              component: "dashboard",
            },
          });
        } else {
          navigate("/dashboard");
        }
        // setCourses(response?.data?.dataList?.Courses);
        // let counter = 0;
        // response?.data?.dataList?.Courses.map((x) => {
        //   if (x.completed_at != null) {
        //     counter = counter + 1;
        //   }
        // });
        // if (
        //   (counter === response?.data?.dataList?.Courses?.length &&
        //     counter > 0) ||
        //   response?.data?.dataList?.Courses?.length === 0
        // ) {
        //   if (!agree) {
        // navigate("/agree-terms", {
        //   state: {
        //     component: "dashboard",
        //   },
        // });
        //   } else {
        //     navigate("/dashboard");
        //   }
        // } else {
        //   if (!agree) {
        //     navigate("/agree-terms", {
        //       state: {
        //         component: "mandatory",
        //       },
        //     });
        //   } else {
        //     navigate("/mandatory");
        //   }
        // }
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        if (!agree) {
          navigate("/agree-terms", {
            state: {
              component: "dashboard",
            },
          });
        } else {
          navigate("/dashboard");
        }
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const register = (
    username,
    password,
    client_secret,
    scope,
    geo_address,
    geo_latitude,
    geo_longitude,
    device_info,
    version,
    captcha_token
  ) => {
    setDataLoading(true);
    dispatch(setDisplayLoader("Display"));
    let data = {};
    data["username"] = username;
    data["password"] = password;
    data["client_secret"] = client_secret;
    data["scope"] = scope;
    data["geo_address"] = geo_address;
    data["geo_latitude"] = geo_latitude;
    data["geo_longitude"] = geo_longitude;
    data["device_info"] = device_info;
    data["version"] = version;
    data["captcha_token"] = captcha_token;
    LoginService.login(data)
      .then((res) => {
        // let dd = courses;
        // dd.map((val) => setCoursesT(val?.completed_at));
        dispatch(setDisplayLoader("Hide"));
        let is_temporary = res?.data?.dataList?.is_temporary;
        if (is_temporary == null) {
          is_temporary = "false";
        }
        if (
          res.data.dataList.is_temporary == false ||
          res.data.dataList.is_temporary == null
        ) {
          if (res && res.data && res.data.dataList.access_token) {
            setDataLoading(false);
            if (
              !res.data.dataList.accept_tnc &&
              !res.data.dataList.accept_social
            ) {
              navigate("/agree-terms", {
                state: {
                  component: "social-policy",
                },
              });
            } else if (
              res.data.dataList.accept_tnc &&
              res.data.dataList.accept_social
            ) {
              getQuaterlyChecks();
            } else if (
              !res.data.dataList.accept_tnc &&
              res.data.dataList.accept_social
            ) {
              navigate("/agree-terms", {
                state: {
                  component: "quater-terms",
                },
              });
            } else if (
              res.data.dataList.accept_tnc &&
              !res.data.dataList.accept_social
            ) {
              navigate("/social-policy", {
                state: {
                  component: "quater-terms",
                },
              });
            }
            // getCourses(1, 10, "id", res.data.dataList.accept_tnc);
            userLogin(res.data.dataList.access_token, is_temporary);
            
            // dispatch(setAdUserName(res.data.dataList.username));
          } else {
            toast.error("Login failed, try again");
          }
        } else {
          navigate("/ResetPassword", {
            state: {
              is_temporary: res.data.dataList.is_temporary,
            },
          });
          userLogin(res.data.dataList.access_token, is_temporary);
          setDataLoading(false);
        }
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        setErrorMsg(error.response.data.message);
        setCredentialError(true);
        setDataLoading(false);
      });
  };

  const getQuaterlyChecks = () => {
    let queryParam = `curMonth=${moment(new Date()).month() + 1}`;
    agreement.quaterlyTerms(queryParam).then((response) => {
      if (response?.status === 200) {
        if (response?.data?.dataList?.checked === true) {
          navigate("/dashboard");
        } else {
          navigate("/quater-terms", {
            state: {
              component: "dashboard",
            },
          });
        }
      }
    });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
    return;
  };

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  useEffect(() => {
    if (reCaptchaLogin && reCaptchaLogin !== "") {
      // dispatch(start());
      // dispatch(Checkout(orderData, reCaptchaOrder));
      setReCaptchaLogin("");
      register(
        usernameData.username,
        usernameData.password,
        clientSecret,
        "",
        "",
        "",
        "",
        agent,
        "",
        reCaptchaLogin
      );
    }
  }, [reCaptchaLogin]);

  const handleLogo = (e) => {
    setLogoValue(e);
  };
  let agent = navigator.userAgent;
  const georg = (id) => {
    LoginService.getOrgbyId(userData?.organisation_code)
      .then((res) => {
        setorgdata(res?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        setErrorMsg(error.response.data.message);
        setCredentialError(true);
        setDataLoading(false);
      });
  };
  useEffect(() => {
    if (
      localStorage.getItem("accesstoken") !== null &&
      localStorage.getItem("accesstoken") !== undefined
    ) {
      navigate("/dashboard");
    }
    var full = window.location.host;
    var parts = full.split(".");
    var sub = parts[0];
    var domain = parts[1];
    var type = parts[2];
    georg(sub);
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${
            orgdata?.bg_image != undefined ? orgdata?.bg_image : Bgimage
          })`,
          height: "300px",
          backgroundRepeat: "no-repeat",
        }}
        className="login-bg"
      >
        <div className="container" id="check">
          <div className="row justify-content-between my-3">
            <div class="col-md-6 col-lg-6 d-flex flex-column text-center text-sm-left">
              <div>
                <img
                  src={orgdata?.logo != undefined ? orgdata?.logo : Logo}
                  alt=""
                  height="55px"
                />
              </div>
              <div class="mt-auto mb-auto">
                <h1>
                  Somewhere,
                  <br />
                  something
                </h1>
                <h2>incredible is waiting to be known.</h2>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="loginTabs">
                <div className="tabfliping">
                  <h4 style={{ margin: "0 0 -12px" }}>
                    Welcome to
                    <img
                      src={
                        orgdata?.logo != undefined ? LoginNewLogo : LoginNewLogo
                      }
                      alt=""
                      height="65px"
                      style={{ margin: "0 0 18px -12px" }}
                    />{" "}
                  </h4>
                  <Formik
                    initialValues={{ username: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {}}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      dirty,
                      isValid,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div
                          className="text-center"
                          onClick={() => navigate("/")}
                        >
                          <Link class="btn btn-primary btn-block azurelogin-btn">
                            One click login with SSO
                          </Link>
                        </div>
                        <div class="orpromocodeline">
                          <span>OR</span>
                        </div>
                        <div className="form-group">
                          <label>Enter your Employee ID</label>
                          <input
                            type="text"
                            name="username"
                            required
                            className="form-control"
                            placeholder="Employee ID"
                            onChange={(e) => {
                              handleChange(e);
                              setUserameData({
                                ...usernameData,
                                username: e.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            maxLength={60}
                            value={usernameData.username}
                          />
                          {touched.username && errors.username ? (
                            <div className="small text-danger">
                              {errors.username}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group mb-2 position-relative">
                          <label>Enter your Password</label>
                          <input
                            maxLength={16}
                            type={passwordType}
                            name="password"
                            required
                            className="form-control"
                            placeholder="Password"
                            onChange={(e) => {
                              handleChange(e);
                              setUserameData({
                                ...usernameData,
                                password: e.target.value,
                              });
                            }}
                            value={usernameData.password}
                            onBlur={handleBlur}
                          />
                          <i
                            className={
                              passwordType === "password"
                                ? "far fa-eye-slash eyepassword"
                                : " far fa-eye eyepassword"
                            }
                            onClick={() => togglePassword()}
                          ></i>
                          {touched.password && errors.password ? (
                            <div className="small text-danger">
                              {errors.password}
                            </div>
                          ) : null}
                        </div>
                        <div style={{ display: "flex" }}>
                          {/* <div
                            className="text-left"
                            style={{ float: "left" }}
                            onClick={() => handleLogin("popup")}
                          >
                            AD User Login
                          </div> */}
                          <div
                            className="text-right"
                            style={{ marginLeft: "auto" }}
                          >
                            <Link to="/forgot" className="forgettxt">
                              Forgot Password
                            </Link>
                          </div>
                        </div>

                        {/* <ReCAPTCHA
                          sitekey="6LchAo8mAAAAAGbJ9QwR_-JcE-LQoIp6n8E4Dg5A"
                          onChange={handleReCaptchaVerify}
                        /> */}
                        <GoogleReCaptchaProvider
                          reCaptchaKey={REACT_CAPTCHA_KEY}
                          scriptProps={{ async: true }}
                        >
                          <YourReCaptchaComponent />
                        </GoogleReCaptchaProvider>

                        {credentialError && (
                          <Alert variant="danger" className="mb-5 mt-2">
                            <i className="fa fa-exclamation-triangle"></i>{" "}
                            {errorMsg}
                          </Alert>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userLogin: (token, istemp) =>
    dispatch({ type: ActionTypes.LOGIN_USER, token, istemp }),
});
export default connect(null, mapDispatchToProps)(CustomLogin);
