import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./Table.module.css";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import * as TransportServices from "../../../../service/LRGRPortal/transportinvoiceservices";
import { getTransporter } from "../../../../service/LRGRPortal/transporterService";
import {PostSubtransadd, getSubtransporter, getSubtransById,PutSubtrans,postApproverAction,getSubapprovalLogs} from "../../../../service/LRGRPortal/subtransporterService"
import { getPlant } from "../../../../service/LRGRPortal/plantService";
import { Modal, Dropdown } from "react-bootstrap";
import Select from "react-select";
import { setDisplayLoader } from "../../../redux/piDataStore";
import { useDispatch } from "react-redux";
import AddIcon from "../../../../assets/images/plus.png";
import { StyledEngineProvider } from "@mui/material";


const Table = (props) => {

  const [invoicedate, setInvoicedate] = useState({ on_date: "" });
  const [invoiceList, setInvoiceList] = useState([]);


  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
 
   const [subtransList, setSubtransList] = useState({});
  const [transporter, setTransporter] = useState([]);
  const [subtransporterList, setSubtransporterList] = useState([]);

  const [mode, setMode] = useState();
  const [requestid, setRequestid] = useState();

  const [approvalid, setApprovalid] = useState();
  const [plantid, setPlantid] = useState();
  const [subtranname, setSubtranname] = useState();
  const [subtranscode,setSubtranscode] = useState();
  // const [loader, setLoader] = useState(true);
  const [selectedPlant, setSelectedPlant] = useState();
  const [secPlant , setSecPlant] = useState();
  const [secTransporter, setSecTransporter] = useState();
  const [selectedTransporter,setSelectedTransporter] = useState();
 
  const [freezeCTA, setFreezeCTA] = useState(false);
  const [searchflag, setSearchflag] = useState(false);
  const approvals = [{ id: 1, status: "P" }, { id: 2, status: "A" }, { id: 3, status: "D" }, { id: 4, status: "R" }]
  const aprvllist = [approvals];

  // modular table prop
  const [plant, setPlant] = useState([]);
  const plant_obj = {
    plant_id:"",
    plant_code: "",
    plant_name:"",
  };
  const transport_obj = {
    transporter_id:"",
    transporter_code:"",
    transporter_name:"",
  }

  const [approvalList, setApprovalList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLoader = (show = true) =>{
      dispatch(setDisplayLoader( show ? "Display" : "Hide"));
    }
 

  // show Plant and Date 
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));

    if (!params.get("mode")) return;
    if (params.get("mode") == "create") {
      handleLoader();
      getPlant()
        .then((res) => {
          setPlant(res?.data?.dataList);

        })
        .catch((err) => {
          console.log(err);
        }).finally(()=>{
          handleLoader(false);
        });
        // setLoader(false);
    } else if ((params.get("mode") == "edit") || ((params.get("mode") == "view") && (params.get("c_a") == undefined))||(params.get("mode") == "approve") && (params.get("c_a") == "True")) {
      handleLoader();
      let queryParm = "?request_id=" + params.get("requestID") 
      getSubtransById(queryParm)
         .then((res) => {
          // setPlant(res?.data?.dataList);
           

           transport_obj.transporter_id = res.data.transporter_id.id
           transport_obj.transporter_code = res.data.transporter_id.code
           transport_obj.transporter_name = res.data.transporter_id.name
           plant_obj.plant_id = res.data.plant_id.id
           plant_obj.plant_code = res.data.plant_id.code
           plant_obj.plant_name = res.data.plant_id.plant_name
           setPlantid(res.data.plant_id.id)
             setPlant([plant_obj])
             setTransporter([transport_obj])
             setSubtransList(res.data)
            
             setSubtranname(res.data.name_of_subtransport);
             setSubtranscode(res.data.code)

             handleLoader();
      if(res.data.transporter_id.id != undefined && res.data.transporter_id.id !=null){
         let query= "";
           query = `?transporter_id=${res.data.transporter_id.id}`  
              
                handleLoader();
                 getSubtransporter(query)
                   .then((response) => {
                     setSubtransporterList(response?.data?.dataList)
                   
                     setSearchflag(true)
                   })
                   .catch((error) => {
                     console.log(
                       (error.response &&
                         error.response.data &&
                         error.response.data.message) ||
                       "failed"
                     );
                   }).finally(()=>{
                     handleLoader(false);
                   }); }
        })
        .catch((error) => {
        
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        }).finally(()=>{
          handleLoader(false);
        });
      

      // setLoader(false);
    } 

  }, [mode]);



  // plant change Handler

  const plantChangeHandler = (code) => {
    setSelectedPlant(code)

    plant.map(elem=>{
      if(elem.plant_id == code){
        setSecPlant(elem.plant_name +"("+ elem.plant_code +")")
      
      }
    })
    // setInvoiceList(invoiceList.map((elem, i) => {
    //   if (index == i) {
    //     if (type == "vehicle_number") {
    //       elem["vehicle_number"] = vnumber;
    //     }
    //     return elem;
    //   } else {
    //     return elem;
    //   }
    // }))
    if(code !=undefined && code !=null && code != ""){ 
     handleLoader();
     let queryParm = "plant_id=" + code 
     
       getTransporter(queryParm)
        .then((response) => {

          setTransporter(response?.data?.dataList);
        })
        .catch((error) => {
        
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        }).finally((e)=>{
         handleLoader(false)
        });
     }

  }

  const TransporterChangeHandler = (code) =>{
    setSelectedTransporter(code)
   
    transporter.map(elem=>{
      if(elem.transporter_id == code){
        setSecTransporter(elem.transporter_name +"("+ elem.transporter_code +")")
       
      }
    })
  }
  
 



  // search Invoice Data

  const searchSubTList = () => {
    if(selectedPlant !=undefined && selectedPlant != "" && selectedTransporter !=undefined && selectedTransporter != "" ){
      handleLoader();
     
        let query= "";
      query = `?transporter_id=${selectedTransporter}`  
         
           handleLoader();
            getSubtransporter(query)
              .then((response) => {
                setSubtransporterList(response?.data?.dataList)
             
                setSearchflag(true)
              })
              .catch((error) => {
                console.log(
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  "failed"
                );
              }).finally(()=>{
                handleLoader(false);
              });
              
             setSubtransList({
                "transporter_id": Number(selectedTransporter),
                "status": "",
                "name_of_subtransport": "",
                "code": "",
                "city": "delhi",
                "contact_number": 90998,
                "gstin_number": "89090",
                "pin_code": 4569,
                "address": "dhifdsfid",
                "plant_id": Number(selectedPlant)
              })
      
    }else{
      toast.error("Please select valid Plant and Transporter");
    }
  }

  


  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
  
    setApprovalid(params.get("approvalID"))
    setRequestid(params.get("requestID"));
    let query = "";
    query = "?request_id=" + params.get("requestID");
    if (params.get("mode") != "create" ) {
      handleLoader();
      
      getSubapprovalLogs(query)
        .then((res) => {
          setApprovalList(res.data);

        })
        .catch((err) => console.log(err))
        .finally(()=>{
          handleLoader(false);
        });
    }

    // if (params.get("mode") == "edit" || params.get("mode") == "view" || params.get("mode") == "approve") {
    //   handleLoader();
    //   TransportServices.getInvoiceReqListID(query)
    //     .then((res) => {
    //       if (res.status == 200) {
    //         setInvoiceList(res.data);

    //       }
    //     })
    //     .catch((err) => console.log(err))
    //     .finally(()=>{
    //       handleLoader(false);
    //     });
    // }



  }, [mode]);







  //EVENT HANDLERS
  const onSaveHandler = () => {
    let duprow = {...subtransList}
    duprow = {...duprow, status: "D"};

    setSubtransList(duprow)
  
    handleLoader();

     if (mode == "create") {
     
        PostSubtransadd(duprow)
        .then((res) => {
          
          toast.success("Save succesfull!");
          setTimeout(() => {
            navigate("/subtransporter");
          }, 1000);
        })
        .catch((err) => {
        
          toast.error("Save failed!");
        
          setTimeout(() => {
            navigate("/subtransporter");
          }, 1000);
        }).finally(()=>{
          handleLoader(false);
        });
    }



    if (mode == "edit") {
       
      handleLoader();
       PutSubtrans(duprow)
        .then((res) => {
        
          toast.success("save succesfull!");
          setTimeout(() => {
            navigate("/subtransporter");
          }, 1000);
        })
        .catch((err) => {
        
          toast.error("save failed!");
          
          setTimeout(() => {
            navigate("/subtransporter");
          }, 1000);
        }).finally(()=>{
          handleLoader(false);
        });
    }


  };


  const onValidation = (type) => {
    

        confirmDialog(type)
  

  }


  const confirmDialog = (type) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };


  

  const fetchsubtransport = (transporterid, index, invoicenum, id) => {
    let queryParm = "?transporter_id=" + transporterid;
    
    if (id != "" && id != undefined) {
      if (transporterid != "" && transporterid != undefined) {
        handleLoader();
        getSubtransporter(queryParm)
          .then((response) => {
            

            const parentRow = invoiceList.find((row) => row.invoice_id == id);
            const ind = invoiceList.findIndex((row) => row.invoice_id == id);
        
            const index = ind;
            const dupRow = { ...parentRow }
            dupRow.sub_transporter_list = response?.data?.dataList;
            dupRow.sub_transporter_id = "";
            dupRow.sub_transporter_id__name = "";

            const full_data = invoiceList;

            full_data[index] = dupRow;
          
            setInvoiceList(full_data);
         
          })
          .catch((error) => {
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          }).finally(()=>{
            handleLoader(false);
          });;

     

      }
    } else {
      if (transporterid != "" && transporterid != undefined) {
        handleLoader();
        getSubtransporter(queryParm)
          .then((response) => {
           
         

            const parentRow = invoiceList.find((row) => row.invoice_id == id);
            const ind = invoiceList.findIndex((row) => row.invoice_id == id);
         
            const index = ind;
            const dupRow = { ...parentRow }
            dupRow.sub_transporter_list = response?.data?.dataList;
            dupRow.sub_transporter_id = "";
            dupRow.sub_transporter_id__name = "";
     
            const full_data = invoiceList;

            full_data[index] = dupRow;
      
            setInvoiceList(full_data);
          

          })
          .catch((error) => {
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          }).finally(()=>{
            handleLoader(false);
          });

      

      }
    }
  }


  const dropdownSubtHandlerr = (subtransporterid, index, type) => {

    if (subtransporterid != "") {
      const subtranporter = invoiceList[index].sub_transporter_list.find((elem) => elem.sub_transporter_id == subtransporterid);
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "sub_transporter_id") {
            elem["sub_transporter_id"] = Number(subtransporterid);
            elem["sub_transporter_id__name"] = subtranporter.sub_transporter_name;

          }
          return elem;
        } else {
          return elem;
        }
      }))



    } else {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "sub_transporter_id") {
            elem["sub_transporter_id"] = "";
            elem["sub_transporter_id__name"] = "";

          }
          return elem;
        } else {
          return elem;
        }
      }))
    }
  }


  const onChangeSubTname = (name, type) =>{
   
    if(name.length != 0){
       let duprow = {...subtransList}
     
       duprow = {...duprow, name_of_subtransport: name};
       setSubtransList(duprow)
       setSubtranname(name)
 
        // setSubtransList(subtransList.map((elem, i) => {
         
        //     if (type == "name") {
        //       elem["name_of_subtransport"] = name;
        //     }
        //     return elem;
          
        // }))
      } else {
      //   setSubtransList(subtransList.map((elem, i) => {
         
      //     if (type == "name") {
      //       elem["name_of_subtransport"] = "";
      //     }
      //     return elem;
        
      // }))
      let duprow = {...subtransList}
      duprow = {...duprow, name_of_subtransport: ""};
      setSubtransList(duprow)
      setSubtranname("")
  
      }
    }


    const onChangeSubTcode = (code, type) =>{
    
      if(code.length != 0){
        let duprow = {...subtransList}
        duprow = {...duprow, code: code};
        setSubtransList(duprow)
       setSubtranscode(code)
      
      //   setSubtransList(subtransList.map((elem, i) => {
            
      //     if (type == "code") {
      //       elem["code"] = code;
      //     }
      //     return elem; 
      // }))
    } else {
      let duprow = {...subtransList}
      duprow = {...duprow, code:code};
      setSubtransList(duprow)
     
      setSubtranscode("")
     // setSubtransList(subtransList.map((elem, i) => {
        // if (type == "code") {
        //   elem["code"] = "";
        // }
        // return elem; 
   // }))
    }
      }



  const onSubmitHandler = (invo) => {
    // setLoader(true);
    let duprow = {...subtransList}
    duprow = {...duprow, status: "P"};

    setSubtransList(duprow)
 

  

    if (mode == "create") {

      handleLoader();
      PostSubtransadd(duprow)
        .then((res) => {
        
          toast.success("Submit succesfull!");
          setTimeout(() => {
            navigate("/subtransporter");
          }, 1000);
        })
        .catch((err) => {
         
          toast.error("Submit failed!");
       
          setTimeout(() => {
            navigate("/subtransporter");
          }, 1000);
        }).finally(()=>{
          handleLoader(false);
        });
    }


    if (mode == "edit") {
      handleLoader(); 
      PutSubtrans(duprow)
        .then((res) => {
      
          toast.success("Submit succesfull!");
          setTimeout(() => {
            navigate("/subtransporter");
          }, 1000);
        })
        .catch((err) => {
       
          toast.error("Submit failed!");
          setTimeout(() => {
            navigate("/subtransporter");
          }, 1000);
        }).finally(()=>{
          handleLoader(false);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/subtransporter");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/subtransporter");
  };

  // approval/reject validate
  const validate = (value) => {
    let data = {
      request_id: requestid,
      plant_id: plantid,
      approval_id: approvalid,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
   
    handleLoader();

      postApproverAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/subtransporter");
        }, 2000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
      }).finally(()=>{
        handleLoader(false);
      });
   
  };




  //JSX markup language
  return (
    <>
      
      <div className={styles["transport-portal-container"]}>
        <ToastContainer autoClose={1000} />
        <div className={styles["transport-portal-content"]}>
          <div
            className={styles["heading"]}
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <h4 class="inner-page-title" style={{ margin: "0" }}>
              SubTransporter
            </h4>


          </div>
          <div className="col-md-12 my-2">
            <div className="border-top md-3"> </div>
          </div>
          
          <div
            className={styles["heading"]}
            style={{
              paddingTop: "0",
              paddingBottom: "15px",
              // justifyContent: "start",
            }}
          >
            <div
              className={styles["transport-portal-label"]}
              style={{ display: "flex" }}
            >
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["transport-portal-label"]}>
                  <b>Plant Name : </b>
                </div>
                <div className={styles["valueboxone"]}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      style={{
                        cursor: "pointer",
                        border: "1px solid #0195D4",
                        color: "#0195DA",
                        minWidth: "120px",
                      }}
                      disabled={mode != "create"}

                      value={selectedPlant}
                      classNamePrefix="select"
                      onChange={(e) => {

                        plantChangeHandler(e.target.value);
                      }}
                    >

                      {mode == "create" ?
                        <option value={"select plant"} label={"Plant Name"} /> :
                        ""
                      }
                      {mode == "create" && plant?.length >= 0 &&
                        plant?.map((el, index) => {
                          return <option key={index} value={el.plant_id} label={el.plant_name + "(" + el.plant_code + ")"} />;
                        })}

                      {mode !== "create" && plant?.length >= 0 &&
                        plant?.map((el, index) => {
                          return <option key={index} value={el.plant_id} label={el.plant_name + "(" + el.plant_code + ")"} />;
                        })}

                    </select>


                  </div>
                </div>
              </div>

              <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "4px",
              }}
            >
              <div className={styles["transport-portal-label"]}>
                <b>Transporter : </b>
              </div>
              <div className={styles["valueboxone"]}>
              <div style={{ display: "flex", gap: "10px" }}>
              <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                // color: "#0195DA",
                minWidth: "150px",
              }}
              disabled={mode == "edit" || mode =="view"}
               
              value={selectedTransporter}
               classNamePrefix="select"
              onChange={(e) => {
                
                TransporterChangeHandler(e.target.value);
              }}
            >
             
             {mode =="create"?
             <option value={""} label={"Select Transporter"}/>:
             ""
             } 
              {mode =="create" && transporter?.length >=0  &&
                transporter?.map((el, index) => {
                  return <option  key={index} value={el.transporter_id} label={el.transporter_name +"(" + el.transporter_code +")"} />;
                })}
              
              {mode !=="create" && transporter?.length >=0  &&
                transporter?.map((el, index) => {
                  return <option   key={index} value={el.transporter_id} label={el.transporter_name +"(" + el.transporter_code +")"} />;
                })}
            </select>
           
           
          </div>
              </div>
            </div> 

              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["transport-portal-label"]}>

                </div>
                <div className={styles["valueboxone"]}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {
                      mode == "create" && mode != undefined ? (
                        <button type="button" class="btn btn-success btn-sm" onClick={searchSubTList}>Go</button>
                      ) : (
                        ""
                      )
                    }

                  </div>
                </div>
              </div>
              { mode !=undefined && mode == "view" &&
                    <div style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "100px",
              }}>
          
              </div>
                 }
              <div >
             
              {/* <h6 style={{marginLeft:"60px", alignItems: "right", justifyContent: "center", color: "red" }} >* Mandatory fields</h6>
            */}
            </div>
            </div>
          </div>
       { searchflag ?(
          <div className="row billrow">
  <div className="col-md-12 my-2">
    <div className="border-top md-3"> </div>
  </div>
  <div
            className={styles["heading"]}
            style={{
              paddingTop: "0",
              paddingBottom: "15px",
              // justifyContent: "start",
            }}
          >
  <div
        style={{
          zIndex: 0,
          position: "relative",
          display: "flex",
          alignItems: "center",
          marginLeft: "20px",
        }}
      >
        <div className={styles["transport-portal-label"]}>
          <b>Subtransporter Name <span class="text-danger">*</span>: </b>
        </div>
      <div className={styles["valueboxone"]}>
        <div style={{ display: "flex", gap: "6px" }}>
           <input className={styles["inputtext"]} type="text" value={subtranname}  maxlength="20"  disabled={mode =="view"} onChange={(e)=>onChangeSubTname(e.target.value, "name")}  name="tname" id="tname"  placeholder="SubTransporter name"/> 
       
        </div>
      </div>
    </div> 
    <div
        style={{
          zIndex: 0,
          position: "relative",
          display: "flex",
          alignItems: "center",
          marginLeft: "4px",
        }}
      >
        <div className={styles["transport-portal-label"]}>
          <b>SubTransporter Code :</b>
        </div>
        <div className={styles["valueboxone"]}>

        <div style={{ display: "flex", gap: "10px" }}>
        <input className={styles["inputtext"]} type="text"  maxlength="20" value={subtranscode}  disabled={mode =="view"} onChange={(e)=>onChangeSubTcode(e.target.value, "code")}  name="tcode" id="tcode"  placeholder="Subtransporter code"/> 
       
     
    </div>
        </div>
      </div>
      {(mode != "view" && mode != "approve") ? (
              <div className={styles["ctas_left"]}>
                <div
                  class="btn btn-success"
                  onClick={() => {
                    onValidation("P");
                  }}
                >
                  Submit
                </div>
                <div
                  class="btn btn-primary-inner"
                  onClick={() => {
                    onValidation("D");
                  }}
                >
                  Save
                </div>
                <div
                  class=" btn btn-danger"
                  onClick={() => {
                    onCancelHandler();
                  }}
                >
                  Cancel
                </div>
              </div>
            ):(
              <div className={styles["ctas_left"]}>
                <div
                  class=" btn btn-danger"
                  onClick={() => {
                    onCancelHandler();
                  }}
                >
                  Cancel
                </div>
              </div>
            )}
         </div>
         </div>
             ):null }
              <div className="col-md-12">
   {searchflag && <div style={{fontWeight:"bold"}}> <label>The table below shows the list of the existing sub-transporters associated with Transporter {mode=="create"?secTransporter: transporter[0].transporter_name+"("+ transporter[0].transporter_code+")"} under Plant {mode=="create"? secPlant: plant[0].plant_name+"("+ plant[0].plant_code+")"}. Please do not create duplicate entry.
             </label></div> }
         </div>
        { searchflag &&  <div className={styles["table_contain"]}>
            <div className={styles["table"]}>
               
              <div>
                <div class="table-responsive"
                style={{ maxHeight: "250px", overflowY: "auto" }}
                >
                  <table class="table table-striped table-bordered tablecured tablcuredlastnew" 
                    style={{ padding:"1px", fontSize:"12px" }}>
                    <thead>
                      <tr  style={{ position: "sticky", top: 0 }}>
                        <th>SubTransporter Name</th>
                        <th>SubTransporter Code</th>
                       {/* <th>Contact Number</th>
                        <th> City</th> */}     
                        {/* <th>Split Invoice</th> */}
                      </tr>
                    </thead>
                    <tbody>

               


                      {((mode != undefined && mode == "view") || (mode != undefined && mode == "approve")) &&
                        (subtransporterList != undefined && (subtransporterList.map((el, i) => {
                          return <tr>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.sub_transporter_name}  </td>
                           <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.sub_transporter_code}  </td>
                          
                          </tr>
                        }))
                        )}
                      {subtransporterList != undefined && subtransporterList?.length != null && subtransporterList?.length != undefined && subtransporterList?.length > 0 ? (
                        (mode != undefined) && (mode == "create" || mode == "edit") &&
                        (subtransporterList != undefined && subtransporterList.length != null && (subtransporterList.map((el, i) => {
                          return <tr>
                          
                      
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.sub_transporter_name}  </td>
                           
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.sub_transporter_code}  </td>
                           
                            {/* <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.contact_number}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.sub_transporter_city}  </td>
                            */}



                          </tr>
                        }))
                        )) : (
                        <tr>
                          <td colSpan={2}> No Record Found</td>
                        </tr>)}

                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>}
  
          {approvalList.length != null && approvalList?.length > 0 &&
            mode != undefined && (mode != "create") && (
              <div className={styles["table_contain"]}>
                <div className={styles["transport-portal-table"]}>
                  <div
                    class="table-responsive"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    <table
                      class="table table-striped table-bordered tablecured tablcuredlastnew"
                      style={{ maxHeight: "200px" }}
                    >
                      <thead style={{ position: "sticky", top: 0 }}>
                        <tr style={{ textAlign: "center" }}>
                          <th>Action At</th>
                          <th>Action By</th>

                          <th>Action Status</th>
                          <th>Action Remark</th>
                        </tr>
                      </thead>
                      <tbody>

                    
                        {approvalList &&
                          approvalList.map((el, i) => {
                            let status;

                            if (el.status == "D") status = "Saved";
                            else if (el.status == "P") status = "Pending";
                            else if (el.status == "A") status = "Approved";
                            else if (el.status == "R") status = "Rejected";
                            else if (el.status == "RP") status = "Auto Rejected";


                          
                            return (
                              <tr>
                                <td>
                                  {status == "Pending"
                                    ? "-"
                                    : el.action_at == "None"
                                      ? "-"
                                      : moment(el.action_at)
                                        .utc()
                                        .format("DD-MM-YYYY")}
                                </td>
                                <td>{el.pending_at_first_name}</td>
                                <td>
                                  {status}
                                </td>
                                <td>
                                  {el.remarks == "None"
                                    ? "-"
                                    : el.remarks
                                  }
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

          <div className={styles["ctas"]}>
            {/* {mode != "view" && mode != "approve" && (
              <div className={styles["ctas_left"]}>
                <div
                  class="btn btn-success"
                  onClick={() => {
                    onValidation("P");
                  }}
                >
                  Submit
                </div>
                <div
                  class="btn btn-primary-inner"
                  onClick={() => {
                    onValidation("D");
                  }}
                >
                  Save
                </div>
              </div>
            )} */}
            {mode == "approve" ? (
              <div>
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                      Add Comments <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      placeholder="Enter Comments here..."
                      onChange={(e) => setRemark(e.target.value)}
                      value={remark}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 text-right pb-3">
                  <button
                    className="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => validate("approve")}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-outline-danger mr-3 ml-3"
                    type="button"
                    onClick={() => {
                      onApprovalCancelHandler();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => validate("reject")}
                  >
                    Decline
                  </button>
                </div>
              </div>
            ) : null
              // <div className={styles["ctas_right"]}>
              //   <div class="btn btn-danger" onClick={onCancelHandler}>
              //     Cancel
              //   </div>
              // </div>
            }
          </div>
        </div>
      </div>
    </>
  )
            }




export default Table;