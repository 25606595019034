import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import {
  getCashbookList,
  getDemandList,
  getReceiptData,
  updateStatus,
} from "../../service/cashbookService";
import moment from "moment";
import ReactPaginate from "react-paginate";
import AllUrlsCashbook from "./AllUrlsCashbook";
import { toast, ToastContainer } from "react-toastify";

function CashbookApproval() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [expenseList, setExpenseList] = useState([]);
  const [receiptList, setReceiptList] = useState([]);
  const [demandList, setDemandList] = useState([]);
  const [error, seterror] = useState(false);
  const [pageCount, setPageCount] = useState({
    expenseCount: 1,
    receiptCount: 1,
    demandCount: 1,
  });
  const [status, setStatus] = useState({
    status: "",
    remarks: "",
  });

  const getCompleteData = async () => {
    getExpenseList(
      currentPage,
      pageSizeNo,
      true,
      sortData?.sort_by,
      searchStr,
      ""
    );
    getReceipt(searchStr, 1, pageSizeNo, sortData.sort_by, true);
    getDemand(searchStr, 1, pageSizeNo, sortData.sort_by, true);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getExpenseList(
        currentPage,
        pageSizeNo,
        true,
        sortData?.sort_by,
        e.target.value.toLowerCase(),
        ""
      );
      getReceipt(
        e.target.value.toLowerCase(),
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      getDemand(
        e.target.value.toLowerCase(),
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const getExpenseList = async (
    pageNo,
    pageSizeNo,
    paginate,
    sortBy,
    search,
    status
  ) => {
    let queryParam = {
      page_no: pageNo,
      page_size: pageSizeNo,
      paginate: paginate,
      sort_by: sortBy,
      search: search,
      status: status,
      for_approval: true,
    };
    dispatch(setDisplayLoader("Display"));
    await getCashbookList(queryParam).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      setExpenseList(response?.data?.dataList?.result);
      setPageCount((prev) => ({
        ...prev,
        expenseCount: response?.data?.dataList?.paginated_data?.totalPages,
      }));
    });
  };

  const handleKey = (search, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate +
      "&for_approval=" +
      true;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  const getReceipt = async (search, pageNo, pageSizeNo, sortBy, paginate) => {
    await getReceiptData(
      handleKey(search, pageNo, pageSizeNo, sortBy, paginate)
    ).then((response) => {
      dispatch(setDisplayLoader("Display"));
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        setReceiptList(response?.data?.dataList?.result);
        setPageCount((prev) => ({
          ...prev,
          receiptCount: response?.data?.dataList?.paginated_data?.totalPages,
        }));
      }
    });
  };

  const getDemand = async (search, pageNo, pageSizeNo, sortBy, paginate) => {
    await getDemandList(
      handleKey(search, pageNo, pageSizeNo, sortBy, paginate)
    ).then((response) => {
      dispatch(setDisplayLoader("Display"));
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        setDemandList(response?.data?.dataList?.result);
        setPageCount((prev) => ({
          ...prev,
          demandCount: response?.data?.dataList?.paginated_data?.totalPages,
        }));
      }
    });
  };

  const handlePageClickExpense = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getExpenseList(
      activePage,
      pageSizeNo,
      true,
      sortData?.sort_by,
      searchStr,
      ""
    );
  };

  const handlePageClickDemand = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getDemand(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
  };

  const handlePageClickReceipt = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getReceipt(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
  };

  const submitAction = (id, stat, type) => {
    let data = status;
    data.status = stat;
    switch (type) {
      case "Receipt":
        data.receipt_id = id;
        data.expense_id = 0;
        data.demand_id = 0;
        changeReceiptRemarks(data);
        break;
      case "Expense":
        data.receipt_id = 0;
        data.expense_id = id;
        data.demand_id = 0;
        changeReceiptRemarks(data);
        break;
      case "Demand":
        data.receipt_id = 0;
        data.expense_id = 0;
        data.demand_id = id;
        changeReceiptRemarks(data);
        break;
    }
  };

  const changeReceiptRemarks = (data) => {
    updateStatus(data)
      .then((response) => {
        // getReceiptDataById("", 1, 10, "id", true, id);
        getCompleteData();
        setStatus({
          status: "",
          remarks: "",
        });
        if (data?.status === "A") {
          toast.success("Approved Successfully.");
        } else {
          toast.success("Rejected Successfully.");
        }
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    getCompleteData();
  }, []);

  return (
    <>
      <div class="row">
        {/* <AllUrlsCashbook /> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Requests for Approval</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      Requests for Approval
                    </Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div class="col-lg-8"></div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs defaultActiveKey="approval" id="approvaltabs">
                <Tab
                  eventKey="approval"
                  title={
                    <React.Fragment>
                      Expesnses for Approval
                      <span class="numberpurchse">{expenseList?.length}</span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Request ID</th>
                          <th>Requested By</th>
                          <th>Request Date</th>
                          <th>Cashbook ID</th>
                          <th>Claim Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expenseList?.length > 0 &&
                          expenseList?.map((obj) => (
                            <tr>
                              <td>
                                {obj?.status !== "A" && obj?.status !== "R" ? (
                                  <>
                                    <button
                                      class="btn btn-blanktd text-success"
                                      onClick={() => {
                                        submitAction(obj?.id, "A", "Expense");
                                      }}
                                    >
                                      <i class="far fa-check-circle"></i>{" "}
                                    </button>
                                    <button
                                      class="btn btn-blanktd text-danger ml-2"
                                      onClick={() => {
                                        submitAction(obj?.id, "R", "Expense");
                                      }}
                                    >
                                      <i class="far fa-times-circle"></i>
                                    </button>
                                  </>
                                ) : (
                                  <td></td>
                                )}
                              </td>
                              <td class="text-theme">
                                <Link
                                  to="/view-cash-expenses"
                                  class="fw-bold text-theme"
                                >
                                  {obj?.id}
                                </Link>
                              </td>
                              <td>{obj?.created_by_name}</td>
                              <td>
                                {moment(obj?.created_at).format("DD-MM-YYYY")}
                              </td>
                              <td>{obj?.cashbook_id}</td>
                              <td>{obj?.total_amount}</td>
                              <td>
                                {obj?.status === null || obj?.status === "P" ? (
                                  <span class="badge bad-status badge-warning">
                                    Pending
                                  </span>
                                ) : obj?.status === "A" ? (
                                  <span class="badge bad-status badge-success">
                                    Approved
                                  </span>
                                ) : (
                                  <span class="badge bad-status badge-danger">
                                    Rejected
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select class="form-control">
                          <option>10</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount?.expenseCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClickExpense}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="approved"
                  title={
                    <React.Fragment>
                      Receipts for Approval
                      <span class="numberpurchse">{receiptList?.length}</span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Request ID</th>
                          <th>Requested By</th>
                          <th>Request Date</th>
                          <th>Cashbook ID</th>
                          <th>Claim Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {receiptList?.length > 0 &&
                          receiptList?.map((obj) => (
                            <tr>
                              {obj?.status !== "A" && obj?.status !== "R" ? (
                                <>
                                  <button
                                    class="btn btn-blanktd text-success"
                                    onClick={() => {
                                      submitAction(obj?.id, "A", "Receipt");
                                    }}
                                  >
                                    <i class="far fa-check-circle"></i>{" "}
                                  </button>
                                  <button
                                    class="btn btn-blanktd text-danger ml-2"
                                    onClick={() => {
                                      submitAction(obj?.id, "R", "Receipt");
                                    }}
                                  >
                                    <i class="far fa-times-circle"></i>
                                  </button>
                                </>
                              ) : (
                                <td></td>
                              )}
                              <td class="fw-bold text-theme">{obj?.id}</td>
                              <td>{obj?.created_by}</td>
                              <td>
                                {moment(obj?.created_at).format("DD-MM-YYYY")}
                              </td>
                              <td>{obj?.cashbook_id}</td>
                              <td>{obj?.amount}</td>
                              <td>
                                {obj?.status === null || obj?.status === "P" ? (
                                  <span class="badge bad-status badge-warning">
                                    Pending
                                  </span>
                                ) : obj?.status === "A" ? (
                                  <span class="badge bad-status badge-success">
                                    Approved
                                  </span>
                                ) : (
                                  <span class="badge bad-status badge-error">
                                    Rejected
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select class="form-control">
                          <option>10</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount?.receiptCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClickReceipt}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="rejected"
                  title={
                    <React.Fragment>
                      Cash Demand for Approval
                      <span class="numberpurchse">{demandList?.length}</span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Request ID</th>
                          <th>Requested By</th>
                          <th>Request Date</th>
                          <th>Cashbook ID</th>
                          <th>Claim Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {demandList?.length > 0 &&
                          demandList?.map((obj) => (
                            <tr>
                              {obj?.status !== "A" && obj?.status !== "R" ? (
                                <>
                                  <button
                                    class="btn btn-blanktd text-success"
                                    onClick={() => {
                                      submitAction(obj?.id, "A", "Demand");
                                    }}
                                  >
                                    <i class="far fa-check-circle"></i>{" "}
                                  </button>
                                  <button
                                    class="btn btn-blanktd text-danger ml-2"
                                    onClick={() => {
                                      submitAction(obj?.id, "R", "Demand");
                                    }}
                                  >
                                    <i class="far fa-times-circle"></i>
                                  </button>
                                </>
                              ) : (
                                <td></td>
                              )}
                              <td
                                class="fw-bold text-theme cursor-pointer"
                                onClick={filteropen}
                              >
                                {obj?.id}
                              </td>
                              <td>{obj?.created_by}</td>
                              <td>
                                {moment(obj?.created_at).format("DD-MM-YYYY")}
                              </td>
                              <td>{obj?.cashbook_id}</td>
                              <td>{obj?.amount}</td>
                              <td>
                                {obj?.status === null || obj?.status === "P" ? (
                                  <span class="badge bad-status badge-warning">
                                    Pending
                                  </span>
                                ) : obj?.status === "A" ? (
                                  <span class="badge bad-status badge-success">
                                    Approved
                                  </span>
                                ) : (
                                  <span class="badge bad-status badge-error">
                                    Rejected
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select class="form-control">
                          <option>10</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount?.demandCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClickDemand}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cash Demand Request Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row viewformaftersubmit">
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cash Request No</label>
                <p>PICASHDE10000472</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cashbook</label>
                <p>CASH IN HAND - RAIPUR</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Request Date</label>
                <p>18-Feb-2023</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cash Transfer Request</label>
                <p>INR 14999</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cashbook</label>
                <p>Ajit Singh</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Beneficiary Code</label>
                <p>RaipurAnoop</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Payable Branch</label>
                <p>RAIPU</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Indentification Doc. Type</label>
                <p>PAN Card</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Indentification Doc. No</label>
                <p>GLDPS8860L</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Remarks</label>
                <p>Test</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>SAP Remarks</label>
                <p>Ok</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-secondary" onClick={filterclose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CashbookApproval;
