import React, { useState } from "react";
import { Link } from "react-router-dom";
import Jivasmall from "../../assets/images/jivasmall.png";
import Sidemenu from "../include/sidemenu";
import Header from "../include/header";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";

function SubModule() {
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const selrole = [
    { value: "Admin", label: "Admin" },
    { value: "Manager", label: "Manager" },
    { value: "Accountant", label: "Accountant" },
    { value: "Engineer", label: "Engineer" },
    { value: "Team Lead", label: "Team Lead" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  return (
    <>
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Sub-Module</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Sub-Module</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <div class="input-group table-search">
                <span class="input-group-text">
                  <img src="images/search.png" alt="" />
                </span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                />
              </div>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <img src="images/export.png" alt="" class="mr-2" /> Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <i class="fas fa-print"></i>Print
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                      <i class="far fa-file-alt"></i>CSV
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                      <i class="far fa-file-excel"></i>Excel
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                      <i class="far fa-file-pdf"></i>Pdf
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                      <i class="far fa-copy"></i>Copy
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button onClick={filteropen} class="btn btn-secondary-inner">
                  <img src="images/filter.png" alt="" class="mr-3" />
                  Filter
                </button>
                <button class="btn btn-primary-inner" onClick={addnewopen}>
                  <img src="images/upload.png" alt="" class="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Sub-Module Name</th>
                <th>Module Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-theme fw-bold">News</td>
                <td>CMS</td>
                <td>Description will come here</td>
                <td>
                  <span class="badge bad-status badge-danger">InActive</span>
                </td>
                <td>
                  <button class="btn btn-blanktd text-primary">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class="btn btn-blanktd text-danger ml-2">
                    <i class="far fa-trash-alt text-danger"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td class="text-theme fw-bold">News</td>
                <td>CMS</td>
                <td>Description will come here</td>
                <td>
                  <span class="badge bad-status badge-success">Active</span>
                </td>
                <td>
                  <button class="btn btn-blanktd text-primary">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class="btn btn-blanktd text-danger ml-2">
                    <i class="far fa-trash-alt text-danger"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td class="text-theme fw-bold">News</td>
                <td>CMS</td>
                <td>Description will come here</td>
                <td>
                  <span class="badge bad-status badge-danger">InActive</span>
                </td>
                <td>
                  <button class="btn btn-blanktd text-primary">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class="btn btn-blanktd text-danger ml-2">
                    <i class="far fa-trash-alt text-danger"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td class="text-theme fw-bold">News</td>
                <td>CMS</td>
                <td>Description will come here</td>
                <td>
                  <span class="badge bad-status badge-success">Active</span>
                </td>
                <td>
                  <button class="btn btn-blanktd text-primary">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class="btn btn-blanktd text-danger ml-2">
                    <i class="far fa-trash-alt text-danger"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td class="text-theme fw-bold">News</td>
                <td>CMS</td>
                <td>Description will come here</td>
                <td>
                  <span class="badge bad-status badge-success">Active</span>
                </td>
                <td>
                  <button class="btn btn-blanktd text-primary">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class="btn btn-blanktd text-danger ml-2">
                    <i class="far fa-trash-alt text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <div class="sortinglist">
              Show
              <select class="form-control">
                <option>10</option>
              </select>
            </div>
          </div>
          <div class="col-sm-7">
            <Pagination>
              <Pagination.Prev className="previpag" />
              <Pagination.Item>{1}</Pagination.Item>
              <Pagination.Item active>{2}</Pagination.Item>
              <Pagination.Ellipsis />
              <Pagination.Item>{9}</Pagination.Item>
              <Pagination.Item>{10}</Pagination.Item>
              <Pagination.Next className="previpag" />
            </Pagination>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Select Module<span class="text-danger">*</span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Sub-Module<span class="text-danger">*</span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>Active</option>
              <option>Inactive</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Sub-Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Select Module<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Sub-Module Name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Module Code"
                    />
                   
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Description<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Description"
                    />
                   
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label className="d-block">
                      Status<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Active
                      <input type="radio" name="statusupdate1" />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input type="radio" name="statusupdate1" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Submit</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SubModule;
