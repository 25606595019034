import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown } from "react-bootstrap";
import CustomBreadcrumb from "./CustomBreadcrumb";
import CustomMenu from "./CustomMenu";
import CustomPagination from "./CustomPagination";
import * as HSNService from "../../service/hsnService";
import { Tooltip } from "react-tippy";
import AddMatHsn from "./addMat-hsn";
import moment from "moment/moment";
import * as searchPoService from "../../service/searchpo";

function MatHSN() {

  const pathname = window.location.pathname;

  const CustomBreadcrumbItems = [
    { to: "/home", title: "Home" },
    { to: "/", title: "Transactions" },
    { active: true, title: "HSN Code" },
  ];

  const [hsnDataList, setHsnDataList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [editHsnData, setEditHsnData] = useState();
  const [searchStr, setSearchStr] = useState("");
  const [editFlag, setEditFlag] = useState(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  const editHsnDataList = (eData) => {
    setEditHsnData(eData);
    addnewopen();
    setEditFlag(true);
  };

  const getHsnListData = (page_no, page_size, report_flag, search) => {
    HSNService.getHsnListData(page_no, page_size, report_flag, search)
      .then((response) => {
        setHsnDataList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getHsnListData(
      activePage,
      pageSizeNo,
      pathname === 'mat-hsn' ? false : true,
      ''
      );
  };

  useEffect(() => {
    getSapSchedule()
    if (pathname === 'mat-hsn'){
    getHsnListData(currentPage, pageSizeNo, false, searchStr);
    }
    else{
      getHsnListData(currentPage, pageSizeNo, true, searchStr);
    }
  }, [pageSizeNo]);

  // useEffect(() => {
  //   getHsnListData("1", "10");
  // }, []);

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      if (
        pathname === "/mat-hsn-report"
      ) {
        getHsnListData(currentPage, pageSizeNo, true, e.target.value.toLowerCase());
      } else {
        getHsnListData(currentPage, pageSizeNo, false, e.target.value.toLowerCase());
      }
      setSearchStr(e.target.value);
    }
  };

  const getSapSchedule = () =>{
    searchPoService.get_sap_schedule('sync_post_hsn_master_data').then((response)=>{
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <CustomMenu />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">HSN Code</h4>
                  {/* <CustomBreadcrumb items={CustomBreadcrumbItems} /> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    {/* <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <i class="fas fa-print"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-alt"></i>CSV
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-pdf"></i>Pdf
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-copy"></i>Copy
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <button class="btn btn-primary-inner" onClick={addnewopen}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Update HSN
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{fontSize:"13px"}}>Next SAP Posting:</b> <span className="mr-3" style={{fontSize:"13px"}}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Material Code</th>
                    <th>HSN Code</th>
                    <th>Plant</th>
                    <th>Status</th>
                    <th>Approver 1</th>
                    <th>Approver 1 Remarks</th>
                    <th>Approver 2</th>
                    <th>Approver 2 Remarks</th>
                    <th>Technical Desc</th>
                    <th>SAP Status</th>
                    <th>SAP Response</th>
                    {/* <th>Next SAP Sync Time</th> */}
                  </tr>
                </thead>
                <tbody>
                  {hsnDataList?.length > 0 ? (
                    hsnDataList?.map((data, i) => (
                      <tr>
                        <td>
                          <button
                            class="btn btn-blanktd text-primary f-12"
                            onClick={() => editHsnDataList(data)}
                          >
                            <i class="far fa-edit"></i>
                          </button>
                          <button class="btn btn-blanktd text-danger ml-2 f-12">
                            <i class="far fa-trash-alt text-danger"></i>
                          </button>
                        </td>
                        <td>
                          <Tooltip
                            title={data?.material_code}
                            position="bottom"
                          >
                            {`${data?.material_description} (${data?.material_code})`}{" "}
                          </Tooltip>
                        </td>
                        {data?.new_hsn_code === null ?
                        <td>
                          <Tooltip
                            title={data?.hsn_code}
                            position="bottom"
                          >
                          {data?.hsn_code}
                          </Tooltip>
                        </td>
                        :
                        <td>{data?.new_hsn_code}</td>
                        }
                        <td>{`${data?.plant_description} (${data?.plant_code})`}</td>
                        <td>
                          {data?.status === "P1" ? (
                            <span class="badge bad-status badge-warning">
                              Pending At Approval 1
                            </span>
                          ) : data?.status === "A1" ? (
                            <span class="badge bad-status badge-success">
                              Approved At Approval 1
                            </span>
                          ) : data?.status === "R1" ? (
                            <span class="badge bad-status badge-danger">
                              Rejected At Approval 1
                            </span>
                          ) : data?.status === "P2" ? (
                            <span class="badge bad-status badge-warning">
                              Pending At Approval 2
                            </span>
                          ) : data?.status === "A2" ? (
                            <span class="badge bad-status badge-success">
                              Approved
                            </span>
                          ) : data?.status === "R2" ? (
                            <span class="badge bad-status badge-danger">
                              Rejected At Approval 2
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {data?.approver_1 == " ()" ? "" : data?.approver_1}
                        </td>
                        <td>
                        <Tooltip
                            title={data?.remark1}
                            position="bottom"
                          >
                          {data?.remark1}
                          </Tooltip>
                        </td>
                        <td>
                          {data?.approver_2 == " ()" ? "" : data?.approver_2}
                        </td>
                        <td>
                        <Tooltip
                            title={data?.remark2}
                            position="bottom"
                          >
                          {data?.remark2}
                          </Tooltip>
                        </td>
                        <td>{data?.desc}</td>
                        {data?.sap_status === true ?
                        <td>Synced</td>
                        :
                        <td>Pending to Sync</td>
                        }
                        <td>
                        <Tooltip
                            title={data?.sap_message}
                            position="bottom"
                          >
                          {data?.sap_message}
                          </Tooltip>
                        </td>
                        {/* {data?.sap_status === false && data?.sap_hit_at !== null ?
                        <td>{moment(data?.sap_hit_at).add(5,'minute').format("DD-MM-YYYY HH:mm")}</td>
                        :
                        <td></td>
                        } */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <CustomPagination
              setPageSizeNo={setPageSizeNo}
              setCurrentPage={setCurrentPage}
              pageCount={pageCount}
              handlePageClick={handlePageClick}
            />
          </div>
        </div>
      </div>
      {addnewshow === true && (
        <AddMatHsn
          addnewclose={addnewclose}
          editFlag={editFlag}
          editHsnData={editHsnData}
          getHsnListData={getHsnListData}
          addnewshow={addnewshow}
          setEditFlag={setEditFlag}
        />
      )}
    </>
  );
}

export default MatHSN;
