import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getChangeShiftData = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/shift/shift_change?${queryParm}`);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/shift/shift_change/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const deleteChangeShiftData = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/shiftshift_change/${id}`);
};

export const getRightForChangeShift = async () => {
  return axios.get(`${AUTH_BASE_URL}/shift/check_right`);
};

export const getCompEmp = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/shift/getCompensatoryEmployee?${queryParm}`
  );
};

export const getFilterData = async () => {
  return axios.get(`${AUTH_BASE_URL}/shift/getFilterEmployee`);
};

export const postData = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/shift/post_comp_allowance`, data);
};

export const updatepostData = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/shift/update_comp_allowance`, data);
};

export const getCompAllowance = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/shift/get_comp_allowance?${queryParm}`);
};

export const getCompOffExport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/shift/get_comp_allowance/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
