import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import * as HigherEduService from "../../service/educationService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import DataTable from "./DataTable";

function HigherEduClaimListing(props) {
    const location = useLocation();
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    const claimData = location?.state?.claimData;

    const amountFormatter = number => new Intl.NumberFormat(claimData.currency == 'INR' ? 'en-In' : 'en-Us', { style: 'currency', currency: claimData.currency }).format(number)

    const actionColumn = (value, row) => {
        return (
            <div className="d-flex justify-content-around">
                <button style={{ background: 'none', border: 'none', color: '#0195d4' }} onClick={() => {
                    navigate("/higher-edu-claim", {
                        state: {
                            id: row.id,
                            currency: claimData.currency,
                            course: row.course
                        }
                    })
                }}> Detail </button>
                <button style={{ background: 'none', border: 'none', color: '#0195d4' }} className="ml-3" onClick={() => {
                    navigate("/higher-edu-add-claim", {
                        state: {
                            id: row.id,
                            currency: claimData.currency,
                            isPreview: true
                        }
                    })
                }}> Summary </button>
                {/* <i className="fas fa-eye" style={{ color: '#0195d4', cursor: 'pointer' }} onClick={() => {
                    navigate("/higher-edu-claim", {
                        state: {
                            id: row.id,
                            currency: claimData.currency,
                            course: row.course
                        }
                    })
                }}></i>
                <i className="fas fa-eye" style={{ color: '#00c89d', cursor: 'pointer' }} onClick={() => {
                    navigate("/higher-edu-add-claim", {
                        state: {
                            id: row.id,
                            currency: claimData.currency,
                            isPreview: true
                        }
                    })
                }}></i> */}
            </div>
        )
    }

    const nameFormatter = (name, id) => {
        const full_name = `${name} (${id})`;
        if (name) {
            return <span style={{ whiteSpace: full_name.length > 25 ? 'pre-wrap' : 'nowrap' }}>{full_name}</span>
        }
    }

    const column = [
        {
            datafield: '',
            text: 'Action',
            formatter: actionColumn
        },
        {
            datafield: 'id',
            text: 'Request ID',
            formatter: value => `HEP${value}`
            // formatter: value => < Link to="/higher-edu-approval" state={{ from: value, isApproval: false }}> HEP{value} </Link>
        },
        {
            datafield: 'created_at',
            text: 'Date',
            formatter: value => moment.parseZone(value).format("DD-MM-YYYY")
        },
        {
            datafield: 'user_full_name',
            text: 'Name',
            formatter: (value, row) => nameFormatter(value, row?.username)
        },
        {
            datafield: 'personal_area_description',
            text: 'Location',
            formatter: (value, row) => nameFormatter(value, row?.personal_area_name)
        },
        {
            datafield: 'course',
            text: 'Course Name'
        },
        {
            datafield: 'university',
            text: 'Name of Institute'
        },
        {
            datafield: 'course_duration',
            text: 'Duration'
        },
        {
            datafield: 'course_fee',
            text: 'Total Requested Amount',
            formatter: value => amountFormatter(value)
        },
        {
            datafield: 'sanctioned_amount',
            text: 'Sanctioned Amount',
            formatter: value => amountFormatter(value)
        },
        {
            datafield: 'claimed_amount',
            text: 'Claimed Amount',
            formatter: value => amountFormatter(value)
        },
        {
            datafield: 'balance_amount',
            text: 'Balance Amount',
            formatter: value => amountFormatter(value)
        },
        {
            datafield: 'justification',
            text: 'Justification'
        },
        {
            datafield: 'created_by_full_name',
            text: 'Created By',
            formatter: (value, row) => nameFormatter(value, row?.created_by_username)
        },
    ];

    return (<>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="content-wrapper-inner content-wrapper-inner2">
                        <div className="innerheadsec">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="inner-page-title">{claimData?.course}</h4>
                                </div>
                                <div className="col-md-12">
                                    <div className="border-top mt-2 mt-md-4 mb-3"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="input-group table-search">
                                        <span className="input-group-text">
                                            <img src="images/search.png" alt="" />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                        // onChange={(e) => {
                                        //     handleSearch(e);
                                        // }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="inline-spacing btn-betweenspaing">
                                        <button
                                            // onClick={filteropen}
                                            className="btn btn-secondary-inner"
                                        >
                                            <img src="images/filter.png" alt="" className="mr-3" />
                                            Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            column={column}
                            list={[claimData]}
                            // option={{
                            //   pagination: {show: true}
                            // }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default HigherEduClaimListing