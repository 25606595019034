import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Accordion, Tabs, Tab } from "react-bootstrap";

function HelpDesk() {
  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/faqs">Faqs</Link>
            </li>
            {/* <li>
              <Link to="/admin-guide">Administrater Guide</Link>
            </li> */}
            <li>
              {/* <Link to="/raise-ticket">Raise Ticket</Link> */}
              <a
                target="blank"
                href="https://piindustries.symphonysummit.com/Summit_ssologin.aspx"
              >
                Raise Ticket
              </a>
            </li>
            <li class="active">
              <Link to="/help-desk">IT Help Desk</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">IT Help Desk</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Help</Breadcrumb.Item>
                    <Breadcrumb.Item active>Help Desk</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-10">
                  <div class="help-deskbg">
                    <img src="images/image.png" alt="" />
                    {/* <h2>BY PHONE</h2>
                    <h3>(Monday to Friday, 9am to 6pm PST)</h3>
                    <h3>
                      <strong>India Toll-Free:</strong>
                    </h3>
                    <h4>+91 9876543210</h4> */}
                  </div>
                </div>
                {/* <div class="col-md-6">
                  <div class="help-deskbg">
                    <img src="images/email.png" alt="" />
                    <h2>BY E-MAIL</h2>
                    <h3>&nbsp;</h3>
                    <h3>Just send us your questions or concerns</h3>
                    <h4>Help.pi@gmail.com</h4>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpDesk;
