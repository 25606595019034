import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import * as RelCodeServices from "../../service/purchaseOrg";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  desc: Yup.string().required("*Description is required").nullable(),
  code: Yup.string()
    .required("*Purchase Organisation Code is required")
    .nullable(),
});

const AddPurchaseOrg = (props) => {
  const { closeForm, showForm, edit, editGrp, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeExist, setIsCodeExist] = useState(false);

  const [groupData, setGroupData] = useState({
    code: "",
    desc: "",
  });

  const savePurchaseOrg = (values) => {
    setIsLoading(true);
    RelCodeServices.saveReleaseCode(values)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updatePurchaseOrg = (values) => {
    setIsLoading(true);
    RelCodeServices.updateReleaseCode(values, values.id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const isCodeExists = (e, fieldName) => {
    let data = {};
    data[fieldName] = e.target.value;
    if (editGrp?.code == e.target.value) {
      setIsCodeExist(false);
      return;
    } else {
      RelCodeServices.isCodeExists(data)
        .then((res) => {
          if (e.target.name == "code") {
            setIsCodeExist(res?.data?.dataList?.is_exist);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  useEffect(() => {
    if (editGrp) {
      setGroupData(editGrp);
      Object.keys(editGrp).forEach((item) => {
        groupData[item] = editGrp[item];
      });
    }
  }, [editGrp]);
  return (
    <>
      <Formik
        initialValues={groupData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (isCodeExist !== true) {
            if (values.id) {
              updatePurchaseOrg(values);
            } else {
              savePurchaseOrg(values);
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Modal
            show={showForm}
            onHide={closeForm}
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {edit ? "Edit" : "Add"} Purchase Organisation
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div class="row justify-content-center">
                  <div class="col-md-11">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group innergroup">
                          <label>
                            Purchase Organisation Code
                         <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="code"
                            className="form-control"
                            placeholder="Enter Purchase Organisation Code"
                            maxLength={15}
                            onChange={(e) => {
                              handleChange(e);
                              isCodeExists(e, "code");
                            }}
                            onBlur={handleBlur}
                            value={values.code}
                          />
                          {touched.code && errors.code ? (
                            <div className="small text-danger">
                              {errors.code}
                            </div>
                          ) : isCodeExist === true ? (
                            <div className="small text-danger">
                              *Purchase Organisation Code already existss
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group innergroup">
                          <label>
                            Description<span class="text-danger">*</span>
                          </label>

                          <textarea
                            name="desc"
                            className="form-control"
                            placeholder="Enter Description here..."
                            maxLength={250}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.desc}
                          />
                          {touched.desc && errors.desc ? (
                            <div className="small text-danger">
                              {errors.desc}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      class="btn btn-outline-danger"
                      onClick={closeForm}
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      class="btn btn-primary-inner bpi-main"
                      type="submit"
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddPurchaseOrg;
