import React, { useState, useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as userGl from "../../service/userGl";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tippy";
import { injectStyle } from "react-toastify/dist/inject-style";
import VendorCardMapping from "./vendorCardMapping";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

if (typeof window !== "undefined") {
  injectStyle();
}

const CardMappingIndex = () => {
  const [userCardMap, setUserCardMap] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editUserCardData, setEditUserCardData] = useState(null);
  const [searchStr, setSearchStr] = useState("");

  const handleKey = (search, page_no, page_size, sort_by, paginate) => {
    let queryParm = "paginate=" + paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (page_no !== "" && page_no !== undefined && page_no !== null) {
      queryParm = queryParm + "&page_no=" + page_no;
    }
    if (page_size !== "" && page_size !== undefined && page_size !== null) {
      queryParm = queryParm + "&page_size=" + page_size;
    }

    return queryParm;
  };

  const getOtherExpensePetty = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    userGl
      .getOtherExpensePetty(
        handleKey(search, page_no, page_size, sort_by, paginate)
      )
      .then((response) => {
        setUserCardMap(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getOtherExpensePetty(searchStr, activePage, pageSizeNo, "-id", "true");
  };

  const onAddButtonClick = () => {
    setShow(true);
    setEditMode(false);
    setEditUserCardData(null);
  };

  const onEdit = (data) => {
    setShow(true);
    setEditMode(true);
    setEditUserCardData(data);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getOtherExpensePetty(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        "-id",
        "true"
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getOtherExpensePetty(data, currentPage, pageSizeNo, "-id", "true");
    }
  };
  const onCloseButtonClick = () => {
    setShow(false);
  };

  const onAddCallBack = () => {
    setShow(false);
    getOtherExpensePetty("", currentPage, pageSizeNo, "-id", "true");
  };

  useEffect(() => {
    getOtherExpensePetty("", currentPage, pageSizeNo, "-id", "true");
  }, [pageSizeNo]);

  return (
    <>
      <div className="content-wrapper-inner">
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Petty User</h4>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on
                      <strong>
                        username,
                        <br />
                        vendorname,
                        <br />
                        card Number
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on username and vendorname..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                <button
                  className="btn btn-primary-inner"
                  type="button"
                  onClick={onAddButtonClick}
                >
                  <img src="images/upload.png" alt="" className="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Vendor Name</th>
                <th>Card Number</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userCardMap?.length ? (
                userCardMap?.map((data, index) => (
                  <>
                    <tr>
                      <td>
                        {data?.user_name} ({data?.user_code})
                      </td>
                      <td>
                        {data?.vendor_name !== null && data?.vendor_name !== ""
                          ? `${data?.vendor_name}-(${data?.vendor_code})`
                          : ""}
                      </td>
                      <td>{data?.card_no}</td>
                      <td>
                        {data?.is_active === true ? "Active" : " Inactive"}
                      </td>
                      <td>
                        <button
                          className="btn btn-blanktd text-primary"
                          type="button"
                          onClick={() => onEdit(data)}
                        >
                          <i className="far fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={6}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {userCardMap?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>
      {show && (
        <VendorCardMapping
          show={show}
          onCloseButtonClick={onCloseButtonClick}
          edit={editMode}
          editUserCardData={editUserCardData}
          onAddCallBack={onAddCallBack}
        />
      )}
    </>
  );
};

export default CardMappingIndex;
