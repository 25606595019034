import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import * as NPSServices from "../../service/NpsServices";
import { useEffect } from "react";
import moment from "moment";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { get_sap_schedule } from "../../service/searchpo";

const NpsReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const filterclose = () => {
    setFilterApplied(false);
    filtersetShow(false);
    getNpsData(
      searchStr,
      "",
      "",
      "",
      currentTab,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      true
    );
    setFilterUser([]);
    setNpsDataFilter({
      ...npsDataFilter,
      user_id: "",
      from_date: "",
    });
  };
  const [currentTab, setCurrentTab] = useState("P");
  const [pageCount, setPageCount] = useState(1);
  const [pendingCount, setPendingCount] = useState("");
  const [sapCount, setSapCount] = useState("");
  const [rejectCount, setrejectCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [npsReportPendingList, setNpsReportPendingList] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState([]);
  const [existingId, setExistingId] = useState([]);
  const [sendToSap, setSendToSap] = useState([]);
  const [allEmployeName, setAllEmployeName] = useState([]);
  const [rejectList, setRejectList] = useState([]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [filterUser, setFilterUser] = useState([]);
  const [pendingDataApproval, setPendingDataApproval] = useState({
    data: [],
  });
  const [npsDataFilter, setNpsDataFilter] = useState({
    user_id: "",
    from_date: "",
    to_date: "",
    status: "",
  });
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')

  const onSubmitHandle = () => {
    if (pendingDataApproval.data.length > 0) {
      if (currentTab === "P") {
        let obj = pendingDataApproval?.data
        obj?.map((res) => {
          effectiveDate?.map((val) => {
            if (res?.id === val?.id) {
              res.effective_date = val?.date
              res.pran_number = val?.pran
            }
          })
        })
        sumbitNpsGrid(pendingDataApproval);
      }
      if (currentTab === "A") {
        sendToSAPServer(sendToSap);
      }
    } else {
      toast.error("Please Select a record");
    }
  };

  const getSapSchedule = () => {
    get_sap_schedule('sync_post_nps').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }

  // const [npsDataForExport, setNpsDataForExport] = useState({
  //     user_id: "",
  //     from_date: "",
  //     to_date: "",
  //     status: "",
  //   });

  const handleKey = (
    search,
    user_id,
    from_date,
    to_date,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    show_all_flag
  ) => {
    let queryParm =
      "page_no=" + page_no + "&page_size=" + page_size + "&sort_by=" + sort_by;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    if (paginate !== "" && paginate !== undefined && paginate !== null) {
      queryParm = queryParm + "&paginate=" + paginate;
    }
    if (
      show_all_flag !== "" &&
      show_all_flag !== undefined &&
      show_all_flag !== null
    ) {
      queryParm = queryParm + "&show_all_flag=" + show_all_flag;
    }

    return queryParm;
  };

  const ExportHandleKey = (
    search,
    user_id,
    from_date,
    to_date,
    status,
    sort_by
  ) => {
    let queryParm = "&sort_by=" + sort_by;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    return queryParm;
  };

  const getNpsData = (
    search,
    user_id,
    from_date,
    to_date,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    show_all_flag
  ) => {
    NPSServices.getNpsData(
      handleKey(
        search,
        user_id,
        from_date,
        to_date,
        status,
        page_no,
        page_size,
        sort_by,
        paginate,
        show_all_flag
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let pendingData = response?.data?.dataList?.pending.map((pd, i) => {
          pd.status = "N";
          return pd;
        });
        setNpsReportPendingList({
          pending: pendingData,
        });
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setPendingCount(response?.data?.dataList?.pendingCount);
        setSapCount(response?.data?.dataList?.sapCount);
        setrejectCount(response?.data?.dataList?.rejectCount);
        setRejectList(response?.data?.dataList?.rejected);
        setSendToSap(response?.data?.dataList?.send_to_sap);
        getAllEmployeIdName(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getAllEmployeIdName = (paginate) => {
    NPSServices.getAllEmployeIdName(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data2 = {
            value: x.id,
            label: x.first_name + x.last_name + "(" + x.username + ")",
          };
          data.push(data2);
        });
        setAllEmployeName(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleSearch = (e) => {
    getNpsData(
      e.target.value.toLowerCase(),
      npsDataFilter.user_id,
      npsDataFilter.from_date,
      npsDataFilter.to_date,
      currentTab,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      true
    );
    setSearchStr(e.target.value);
  };

  const sendToSAPServer = (data) => {
    let obj = { data: data };
    NPSServices.sendToSAP(obj).then((response) => {
      toast.success(response.data.message);
      getNpsData(
        searchStr,
        npsDataFilter.user_id,
        npsDataFilter.from_date,
        npsDataFilter.to_date,
        currentTab,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true
      );
    });
  };

  const sumbitNpsGrid = (values) => {
    NPSServices.sumbitNpsGrid(values)
      .then((response) => {
        toast.success(response.data.message);
        getNpsData(
          searchStr,
          npsDataFilter.user_id,
          npsDataFilter.from_date,
          npsDataFilter.to_date,
          currentTab,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          true
        );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getNpsData(
        data,
        npsDataFilter.user_id,
        npsDataFilter.from_date,
        npsDataFilter.to_date,
        currentTab,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true
      );
    }
  };

  const getExportDetails = (
    search,
    user_id,
    from_date,
    to_date,
    status,
    sort_by
  ) => {
    dispatch(setDisplayLoader("Display"));
    NPSServices.getExportValue(
      ExportHandleKey(search, user_id, from_date, to_date, status, sort_by)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "NpsReport.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getNpsData(
      searchStr,
      npsDataFilter.user_id,
      npsDataFilter.from_date,
      npsDataFilter.to_date,
      currentTab,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      true
    );
  };

  const handleAllData = (e) => {
    if (e.target.checked === true) {
      let data = [];
      let finalData = npsReportPendingList.pending;
      finalData.map((fd, i) => {
        // fd.status = fd.status === "Y" ? "N" : "Y";
        fd.status = "Y";
        data.push({
          id: fd.id,
        });
      });
      setPendingDataApproval((prev) => ({
        ...prev,
        data: data,
      }));
      setNpsReportPendingList((prev) => ({
        ...prev,
        pending: finalData,
      }));
    } else {
      let finalData = npsReportPendingList.pending;
      finalData.map((fd, i) => {
        // fd.status = fd.status === "Y" ? "N" : "Y";
        fd.status = "N";
      });
      setNpsReportPendingList((prev) => ({
        ...prev,
        pending: finalData,
      }));
      setPendingDataApproval((prev) => ({
        ...prev,
        data: [],
      }));
    }
  };

  const handleBulkData = (pid) => {
    let pdata = {
      id: pid,
    };
    let findData = pendingDataApproval?.data.find((obj) => {
      if (obj.id === pid) {
        return true;
      }
    });
    if (findData === undefined) {
      setPendingDataApproval((prev) => ({
        ...prev,
        data: [...prev.data, pdata],
      }));
    } else {
      let curData = pendingDataApproval?.data
        .filter((obj) => {
          if (obj?.id !== pid) {
            return true;
          } else {
            return false;
          }
        })
        .map((obj) => obj);
      setPendingDataApproval((prev) => ({
        ...prev,
        data: curData,
      }));
    }
    let finalData = npsReportPendingList;
    finalData.pending.map((fd, i) => {
      if (fd.id == pid) {
        fd.status = fd.status === "Y" ? "N" : "Y";
      }
    });
    setNpsReportPendingList(finalData);
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getNpsData(
      searchStr,
      npsDataFilter.user_id,
      npsDataFilter.from_date,
      npsDataFilter.to_date,
      currentTab,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      true
    );
    getSapSchedule();
  }, [currentTab, pageSizeNo]);

  const mainDataFilter = () => {
    setFilterApplied(true);
    getNpsData(
      searchStr,
      npsDataFilter.user_id,
      npsDataFilter.from_date,
      npsDataFilter.to_date,
      currentTab,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      true
    );
    filtersetShow(false);
  };

  const updateValue = (field, j, e, data) => {
    setSubmitFlag(true);
    if (field === "status") {
      let obj = pendingDataApproval?.data;
      obj?.map((obj) => {
        if (obj.id === j) {
          obj.status = e;
          // obj.pran_number = data?.pran_number
          // obj.nps_contribution = data?.nps_contribution
          // obj.effective_date = data?.effective_date
          // obj.user_id = data?.user_id
        }
      });
      setPendingDataApproval((prev) => ({
        ...prev,
        data: obj,
      }));
    } else {
      if (e.status === "Y") {
        let curData = pendingDataApproval?.data?.filter((obj) => {
          if (obj.id === e.id) {
            return false;
          } else {
            return true;
          }
        });
        setPendingDataApproval((prev) => ({
          ...prev,
          data: curData,
        }));
      } else {
        setPendingDataApproval((prev) => ({
          ...prev,
          data: [
            ...prev.data,
            {
              id: e.id,
              status: "Y",
            },
          ],
        }));
      }
      let testData = npsReportPendingList?.pending?.map((obj) => {
        if (obj.id === e.id) {
          obj.status = obj.status === "N" ? "Y" : "N";
          return obj;
        }
        return obj;
      });
      setNpsReportPendingList((prev) => ({
        ...prev,
        pending: testData,
      }));
    }
  };

  const updateEffectiveDate = (flag, id, value, data) => {
    if (!existingId.includes(id)) {
      existingId.push(id)
      let obj = { id: id, date: value }
      effectiveDate.push(obj)
    }
    if (existingId.includes(id)) {
      let data = JSON.parse(JSON.stringify(effectiveDate))
      data?.map((val) => {
        if (val.id === id) {
          val.date = value
        }
      })
      setEffectiveDate(data)
    }
    let copyObj = JSON.parse(JSON.stringify(npsReportPendingList))
    copyObj?.pending?.map((res) => {
      if (res?.id === id) {
        res.effective_date = value
      }
    })
    setNpsReportPendingList(copyObj)
  }

  const handlePranChange = (id, value, data) => {
    if (!existingId.includes(id)) {
      existingId.push(id)
      let obj = { id: id, pran: value }
      effectiveDate.push(obj)
    }
    if (existingId.includes(id)) {
      let data = JSON.parse(JSON.stringify(effectiveDate))
      data?.map((val) => {
        if (val.id === id) {
          val.pran = value
        }
      })
      setEffectiveDate(data)
    }
    let copyObj = JSON.parse(JSON.stringify(npsReportPendingList))
    copyObj?.pending?.map((res) => {
      if (res?.id === id) {
        res.pran_number = value
      }
    })
    setNpsReportPendingList(copyObj)
  }

  return (
    <>
      <div class="row">
        <ToastContainer autoClose={1000} />
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">NPS Report</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                    <Breadcrumb.Item active>NPS Report</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      id="searchbar"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                    <span class="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          class="far fa-times-circle cursor-pointer"
                          onClick={() => {
                            closeButtonCallBack();
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            disabled={
                              npsReportPendingList.length !== 0 ? false : true
                            }
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                "",
                                npsDataFilter.user_id,
                                npsDataFilter.from_date,
                                npsDataFilter.to_date,
                                currentTab,
                                sortData.sort_by
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      type="button"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button
                      class="btn btn-primary-inner"
                      type="submit"
                      onClick={() => onSubmitHandle()}
                    // disabled={!submitFlag}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Submit
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs
                defaultActiveKey="P"
                id="approvaltabs"
                onSelect={(e) => setCurrentTab(e)}
              >
                <Tab
                  eventKey="P"
                  title={
                    <React.Fragment>
                      Pending
                      <span class="numberpurchse">
                        <span class="numberpurchse">{pendingCount}</span>
                      </span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>
                            <label class="logCheck d-inline-block">
                              <input
                                type="checkbox"
                                name="permission"
                                onClick={(e) => {
                                  handleAllData(e);
                                }}
                              />
                              <span class="checkmark"></span>
                            </label>
                          </th>
                          <th>Employee Name.</th>
                          <th>Mobile No</th>
                          <th>PRAN</th>
                          <th>Contribution</th>
                          <th>Effective From</th>
                          <th>Created on</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {npsReportPendingList?.pending?.length ? (
                          npsReportPendingList?.pending?.map((data, index) => (
                            <>
                              <tr>
                                <td>
                                  <label class="logCheck d-inline-block">
                                    <input
                                      type="checkbox"
                                      name="permission"
                                      checked={data?.status == "Y"}
                                      onClick={(e) => {
                                        updateValue("id", index, data);
                                        // handleBulkData(data?.id)
                                      }}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </td>
                                <td class="text-theme fw-bold">
                                  {/* <Link to="/viewjv" class="text-theme"> */}
                                  {data.user_name}({data.emp_code})
                                  {/* </Link> */}
                                </td>
                                {data?.mobile_no !== null ? (
                                  <td>{data.mobile_no}</td>
                                ) : (
                                  <td>Number Unavailable</td>
                                )}
                                <td>
                                  <input
                                    onInput={(e) => {
                                      (e.target.value = e.target.value.slice(
                                        0,
                                        12
                                      ))
                                      handlePranChange(
                                        data.id,
                                        e.target.value,
                                        data
                                      )
                                    }
                                    }
                                    type="number"
                                    value={data.pran_number}
                                  />
                                </td>
                                <td>{data.nps_contribution}</td>
                                {data?.effective_date !== null ? (
                                  <input
                                    type="date"
                                    class="form-control"
                                    name="effective_date"
                                    id="effective_date"
                                    // style={{ width: "100px" }}
                                    onChange={(e) => {
                                      updateEffectiveDate(
                                        "effective_date",
                                        data.id,
                                        e.target.value,
                                        data
                                      );
                                    }}
                                    value={data.effective_date}
                                  />
                                  // <td>
                                  //   {moment(data.effective_date).format(
                                  //     "DD-MM-YYYY"
                                  //   )}
                                  // </td>
                                ) : (
                                  <td></td>
                                )}
                                <td>{moment(data?.created_at).format("DD-MM-YYYY")}</td>
                                <td>
                                  <select
                                    class="px-1"
                                    style={{ width: "100px" }}
                                    id="status"
                                    name="status"
                                    onChange={(e) => {
                                      updateValue(
                                        "status",
                                        data.id,
                                        e.target.value,
                                        data
                                      );
                                    }}
                                  // value={mySubmitData ? mySubmitData[index].status :""}
                                  >
                                    <option value="">Select</option>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                  </select>
                                </td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {npsReportPendingList?.length === 0 ? (
                    ""
                  ) : (
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="sortinglist">
                          Show
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                            <option value={500} label={500}>
                              500
                            </option>
                            <option value={1000} label={1000}>
                              1000
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                  {/* </div> */}
                </Tab>

                <Tab
                  eventKey="A"
                  title={
                    <React.Fragment>
                      Send to SAP
                      <span class="numberpurchse">{sapCount}</span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Employee Name</th>
                          <th>Mobile No</th>
                          <th>PRAN</th>
                          <th>Contribution</th>
                          <th>Effective From</th>
                          <th>SAP Status</th>
                          <th>SAP Response</th>
                          <th>Created On</th>
                          <th>SAP Sync Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sendToSap?.length ? (
                          sendToSap?.map((dataSap, index) => (
                            <tr>
                              <td class="text-theme fw-bold">
                                {/* <Link to="/viewjv" class="text-theme"> */}
                                {dataSap.user_name}({dataSap.emp_code})
                                {/* </Link> */}
                              </td>
                              {dataSap?.mobile_no !== null ? (
                                <td>{dataSap?.mobile_no}</td>
                              ) : (
                                <td>Number Unavailable</td>
                              )}
                              <td>{dataSap.pran_number}</td>
                              <td>{dataSap.nps_contribution}</td>
                              {dataSap?.effective_date !== null ? (
                                <td>
                                  {moment(dataSap.effective_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                              ) : (
                                <td></td>
                              )}
                              {dataSap?.sap_status === true ? (
                                <td>Synced</td>
                              ) : (
                                <td>Pending to Sync</td>
                              )}
                              <td>{dataSap?.sap_error}</td>
                              <td>{moment(dataSap?.created_at).format("DD-MM-YYYY")}</td>
                              {dataSap?.sync_date !== null ?
                                <td>{moment(dataSap?.sync_date).format("DD-MM-YYYY")}</td>
                                :
                                <td></td>
                              }
                              <td>{dataSap.sent_to_sap}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {sendToSap?.send_to_sap?.length === 0 ? (
                    ""
                  ) : (
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="sortinglist">
                          Show
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </Tab>

                <Tab
                  eventKey="N"
                  title={
                    <React.Fragment>
                      Discarded
                      <span class="numberpurchse">{rejectCount}</span>
                    </React.Fragment>
                  }
                  onclick
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Employee No.</th>
                          <th>Mobile No</th>
                          <th>PRAN</th>
                          <th>Contribution</th>
                          <th>Effective From</th>
                          <th>Created On</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rejectList?.length ? (
                          rejectList?.map((rejectedData, index) => (
                            <tr>
                              <td class="">
                                {/* <Link to="/viewjv" class="text-theme"> */}
                                {rejectedData.user_name}({rejectedData.emp_code}
                                ){/* </Link> */}
                              </td>
                              {rejectedData?.mobile_no !== null ? (
                                <td>{rejectedData?.mobile_no}</td>
                              ) : (
                                <td>Number Unavailable</td>
                              )}
                              <td>{rejectedData.pran_number}</td>
                              <td>{rejectedData.nps_contribution}</td>
                              {rejectedData?.effective_date !== null ? (
                                <td>
                                  {moment(rejectedData.effective_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                              ) : (
                                <td></td>
                              )}
                              <td>{moment(rejectedData?.created_at).format("DD-MM-YYYY")}</td>
                              <td>{rejectedData.sent_to_sap}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {rejectList?.length === 0 ? (
                    ""
                  ) : (
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="sortinglist">
                          Show
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Effective From Date<span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              value={npsDataFilter.from_date}
              onChange={(e) => {
                setNpsDataFilter({
                  ...npsDataFilter,
                  from_date: e.target.value,
                });
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              value={npsDataFilter.to_date}
              onChange={(e) => {
                setNpsDataFilter({
                  ...npsDataFilter,
                  to_date: e.target.value,
                });
              }}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Employee ID<span class="text-danger"></span>
            </label>
            <Select
              options={allEmployeName}
              className="basic-multi-select"
              id="select-box"
              name="username"
              classNamePrefix="select"
              onChange={(e) => {
                setNpsDataFilter({
                  ...npsDataFilter,
                  user_id: e.value,
                });
                setFilterUser(e);
                setCurrentPage(1);
              }}
              value={filterUser}
            />
          </div>

          {/* <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect" onChange={(e) => {setNpsDataFilter({
                  ...npsDataFilter,
                  status: e.target.value,
                })}}>
              <option>Select</option>
              <option >Pending</option>
              <option>SAP Status</option>
              <option>Discarded</option>
            </select>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="button"
            onClick={mainDataFilter}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NpsReport;
