import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import CustomMenu from "./CustomMenu";
import CustomPagination from "./CustomPagination";
import CustomBreadcrumb from "./CustomBreadcrumb";

function MaterialExtension() {

  const CustomBreadcrumbItems = [
    { to: '/home', title: 'Home' },
    { to: '/', title: 'Transactions' },
    { active: true, title: 'Material Extension' }
  ]

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <CustomMenu />
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Material Extension</h4>
                  <CustomBreadcrumb items={CustomBreadcrumbItems} />
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <i class="fas fa-print"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-alt"></i>CSV
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-pdf"></i>Pdf
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-copy"></i>Copy
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Link to="/addnewmaterialext" class="btn btn-primary-inner">
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Mat. Desc.</th>
                    <th>Plant</th>
                    <th>Mat. Group</th>
                    <th>Mat. Type</th>
                    <th>Mat. Unit</th>
                    <th>Mat. No.</th>
                    <th>Created Date</th>
                    <th>Status</th>
                    <th>Pending/Rejected By</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="text-theme fw-bold">94001987 - CD MEDIA DVD</td>
                    <td>SR02</td>
                    <td>001</td>
                    <td>ERSA</td>
                    <td>NO</td>
                    <td>94001987</td>
                    <td>2023-02-11</td>
                    <td>
                      <span class="badge bad-status badge-warning">
                        Pending
                      </span>
                    </td>
                    <td>Narendra Kumar Porwal</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="text-theme fw-bold">94001987 - CD MEDIA DVD</td>
                    <td>SR02</td>
                    <td>001</td>
                    <td>ERSA</td>
                    <td>NO</td>
                    <td>94001987</td>
                    <td>2023-02-11</td>
                    <td>
                      <span class="badge bad-status badge-success">
                        Approved
                      </span>
                    </td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="text-theme fw-bold">94001987 - CD MEDIA DVD</td>
                    <td>SR02</td>
                    <td>001</td>
                    <td>ERSA</td>
                    <td>NO</td>
                    <td>94001987</td>
                    <td>2023-02-11</td>
                    <td>
                      <span class="badge bad-status badge-danger">
                        Rejected
                      </span>
                    </td>
                    <td>Narendra Kumar Porwal</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="text-theme fw-bold">94001987 - CD MEDIA DVD</td>
                    <td>SR02</td>
                    <td>001</td>
                    <td>ERSA</td>
                    <td>NO</td>
                    <td>94001987</td>
                    <td>2023-02-11</td>
                    <td>
                      <span class="badge bad-status badge-warning">
                        Pending
                      </span>
                    </td>
                    <td>Narendra Kumar Porwal</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="text-theme fw-bold">94001987 - CD MEDIA DVD</td>
                    <td>SR02</td>
                    <td>001</td>
                    <td>ERSA</td>
                    <td>NO</td>
                    <td>94001987</td>
                    <td>2023-02-11</td>
                    <td>
                      <span class="badge bad-status badge-warning">
                        Pending
                      </span>
                    </td>
                    <td>Narendra Kumar Porwal</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <CustomPagination />

          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Material Description<span class="text-danger">*</span>
            </label>
            <input class="form-control" placeholder="Enter Description" />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Plant<span class="text-danger">*</span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>Approved</option>
              <option>Declined</option>
              <option>Pending</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MaterialExtension;
