import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  changeReimbursementStatus,
  getSelectedData,
} from "./claimReimbServices";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Spinner } from "react-bootstrap";

function ReimbursementApproval() {
  const location = useLocation();
  let pathname = location.pathname.split("/")[2];

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, seterror] = useState(false);
  const [status, setStatus] = useState({
    status: "",
    remarks: "",
  });
  const [curType, setCurType] = useState("");

  useEffect(() => {
    switch (pathname) {
      case "Mobile":
        setCurType("1");
        getReimbursementData("1", location?.state?.from);
        break;
      case "Marriage":
        setCurType("2");
        getReimbursementData("2", location?.state?.from);
        break;

      case "Notice":
        setCurType("3");
        getReimbursementData("3", location?.state?.from);
        break;

      default:
        break;
    }
  }, [pathname]);

  const getReimbursementData = (type, id) => {
    getSelectedData(type, id).then((response) => {
      if (response?.status === 200) {
        setData(response?.data?.dataList);
      }
    });
  };
  const submitAction = (id, stat) => {
    let data = status;
    let idlist = [id];
    data.status = stat;
    data.id = idlist;
    data.reimb_type = curType;
    if (data.remarks !== "" || data.status === "A") {
      setIsLoading(true);
      changeReimbursementStatus(data).then((response) => {
        if (response?.status === 200) {
          setData({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        }
      });
    } else {
      seterror(true);
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link>{location?.state?.type}</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">
                    Approve/Reject {location?.state?.type}
                  </h3>
                </div>
              </div>
              <div class="row viewformaftersubmit">
                {location?.state?.type === "Mobile" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{data?.created_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requested At</label>
                        <p>
                          {moment(data?.created_at).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Mobile Entitlement</label>
                        <p>{data?.mobile_entitlement}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Manufacturer</label>
                        <p>{data?.manufacturer}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>OS Version</label>
                        <p>{data?.os_ver}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>IMEI Number</label>
                        <p>{data?.imei_no}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Company Mobile</label>
                        <p>{data?.company_mobile}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Bill No.</label>
                        <p>{data?.bill_no}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Bill Date</label>
                        <p>{moment(data?.bill_date).format("DD-MM-YYYY")}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Amount</label>
                        <p>{data?.amount}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Particulars</label>
                        <p>{data?.particulars}</p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group innergroup">
                        <label>Download Attachments</label>
                        <p>
                          {data?.attachment !== undefined &&
                            data?.attachment.map((a) => (
                              <a
                                href={a?.attachment_path}
                                target="_blank"
                                rel="noreferrer"
                                zIndex="100"
                              >
                                <span class="mr-3">
                                  {a?.attachment_path
                                    .split("/")
                                    .pop()
                                    .includes(".pdf") ? (
                                    <img
                                      src={"/images/policy-icon/pdf.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  ) : a?.attachment_path
                                      .split("/")
                                      .pop()
                                      .includes(".mp4") ? (
                                    <img
                                      src={"/images/policy-icon/video.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  ) : (
                                    <img
                                      src={"/images/policy-icon/img.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  )}
                                </span>
                              </a>
                            ))}
                        </p>
                      </div>
                    </div>
                  </>
                ) : location?.state?.type === "Marriage" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{data?.created_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requested At</label>
                        <p>
                          {moment(data?.created_at).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Marriage Entitlement</label>
                        <p>{data?.marriage_entitlement}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Marriage For</label>
                        <p>{data?.marriage_for}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Marriage Date</label>
                        <p>
                          {moment(data?.marriage_date).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>First Name</label>
                        <p>{data?.first_name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Last Name</label>
                        <p>{data?.last_name}</p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group innergroup">
                        <label>Download Attachments</label>
                        <p>
                          {data?.attachment !== undefined &&
                            data?.attachment.map((a) => (
                              <a
                                href={a?.attachment_path}
                                target="_blank"
                                rel="noreferrer"
                                zIndex="100"
                              >
                                <span class="mr-3">
                                  {a?.attachment_path
                                    .split("/")
                                    .pop()
                                    .includes(".pdf") ? (
                                    <img
                                      src={"/images/policy-icon/pdf.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  ) : a?.attachment_path
                                      .split("/")
                                      .pop()
                                      .includes(".mp4") ? (
                                    <img
                                      src={"/images/policy-icon/video.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  ) : (
                                    <img
                                      src={"/images/policy-icon/img.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  )}
                                </span>
                              </a>
                            ))}
                        </p>
                      </div>
                    </div>
                  </>
                ) : location?.state.type === "Notice" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{data?.created_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requested At</label>
                        <p>
                          {moment(data?.created_at).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Department</label>
                        <p>{data?.department}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Location</label>
                        <p>{data?.location}</p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Amount</label>
                        <p>{data?.amount}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Particulars</label>
                        <p>{data?.particulars}</p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group innergroup">
                        <label>Download Attachments</label>
                        <p>
                          {data?.attachment !== undefined &&
                            data?.attachment.map((a) => (
                              <a
                                href={a.attachment_path}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span class="mr-3">
                                  {a.attachment_path
                                    .split("/")
                                    .pop()
                                    .includes(".pdf") ? (
                                    <iframe
                                      width="100"
                                      height="100"
                                      src={a.attachment_path}
                                    ></iframe>
                                  ) : a.attachment_path
                                      .split("/")
                                      .pop()
                                      .includes(".mp4") ? (
                                    <video controls>
                                      <source
                                        src={a.attachment_path}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <img
                                      src={a.attachment_path}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  )}
                                </span>
                              </a>
                            ))}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {(data?.status == null || data?.status === "P") && (
              <>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label></label>
                    <textarea
                      onChange={(e) => {
                        setStatus((prev) => ({
                          ...prev,
                          remarks: e.target.value,
                        }));
                        seterror(false);
                      }}
                      class="form-control"
                      placeholder="remarks..."
                    ></textarea>
                    {
                      <div hidden={!error} className="small text-danger">
                        Kindly give Remarks
                      </div>
                    }
                    {/* */}
                  </div>
                </div>

                <div class="col-md-12 pb-3">
                  <button
                    class="btn btn-outline-secondary mr-2"
                    onClick={() => {
                      window.history.go(-1);
                      return false;
                    }}
                  >
                    <i class="fas fa-arrow-circle-left"></i> Cancel
                  </button>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          submitAction(data?.id, "D");
                        }}
                        class="btn btn-outline-danger mr-2"
                      >
                        <i class="far fa-times-circle"></i> Reject
                      </button>

                      <button
                        onClick={() => {
                          submitAction(data?.id, "A");
                        }}
                        class="btn btn-primary-inner bpi-main"
                      >
                        <i class="far fa-check-square"></i> Approve
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReimbursementApproval;
