import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { toast } from "react-toastify";
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown, Pagination, ProgressBar, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService"
import * as OrganisationService from "../../service/organisation"
import * as otherExpService from "../../service/otherExpService";

import { useDispatch, useSelector } from "react-redux";

import {
    selectFinalUrlPermission,
    setDisplayLoader,
    selectUserData,
} from "../redux/piDataStore";


function OtherExpBHRReport() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const finalUser = useSelector(selectUserData);
    const [userGroup,setuserGroup] = useState({
        _userGroup:""
    })




    const [show, filtersetShow] = useState(false);
    const [otherExpData, setotherExpData] = useState([]);

    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);
    const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [totalRecord, setTotalRecord] = useState();
    const [searchStr, setSearchStr] = useState("");
    const [travelList, setTravelList] = useState([]);
    const [usersDropdown, setUsersDropdown] = useState([]);
    const [mastGLDropdown, setmastGLDropdown] = useState([]);
    const [orgDropDown, setorgDropDown] = useState([]);
    const [organisation_User, setOrganisation_User] = useState({
        company: "",
        user_id: ""
    });

    const [filterdata, setfilterData] = useState({
        user_id: { value: 0 },
        id: "",
        // organisation:{value: ""},
        from_date: "",
        to_date: "",
        _gl:{value: 0},
        search_by:"T"
      });


    const getOtherExp = (
        status,
        is_export,
        pageNo,
        pageSize,
        sort_by,
        paginate,
        user_id,
        org,
        reqid,
        search,
        
            from_date,
            to_date,
            search_by
    ) => {
        dispatch(setDisplayLoader("Display"));

        if(is_export){


            otherExpService.otherExpBHRExport2(
                // `status=` + status
                // +
                 `&page_no=` + pageNo
                + `&paginate=` + paginate
                + `&page_size=` + pageSize
                +`&sort_by=`+sort_by
                +`&is_export=`+is_export
                +`&user_id=`+(user_id>0?user_id : "")
                +`&organisation=`+(org ?org : "")
                +`&req_id=`+reqid
                +`&search=`+search
                +`&from_date=`+from_date
                +`&to_date=`+to_date
                +`&search_by=`+search_by
                +`&hr_id=`+finalUser?.id
    
            )
                .then((ress) => {
                    // console.log(`otherexp...`,response);
                    dispatch(setDisplayLoader("Hide"));
                    // let data = response?.data?.result;
                    // setVehicleData(response?.data?.dataList?.result);
                    // let ress=null
                    // console.log(`-->`,userGroup);
                    // switch (userGroup._userGroup){
                    //     case "ssc user":
                    //         ress=response?.data?.dataList?.result.filter((data)=>{
                    //             return !data.ssc_user_status
                    //         })
                    //         break
                    //     case "ssc manager":
                    //         ress=response?.data?.dataList?.result.filter((data)=>{
                    //             return data.ssc_user_status==="A" && !data.ssc_manager_status
                    //         })
                    //         break
                    //     default:
                    //         break
    
                    // }
    
    
                   
    
                        const url = window.URL.createObjectURL(new Blob([ress.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "Detail_Report.xlsx"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                
                    toast.success(`Report download successfully`)

    
    
                   
                    // setCurrentPage(ress?.data?.dataList?.paginated_data?.currentPage)                
                    // setPageCount(ress?.data?.dataList?.paginated_data?.totalPages);
                })
                .catch((error) => {
                    dispatch(setDisplayLoader("Hide"));

                    console.log(error.response);
                    console.log(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        "failed"
                    );
                });
            



        }
        else
        otherExpService.otherExpBHRExport(
            // `status=` + status
            // + 
            `&page_no=` + pageNo
            + `&paginate=` + paginate
            + `&page_size=` + pageSize
            +`&sort_by=`+sort_by
            +`&is_export=`+is_export
            +`&user_id=`+(user_id>0?user_id : "")
            +`&organisation=`+(org ?org : "")
            +`&req_id=`+reqid
            +`&search=`+search
            +`&from_date=`+from_date
                +`&to_date=`+to_date
                +`&search_by=`+search_by
                +`&hr_id=`+finalUser?.id

        )
            .then((ress) => {
                // console.log(`otherexp...`,response);
                dispatch(setDisplayLoader("Hide"));
                // let data = response?.data?.result;
                // setVehicleData(response?.data?.dataList?.result);
                // let ress=null
                // console.log(`-->`,userGroup);
                // switch (userGroup._userGroup){
                //     case "ssc user":
                //         ress=response?.data?.dataList?.result.filter((data)=>{
                //             return !data.ssc_user_status
                //         })
                //         break
                //     case "ssc manager":
                //         ress=response?.data?.dataList?.result.filter((data)=>{
                //             return data.ssc_user_status==="A" && !data.ssc_manager_status
                //         })
                //         break
                //     default:
                //         break

                // }


                setotherExpData(ress?.data?.dataList?.result);
                    console.log(`log..`,ress);
                
                
                setCurrentPage(ress?.data?.dataList?.paginated_data?.currentPage)                
                setPageCount(ress?.data?.dataList?.paginated_data?.totalPages);
            })
            .catch((error) => {
                console.log(error.response);
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };



    const handlePageClick = (action) => {
        let activePage = action.selected + 1;
        setCurrentPage(activePage);
        getOtherExp("A",false,
          activePage,
          pageSizeNo,
          "-id",
          true,
          filterdata?.user_id?.value,"",filterdata?.id,searchStr,filterdata?.from_date,
          filterdata?.to_date,
          filterdata?.search_by
        );
        
      };

    useEffect(() => {

        

        let localGroup=""

        finalUser.groups.every(
            (data) =>
              {
                if(data?.name.toLowerCase() === "ssc user" || data?.name.toLowerCase() === "ssc manager"){
                    localGroup=data?.name.toLowerCase()
                    console.log(`-->`,localGroup);
                    userGroup._userGroup=localGroup
                    return false
                }
                return true
              }
          )
        getOtherExp(
            "A",
            false,
            currentPage,
            pageSizeNo,
            "-id",
            true,
            "",
            "",
            "",
            "","","",""
        )



        getUsersList()
        // getAllOrganisation()
        getBusinessUnits()

        getGLmaster()
        
        
    }, [])

    useEffect(()=>{


        getOtherExp("A",false,
          1,
          pageSizeNo,
          "-id",
          true,
          filterdata?.user_id?.value, filterdata?._gl.value,
          filterdata?.id,searchStr,filterdata?.from_date,
          filterdata?.to_date,
          filterdata?.search_by
        );



    },[pageSizeNo])


    const handleSearch = (e) => {

        if (e.target.value.length > 2 || e.target.value.length === 0) {

            getOtherExp("A",false,
            1,
            pageSizeNo,
            "-id",
            true,
            filterdata?.user_id.value,
            filterdata?._gl.value,
            filterdata?.id,
            e.target.value,
            filterdata?.from_date,
            filterdata?.to_date,
            filterdata?.search_by
            );

           


            setSearchStr(e.target.value);
        }
    };




    const mainDataFilter = () => {
        console.log(" filterdata:", filterdata);

        getOtherExp("A",false,
            currentPage,
            pageSizeNo,
            "-id",
            true,
            filterdata?.user_id.value,
            filterdata?._gl.value,
            filterdata?.id,
            searchStr,
            filterdata?.from_date,
            filterdata?.to_date,
            filterdata?.search_by
            );


        filterclose();
    };


    const clearfilter = () => {
        filterclose();
        getOtherExp("A", false,currentPage, pageSizeNo, "-id", true, "", "", "", searchStr,
        "",
        "",
        "",
        );

        setfilterData({
            organisation:{ value: "" },
            id:"",
            user_id: { value: 0 },
            from_date: "",
            to_date: "",
            _gl:{ value: 0 },
            search_by:"",
          });
    };


    const getUsersList = () => {
        UserService.getUsersListDataNew(0).then((res) => {
            // setApproverList(res?.data?.dataList?.result);
            const data = [
                { value: null, label: "--select--" },
                ...res?.data?.dataList?.result?.map((x) => {
                    return {
                        value: x.username,
                        label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
                    };
                }),
            ];
            setUsersDropdown(data);





        });
    };

    const getAllOrganisation = () => {
        OrganisationService.getAllOrganisation(false).then((res) => {
            // setApproverList(res?.data?.dataList?.result);
            const data = [
                { value: null, label: "--select--" },
                ...res?.data?.dataList?.result?.map((x) => {
                    return {
                        value: x.code,
                        label: `${x?.name} ( ${x.code} )`,
                    };
                }),
            ];
            setorgDropDown(data);





        });
    };

    const getGLmaster=()=>{

        OrganisationService.getGLmaster().then((res)=>{

            const data = [
                { value: null, label: "--select--" },
                ...res?.data?.dataList?.result?.map((x) => {
                    return {
                        value: x.id,
                        label: `${x.gl_account} ${x.short_desc}`,
                    };
                }),
            ];
            

            setmastGLDropdown(data)

        }).catch((err)=>{

            console.log(err);
        })
    }


    const otherExpExport = async (
        search,
        from_date,
        to_date,
        sort_by
        
    )=>{

        otherExpService.otherExpExport(
            'search='+search
            +'&from_date='+from_date
            +'&to_date='+to_date
            +'&sort_by='+sort_by
        ).then((resp)=>{

            const url = window.URL.createObjectURL(new Blob([resp.data.dataList.result]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "OtherExpenseExpense.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            
            toast.success(`export success`)
            console.log(`export`,resp);
            
        }).catch((err)=>{
            console.log(err);
            toast.error(`Unable to export`)
        })
        

    }

    const getBusinessUnits = () => {
        otherExpService.getBusinessUnits().then((res)=>{  
        // setApproverList(res?.data?.dataList?.result);
          const data = [
            { value: null, label: "--select--" },
            ...res?.data?.dataList?.result?.map((x) => {
              return {
                value: x.code,
                label: `${x?.name}( ${x.code} )`,
              };
            }),
          ];
          setorgDropDown(data);
        });
      };

    
      const [shows, setShow] = useState(false);
  const [fileObj, setFileObj] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileView = (data) =>{
    setFileObj(data)
    handleShow()
}

    const getChcekExpenseList = (
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
    ) => {
        TravelListingAPI.getCheckExpenseList(
            handleKey(
                search,
                page_no,
                page_size,
                sort_by,
                paginate,
                status,
            )
        ).then((response) => {
            setTravelList(response?.data?.dataList?.result);
            setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
            setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
        })
    }

    const handleKey = (
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
    ) => {
        let queryParm =
            "page_no=" +
            page_no +
            "&page_size=" +
            page_size +
            "&paginate=" +
            paginate;
        if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
            queryParm = queryParm + "&sort_by=" + sort_by;
        }
        if (search !== "" && search !== undefined && search !== null) {
            queryParm = queryParm + "&search=" + search;
        }
        if (status !== "" && status !== undefined && status !== null) {
            queryParm = queryParm + "&status=" + status;
        }
        return queryParm;
    };

    const getExportValue = (search, page_no, page_Size, sort_by, paginate, status) => {
        // dispatch(setDisplayLoader("Display"));
        TravelListingAPI.getCheckExpenseExportValue(
            handleKey(search, page_no, page_Size, sort_by, paginate, status)
        )
            .then((response) => {
                // dispatch(setDisplayLoader("Hide"));
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "CheckExpense.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };


    return (
        <>
            <div class="container-fluid">

                <div class="row">
                    {/* <div class="col-md-3">
                                    <ul class="policylistnames">
                                        <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                        <li class="active"><Link to="/travel-expense">Check Expense</Link></li>
                                        <li><Link to="/travel-bills">All Bills</Link></li>
                                        <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                        <li><Link to="/travel-km-report">KM Report</Link></li>
                                        <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                        <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                        <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                        <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                        <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                        <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                    </ul>
                                </div> */}

                    <div class="col-md-12">
                        <div class="content-wrapper-inner content-wrapper-inner2">
                            <div class="innerheadsec">
                                <div className="row">
                                    <div class="col-md-12">
                                        <h4 class="inner-page-title">BHR Other Expense</h4>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="border-top mt-2 mt-md-4 mb-3"></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-lg-4">

                                    <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip
                            className="bs-tooltip-end tooltip"
                            id="overlay-example"
                          >
                            <div class="tooltip-inner text-left">
                              Search on{" "}
                              <strong> Name, Employee ID, GL Code</strong>
                            </div>
                          </Tooltip>
                        }
                      >

<div class="input-group table-search">
                                            <span class="input-group-text"><img src="images/search.png" alt="" /></span>
                                            <input type="text"
                                                class="form-control"
                                                placeholder="Search..."
                                                onChange={(e) => {
                                                    handleSearch(e);
                                                }}
                                            />
                                        </div>
                         
                      </OverlayTrigger>
                                        
                                    </div>
                                    <div class="col-lg-8">
                                                    <div class="inline-spacing btn-betweenspaing">
                                                        <Dropdown show>
                                                            <Dropdown.Toggle variant="primary-inner dropdownbtn" id="dropdown-basic">
                                                                <img src="images/export.png" alt="" class="mr-2" /> Export
                                                            </Dropdown.Toggle>
    
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item >
                                                                <button
                                                                type="button"
                                                                // disabled={travelList.length !== 0 ? false : true}
                                                                style={{ border: 0, background: "none" }}
                                                                onClick={() =>
                                                                    // getExportValue(searchStr, currentPage, pageSizeNo, sortData.sort_by, true, "pending", )
                                                                    // otherExpExport(searchStr,filterdata.from_date,filterdata.to_date,"id")
                                                                    getOtherExp(
                                                                        "A",    
                                                                         true,   
                                                                          currentPage,   
                                                                          pageSizeNo,    
                                                                         "-id",    
                                                                         true, 
                                                                           filterdata?.user_id.value,
                                                                        filterdata?._gl.value,
                                                                        filterdata?.id,
                                                                        searchStr,
                                                                        filterdata?.from_date,
                                                                        filterdata?.to_date,
                                                                        filterdata?.search_by )
                                                        
                                                                }
                                                                >
                                                                <i className="far fa-file-excel"></i>Excel
                                                                </button>
                                                                    </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <button onClick={filteropen} class="btn btn-secondary-inner"><img src="images/filter.png" alt="" class="mr-3" />Filter</button> 
                                                    </div>
                                                </div>
                                </div>
                            </div>

                            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                                <Tabs defaultActiveKey="draft" id="approvaltabs">

                                    <Tab eventKey="draft"
                                        title={""
                                            // <React.Fragment>
                                            //     In Process
                                            //     <span class="numberpurchse">{totalRecord}</span>
                                            // </React.Fragment>
                                        }
                                    >
                                        <div class="table-responsive">
                                            <table class="table table-striped table-bordered tablecured">
                                                <thead>
                                                    <tr>

                                                    
                                                    <th>Sr.No</th>
                                                    <th>Employee ID</th>
                                                    <th>Name</th>
                                                    <th>Gl Code</th>
                                                    <th>Req. ID</th>
                                                    <th>Claim Date</th>
                                                    <th>Claim Amt.</th>
                                                    <th>Approved On</th>
                                                    <th>Doc. No.</th>
                                                    <th>Bill No</th>
                                                    <th>Bill Date</th>
                                                    <th>Reason For Expense</th>
                                                    <th>Month Of</th>
                                                    <th>Vendor Code</th>
                                                    <th>Grade</th>
                                                    <th>Reporting Head</th>
                                                    <th>Location</th>
                                                    <th>Zone
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {otherExpData?.length ?
                                                        otherExpData?.map((data, index) => {
                                                            return (
                                                                <tr className="text-center"
                                                                style={{
                                                                    backgroundColor:
                                                                      data?.chat_status > 0
                                                                        ? "yellow"
                                                                        : (data?.send_back_remark||data?.send_back_ssc_remark)
                                                                        ? "#FFBF00"
                                                                        : data?.is_exception
                                                                        ? "#FFA895"
                                                                        : "",
                                                                  }}  
                                                                
                                                                >
                                                                    <td>{data['Sr.No']}</td>
                                                                    <td>
                                                                    {data?.attachment?.length >0 ?
                                                                    <Link  onClick={()=>{handleFileView(data?.attachment)}}>
                                                                    <button class="btn btn-blanktd text-primary">
                                                                    {data["user_code"]}
                                                                    </button>
                                                                    </Link>
                                                                    :data["user_code"]}
                                                                    </td>
                                                                    <td>{data?.username}</td>
                                                                    <td data-toggle="tooltip" data-placement="bottom" data-html="true" title={data?.gl_desc}>{data?.gl_account} {data?.gl_desc}</td>
                                                                    <td>
                                                                    {data["Request ID"]?data["Request ID"]:(
                                                                        data?.user_organisation
                                                                        +(data?.expense_type?.toLowerCase() == "petty"
                                                                        ? `PE`
                                                                        : `OE`
                                                                        +data?.id))}
                                                                        
                                                                        </td>
                                                                    <td>{data?.created_at && moment.parseZone(data?.created_at)?.format("DD-MM-YYYY")} </td>
                                                                    <td>{data?.bill_amount?.toFixed(2)}</td>
                                                                    <td>{data?.ssc_manager_approved_at && moment.parseZone(data?.ssc_manager_approved_at).format("DD-MM-YYYY")}</td>
                                                                    <td>{data?.sap_doc_no}</td>
                                                                    <td>{data?.bill_number}</td>
                                                                    <td>{data?.bill_date && moment.parseZone(data?.bill_date)?.format("DD-MM-YYYY")} </td>
                                                                    <td>{data?.reason_for_expense} </td>
                                                                    <td>{data?.for_month_of} </td>
                                                                    
                                                                    <td>{data?.user_vendor_code}</td>
                                                                    <td>{data["Emp Grade"]}</td>
                                                                    <td>{data?.reporting_user_name}</td>
                                                                    <td>{data["Location (HQ)"]}</td>
                                                                    <td>{data?.sales_dist}</td>
                                                                    
                                                                    {/* <td>{data?.machine_no} {data?.travel_by}</td> */}
                                                                    {/* <td>{data?.approved_amount?.toFixed(2)}</td> */}
                                                                    {/* <td>{data?.is_picked_at && moment.parseZone(data?.is_picked_at)?.format("DD-MM-YYYY")}</td> */}
                                                                    {/* <td>{data?.ssc_username}</td> */}
                                                                    {/* <td>{data?.status}</td> */}
                                                                    {/* <td>{data?.discard_reason} </td> */}
                                                                    
                                                                    
                                                                </tr>
                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={12}>No Records Found...</td>
                                                        </tr>
                                                    }
                                                    <Modal 
                           className="modaldefaultclose modaldefaultclosecenter"
                          backdrop="static"  show={shows} onHide={handleClose}>
                                        <Modal.Header 
                                        // className="bg-primary text-white" 
                                        closeButton>
                                          <Modal.Title  >View attachments</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <div class="table-responsive mb-3">
                                                    
                                                            <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                                                            <thead>
                                                                <tr>
                                                              
                                                                <th>EXPENSE TYPE</th>
                                                                <th>FILE NAME	</th>
                                                                <th>VIEW</th>
                                                                
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                          {fileObj?.map((adata,idx)=>(
                                                              <tr>   
                                                                <td>{adata?.expense_type}</td>        
                                                              <td>{adata?.attachment_path?.substring(adata?.attachment_path?.lastIndexOf("/")+1)}</td>
                                                              <td>
                                                                  <Link target="_Blank" to={adata?.attachment_path}>View</Link>
                                                              </td>
                                                              </tr>
                                                              
                                                          ))}
                                                          </tbody>
                                                          </table>
                                                    
                                                      
                                                  
                                                  </div>    
                                        </Modal.Body>
                                        {/* <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                          </Button>
                                        </Modal.Footer> */}
                                      </Modal>
                                                </tbody>
                                            </table>

                                        </div>

                                        <div class="row">
                                            <div class="col-sm-5">
                                                <div class="sortinglist">
                                                    Show
                                                    <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={50} label={50}>
                            50
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                          <option value={200} label={200}>
                            200
                          </option>
                        </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-7">
                                                <ReactPaginate
                                                key={pageSizeNo}
                                                    previousLabel={"<"}
                                                    nextLabel={">"}
                                                    breakLabel={"..."}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={2}
                                                    onPageChange={handlePageClick}
                                                    disableInitialCallback={true}
                                                    containerClassName={"pagination justify-content-left"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                />
                                            </div>
                                        </div>
                                    </Tab>


                                </Tabs>
                            </div>


                        </div>
                    </div>
                </div>

            </div>



            <Modal show={show} onHide={filterclose} className="filter-popup modaldefaultclose">
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <div class="form-group innergroup">
                    <label>
                    Request ID <span class="text-danger"></span>
                    </label>
                    <input
                    type="text"
                    class="form-control"
                    onChange={(e) =>
                        setfilterData({
                        ...filterdata,
                        id: e.currentTarget.value,
                        })
                    }
                    value={filterdata.id}
                    />
                </div>

                            <div class="form-group innergroup">
                        <label>
                        Employee ID <span class="text-danger"></span>
                        </label>
                        <Select
                        options={usersDropdown}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) =>
                            setfilterData({
                            ...filterdata,
                            user_id: e,
                            })
                        }
                        value={filterdata.user_id}
                        />
                    </div>

                    {/* <div class="form-group innergroup">
                        <label>
                        Company <span class="text-danger"></span>
                        </label>
                        <Select
                        options={orgDropDown}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) =>
                            setfilterData({
                            ...filterdata,
                            organisation: e,
                            })
                        }
                        value={filterdata.organisation}
                        />
                    </div> */}

                    <div class="form-group innergroup">
                        <label>
                        GL Code <span class="text-danger"></span>
                        </label>
                        <Select

                         options={mastGLDropdown}
                         className="basic-multi-select"
                         classNamePrefix="select"
                         onChange={(e) =>
                             setfilterData({
                             ...filterdata,
                             _gl: e,
                             })
                         }
                         value={filterdata._gl}

                        />
                       
                    </div>


                    <div class="form-group innergroup">
                        <label>
                        Applied Date From <span class="text-danger"></span>
                        </label>
                        <input
                        type="date"
                        class="form-control"
                        onChange={(e) =>
                            setfilterData({
                            ...filterdata,
                            from_date: e.currentTarget.value,
                            })
                        }
                        value={filterdata.from_date}
                        />
                    </div>

                    <div class="form-group innergroup">
                        <label>
                        Applied Date To <span class="text-danger"></span>
                        </label>
                        <input
                        type="date"
                        class="form-control"
                        onChange={(e) =>
                            setfilterData({
                            ...filterdata,
                            to_date: e.currentTarget.value,
                            })
                        }
                        value={filterdata.to_date}
                        />
                    </div>

                    <div class="form-group innergroup">
                        <label>
                        Search By <span class="text-danger"></span>
                        </label>

                        <select
                        class="form-control"                        
                        onChange={(e) =>
                            setfilterData({
                            ...filterdata,
                            search_by: e.currentTarget.value,
                            })
                        }
                        >
                            <option selected={filterdata?.search_by==="T"} value="T">
                                T (Bill Created At Date)
                            </option>
                            <option selected={filterdata?.search_by==="P"} value="P">
                                P (SSC Processed date + Pick List)
                            </option>
                        </select>
                         
                    </div>

                    {/* <div class="form-group innergroup">
                            <label>Trip Number <span class="text-danger"></span></label>
                            <Select  
                                options={options}
                                className="basic-multi-select"
                                classNamePrefix="select" 
                            />
                        </div> */}

                    {/* <div class="form-group innergroup">
                        <label>
                            Start Date <span class="text-danger"></span>
                        </label>
                        <input type="date" class="form-control"
                            onChange={(e) => setfilterData({
                                ...filterdata,
                                from_date: e.currentTarget.value
                            })}
                            value={filterdata.from_date}
                        />
                    </div>

                    <div class="form-group innergroup">
                        <label>
                            End Date <span class="text-danger"></span>
                        </label>
                        <input type="date" class="form-control"
                            onChange={(e) => setfilterData({
                                ...filterdata,
                                to_date: e.currentTarget.value

                            })}
                            value={filterdata.to_date}
                        />
                    </div> */}

                </Modal.Body>
                <Modal.Footer>
                    <button class="btn btn-outline-danger"
                        onClick={() => clearfilter()}
                    //  onClick={filterclose}
                    >
                        Clear
                    </button>
                    <button class="btn btn-primary-inner bpi-main"
                        onClick={() => mainDataFilter()}
                    >Apply</button>
                </Modal.Footer>
            </Modal>



        </>
    );
}

export default OtherExpBHRReport;
