import React, { useState } from "react";
import {
  Modal,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import CustomMenu from "./CustomMenu";
import CustomPagination from "./CustomPagination";
import CustomBreadcrumb from "./CustomBreadcrumb";

function MatExtApproval() {
  const CustomBreadcrumbItems = [
    { to: '/home', title: 'Home' },
    { to: '/', title: 'Transactions' },
    { active: true, title: 'Material Extension Approval' }
  ]

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <CustomMenu />
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Material Extension Approval</h4>
                  <CustomBreadcrumb items={CustomBreadcrumbItems} />
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div class="col-lg-8"></div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs defaultActiveKey="approval" id="approvaltabs">
                <Tab
                  eventKey="approval"
                  title={
                    <React.Fragment>
                      For Approval
                      <span class="numberpurchse">11</span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>
                            <label class="logCheck d-inline-block">
                              <input type="checkbox" name="permission" />
                              <span class="checkmark"></span>
                            </label>
                          </th>
                          <th>Mat. Desc.</th>
                          <th>Status</th>
                          <th>Plant</th>
                          <th>Mat. Group</th>
                          <th>Mat. Type</th>
                          <th>Mat. Unit</th>
                          <th>Mat. No.</th>
                          <th>Created By</th>
                          <th>Created Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label class="logCheck d-inline-block">
                              <input type="checkbox" name="permission" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-warning">
                              Pending
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Ajitabh Singh</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td>
                            <label class="logCheck d-inline-block">
                              <input type="checkbox" name="permission" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-warning">
                              Pending
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Narendra Kumar Porwal</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td>
                            <label class="logCheck d-inline-block">
                              <input type="checkbox" name="permission" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-warning">
                              Pending
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Ajitabh Singh</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td>
                            <label class="logCheck d-inline-block">
                              <input type="checkbox" name="permission" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-warning">
                              Pending
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Narendra Kumar Porwal</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td>
                            <label class="logCheck d-inline-block">
                              <input type="checkbox" name="permission" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-warning">
                              Pending
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Ajitabh Singh</td>
                          <td>2023-02-11</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <CustomPagination />

                  <div class="row jvactionsbutton">
                    <div class="col-md-12">
                      <div class="p-3">
                        <button class="btn btn-danger mr-2">
                          <i class="far fa-times-circle"></i> Reject
                        </button>
                        <button class="btn btn-success">
                          <i class="far fa-check-circle"></i> Approved
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="approved"
                  title={<React.Fragment>Approved</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>Mat. Desc.</th>
                          <th>Status</th>
                          <th>Plant</th>
                          <th>Mat. Group</th>
                          <th>Mat. Type</th>
                          <th>Mat. Unit</th>
                          <th>Mat. No.</th>
                          <th>Created By</th>
                          <th>Created Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-success">
                              Approved
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Ajitabh Singh</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-success">
                              Approved
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Narendra Kumar Porwal</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-success">
                              Approved
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Ajitabh Singh</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-success">
                              Approved
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Narendra Kumar Porwal</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-success">
                              Approved
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Ajitabh Singh</td>
                          <td>2023-02-11</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <CustomPagination />
                </Tab>

                <Tab
                  eventKey="rejected"
                  title={<React.Fragment>Rejected</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>Mat. Desc.</th>
                          <th>Status</th>
                          <th>Plant</th>
                          <th>Mat. Group</th>
                          <th>Mat. Type</th>
                          <th>Mat. Unit</th>
                          <th>Mat. No.</th>
                          <th>Created By</th>
                          <th>Created Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-danger">
                              Rejected
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Ajitabh Singh</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-danger">
                              Rejected
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Narendra Kumar Porwal</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-danger">
                              Rejected
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Ajitabh Singh</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-danger">
                              Rejected
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Narendra Kumar Porwal</td>
                          <td>2023-02-11</td>
                        </tr>
                        <tr>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>
                            <span class="badge bad-status badge-danger">
                              Rejected
                            </span>
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                          <td>Ajitabh Singh</td>
                          <td>2023-02-11</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <CustomPagination />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              GL Code<span class="text-danger">*</span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Select User<span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Reviewer<span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MatExtApproval;
