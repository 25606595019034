import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import * as vehicleService from "../../service/vehiclelog";
import Select from "react-select";
import * as userGl from "../../service/userGl";

const AssignNewVehicle = (props) => {
  const {
    show,
    onCloseButtonClick,
    edit,
    editVehicleAssignData,
    onAddCallBack,
    assignFlag,
    vehicleAssignValues,
  } = props;

  const [assignData, setAssignData] = useState({
    vehicle_id: 0,
    user_id: 0,
    assign_date: "",
  });
  const [assignUpdate, setAssignUpdate] = useState({
    free_date: "",
    is_free: true,
  });

  const [formValidation, setFormValidation] = useState({
    vehicle_id: "",
    user_id: "",
    assign_date: "",
    free_date: "",
  });
  const [vehicleResNo, setVehicleResNo] = useState();
  const [vehicleResData, setVehicleResData] = useState();
  const [userResData, setUserResData] = useState();
  const [allEmployee, setAllEmployee] = useState([]);
  const [validateDuplicateUser, setValidateDuplicateUser] = useState(false);

  const saveVehicleAssignMaster = (data) => {
    vehicleService
      .saveVehicleAssignMaster(data)
      .then((response) => {
        onAddCallBack();
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getUsers = (paginate) => {
    userGl
      .getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.full_name}  (${x?.username})`,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (
    search,
    pageNo,
    is_active,
    page_size,
    sort_by,
    paginate,
    is_assigned,
    user_id
  ) => {
    let queryParm = "paginate=" + paginate + "&is_assigned=" + is_assigned;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (pageNo !== "" && pageNo !== undefined && pageNo !== null) {
      queryParm = queryParm + "&pageNo=" + pageNo;
    }
    if (page_size !== "" && page_size !== undefined && page_size !== null) {
      queryParm = queryParm + "&page_size=" + page_size;
    }
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (is_active !== "" && is_active !== undefined && is_active !== null) {
      queryParm = queryParm + "&is_active=" + is_active;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    return queryParm;
  };

  const getVehicleData = (
    search,
    pageNo,
    is_active,
    page_size,
    sort_by,
    paginate,
    is_assigned,
    user_id
  ) => {
    vehicleService
      .getVehicleData(
        handleKey(
          search,
          pageNo,
          is_active,
          page_size,
          sort_by,
          paginate,
          is_assigned,
          user_id
        )
      )
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj, index) => {
          return {
            value: obj.id,
            label: `${obj?.vehicle_no} (${obj?.name})`,
          };
        });

        setVehicleResNo(res);
      })
      .catch((error) => {
        if (error.response?.data?.code == 404) {
          toast.error(error.response?.data?.message);
          setVehicleResNo();
        }
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateVehicleAssignMaster = (id, data) => {
    if (!assignFlag) {
      data["is_free"] = false;
      data["free_date"] = null;
    } else {
      data["free_date"] = assignUpdate?.free_date;
      data["is_free"] = assignUpdate.is_free;
    }
    vehicleService
      .updateVehicleAssignMaster(id, data)
      .then((response) => {
        onAddCallBack();
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const validateRecords = () => {
    let isError = false;

    const valid = { ...formValidation };
    if (!assignData.vehicle_id) {
      isError = true;
      valid.vehicle_id = "*Vehicle Id is required ";
      setFormValidation(valid);
    }
    if (!assignData.user_id) {
      isError = true;
      valid.user_id = "*User Id is required ";
      setFormValidation(valid);
    }
    if (!assignData.assign_date) {
      isError = true;
      valid.assign_date = "*Assign date is required ";
      setFormValidation(valid);
    }
    if (!assignUpdate.free_date && assignFlag == true) {
      isError = true;
      valid.free_date = "*Free date is required ";
      setFormValidation(valid);
    }
    return isError;
  };

  const onPushVehicle = (e) => {
    setVehicleResData(e);
    setAssignData((prev) => ({
      ...prev,
      vehicle_id: e.value,
    }));
  };
  const onPushUsers = (e) => {
    setUserResData(e);
    getVehicleData("", "", "true", "", "", "false", "true", e.value);
    setAssignData((prev) => ({
      ...prev,
      user_id: e.value,
    }));
  };

  const handleSubmit = () => {
    if (validateRecords()) return false;
    if (assignData?.id == undefined) {
      saveVehicleAssignMaster(assignData);
    } else {
      updateVehicleAssignMaster(assignData?.id, assignData);
    }
  };

  useEffect(() => {
    if (editVehicleAssignData) {
      setAssignData(editVehicleAssignData);
      let userIdsData = {
        value: editVehicleAssignData.user_id,
        label: `${editVehicleAssignData?.user_name} (${editVehicleAssignData?.user_code})`,
      };
      setUserResData(userIdsData);
      let vehicleIdsData = {
        value: editVehicleAssignData.vehicle_id,
        label: `${editVehicleAssignData?.vehicle_no}`,
      };
      setVehicleResData(vehicleIdsData);
      setAssignUpdate({
        ...assignUpdate,
        free_date: editVehicleAssignData?.free_date,
      });
      Object.keys(editVehicleAssignData).forEach((item) => {
        assignData[item] = editVehicleAssignData[item];
      });
    }
  }, [editVehicleAssignData]);

  useEffect(() => {
    // getVehicleData("", "", "", "", "", "false", "true");
    getUsers(false);
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={onCloseButtonClick}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {edit === true ? "Edit" : "Add"} New Assign Vehicle
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="row justify-content-center">
            <ToastContainer autoClose={1000} />
            <div class="col-md-11">
              <div class="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      User Id
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={allEmployee}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="user_id"
                      onChange={(e) => {
                        onPushUsers(e);
                        setFormValidation({
                          ...formValidation,
                          user_id: "",
                        });
                      }}
                      value={userResData}
                    />
                    <div className="small text-danger">
                      {formValidation.user_id}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Vehicle</label>
                    <span className="text-danger">*</span>
                    <Select
                      options={vehicleResNo}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="vehicle_id"
                      onChange={(e) => {
                        onPushVehicle(e);
                        setFormValidation({
                          ...formValidation,
                          vehicle_id: "",
                        });
                      }}
                      value={vehicleResData}
                    />
                    <div className="small text-danger">
                      {formValidation.vehicle_id}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Assigned Date</label>
                    <span className="text-danger">*</span>
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Enter Vehicle Location"
                      name="assign_date"
                      onChange={(e) => {
                        setAssignData((prev) => ({
                          ...prev,
                          assign_date: e.target.value,
                        }));
                        setFormValidation({
                          ...formValidation,
                          assign_date: "",
                        });
                      }}
                      value={assignData.assign_date}
                    />
                    <div className="small text-danger">
                      {formValidation.assign_date}
                    </div>
                  </div>
                </div>
                {assignFlag && (
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>Vehicle Free Date</label>
                      <span className="text-danger">*</span>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="Enter Vehicle Location"
                        name="free_date"
                        onChange={(e) => {
                          setAssignUpdate((prev) => ({
                            ...prev,
                            free_date: e.target.value,
                          }));
                          setFormValidation({
                            ...formValidation,
                            free_date: "",
                          });
                        }}
                        value={assignUpdate.free_date}
                      />
                      <div className="small text-danger">
                        {formValidation.free_date}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <>
            <button
              class="btn btn-outline-secondary mr-2"
              onClick={() => onCloseButtonClick()}
            >
              Cancel
            </button>
            <button
              class="btn btn-primary-inner bpi-main"
              type="submit"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignNewVehicle;
