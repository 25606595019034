import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as HSNService from "../../service/hsnService";
import { toast } from "react-toastify";
import * as PrService from "../../service/prService";
import { selectUserData } from "../redux/piDataStore";
import { useSelector } from "react-redux";

const AddPowerUser = (props) => {
  const userData = useSelector(selectUserData);
  const { addnewshow, addnewclose, editData, editFlag,getHsnApproverListing } = props;
  const [powerUserData, setPowerUserData] = useState({
    plant_id: 0,
    material_type_id: 0,
    approver_1: 0,
    approver_2: 0,
  });
  const [error, setError] = useState({
    plant_id: "",
    material_type_id: "",
    approver_1: "",
    approver_2: "",
  });
  const [matType, setMatType] = useState();
  const [plantList, setPlantList] = useState();
  const [plantListValue, setPlantListValue] = useState();
  const [matTypeValue, setMatTypeValue] = useState();
  const [approver1, setApprover1] = useState();
  const [approver2, setApprover2] = useState();
  const [approversList, setApproversList] = useState([]);

  const getMaterialTypePlant = () => {
    HSNService.getMaterialTypePlantFullList()
      .then((response) => {
        let matData = response?.data?.dataList?.result?.map(
          (item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }
        );
        setMatType(matData);
        let data = response?.data?.dataList?.result?.plant?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
          };
        });
        data.unshift({ value: "", label: "Select" });
        // setPlantList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getPlants = () => {
    PrService.getPlantsList({ organisation_id: userData?.organisation_id })
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: item.code + "(" + item?.description + ")",
          }));
          setPlantList(options);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getApproverList = () => {
    HSNService.getHsnApprover().then((res) => {
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: x.full_name + "(" + x.username + ")",
          };
        }),
      ];
      setApproversList(data);
    });
  };

  const saveHsnApprover = (data) => {
    HSNService.saveHsnApprover(data)
      .then((response) => {
        toast.success("Approver Created Successfully")
        getHsnApproverListing()
        // getHsnListData(1, 10);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleChange = (event, name, regex, length) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "material_type_id":
        setPowerUserData((prev) => ({
          ...prev,
          material_type_id: event.value,
        }));
        break;
      case "plant_id":
        setPowerUserData((prev) => ({
          ...prev,
          plant_id: event.value,
        }));
        break;
      case "approver_1":
        setPowerUserData((prev) => ({
          ...prev,
          approver_1: event.value,
        }));
        break;
      case "approver_2":
        setPowerUserData((prev) => ({
          ...prev,
          approver_2: event.value,
        }));
        break;
      default:
        break;
    }
  };

  const onPushMatType = (newValue, name) => {
    handleChange(newValue, name);
    setMatTypeValue(newValue);
  };
  const onPushPlant = (newValue, name) => {
    handleChange(newValue, name);
    setPlantListValue(newValue);
  };
  const onPushApprover1 = (newValue, name) => {
    handleChange(newValue, name);
    setApprover1(newValue);
  };
  const onPushApprover2 = (newValue, name) => {
    handleChange(newValue, name);
    setApprover2(newValue);
  };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...error };
    if (!powerUserData.approver_1) {
      isError = true;
      formerr.approver_1 = "*Approver 1 is required";
      setError(formerr);
    }
    if (!powerUserData.approver_2) {
      isError = true;
      formerr.approver_2 = " *Approver 2 is required";
      setError(formerr);
    }
    if (!powerUserData.material_type_id) {
      isError = true;
      formerr.material_type_id = " *Material Type is required";
      setError(formerr);
    }
    if (!powerUserData.plant_id) {
      isError = true;
      formerr.plant_id = " *Plant is required";
      setError(formerr);
    }
    return isError;
  };

  const handleSaveData = () => {
    if (handleValidate()) return false;
    if (powerUserData.id == undefined) {
      saveHsnApprover(powerUserData);
      //   setHsnData();
    } else {
      saveHsnApprover(powerUserData);
        // updateHsnDataList(powerUserData.id, powerUserData);
    }
    addnewclose();
  };

  useEffect(() => {
    if (editFlag) {
      //   getMaterialList(
      //     editHsnData?.plant_id,
      //     editHsnData?.material_type_name,
      //     1,
      //     10
      //   );
      let data1 = {
        value: editData.plant_id,
        label: editData?.plant_description + "(" + editData?.plant_code + ")",
      };
      let data2 = {
        value: editData.material_type_id,
        label: editData.material_type_name,
      };
      let approver1Data = {
        value: editData.approver1_id,
        label: editData.approver_1,
      };
      let approver2Data = {
        value: editData.approver2_id,
        label: editData.approver_2,
      };
      setPowerUserData((prev) => ({
        ...prev,
        approver_1: editData.approver1_id,
        approver_2: editData.approver2_id,
        plant_id: editData.plant_id,
        material_type_id: editData.material_type_id
      }));
      setApprover1(approver1Data);
      setApprover2(approver2Data);
      setMatTypeValue(data2);
      setPlantListValue(data1);
      Object.keys(editData).forEach((item) => {
        powerUserData[item] = editData[item];
      });
    }
  }, [editFlag]);

  useEffect(() => {
    getMaterialTypePlant();
    getApproverList();
    getPlants()
  }, []);

  return (
    <Modal
      show={addnewshow}
      onHide={addnewclose}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add/Edit Assign Power User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Material Type<span class="text-danger">*</span>
                  </label>
                  <Select
                    options={matType}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="material_type_id"
                    onChange={(newValue, action) => {
                      onPushMatType(newValue, action?.name);
                      setError({ ...error, material_type_id: "" });
                    }}
                    value={matTypeValue}
                  />
                  <div className="small text-danger">
                    {error?.material_type_id}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Plant<span class="text-danger">*</span>
                  </label>
                  <Select
                    options={plantList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="plant_id"
                    onChange={(newValue, action) => {
                      onPushPlant(newValue, action?.name);
                      setError({ ...error, plant_id: "" });
                    }}
                    value={plantListValue}
                  />
                  <div className="small text-danger">{error?.plant_id}</div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Approver 1<span class="text-danger">*</span>
                  </label>
                  <Select
                    options={approversList}
                    name="approver_1"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(newValue, action) => {
                      onPushApprover1(newValue, action?.name);
                      setError({ ...error, approver_1: "" });
                    }}
                    value={approver1}
                  />
                  <div className="small text-danger">{error?.approver_1}</div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Approver 2<span class="text-danger">*</span>
                  </label>
                  <Select
                    options={approversList}
                    name="approver_2"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(newValue, action) => {
                      onPushApprover2(newValue, action?.name);
                      setError({ ...error, approver_2: "" });
                    }}
                    value={approver2}
                  />
                  <div className="small text-danger">{error?.approver_2}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button class="btn btn-outline-danger" onClick={addnewclose}>
          Cancel
        </button>
        <button
          class="btn btn-primary-inner bpi-main"
          onClick={() => handleSaveData()}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPowerUser;
