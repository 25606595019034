import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { selectAudit, selectUserData } from "../redux/piDataStore";
import AuditLog from "../audit/auditlog";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as ActivePoServices from "../../service/activePo";
import * as vendorInvoiceService from "../../service/vendorInvoiceService";
import Select from "react-select";
import * as HeaderService from "../../service/jv_service";
import { getVendorCode } from "../../service/userService";

const AddVendorInvoicing = (props) => {
  const nameForm = "Vendor Invoice";
  let condition = "false";

  const { addnewshow, onClose, onAddCallBack, sscList } = props;
  const dispatch = useDispatch();
  const userId = useSelector(selectUserData);

  const date = new Date();
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let year = date.getFullYear();

  const todayDate = () => {
    mm = (mm + "").length < 2 ? "0" + mm : mm;
    dd = (dd + "").length < 2 ? "0" + dd : dd;
    return `${year}-${mm}-${dd}`;
  };
  const [vendorData, setVendorData] = useState({
    invoice_type: "",
    vendor_id: "",
    invoice_no: "",
    invoice_date: todayDate(),
    bill_amount: "",
    dispatch_date: todayDate(),
    courier_to_id: "",
    courier_no: "",
    invoice_receipt: "",
    status: "",
    grn_number: "",
    ses_number: "",
    company_id: "",
    gl_code_id: undefined,
    cost_center_id: undefined,
  });

  const [formValidation, setFormValidation] = useState({
    invoice_type: "",
    vendor_id: "",
    invoice_no: "",
    invoice_date: "",
    bill_amount: "",
    dispatch_date: "",
    courier_to_id: "",
    invoice_receipt: "",
    status: "",
    grn_number: "",
    ses_number: "",
    company_id: "",
    gl_code_id: "",
    cost_center_id: "",
  });
  const [vendorDataList, setVendorDataList] = useState([]);
  const [vendorId, setVendorId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companyCodeData, setCompanyCodeData] = useState([]);
  const [glCodeList, setGlCodeList] = useState([]);
  const [glCode, setGlCode] = useState([]);
  const [costCenterList, setCostCenterList] = useState([]);
  const [costCenterData, setCostCenterData] = useState([]);
  const [companyCode, setCompanyCode] = useState([]);

  const saveVendorInvoice = (values) => {
    if (condition == "false") {
      condition = "true";
      vendorInvoiceService
        .saveVendorInvoice(values)
        .then((response) => {
          condition = "false";
          onAddCallBack(response);
          toast.success(response.data.message);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const getVendorId = (search) => {
    ActivePoServices.getVendorId(search)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: `${x?.name} (${x?.code})`,
            name: x?.name,
          };
          plant.push(data);
        });
        setVendorId(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getGlCodeList = (id) => {
    HeaderService.getGlCodeList(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.short_desc + "(" + res.gl_account + ")",
          };
        });
        setGlCodeList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCostCenter = (id) => {
    HeaderService.getCostCenter(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.name + "(" + res.code + ")",
          };
        });
        setCostCenterList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCompanyCode = () => {
    HeaderService.getCompanyCode()
      .then((response) => {
        let data = response?.data?.dataList?.result.map((res) => {
          return {
            value: res.id,
            label: res.name + "(" + res.code + ")",
          };
        });
        setCompanyCodeData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleChange = (event, name, regex, length) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "invoice_type":
        setVendorData((prev) => ({
          ...prev,
          invoice_type: event.target.value,
        }));
        break;
      case "grn_number":
        if (event.target.value.length < length) {
          if (!event.target.value.match(regex)) {
            setVendorData((prev) => ({
              ...prev,
              grn_number: event.target.value,
            }));
          }
        }
        break;
      case "ses_number":
        if (event.target.value.length < length) {
          if (!event.target.value.match(regex)) {
            setVendorData((prev) => ({
              ...prev,
              ses_number: event.target.value,
            }));
          }
        }
        break;
      case "invoice_no":
        setVendorData((prev) => ({
          ...prev,
          invoice_no: event.target.value,
        }));
        break;
      case "invoice_date":
        setVendorData((prev) => ({
          ...prev,
          invoice_date: event.target.value,
        }));
        setVendorData((prev) => ({
          ...prev,
          dispatch_date: "",
        }));
        break;
      case "bill_amount":
        if (event.target.value.length < length) {
          setVendorData((prev) => ({
            ...prev,
            bill_amount: event.target.value,
          }));
        }
        break;
      case "dispatch_date":
        setVendorData((prev) => ({
          ...prev,
          dispatch_date: event.target.value,
        }));
        break;
      case "courier_to_id":
        setVendorData((prev) => ({
          ...prev,
          courier_to_id: event.target.value,
        }));
        break;
      case "courier_no":
        if (!event.target.value.match(regex)) {
          setVendorData((prev) => ({
            ...prev,
            courier_no: event.target.value,
          }));
        }
        break;
      case "invoice_receipt":
        setVendorData((prev) => ({
          ...prev,
          invoice_receipt: event.target.value,
        }));
        break;
      case "vendor_id":
        setVendorData((prev) => ({
          ...prev,
          vendor_id: event.value,
        }));
        break;
      case "company_id":
        setVendorData((prev) => ({
          ...prev,
          company_id: event.value,
        }));
        getGlCodeList(event.value);
        getCostCenter(event.value);
        setCompanyCode(event);
        break;
      case "gl_code_id":
        setVendorData((prev) => ({
          ...prev,
          gl_code_id: event.value,
        }));
        setGlCode(event);
        break;
      case "cost_center_id":
        setVendorData((prev) => ({
          ...prev,
          cost_center_id: event.value,
        }));
        setCostCenterData(event);
        break;
      default:
        break;
    }
  };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...formValidation };
    if (!vendorData.invoice_type) {
      isError = true;
      formerr.invoice_type = "*Invoice Type is required  ";
      setFormValidation(formerr);
    }
    if (!vendorData.vendor_id) {
      isError = true;
      formerr.vendor_id = " *Vendor Code is required ";
      setFormValidation(formerr);
    }
    if (!vendorData.invoice_no) {
      isError = true;
      formerr.invoice_no = " *Invoice No. is required";
      setFormValidation(formerr);
    }
    if (!vendorData.invoice_date) {
      isError = true;
      formerr.invoice_date = " *Invoice Date is required ";
      setFormValidation(formerr);
    }
    if (!vendorData.bill_amount) {
      isError = true;
      formerr.bill_amount = " *Amount is required ";
      setFormValidation(formerr);
    }
    if (!vendorData.dispatch_date) {
      isError = true;
      formerr.dispatch_date = " *Dispatch Date is required ";
      setFormValidation(formerr);
    }
    if (!vendorData.courier_to_id) {
      isError = true;
      formerr.courier_to_id = " *Courier is required ";
      setFormValidation(formerr);
    }
    if (!vendorData.company_id) {
      isError = true;
      formerr.company_id = " *Company Code is required ";
      setFormValidation(formerr);
    }
    if (
      !vendorData.gl_code_id &&
      vendorData?.invoice_type === "Non PO Based Invoices"
    ) {
      isError = true;
      formerr.gl_code_id = " *GL Code is required ";
      setFormValidation(formerr);
    }
    if (
      !vendorData.cost_center_id &&
      vendorData?.invoice_type === "Non PO Based Invoices"
    ) {
      isError = true;
      formerr.cost_center_id = " *Cost Center is required ";
      setFormValidation(formerr);
    }
    if (
      !vendorData.grn_number &&
      vendorData?.invoice_type === "PO Based Material Invoices"
    ) {
      isError = true;
      formerr.grn_number = " *GRN number is required ";
      setFormValidation(formerr);
    }
    if (
      !vendorData.ses_number &&
      vendorData?.invoice_type === "PO Based Service Invoices"
    ) {
      isError = true;
      formerr.ses_number = "*SES number is required ";
      setFormValidation(formerr);
    }
    return isError;
  };

  const saveAllVendorsData = () => {
    let savedVendorsData = {
      invoice_type: vendorData.invoice_type,
      vendor_id: vendorData.vendor_id,
      invoice_no: vendorData.invoice_no,
      invoice_date: vendorData.invoice_date,
      bill_amount: vendorData.bill_amount,
      dispatch_date: vendorData.dispatch_date,
      courier_to_id: vendorData.courier_to_id,
      courier_no: vendorData.courier_no,
      invoice_receipt: "",
      status: "Submitted",
      grn_number: vendorData.grn_number,
      ses_number: vendorData.ses_number,
      gl_code_id: vendorData.gl_code_id ? vendorData.gl_code_id : undefined,
      company_id: vendorData.company_id,
      cost_center_id: vendorData.cost_center_id
        ? vendorData.cost_center_id
        : undefined,
    };

    if (handleValidate()) return false;
    saveVendorInvoice(savedVendorsData);
    AuditLog.addAuditLog(dispatch, nameForm);
  };

  const pushDataVendor = (newValue, name) => {
    setVendorDataList(newValue);
    handleChange(newValue, name);
  };

  const sscMembeDataList = (optionsStateList) => {
    return optionsStateList?.map((item, index) => (
      <option
        key={`state${index}`}
        value={`${item?.user_id}`}
        label={`${item?.username} (${item?.first_name} ${item?.last_name}) (${
          item?.location === null ? "" : item?.location_details
        })`}
      />
    ));
  };

  useEffect(() => {
    getVendorId();
    getCompanyCode();
  }, []);

  useEffect(() => {
    setVendorDataList([]);
    setCompanyCode([]);
    setGlCode([]);
    setCostCenterData([]);
    setVendorData({
      invoice_type: "",
      vendor_id: "",
      invoice_no: "",
      invoice_date: todayDate(),
      bill_amount: "",
      dispatch_date: todayDate(),
      courier_to_id: "",
      courier_no: "",
      invoice_receipt: "",
      status: "",
      grn_number: "",
      ses_number: "",
      gl_code_id: "",
      company_id: "",
      cost_center_id: "",
    });
    setFormValidation({
      invoice_type: "",
      vendor_id: "",
      invoice_no: "",
      invoice_date: "",
      bill_amount: "",
      dispatch_date: "",
      courier_to_id: "",
      courier_no: "",
      invoice_receipt: "",
      status: "",
      grn_number: "",
      ses_number: "",
      company_id: "",
      gl_code_id: "",
      cost_center_id: "",
    });
  }, [onClose]);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  return (
    <>
      <Modal
        show={addnewshow}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Invoice Entry</Modal.Title>
        </Modal.Header>
        <>
          <Modal.Body>
            <div class="row justify-content-center">
              <ToastContainer autoClose={1000} />
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        For Single Invoice Entry
                        <span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-control newbgselect"
                        name="invoice_type"
                        onChange={(newValue, action) => {
                          handleChange(newValue, action?.name);
                          setFormValidation({
                            ...formValidation,
                            invoice_type: "",
                          });
                        }}
                        value={vendorData.invoice_type}
                      >
                        <option value="" disabled>
                          Select Invoice Type
                        </option>
                        <option value="PO Based Material Invoices" selected>
                          PO Based Material Invoices
                        </option>
                        <option value="PO Based Service Invoices">
                          PO Based Service Invoices
                        </option>
                        <option value="Non PO Based Invoices">
                          Non PO Based Invoices
                        </option>
                      </select>

                      <div className="small text-danger">
                        {formValidation.invoice_type}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  {vendorData?.invoice_type === "PO Based Material Invoices" ? (
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                          GRN Number<span class="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Enter GRN Number"
                          name="grn_number"
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={blockInvalidChar}
                          maxLength={20}
                          onChange={(newValue, action) => {
                            handleChange(
                              newValue,
                              action?.name,
                              "[^A-Za-z0-9]",
                              "11"
                            );
                            setFormValidation({
                              ...formValidation,
                              grn_number: "",
                            });
                          }}
                          value={vendorData.grn_number}
                        />

                        <div className="small text-danger">
                          {formValidation.grn_number}
                        </div>
                      </div>
                    </div>
                  ) : vendorData?.invoice_type ===
                    "PO Based Service Invoices" ? (
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                          SES Number<span class="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Enter SES Number"
                          name="ses_number"
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={blockInvalidChar}
                          pattern=""
                          maxLength={20}
                          onChange={(newValue, action) => {
                            handleChange(
                              newValue,
                              action?.name,
                              "[^A-Za-z0-9]",
                              "11"
                            );
                            setFormValidation({
                              ...formValidation,
                              ses_number: "",
                            });
                          }}
                          value={vendorData.ses_number}
                        />

                        <div className="small text-danger">
                          {formValidation.ses_number}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Vendor Code<span class="text-danger">*</span>
                      </label>
                      <Select
                        options={vendorId}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Press enter to search"
                        name="vendor_id"
                        maxLength={20}
                        value={vendorDataList}
                        onInputChange={(e) => {
                          getVendorId(e);
                        }}
                        onChange={(newValue, action) => {
                          pushDataVendor(newValue, action?.name);
                          setFormValidation({
                            ...formValidation,
                            vendor_id: "",
                          });
                        }}
                      />

                      <div className="small text-danger">
                        {formValidation.vendor_id}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Vendor Name</label>
                      <input
                        type="text"
                        class="form-control"
                        maxLength={20}
                        disabled
                        placeholder="Enter Vendor Name"
                        name="vendor_name"
                        value={vendorDataList.name}
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Company Code<span class="text-danger">*</span>
                      </label>
                      <Select
                        options={companyCodeData}
                        className="basic-multi-select"
                        name="company_id"
                        id="company_id"
                        classNamePrefix="select"
                        onChange={(newValue, action) => {
                          handleChange(newValue, action?.name, "", "");
                          setFormValidation({
                            ...formValidation,
                            company_id: "",
                          });
                        }}
                        value={companyCode}
                      />
                      <div className="small text-danger">
                        {formValidation.company_id}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        GL Code
                        {vendorData?.invoice_type ===
                          "Non PO Based Invoices" && (
                          <span class="text-danger">*</span>
                        )}
                      </label>
                      <Select
                        options={glCodeList}
                        name="gl_code_id"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(newValue, action) => {
                          handleChange(newValue, action?.name, "", "");
                          setFormValidation({
                            ...formValidation,
                            gl_code_id: "",
                          });
                        }}
                        value={glCode}
                      />
                      <div className="small text-danger">
                        {formValidation.gl_code_id}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Cost Center
                        {vendorData?.invoice_type ===
                          "Non PO Based Invoices" && (
                          <span class="text-danger">*</span>
                        )}
                      </label>
                      <Select
                        options={costCenterList}
                        name="cost_center_id"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(newValue, action) => {
                          handleChange(newValue, action?.name, "", "");
                          setFormValidation({
                            ...formValidation,
                            cost_center_id: "",
                          });
                        }}
                        value={costCenterData}
                      />
                      <div className="small text-danger">
                        {formValidation.cost_center_id}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Invoice No.<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Invoice No."
                        name="invoice_no"
                        id="invoice_no"
                        maxLength={18}
                        onChange={(newValue, action) => {
                          handleChange(newValue, action?.name, "");
                          setFormValidation({
                            ...formValidation,
                            invoice_no: "",
                          });
                        }}
                        value={vendorData.invoice_no}
                      />

                      <div className="small text-danger">
                        {formValidation.invoice_no}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Invoice Date<span class="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        placeholder=""
                        name="invoice_date"
                        max={todayDate()}
                        onChange={(newValue, action) => {
                          handleChange(newValue, action?.name);
                          setFormValidation({
                            ...formValidation,
                            invoice_date: "",
                          });
                        }}
                        value={vendorData.invoice_date}
                      />

                      <div className="small text-danger">
                        {formValidation.invoice_date}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Bill Amount<span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Enter Bill Amount"
                        name="bill_amount"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        onChange={(newValue, action) => {
                          handleChange(newValue, action?.name, "", "14");
                          setFormValidation({
                            ...formValidation,
                            bill_amount: "",
                          });
                        }}
                        value={vendorData.bill_amount}
                      />

                      <div className="small text-danger">
                        {formValidation.bill_amount}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Dispatch Date<span class="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        placeholder=""
                        name="dispatch_date"
                        disabled={!vendorData.invoice_date}
                        min={vendorData.invoice_date}
                        onChange={(newValue, action) => {
                          handleChange(newValue, action?.name);
                          setFormValidation({
                            ...formValidation,
                            dispatch_date: "",
                          });
                        }}
                        value={vendorData.dispatch_date}
                      />

                      <div className="small text-danger">
                        {formValidation.dispatch_date}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Courier Addressed to<span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-control newbgselect"
                        name="courier_to_id"
                        onChange={(newValue, action) => {
                          handleChange(newValue, action?.name);
                          setFormValidation({
                            ...formValidation,
                            courier_to_id: "",
                          });
                        }}
                        value={vendorData.courier_to_id}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {sscMembeDataList(sscList)}
                      </select>

                      <div className="small text-danger">
                        {formValidation.courier_to_id}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Courier Docket No.</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Courier Docket No."
                        name="courier_no"
                        maxLength={10}
                        onChange={(newValue, action) => {
                          handleChange(newValue, action?.name, "[^A-Za-z0-9]");
                          setFormValidation({
                            ...formValidation,
                            courier_no: "",
                          });
                        }}
                        value={vendorData.courier_no}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isLoading ? (
              <Spinner
                animation="border"
                variant="primary"
                as="span"
                role="status"
                aria-hidden="true"
              ></Spinner>
            ) : (
              <>
                <button
                  class="btn btn-outline-danger"
                  type="button"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
                <button
                  class="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => saveAllVendorsData()}
                >
                  Submit
                </button>
              </>
            )}
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default AddVendorInvoicing;
