import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";

import { uploadfile } from "../service/Survey";

const InputField = (props) => {
  const { setQuestionData, questionData, categoryName, setDataOptionValue, dataOptionValue, qid, isViewingorPublished, set_imageLocalPreviewDict, imageLocalPreviewDict, QuestionDataChange } =
    props;
  // let fillData = questionData;
  // console.log(fillData, "fill");
  // console.log(dataOptionValue, "doptval");

  const [aquestionData, asetQuestionData] = useState([
    {
      id: 0,
      question: "",
      type: "",
      option_type: "",
      options: [],
    },
  ]);

  const [filename, setFilename] = useState([])
  const [defaultOptionFlag, setDefaultOptionFlag] = useState(false);

  const fileUploadHandler = (e, qItem, qIndex, idx) => {

    const fileData = new FormData();
    let files = [];
    let urls = [];
    let fileNames = [];
    let file = e.target.files;

    let imgLocalPreviewDict = { ...imageLocalPreviewDict }


    if (!imgLocalPreviewDict[qIndex]) {



      imgLocalPreviewDict[qIndex] = []


    }

    for (let i = 0; i < file.length; i++) {
      let myUrl = URL.createObjectURL(e.target.files[i]);
      urls.push(myUrl);


      imgLocalPreviewDict[qIndex].push(myUrl)
      set_imageLocalPreviewDict(imgLocalPreviewDict)

      let data = e.target.files[i];
      fileData.append("uploaded_file", data);
      let name = { name: data.name };
      fileNames.push(name);
      uploadfile(fileData).then((response) => {
        files.push(response.data);

      }).then(() => {

        const copyValue = [...aquestionData];
        copyValue[qIndex]["options"][idx]["option"] = files[0]

        asetQuestionData(copyValue)
        setQuestionData(copyValue);

      });
    }


    // setFormObj((prev) => ({
    //   ...prev,
    //   attachment: files,
    // }));

    // setFilename(file[0]);
    // setfileurl(urls);
  };

  useEffect(() => {

    asetQuestionData(questionData);


  }, [questionData, aquestionData])


  const handleDelete = (item, pi, idx) => {

    const copyValue = [...questionData];
    let Items = item;
    Items = Items.splice(idx, 1);
    copyValue[pi] = { ...copyValue[pi], options: item };
    setQuestionData(copyValue);
  };

  const setDefaultOption = (e, index, value) => {
    let copyData = questionData
    copyData[qid].default_option = value
    setQuestionData(copyData)
    setDefaultOptionFlag(true)
    QuestionDataChange(value, qid)
  }

  const RatingBox = (value, i) => {
    return (
      <>
        {value?.type !== "Date" && (
          <div class="col-md-3">
            <div class="form-group innergroup">
              <label>Max. Rating</label>
              <select
                disabled={isViewingorPublished}
                class="form-control newbgselect"
                name="max"
                onChange={(e) => {
                  HandleDataRow(value, i, "max", e);
                }}
                // onChange={(e) =>
                //   setDataOptionValue({ ...dataOptionValue, max: e.target.value })
                // }
                value={value.options[0].max}
              >
                <option value="">Select</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
        )}
        <div className="col-md-7">
          <div class="form-group innergroup">
            <label>Rating Description</label>
            <textarea className="form-control"
              disabled={isViewingorPublished}
              class="form-control newbgselect"
              name="desc"
              onChange={(e) => {
                HandleDataRow(value, i, "desc", e);
              }}
              value={value.options[0].desc}
            />
          </div>
        </div>
      </>
    );
  };

  const InputBoxAdd = (value) => {
    return (
      <>
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>{value.option}</label>
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder="Enter Designation Name"
            />
          </div>
          <div class="mt-2 mt-sm-0">
            <span class="text-danger cursor-pointer text-sm-right">
              <i class="far fa-times-circle"></i> Remove
            </span>
          </div>
        </div>
      </>
    );
  };

  const RatingStar = (value, item, pi, idx) => {
    return (
      <>
        <div class="Row">
          <div class="col-md-6">
            <label>{value.option}</label>
            <div class="ratings">
              <i class="fa fa-star rating"></i>
            </div>
            <div class="mt-2 mt-sm-0">
              <span
                class="text-danger cursor-pointer text-sm-right"
                onClick={() => handleDelete(value, pi, idx)}
              >
                <i class="far fa-times-circle"></i> Remove
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const HandleDataRow = (data, i, min_max, e) => {

    const copyValue = [...aquestionData];

    switch (data.type) {
      case "Short Answer":
      case "Slider":
      case "Long Answer":
      case "Rating":
      case "Date":
        copyValue[i]["options"][0][min_max] = e.target.value
        break;
    }

    setQuestionData(copyValue);
    asetQuestionData(copyValue)
  };

  const InputBoxShort = (value, index) => {
    return (
      <>
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Min Character</label>
            <input
              disabled={isViewingorPublished}
              type="number"
              class="form-control"
              name="min"
              placeholder="Enter Min Character"
              onChange={(e) => {
                HandleDataRow(value, index, "min", e);
              }}
              // onChange={(e) =>
              //   setDataOptionValue({ ...dataOptionValue, min: e.target.value })
              // }
              value={value.options[0].min}
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Max Character</label>
            <input
              disabled={isViewingorPublished}
              type="number"
              class="form-control"
              name="max"
              placeholder="Enter Max Character"
              onChange={(e) => {
                HandleDataRow(value, index, "max", e);
              }}
              // onChange={(e) =>
              //   setDataOptionValue({ ...dataOptionValue, max: e.target.value })
              // }
              value={value.options[0].max}
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Box Width</label>
            <select
              disabled={isViewingorPublished}
              class="form-control newbgselect"
              name="max"
              onChange={(e) => {
                HandleDataRow(value, index, "optWidth", e);
              }}
              value={value.options[0].optWidth}
            >
              <option value="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </div>
        </div>
      </>
    );
  };

  const RadioBox = (value, item, pi, idx) => {
    return (
      <>
        <Col md={12} className="mb-3">
          <label class="logCheck d-inline-block mr-4">
            {item.option} (WIDTH: {item?.optWidth})
            <input disabled type="radio" name="dropdquestion" />
            <span class="checkmark"></span>
          </label>
          {!isViewingorPublished ? (<div class="mt-2 mt-sm-0 d-sm-inline-block">
            <span
              class="text-danger cursor-pointer text-sm-right"
              onClick={() => handleDelete(value, pi, idx)}
            >
              <i class="far fa-times-circle"></i> Remove
            </span>
            {(questionData[qid]?.is_dropdown || categoryName === "Quiz") && (
              <>
                <input
                  type="radio"
                  name="default_opt"
                  className="ml-4"
                  checked={questionData[qid]?.default_option == value[idx].option}
                  onChange={(e) => {
                    setDefaultOption(e, idx, value[idx].option)
                  }}
                />
                <label className="ml-2">{categoryName === "Quiz" ? "Set as answer" : "Set as default"}</label>
              </>
            )}
          </div>) : ("")}
        </Col>
      </>
    );
  };

  const ImageBox = () => {
    return (
      <div class="col-md-2">
        <div class="upload-btn-wrapper mb-1 d-block">
          <button class="uploadBtn add-imagequestion">Add Image</button>
          <input type="file" />
        </div>
        <img src="images/new-detail.jpg" width="100px" class="border p-1" />
      </div>
    );
  };

  const Attachment = (value, item, index, idx) => {
    return (
      <div className="col-md-6 form-group">
        <div className="innergroup position-relative">
          <label> Image</label>
          <input
            type="text"
            className="form-control bg-white"
            disabled
            placeholder="(Image, jpeg/png Format)"
          />
          <div className="upload-btn-wrapper up-loposition">
            <button className="uploadBtn">Browse</button>
            <input
              type="file"
              title=""
              name="icon"
              accept="image/png, image/jpeg"
              onChange={(e) => fileUploadHandler(e, value, index, idx)}
            />
          </div>

          {/* {imageLocalPreviewDict[index]?(
            <img src= {imageLocalPreviewDict[index][idx]} />
            ):("")
            

          } */}

          {
            typeof item?.option == "string" ? (
              <img src={item.option} />
            )
              : (<img src={imageLocalPreviewDict[index][idx]} />)

          }



          {/* {imageLocalPreviewDict[index]?.map((itemm,iddxx)=>(

            iddxx==idx?(<img src= {itemm} />
            ):("")
          ))
            
          } */}

          {item?.option?.original_name}
        </div>
        {!isViewingorPublished ? (
          <div>
            <span
              class="text-danger cursor-pointer text-sm-right"
              onClick={() => { handleDelete(value, index, idx) }}
            >
              <i class="far fa-times-circle"></i> Remove
            </span>
          </div>) : ("")}
      </div>
    );
  };

  const SelectBox = (value, item, pi, idx) => {
    return (
      <Col md={12} className="mb-3">
        <label class="logCheck d-inline-block mr-4">
          {item.option}
          <input disabled type="checkbox" name="statusupdate3" />
          <span class="checkmark"></span>
        </label>

        {!isViewingorPublished ? (
          <div class="mt-2 mt-sm-0 d-sm-inline-block">
            <span
              class="text-danger cursor-pointer text-sm-right"
              onClick={() => { handleDelete(value, pi, idx) }}
            >
              <i class="far fa-times-circle"></i> Remove
            </span>
          </div>) : ("")}

      </Col>
    );
  };
  return (
    <>
      {questionData?.map((value, index) => {
        if (index == qid) {
          return <>

            {value.type === "Multiple Choice" &&
              value.option_type === "txt" &&
              value.options.map((item, idx) =>
                SelectBox(value.options, item, index, idx)
              )}
            {value.type === "Multiple Choice" &&
              (value.option_type === "file" || value.option_type === "old_file") &&
              value.options.map((item, idx) =>
                Attachment(value.options, item, index, idx)
              )}

            {value.type === "Single Select" &&
              value.option_type === "txt" &&
              value.options.map((item, idx) =>
                RadioBox(value.options, item, index, idx)
              )}

            {value.type === "Single Select" &&
              (value.option_type === "file" || value.option_type === "old_file") &&
              value.options.map((item, idx) =>
                Attachment(value.options, item, index, idx)
              )
            }

            {/* {value.type === "Short Answer" &&
            value.options.map((item, idx) => InputBoxAdd(item, index))} */}
            {value.type === "Short Answer" && InputBoxShort(value, index)}
            {(value.type === "Rating" || value?.type === "Slider" || value?.type === "Date") && RatingBox(value, index)}
            {(value?.type === "Long Answer") && (
              <div class="col-md-4">
                <div class="form-group innergroup">
                  <label>Box Width</label>
                  <select
                    disabled={isViewingorPublished}
                    class="form-control newbgselect"
                    name="max"
                    onChange={(e) => {
                      HandleDataRow(value, index, "optWidth", e);
                    }}
                    value={value.options[0].optWidth}
                  >
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>
              </div>
            )}
            {/* {value.type === "Rating" &&
            value.options.map((item, idx) =>
              RatingStar(value.options, item, index, idx)
            )} */}
          </>
        }
      })}
    </>
  );
};

export default InputField;
