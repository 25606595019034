import { useEffect } from "react";
import { useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ModularTable from "../../../../elements/ModularTable";
import {
  getMajorProduct,
  getCategoryDropdown,
  getLastMajorProduct,
  getMajorProductsApprovalLogs,
  getMoleculesByCatId,
  postMajorProduct,
  postMajorProductAction,
  putMajorProduct,
} from "../../../../service/AgriPortalService/MajorProductService";
import styles from "./Table.module.css";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";

const Root = (props) => {
  //HOOKS
  const [dropwdownCategories, setDropdownCategories] = useState([]);
  const [dropdownProducts, setDropdownProducts] = useState({});

  const [rawData, setRawData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [updatedTableData, setUpdatedTableData] = useState([[]]);

  const [mode, setMode] = useState();

  const [freezeCTA, setFreezeCTA] = useState(false);

  const [approvalList, setApprovalList] = useState();
  const [locationData, setLocationData] = useState({});
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getMajorProduct()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mode]);

  //Called to set table data everytime an value is updated
  const onTableDataChange = (data) => {
    const totals = [{ value: ["Total"] }, { value: ["-"] }];

    for (let i = 2; i < data[0]?.length; i++) {
      let total = 0;
      for (let j = 0; j < data.length - 1; j++) {
        if (!parseInt(data[j][i].value[0])) total += 0;
        else total += parseInt(data[j][i].value[0]);
      }
      totals.push({ value: [total] });
    }

    data[data.length - 1] = totals;

    setUpdatedTableData([...data]);
  };

  //To preload dropdown player values
  useEffect(() => {
    getCategoryDropdown()
      .then((res) => {
        setDropdownCategories(res.data.dataList);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!dropwdownCategories) return;

    fetchAllProductDropdownByCategory(dropwdownCategories)
      .then((res) => {
        setDropdownProducts(res);
      })
      .catch((err) => console.log(err));
  }, [dropwdownCategories]);

  useEffect(() => {
    if (!mode) return;
    let query = "/mymarket/majorproducts";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/mymarket/majorproducts");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  async function fetchAllProductDropdownByCategory(category) {
    const obj = {};

    for (let i = 0; i < category.length; i++) {
      if (category[i].line_items != null) {
        const res = await getMoleculesByCatId("?category_id=" + category[i].id);
        obj[category[i].name] = res.data.dataList;
      }
    }

    return obj;
  }

  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setPageId(params.get("id"));
    let query = "";
    if (params.get("mode") != "create")
      query = "?major_product_id=" + params.get("id");

    if (params.get("mode") != "create") {
      getMajorProductsApprovalLogs(query)
        .then((res) => setApprovalList(res.data.dataList))
        .catch((err) => console.log(err));
    }

    getLastMajorProduct(query)
      .then((res) => {
        setRawData(res.data.dataList);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code == 409) {
          toast.error("Major products already exists!");
          setTimeout(() => {
            navigate("/mymarket/majorproducts");
          }, 1000);
        }
      });
  }, []);

  //Set table data after past data is fetched
  useEffect(() => {
    if (!rawData.product_data) return;

    if (
      Object.keys(dropwdownCategories).length == 0 ||
      Object.keys(dropdownProducts).length == 0
    )
      return;

    setTableHeadData((state) => {
      if (!rawData) return [];

      let headData;

      if (mode != "view" && mode != "approve")
        headData = [
          {
            name: "Category",
            type: "T",
            isEditable: false,
          },
          {
            name: "Products",
            type: "D",
            isEditable: true,
            nonrepeat: true,
          },
          // {
          //   name: rawData?.product_data[0]?.fy_m_2,
          //   type: "N",
          //   isEditable: true,
          //   allowDecimalUpto: 2,
          // },
          {
            name: rawData?.product_data[0]?.fy_m_1,
            type: "N",
            isEditable: true,
            allowDecimalUpto: 2,
          },
          {
            name: rawData?.product_data[0]?.fy,
            type: "N",
            isEditable: true,
            allowDecimalUpto: 2,
          },
          // {
          //   name: rawData?.product_data[0]?.fy_p_1,
          //   type: "N",
          //   isEditable: true,
          //   allowDecimalUpto: 2,
          // },
          // {
          //   name: rawData?.product_data[0]?.fy_p_2,
          //   type: "N",
          //   isEditable: true,
          //   allowDecimalUpto: 2,
          // },
        ];

      if (mode == "view" || mode == "approve")
        headData = [
          {
            name: "Category",
            type: "T",
            isEditable: false,
          },
          {
            name: "Products",
            type: "D",
            isEditable: false,
            nonrepeat: true,
          },
          // {
          //   name: rawData?.product_data[0]?.fy_m_2,
          //   type: "N",
          //   isEditable: false,
          //   allowDecimalUpto: 2,
          // },
          {
            name: rawData?.product_data[0]?.fy_m_1,
            type: "N",
            isEditable: false,
            allowDecimalUpto: 2,
          },
          {
            name: rawData?.product_data[0]?.fy,
            type: "N",
            isEditable: false,
            allowDecimalUpto: 2,
          },
          // {
          //   name: rawData?.product_data[0]?.fy_p_1,
          //   type: "N",
          //   isEditable: false,
          //   allowDecimalUpto: 2,
          // },
          // {
          //   name: rawData?.product_data[0]?.fy_p_2,
          //   type: "N",
          //   isEditable: false,
          //   allowDecimalUpto: 2,
          // },
        ];

      return headData;
    });

    setTableData((state) => {
      const newTableData = [];

      let val1 = 0,
        val2 = 0,
        val3 = 0,
        val4 = 0,
        val5 = 0;
      if (mode == "create")
        for (let i = 0; i < dropwdownCategories.length; i++) {
          let filteredRawData = rawData.product_data.filter(
            (el) => el.category_name == dropwdownCategories[i]?.name
          );
          for (
            let j = 0;
            j <
            (dropwdownCategories[i]?.line_items
              ? dropwdownCategories[i]?.line_items
              : 0);
            j++
          ) {
            let rowData;

            if (filteredRawData.length > j) {
              // val1 += parseInt(filteredRawData[j].fy_m_2_value);
              val2 += parseInt(filteredRawData[j].fy_m_1_value);
              val3 += parseInt(filteredRawData[j].fy_value);
              val4 += parseInt(filteredRawData[j].fy_p_1_value);
              // val5 += parseInt(filteredRawData[j].fy_p_1_value);
              rowData = [
                {
                  value: dropwdownCategories[i]?.name,
                  meta: {
                    id: dropwdownCategories[i]?.id,
                  },
                },
                {
                  value: dropdownProducts[dropwdownCategories[i]?.name]?.map(
                    (el) => el.name
                  ),
                  meta: {
                    raw: dropdownProducts[dropwdownCategories[i]?.name],
                  },
                  selected: filteredRawData[j].molecule_name,
                },
                // {
                //   value: [filteredRawData[j].fy_m_2_value],
                //   meta: {},
                // },
                {
                  value: [filteredRawData[j].fy_m_1_value],
                  meta: {},
                },
                {
                  value: [filteredRawData[j].fy_value],
                  meta: {},
                },
                // {
                //   value: [filteredRawData[j].fy_p_1_value],
                //   meta: {},
                // },
                // {
                //   value: [filteredRawData[j].fy_p_2_value],
                //   meta: {},
                // },
              ];
            } else
              rowData = [
                {
                  value: dropwdownCategories[i]?.name,
                  meta: {
                    id: dropwdownCategories[i]?.id,
                  },
                },
                {
                  value: dropdownProducts[dropwdownCategories[i]?.name]?.map(
                    (el) => el.name
                  ),
                  meta: {
                    raw: dropdownProducts[dropwdownCategories[i]?.name],
                  },
                  selected:
                    dropdownProducts[dropwdownCategories[i]?.name][j]?.name,
                },
                // {
                //   value: [0],
                //   meta: {},
                // },
                // {
                //   value: [0],
                //   meta: {},
                // },
                {
                  value: [0],
                  meta: {},
                },
                {
                  value: [0],
                  meta: {},
                },
                // {
                //   value: [0],
                //   meta: {},
                // },
              ];

            newTableData.push(rowData);
          }
        }
      else
        rawData.product_data.forEach((el, idx) => {
          // val1 += parseInt(el.fy_m_2_value);
          val2 += parseInt(el.fy_m_1_value);
          val3 += parseInt(el.fy_value);
          val4 += parseInt(el.fy_p_1_value);
          // val5 += parseInt(el.fy_p_1_value);

          let rowData;
          rowData = [
            {
              value: el.category_name,
              meta: {
                id: el.category_id,
              },
            },
            {
              value: dropdownProducts[el.category_name]?.map((el) => el.name),
              meta: {
                raw: dropdownProducts[el.category_name],
              },
              selected: el.molecule_name,
            },
            // {
            //   value: [el.fy_m_2_value],
            //   meta: {},
            // },
            {
              value: [el.fy_m_1_value],
              meta: {},
            },
            {
              value: [el.fy_value],
              meta: {},
            },
            // {
            //   value: [el.fy_p_1_value],
            //   meta: {},
            // },
            // {
            //   value: [el.fy_p_2_value],
            //   meta: {},
            // },
          ];

          newTableData.push(rowData);
        });

      newTableData.push([
        {
          value: ["Total"],
          meta: {},
        },
        {
          value: ["-"],
        },
        // {
        //   value: [val1],
        //   meta: {},
        // },
        // {
        //   value: [val2],
        //   meta: {},
        // },
        {
          value: [val3],
          meta: {},
        },
        {
          value: [val4],
          meta: {},
        },
        // {
        //   value: [val5],
        //   meta: {},
        // },
      ]);

      return newTableData;
    });
  }, [rawData, dropwdownCategories, dropdownProducts]);

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      major_product_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postMajorProductAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };

  //Called to format data for output body
  const convertCategoryData = (data) => {
    const ans = [];

    if (!data) return;

    let moleculeName = "";
    let moleculeData = {};
    for (let i = 0; i < data.length; i++) {
      if (data[i][0].value != "Total") {
        moleculeName = data[i][0]?.value;
        if (moleculeData[moleculeName]) {
          moleculeData[moleculeName].push(data[i][1]?.selected);
        } else {
          moleculeData[moleculeName] = [data[i][1]?.selected];
        }
      }
    }
    let exisingData = [];
    for (let i = 0; i < Object.values(moleculeData).length; i++) {
      if (!checkIfArrayIsUnique(Object.values(moleculeData)[i])) {
        exisingData.push(Object.keys(moleculeData)[i]);
      }
    }

    if (exisingData.length > 0) {
      return { error: true, data: exisingData };
    }

    data.forEach((row) => {
      if (row[0].value != "Total") {
        let molecule_id = -1;
        molecule_id = row[1].meta.raw.find((el) => el.name == row[1].selected);
        console.log({molecule_id});
        if(molecule_id === undefined){
         return
          
        }
        molecule_id = molecule_id?.id;
        ans.push({
          category_id: row[0].meta.id,
          molecule_id: molecule_id,
          // fy_m_2: tableHeadData[2].name,
          fy_m_1: tableHeadData[2].name,
          fy: tableHeadData[3].name,
          // fy_p_1: tableHeadData[3].name,
          // fy_p_2: tableHeadData[6].name,
          // fy_m_2_value: row[2].value[0] == "" ? "0" : row[2].value[0],
          fy_m_1_value: row[2].value[0] == "" ? "0" : row[2].value[0],
          fy_value: row[3].value[0] == "" ? "0" : row[3].value[0],
          // fy_p_1_value: row[3].value[0] == "" ? "0" : row[3].value[0],
          // fy_p_2_value: row[6].value[0] == "" ? "0" : row[6].value[0],
        });
      }
    });
    return { error: false, data: ans };
  };

  function checkIfArrayIsUnique(myArray) {
    return myArray.length === new Set(myArray).size;
  }

  const preparePayload = (type) => {
    if (freezeCTA) return;

    setFreezeCTA(true);
    let cfy = rawData?.cfy.split("-")[1];
    if (cfy.length !== 4) return;

    cfy = rawData?.cfy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: type,
    };
    console.log({updatedTableData});
    const product_data = convertCategoryData(updatedTableData);
    if (product_data.error) {
      let text = `Please select different product for following categories: ${product_data.data.join(
        ", "
      )}`;
      setFreezeCTA(false);
      errorDialog(text);
    } else {
      if (!product_data) return;
      body.product_data = product_data.data;
      setFreezeCTA(false);
      confirmDialog(type, body);
    }
  };

  //EVENT HANDLERS
  const submitData = (type, body) => {
    if (mode == "create")
      postMajorProduct(body)
        .then((res) => {
          toast.success(
            type == "P" ? "Submit Succesfull!" : "Save Succesfull!"
          );
          setTimeout(() => {
            navigate("/mymarket/majorproducts");
          }, 1000);
        })
        .catch((err) => {
          toast.error(type == "P" ? "Submit Failed!" : "Save Failed!");
          setTimeout(() => {
            navigate("/mymarket/majorproducts");
          }, 1000);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putMajorProduct(body)
        .then((res) => {
          toast.success(
            type == "P" ? "Submit Succesfull!" : "Save Succesfull!"
          );
          setTimeout(() => {
            navigate("/mymarket/majorproducts");
          }, 1000);
        })
        .catch((err) => {
          toast.error(type == "P" ? "Submit Failed!" : "Save Failed!");
          setTimeout(() => {
            navigate("/mymarket/majorproducts");
          }, 1000);
        });
    }
  };

  const errorDialog = (data) => {
    confirmAlert({
      title: "Duplicate Entries",
      message: data,
      buttons: [
        {
          label: "Okay",
        },
      ],
    });
  };

  const confirmDialog = (type, body) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            submitData(type, body);
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  const onCancelHandler = () => {
    return navigate("/mymarket/majorproducts");
  };

  (function () {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
    }
  })();

  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Major Products</Breadcrumb.Item>
                    <Breadcrumb.Item active>Data</Breadcrumb.Item>
                  </Breadcrumb> */}
        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Major Products
          </h4>
          <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            QTY in MT / KL
          </p>
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{rawData?.cfy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>REGION</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.region_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>TERRITORY</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.territory_name}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              showTotal={true}
              columns={tableHeadData}
              data={tableData}
              onTableDataChange={onTableDataChange}
            />
          </div>
        </div>
        {approvalList?.approvals && approvalList?.approvals.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>Action At</th>
                      <th>Action By</th>
                      <th>Action User</th>
                      <th>Action Status</th>
                      <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList?.approvals &&
                      approvalList?.approvals.map((el, i) => {
                        let status;

                        const findPendingIndex =
                          approvalList.approvals.findIndex(
                            (ele) => ele.status == "P" && ele.discarded == false
                          );

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P" && el.discarded == true)
                          status = "Auto Discarded";
                        else if (el.status == "P") status = "Pending";
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr>
                            <td>
                              {status == "Pending"
                                ? "-"
                                : moment(el.action_at)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm:ss")}
                            </td>
                            <td>{el.action_by_id}</td>
                            <td>{el.action_user_name}</td>
                            <td>
                              {findPendingIndex == -1
                                ? status
                                : i > findPendingIndex
                                  ? "-"
                                  : status}
                            </td>
                            <td>{el.remarks}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  preparePayload("P");
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  preparePayload("D");
                }}
              >
                Save
              </div>
            </div>
          )}
          {mode == "approve" || approvalList?.for_approval ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Root;

// const columnSettings = [
//     {
//         name:"Category",
//         type:"D",
//         isEditable:true,
//     },
//     {
//         name:"Products",
//         type:"D",
//         isEditable:true,
//     },
//     {
//         name:"2021-22",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2022-23",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2023-24",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2024-25",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2025-26",
//         type:"N",
//         isEditable:true,
//     }
// ];

// const data = [
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],[
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],[
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],[
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],[
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],[
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1','Category 2','Category 3','Category 4'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Product 1','Product 2','Product 3','Product 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],

// ]
