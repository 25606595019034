import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getHolidayList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/holiday_calendar?${queryParm}`);
};
export const saveHoliday = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/holiday_calendar`, data);
};
export const updateHoliday= async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/holiday_calendar/${id}`, data);
};

export const deleteHoliday = async (id, data) => {
    return axios.delete(`${AUTH_BASE_URL}/holiday_calendar/${id}`, data);
  };
  export const getExportValue = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/holiday_calendar/export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };
  export const getStateList = async (paginate) => {
    return axios.get(`${AUTH_BASE_URL}/state?paginate=${paginate}`);
  };
