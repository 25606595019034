import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as ActionTypes from "../../store/actions";
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../Config";
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import * as userService from "../../service/userService";
import { Spinner } from "react-bootstrap";
import { logoutUser } from "../../service/userService";
import { quaterlyTerms } from "../../service/agree-terms";
import moment from "moment";
import { getCourseList } from "../../service/dashboard";

const Login = ({ userLogin }) => {
  const navigate = useNavigate();
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const myMsal = new PublicClientApplication(msalConfig);
  const isAuthenticated = useIsAuthenticated();
  const { result, error } = useMsalAuthentication();
  const { accounts, inProgress, instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      accounts?.length > 0 &&
      (localStorage.getItem("accesstoken") === null ||
        localStorage?.getItem("accesstoken") === undefined)
    ) {
      adLogin();
    } else {
      if (accounts.length === 0) {
        if (
          localStorage.getItem("accesstoken") !== null &&
          localStorage.getItem("accessToken") !== undefined
        ) {
          navigate("/dashboard");
        } else {
          // alert("Not login");
          handleLogin("popup");
        }
      } else {
        navigate("/dashboard");
      }
    }
  }, [accounts, instance, inProgress]);

  const adLogin = () => {
    // alert("login side");
    console.log(accounts, result);
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        console.log(response);
        if (
          response?.account?.idTokenClaims?.userid === undefined ||
          response?.account?.idTokenClaims?.userid === null ||
          response?.account?.idTokenClaims?.userid === '') {
          navigate('/denied', {
            state: {
              auth: true
            }
          })
        } else {
          localStorage.setItem("accesstoken", response?.accessToken);
          localStorage.setItem("expiry", response?.expiresOn);
          localStorage.setItem("isTemporary", "false");
          localStorage.setItem("fromAd", true);
          localStorage.setItem("secret", result?.idToken);
          userLogin(response?.accessToken, "false");
          saveAdUserToken(
            response?.accessToken,
            response?.account?.idTokenClaims?.userid
          );
        }
        // window.location.replace("/dashboard");
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          if (
            response?.account?.idTokenClaims?.userid === undefined ||
            response?.account?.idTokenClaims?.userid === null ||
            response?.account?.idTokenClaims?.userid === '') {
            navigate('/denied', {
              state: {
                auth: true
              }
            })
          } else {
            localStorage.setItem("accesstoken", response?.accessToken);
            localStorage.setItem("expiry", response?.expiresOn);
            localStorage.setItem("isTemporary", "false");
            localStorage.setItem("fromAd", true);
            localStorage.setItem("secret", result?.idToken);
            userLogin(response?.accessToken, "false");
            saveAdUserToken(
              response?.accessToken,
              response?.account?.idTokenClaims?.userid
            );
          }
          // window.location.replace("/dashboard");
        });
      });
  };

  const logoutHandle = async () => {
    await logoutUser().then((response) => {
      if (response?.status === 200) {
        if (localStorage.getItem("fromAd") === "true") {
          localStorage.clear();
          sessionStorage.clear();
          handleLogout("redirect");
        } else {
          window.location.replace("/custom");
          localStorage.removeItem("accesstoken");
          localStorage.removeItem("delegatedLogin");
        }
      }
    });
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      const logoutRequest = {
        account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
        postLogoutRedirectUri: "/",
      };
      instance.logoutRedirect(logoutRequest);
      // const logoutRequest = {
      //   redirectUri: "/",
      //   postLogoutRedirectUri: "/",
      // };
      // instance.logoutPopup(logoutRequest);
    }
  };

  const getCourseListForUser = async (res) => {
    await getCourseList()
      .then((response) => {
        let res_ = response?.data?.dataList;
        if (
          (res_?.cyber === true && res_?.posh === true && res_?.coc === true) ||
          response?.data?.success === false ||
          response?.data?.code === 401
        ) {
          if (
            !res.data.dataList.accept_tnc &&
            !res.data.dataList.accept_social
          ) {
            navigate("/agree-terms", {
              state: {
                component: "social-policy",
              },
            });
          } else if (
            !res.data.dataList.accept_tnc &&
            res.data.dataList.accept_social
          ) {
            navigate("/agree-terms", {
              state: {
                component: "quater-terms",
              },
            });
          } else if (
            res.data.dataList.accept_tnc &&
            !res.data.dataList.accept_social
          ) {
            navigate("/social-policy", {
              state: {
                component: "quater-terms",
              },
            });
          } else if (
            res.data.dataList.accept_tnc &&
            res.data.dataList.accept_social
          ) {
            getQuaterlyChecks();
          }
        } else {
          navigate("/mandatory", {
            state: {
              res: JSON.stringify(res),
            },
          });
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const saveAdUserToken = async (token, username) => {
    setIsLoading(true);
    let obj = {
      token: token,
      username: username,
      client_secret: clientSecret,
    };
    await userService
      .saveAdUserToken(obj)
      .then((response) => {
        setIsLoading(false);
        if (response?.status === 200) {
          getCourseListForUser(response);
          // window.location.replace("/dashboard");
          // if (!response?.data?.dataList?.accept_tnc) {
          //   navigate("/agree-terms", {
          //     state: {
          //       component: "quater-terms",
          //     },
          //   });
          // } else {
          //   getQuaterlyChecks();
          // }
        }
      })
      .catch((error) => {
        if (
          error.response.data.detail === "Unauthorized Access" &&
          error.response.data.code === 401
        ) {
          setIsLoading(false);
          window.location.replace("/denied");
          setTimeout(() => {
            logoutHandle();
          }, 20000);
        }
        console.log(error);
      });
  };

  const getQuaterlyChecks = () => {
    let queryParam = `curMonth=${moment(new Date()).month() + 1}`;
    quaterlyTerms(queryParam).then((response) => {
      if (response?.status === 200) {
        if (response?.data?.dataList?.checked === true) {
          navigate("/dashboard");
        } else {
          navigate("/quater-terms", {
            state: {
              component: "dashboard",
            },
          });
        }
      }
    });
  };

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  if (isLoading) {
    return (
      <Spinner
        animation="border"
        variant="primary"
        as="span"
        role="status"
        aria-hidden="true"
      ></Spinner>
    );
  }
};

const mapDispatchToProps = (dispatch) => ({
  userLogin: (token, istemp) =>
    dispatch({ type: ActionTypes.LOGIN_USER, token, istemp }),
});
export default connect(null, mapDispatchToProps)(Login);
