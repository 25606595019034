import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";

const AddGrc = (props) => {
  const { showForm1, onClose, grcData, approvalsettings } = props;
  const grcRedirection = (user) => {
    localStorage.setItem(
      "currsso",
      btoa(atob(localStorage.getItem("currsso")) + "@@" + user)
    );
    window.open("/ssorequest", "_blank");
  };
  return (
    <Modal
      show={showForm1}
      onHide={onClose}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title> {"GRC"}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          {grcData.length > 0 ? (
            <>
              <Slider className="clientslider" {...approvalsettings}>
                {grcData?.map((data) => (
                  <div>
                    <Link
                      to=""
                      // target="_blank"
                      onClick={() => {
                        grcRedirection(data?.grc_username);
                      }}
                    >
                      <div className="aprlTxt">
                        <p style={{ fontSize: "20px" }}>{data?.grc_username}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </>
          ) : (
            <p class="mt-2 f-15 text-muted d-flex flex-column justify-content-center h-100 text-center">
              <strong>No GRC id's are available.</strong>
            </p>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default AddGrc;
