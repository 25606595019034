import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import Sidemenu from "../include/sidemenu";
import * as BrandService from "../../service/brandService";
import Header from "../include/header";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ModalTitle,
} from "react-bootstrap";
import Select from "react-select";
import CmsNewsForms from "./CmsNewsForm";
import * as Yup from "yup";
import * as CmsNewsService from "../../service/Cms-newsService";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function CMSNews() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userData = useSelector(selectUserData);
  const options = [
    { value: "News", label: "News" },
    { value: "Notice", label: "Notice" },
    { value: "Popup", label: "Popup" },
  ];
  const [filterdata, setfilterData] = useState({
    Type: "",
    from_date: "",
    to_date: "",
    status: "",
  });
  const [editData, seteditNotice] = useState({});
  const [branchcopy, setBranchDatacopy] = useState([]);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [noticeData, setNoticeData] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [viewnewshow, viewnewsetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const viewnewopen = () => viewnewsetShow(true);
  const viewnewclose = () => viewnewsetShow(false);
  const getData = (
    currentpage,
    pagesize,
    sort_by,
    search,
    paginate,
    type,
    from_date,
    to_date,
    status
  ) => {
    dispatch(setDisplayLoader("Display"));

    CmsNewsService.getNotices(
      currentpage,
      pagesize,
      sort_by,
      search,
      paginate,
      type,
      from_date,
      to_date,
      status
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let data = response?.data?.result;
        setNoticeData(response?.data?.result);
        setpageCount(response?.data?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getBranch = async () => {
    await BrandService.getBranch(false)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data2 = {
            value: x.id,
            label: x.name + " (" + x.code + ")",
            organisation:
              x?.organisation_name + " (" + x.organisation_code + ")",
          };
          data.push(data2);
        });
        setBranchDatacopy(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const editNotice = (data) => {
    seteditNotice(data);
    setEditForm(true);
    addnewopen();
  };
  const addnewopenForm = () => {
    addnewsetShow(true);
    setEditForm(false);
  };

  useEffect(() => {
    getBranch();
    getData(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      searchStr,
      true,
      filterdata.Type,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.status
    );
  }, [pageSizeNo]);
  const deleteNotice = (id) => {
    // let data = { id: id };
    CmsNewsService.deleteNotice(id)
      .then((res) => {
        getData(currentPage, pageSizeNo, "-id", "", true, "", "", "", "");
        if (res.data.message.includes("can't")) {
          toast.warning(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleShow = (e) => {
    // getData(
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   searchStr,
    //   true,
    //   filterdata.Type,
    //   filterdata.from_date,
    //   filterdata.to_date
    // );
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };
  const submitDelete = (id, type) => {
    confirmAlert({
      title: "Delete " + type,
      message: `Are you sure to delete ` + type + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteNotice(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getData(
      activePage,
      pageSizeNo,
      "-id",
      "",
      true,
      filterdata?.Type,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.status
    );
    setCurrentPage(activePage);
  };
  const mainDataFilter = () => {
    console.log(" filterdata?.status", filterdata?.status);
    setFilterApplied(true);
    getData(
      currentPage,
      pageSizeNo,
      "-id",
      "",
      true,
      filterdata?.Type,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.status
    );

    filterclose();
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getData(1, pageSizeNo, sortData.sort_by, "", true, "", "", "", "");
    }
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getData(
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      searchStr,
      true,
      filterdata.Type,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.status
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getData(
        currentPage,
        pageSizeNo,
        "-id",
        e.target.value.toLowerCase(),
        true,
        "",
        "",
        "",
        ""
      );
      setSearchStr(e.target.value);
    }
  };
  const clearfilter = () => {
    setFilterApplied(false);
    filterclose();
    getData(currentPage, pageSizeNo, "-id", "", true, "", "", "", "");
    setfilterData({
      Type: "",
      from_date: "",
      to_date: "",
      status: "",
    });
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">News, Notice & Popup</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/home">CMS</Breadcrumb.Item>
                <Breadcrumb.Item active>News, Notice & Popup</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Title,
                        <br />
                        Display On
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Title, Display On..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                <button onClick={filteropen} class="btn btn-secondary-inner" style={{
                  backgroundColor: filterApplied && "yellow",
                  color: filterApplied && userData?.dark_mode === false && "#000"
                }}>
                  <img src="images/filter.png" alt="" class="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    class="btn btn-primary-inner"
                    onClick={addnewopenForm}
                  >
                    <img src="images/upload.png" alt="" class="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
              {finalUrlValue?.E || finalUrlValue?.R ? <th>Actions</th> : ""}
                <th
                  onClick={(e) => {
                    handlerData("Type");
                  }}
                  className={
                    sortData["Type"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["Type"]
                  }
                >
                  Type
                </th>
                <th>Title</th>
                <th>Occurrence</th>
                <th>Display On</th>
                <th>Branch</th>
                <th
                onClick={(e) => {
                    handlerData("created_at");
                  }}
                  className={
                    sortData["created_at"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["created_at"]
                  }
                >
                Created At
                </th>
                <th
                  onClick={(e) => {
                    handlerData("startDateTime");
                  }}
                  className={
                    sortData["startDateTime"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["startDateTime"]
                  }
                >
                
                  Start Date & Time
                </th>
                <th
                  onClick={(e) => {
                    handlerData("endDateTime");
                  }}
                  className={
                    sortData["endDateTime"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["endDateTime"]
                  }
                >
                  End Date & Time
                </th>
                <th>Last updated by</th>
                <th>Last updated on</th>
                <th>Approver</th>
                <th
                  onClick={(e) => {
                    handlerData("status");
                  }}
                  className={
                    sortData["status"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["status"]
                  }
                >
                  Status
                </th>
                <th>Approval Status</th>
                {/* {finalUrlValue?.E || finalUrlValue?.R ? <th>Actions</th> : ""} */}
              </tr>
            </thead>
            <tbody>
              {noticeData?.length ? (
                noticeData?.map((x) => (
                  <tr>
                  {finalUrlValue?.E || finalUrlValue?.R ? (
                      <td>
                        {finalUrlValue?.E ? (
                          <button
                            className="btn btn-blanktd text-primary"
                            // disabled={x?.status_approve === "Approved"}
                            onClick={() => {
                              editNotice(x);
                            }}
                          >
                            <i className="far fa-edit"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        {finalUrlValue?.R ? (
                          <button
                            className="btn btn-blanktd text-danger ml-2"
                            onClick={() => {
                              submitDelete(x?.id, x?.Type);
                            }}
                          >
                            <i className="far fa-trash-alt text-danger"></i>
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                    <td class="text-theme fw-bold">{x.Type}</td>
                    <td>{x?.title}</td>
                    <td>{x.occurrence ? x.occurrence : "N/A"}</td>
                    <td>
                      {x?.display_on != null
                        ? x?.display_on?.map((x) => x + ",")
                        : ""}
                    </td>
                    <td>{x?.branch.map((x) => x?.name + ",")}</td>
                    <td>{moment.utc(x?.created_at).format('DD-MM-YYYY, HH:mm')}</td>
                    {x?.startDateTime !== null ? (
                      <td>
                        {moment
                          .utc(x?.startDateTime)
                          .format("DD-MM-YYYY,HH:mm")}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    {x?.endDateTime !== null ? (
                      <td>
                        {moment.utc(x?.endDateTime).format("DD-MM-YYYY,HH:mm")}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    <td>{x?.updated_by}</td>
                    <td>
                      {moment.utc(x?.updated_at).format("DD-MM-YYYY,HH:mm")}
                    </td>
                    <td>{x?.approved_at}</td>
                    {x.status ? (
                      <td>
                        <span className="badge bad-status badge-success">
                          Active
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span class="badge bad-status badge-danger">
                          InActive
                        </span>
                      </td>
                    )}
                    {/* <td>{x?.status_approve}</td> */}
                    <td>
                      {x?.status_approve == "Approved" ||
                        x?.status_approve == "A" ? (
                        <span class="badge bad-status badge-success">
                          Approved{" "}
                        </span>
                      ) : x?.status_approve == "Pending" ||
                        x?.status_approve == null ? (
                        <span class="badge bad-status badge-warning">
                          Pending{" "}
                        </span>
                      ) : x?.status_approve == "Declined" ||
                        x?.status_approve == "R" ||
                        x?.status_approve == "D" ? (
                        <span class="badge bad-status badge-danger">
                          Declined{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}>NO Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {noticeData?.length ? (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control"
                  onChange={(e) => {
                    handleShow(e);
                  }}
                  style={{ textAlign: "center" }}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>100</option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Type</label>
            <select
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  Type: e.target.value,
                }))
              }
              value={filterdata?.Type}
              name="Type"
              class="form-control newbgselect"
            >
              <option value={null}>Select Type</option>
              <option value="News">News</option>
              <option value="Notice">Notice</option>
              <option value="Popup">Popup</option>
            </select>
          </div>
          <div class="form-group innergroup">
            <label>From Date</label>
            <input
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  from_date: e.target.value,
                }))
              }
              value={filterdata?.from_date}
              max={moment(filterdata.to_date).format("YYYY-MM-DD")}
              name="from_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          <div class="form-group innergroup">
            <label>To Date</label>
            <input
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  to_date: e.target.value,
                }))
              }
              value={filterdata?.to_date}
              min={moment(filterdata.from_date).format("YYYY-MM-DD")}
              name="to_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          <div class="form-group innergroup">
            <label>Status</label>
            <select
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  status: e.target.value,
                }))
              }
              value={filterdata?.status}
              name="status"
              class="form-control newbgselect"
            >
              <option value="">Select Type</option>
              <option value={true}>Active</option>
              <option value={false}>In-Active</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => clearfilter()}>
            Clear
          </button>
          <button
            type="submit"
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {addnewshow && (
        <CmsNewsForms
          open={addnewshow}
          branchcopy1={branchcopy}
          onhide={addnewclose}
          getdata={() =>
            getData(currentPage, pageSizeNo, "-id", "", true, "", "", "", "")
          }
          data={editData}
          editForm={editForm}
        />
      )}

      <Modal
        show={viewnewshow}
        onHide={viewnewclose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>News</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row news-detail">
                <div class="col-md-12 mb-3 mb-sm-4">
                  <h2>
                    PI Industries Vice Chairman and MD Mayank Singhal's formula
                    for Consistent Growth
                  </h2>
                  <h4>
                    Mayank Singhal speaks as fast as he thinks. It reflects on
                    his company's growth
                  </h4>
                </div>
                <div class="col-md-5">
                  <img src="images/new-detail.jpg" alt="" />
                </div>
                <div class="col-md-7">
                  <p>
                    If you think you speak fast, you should meet Mayank Singhal.
                    The Vice Chairman and MD of PI Industries, a major player in
                    agrochemicals, can dumbfound you by his speed of thought and
                    speech. And his company's growth is a worthy match. More
                    important is the consistency. Despite the Covid-19 pandemic,
                    PI Industries has grown its revenue and profits each year
                    since 2018.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CMSNews;
