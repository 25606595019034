import axios from "../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getShiftList = async () => {
    return axios.get(`${AUTH_BASE_URL}/shift`);
  }
  export const getReasonList = async () => {
    return axios.get(`${AUTH_BASE_URL}/shift/reason`);
  }
  export const getPreviousShiftList = async () => {
    return axios.get(`${AUTH_BASE_URL}/shift/previous_shift`);
  }
  export const saveShiftChangeData = async (shiftChangeDetails) => {
    return axios.post(`${AUTH_BASE_URL}/shift/shift_change`, shiftChangeDetails);
  }
  export const getShiftChangeData = async () => {
    return axios.get(`${AUTH_BASE_URL}/shift/shift_change`);
  }
  export const updateShiftChangeData = async (id,data) => {
    return axios.put(`${AUTH_BASE_URL}/shift/shift_change/${id}`, data);
  }

  export const getShiftChangeById = async (id) => {
    return axios.get(`${AUTH_BASE_URL}/shift/shiftById/${id}`);
  }

  export const getLoanById = async (id) => {
    return axios.get(`${AUTH_BASE_URL}/loan/loanById/${id}`);
  }

  export const getSalaryById = async (id) => {
    return axios.get(`${AUTH_BASE_URL}/loan/salaryById/${id}`);
  }

  export const changeShiftStatus = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/shift/shiftStatus`, data);
  }

  export const changeLoanRequestStatus = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/loan/action`, data);
  }

  export const changeSalaryAdvRequestStatus = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/loan/action/salaryAdv`, data);
  }

  export const getVPFById = async (id) => {
    return axios.get(`${AUTH_BASE_URL}/vpf/vpfById/${id}`);
  }

  export const changeVPFRequestStatus = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/vpf/action`, data);
  }