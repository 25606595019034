import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import {
  Tabs,
  Tab,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import CustomMenu from "./CustomMenu";

function AddNewMater() {
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <CustomMenu />
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Add New Material</h4>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transaction</Breadcrumb.Item>
                    <Breadcrumb.Item href="/material-req">
                      Material Request
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Add New</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              <Tabs defaultActiveKey="Create" id="approvaltabs">
                <Tab
                  eventKey="Create"
                  title={<React.Fragment>Select Category</React.Fragment>}
                >
                  <div class="px-4 py-2">
                    <h6 class="fw-bold mb-1">Select Material Category</h6>
                    <p class="f-14 text-muted">
                      Read the description carefully.
                    </p>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="privatepublic permissionpublic mb-2 innergroup">
                          <label class="logCheck d-block">
                            ESRA
                            <br />
                            <span>
                              Do you want to create material code for
                              Engineering Spares (94 series)? Example -
                              Coupling, Flange, Bearing, Valve etc.
                            </span>
                            <input type="radio" name="Material" checked />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-block">
                            GEN
                            <br />
                            <span>
                              Do you want to create material code for General
                              purchase (95 series)? Example - Computer
                              Accessories, Housekeeping Items, Stationery,
                              Building material, Hardware etc.
                            </span>
                            <input type="radio" name="Material" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-block">
                            ZLAB
                            <br />
                            <span>
                              Do you want to create material code for Lab
                              consumables/ spares (96 series)? Example - Funnel,
                              Beaker, Flask etc.
                            </span>
                            <input type="radio" name="Material" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-block">
                            ZR&D
                            <br />
                            <span>
                              Do you want to create material code for R&amp;D
                              Chemicals (97 series)? Example - Benzonitrile,
                              Isopropanol etc.
                            </span>
                            <input type="radio" name="Material" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="privatepublic permissionpublic mb-2 innergroup">
                          <label class="logCheck d-block">
                            ZSRC
                            <br />
                            <span>
                              Do you want to create material code for CRE
                              Research Chemicals (76 series)? Example - Agar,
                              Benzoic Acid etc.
                            </span>
                            <input type="radio" name="Material" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-block">
                            ROH
                            <br />
                            <span>
                              Do you want to create material code for RM used in
                              production (93 series)? Example - Ethyl Mercaptan,
                              Diethylene Glycol etc.
                            </span>
                            <input type="radio" name="Material" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-block">
                            VERP
                            <br />
                            <span>
                              Do you want to create material code for PM used in
                              production (92 series)? Example - Bag HDPE, Pet
                              Bottle, Label etc.
                            </span>
                            <input type="radio" name="Material" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-block">
                            ZCRE
                            <br />
                            <span>
                              Do you want to create material code for CRE
                              Biology (77 series)? Example - Helicoverpa
                              armigera, Spodoptera litura etc.
                            </span>
                            <input type="radio" name="Material" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="Review"
                  title={<React.Fragment>Create Material</React.Fragment>}
                >
                  <div class="px-4 py-2">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="form-group innergroup">
                          <label>
                            Material Description{" "}
                         <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Material Description"
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Select Plant<span class="text-danger">*</span>
                          </label>
                          <Select
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Industry Indicator{" "}
                         <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            value="C"
                            disabled
                            class="form-control"
                            placeholder="Enter Industry Indicator"
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Material Group<span class="text-danger">*</span>
                          </label>
                          <Select
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Material Type<span class="text-danger">*</span>
                          </label>
                          <select class="form-control newbgselect">
                            <option>Select</option>
                            <option>ERSA - Spare Parts</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Unit<span class="text-danger">*</span>
                          </label>
                          <Select
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Material Category<span class="text-danger">*</span>
                          </label>
                          <select class="form-control newbgselect">
                            <option>Select</option>
                            <option selected>A</option>
                            <option>B</option>
                            <option>C</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Storage Location<span class="text-danger">*</span>
                          </label>
                          <select class="form-control newbgselect">
                            <option>Select</option>
                            <option>0001 - Main Store</option>
                            <option>0003 - Capital Goods</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Valuation Class<span class="text-danger">*</span>
                          </label>
                          <select class="form-control newbgselect">
                            <option selected="selected" value="">
                              Select
                            </option>
                            <option value="8300">
                              8300 - Inv. Store/Spare-Electrical
                            </option>
                            <option value="8301">
                              8301 - Inv. Store/Spare-Mechanical
                            </option>
                            <option value="8302">
                              8302 - Inv. Store/Spare-Instrument
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Include Variation Type{" "}
                         <span class="text-danger">*</span>
                          </label>
                          <select class="form-control newbgselect">
                            <option>Select</option>
                            <option selected>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Variation Type<span class="text-danger">*</span>
                          </label>
                          <select class="form-control newbgselect">
                            <option>Select</option>
                            <option selected>Damaged</option>
                            <option>Good</option>
                            <option>Repaired</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reference Material No.{" "}
                         <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            placeholder="Enter Reference Material No."
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Technical Description for HSN Code{" "}
                         <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Technical Description for HSN Code"
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Additional Description{" "}
                         <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Additional Description"
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Manufacturer Part No.{" "}
                         <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Manufacturer Part No."
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Serial No. Profile{" "}
                         <span class="text-danger">*</span>
                          </label>
                          <select class="form-control newbgselect">
                            <option selected="" value="">
                              -Select-
                            </option>
                            <option value="ZPMA">
                              ZPMA - Administrative Equipment
                            </option>
                            <option value="ZPME">
                              ZPME - Pollution Control Equipment
                            </option>
                            <option value="ZPMF">
                              ZPMF - Fire Fighting Equipment
                            </option>
                            <option value="ZPMK">
                              ZPMK - Instrumentation Equipment
                            </option>
                            <option value="ZPML">
                              ZPML - Electrical Equipment
                            </option>
                            <option value="ZPMP">
                              ZPMP - Process Equipment
                            </option>
                            <option value="ZPMQ">
                              ZPMQ - Test/measur Equipment
                            </option>
                            <option value="ZPMU">
                              ZPMU - Utility Equipment
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Planned Delivery Time in Days{" "}
                         <span class="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Enter Planned Delivery Time in Days"
                          />
                        </div>
                      </div>

                      <div class="col-md-12 mt-4">
                        <Link
                          to="/material-req"
                          class="btn btn-outline-secondary mr-3"
                        >
                          <i class="far fa-times-circle"></i> Cancel
                        </Link>
                        <button class="btn btn-outline-primary mr-3">
                          <i class="far fa-arrow-alt-circle-left"></i> Previous
                        </button>
                        <button class="btn btn-primary-inner bpi-main">
                          <i class="far fa-check-circle"></i> Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewMater;
