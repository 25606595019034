import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as LoanDataService from "../../service/loansalary";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const LoanApproval = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const [filterApplied, setFilterApplied] = useState(false);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => {
    setFilterApplied(false);
    filtersetShow(false);
  }
  const [userLoanData, setUserLoanData] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [salaryApproverFilter, setSalaryApproverFilter] = useState({
    type: "",
  });
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getUserLoanForApproval(currentPage, pageSizeNo, sortData.sort_by, true);
  }, [pageSizeNo]);

  const getUserLoanForApproval = (page_no, page_size, sort_by, paginate) => {
    LoanDataService.getUserLoan_forApproval(
      handleKey(page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        setUserLoanData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    return queryParm;
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getUserLoanForApproval(activePage, pageSizeNo, sortData.sort_by, true);
    setCurrentPage(activePage);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
      let data = userLoanData;
      let data1 = [];
      data.map((res) => {
        if (
          res?.type.toLowerCase().includes(e.target.value) ||
          res?.loan_type.toLowerCase().includes(e.target.value) ||
          res?.apply_date.toLowerCase().includes(e.target.value) ||
          res?.emp_code.toLowerCase().includes(e.target.value) ||
          // res?.updated_at.toLowerCase().includes(e.target.value) ||
          res?.comments.toLowerCase().includes(e.target.value) ||
          res?.emp_fullname.toLowerCase().includes(e.target.value)
        ) {
          data1.push(res);
        }
      });
      setUserLoanData(data1);
    }
    if (e.target.value.length === 0) {
      getUserLoanForApproval(currentPage, pageSizeNo, sortData.sort_by, true);
    }
  };

  const mainDataFilter = (salaryApproverFilter) => {
    setFilterApplied(true);
    getUserLoanForApproval(currentPage, pageSizeNo, sortData.sort_by, true);
    filterclose();
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      getUserLoanForApproval(currentPage, pageSizeNo, sortData.sort_by, true);
      setSearchStr("");
    }
  };

  const handleExport = (pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    // if (status !== "" && status !== undefined && status !== null) {
    //   queryParm = queryParm + "&status=" + status;
    // }
    // if (search !== "" && search !== undefined && search !== null) {
    //   queryParm = queryParm + "&search=" + search;
    // }
    // if (from_date !== "" && from_date !== undefined && from_date !== null) {
    //   queryParm = queryParm + "&from_date=" + from_date;
    // }
    // if (to_date !== "" && to_date !== undefined && to_date !== null) {
    //   queryParm = queryParm + "&to_date=" + to_date;
    // }

    return queryParm;
  };

  const getExportValue = (pageNo, page_size, sort_by, paginate) => {
    LoanDataService.getExportValue(
      handleExport(pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "loanreport.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      <div>
        <div class="row">
          {/* <div class="col-md-3">
                <ul class="policylistnames">
                  <li>
                    <Link to="/loan-apply">Loan & Salary Advance</Link>
                  </li>
                  <li>
                    <Link to="/loan-report">Loan & Salary Advance Report</Link>
                  </li>
                  <li>
                    <Link to="/salary-approver">Approver Assignment</Link>
                  </li>
                  <li class="active">
                    <Link to="/loan-approval">For Approval</Link>{" "}
                  </li>
                </ul>
              </div> */}
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Loan & Salary Advance</h4>
                    {/* <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                          <Breadcrumb.Item active>
                            Loan & Salary Advance
                          </Breadcrumb.Item>
                        </Breadcrumb> */}
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Advance Type,
                              <br />
                              Advance No,
                              <br />
                              Amount
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          id="searchbar"
                          type="text"
                          class="form-control"
                          placeholder="Search on Advance Type, Advance No, Amount..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              // disabled={changeShiftData.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportValue(
                                  currentPage,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <Tabs defaultActiveKey="approval" id="approvaltabs">
                  <Tab
                    eventKey="approval"
                    title={
                      <React.Fragment>
                        Pending
                        {/* <span class="numberpurchse">11</span> */}
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            {/* <th>
                                  <label class="logCheck d-inline-block">
                                    <input type="checkbox" name="permission" />
                                    <span class="checkmark"></span>
                                  </label>
                                </th> */}
                            <th>Status</th>
                            <th>Advance Type</th>
                            <th>Loan Type</th>
                            <th>Applied Date</th>
                            {/* <th>Advance No</th> */}
                            <th>Employee Code</th>
                            <th>Employee Name</th>
                            <th>Amount</th>
                            <th>Document No</th>
                            <th>Attachment</th>
                            <th>Last Modified</th>
                            <th>Remarks</th>
                            <th>Exception Reason</th>
                            <th>SAP Response</th>
                            <th>SAP Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userLoanData
                            ?.filter((res) => {
                              if (res.status === "P") {
                                return true;
                              }
                            })
                            .map((data) => {
                              return (
                                <tr>
                                  {/* <td>
                                  <label class="logCheck d-inline-block">
                                    <input type="checkbox" name="permission" />
                                    <span class="checkmark"></span>
                                  </label>
                                </td> */}
                                  <td>
                                    <span class="badge bad-status badge-warning">
                                      Pending
                                    </span>
                                  </td>
                                  <td class="text-theme fw-bold">
                                    {data?.type}
                                  </td>
                                  <td>{data?.loan_type}</td>
                                  <td>
                                    {moment(data?.apply_date).format(
                                      "Do MMM YYYY"
                                    )}
                                  </td>
                                  {/* <td></td> */}
                                  <td>{data?.emp_code}</td>
                                  <td>{data?.emp_fullname}</td>
                                  <td>{data?.amount}</td>
                                  <td></td>
                                  {data?.attachment !== undefined ? (
                                    <td>
                                      <a
                                        href={
                                          data?.type == "Loan" ||
                                            data?.type == "Loan(Exceptional Case)"
                                            ? data?.attachment[0]
                                              ?.attachment_path
                                            : data?.attachment_path
                                        }
                                        target="_blank"
                                      >
                                        <i class="fas fa-paperclip"></i> View
                                        Doc
                                      </a>
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                  <td>
                                    {moment(data?.updated_at).format(
                                      "Do MMM YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <div class="remarks_text">
                                      {data?.comments}
                                    </div>
                                  </td>
                                  <td>
                                    {data?.exception_reason}
                                  </td>
                                  <td>
                                    <button class="btn btn-blanktd">
                                      <span class="badge badge-secondary">
                                        <i class="far fa-copy"></i> Re-Post
                                      </span>
                                    </button>
                                  </td>
                                  <td></td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    {userLoanData?.length == 0 ? (
                      ""
                    ) : (
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="sortinglist">
                            Show
                            <select
                              class="form-control"
                              onChange={(e) => {
                                setPageSizeNo(e.target.value);
                                setCurrentPage(1);
                              }}
                            >
                              <option value={10} label={10}>10</option>
                              <option value={20} label={20}>20</option>
                              <option value={30} label={30}>30</option>
                              <option value={100} label={100}>100</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-7">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-left"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}

                    {/* <div class="row jvactionsbutton">
                          <div class="col-md-12">
                            <div class="p-3">
                              <button class="btn btn-danger mr-2">
                                <i class="far fa-times-circle"></i> Reject
                              </button>
                              <button class="btn btn-success">
                                <i class="far fa-check-circle"></i> Approved
                              </button>
                            </div>
                          </div>
                        </div> */}
                  </Tab>

                  <Tab
                    eventKey="approved"
                    title={<React.Fragment>Approved</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th>Advance Type</th>
                            <th>Loan Type</th>
                            <th>Applied Date</th>
                            {/* <th>Advance No</th> */}
                            <th>Employee Code</th>
                            <th>Employee Name</th>
                            <th>Amount</th>
                            <th>Document No</th>
                            <th>Attachment</th>
                            <th>Last Modified</th>
                            <th>Remarks</th>
                            <th>Exception Reason</th>
                            <th>SAP Response</th>
                            <th>SAP Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userLoanData?.length > 0 ? (
                            userLoanData
                              ?.filter((res) => {
                                if (res.status === "A") {
                                  return true;
                                }
                              })
                              .map((data) => {
                                return (
                                  <tr>
                                    <td>
                                      <span class="badge bad-status badge-success">
                                        Approved
                                      </span>
                                    </td>
                                    <td class="text-theme fw-bold">
                                      {data?.type}
                                    </td>
                                    <td>{data?.loan_type}</td>
                                    <td>
                                      {moment(data?.apply_date).format(
                                        "Do MMM YYYY"
                                      )}
                                    </td>
                                    {/* <td></td> */}
                                    <td>{data?.emp_code}</td>
                                    <td>{data?.emp_fullname}</td>
                                    <td>{data?.amount}</td>
                                    <td></td>
                                    {data?.attachment !== undefined ? (
                                      <td>
                                        <a
                                          href={
                                            data?.type == "Loan" ||
                                              data?.type ==
                                              "Loan(Exceptional Case)"
                                              ? data?.attachment[0]
                                                ?.attachment_path
                                              : data?.attachment_path
                                          }
                                          target="_blank"
                                        >
                                          <i class="fas fa-paperclip"></i> View
                                          Doc
                                        </a>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    <td>
                                      {moment(data?.updated_at).format(
                                        "Do MMM YYYY"
                                      )}
                                    </td>
                                    <td>{data?.comments}</td>
                                    <td>
                                      {data?.exception_reason}
                                    </td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                );
                              })
                          ) : (
                            <tr>
                              <td colSpan={12}>No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select class="form-control">
                            <option>10</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Tab>

                  <Tab
                    eventKey="rejected"
                    title={<React.Fragment>Rejected</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th>Advance Type</th>
                            <th>Loan Type</th>
                            <th>Applied Date</th>
                            {/* <th>Advance No</th> */}
                            <th>Employee Code</th>
                            <th>Employee Name</th>
                            <th>Amount</th>
                            <th>Document No</th>
                            <th>Attachment</th>
                            <th>Last Modified</th>
                            <th>Remarks</th>
                            <th>Exception Reason</th>
                            {/* <th>SAP Response</th> */}
                            {/* <th>SAP Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {userLoanData?.length > 0 ? (
                            userLoanData
                              ?.filter((res) => {
                                if (res.status === "D") {
                                  return true;
                                }
                              })
                              .map((data) => {
                                return (
                                  <tr>
                                    <td>
                                      <span class="badge bad-status badge-danger">
                                        Rejected
                                      </span>
                                    </td>
                                    <td class="text-theme fw-bold">
                                      {data?.type}
                                    </td>
                                    <td>{data?.loan_type}</td>
                                    <td>
                                      {moment(data?.apply_date).format(
                                        "Do MMM YYYY"
                                      )}
                                    </td>
                                    {/* <td></td> */}
                                    <td>{data?.emp_code}</td>
                                    <td>{data?.emp_fullname}</td>
                                    <td>{data?.amount}</td>
                                    <td></td>
                                    {data?.attachment !== undefined ? (
                                      <td>
                                        <a
                                          href={
                                            data?.type == "Loan" ||
                                              data?.type ==
                                              "Loan(Exceptional Case)"
                                              ? data?.attachment[0]
                                                ?.attachment_path
                                              : data?.attachment_path
                                          }
                                          target="_blank"
                                        >
                                          <i class="fas fa-paperclip"></i> View
                                          Doc
                                        </a>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    <td>
                                      {moment(data?.updated_at).format(
                                        "Do MMM YYYY"
                                      )}
                                    </td>
                                    <td>{data?.comments}</td>
                                    <td>
                                      {data?.exception_reason}
                                    </td>
                                    {/* <td></td> */}
                                    {/* <td></td> */}
                                  </tr>
                                );
                              })
                          ) : (
                            <tr>
                              <td colSpan={12}>No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select class="form-control">
                            <option>10</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div class="form-group innergroup">
            <label>
              Employee ID<span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div>

          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div> */}

          <div class="form-group innergroup">
            <label>Advance Type</label>
            <select
              name="type"
              class="form-control newbgselect"
              value={salaryApproverFilter?.type}
              onChange={(e) => {
                setSalaryApproverFilter({
                  ...salaryApproverFilter,
                  type: e.target.value,
                });
              }}
            >
              <option>Select</option>
              <option>Loan</option>
              <option>Salary</option>
            </select>
          </div>

          {/* <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>Pending</option>
              <option>Approved</option>
              <option>Rejected</option>
            </select>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => {
              mainDataFilter(salaryApproverFilter);
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoanApproval;
