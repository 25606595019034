import { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Root.module.css";
import * as BillProcessService from "../../../../service/LRGRPortal/billtaggingservices";
import { confirmAlert } from "react-confirm-alert";
import {getPlant} from "../../../../service/LRGRPortal/plantService";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Dropdown } from "react-bootstrap";
import CustomPagination from "../../../CashbookProfile/CustomPagination";
import Select from "react-select";
import { Tooltip } from "react-tippy";
import moment, { invalid } from "moment";
import { useDispatch } from "react-redux";
import { setDisplayLoader, selectLoader } from "../../../../pages/redux/piDataStore";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";



const Root = ()=>{
    const dispatch = useDispatch();
    const handleLoader = (show = true) =>{
      dispatch(setDisplayLoader( show ? "Display" : "Hide"));
    }
    let status = "D";
    const userList = [12,3];
   const subPersonalArea = ["one", 'two',"three"]
    const [departmentDataForExport, setDepartmentDataForExport] = useState({
        business_units_id: "",
        personal_area_id: "",
        sub_personal_area_id: "",
        designation_id: "",
        status: "",
      });

      const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
      const [searchStr, setSearchStr] = useState("");
      const [transporterd, settransporterd] = useState("");
      const [pageSizeNo, setPageSizeNo] = useState(10);
      const [pageCount, setpageCount] = useState(1);
      const [freightReqListData, setfreightReqListData] = useState([]);
      const [plantcode, setPlantcode] = useState("");
      const [transportercode, setTransportercode] = useState("");
      const [statustype,setStatustype] = useState("");
      const [managerType, setManagerType] = useState();
      const [showAddNew, setShowAddNew] = useState(false)
      const [currentPage, setCurrentPage] = useState(1);
      const [statuslist, setStatuslist] = useState([])
      const [plantallcode, setPlantallcode] = useState([])
      const [transporterallcode, setTransporterallcode] = useState([]);
      
      const [statusalltype, setStatusalltype] = useState([])
    const [loader, setLoader] = useState(false);
    const [transportData, setTransportData] = useState([]);
    const [transportSelectedData, setTrandportSeletedData] = useState([]);
    const [pendingData, setPendingData] = useState([]);
    const [apporvedData, setApprovedData] = useState([]);
    const [savedData, setSaveData] = useState([]);
    const [dateData, setDateData] = useState([]);


    const [show, setShow] = useState(false);
    const [filterState, setFilterState] = useState({});
    const filteropen = () => setShow(true);
    const filterclose = () => setShow(false);
    const [prTypeOptions, setPrTypeOptions] = useState([]); //just for code run
    
    const navigate = useNavigate();
    const [freightDataForExport, setFreightDataForExport] = useState({
      plant_id: [],
      invoice_number: "",
      status: [],
      bill_number:"",
      transporter_id:[],   
    });
    const multi_status = [];
    const mutli_plant =  [] ;
    const multi_tranporter = [];
  const [freightDataFilter, setFreightDataFilter] = useState({
  invoice_number: "",
  status: [],
  bill_number:"",
  transporter_id:[],  
  });




                    const da = [{ 
                        value: "1",
                        label: "Delhi" + "(" +"84873"+ ")",
                    },
                    { 
                        value: "2",
                        label: "Mumbai" + "(" +"34443"+ ")",
                    },
                    { 
                        value: "3",
                        label: "Banglore" + "(" +"80980"+ ")",
                    },
                    ]
                                  

                    const statuslis = [
                      {value: "1", label: "Pending"},
                      { value: "2",label: "Approved"},
                      {value: "3", label: "Rejected"},
                      {value: "4",label: "Save as Draft"}]
            
                const statuslis_lm = [
                      { value: "1", label: "Pending"},
                      {value: "2",label: "Approved"},
                      {value: "3",label: "Rejected"}]

        useEffect(()=>{
  
      setPlantallcode(da)
      
      setStatusalltype(statuslis)
  
    
        },[]);
        useEffect(() => {
          getFreight(
            searchStr,
            freightDataFilter.plant_id,
            freightDataFilter.invoice_number,
            freightDataFilter.status,
            freightDataFilter.bill_number,
            freightDataFilter.transporter_id,
            currentPage,
            pageSizeNo,
            sortData.sort_by
          );
        }, [pageSizeNo]);

        const getFreight = (
          search,
          plant_id,
          invoice_number,
          status,
          bill_number,
          transporter_id,
          pageCount,
          pageSizeNo,
          sort_by,
        ) => {
        console.log("calling freight")
          handleLoader();
          BillProcessService
            .getInvofrList(
              handleKey(
                search,
                plant_id,
                invoice_number,
                status,
                bill_number,
                transporter_id,
                pageCount,
                pageSizeNo,
                sort_by,
    
              )
            )
            .then((response) => {
              if(response?.data?.items[0]?.mesage == "No data found"){
                setfreightReqListData([]);
                if(response.data.manager=="LM"){
                  setShowAddNew(true)
                  setManagerType(response.data.manager)
                }else if(response.data.manager=="PM"){
                  setShowAddNew(false)
                  setManagerType(response.data.manager)
                }
              } else if(response?.data?.items[0]?.mesage == "You dont have access to view lr gr request"){
                setfreightReqListData([]);
                if(response.data.manager=="LM"){
                  setShowAddNew(true)
                  setManagerType(response.data.manager)
                }else if(response.data.manager=="PM"){
                  setShowAddNew(false)
                  setManagerType(response.data.manager)
                }
            }
              else{
                setfreightReqListData(response.data.items);
                console.log("response for lsit", response.data)
                
              if(response?.data?.total > pageSizeNo){
                  const itemcount = (response?.data?.total)/pageSizeNo
                  setpageCount(Math.ceil(itemcount));
              }
              else{
                  setpageCount(1)
                }
              //   console.log("response", response)
 
              }
             
              if(response.data.manager=="LM"){
                setShowAddNew(true)
                setManagerType(response.data.manager)
              }else if(response.data.manager=="PM"){
                setShowAddNew(false)
                setManagerType(response.data.manager)
              }
            })
            .catch((error) => {
              console.log("error", error)
              console.log(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  "failed"
              ); 
            }).finally(()=>{
              handleLoader(false);
            });
        }

        const handleKey = (
          search,
          plant_id,
          invoice_number,
          status,
          bill_number,
          transporter_id,
          pageCount,
          pageSizeNo,
          sort_by,
         
        ) => {
          console.log("page count", pageCount, "paze size", pageSizeNo)
          let queryParm =
            "?page=" +
            pageCount +
            "&page_size=" +
            pageSizeNo +
            "&sort_by=" +
            sort_by ;
    
            if (invoice_number !== "" && invoice_number !== undefined && invoice_number !== null) {
              queryParm = queryParm + "&invoice_number=" + invoice_number;
            }
      
            if(status?.length != 0){
                      status.map((elem,i)=>{
                        if(elem =="A"){
                          queryParm = queryParm + "&status=" + "A";
                        }
                       if(elem =="D"){
                          queryParm = queryParm + "&status=" + "D";
                        }
                        if(elem =="R"){
                          queryParm = queryParm + "&status=" + "R";
                        }
                        if(elem =="P"){
                          queryParm = queryParm + "&status=" + "P";
                        }
                      
                      })
                     
            }else{
              if (status !== "" && status !== undefined && status !== null) {
              //  queryParm = queryParm + "&status=" + status;
              }
            }
        
          if (search !== "" && search !== undefined && search !== null) {
            queryParm = queryParm + "&search=" + search;
            console.log("search in find", queryParm)
          }
       
            if (
              plant_id !== "" &&
              plant_id !== undefined &&
              plant_id !== null
            ) {
             queryParm = queryParm + "&plant_id=" + plant_id;
            //console("plant id find", plant_id)
            }
          
        
          if (
            bill_number !== "" &&
            bill_number !== undefined &&
            bill_number !== null
          ) {
            queryParm = queryParm + "&bill_number=" + bill_number;
          }
          if (
            transporter_id !== "" &&
            transporter_id !== undefined &&
            transporter_id !== null
          ) {
            queryParm = queryParm + "&transporter_id=" + transporter_id;
          }
          console.log('query param', queryParm)
          return queryParm;
        }

        const getStatus = ()=>{
          // if(showAddNew){
          //   setStatuslist(statuslis_lm)
          // }else {
            setStatuslist(statuslis)
         // }
        }

        const filterShow = () => {
          filteropen();
          getStatus();
       //   getAllPlant();
        };
        useEffect(() => {
    
          const params = new URLSearchParams(window.location.search);
         // setMode(params.get("start"));
         let query= "";
      
         if(params.get("start_date") !=null && params.get("end_date") !=null){
          //query = "start_date=" + params.get("start_date")+ "&end_date=" + params.get("end_date");
          getFreight(searchStr, "", "", "","","", 1, pageSizeNo, sortData.sort_by);
          // TransportServices.
          // getInvoiceReqList(query).then(res => {
            
          //   console.log("responseseee", res)
          //   if(res.data.items[0].mesage=="No data found"){
          //     setInvoiceReqListData([]);
          //   }else{
          //     setInvoiceReqListData(res.data.items);
          //   }
            
          //  // console.log("res.data", res.data.items, "hfdhls", res.data.items[0].mesage)
          
         
          // })
          //     .catch(err => {
          //         toast.error("Fetching Inovice List failed!")
          //         console.log(err);
          //     })
        
            }else{
              getFreight(searchStr, "", "", "","","", 1, pageSizeNo, sortData.sort_by);
              // TransportServices.
              // getInvoiceReqList().then(res => {
              //   console.log("resaaaaaaaaaaa", res)
              //   if(res.data.items[0].mesage=="No data found"){
              //     setInvoiceReqListData([]);
              //   }else{
              //     setInvoiceReqListData(res.data.items);
              //   }
      
              // })
              //     .catch(err => {
              //         toast.error("Fetching Inovice List failed!")
              //         console.log(err);
              //     })
            }
      
      }, []);

      const getAllPlant = () => {
        let data = [];
        handleLoader();
        getPlant()
          .then((response) => {
            response?.data?.dataList?.map((x) => {
              console.log("id", x.plant_id)
              let plantData = {
                value: x.plant_id,
                label: `${x?.plant_name +"("+ x?.plant_code +")"}`,
              };
              data.push(plantData);
            });
            console.log("data plant", data)
            setPlantallcode(data);
            console.log("plant fetch",response.data.dataList)
          })
          .catch((error) => {
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          }).finally(()=>{
            handleLoader(false);
          });
      };

  const handleeditclick = ()=>{
    alert("you will be redirect to edit page soon....")
    navigate("/billprocess/table")
   
  }

  const handleviewclick = ()=>{

    alert("view page will be show soon enough")
  //  navigate("/trans/billprocess/table?mode=view&id=" + el.id + "&fy=" + el.cfy)
  }
//  search,
//           plant_id,
//           invoice_number,
//           status,
//           bill_number,
//           transporter_id,
//           sort_by,
//           pageCount,
//           pageSizeNo,
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setCurrentPage(1);
      setSortData({ colName: "id", sort_by: "-id" });
      getFreight(
        e.target.value.toLowerCase(),
        freightDataFilter.plant_id,
        freightDataFilter.invoice_number,
        freightDataFilter.status,
        freightDataFilter.bill_number,
        freightDataFilter.transporter_id,
        1,
        pageSizeNo,
        "-id"
        
      );
      setSearchStr(e.target.value);
    }
  }
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    setCurrentPage(1);
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getFreight(
        data,
        freightDataFilter.plant_id,
        freightDataFilter.invoice_number,
        freightDataFilter.status,
        freightDataFilter.bill_number,
        freightDataFilter.transporter_id,
        1,
        pageSizeNo,
        sortData.sort_by
        
      );
    }
  };
  
  const handlerData = (backdata) => {
      let sdata = {};
      if (sortData["colName"] === backdata) {
    
        if (sortData["sort_by"].includes("-")) {
          sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
        } else {
          sdata = {
            colName: backdata,
            sort_by: "-" + backdata,
            [backdata]: "desccls",
          };
        }
      } else {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      }
      setSortData(sdata);
      getFreight(
        searchStr,
        freightDataFilter.plant_id,
        freightDataFilter.invoice_number,
        freightDataFilter.status,
        freightDataFilter.bill_number,
        freightDataFilter.transporter_id,
        1,
        pageSizeNo,
        sdata.sort_by
      );
  };
 
  const mainDataFilter = () => {
    console.log("main data filter")
    filterClose()
    console.log("freightDataFilter",freightDataFilter)
    if(freightDataFilter.invoice_number !=""){
      console.log("freightDataFilterr",freightDataFilter)
      setFreightDataForExport({
        ...freightDataForExport,
         plant_id : "",
         invoice_number : freightDataFilter.invoice_number,
         status : "",
         bill_number : "",
         transporter_id : "", 
      });
      const plant_id = "";
      const invoice_number = freightDataFilter.invoice_number;
      const status = "";
      const bill_number = "";
      const transporter_id ="";
      getFreight(
        searchStr,
        plant_id,
        invoice_number,
        status,
        transporter_id,
        bill_number,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        
      );
    }else{
      console.log("freightDataFilter",freightDataFilter)
      setFreightDataForExport({
        ...freightDataForExport,
        plant_id: freightDataFilter.plant_id,
        invoice_number: freightDataFilter.invoice_number,
        status: freightDataFilter.status,
        bill_number: freightDataFilter.bill_number,
        transporter_id: freightDataFilter.transporter_id,
      });
      const plant_id = freightDataFilter.plant_id;
      const invoice_number = freightDataFilter.invoice_number;
      const status = freightDataFilter.status;
      const bill_number = freightDataFilter.bill_number;
      const transporter_id = freightDataFilter.transporter_id;
      getFreight(
        searchStr,
        plant_id,
        invoice_number,
        status,
        bill_number,
        transporter_id,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        
      );
    }
 
    filterClose();
  };
  const filterClose = () => {
    filterclose();
  };
  const handlePageClick = (page) => {
    let activePage = page.selected + 1;
    setCurrentPage(activePage);
    getFreight(
      searchStr,
      freightDataFilter.plant_id,
      freightDataFilter.invoice_number,
      freightDataFilter.status,
      freightDataFilter.bill_number,
      freightDataFilter.transporter_id,
      activePage,
      pageSizeNo,
      sortData.sort_by
    );
  };

  // get export data excel
//   const getExportDetails = (
//     search,
//     is_sf,
//     business_units_id,
//     personal_area_id,
//     sub_personal_area_id,
//     designation_id,
//     status,
//     role,
//     pageNo,
//     page_size,
//     sort_by,
//     paginate
//   ) => {
//     dispatch(setDisplayLoader("Display"));
//     UserService.getExportValue(
//       handleKey(
//         search,
//         is_sf,
//         business_units_id,
//         personal_area_id,
//         sub_personal_area_id,
//         designation_id,
//         status,
//         role,
//         pageNo,
//         page_size,
//         sort_by,
//         paginate
//       )
//     )
//       .then((response) => {
//         dispatch(setDisplayLoader("Hide"));
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement("a");
//         link.href = url;
//         link.setAttribute("download", "users.xlsx"); //or any other extension
//         document.body.appendChild(link);
//         link.click();
//       })
//       .catch((error) => {
//         console.log(
//           (error.response &&
//             error.response.data &&
//             error.response.data.message) ||
//             "failed"
//         );
//       });
//   };
const  onchangehandler = (e)=>{

}
  const handlePageCountChange = (count) => {
    // const query = { ...data.query, page_size: count };
    // getData(query);
    setPageSizeNo(count);
    setCurrentPage(1);
    setSortData({ colName: "id", sort_by: "-id" });
  };
  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(freightDataFilter));
    console.log("clear filer", ClearData)
    Object.entries(ClearData).map((type) => {
      //console.log("object entries", type)
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        console.log(type[1],"type[1]");
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        console.log(type[1],"type[1]else");
      if (type[1].length > 0) {
          ClearData[type[0]] = "";
         }
      }
    });
    setFreightDataFilter({
      invoice_number: "",
      status: "",
      bill_number:"",
      transporter_id:"", 
    });
    setStatuslist([]);
    setTransportercode("");
    setTransporterallcode([]);
    setStatustype("");
    getFreight(searchStr, "", "", "","","", 1, pageSizeNo, sortData.sort_by);
    filterClose();
  };
  const handleClearFilter = () => {
    setFilterState({});
    // getData({
    //   search: data.query.search,
    //   page_size: data.query.page_size,
    //   page_no: 1,
    //   paginate: true,
    // });
    filterclose();
  };

    
    return (
        <>
        <div class="row">
       <div class="col-md-12">
       <div class="content-wrapper-inner content-wrapper-inner2">
       
        <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Bill Process</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>My PR List</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Plant name,
                            <br />
                            Plant code,
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
              
              <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                     id="searchbar"
                      type="text"
                      class="form-control"
                      placeholder="Search on Plant name, Plant code..."
                      onChange={(e) => handleSearch(e)}
                    />
                    <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                  </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                  {/* {finalUrlValue?.D ? ( */}
                      {/* <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img
                            src="images/export.png"
                            alt=""
                            className="mr-2"
                          />
                          Export
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={userList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,
                                  departmentDataFilter.is_sf,
                                  departmentDataFilter.business_units_id,
                                  departmentDataFilter.personal_area_id,
                                  departmentDataFilter.sub_personal_area_id,
                                  departmentDataFilter.designation_id,
                                  departmentDataFilter.status,
                                  departmentDataFilter?.role,
                                  1,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                   
                    <button
                     onClick={() => {
                      filterShow();
                    }}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {!showAddNew && (
                    <div>
                    <Link to="/billprocess/table?mode=create" class="btn btn-primary-inner" >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </Link>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
        <div className="table-responsive">
        <table class="table table-striped table-bordered tablecured tablcuredlastnew">
        <thead>
            <tr>
           
                <th
                    //  width="250"
                      onClick={(e) => {
                        handlerData("plant_id__plant_name");
                      }}
                      className={
                        sortData["plant_id__plant_name"] === undefined
                          ? "unsortcls"
                          : sortData["plant_id__plant_name"]
                      }
                    >
                     Plant Name
                    </th>
               
         
                <th
                      //width="250"
                      onClick={(e) => {
                        handlerData("transporter_id__name");
                      }}
                      className={
                        sortData["transporter_id__name"] === undefined
                          ? "unsortcls"
                          : sortData["transporter_id__name"]
                      }
                    >
                     Transporter Name
                    </th>
                
                <th
                     // width="250"
                      onClick={(e) => {
                        handlerData("from_date");
                      }}
                      className={
                        sortData["from_date"] === undefined
                          ? "unsortcls"
                          : sortData["from_date"]
                      }
                    >
                     Invoice<br></br> Start Date
                    </th>
                <th>Invoice<br></br>End Date</th>
                <th>Bill <br></br>Number</th>
                <th>Bill<br></br> Amount</th>
                <th>Bill Date</th>
                <th>Submitted By</th>
                <th>Submitted On</th>
                <th>Remark</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
                {   freightReqListData?.length > 0  ?
                 (freightReqListData !=null && freightReqListData !=undefined && 
                         (freightReqListData.map(el=>{

                    return <tr style={{ backgroundColor: el.current_approver =="True" ? "#92ff40" : "", fontWeight: el.for_my_approval ? "bold" : ""}}>
                         
                       
                       <td>{el.plant_name +"(" + el.plant_code +")"}</td>
                       <td>{el.transporter_id__name}</td>
                       <td>{moment(el.from_date).format("DD-MM-YYYY")}</td>
                       <td>{moment(el.to_date).format("DD-MM-YYYY")}</td>
                       <td>{el.bill_number}</td>
                       <td>{el.bill_amount}</td>
                       <td>{moment(el?.bill_date).format("DD-MM-YYYY")}</td>
                       <td>{el.created_by__username}</td>
                       <td>{moment(el?.created_at).format("DD-MM-YYYY")}</td>
                       <td>{el.remarks !=""? el.remarks : "-"}</td>
                       { el.status == "D"?(<td>{"Saved"}</td>)
                       :el.status=="R"?(<td>{"Rejected"}</td>)
                       : el.status=="A"?(<td>{"Approved"}</td>)
                        : el.status=="P"?(<td>{`Pending with ${el.pending_at}`}</td>)
                       : el.status=="RP"?(<td>{"Auto Rejected"}</td>)
                       :el.status =="None"?(<td>{"Pending"}</td>)
                       :el.status == "AA"?(<td>{"Auto Approved"}</td>)
                       :el.status == null ?(<td>{"Pending"}</td>)
                       :""}
                       {(el.status == "D" || (el.status == "RP")||
                              (el.status == "R")) && (managerType == "PM" ) &&
                               (
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    navigate(
                                        "/billprocess/table?mode=edit&id=" +
                                        el.request_id +
                                        "&plantID="+
                                         el.plant_id +
                                         "&plant_code="+
                                         el.plant_code +
                                         "&trans_name=" +
                                         el.transporter_id__name +
                                         "&trans_id=" +
                                         el.transporter_id +
                                         "&to_date=" +
                                         el.to_date +
                                         "&from_date=" +
                                         el.from_date +
                                         "&bill_number=" +
                                         el.bill_number +
                                         "&bill_date=" +
                                         el.bill_date +
                                         "&bill_amount=" +
                                         el.bill_amount
                                    )
                                  }
                                >
                                  Edit
                                </td>
                              )}
                            {((el.status == "P" || el.status == "A" || el.status == "AA") && (managerType == "PM" ) ) ? (
                              <td
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                onClick={(e) =>
                                  navigate(
                                    "/billprocess/table?mode=view&id=" +
                                    el.request_id +
                                    "&plantID="+
                                     el.plant_id +
                                     "&plant_code="+
                                     el.plant_code +
                                     "&trans_name=" +
                                     el.transporter_id__name +
                                     "&trans_id=" +
                                     el.transporter_id +
                                     "&to_date=" +
                                     el.to_date +
                                     "&from_date=" +
                                     el.from_date +
                                     "&bill_number=" +
                                     el.bill_number +
                                     "&bill_date=" +
                                     el.bill_date +
                                     "&bill_amount=" +
                                     el.bill_amount 

                                  ) 
                                }
                              >
                                View
                              </td>   
                         ): el.current_approver !=undefined && el.current_approver=="True" ? (
                          <td
                          style={{ cursor: "pointer", fontWeight: "bold" }}
                          onClick={(e) =>
                            navigate(
                              "/billprocess/table?mode=approve&id=" +
                              el.request_id +
                              "&plantID="+
                               el.plant_id +
                               "&plant_code="+
                               el.plant_code +
                               "&trans_name=" +
                               el.transporter_id__name +
                               "&trans_id=" +
                               el.transporter_id +
                               "&to_date=" +
                               el.to_date +
                               "&from_date=" +
                               el.from_date +
                               "&bill_number=" +
                               el.bill_number +
                               "&bill_date=" +
                               el.bill_date +
                               "&bill_amount=" +
                               el.bill_amount +
                               "&approvalID=" +
                               el.approval_id +
                               "&c_a="+
                               el.current_approver

                            )
                          }
                        >
                          View
                        </td>
                         ) : el.current_approver !=undefined && el.current_approver=="False" ? (
                          <td
                          style={{ cursor: "pointer", fontWeight: "bold" }}
                          onClick={(e) =>
                            navigate(
                              "/billprocess/table?mode=view&id=" +
                              el.request_id +
                              "&plantID="+
                               el.plant_id +
                               "&plant_code="+
                               el.plant_code +
                               "&trans_name=" +
                               el.transporter_id__name +
                               "&trans_id=" +
                               el.transporter_id +
                               "&to_date=" +
                               el.to_date +
                               "&from_date=" +
                               el.from_date +
                               "&bill_number=" +
                               el.bill_number +
                               "&bill_date=" +
                               el.bill_date +
                               "&bill_amount=" +
                               el.bill_amount 
                            )
                          }
                        >
                          View
                        </td>
                         ) : "" 
                         }
                            

</tr>
                         }) 
                         ) ):( <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>)}        
                    
    
     
     
          

                                    
                                
                            </tbody>
        
        </table>
        </div>
        <CustomPagination
              onChange={(e) => handlePageCountChange(e.target.value)}
              handlePageClick={(page) => handlePageClick(page)}
              pageSizeNo={pageSizeNo}
              pageCount={pageCount}
              currentPage={currentPage}
            />

        </div>
        </div>
       
       </div>

        <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {/* <div class="form-group innergroup">
            <label>
              PR ID<span class="text-danger"></span>
            </label>
            <input
            type="text"
            class="form-control"
            placeholder="Enter PR ID"
            value={filterState.prId}
            onChange={(e) =>
              setFilterState({
                ...filterState,
                prId: e.target.value,
              })
            }
            />
          </div> */}
           <div class="form-group innergroup">
            <label>
              Invoice No<span class="text-danger"></span>
            </label>
            <input
            type="text"
            class="form-control"
            placeholder="Enter Invoice No"
            value={freightDataFilter.invoice_number}
            onChange={(e) =>
                setFreightDataFilter({
                  ...freightDataFilter,
                  invoice_number: e.target.value,
                })
              
            }
            />
          </div>
          <div class="form-group innergroup"  style={{ justifyContent:"center", textAlign:"center", color:"black", fontWeight: "bold"}}>
            <label style={{color:"black", fontWeight: "bold", fontSize:"20px"}}>
              OR 
            </label>
         
          </div>
          {/* <div class="form-group innergroup">
            <label>
              Bill Number<span class="text-danger"></span>
            </label>
            <input
            type="text"
            class="form-control"
            placeholder="Enter Bill No"
            value={"45788"}
            // onChange={(e) =>
            //   setFilterState({
            //     ...filterState,
            //     sap_pr_no: e.target.value,
            //   })
            // }
            />
          </div> */}
               <div class="form-group innergroup">
            <label>
             Bill Number<span class="text-danger"></span>
            </label>
            <input
            type="text"
            class="form-control"
            placeholder="Enter Bill No"
            value={freightDataFilter.bill_number}
            onChange={(e) =>
                setFreightDataFilter({
                  ...freightDataFilter,
                  bill_number: e.target.value,
                })
              
            }
            />
          </div>
          
          {/* <div class="form-group innergroup">
            <label>Creation Date</label>
            <input
              type="date"
              class="form-control"
              placeholder="Enter Description"
              value={filterState.created_date}
              onChange={(e) =>
                setFilterState((pre) => ({
                  ...pre,
                  created_date: e.target.value,
                }))
              }
            />
          </div> */}
           {/* <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              class="form-control newbgselect"
              value={setFilterState.status}
              onChange={(e) =>
                setFilterState((pre) => ({ ...pre, status: e.target.value }))
              }
            >
              <option>Select</option>
              <option value={"A"}>Approved</option>
              <option value={"P"}>Pending</option>
              <option value={"D"}>Rejected</option>
         
              <option value={"Draft"}>Pending for Submission</option>
            </select>
          </div> */}
          {/* <div class="form-group innergroup">
            <label>Plant Code</label>
            <select
              class="form-control newbgselect"
              value={filterState.source}
              onChange={(e) =>
                setFilterState((pre) => ({ ...pre, source: e.target.value }))
              }
            >
              <option>Select</option>
              <option value={"M"}>code one</option>
              <option value={"E"}>code two</option>
              <option value={"SAP"}>code three</option>
            </select>
          </div> */}
            
              {/* <div className="form-group innergroup">    
                <label>
                    Select Transporter <span className="text-danger"></span>
                  </label>
                  <Select
                    name="business_unit_id"
                    isMulti
                      options={transporterallcode}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {

                      e?.map((data) => {
                        multi_tranporter.push(data)
                        // setTransportDataFilter({
                        //   ...transportDataFilter,
                        //   plant_id: data.value,
                        // });
                      })
                          setFreightDataFilter({
                          ...freightDataFilter,
                          transporter_id: multi_tranporter,
                        });
                        setTransportercode(e);
                        setCurrentPage(1);
                    }}
                    value={transportercode}
                  />
                </div> */}
                <div className="form-group innergroup">
                  <label>
                    Select Status <span className="text-danger"></span>
                  </label>
                  <Select
                    name="status_type"
                    isMulti
                    options={statuslist}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      e?.map((data) => {
                        if(data.label=="Approved"){
                          multi_status.push("A")
                        
                            
                        }
                        if(data.label=="Pending"){
                          multi_status.push("P")
                         
                        }
                        if(data.label=="Save as Draft"){
                          multi_status.push("D")
                         
                        }
                        if(data.label=="Rejected"){
                          multi_status.push("R")
                        
                        }
                       
                      })
                          setFreightDataFilter({
                            ...freightDataFilter,
                            status: multi_status})
                      setStatustype(e)
                      setCurrentPage(1)
                    }}
                    value={statustype}
                  />
                </div>
       
         
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={clearFilter}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={mainDataFilter}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      
      
        </>
    )
    
}



export default Root;
