import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import AllUrlsCashbook from "./AllUrlsCashbook";
import * as BeneficiaryService from "../../service/cashbookService";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import { selectUserData } from "../redux/piDataStore";

const AddCashbookBeneficial = (props) => {
  let condition = "false";
  const { addnewshow, onClose, onAddCallBack, editModuleData, edit, showView } =
    props;
  const userData = useSelector(selectUserData);
  {
  }
  const [cashbookMasterList, setCashbookMasterList] = useState([]);
  const [userValue, setUserValue] = useState();
  const [usrData, setUsrData] = useState([]);
  const [benAllData, setBenAllData] = useState([]);
  const [cashbookBeneficial, setCashbookBeneficial] = useState({
    user_id: 0,
    cashbook_id: "",
    beneficiary_code: "",
    delivery_address: "",
    payable_branch: "",
    agency_code: "",
    agency_name: "",
    branch_code: "",
    location_code: "",
    cash_delivery_code: "",
  });
  const [formValidation, setFormValidation] = useState({
    user_id: "",
    beneficiary_code: "",
    delivery_address: "",
    payable_branch: "",
    agency_code: "",
    agency_name: "",
    branch_code: "",
    location_code: "",
    cash_delivery_code: "",
  });
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    if (!cashbookBeneficial.user_id) {
      isError = true;
      formerr.user_id = "User Id is required";
      setFormValidation(formerr);
    }
    if (!cashbookBeneficial.beneficiary_code) {
      isError = true;
      formerr.beneficiary_code = "*Beneficiary Code is required";
      setFormValidation(formerr);
    }

    // else if (cashbookBeneficial.beneficiary_code.length >= 15) {
    //   isError = true;
    //   formerr.beneficiary_code = "*Beneficiary Code Should be less then 15";
    //   setFormValidation(formerr);
    // }
    if (!cashbookBeneficial.delivery_address) {
      isError = true;
      formerr.delivery_address = "*Delivery Address is required";
      setFormValidation(formerr);
    }
    if (!cashbookBeneficial.payable_branch) {
      isError = true;
      formerr.payable_branch = "*Payable Branch is required";
      setFormValidation(formerr);
    }
    if (!cashbookBeneficial.agency_code) {
      isError = true;
      formerr.agency_code = "*Agency Code is required";
      setFormValidation(formerr);
    }
    if (!cashbookBeneficial.agency_name) {
      isError = true;
      formerr.agency_name = "*Agency Name is required";
      setFormValidation(formerr);
    }
    if (!cashbookBeneficial.branch_code) {
      isError = true;
      formerr.branch_code = "*Branch Code is required";
      setFormValidation(formerr);
    }
    if (!cashbookBeneficial.location_code) {
      isError = true;
      formerr.location_code = "*Location Code is required";
      setFormValidation(formerr);
    }
    if (!cashbookBeneficial.cash_delivery_code) {
      isError = true;
      formerr.cash_delivery_code = "*Cash Delivery Code is required";
      setFormValidation(formerr);
    }
    return isError;
  };

  const getUserList = () => {
    BeneficiaryService.getUserList()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj?.user_id,
            label: `${obj?.user_name} (${obj?.user_code}) `,
            id: obj?.user_id,
          };
        });
        setUsrData(res);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getCashbookMasterDataList = (data) => {
    BeneficiaryService.getCashbookMasterDataList(data)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj?.id,
            label: `${obj?.desc}`,
            id: obj?.id,
          };
        });
        setCashbookMasterList(res);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getAllBeneficiaryData = (paginate) => {
    BeneficiaryService.getAllBeneficiaryData(paginate)
      .then((response) => {
        setBenAllData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const pushUserId = (e) => {
    setUserValue(e);
    setCashbookBeneficial({
      ...cashbookBeneficial,
      user_id: e.value,
    });
  };
  const saveBeneficiary = async (cashbookBeneficial) => {
    if (condition == "false") {
      condition = "true";
      let datas = cashbookBeneficial;
      datas.cashbook_id = Number(cashbookMasterList[0]?.id);
      await BeneficiaryService.saveBeneficiary(datas)
        .then((response) => {
          condition = "false";
          if (response?.data.code == 200) {
            toast.success("Cashbook Beneficiary Created Successfully");
            onAddCallBack();
            onClose();
            getAllBeneficiaryData(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const updateBeneficiary = async (id, data) => {
    if (condition == "false") {
      condition = "true";
      let datas = data;
      datas.cashbook_id = Number(cashbookMasterList[0]?.id);
      await BeneficiaryService.updateBeneficiary(id, datas)
        .then((response) => {
          condition = "false";
          if (response?.data?.code == 200) {
            toast.success("Cashbook Beneficiary Updated Successfully");
            onAddCallBack();
            onClose();
            getAllBeneficiaryData(false);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const handleValidateForm = (cashbookBeneficial) => {
    if (edit == false) {
      if (handleValidate()) return false;
      saveBeneficiary(cashbookBeneficial);
    } else {
      if (handleValidate()) return false;
      updateBeneficiary(cashbookBeneficial?.id, cashbookBeneficial);
    }
  };
  useEffect(() => {
    if (editModuleData) {
      setCashbookBeneficial(editModuleData);
      let obj1 = [
        {
          value: editModuleData?.cashbook_id,
          label: editModuleData?.cashbook_desc,
        },
      ];
      setCashbookMasterList(obj1);
      Object.keys(editModuleData).forEach((item) => {
        if (item === "user_id") {
          let value = usrData?.filter((obj) => {
            if (obj.value === Number(editModuleData[item])) {
              return true;
            }
          })[0];
          setUserValue(value);
          // setCashbookBeneficial((prev) => ({
          //   ...prev,
          //   user_id: value?.cashbook_desc,
          // }));
        } else {
          cashbookBeneficial[item] = editModuleData[item];
        }
      });
    }
  }, [editModuleData, usrData]);
  useEffect(() => {
    getUserList();
  }, []);
  return (
    <div>
      <Modal
        show={addnewshow}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose maxwidth90 modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {""}
            {edit === true ? "Edit" : "Add"} Beneficiary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      User<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={usrData}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="user_id"
                      onChange={(e) => {
                        pushUserId(e);
                        setFormValidation({
                          ...formValidation,
                          user_id: "",
                        });
                        getCashbookMasterDataList(e?.id);
                      }}
                      value={userValue}
                    />
                    <div className="small text-danger">
                      {formValidation.user_id}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Cashbook<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={cashbookMasterList[0]?.label}
                      // placeholder={
                      //   edit ? editModuleData?.cashbook_desc : "Enter GST No"
                      // }
                    />
                    {/* <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    /> */}
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Beneficiary Code<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Beneficiary Code"
                      name="beneficiary_code"
                      maxLength={15}
                      onChange={(e) => {
                        setCashbookBeneficial({
                          ...cashbookBeneficial,
                          beneficiary_code: e.target.value.trim(),
                        });
                        setFormValidation({
                          ...formValidation,
                          beneficiary_code: "",
                        });
                      }}
                      value={cashbookBeneficial?.beneficiary_code}
                    />
                    <div className="small text-danger">
                      {formValidation.beneficiary_code}
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Delivery Address<span class="text-danger">*</span>
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      placeholder="Enter Delivery Address"
                      name="delivery_address"
                      maxLength={500}
                      onChange={(e) => {
                        setCashbookBeneficial({
                          ...cashbookBeneficial,
                          delivery_address: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          delivery_address: "",
                        });
                      }}
                      value={cashbookBeneficial?.delivery_address}
                    ></textarea>

                    <div className="small text-danger">
                      {formValidation.delivery_address}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Payble Branch<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Payable Branch"
                      name="payable_branch"
                      maxLength={15}
                      onChange={(e) => {
                        setCashbookBeneficial({
                          ...cashbookBeneficial,
                          payable_branch: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          payable_branch: "",
                        });
                      }}
                      value={cashbookBeneficial?.payable_branch}
                    />
                    <div className="small text-danger">
                      {formValidation.payable_branch}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Agency Code<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Agency Code"
                      name="agency_code"
                      maxLength={15}
                      onChange={(e) => {
                        setCashbookBeneficial({
                          ...cashbookBeneficial,
                          agency_code: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          agency_code: "",
                        });
                      }}
                      value={cashbookBeneficial?.agency_code}
                    />
                    <div className="small text-danger">
                      {formValidation.agency_code}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Agency Name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Agency Name"
                      name="agency_name"
                      maxLength={15}
                      onChange={(e) => {
                        setCashbookBeneficial({
                          ...cashbookBeneficial,
                          agency_name: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          agency_name: "",
                        });
                      }}
                      value={cashbookBeneficial?.agency_name}
                    />
                    <div className="small text-danger">
                      {formValidation.agency_name}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Branch Code<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Branch Code"
                      name="branch_code"
                      maxLength={15}
                      onChange={(e) => {
                        setCashbookBeneficial({
                          ...cashbookBeneficial,
                          branch_code: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          branch_code: "",
                        });
                      }}
                      value={cashbookBeneficial?.branch_code}
                    />
                    <div className="small text-danger">
                      {formValidation.branch_code}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Location Code<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Location Code"
                      name="location_code"
                      maxLength={15}
                      onChange={(e) => {
                        setCashbookBeneficial({
                          ...cashbookBeneficial,
                          location_code: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          location_code: "",
                        });
                      }}
                      value={cashbookBeneficial?.location_code}
                    />
                    <div className="small text-danger">
                      {formValidation.location_code}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Cash Delivery Code<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Cash Delivery Code"
                      name="cash_delivery_code"
                      maxLength={15}
                      onChange={(e) => {
                        setCashbookBeneficial({
                          ...cashbookBeneficial,
                          cash_delivery_code: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          cash_delivery_code: "",
                        });
                      }}
                      value={cashbookBeneficial?.cash_delivery_code}
                    />
                    <div className="small text-danger">
                      {formValidation.cash_delivery_code}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => onClose()}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => {
              handleValidateForm(cashbookBeneficial);
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCashbookBeneficial;
