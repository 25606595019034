import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getDepartment = async (
  queryParm
) => {
  return axios.get(
    `${AUTH_BASE_URL}/sub_department_router?${queryParm}`
  );
};

export const saveDepartment = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/sub_department_router`, data);
};

export const updateDepartment = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/sub_department_router/${id}`, data);
};

export const deleteDepartment = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/sub_department_router/${id}`);
};
export const getOrganisation = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/organisations?paginate=${paginate}`);
};
export const getAllSubDept = async (id) => {
  return axios.post(
    `${AUTH_BASE_URL}/sub_department_router/list?department_id=${id}`
  );
};

export const isDeptExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/sub_department_router/exists`, isData);
};

export const getAllDepartment = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/department?paginate=${paginate}`);
};
export const getAllIdeaDepartment = async (paginate,module) => {
  return axios.get(`${AUTH_BASE_URL}/department?paginate=${paginate}&module=${module}`);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/sub_department_router/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
