import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import CustomMenu from "./CustomMenu";

function AddMatExt() {
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <CustomMenu />
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Add New Material Extension</h4>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transaction</Breadcrumb.Item>
                    <Breadcrumb.Item href="/material-req">
                      Material Extension
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Add New</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              <Tabs defaultActiveKey="Create" id="approvaltabs">
                <Tab
                  eventKey="Create"
                  title={
                    <React.Fragment>Material Extension Request</React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured viewpo">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Mat. Desc.</th>
                          <th>Plant</th>
                          <th>Mat. Group</th>
                          <th>Mat. Type</th>
                          <th>Mat. Unit</th>
                          <th>Mat. No.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <button class="btn btn-blanktd text-primary f-12">
                              <i class="far fa-edit"></i>
                            </button>
                            <button class="btn btn-blanktd text-danger ml-2 f-12">
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          </td>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                        </tr>
                        <tr>
                          <td>
                            <button class="btn btn-blanktd text-primary f-12">
                              <i class="far fa-edit"></i>
                            </button>
                            <button class="btn btn-blanktd text-danger ml-2 f-12">
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          </td>
                          <td class="text-theme fw-bold">
                            94001987 - CD MEDIA DVD
                          </td>
                          <td>SR02</td>
                          <td>001</td>
                          <td>ERSA</td>
                          <td>NO</td>
                          <td>94001987</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="px-4 pb-2">
                    <div class="row align-items-center">
                      <div class="col-md-12 text-sm-right">
                        <button class="btn btnblanktd text-primary">
                          <i class="far fa-plus-square mr-2"></i> Add More
                        </button>
                      </div>
                    </div>

                    <div class="bg-light px-3 pt-3 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Reference Plant<span class="text-danger">*</span>
                            </label>
                            <select class="form-control newbgselect">
                              <option>Select</option>
                              <option value="GG01">GG01</option>
                              <option value="GG02">GG02</option>
                              <option value="NZ12">NZ12</option>
                              <option value="PN01">PN01</option>
                              <option value="PN02">PN02</option>
                              <option value="PN03">PN03</option>
                              <option value="PN04">PN04</option>
                              <option value="PN05">PN05</option>
                              <option value="SR02">SR02</option>
                              <option value="UD01">UD01</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Reference Store Location{" "}
                           <span class="text-danger">*</span>
                            </label>
                            <select class="form-control newbgselect">
                              <option selected="" value="">
                                -Select-
                              </option>
                              <option value="0001">MAIN STORAGE</option>
                              <option value="0008">APPROVED U/Declined</option>
                              <option value="0009">LABORATORY STORE</option>
                              <option value="0013">CAPITAL GOODS</option>
                              <option value="0016">RMPM WH 1</option>
                              <option value="0017">RMPM WH 2</option>
                              <option value="0018">RMPM WH 3 LF</option>
                              <option value="0019">RMPM WH 4 10G</option>
                              <option value="0020">RMPM WH 5 ETP</option>
                              <option value="0026">COLD STORAGE</option>
                              <option value="0027">RENTED WH NO. 3</option>
                              <option value="0048">LABORATORY (QC)</option>
                              <option value="0049">LABORATORY (HSE)</option>
                              <option value="0061">CRE MAIN STORE</option>
                              <option value="0062">CRE COMPOUNDS</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Material<span class="text-danger">*</span>
                            </label>
                            <Select
                              options={options}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Ext. Plant<span class="text-danger">*</span>
                            </label>
                            <select class="form-control newbgselect">
                              <option>Select</option>
                              <option value="GG01">GG01</option>
                              <option value="GG02">GG02</option>
                              <option value="NZ12">NZ12</option>
                              <option value="PN01">PN01</option>
                              <option value="PN02">PN02</option>
                              <option value="PN03">PN03</option>
                              <option value="PN04">PN04</option>
                              <option value="PN05">PN05</option>
                              <option value="SR02">SR02</option>
                              <option value="UD01">UD01</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Store Location<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Store Location"
                              disabled
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Material Type<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Material Type"
                              disabled
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Material Group<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Material Group"
                              disabled
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Unit<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Unit"
                              disabled
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Include Variation Type{" "}
                           <span class="text-danger">*</span>
                            </label>
                            <select class="form-control newbgselect">
                              <option>Select</option>
                              <option selected>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Variation Type<span class="text-danger">*</span>
                            </label>
                            <select class="form-control newbgselect">
                              <option>Select</option>
                              <option selected>Damaged</option>
                              <option>Good</option>
                              <option>Repaired</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="row">
                        <div class="col-md-12 mt-3 text-center">
                          <Link
                            to="/mat-ext"
                            class="btn btn-outline-secondary mr-2"
                          >
                            Cancel
                          </Link>
                          <button class="btn btn-primary-inner bpi-main">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddMatExt;
