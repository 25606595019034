import React, { useState, useEffect } from "react";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Tabs,
  Tab,
} from "react-bootstrap";
import AddCreInstrument from "./AddCreInstrument";
import EditCreInstrument from "./EditCreInstrument";
import * as CreService from "../../service/creServices.js";
import { ToastContainer, toast } from "react-toastify";
import * as moment from "moment";
import { injectStyle } from "react-toastify/dist/inject-style";
import ReactPaginate from "react-paginate";
import { getIn } from "formik";
import { selectFinalUrlPermission } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

if (typeof window !== "undefined") {
  injectStyle();
}

function CREInstrument() {
  const dispatch = useDispatch();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [creData, setCreData] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [moduleList, setModuleList] = useState([]);
  const [creDataFilter, setCreDataFilter] = useState({
    status: "",
    instrument_id: "",
    manufacturer_id: "",
    building: "",
    asset_code: "",
  });
  const [valueExportData, setValueExportData] = useState({
    status: "",
    instrument_id: "",
    manufacturer_id: "",
    building: "",
    asset_code: "",
  });
  const [typeFilterData, setTypeFilterData] = useState([]);
  const [manufactureFilterData, setManufactureFilterData] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentTab, setCurrentTab] = useState("Requests");
  const [editDataCre, setEditDataCre] = useState([]);

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  const [importshow, importsetShow] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  const getCreList = (
    search,
    status,
    instrument_id,
    manufacturer_id,
    building,
    page_no,
    page_size,
    sort_by,
    paginate,
    asset_code,
    is_user
  ) => {
    CreService.getCreList(
      handleKey(
        search,
        status,
        instrument_id,
        manufacturer_id,
        building,
        page_no,
        page_size,
        sort_by,
        paginate,
        asset_code,
        is_user
      )
    )
      .then((response) => {
        setCreData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setTotalData(response?.data?.dataList?.paginated_data?.totalRecords);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (
    search,
    status,
    instrument_id,
    manufacturer_id,
    building,
    page_no,
    page_size,
    sort_by,
    paginate,
    asset_code,
    is_user
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate +
      "&is_user=" +
      is_user;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (
      instrument_id !== "" &&
      instrument_id !== undefined &&
      instrument_id !== null
    ) {
      queryParm = queryParm + "&instrument_id=" + instrument_id;
    }
    if (
      manufacturer_id !== "" &&
      manufacturer_id !== undefined &&
      manufacturer_id !== null
    ) {
      queryParm = queryParm + "&manufacturer_id=" + manufacturer_id;
    }
    if (building !== "" && building !== undefined && building !== null) {
      queryParm = queryParm + "&building=" + building;
    }
    if (asset_code !== "" && asset_code !== undefined && asset_code !== null) {
      queryParm = queryParm + "&asset_code=" + asset_code;
    }
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };
  const handleExportKey = (
    search,
    status,
    instrument_id,
    manufacturer_id,
    building,
    // page_no,
    // page_size,
    sort_by,
    // paginate,
    asset_code,
    is_user
  ) => {
    let queryParm =
      // "page_no=" +
      // page_no +
      // "&page_size=" +
      // page_size +
      // "&paginate=" +
      // paginate +
      "&is_user=" + is_user;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (
      instrument_id !== "" &&
      instrument_id !== undefined &&
      instrument_id !== null
    ) {
      queryParm = queryParm + "&instrument_id=" + instrument_id;
    }
    if (
      manufacturer_id !== "" &&
      manufacturer_id !== undefined &&
      manufacturer_id !== null
    ) {
      queryParm = queryParm + "&manufacturer_id=" + manufacturer_id;
    }
    if (building !== "" && building !== undefined && building !== null) {
      queryParm = queryParm + "&building=" + building;
    }
    if (asset_code !== "" && asset_code !== undefined && asset_code !== null) {
      queryParm = queryParm + "&asset_code=" + asset_code;
    }
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      if (currentTab == "Requests") {
        getCreList(
          e.target.value.toLowerCase(),
          creDataFilter.status,
          creDataFilter.instrument_id,
          creDataFilter.manufacturer_id,
          creDataFilter.building,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          "",
          true
        );
      } else {
        getCreList(
          e.target.value.toLowerCase(),
          creDataFilter.status,
          creDataFilter.instrument_id,
          creDataFilter.manufacturer_id,
          creDataFilter.building,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          "",
          false
        );
      }

      setSearchStr(e.target.value);
    }
  };
  const getExportDetails = (
    search,
    status,
    instrument_id,
    manufacturer_id,
    building,
    // page_no,
    // page_size,
    sort_by,
    // paginate,
    asset_code,
    is_user
  ) => {
    CreService.getExportValue(
      handleExportKey(
        search,
        status,
        instrument_id,
        manufacturer_id,
        building,
        // page_no,
        // page_size,
        sort_by,
        // paginate,
        asset_code,
        is_user
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CreInstrument.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      // setSearchStr("");

      if (currentTab == "Requests") {
        getCreList(
          data,
          creDataFilter.status,
          creDataFilter.instrument_id,
          creDataFilter.manufacturer_id,
          creDataFilter.building,
          1,
          pageSizeNo,
          sortData.sort_by,
          true,
          "",
          true
        );
      } else {
        getCreList(
          data,
          creDataFilter.status,
          creDataFilter.instrument_id,
          creDataFilter.manufacturer_id,
          creDataFilter.building,
          1,
          pageSizeNo,
          sortData.sort_by,
          true,
          "",
          false
        );
      }
    }
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    if (currentTab == "Requests") {
      getCreList(
        searchStr,
        creDataFilter.status,
        creDataFilter.instrument_id,
        creDataFilter.manufacturer_id,
        creDataFilter.building,
        activePage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        true
      );
    } else {
      getCreList(
        searchStr,
        creDataFilter.status,
        creDataFilter.instrument_id,
        creDataFilter.manufacturer_id,
        creDataFilter.building,
        activePage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        false
      );
    }
  };

  const editCountry = (data) => {
    importuser();
    setEditDataCre(data);
  };
  const onAddCallBack = (response) => {
    addnewclose();
    getCreList(
      searchStr,
      creDataFilter.status,
      creDataFilter.instrument_id,
      creDataFilter.manufacturer_id,
      creDataFilter.building,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      true
    );
  };
  const getInstrumentType = () => {
    CreService.getInstrumentType()
      .then((res) => {
        setTypeFilterData(res?.data?.dataList?.result);
      })
      .catch((error) => {
        error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getMakingList = (idx) => {
    CreService.getMakingList(idx)
      .then((res) => {
        setManufactureFilterData(res?.data?.dataList?.result);
      })
      .catch((error) => {
        error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const instrumentTypeData = (optionsModuleList) => {
    return optionsModuleList?.map((item, index) => (
      <option
        key={`type${index}`}
        value={`${item?.id}`}
        label={`${item?.description}`}
      />
    ));
  };
  const manufactureListData = (optionsModuleList) => {
    return optionsModuleList?.map((item, index) => (
      <option
        key={`modules${index}`}
        value={`${item?.id}`}
        label={`${item?.manufacturer_name}`}
      />
    ));
  };
  const filterShow = () => {
    filteropen();
    getInstrumentType();
  };
  const mainDataFilter = () => {
    // setTypeFilterData({
    //   ...creDataFilter,
    //   instrument_id: creDataFilter.instrument_id,
    //   manufacturer_id: creDataFilter.manufacturer_id,
    //   building: creDataFilter.building,
    //   status: creDataFilter.status,
    // });
    const instrumentId = creDataFilter.instrument_id;
    const manufacturer_id = creDataFilter.manufacturer_id;
    const status = creDataFilter.status;
    const building = creDataFilter.building;

    if (currentTab === "Requests") {
      getCreList(
        searchStr,
        status,
        instrumentId,
        manufacturer_id,
        building,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        true
      );
    } else {
      getCreList(
        searchStr,
        status,
        instrumentId,
        manufacturer_id,
        building,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        false
      );
    }

    filterClose();
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    if (currentTab === "Requests") {
      getCreList(
        searchStr,
        "",
        "",
        "",
        "",
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        true
      );
    } else {
      getCreList(
        searchStr,
        "",
        "",
        "",
        "",
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        false
      );
    }
  };
  const filterClose = () => {
    filterclose();
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(creDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setCreDataFilter(ClearData);

    if (currentTab === "Requests") {
      getCreList(
        searchStr,
        "",
        "",
        "",
        "",
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        true
      );
    } else {
      getCreList(
        searchStr,
        "",
        "",
        "",
        "",
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        false
      );
    }
    filterClose();
  };

  useEffect(() => {
    if (currentTab == "Requests") {
      getCreList(
        searchStr,
        creDataFilter.status,
        creDataFilter.instrument_id,
        creDataFilter.manufacturer_id,
        creDataFilter.building,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        true
      );
    } else {
      getCreList(
        searchStr,
        creDataFilter.status,
        creDataFilter.instrument_id,
        creDataFilter.manufacturer_id,
        creDataFilter.building,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        false
      );
    }
    closeButtonCallBack();
    setPageSizeNo(pageSizeNo);
  }, [currentTab, pageSizeNo]);

  return (
    <>
      <div class="row">
        <ToastContainer autoClose={1000} />
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">CRE Instrument</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>CRE Instrument</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      id="searchbar"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                    <span class="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          class="far fa-times-circle cursor-pointer"
                          onClick={() => {
                            closeButtonCallBack();
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    {/* {currentTab === "Requests" ? ( */}
                    {finalUrlValue?.D ? (
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              disabled={creData.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() => {
                                currentTab === "Requests"
                                  ? getExportDetails(
                                      searchStr,
                                      creDataFilter.status,
                                      creDataFilter.instrument_id,
                                      creDataFilter.manufacturer_id,
                                      creDataFilter.building,
                                      // 1,
                                      // pageSizeNo,
                                      sortData.sort_by,
                                      // false,
                                      "",
                                      true
                                    )
                                  : getExportDetails(
                                      searchStr,
                                      creDataFilter.status,
                                      creDataFilter.instrument_id,
                                      creDataFilter.manufacturer_id,
                                      creDataFilter.building,
                                      // 1,
                                      // pageSizeNo,
                                      sortData.sort_by,
                                      // false,
                                      "",
                                      false
                                    );
                              }}
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}

                    <button
                      onClick={() => {
                        filterShow();
                      }}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {finalUrlValue?.A ? (
                      currentTab === "Requests" ? (
                        <button
                          onClick={addnewopen}
                          class="btn btn-primary-inner"
                        >
                          <img src="images/upload.png" alt="" class="mr-3" />
                          Add New
                        </button>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs
                defaultActiveKey="Requests"
                id="approvaltabs"
                onSelect={(e) => {
                  setCurrentTab(e);
                  closeButtonCallBack();
                }}
              >
                <Tab
                  eventKey="Requests"
                  title={
                    <React.Fragment>
                      My Service Requests
                      {currentTab == "Requests" && (
                        <>
                          <span class="numberpurchse">{totalData}</span>
                        </>
                      )}
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          {/* <th>Sr No.</th> */}
                          {finalUrlValue?.E ? <th>Action</th> : ""}
                          <th>Request ID</th>
                          <th>Instrument Type</th>
                          <th>Make of Instrument</th>
                          <th>Asset Code</th>
                          <th>Requester</th>
                          <th>Building</th>
                          <th>Location</th>
                          <th>Problem Description</th>
                          <th>Status</th>
                          <th>Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {creData?.map((data, index) => (
                          <tr key={`cre${index}`}>
                            {/* <td>{currentPage > 1 && currentPage}{index+1}</td> */}
                            {finalUrlValue?.E ? (
                              <td>
                                {data?.status == "CLOSED" ? (
                                  ""
                                ) : (
                                  <button
                                    class="btn btn-blanktd text-success"
                                    onClick={() => {
                                      editCountry(data);
                                    }}
                                  >
                                    <i class="far fa-edit"></i>{" "}
                                  </button>
                                )}
                              </td>
                            ) : (
                              ""
                            )}
                            <td class="text-theme fw-bold">{data?.id}</td>
                            <td>{data?.instrument_name}</td>
                            <td>{data?.manufacturer_name}</td>
                            <td>{data?.asset_code}</td>
                            <td>
                              {data?.user_name}
                              <br />
                            </td>
                            <td>{data?.building}</td>
                            <td>LAB</td>
                            <td> {data?.issue_problem}</td>
                            <td>
                              <span class="badge bad-status badge-warning">
                                {data?.status}
                              </span>
                            </td>

                            <td>
                              {moment
                                .utc(data?.created_at)
                                .format("Do MMM YYYY")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {creData?.length == 0 ? (
                    <td colSpan={12}> No Record Found</td>
                  ) : (
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control main-pointer"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="Pending"
                  title={
                    <React.Fragment>
                      All Service Requests
                      {currentTab == "Pending" && (
                        <>
                          <span class="numberpurchse">{totalData}</span>
                        </>
                      )}
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          {/* <th>Sr No.</th> */}
                          {/* <th>Action</th> */}
                          <th>Request ID</th>
                          <th>Instrument Type</th>
                          <th>Make of Instrument</th>
                          <th>Asset Code</th>
                          <th>Requester</th>
                          <th>Building</th>
                          <th>Location</th>
                          <th>Problem Description</th>
                          <th>Status</th>
                          {/* <th
                            onClick={(e) => {
                              handlerData("is_active");
                            }}
                            className={
                              sortData["is_active"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["is_active"]
                            }
                          >
                            Status
                          </th> */}
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {creData?.map((data, idx) => (
                          <tr key={`cd${idx}`}>
                            {/* <td>{idx + 1}</td> */}
                            {/* <td>
                              <button
                                class="btn btn-blanktd text-success"
                                onClick={() => {
                                  editCountry(data);
                                }}
                              >
                                <i class="far fa-edit"></i>{" "}
                              </button>
                            </td> */}
                            <td class="text-theme fw-bold">{data?.id}</td>
                            <td>{data?.instrument_name}</td>
                            <td>{data?.manufacturer_name}</td>
                            <td>{data?.asset_code}</td>
                            <td>
                              {data?.user_name}
                              <br />
                            </td>
                            <td>{data?.building}</td>
                            <td>LAB</td>
                            <td> {data?.issue_problem}</td>
                            <td>
                              <span class="badge bad-status badge-warning">
                                {data?.status}
                              </span>
                            </td>
                            <td>
                              {moment
                                .utc(data?.created_at)
                                .format("Do MMM YYYY")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {creData?.length == 0 ? (
                    <td colSpan={12}> No Record Found</td>
                  ) : (
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control main-pointer"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>

            <div class="purchaseinnertabs"></div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>Type of the Instrument/Utilities </label>
            <select
              className="form-control newbgselect main-pointer"
              name="module_parent_id"
              type="text"
              onChange={(e) => {
                setCreDataFilter({
                  ...creDataFilter,
                  instrument_id: e.target.value,
                });
                getMakingList(e.target.value);
              }}
              value={creDataFilter?.instrument_id}
            >
              <option
                value=""
                label="Select Type of Instrument/Utilities"
                disabled
              />
              {instrumentTypeData(typeFilterData)}
              {/* {ModuleListData(moduleList)} */}
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Make of the Instrument/Utilities </label>
            <select
              className="form-control newbgselect main-pointer"
              name="manufacturer_id"
              type="text"
              onChange={(e) => {
                setCreDataFilter({
                  ...creDataFilter,
                  manufacturer_id: e.target.value,
                });
              }}
              value={creDataFilter?.manufacturer_id}
            >
              <option value="" label="Select Make of Instrument/Utilities" />
              {manufactureListData(manufactureFilterData)}
            </select>
          </div>
          {/* <div class="form-group innergroup">
            <label>Asset Code of the Instrument </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div> */}
          <div class="form-group innergroup">
            <label>Building </label>
            <select
              className="form-control newbgselect main-pointer"
              name="building"
              type="text"
              onChange={(e) => {
                setCreDataFilter({
                  ...creDataFilter,
                  building: e.target.value,
                });
              }}
              value={creDataFilter?.building}
            >
              <option value="" label="Select Building" disabled />
              <option value="AKRC" label="AKRC" />
              <option value="PPSRC" label="PPSRC" />
              {/* {manufactureListData(manufactureFilterData)} */}
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Status</label>
            <select
              class="form-control newbgselect"
              onChange={(e) =>
                setCreDataFilter({
                  ...creDataFilter,
                  status: e.target.value,
                })
              }
              value={creDataFilter.status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="WIP">WIP</option>
              <option value="HOLD">Hold</option>
              <option value="CLOSED">Closed</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter(setCurrentTab)}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {addnewshow && (
        <AddCreInstrument
          addnewshow={addnewshow}
          addnewclose={addnewclose}
          onAddCallBack={onAddCallBack}
        />
      )}

      {importshow && (
        <EditCreInstrument
          importshow={importshow}
          importclose={importclose}
          editDataCre={editDataCre}
          onAddCallBack={onAddCallBack}
        />
      )}
    </>
  );
}

export default CREInstrument;
