import React from "react";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import * as TravelListingAPI from "../../service/travelService";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { selectUserData } from "../redux/piDataStore";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import AddHotelChild from "./AddHotelChild";
import { Modal, Alert } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddHotel = (props) => {
  const {
    hotelDataValue,
    isEdit,
    hotelList,
    deleteHotelData,
    isDelete,
    getHotelList,
    att,
    setIsDelete,
    getTotalAmt,
    dataNew,
    setCheckActiveTab,
  } = props;
  const [doubleClick, setDoubleClick] = useState("false");
  const [visibleD, setVisibleD] = useState(false);
  const { state } = useLocation();
  const [showForm, setShowForm] = useState(false);
  const userData = useSelector(selectUserData);
  const [calc, setCalc] = useState(false);
  const [cityDataNew, setCityDataNew] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [taxRate, setTaxRate] = useState();
  const [stayAtValue, setStayAtValue] = useState();
  let errEle = null;
  const [vendorValue, setVendorValue] = useState();
  const [taxRateValue, setTaxRateValue] = useState();
  const [attachment, setAttachment] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [newEmp, setNewEmp] = useState([]);
  const [file, setfiles] = useState([]);
  const [flag, setflag] = useState(false);
  const [Errorattachment, setErrorattachment] = useState(false);
  const [codeFlagVendor, setCodeFlagVendor] = useState(false);
  const [codeFlagTaxRate, setCodeFlagTaxRate] = useState(false);
  const [codeFlagHotelState, setCodeFlagHotelState] = useState(false);
  const [codeFlagpOne, setFlagpOne] = useState(false);
  const [codeFlagTwo, setFlagTwo] = useState(false);
  const [stayAtFlag, setStayAtFlag] = useState(false);
  const [hotelListData, setHotelListData] = useState();
  const [allEmployee, setAllEmployee] = useState([]);
  const [chkFilterOpt, setFilterDataOpt] = useState([]);
  const [fcmData, setFcmData] = useState([]);
  const [calDateValue, setCalDateValue] = useState();
  const [entPrice, setEntPrice] = useState();
  const [entPriceNew, setEntPriceNew] = useState("");
  const [billPrice, setBillPrice] = useState("");
  const [hotelListDataTwo, setHotelListDataTwo] = useState([]);
  const [deleteattachment, setDeleteAttachment] = useState([]);
  const [excludePerson, setExcludePerson] = useState(null);
  const [shouldDeleteAttachment, setShouldDleteAttachment] = useState(true);
  const [billAmtNot, setBillAmtNot] = useState([]);
  const [claimAmountChange, setClaimAmountChange] = useState(false);
  const [hotelDateModal, sethotelDateModal] = useState(false);
  const [hotelDateOption, sethotelDateOption] = useState([]);
  const [hotelDate, sethotelDate] = useState(null);
  const [alertStayAt, setAlertStayAt] = useState(false);
  const [gradeAndLocate, setGradeAndLocate] = useState(false);

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);
    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setCalDateValue(diffDays);
      return diffDays + 1;
    } else {
      return 0;
    }
  };
  var date = new Date();
  date.setDate(date.getDate() - 1);
  const getHotelListTwo = async (travel_id) => {
    await TravelListingAPI.getHotelListTwo(travel_id)
      .then((response) => {
        let res = response?.data?.dataList?.result.map((each) => {
          let diff = moment(each?.check_out_date).diff(
            moment(each?.check_in_date),
            "days"
          );
          each.days_limit = diff;
          return each;
        });
        setHotelListDataTwo(res);

        setBillPrice(response?.data?.dataList?.result?.city_entitlement);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [expenseData, setExpenseData] = useState([
    {
      check_in_date: undefined,
      check_out_date: undefined,
      city_id: undefined,
      person_one_id: undefined,
      person_two_id: undefined,
      second_entitlement: undefined,
      third_entitlement: undefined,
      days: 0,
      stay_at: undefined,
      hotel_state_id: undefined,
      hotel_name: undefined,
      hotel_vendor_category: undefined,
      hotel_gst_no: undefined,
      invoice_no: undefined,
      invoice_date: undefined,
      sharing: false,
      taxable_amount: billPrice,
      tax_rate_id: undefined,
      tax_amount: 0,
      // other_charges: 0,
      tax_bill_amount: 0,
      claim_amount: 0,
      total_claim_amount: 0,
      remark: undefined,
      bill_amount: 0,
      reason_for_exception_name: undefined,
      is_exception: false,
      key: 1,
    },
  ]);

  const [totalHotelClaim, setTotalHotelClaim] = useState(0);

  useEffect(() => {
    let val = 0;
    JSON.parse(JSON.stringify(expenseData))?.map((each) => {
      val += Number(each?.total_claim_amount);
    });
    setTotalHotelClaim(val);
  }, [expenseData, claimAmountChange]);

  const [formValidation, setFormValidation] = useState({
    // check_in_date: "",
    // check_out_date: "",
    city_id: "",
    days: "",
    stay_at: "",
    hotel_state_id: "",
    hotel_name: "",
    hotel_vendor_category: "",
    hotel_gst_no: "",
    invoice_no: "",
    invoice_date: "",
    sharing: "",
    taxable_amount: "",
    tax_rate_id: "",
    // other_charges: "",
    tax_bill_amount: "",
    claim_amount: "",
    bill_amount: "",
  });
  const taxAmountFinalFun = (tax, i) => {
    let amt = (tax * expenseData[i]?.taxable_amount) / 100;
    let expD = expenseData;
    expD[i].tax_amount = amt;
    setExpenseData(expD);
  };
  const totalBillAmountFinal = (e, i) => {
    let clAmt =
      Number(expenseData[i]?.taxable_amount) +
      Number(expenseData[i]?.tax_amount);
    let expD = expenseData;
    expD[i].tax_bill_amount = clAmt;
    setExpenseData(expD);
  };
  const handleClose = () => {
    setVisibleD(false);
  };
  // const taxAmountFinal =

  //   (hotelTaxCalculation * expenseData[0]?.taxable_amount) / 100;

  const billAmountFinal =
    Number(expenseData[0]?.taxable_amount) +
    Number(expenseData[0]?.tax_rate_id);
  const totalClaimAmountFinal = (e, i) => {
    let total = expenseData[i]?.taxable_amount;
    let totalBill = expenseData[i]?.tax_bill_amount;
    let expD = expenseData;
    if (Number(total) < Number(e) || Number(total) === Number(e)) {
      
      expD[i].claim_amount = total;
      expD[i].total_claim_amount = totalBill;
    } else {
      
      let clAmt = e !== "" && Number(e) + Number(expenseData[i]?.tax_amount);
      // Number(expenseData[i].other_charges);
      expD[i].claim_amount = e;
      if (expD[i].claim_amount === "0") {
        expD[i].total_claim_amount = 0;
      } else {
        expD[i].total_claim_amount = clAmt;
      }
      if(e <= billAmtNot[i]){
        expD[i].is_exception = false;
      }

      // expD[i].total_claim_amount = clAmt;
    }
    setClaimAmountChange((prev) => !prev);
    setExpenseData(expD);
  };
  const option = [
    { value: "Hotel", label: "Hotel" },
    { value: "Guest House", label: "Guest House" },
    { value: "Family/Friends", label: "Family/Friends" },
  ];
  const vendorOption = [
    { value: "", label: "Select" },
    { value: "Registered", label: "Registered" },
    { value: "Non-Registered", label: "Non-Registered" },
  ];
  const getCityList = async (paginate) => {
    await TravelListingAPI.getCityList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.name}`,
          };
        });
        setCityDataNew(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getFcmList = async () => {
    await TravelListingAPI.getFcmList()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.name}`,
          };
        });
        setFcmData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getEmployee = (paginate) => {
    TravelListingAPI.getEmployee(paginate)
      .then((response) => {
        let data = [];
        const filteredUsers = response?.data?.dataList?.result.filter(
          (user) => user.is_active
        );
        filteredUsers
          .filter((each) => {
            if (each?.id !== userData?.id) {
              return true;
            }
          })
          .map((x) => {
            let empData = {
              value: x.id,
              label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
            };
            data.push(empData);
          });
        setAllEmployee(data);
        setNewEmp(data);
        // setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getTaxRate = async () => {
    await TravelListingAPI.getTaxRate()
      .then((response) => {
        let res = response?.data?.dataList?.tax_rate_data?.map((obj) => {
          return {
            value: obj.id,
            name: `${obj?.name}`,
            label: `${obj?.name}`,
          };
        });
        setTaxRate(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStateList = async (paginate) => {
    await TravelListingAPI.getStateList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          let code =
            obj?.gst_state_code?.length === 1
              ? 0 + obj?.gst_state_code
              : obj?.gst_state_code;
          return {
            value: obj.id,
            label: `${code} -${obj?.name} `,
          };
        });
        setStateData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onProfileChange = async (e, flag) => {
    if (shouldDeleteAttachment) {
      let delAtt = [...deleteattachment];
      attachment.forEach((obj) => delAtt.push(obj.id));
      setDeleteAttachment(delAtt);
      setShouldDleteAttachment(false);
    }
    let valid_files_index = [];
    let _file = [];
    let valid_mime = [
      "image/jpeg", // for JPEG
      "image/png", // for PNG
      "application/pdf", // for PDF
      // MSG files don't have a standard MIME type;  might need custom handling
      "application/msword", // for DOC
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for DOCX
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // for XLSX
      "application/vnd.ms-excel.sheet.binary.macroEnabled.12", // for XLSB
    ];

    for (let i = 0; i < e.target.files.length; i++) {
      if (valid_mime.includes(e.target.files[i].type)) {
        valid_files_index.push(i);
        _file.push(e.target.files[i]);
      }
    }
    let ooold = [...file, ..._file];

    setfiles(ooold);

    // setfiles(ooold);
    let files = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < valid_files_index.length; i++) {
      let data = e.target.files[valid_files_index[i]];
      let myUrl = URL.createObjectURL(e.target.files[valid_files_index[i]]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      await TravelListingAPI.uploadFile(fileData).then((response) => {
        let name = { name: data.name };
        filename.push(name);
        files.push(response.data);
      });
    }

    setflag(true);
    setErrorattachment(false);
    setAttachment([]);
    setUploadDataIm(files);
  };
  const pushStayAt = (e, i) => {
    changeHandler(e.value, "hotel_state_id", i);
  };
  const pushPersonOne = (e, i, expData) => {
    // changeHandler(e.value, "person_one_id", i);
    setCalc(true);

    let cite_id = null;

    state?.travel_detail_data?.every((data) => {
      if (
        data?.to_city_id == expData?.city_id &&
        data?.to_city_category == expData?.city_category
      ) {
        cite_id = expData?.city_id;
        return false;
      }
      return true;
    });

    getEntitelmentPrice(e.value, cite_id, i, expData, "p1");
  };
  const pushPersonTwo = (e, i, expData) => {
    // changeHandler(e.value, "person_two_id", i);
    setCalc(true);

    let cite_id = null;

    state?.travel_detail_data?.every((data) => {
      if (
        data?.to_city_id == expData?.city_id &&
        data?.to_city_category == expData?.city_category
      ) {
        cite_id = expData?.city_id;
        return false;
      }
      return true;
    });

    getEntitelmentPriceNew(e.value, cite_id, i, expData, "p2");
  };
  const pushState = (e, i, expData) => {
    changeHandler(e.value, "stay_at", i, expData);
    setVendorValue("");
    if (e.value === "Family/Friends") {
      formValidation[i].hotel_name = "";
      formValidation[i].hotel_vendor_category = "";
      formValidation[i].hotel_gst_no = "";
      formValidation[i].invoice_no = "";
      formValidation[i].claim_amount = "";
    } else if (e.value === "Guest House");
    {
      formValidation[i].hotel_name = "";
      formValidation[i].hotel_vendor_category = "";
      formValidation[i].hotel_gst_no = "";
      formValidation[i].invoice_no = "";
      formValidation[i].claim_amount = "";
      formValidation[i].taxable_amount = "";
    }
    if (e.value == "Hotel" || e.value == "Guest House") {
      setAlertStayAt(false);
    }
  };

  const pushVendor = (e, i) => {
    changeHandler(e.value, "hotel_vendor_category", i);
    if (e.value === "Registered") {
      formValidation[i].hotel_name = "";
      formValidation[i].hotel_vendor_category = "";
      formValidation[i].hotel_gst_no = "";
      formValidation[i].invoice_no = "";
      formValidation[i].claim_amount = "";
      formValidation[i].hotel_state_id = "";
      formValidation[i].stay_at = "";
    } else if (e.value === "Non-Registered");
    {
      formValidation[i].hotel_name = "";
      formValidation[i].hotel_vendor_category = "";
      formValidation[i].hotel_gst_no = "";
      formValidation[i].invoice_no = "";
      formValidation[i].claim_amount = "";
      formValidation[i].taxable_amount = "";
      formValidation[i].hotel_state_id = "";
      formValidation[i].stay_at = "";
    }
  };
  const pushTaxRate = (e, i) => {
    changeHandler(e, "tax_rate_id", i);
    // changeHandler("", "hotel_gst_no", i);
  };

  const itemData = expenseData.map((value) => ({
    check_in_date: state?.travel_detail_data[0]?.from_date,
    check_out_date: state?.travel_detail_data[1]?.to_date,
    city_id: state?.travel_detail_data[0]?.from_city_id,
    person_one_id: value?.person_one_id,
    person_one_name: value?.person_one_name,
    second_entitlement: value?.second_entitlement,
    third_entitlement: value?.third_entitlement,
    person_two_id: value?.person_two_id,
    person_two_name: value?.person_two_name,
    days: calDateValue,
    stay_at: value.stay_at,
    hotel_state_id: value.hotel_state_id,
    hotel_name: value.hotel_name,
    hotel_vendor_category: value.hotel_vendor_category,
    hotel_gst_no: value.hotel_gst_no,
    invoice_no: value.invoice_no,
    invoice_date: value.invoice_date,
    sharing: value.sharing,
    taxable_amount: value.tax_amount,
    tax_rate_id: value?.tax_rate_id,
    tax_amount: value.tax_amount,
    // other_charges: value.other_charges,
    tax_bill_amount: value.tax_bill_amount,
    claim_amount: value.claim_amount,
    total_claim_amount: value.total_claim_amount,
    reason_for_exception_name: value?.reason_for_exception_name,
    remark: value?.remark,
  }));

  function expenseHotelEdit() {
    const arrayGST = [];
    if (hotelDataValue) {
      for (let i = 0; i < hotelDataValue.length; i++) {
        arrayGST.push({
          key: hotelDataValue[i].id,
          id: hotelDataValue[i].id,
          appr_amount: hotelDataValue[i].appr_amount,
          appr_bill_amount: hotelDataValue[i].appr_bill_amount,
          appr_claim_amount: hotelDataValue[i].appr_claim_amount,
          // appr_other_charges: hotelDataValue[i].appr_other_charges,
          appr_tax_amount: hotelDataValue[i].appr_tax_amount,
          appr_tax_rate: hotelDataValue[i].appr_tax_rate,
          check_in_date: hotelDataValue[i].check_in_date,
          check_out_date: hotelDataValue[i].check_out_date,
          maxDateFrom: hotelDataValue[i]?.check_out_date,
          minDateFrom: hotelDataValue[i]?.check_in_date,
          maxDateTo: hotelDataValue[i]?.check_out_date,
          minDateTo: hotelDataValue[i]?.check_in_date,
          days_limit: moment(hotelDataValue[i].check_out_date).diff(
            hotelDataValue[i].check_in_date,
            "days"
          ),
          city_id: hotelDataValue[i].city_id,
          city_name: hotelDataValue[i].city_name,
          claim_amount: hotelDataValue[i].claim_amount,
          comments: hotelDataValue[i].comments,
          days: hotelDataValue[i].days,
          expense_by: hotelDataValue[i].expense_by,
          stay_at: hotelDataValue[i].stay_at,
          hotel_state_id: hotelDataValue[i].hotel_state_id,
          hotel_state_name: hotelDataValue[i].hotel_state_name,
          person_one_name: hotelDataValue[i].person_one_name,
          person_two_name: hotelDataValue[i].person_two_name,
          hotel_name: hotelDataValue[i].hotel_name,
          hotel_vendor_category: hotelDataValue[i].hotel_vendor_category,
          hotel_gst_no: hotelDataValue[i].hotel_gst_no,
          invoice_no: hotelDataValue[i].invoice_no,
          invoice_date: hotelDataValue[i].invoice_date,
          sharing: hotelDataValue[i].sharing,
          taxable_amount: hotelDataValue[i].taxable_amount,
          tax_rate_id: hotelDataValue[i].tax_rate_id,
          tax_amount: hotelDataValue[i].tax_amount,
          // other_charges: hotelDataValue[i].other_charges,
          person_one_id: hotelDataValue[i].person_one_id,
          person_two_id: hotelDataValue[i].person_two_id,
          second_entitlement: hotelDataValue[i].second_entitlement,
          third_entitlement: hotelDataValue[i].third_entitlement,
          tax_bill_amount: hotelDataValue[i].tax_bill_amount,
          tax_name: hotelDataValue[i].tax_name,
          tax_rate_value: hotelDataValue[i].tax_rate_value,
          total_bill_amount: hotelDataValue[i]?.total_bill_amount,
          user_id: hotelDataValue[i].user_id,
          user_name: hotelDataValue[i].user_name,
          total_claim_amount: hotelDataValue[i].total_claim_amount,
          remark: hotelDataValue[i].remark,
          reason_for_exception_name:
            hotelDataValue[i].reason_for_exception_name,
        });
      }
      setExpenseData(arrayGST);
    }
  }

  const tooltipTableComp = (
    <table
      class="table table-striped table-bordered tablecured viewpo"
      style={{ backgroundColor: "white" }}
    >
      <thead>
        <tr>
          <th>GSTIN</th>
          <th>Explanation</th>
          <th>Form</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="fw-bold text-theme">08</td>
          <td class="fw-bold text-theme">First Two Digit Denotes State Code</td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">A</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">A</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">B</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">C</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">P</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">2</td>
          <td class="fw-bold text-theme">Next Ten Digit Denotes PAN number</td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">1</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>

        <tr>
          <td class="fw-bold text-theme">8</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">3</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">M</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">1</td>
          <td class="fw-bold text-theme">
            3th Number Denotes number of Entity
          </td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">Z</td>
          <td class="fw-bold text-theme">14TH one is always default (Z)</td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">5</td>
          <td class="fw-bold text-theme">Last one is check code</td>
          <td class="fw-bold text-theme">Alphabet/Numeric</td>
        </tr>
      </tbody>
    </table>
  );
  const saveHotelExpenseData = (data) => {
    if (doubleClick == "false") {
      setDoubleClick("true");
      let data11 = data?.map((each) => {
        let obj = {};
        Object.keys(each).map((val) => {
          if (each[val] !== null && each[val] !== "") {
            obj[val] = each[val];
          }
        });
        return obj;
      });
      data11?.map((fd, i) => {
        fd.city_name = expenseData[i]?.city_name;
        fd.city_category = expenseData[i]?.city_category;
        fd.city_entitlement = expenseData[i]?.city_entitlement;
        fd.city_id = expenseData[i]?.city_id;
        fd.from_city_name = expenseData[i]?.from_city_name;
        fd.city_input = expenseData[i]?.city_name;
        fd.check_in_date = moment(expenseData[i]?.check_in_date).format(
          "YYYY-MM-DD"
        );
        fd.check_out_date = moment(expenseData[i]?.check_out_date).format(
          "YYYY-MM-DD"
        );
        if (expenseData[i]?.invoice_date !== "")
          fd.invoice_date = moment(expenseData[i]?.invoice_date).format(
            "YYYY-MM-DD"
          );
      });
      // ((data11.city_name = hotelListDataTwo?.city_name)),
      //   (data11.city_category = hotelListDataTwo?.city_category),
      //   (data11.city_entitlement = hotelListDataTwo?.city_entitlement);

      let dataSave = {
        travel_id: state?.id,
        attachment_path: uploadDataIm,
        attachment_delete: deleteattachment,
        hotel_data: data11,
      };
      TravelListingAPI.saveHotelExpenseData(dataSave)
        .then((response) => {
          if (response?.data.code == 200) {
            getHotelList(state?.id);
            setCheckActiveTab();
            setDoubleClick("false");
            toast.success("Hotel Expenses Added Successfully");
            setDeleteAttachment([]);
            setShouldDleteAttachment(true);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setDoubleClick("false");
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const updateHotelExpense = async (id, data) => {
    if (doubleClick == "false") {
      setDoubleClick("true");
      let data11 = data?.map((each) => {
        let obj = {};
        Object.keys(each).map((val) => {
          if (each[val] !== null && each[val] !== "") {
            obj[val] = each[val];
          }
        });
        return obj;
      });
      data11?.map((fd, i) => {
        fd.city_name = expenseData[i]?.city_name;
        fd.city_category = expenseData[i]?.city_category;
        fd.city_entitlement = expenseData[i]?.city_entitlement;
        fd.city_id = expenseData[i]?.city_id;
        fd.from_city_name = expenseData[i]?.from_city_name;
        fd.city_input = expenseData[i]?.city_name;
        fd.check_in_date = moment(expenseData[i]?.check_in_date).format(
          "YYYY-MM-DD"
        );
        fd.check_out_date = moment(expenseData[i]?.check_out_date).format(
          "YYYY-MM-DD"
        );
        if (expenseData[i]?.invoice_date !== "")
          fd.invoice_date = moment(expenseData[i]?.invoice_date).format(
            "YYYY-MM-DD"
          );
        if (fd.stay_at !== "Hotel") delete fd.invoice_date;
      });
      let dataSave = {
        travel_id: state?.id,
        attachment_path: uploadDataIm,
        attachment_delete: deleteattachment,
        hotel_data: data11,
      };
      await TravelListingAPI.updateHotelExpense(id, dataSave)
        .then((response) => {
          if (response.data.code == 200) {
            setCheckActiveTab();
            toast.success("Hotel Expense Updated Successfully");
            getHotelList(state?.id);
            setDoubleClick("false");
            setIsDelete(false);
            setDeleteAttachment([]);
            setShouldDleteAttachment(true);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setDoubleClick("false");
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const setValue = (id, value) => {
    return {
      value: id,
      label: value,
    };
  };
  const handleCalcBill = (value, i) => {
    let dataValue = JSON.parse(JSON.stringify(expenseData));
    if (dataValue[i].hotel_vendor_category === "Non-Registered") {
      dataValue[i].taxable_amount = 0;
    } else {
      dataValue[i].taxable_amount = value;
      let taxRateVal = taxRate?.filter((each) => {
        if (each?.value === dataValue[i]?.tax_rate_id) {
          return true;
        }
      })[0]?.label;
      let amt = (Number(taxRateVal) * value) / 100;
      // (Number(taxRateValue?.name) == NaN
      //   ? 0
      //   : Number(taxRateValue?.name) * Number(dataValue[i]?.taxable_amount) ==
      //     NaN
      //   ? 0
      //   : Number(dataValue[i]?.taxable_amount)) / 100;
      dataValue[i].tax_amount = amt;
      // let otherValue =
      //   dataValue[i].other_charges || Number(dataValue[i].other_charges);
      let clAmt = Number(dataValue[i]?.taxable_amount) + Number(amt);

      dataValue[i].tax_bill_amount = clAmt;
      setExpenseData(dataValue);
    }
  };
  const handleOtherCharges = (value, i) => {
    let dataValue = JSON.parse(JSON.stringify(expenseData));
    // dataValue[i].other_charges = value;
    if (dataValue[i].claim_amount === "0" || dataValue[i].claim_amount === "") {
      dataValue[i].total_claim_amount = 0;
      setExpenseData(dataValue);
    } else {
      let clAmt =
        Number(dataValue[i]?.taxable_amount) + Number(dataValue[i].tax_amount);
      //  +
      // Number(dataValue[i].other_charges);
      dataValue[i].tax_bill_amount = clAmt;
      let clAmtValue =
        Number(dataValue[i].claim_amount) + Number(dataValue[i]?.tax_amount);
      //  +
      // Number(dataValue[i].other_charges);
      dataValue[i].total_claim_amount = clAmtValue;
      setExpenseData(dataValue);
    }
  };
  const changeHandler = (value, name, index, expData) => {
    
    errEle = null;
    let fd = JSON.parse(JSON.stringify(expenseData));
    
    
    if (name === "stay_at") {
      if (value == "Guest House") {
        fd[index].hotel_vendor_category = "";
        fd[index].claim_amount = 0;
        fd[index].hotel_gst_no = "";
        fd[index].invoice_no = "";
        fd[index].sharing = false;
        fd[index].enterValueFirst = "";
        fd[index].invoice_date = "";
        fd[index].enterValueSecond = "";
        fd[index].tax_rate_id = taxRate[0].value;
        fd[index].tax_rate_value = 0;
        fd[index].tax_amount = 0;
        fd[index].taxable_amount = 0;
        fd[index].tax_bill_amount = 0;
        fd[index].claim_amount = 0;
        fd[index].total_claim_amount = 0;
        fd[index].remark = "";
        // fd[index].other_charges = 0;
      } else if (value === "Family/Friends") {
        fd[index].sharing = false;
        fd[index].enterValueFirst = "";
        fd[index].enterValueSecond = "";
      }
      if (value == "Hotel") {
        fd[index].hotel_vendor_category = "";
        fd[index].taxable_amount = "";
        fd[index].tax_bill_amount = 0;
        fd[index].hotel_gst_no = "";
        fd[index].tax_rate_id = taxRate[0].value;
        fd[index].tax_rate_value = 0;
        fd[index].claim_amount = "";
        fd[index].total_claim_amount = "";
      }
      let familyFriendsExists = false;
      fd?.map((data, idx) => {
        if (data?.city_name == expData?.city_name) {
          if (data?.stay_at == "Family/Friends") {
            setAlertStayAt(true);

            familyFriendsExists = true;
            fd[index].taxable_amount = "0";
            fd[index].tax_bill_amount = "0";
            fd[index].total_claim_amount = "0";
            fd[index].claim_amount = "0";
            // fd[index].hotel_gst_no = "";
            fd[index].hotel_name = "";
            fd[index].invoice_date = "";
            fd[index].tax_rate_id = taxRate[0].value;
            fd[index].tax_rate_value = "";
            fd[index].hotel_vendor_category = "Non-Registered";
          }
        }
      });

      if (value == "Family/Friends" && familyFriendsExists == false) {
        if (
          dataNew[0]?.emp_sub_group == "J1" ||
          dataNew[0]?.emp_sub_group == "J2" ||
          dataNew[0]?.emp_sub_group == "J3" ||
          dataNew[0]?.emp_sub_group == "J4" ||
          dataNew[0]?.emp_sub_group == "E1" ||
          dataNew[0]?.emp_sub_group == "E2" ||
          dataNew[0]?.emp_sub_group == "E3" ||
          dataNew[0]?.emp_sub_group == "E4" ||
          dataNew[0]?.emp_sub_group == "O1" ||
          dataNew[0]?.emp_sub_group == "O2" ||
          dataNew[0]?.emp_sub_group == "O3" ||
          dataNew[0]?.emp_sub_group == "O4" ||
          dataNew[0]?.emp_sub_group == "A1" ||
          dataNew[0]?.emp_sub_group == "A2" ||
          dataNew[0]?.emp_sub_group == "A3" ||
          dataNew[0]?.emp_sub_group == "A4" ||
          dataNew[0]?.emp_sub_group == "F1" ||
          dataNew[0]?.emp_sub_group == "F2" ||
          dataNew[0]?.emp_sub_group == "F3" ||
          dataNew[0]?.emp_sub_group == "F4" ||
          dataNew[0]?.emp_sub_group == "W1" ||
          dataNew[0]?.emp_sub_group == "W2" ||
          dataNew[0]?.emp_sub_group == "W3" ||
          dataNew[0]?.emp_sub_group == "W4"
        ) {
          fd[index].taxable_amount = "350";
          fd[index].tax_bill_amount = "350";
          fd[index].total_claim_amount = "350";
          fd[index].invoice_date = "";
          fd[index].claim_amount = "350";
          fd[index].tax_rate_id = taxRate[0].value;
          fd[index].tax_rate_value = "";
        } else if (
          dataNew[0]?.emp_sub_group == "M1" ||
          dataNew[0]?.emp_sub_group == "M2" ||
          dataNew[0]?.emp_sub_group == "M3" ||
          dataNew[0]?.emp_sub_group == "M4"
        ) {
          fd[index].taxable_amount = "500";
          fd[index].tax_bill_amount = "500";
          fd[index].total_claim_amount = "500";
          fd[index].claim_amount = "500";
          fd[index].hotel_gst_no = "";
          fd[index].hotel_name = "";
          fd[index].invoice_date = "";
          fd[index].tax_rate_id = taxRate[0].value;
          fd[index].tax_rate_value = "";
        } else if (
          dataNew[0]?.emp_sub_group == "S1" ||
          dataNew[0]?.emp_sub_group == "S2" ||
          dataNew[0]?.emp_sub_group == "S3" ||
          dataNew[0]?.emp_sub_group == "S4"
        ) {
          fd[index].taxable_amount = "1000";
          fd[index].tax_bill_amount = "1000";
          fd[index].total_claim_amount = "1000";
          fd[index].claim_amount = "1000";
          fd[index].hotel_gst_no = "";
          fd[index].hotel_name = "";
          fd[index].invoice_date = "";
          fd[index].tax_rate_id = taxRate[0].value;
          fd[index].tax_rate_value = "";
        } else {
          fd[index].taxable_amount = "1500";
          fd[index].tax_bill_amount = "1500";
          fd[index].total_claim_amount = "1500";
          fd[index].claim_amount = "1500";
          fd[index].hotel_gst_no = "";
          fd[index].hotel_name = "";
          fd[index].invoice_date = "";
          fd[index].tax_rate_id = taxRate[0].value;
          fd[index].tax_rate_value = "";
        }
        // fd[index].hotel_gst_no = "";
        fd[index].invoice_no = "";
        fd[index].tax_amount = "0";
        fd[index].hotel_gst_no = "";
        fd[index].hotel_name = "";
        fd[index].invoice_date = "";
        fd[index].tax_rate_id = taxRate[0].value;
        fd[index].tax_rate_value = "";
        fd[index].hotel_vendor_category = "Non-Registered";
      }
    }

    if (name === "invoice_date")
      fd[index].invoice_date = value ? moment(value).format("YYYY-MM-DD") : "";

    if (name === "reason_for_exception_name" && value !== "") {
      fd[index].is_exception = true;
    }
    if(value <= billAmtNot[index]){
      
      fd[index].is_exception = false;
    }
    if (name === "taxable_amount" && value !== "") {
      fd[index].tax_bill_amount = value;
      fd[index].claim_amount = "";
      fd[index].total_claim_amount = "";
    }

    if (
      (name === "hotel_vendor_category" && value === "Non-Registered") ||
      value === "Registered"
    ) {
      fd[index].tax_amount = "";
      fd[index].taxable_amount = "";
      fd[index].tax_bill_amount = "";

      fd[index].hotel_gst_no = "";
      fd[index].tax_rate_id = taxRate[0].value;
      fd[index].tax_rate_value = "";
      fd[index].claim_amount = "";
      fd[index].total_claim_amount = "";
    }
    if (name === "sharing" && value === false) {
      fd[index].person_one_id = "";
      fd[index].person_two_id = "";
      fd[index].enterValueFirst = "";
      fd[index].enterValueSecond = "";
      fd[index].second_entitlement = "";
      fd[index].third_entitlement = "";
      setExcludePerson(null);
    }
    if (value === "Non-Registered" && name === "hotel_vendor_category") {
      fd[index].tax_rate_id = taxRate[0].value;
      fd[index].tax_rate_value = "";
      fd[index].tax_amount = "";
    }

    if (name === "tax_rate_id") {
      // if (fd[index]?.claim_amount === "0" || fd[index]?.claim_amount === "") {
      //   fd[index].total_claim_amount = 0;
      // } else {
      let amt = (value?.label * fd[index]?.taxable_amount) / 100;
      fd[index].tax_amount = amt;
      fd[index][name] = value?.value;

      let clAmt =
        Number(fd[index]?.taxable_amount) + Number(fd[index]?.tax_amount);
      fd[index].tax_bill_amount = clAmt;

      let tbamt =
        Number(fd[index]?.tax_amount) + Number(fd[index]?.claim_amount);

      fd[index].total_claim_amount = tbamt;
      // }
    } else {
      if (name === "check_out_date") {
        fd[index]["days"] = value
          ? moment(value).diff(moment(fd[index]["check_in_date"]), "days")
          : "";
        calculateTotalAmount(
          fd[index]?.city_entitlement,
          fd[index]["days"],
          "days1",
          fd[index]?.second_entitlement,
          fd[index]?.third_entitlement,
          index
        );
      }
      if (name === "check_in_date") {
        fd[index][name] = value ? moment(value).format("YYYY-MM-DD") : "";
        fd[index]["minDateTo"] = value
          ? moment(value).format("YYYY-MM-DD")
          : null;
        fd[index]["days"] = value
          ? moment(fd[index]["check_out_date"]).diff(moment(value), "days")
          : "";
        calculateTotalAmount(
          fd[index]?.city_entitlement,
          fd[index]["days"],
          "days1",
          fd[index]?.second_entitlement,
          fd[index]?.third_entitlement,
          index
        );
        // fd[index]["minDateTo"] = moment(value)
        //   .add(1, "day")
        //   .format("YYYY-MM-DD");
      } else {
        fd[index][name] = value;
      }
    }
    // if ((name = "taxable_amount" && value !== "")) {
    //   fd[index].tax_bill_amount = value?.value;
    // }
    
    setExpenseData(fd);
  };

  useEffect(() => {}, [formValidation]);
  function scrollWin() {
    window.scrollTo(0, 150);
  }
  const handleValidateForm = (expenseData) => {
    // scrollWin();
    // if (handleValidate() === true) {
    //   window.scrollTo(0, 250);
    // }

    if (handleValidate()) return false;
    if (isEdit == "false") {
      saveHotelExpenseData(expenseData);
    } else {
      hotelExpense();
    }
  };
  const handleValidate = () => {
    let isError = false;
    let regexforGst = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    let formerr = JSON.parse(JSON.stringify(formValidation));
    JSON.parse(JSON.stringify(expenseData))?.map((expenseData, i) => {
      if (!expenseData.stay_at) {
        isError = true;
        formerr[i].stay_at = "*Stay At is required ";
        if (!errEle) errEle = expenseData.key;
      }
      if (
        !expenseData.hotel_vendor_category &&
        expenseData.stay_at != "Guest House"
      ) {
        isError = true;
        formerr[i].hotel_vendor_category = "Vendor Category is required";
        if (!errEle) errEle = expenseData.key;
        // setFormValidation(formerr);
      }
      if (expenseData.days == 0) {
        isError = true;
        formerr[i].days = "Days cannot be zero";
        if (!errEle) errEle = expenseData.key;
        // setFormValidation(formerr);
      }
      if (
        !expenseData.hotel_gst_no &&
        expenseData.stay_at !== "Guest House" &&
        expenseData.hotel_vendor_category !== "Non-Registered"
      ) {
        isError = true;
        formerr[i].hotel_gst_no = "GST No is required";
        if (!errEle) errEle = expenseData.key;
        // setFormValidation(formerr);
      }
      if (
        expenseData.hotel_gst_no !== "" &&
        regexforGst.test(expenseData.hotel_gst_no) === false &&
        expenseData.stay_at !== "Guest House" &&
        expenseData.hotel_vendor_category !== "Non-Registered"
      ) {
        isError = true;
        formerr[i].hotel_gst_no = "*Wrong GST number";
        if (!errEle) errEle = expenseData.key;
      }
      // if (
      //   expenseData.sharing === true &&
      //   expenseData.enterValueFirst === "" &&
      //   expenseData.enterValueSecond === ""
      // ) {
      //   isError = true;
      //   formerr[i].sharing =
      //     "*Please selected Atleast One Sharing Person";
      // }

      if (!expenseData.hotel_state_id) {
        isError = true;
        formerr[i].hotel_state_id = "*Stay at State is Required";
        if (!errEle) errEle = expenseData.key;
      }
      if (
        !expenseData.hotel_name &&
        expenseData.stay_at != "Guest House" &&
        expenseData.stay_at != "Family/Friends"
      ) {
        isError = true;
        formerr[i].hotel_name = "*Hotel Name is Required";
        if (!errEle) errEle = expenseData.key;
      }
      // if (!expenseData.remark) {
      //   isError = true;
      //   formerr[i].remark = "*Remarks is Required";
      // }
      if (
        !expenseData.invoice_no &&
        expenseData.stay_at != "Guest House" &&
        expenseData.stay_at != "Family/Friends"
      ) {
        isError = true;
        formerr[i].invoice_no = "*Invoice No is required";
        if (!errEle) errEle = expenseData.key;
        // setFormValidation(formerr);
      }
      if (!expenseData.taxable_amount && expenseData.stay_at != "Guest House") {
        isError = true;
        formerr[i].taxable_amount = "*Bill Amount is required";
        if (!errEle) errEle = expenseData.key;
        // setFormValidation(formerr);
      }
      if (
        !expenseData.claim_amount &&
        expenseData.stay_at != "Guest House" &&
        expenseData.stay_at != "Family/Friends"
      ) {
        isError = true;
        formerr[i].claim_amount = "*Claim Amount is required";
        if (!errEle) errEle = expenseData.key;
        // setFormValidation(formerr);
      }
      if (!expenseData.taxable_amount && expenseData.stay_at != "Guest House") {
        isError = true;
        formerr[i].taxable_amount = "*Bill Amount is required";
        if (!errEle) errEle = expenseData.key;
        // setFormValidation(formerr);
      }
      // if (!expenseData?.reason_for_exception_name) {
      //   isError = true;
      //   formerr[i].reason_for_exception_name = "*Exception Reason required";
      // setFormValidation(formerr);
      // }
      if (
        !expenseData?.check_out_date ||
        !expenseData?.check_in_date ||
        ((expenseData.days === "" || expenseData?.days === 0) &&
          moment(expenseData?.check_out_date).diff(
            moment(expenseData?.check_in_date),
            "days"
          ) === 0)
      ) {
        isError = true;
        formerr[i].days = "*Expense Days is required";
        if (!errEle) errEle = expenseData.key;
      }
      if (
        expenseData.stay_at === "Hotel" &&
        expenseData.hotel_vendor_category != "Non-Registered" &&
        expenseData?.hotel_gst_no?.includes(
          stateData
            ?.filter((each) => {
              if (each?.value === expenseData?.hotel_state_id) {
                return each;
              }
            })[0]
            ?.label.slice(0, 2)
        ) === false
      ) {
        isError = true;
        formerr[i].hotel_gst_no = "State Code not matching";
        // setFormValidation(formerr);
      }

      // if (alertStayAt == true) {
      //   isError = true;
      // }
    });
    setFormValidation(formerr);
    if (errEle !== null) {
      document.getElementById(errEle)?.scrollIntoView({ behavior: "smooth" });
    }
    return isError;
  };

  const handleKeyPress = (e) => {
    ["e", "E", "+", "-", "ArrowDown"].includes(e.key) && e.preventDefault();
  };
  const handleKeyPressNew = (e) => {
    ["e", "E", "+", "-", "ArrowDown", "."].includes(e.key) &&
      e.preventDefault();
  };
  const deleteAttachment = (id) => {
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (attachment == undefined) {
      let obj = file.filter((ele) => ele.name !== id);
      // let obj = {};
      // Object.keys(file)
      //   .filter((del) => {
      //     if (file[del].name !== id) {
      //       return true;
      //     }
      //   })
      //   .map((item) => {
      //     obj[item] = file[item];
      //   });
      document.getElementById("policyattachment").value = "";
      setfiles(obj);
    } else {
      let deleteId = [...deleteattachment];
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          if (del.attachment_path?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            if (!deleteId.includes(del.id)) deleteId.push(del.id);
          }
        });
        setAttachment(data2);
        setDeleteAttachment(deleteId);
        document.getElementById("policyattachment").value = "";
      } else {
        let obj = file.filter((ele) => ele.name !== id);
        // let obj = {};
        // Object.keys(file)
        //   .filter((del) => {
        //     if (file[del].name !== id) {
        //       return true;
        //     }
        //   })
        //   .map((item) => {
        //     obj[item] = file[item];
        //   });
        document.getElementById("policyattachment").value = "";
        setfiles(obj);
      }
    }
  };
  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1 col-2">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ||
                data2.name.includes(".csv") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name.includes(".msg") ? (
                <img
                  src={"/images/policy-icon/msg.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
              <br></br>
              <span>{data2?.name}</span>
            </button>
          </div>
        </>
      );
    }
    return data;
  };

  // const notAmt =()={
  //   (expenseData[i]?.sharing === false
  //     ? 0
  //     : expenseData[i].enterValueFirst) +
  //   (expenseData[i]?.sharing === false
  //     ? 0
  //     : expenseData[i]?.enterValueSecond))
  // }

  const validateSSCFeature = () => {
    let errorExist = false;
    expenseData.map((item) => {
      if (
        // item?.bill_number === "" ||
        // item?.bill_date === "" ||
        // item?.taxable_amount?.trim().length === 0 ||
        // item?.remark === ""
        item &&
        item?.stay_at === undefined &&
        // item?.hotel_state_id === 0 ||
        item &&
        item?.hotel_vendor_category === undefined
        // item?.invoice_no === "" ||
        // item?.invoice_date === "" ||
        // item?.taxable_amount?.trim().length === 0 ||
        // item?.tax_rate_id === 0 ||
        // item?.other_charges === 0 ||
        // item?.tax_bill_amount?.trim().length === 0 ||
        // item?.claim_amount?.trim().length === 0 ||
        // item?.taxable_amount?.trim().length === 0
      ) {
        errorExist = true;
      }
      return item;
    });
    return errorExist;
  };

  const addMoreExpense = (ele, i) => {
    // setAlertForAmount(false);
    // if (validateSSCFeature()) {
    // } else {
    if (expenseData.length < 90) {
      const copyValue = JSON.parse(JSON.stringify(expenseData));
      const ele = {};
      ele.check_in_date = "";
      ele.check_out_date = "";
      ele.city_id = 0;
      ele.days = 0;
      ele.stay_at = "";
      ele.hotel_state_id = 0;
      ele.hotel_name = "";
      ele.hotel_vendor_category = "";
      ele.hotel_gst_no = "";
      ele.invoice_no = "";
      ele.invoice_date = "";
      ele.sharing = "";
      ele.taxable_amount = 0;
      ele.tax_rate_id = "";
      ele.tax_amount = 0;
      // ele.other_charges = 0;
      ele.tax_bill_amount = 0;
      ele.claim_amount = 0;
      ele.taxable_amount = 0;
      ele.total_claim_amount = 0;
      ele.remark = "";
      ele.taxable_amount = 0;
      ele.key = expenseData[expenseData.length - 1].key + 1;
      copyValue.push(ele);
      setExpenseData(copyValue);
    } else {
      toast.error("max");
    }
  };
  //   }
  // };

  const removeHotelExpenses = () => {
    const minVal = hotelList;
    const index = minVal?.findIndex((v) => v.id == deleteHotelData.id);
    if (index > -1) {
      minVal.splice(index, 1);
    }
    updateHotelExpense(state.id, minVal);
  };
  const removeHotel = (idx) => {
    let curData = minDate(idx);
    if (idx > 0) {
      curData[idx - 1].activeRec = true;
      curData[idx - 1].days_limit = curData[idx]?.days_limit + 1;
    }
    let newExpDetails = curData.filter((item, i) => i !== idx);
    let DeletedData = curData.filter((item, i) => i == idx);
    const newValidation = formValidation.filter((item, i) => i !== idx);
    setExpenseData(newExpDetails);
    setFormValidation(newValidation);
  };
  const minDate = (idx) => {
    let curData = JSON.parse(JSON.stringify(expenseData));
    if (expenseData[idx - 1]) {
      if (expenseData[idx].city_name === expenseData[idx - 1].city_name) {
        if (
          new Date(expenseData[idx - 1].maxDateTo) <
          new Date(expenseData[idx].maxDateTo)
        )
          curData[idx - 1].maxDateTo = curData[idx].maxDateTo;
      }
      if (expenseData[idx].city_name === expenseData[idx - 1].city_name) {
        expenseData[idx - 1].maxDateTo = expenseData[idx].check_out_date;
      }
      if (expenseData[idx].fr) return curData;
    }
    if (expenseData[idx + 1]) {
      if (expenseData[idx].city_name == expenseData[idx + 1].city_name) {
        curData[idx + 1].minDateFrom = curData[idx].minDateFrom;
        return curData;
      }
    }
    return curData;
  };
  const removeHotelData = (idx) => {
    confirmAlert({
      title: "Delete Hotel Listing",
      message: `Are you sure to delete Hotel Listing?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => removeHotel(idx),
        },
        {
          label: "No",
        },
      ],
      closeOnClickOutside: false,
    });
  };
  const setCheckedValue = (e, i) => {
    let value = JSON.parse(JSON.stringify(expenseData));
    value[i].enterValueFirst = "";
    value[i].enterValueSecond = "";
    value[i].sharing = value[i]?.sharing === true ? false : true;
    setExpenseData(value);
  };
  const dateChkOut =
    state?.travel_detail_data[state?.travel_detail_data?.length - 1]?.to_date;
  let test = moment(dateChkOut).add(1, "day").format("YYYY-MM-DD");
  // const ddd = dateChkOut + 1;

  // date.setDate(date.getDate() + 1);
  const getEntitelmentPrice = (user_id, city_id, i, expData) => {
    TravelListingAPI.getEntitelmentPrice(user_id, city_id)
      .then((response) => {
        let data = response?.data?.dataList;
        setEntPrice(data?.hotel);
        let dataValue = JSON.parse(JSON.stringify(expenseData));
        dataValue[i].enterValueFirst = response?.data?.dataList?.hotel;
        dataValue[i].second_entitlement = response?.data?.dataList?.hotel;
        dataValue[i].person_one_id = user_id;
        calculateTotalAmount(
          expData?.city_entitlement,
          expData?.days,
          "days1",
          response?.data?.dataList?.hotel,
          expenseData[i].enterValueSecond,
          i
        );
        setExpenseData(dataValue);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  ///code updted
  const getEntitelmentPriceNew = (user_id, city_id, i, expData, label) => {
    TravelListingAPI.getEntitelmentPrice(user_id, city_id)
      .then((response) => {
        let data = response?.data?.dataList;

        setEntPriceNew(data?.hotel);
        let dataValue = JSON.parse(JSON.stringify(expenseData));
        dataValue[i].enterValueSecond = response?.data?.dataList?.hotel;
        dataValue[i].person_two_id = user_id;
        dataValue[i].third_entitlement = response?.data?.dataList?.hotel;
        calculateTotalAmount(
          expData?.city_entitlement,
          expData?.days,
          label,
          expenseData[i].enterValueFirst,
          response?.data?.dataList?.hotel,
          i
        );
        setExpenseData(dataValue);
        // setExpenseData(
        //   ...expenseData,
        //   second_entitlement : expenseData[i].enterValueFirst
        // );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  // const getEntitelmentPriceBill = (user_id, city_id) => {
  //   TravelListingAPI.getEntitelmentPrice(user_id, city_id)
  //     .then((response) => {
  //       let data = response?.data?.dataList;
  //       setBillPrice(data?.hotel);
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };
  const chkGstExist = async (e, i) => {
    let payload = {
      gst_no: e.target.value,
    };
    await TravelListingAPI.chkGst(payload)
      .then((res) => {
        if (res?.status === 200) {
          let response = res?.data?.dataList;
          if (response?.is_exist) {
            let valid = JSON.parse(JSON.stringify(formValidation));
            valid[i].hotel_gst_no =
              "You can not enter the organization's GST number";
            setFormValidation(valid);
          }
        }
      })
      .catch((err) => {
        console.log(err, "valid");
      });
  };
  useEffect(() => {
    getCityList(false);
    getStateList(false);
    getTaxRate();
    getEmployee(false);
    getFcmList();
    getHotelListTwo(state?.id);
  }, []);

  useEffect(() => {
    let data1 = [];
    hotelList?.map((data, i) => {
      data1.push(data);
    });
    setHotelListData();
  }, [hotelList]);

  function hotelExpense() {
    // const minVal = hotelList;
    // const index = minVal?.findIndex((v) => v.id == hotelDataValue.id);
    // minVal.splice(index, 1, expenseData[0]);
    let data = expenseData?.map((each) => {
      let obj = {};
      Object.keys(each).map((val) => {
        if (each[val] !== null && each[val] !== "") {
          if (
            val == "check_in_date" ||
            val == "check_out_date" ||
            val == "invoice_date"
          ) {
            obj[val] = moment(each[val]).format("YYYY-MM-DD");
          } else {
            obj[val] = each[val];
          }
        }
      });
      return obj;
    });
    updateHotelExpense(state.id, data);
  }
  const onClose = () => {
    setShowForm(false);
  };

  const onShow = (i) => {
    // setShowForm(true);
    let dd = [...expenseData];
    if (dd?.length === 0) {
      dd.push({
        check_in_date: "",
        check_out_date: "",
        // city_id: "",
        person_one_id: undefined,
        person_two_id: undefined,
        second_entitlement: undefined,
        third_entitlement: undefined,
        days: 0,
        stay_at: "",
        hotel_state_id: "",
        hotel_name: "",
        hotel_vendor_category: "",
        hotel_gst_no: "",
        invoice_no: "",
        invoice_date: "",
        sharing: false,
        taxable_amount: 0,
        tax_rate_id: "",
        tax_amount: 0,
        // other_charges: "",
        tax_bill_amount: 0,
        claim_amount: 0,
        total_claim_amount: 0,
        // bill_amount: "",
        remark: "",
        reason_for_exception_name: undefined,
        city_name: expenseData[i]?.city_name,
        city_category: expenseData[i]?.city_category,
        city_entitlement: expenseData[i]?.city_entitlement,
        from_city_name: expenseData[i]?.from_city_name,
        city_input: expenseData[i]?.city_name,
        mode_name: expenseData[i]?.mode_name,
        key: expenseData[i].key + 1,
        city_id: expenseData[i]?.city_id,
      });
      setFormValidation([
        {
          // check_in_date: "",
          // check_out_date: "",
          // city_id: "",
          // days: "",
          stay_at: "",
          hotel_state_id: "",
          hotel_name: "",
          hotel_vendor_category: "",
          // hotel_gst_no: undefined,
          invoice_no: "",
          invoice_date: "",
          sharing: "",
          taxable_amount: "",
          tax_rate_id: "",
          // other_charges: "",
          tax_bill_amount: "",
          claim_amount: "",
          // bill_amount: "",
        },
      ]);
    } else {
      let moddd = {
        check_in_date: expenseData[i]?.check_in_date,
        check_out_date: expenseData[i]?.check_out_date,
        maxDateFrom: expenseData[i]?.check_out_date,
        minDateFrom: expenseData[i]?.check_in_date,
        maxDateTo: expenseData[i]?.check_out_date,
        minDateTo: expenseData[i]?.check_in_date,
        city_name: expenseData[i]?.city_name,
        city_category: expenseData[i]?.city_category,
        city_entitlement: expenseData[i]?.city_entitlement,
        from_city_name: expenseData[i]?.from_city_name,
        city_input: expenseData[i]?.city_name,
        city_id: expenseData[i]?.city_id,
        person_one_id: undefined,
        person_two_id: undefined,
        second_entitlement: undefined,
        third_entitlement: undefined,
        days: expenseData[i]?.days,
        stay_at: "",
        hotel_state_id: "",
        hotel_name: "",
        hotel_vendor_category: "",
        hotel_gst_no: "",
        invoice_no: "",
        invoice_date: "",
        sharing: false,
        taxable_amount: 0,
        tax_rate_id: "",
        tax_amount: 0,
        other_charges: "",
        tax_bill_amount: 0,
        claim_amount: 0,
        total_claim_amount: 0,
        remark: "",
        // bill_amount: "",
        reason_for_exception_name: undefined,
        mode_name: expenseData[i]?.mode_name,
      };
      // moddd.days_limit = dd[i].days_limit - 1
      moddd.activeRec = true;
      moddd.days_limit = dd[i]?.days_limit - 1;
      dd.splice(i + 1, 0, moddd);
      delete dd[i].activeRec;
      dd[i].days_limit -= 1;
      let validation = JSON.parse(JSON.stringify(formValidation));
      validation.splice(i + 1, 0, {
        // check_in_date: "",
        // check_out_date: "",
        city_id: "",
        // days: "",
        stay_at: "",
        hotel_state_id: "",
        hotel_name: "",
        hotel_vendor_category: "",
        // hotel_gst_no: undefined,
        invoice_no: "",
        invoice_date: "",
        sharing: "",
        taxable_amount: "",
        tax_rate_id: "",
        other_charges: "",
        tax_bill_amount: "",
        claim_amount: "",
        bill_amount: "",
      });
      setFormValidation(validation);
    }
    setExpenseData(dd);
  };

  const addFromModel = (hotelDatafromModel) => {
    // return
    // setShowForm(true);
    let dd = [...expenseData];
    let i = expenseData.length - 1;

    let alreadyAdded = false;

    expenseData?.every((eobj) => {
      if (
        moment(eobj.check_in_date).isSame(
          moment(hotelDatafromModel.check_in_date)
        ) &&
        moment(eobj.check_out_date).isSame(
          moment(hotelDatafromModel.check_out_date)
        )
      ) {
        alreadyAdded = true;

        return false;
      }
      return true;
    });

    if (alreadyAdded) {
      toast.error("Already added.");
      return false;
    }

    if (hotelListDataTwo?.length > 0 && !alreadyAdded) {
      let moddd = {
        check_in_date: hotelDatafromModel?.check_in_date,
        check_out_date: hotelDatafromModel?.check_out_date,
        maxDateFrom: hotelDatafromModel?.check_out_date,
        minDateFrom: hotelDatafromModel?.check_in_date,
        maxDateTo: hotelDatafromModel?.check_out_date,
        minDateTo: hotelDatafromModel?.check_in_date,
        city_name: hotelDatafromModel?.city_name,
        city_category: hotelDatafromModel?.city_category,
        city_entitlement: hotelDatafromModel?.city_entitlement,
        from_city_name: hotelDatafromModel?.from_city_name,
        city_input: hotelDatafromModel?.city_name,
        city_id: hotelDatafromModel?.city_id,
        person_one_id: undefined,
        person_two_id: undefined,
        second_entitlement: undefined,
        third_entitlement: undefined,
        days: moment(hotelDatafromModel?.check_out_date).diff(
          moment(hotelDatafromModel?.check_in_date),
          "days"
        ),
        stay_at: "",
        hotel_state_id: "",
        hotel_name: "",
        hotel_vendor_category: "",
        hotel_gst_no: "",
        invoice_no: "",
        invoice_date: "",
        sharing: false,
        taxable_amount: 0,
        tax_rate_id: "",
        tax_amount: 0,
        other_charges: "",
        tax_bill_amount: 0,
        claim_amount: 0,
        total_claim_amount: 0,
        remark: "",
        // bill_amount: "",
        reason_for_exception_name: undefined,
        mode_name: hotelDatafromModel?.mode_name,
      };
      // moddd.days_limit = dd[i].days_limit - 1
      moddd.activeRec = true;
      moddd.days_limit = dd[i]?.days_limit - 1;
      dd.splice(i + 1, 0, moddd);
      delete dd[i].activeRec;
      dd[i].days_limit -= 1;
      let validation = JSON.parse(JSON.stringify(formValidation));
      validation.splice(i + 1, 0, {
        // check_in_date: "",
        // check_out_date: "",
        city_id: "",
        // days: "",
        stay_at: "",
        hotel_state_id: "",
        hotel_name: "",
        hotel_vendor_category: "",
        // hotel_gst_no: undefined,
        invoice_no: "",
        invoice_date: "",
        sharing: "",
        taxable_amount: "",
        tax_rate_id: "",
        other_charges: "",
        tax_bill_amount: "",
        claim_amount: "",
        bill_amount: "",
      });
      setFormValidation(validation);
    }
    sethotelDateModal(false);
    setExpenseData(dd);
  };

  useEffect(() => {
    if (isEdit == true) {
      expenseHotelEdit();
    }
  }, [isEdit]);
  // useEffect(() => {
  //   getEntitelmentPriceBill(
  //     userData?.id,
  //     state?.travel_detail_data[state?.travel_detail_data?.length - 1]
  //       ?.to_city_id
  //   );
  // }, []);
  // useEffect(() => {
  //   if (isDelete === true) {
  //     removeHotelData();
  //   }
  // }, [isDelete]);

  useEffect(() => {
    if (dataNew[0]?.personal_area_name != undefined) {
      if (
        dataNew[0]?.personal_area_name === "1003" ||
        dataNew[0]?.personal_area_name === "1007" ||
        dataNew[0]?.personal_area_name === "1017"
      ) {
        let data = option?.filter((ele) => {
          if (ele?.label !== "Guest House") {
            return true;
          }
        });
        setFilterDataOpt(data);
      } else {
        setFilterDataOpt(option);
      }
    }
  }, [dataNew]);

  useEffect(() => {
    calculateDateDifference(
      expenseData[0]?.check_in_date,
      expenseData[0]?.check_out_date
    );
  }, [expenseData]);

  useEffect(() => {
    let data = allEmployee?.filter((each) => {
      if (each?.value !== excludePerson?.value) {
        return true;
      }
    });
    setAllEmployee(data);
  }, [excludePerson]);

  useEffect(() => {
    if (hotelList.length > 0) {
      setFormValidation(
        Array.from(hotelList).map((val) => {
          return {
            // check_in_date: "",
            // check_out_date: "",
            city_id: "",
            days: "",
            stay_at: "",
            hotel_state_id: "",
            hotel_name: "",
            hotel_vendor_category: "",
            hotel_gst_no: "",
            invoice_no: "",
            invoice_date: "",
            sharing: "",
            taxable_amount: "",
            tax_rate_id: "",
            // other_charges: "",
            tax_bill_amount: "",
            claim_amount: "",
            bill_amount: "",
          };
        })
      );
      let k = 1;
      let res = hotelList?.map((each) => {
        each.days_limit = moment(each?.check_out_date).diff(
          moment(each?.check_in_date),
          "days"
        );
        each.key = k++;
        each.maxDateFrom = each?.check_out_date;
        each.minDateFrom = each?.check_in_date;
        each.maxDateTo = each?.check_out_date;
        each.minDateTo = each?.check_in_date;
        each.activeRec =
          moment(each?.check_out_date).diff(
            moment(each?.check_in_date),
            "days"
          ) > 1
            ? true
            : false;
        return each;
      });
      setExpenseData(res);
    } else {
      if (hotelListDataTwo) {
        setFormValidation(
          Array.from(hotelListDataTwo).map((val) => {
            return {
              // check_in_date: "",
              // check_out_date: "",
              city_id: "",
              days: "",
              stay_at: "",
              hotel_state_id: "",
              hotel_name: "",
              hotel_vendor_category: "",
              hotel_gst_no: "",
              invoice_no: "",
              invoice_date: "",
              sharing: "",
              taxable_amount: "",
              tax_rate_id: "",
              // other_charges: "",
              tax_bill_amount: "",
              claim_amount: "",
              bill_amount: "",
            };
          })
        );
        let k = 1;
        let total_data = Array.from(hotelListDataTwo).map((obj) => {
          return {
            key: k++,
            check_in_date: obj?.check_in_date,
            check_out_date: obj?.check_out_date,
            days_limit: obj?.days_limit,
            maxDateFrom: moment(obj?.check_out_date)
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
            minDateFrom: obj?.check_in_date,
            maxDateTo: obj?.check_out_date,
            minDateTo: moment(obj?.check_in_date).format("YYYY-MM-DD"),
            // minDateTo: moment(obj?.check_in_date)
            //   .add(1, "day")
            //   .format("YYYY-MM-DD"),
            city_id: undefined,
            person_one_id: undefined,
            person_two_id: undefined,
            second_entitlement: undefined,
            third_entitlement: undefined,
            days: moment(obj?.check_out_date).diff(
              moment(obj?.check_in_date),
              "days"
            ),
            activeRec:
              moment(obj?.check_out_date).diff(
                moment(obj?.check_in_date),
                "days"
              ) > 1
                ? true
                : false,
            stay_at: "",
            hotel_state_id: "",
            hotel_name: "",
            hotel_vendor_category: "",
            hotel_gst_no: "",
            invoice_no: "",
            invoice_date: "",
            sharing: false,
            taxable_amount: "",
            // obj?.city_entitlement,
            tax_rate_id: "",
            tax_amount: "",
            // other_charges: "",
            tax_bill_amount: "",
            claim_amount: "",
            total_claim_amount: "",
            remark: "",
            bill_amount: "",
            reason_for_exception_name: "",
            from_city_name: obj?.from_city_name,
            mode_name: obj?.mode_name,
            city_id: obj?.city_id,
            city_name: obj?.city_name,
            city_category: obj?.city_category,
            city_entitlement: obj?.city_entitlement,
            activeRec:
              moment(obj?.check_out_date).diff(
                moment(obj?.check_in_date),
                "days"
              ) > 1
                ? true
                : false,
          };
        });
        setExpenseData(total_data);
      }
    }

    if (hotelListDataTwo) {
      // setFormValidation(
      //   Array.from(hotelListDataTwo).map((val) => {
      //     return {
      //       // check_in_date: "",
      //       // check_out_date: "",
      //       city_id: "",
      //       days: "",
      //       stay_at: "",
      //       hotel_state_id: "",
      //       hotel_name: "",
      //       hotel_vendor_category: "",
      //       hotel_gst_no: "",
      //       invoice_no: "",
      //       invoice_date: "",
      //       sharing: "",
      //       taxable_amount: "",
      //       tax_rate_id: "",
      //       // other_charges: "",
      //       tax_bill_amount: "",
      //       claim_amount: "",
      //       bill_amount: "",
      //     };
      //   })
      // );
      let k = 1;
      let dateOption = [];

      let total_data = Array.from(hotelListDataTwo).map((obj) => {
        dateOption.push({
          label: `${moment(obj["check_in_date"]).format("DD-MM-YYYY")}-${moment(
            obj["check_out_date"]
          ).format("DD-MM-YYYY")}- (${obj.city_name})`,
          value: `${moment(obj["check_in_date"]).format("DD-MM-YYYY")}-${moment(
            obj["check_out_date"]
          ).format("DD-MM-YYYY")}- (${obj.city_name})`,
          check_in_date: obj["check_in_date"],
          check_out_date: obj["check_out_date"],
          city_name: obj?.city_name,
          city_category: obj?.city_category,
          city_entitlement: obj?.city_entitlement,
          from_city_name: obj?.from_city_name,
          city_input: obj?.city_name,
          city_id: obj?.city_id,
          mode_name: obj?.mode_name,
        });

        return {
          key: k++,
          check_in_date: obj?.check_in_date,
          check_out_date: obj?.check_out_date,
          days_limit: obj?.days_limit,
          maxDateFrom: moment(obj?.check_out_date)
            .subtract(1, "day")
            .format("YYYY-MM-DD"),
          minDateFrom: obj?.check_in_date,
          maxDateTo: obj?.check_out_date,
          minDateTo: moment(obj?.check_in_date).format("YYYY-MM-DD"),
          // minDateTo: moment(obj?.check_in_date)
          //   .add(1, "day")
          //   .format("YYYY-MM-DD"),
          city_id: undefined,
          person_one_id: undefined,
          person_two_id: undefined,
          second_entitlement: undefined,
          third_entitlement: undefined,
          days: moment(obj?.check_out_date).diff(
            moment(obj?.check_in_date),
            "days"
          ),
          activeRec:
            moment(obj?.check_out_date).diff(
              moment(obj?.check_in_date),
              "days"
            ) > 1
              ? true
              : false,
          stay_at: "",
          hotel_state_id: "",
          hotel_name: "",
          hotel_vendor_category: "",
          hotel_gst_no: "",
          invoice_no: "",
          invoice_date: "",
          sharing: false,
          taxable_amount: "",
          // obj?.city_entitlement,
          tax_rate_id: "",
          tax_amount: "",
          // other_charges: "",
          tax_bill_amount: "",
          claim_amount: "",
          total_claim_amount: "",
          remark: "",
          bill_amount: "",
          reason_for_exception_name: "",
          from_city_name: obj?.from_city_name,
          mode_name: obj?.mode_name,
          city_id: obj?.city_id,
          city_name: obj?.city_name,
          city_category: obj?.city_category,
          city_entitlement: obj?.city_entitlement,
          activeRec:
            moment(obj?.check_out_date).diff(
              moment(obj?.check_in_date),
              "days"
            ) > 1
              ? true
              : false,
        };
      });

      sethotelDateOption(dateOption);
      if (dateOption.length > 0) {
        // sethotelDate(dateOption[0]);
        // setEndDate(dateOption[dateOption.length - 1].date);
      }

      // setExpenseData(total_data);
    }
  }, [state, hotelListDataTwo, hotelList]);
  var day = 60 * 60 * 24 * 1000;
  const calculateTotalAmount = (hotelListDataTwo, value, flag, x, y, i) => {
    let kk = [];
    let sum = 0;
    let total = 0;
    let totalSum = JSON.parse(JSON.stringify(billAmtNot));
    let index = 0;
    if (flag === "list") {
      Array.from(hotelListDataTwo).map((obj, i) => {
        sum = Number(obj?.city_entitlement);
        if (
          expenseData[i]?.second_entitlement !== null &&
          expenseData[i]?.second_entitlement !== undefined &&
          expenseData[i]?.second_entitlement !== ""
        ) {
          sum += Number(obj?.second_entitlement);
        } else {
          sum += 0;
        }
        if (
          expenseData[i]?.third_entitlement !== null &&
          expenseData[i]?.second_entitlement !== undefined &&
          expenseData[i]?.second_entitlement !== ""
        ) {
          sum += Number(obj?.third_entitlement);
        } else {
          sum += 0;
        }
        kk[index] = sum * obj?.days;
        index++;
      });
      setBillAmtNot(kk);
    }

    // else if (flag === "days") {
    //   sum = Number(hotelListDataTwo) * Number(value);
    //   setBillAmtNot(sum);
    // }
    else {
      total =
        Number(hotelListDataTwo) +
        (x === undefined
          ? expenseData[i]?.second_entitlement !== undefined
            ? Number(expenseData[i]?.second_entitlement)
            : 0
          : Number(x)) +
        (y == undefined
          ? expenseData[i]?.third_entitlement !== undefined
            ? Number(expenseData[i]?.third_entitlement)
            : 0
          : Number(y));
      totalSum[i] = Number(value) * total;
      setBillAmtNot(totalSum);
    }
  };

  useEffect(() => {
    if (isEdit == false) {
      calculateTotalAmount(hotelListDataTwo, "", "list");
    }
  }, [hotelListDataTwo]);

  useEffect(() => {
    if (isEdit && !calc) {
      calculateTotalAmount(expenseData, "", "list");
    }
  }, [expenseData, calc]);

  function scrollWin() {
    window.scrollTo(0, 100);
  }

  useEffect(() => {
    if (alertStayAt == true) {
      scrollWin();
    }
  }, [alertStayAt]);

  useEffect(() => {
    if (
      dataNew[0]?.emp_sub_group == null ||
      dataNew[0]?.emp_sub_group == "" ||
      dataNew[0]?.location == null ||
      dataNew[0]?.location == ""
    ) {
      setGradeAndLocate(true);
    } else {
      setGradeAndLocate(false);
    }
  }, [dataNew]);

  return (
    <div>
      <div class="px-4 pb-2">
        {alertStayAt && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i>
            &nbsp;Cannot select 'family/friends' more than once for the same
            city.
          </Alert>
        )}
        {gradeAndLocate == true && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i>
            &nbsp;Your grade & location is not mapped in user master, please
            contact your BHR.
          </Alert>
        )}
        {hotelListDataTwo?.length !== 0 ? (
          <div class="row align-items-center">
            <div class="col-md-6">
              <h6 class="f mt-2">
                <strong>Total : </strong>
                <span class="text-muted">
                  {"  "}
                  <i class="fas fa-rupee-sign f-14"></i>
                  {"  "}
                  {totalHotelClaim}
                </span>
              </h6>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* {expenseData?.length === 0 && (
          <>
            <div className="col-sm-12">
              <div className="col-sm-10" style={{ float: "left" }}></div>
              <div
                className="col-sm-2"
                style={{ float: "right", marginLeft: "20px" }}
              >
                <button
                  class="btn btnblanktd text-primary"
                  onClick={() => {
                    // addMoreExpense(expenseData);
                    // handleValidate();
                    onShow(-1);
                  }}
                >
                  <i class="far fa-plus-square mr-2"></i> Add More
                </button>
              </div>
            </div>
          </>
        )} */}
        {expenseData?.length !== 0 ? (
          expenseData.map((expData, i) => (
            <div key={expData.key} id={expData.key}>
              <span
                style={{
                  color: "#959595",
                  fontWeight: "bold",
                  marginLeft: "30px",
                }}
              >
                Hotel {i + 1}
              </span>
              <div class="tickethistorbg mt-3 mx-4">
                <div class="row viewformaftersubmit">
                  <div class="col-md-3">
                    <div class="innergroup">
                      <p>TRAVEL DATE</p>
                      <label>
                        {moment.utc(expData?.minDateFrom).format("DD-MM-YYYY")}{" "}
                        {expData?.check_in_date === undefined
                          ? hotelListDataTwo?.check_in_date
                          : ""}
                        {/* {state?.travel_detail_data[
                        state?.travel_detail_data?.length - 1
                      ]?.from_time?.slice(0, 5)} */}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="innergroup">
                      <p>FROM</p>
                      <label>{`${expData?.from_city_name}  `}</label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="innergroup">
                      <p>DESTINATION</p>
                      <label>{`${
                        expData?.city_name === null || expData?.city_name === ""
                          ? expData?.city_input
                          : expData?.city_name
                      } `}</label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="innergroup">
                      <p>MODE</p>
                      <label>{expData.mode_name}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-light px-3 pt-3 mb-3">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group innergroup d-flex flex-column">
                      <label>
                        Check in Date
                        <span class="text-danger">*</span>
                      </label>
                      {/* <input
                      type="date"
                      class="form-control"
                      name="check_in_date"
                      // disabled
                      // value={state?.travel_detail_data[0]?.from_date}
                      min={expData?.minDateFrom}
                      max={expData?.maxDateFrom}
                      value={
                        isEdit
                          ? expData?.check_in_date
                          : expenseData[i]?.check_in_date
                      }
                      onChange={(e) => {
                        changeHandler(e.target.value, "check_in_date", i);
                      }}
                    /> */}
                      <DatePicker
                        selected={
                          isEdit
                            ? expData?.check_in_date &&
                              expData?.check_in_date !== ""
                              ? new Date(expData?.check_in_date)
                              : null
                            : expenseData[i]?.check_in_date &&
                              expenseData[i]?.check_in_date !== ""
                            ? new Date(expenseData[i]?.check_in_date)
                            : null
                        }
                        onChange={(date) => {
                          changeHandler(date, "check_in_date", i);
                        }}
                        minDate={
                          expData?.minDateFrom !== "" &&
                          new Date(expData?.minDateFrom)
                        }
                        maxDate={
                          expData?.maxDateFrom !== "" &&
                          new Date(expData?.maxDateFrom)
                        }
                        placeholderText="DD-MM-YYYY"
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        City/Entitlement<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="city_id"
                        disabled
                        value={`${
                          expData?.city_name === null ||
                          expData?.city_name === ""
                            ? expData?.city_input
                            : expData?.city_name
                        } - ${expData?.city_category} - ${
                          expData?.city_entitlement
                        } `}
                      />
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup d-flex flex-column">
                      <label>
                        Checkout Date <span class="text-danger">*</span>
                      </label>
                      {/* <input
                      type="date"
                      class="form-control"
                      name="check_out_date"
                      min={expData?.minDateTo}
                      max={expData?.maxDateTo}
                      value={
                        isEdit
                          ? expData?.check_out_date
                          : expenseData[i]?.check_out_date
                      }
                      onChange={(e) => {
                        changeHandler(e.target.value, "check_out_date", i);
                      }}
                    /> */}
                      <DatePicker
                        selected={
                          isEdit
                            ? expData?.check_out_date &&
                              expData?.check_out_date !== ""
                              ? new Date(expData?.check_out_date)
                              : null
                            : expenseData[i]?.check_out_date &&
                              expenseData[i]?.check_out_date !== ""
                            ? new Date(expenseData[i]?.check_out_date)
                            : null
                        }
                        onChange={(date) => {
                          changeHandler(date, "check_out_date", i);
                        }}
                        minDate={
                          expData?.minDateTo !== "" &&
                          new Date(expData?.minDateTo)
                        }
                        maxDate={
                          expData?.maxDateTo !== "" &&
                          new Date(expData?.maxDateTo)
                        }
                        placeholderText="DD-MM-YYYY"
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Days<span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        class="form-control noscroll"
                        name="days"
                        placeholder="Enter Days"
                        onKeyDown={handleKeyPressNew}
                        // max="9"
                        // value={diffInDays}
                        onChange={(e) => {
                          if (
                            JSON.stringify(Number(expData?.days))?.length > 1
                          ) {
                            if (
                              (e.target.value == "" &&
                                e.target.value?.length == 0) ||
                              (e.target.value != "" &&
                                e.target.value?.length == 1) ||
                              Number(e.target.value) -
                                (moment(expData?.check_out_date).diff(
                                  moment(expData?.check_in_date),
                                  "days"
                                ) +
                                  1) ===
                                0
                              // e.target.value >=
                              // moment(expData?.check_out_date).diff(
                              //   moment(expData?.check_in_date),
                              //   "days"
                              // ) +
                              //   1
                            ) {
                              setCalc(true);
                              changeHandler(e.target.value, "days", i);
                              let curVal = JSON.parse(
                                JSON.stringify(formValidation)
                              );
                              calculateTotalAmount(
                                expData?.city_entitlement,
                                e.target.value,
                                "days1",
                                expenseData[i].enterValueFirst,
                                expenseData[i].enterValueSecond,
                                i
                              );
                              curVal[i].days = "";
                              setFormValidation(curVal);
                            }
                          } else if (
                            JSON.stringify(Number(expData?.days))?.length == 1
                          ) {
                            if (
                              e.target.value === "" ||
                              Number(e.target.value) -
                                (moment(expData?.check_out_date).diff(
                                  moment(expData?.check_in_date),
                                  "days"
                                ) +
                                  1) ===
                                0
                              // e.target.value >=
                              // moment(expData?.check_out_date).diff(
                              //   moment(expData?.check_in_date),
                              //   "days"
                              // ) +
                              //   1
                            ) {
                              setCalc(true);
                              changeHandler(e.target.value, "days", i);
                              let curVal = JSON.parse(
                                JSON.stringify(formValidation)
                              );
                              calculateTotalAmount(
                                expData?.city_entitlement,
                                e.target.value,
                                "days1",
                                expenseData[i].enterValueFirst,
                                expenseData[i].enterValueSecond,
                                i
                              );
                              curVal[i].days = "";
                              setFormValidation(curVal);
                            }
                          }
                        }}
                        value={
                          expData?.days === 0 &&
                          expData?.check_out_date !== "" &&
                          expData?.check_in_date !== ""
                            ? moment(expData?.check_out_date).diff(
                                moment(expData?.check_in_date),
                                "days"
                              )
                            : expData?.days
                        }
                      />
                      <div className="small text-danger">
                        {formValidation[i]?.days}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Stay At<span class="text-danger">*</span>
                      </label>

                      <Select
                        options={chkFilterOpt}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        name="stay_at"
                        id={"stay_at" + i}
                        onChange={(e) => {
                          pushState(e, i, expData);
                          setStayAtValue(e);
                          setStayAtFlag(true);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[i].stay_at = "";
                          setFormValidation(curVal);
                        }}
                        value={
                          isEdit == true && stayAtFlag == false
                            ? setValue(expData?.stay_at, expData?.stay_at)
                            : expData?.stay_at !== ""
                            ? chkFilterOpt?.filter((each) => {
                                if (each?.value === expData?.stay_at) {
                                  return true;
                                }
                              })[0]
                            : ""
                        }
                      />
                      <div className="small text-danger">
                        {formValidation[i]?.stay_at}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Hotel Name<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="hotel_name"
                        maxLength={30}
                        disabled={
                          expenseData[i]?.stay_at === "Family/Friends" ||
                          expenseData[i]?.stay_at === "Guest House"
                        }
                        placeholder="Enter Hotel Name"
                        onChange={(e) => {
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[i].hotel_name = "";
                          setFormValidation(curVal);
                          changeHandler(e.target.value, "hotel_name", i);
                        }}
                        value={
                          isEdit
                            ? expData?.hotel_name
                            : expenseData[i]?.hotel_name
                        }
                        // value={expenseData[i]?.hotel_name}
                      />
                      <div className="small text-danger">
                        {formValidation[i]?.hotel_name}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Vendor Catagory<span class="text-danger">*</span>
                      </label>

                      <Select
                        options={vendorOption}
                        className="basic-multi-select"
                        isDisabled={
                          expenseData[i]?.stay_at === "Guest House" ||
                          expenseData[i]?.stay_at === "Family/Friends"
                        }
                        classNamePrefix="select"
                        name="hotel_vendor_category"
                        id={"hotel_vendor_category" + i}
                        onChange={(e) => {
                          pushVendor(e, i);
                          setVendorValue(e);
                          setCodeFlagVendor(true);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[i].hotel_vendor_category = "";
                          setFormValidation(curVal);
                        }}
                        value={
                          isEdit == true && codeFlagVendor == false
                            ? setValue(
                                expData.hotel_vendor_category,
                                expData.hotel_vendor_category
                              )
                            : vendorOption?.filter((obj) => {
                                if (
                                  obj.value ===
                                  expenseData[i]?.hotel_vendor_category
                                ) {
                                  return true;
                                }
                              })[0]
                        }
                      />
                      <div className="small text-danger">
                        {formValidation[i]?.hotel_vendor_category}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Stay At - State<span class="text-danger">*</span>
                      </label>
                      <Select
                        options={stateData}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        name="hotel_state_id"
                        id={"hotel_state_id" + i}
                        onChange={(e) => {
                          pushStayAt(e, i);
                          setStateValue(e);
                          setCodeFlagHotelState(true);

                          // setFormValidation({
                          //   ...formValidation,
                          //   hotel_state_id: "",
                          // });
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[i].hotel_state_id = "";
                          setFormValidation(curVal);
                        }}
                        value={
                          isEdit == true && codeFlagHotelState == false
                            ? setValue(
                                expData?.hotel_state_id,
                                expData?.hotel_state_name
                              )
                            : stateData !== undefined &&
                              expenseData[i].hotel_state_id !== ""
                            ? stateData?.filter((obj) => {
                                if (
                                  obj?.value === expenseData[i].hotel_state_id
                                ) {
                                  return obj;
                                }
                              })[0]
                            : ""
                          // stateValue
                        }
                      />
                      <div className="small text-danger">
                        {formValidation[i]?.hotel_state_id}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Hotel GST No</label>
                      <i
                        onClick={() => setVisibleD(true)}
                        class="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#4A9BD3" }}
                      ></i>
                      <input
                        type="text"
                        class="form-control"
                        name="hotel_gst_no"
                        onBlur={(e) => {
                          chkGstExist(e, i);
                        }}
                        maxLength={15}
                        disabled={
                          // expenseData[i]?.stay_at === "Guest House" ||
                          expenseData[i]?.hotel_vendor_category ===
                            "Non-Registered" ||
                          expenseData[i]?.stay_at === "Guest House"
                        }
                        placeholder="Enter Hotel GST No"
                        onChange={(e) => {
                          changeHandler(
                            e.target.value.trim(),
                            "hotel_gst_no",
                            i
                          );
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[i].hotel_gst_no = "";
                          setFormValidation(curVal);
                        }}
                        value={
                          isEdit
                            ? expData?.hotel_gst_no
                            : expenseData[i]?.hotel_gst_no
                        }
                      />
                      <div className="small text-danger">
                        {formValidation[i]?.hotel_gst_no}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Invoice No<span class="text-danger">*</span>
                      </label>

                      <input
                        type="text"
                        maxLength={16}
                        class="form-control"
                        name="invoice_no"
                        disabled={
                          expenseData[i]?.stay_at === "Family/Friends" ||
                          expenseData[i]?.stay_at === "Guest House"
                        }
                        placeholder="Enter Invoice GST No"
                        onChange={(e) => {
                          changeHandler(e.target.value.trim(), "invoice_no", i);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[i].invoice_no = "";
                          setFormValidation(curVal);
                        }}
                        value={
                          isEdit
                            ? expData?.invoice_no
                            : expenseData[i]?.invoice_no
                        }
                        // value={expenseData[i]?.invoice_no}
                      />
                      <div className="small text-danger">
                        {formValidation[i]?.invoice_no}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup d-flex flex-column">
                      <label>
                        Invoice Date<span class="text-danger">*</span>
                      </label>
                      {/* <input
                      type="date"
                      maxLength={40}
                      class="form-control"
                      disabled={
                        expenseData[i]?.stay_at === "Guest House" ||
                        expenseData[i]?.stay_at === "Family/Friends"
                      }
                      name="invoice_date"
                      onChange={(e) => {
                        changeHandler(e.target.value.trim(), "invoice_date", i);
                        let curVal = JSON.parse(JSON.stringify(formValidation));
                        curVal[i].invoice_no = "";
                        setFormValidation(curVal);
                        // setFormValidation({
                        //   ...formValidation,
                        //   invoice_date: "",
                        // });
                      }}
                      min={state?.travel_detail_data[0]?.from_date}
                      max={
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date
                      }
                      value={
                        isEdit
                          ? expData?.invoice_date
                          : expenseData[i]?.invoice_date
                      }
                    /> */}
                      <DatePicker
                        selected={
                          isEdit
                            ? expData?.invoice_date &&
                              expData?.invoice_date !== ""
                              ? new Date(expData?.invoice_date)
                              : null
                            : expenseData[i]?.invoice_date !== ""
                            ? new Date(expenseData[i]?.invoice_date)
                            : null
                        }
                        onChange={(date) => {
                          changeHandler(date, "invoice_date", i);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[i].invoice_no = "";
                          setFormValidation(curVal);
                        }}
                        disabled={
                          expenseData[i]?.stay_at === "Guest House" ||
                          expenseData[i]?.stay_at === "Family/Friends"
                        }
                        minDate={
                          state?.travel_detail_data[0]?.from_date !== "" &&
                          new Date(state?.travel_detail_data[0]?.from_date)
                        }
                        maxDate={
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date !== "" &&
                          new Date(
                            state?.travel_detail_data[
                              state?.travel_detail_data?.length - 1
                            ]?.to_date
                          )
                        }
                        placeholderText="DD-MM-YYYY"
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                      />
                      <div className="small text-danger">
                        {formValidation[i]?.invoice_date}
                      </div>
                    </div>
                  </div>
                  {expenseData[i].stay_at === "Hotel" ? (
                    <div className="col md-4">
                      <div class="form-group innergroup">
                        <div>
                          <label className="logCheck d-inline-block mr-4 mt-4">
                            Sharing
                            <input
                              type="checkbox"
                              name="sharing"
                              checked={expenseData[i]?.sharing === true}
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setAllEmployee(newEmp);
                                }
                                setCalc(false);

                                setCheckedValue(e, i);
                                changeHandler(e.target.checked, "sharing", i);
                              }}
                              value={expenseData[i]?.sharing}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {expenseData[i].sharing === true ? (
                    <div className="col-md-12">
                      <p style={{ fontSize: "12px" }}>
                        Note: Only one person should claim total bill amount in
                        case of room sharing
                      </p>{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  {expenseData[i].sharing === true ? (
                    <>
                      <div className="col-md-3">
                        <div className="form-group innergroup">
                          <label>2nd Person</label>
                          <Select
                            name="person_one_id"
                            id="person_one_id"
                            options={allEmployee}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              setExcludePerson(e);
                              // setExpenseData({
                              //   ...expenseData,
                              //   second_entitlement:
                              //     expenseData[i].enterValueFirst,
                              // });

                              pushPersonOne(e, i, expData);
                              // setStateValue(e);
                              // setCodeFlagHotelState(true);
                              setFlagpOne(true);
                              // setFormValidatiśon({
                              //   ...formValidation,
                              //   person_one_id: "",
                              // });
                              // let curVal = JSON.parse(JSON.stringify(formValidation));
                              // curVal[i].invoice_no = "";
                              // setFormValidation(curVal);
                            }}
                            value={
                              isEdit == true && codeFlagpOne == false
                                ? setValue(
                                    expData.person_one_id,
                                    expData.person_one_name
                                  )
                                : allEmployee !== undefined &&
                                  allEmployee?.filter((obj) => {
                                    if (
                                      obj?.value ===
                                      expenseData[i].person_one_id
                                    ) {
                                      return obj;
                                    }
                                  })[0]
                            }
                          />
                          {/* <div className="small text-danger">
                          {formValidation[i]?.sharing}
                        </div> */}
                          {expenseData[i]?.person_one_id !== "" ||
                          expenseData[i]?.person_one_id !== undefined ? (
                            <span
                              class="badge badge-primary text-white"
                              style={{
                                position: "absolute",
                                top: "34px",
                                right: "65px",
                              }}
                            >
                              {expenseData[i].second_entitlement}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div className="form-group innergroup">
                          <label>3rd Person</label>
                          <Select
                            name="person_two_id"
                            id="person_two_id"
                            options={allEmployee}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              pushPersonTwo(e, i, expData);

                              // setExcludePerson(e);
                              // setStateValue(e);
                              // setCodeFlagHotelState(true);
                              setFlagTwo(true);
                              // setFormValidation({
                              //   ...formValidation,
                              //   person_two_id: "",
                              // });

                              let curVal = JSON.parse(
                                JSON.stringify(formValidation)
                              );
                              curVal[i].person_two_id = "";
                              setFormValidation(curVal);
                            }}
                            value={
                              isEdit == true && codeFlagTwo == false
                                ? setValue(
                                    expData.person_two_id,
                                    expData.person_two_name
                                  )
                                : allEmployee !== undefined &&
                                  allEmployee?.filter((obj) => {
                                    if (
                                      obj?.value ===
                                      expenseData[i].person_two_id
                                    ) {
                                      return obj;
                                    }
                                  })[0]
                            }
                          />
                          <span
                            class="badge badge-primary text-white"
                            style={{
                              position: "absolute",
                              top: "34px",
                              right: "65px",
                            }}
                          >
                            {expenseData[i]?.third_entitlement}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Bill Amount (Excluding GST){" "}
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        onKeyDown={handleKeyPress}
                        disabled={
                          expenseData[i]?.stay_at === "Family/Friends" ||
                          expenseData[i]?.stay_at === "Guest House"
                        }
                        name="Bill Amount (Excluding GST) "
                        placeholder={expData?.city_entitlement}
                        onBlur={(e) => handleCalcBill(e.target.value, i)}
                        onChange={(e) => {
                          if (e.target.value?.length <= 20) {
                            let arr = ("" + e.target.value).split(".");
                            let num = expenseData[i].taxable_amount;
                            if (arr.length) {
                              if (arr.length == 1) {
                                num = arr[0];
                              } else {
                                if (Number(arr[1]) <= 99) {
                                  num = e.target.value;
                                }
                              }
                            }
                            changeHandler(num, "taxable_amount", i);

                            let curVal = JSON.parse(
                              JSON.stringify(formValidation)
                            );
                            curVal[i].taxable_amount = "";
                            setFormValidation(curVal);
                          }

                          // setFormValidation({
                          //   ...formValidation,
                          //   taxable_amount: "",
                          // });
                        }}
                        // value={expenseData[i]?.taxable_amount}
                        value={
                          isEdit
                            ? expData?.taxable_amount
                            : expenseData[i]?.taxable_amount
                        }
                      />
                      <div className="small" style={{ color: "#4a9bd4" }}>
                        {expenseData[i]?.stay_at === "Guest House" ||
                        expenseData[i]?.stay_at === "Family/Friends"
                          ? ""
                          : `Your maximum hotel entitlement limit is ${billAmtNot[i]}`}
                      </div>
                      <div className="small text-danger">
                        {formValidation[i]?.taxable_amount}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Tax Rate (%)<span class="text-danger">*</span>
                      </label>
                      <Select
                        options={taxRate}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isDisabled={
                          expenseData[i]?.stay_at === "Guest House" ||
                          expenseData[i]?.hotel_vendor_category ===
                            "Non-Registered"
                        }
                        name="tax_rate_id"
                        id={"tax_rate_id" + i}
                        onChange={(e) => {
                          pushTaxRate(e, i);
                          setTaxRateValue(e);
                          setCodeFlagTaxRate(true);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[i].tax_rate_id = "";
                          setFormValidation(curVal);
                          // taxAmountFinalFun(e.label, i);
                          // totalBillAmountFinal(e.label, i);
                        }}
                        // value={
                        //   expenseData && codeFlagTaxRate == false
                        //     ? setValue(
                        //         expenseData[i].tax_rate_id
                        //         // ,
                        //         // expenseData[i].gl_id_name
                        //       )
                        //     : expenseData[i]?.taxRateValue
                        // }
                        value={
                          isEdit == true && codeFlagTaxRate == false
                            ? setValue(
                                expData.tax_rate_id,
                                expData.tax_rate_value
                              )
                            : taxRate !== undefined &&
                              expenseData[i].tax_rate_id !== ""
                            ? taxRate?.filter((obj) => {
                                if (obj?.value === expenseData[i].tax_rate_id) {
                                  return obj;
                                }
                              })[0]
                            : ""
                          //  taxRateValue
                        }
                      />

                      <div className="small text-danger">
                        {formValidation[i]?.tax_rate_id}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Tax Amount<span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        maxLength={40}
                        class="form-control"
                        name="tax_amount"
                        disabled
                        value={Number(expenseData[i]?.tax_amount).toFixed(2)}
                        // value={
                        //   isEdit
                        //     ? expenseData?.tax_amount
                        //     : expenseData[i]?.tax_amount
                        // }
                      />
                    </div>
                  </div>
                  {/* <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      Other Charges<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      disabled={
                        expenseData[i]?.stay_at === "Guest House" ||
                        expenseData[i]?.stay_at === "Family/Friends"
                      }
                      class="form-control"
                      name="other_charges"
                      onKeyDown={handleKeyPress}
                      onBlur={(e) => {
                        handleOtherCharges(e.target.value, i);
                      }}
                      onChange={(e) => {
                        let arr = ("" + e.target.value).split(".");
                        let num = expenseData[i].other_charges;
                        if (arr.length) {
                          if (arr.length == 1) {
                            num = arr[0];
                          } else {
                            if (Number(arr[1]) <= 99) {
                              num = e.target.value;
                            }
                          }
                        }
                        changeHandler(num, "other_charges", i);
                        // if (e.target.value !== ".") {
                        // changeHandler(
                        //   Number(e.target.value) ? Number(e.target.value) : 0,
                        //   "other_charges",
                        //   i
                        // );
                        // }
                      }}
                      value={expenseData[i]?.other_charges}
                    />
                  </div>
                </div> */}

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Total Bill Amount<span class="text-danger">*</span>
                      </label>

                      <input
                        type="number"
                        pattern="[0-9]+"
                        disabled={
                          expenseData[i]?.stay_at === "Guest House" ||
                          expenseData[i]?.hotel_vendor_category ===
                            "Non-Registered"
                        }
                        class="form-control"
                        name="tax_bill_amount"
                        // placeholder="Enter Total Bill Amount"

                        onChange={(e) => {
                          if (
                            // e.target.value !== "." &&
                            // e.target.value.match(/^(0|[1-9]\d*)$/) &&
                            e.target.value?.length <= 20
                          ) {
                            let arr = ("" + e.target.value).split(".");
                            let num = expenseData[i].tax_bill_amount;
                            if (arr.length) {
                              if (arr.length == 1) {
                                num = arr[0];
                              } else {
                                if (Number(arr[1]) <= 99) {
                                  num = e.target.value;
                                }
                              }
                            }
                            changeHandler(num, "tax_bill_amount", i);
                            // changeHandler(
                            //   Number(e.target.value),
                            //   "tax_bill_amount",
                            //   i
                            // );

                            let curVal = JSON.parse(
                              JSON.stringify(formValidation)
                            );
                            curVal[i].tax_bill_amount = "";
                            setFormValidation(curVal);
                          }
                        }}
                        value={
                          isEdit
                            ? expData?.tax_bill_amount
                            : Number(expenseData[i]?.tax_bill_amount).toFixed(2)
                        }
                      />
                      <div className="small text-danger">
                        {formValidation[i]?.tax_bill_amount}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Claim Amount(Excluding GST)
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        pattern="[0-9]"
                        class="form-control"
                        onKeyDown={handleKeyPress}
                        disabled={
                          expenseData[i]?.stay_at === "Guest House" ||
                          expenseData[i]?.stay_at === "Family/Friends"
                        }
                        name="claim_amount"
                        placeholder="Enter Total Claim Amount"
                        // onChange={(e) => {
                        //   changeHandler(e.target.value.trim(), "claim_amount", i);
                        //   totalClaimAmountFinal(e.target.value, i);
                        //   let curVal = JSON.parse(JSON.stringify(formValidation));
                        //   curVal[i].claim_amount = "";
                        //   setFormValidation(curVal);
                        // }}
                        onChange={(e) => {
                          if (
                            // e.target.value.includes(".") == false &&
                            e.target.value?.length <= 20
                          ) {
                            // changeHandler(
                            //   Number(e.target.value),
                            //   "claim_amount",
                            //   i
                            // );
                            let arr = ("" + e.target.value).split(".");
                            let num = expenseData[i].claim_amount;
                            if (arr.length) {
                              if (arr.length == 1) {
                                num = arr[0];
                              } else {
                                if (Number(arr[1]) <= 99) {
                                  num = e.target.value;
                                }
                              }
                            }
                            changeHandler(num, "claim_amount", i);
                            totalClaimAmountFinal(e.target.value, i);
                            let curVal = JSON.parse(
                              JSON.stringify(formValidation)
                            );
                            curVal[i].claim_amount = "";
                            setFormValidation(curVal);
                          }
                        }}
                        // value={expenseData[i]?.claim_amount}
                        value={
                          isEdit
                            ? expData?.claim_amount
                            : expenseData[i]?.claim_amount
                        }
                      />
                      <div className="small text-danger">
                        {formValidation[i]?.claim_amount}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Total Claimed Amount
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        disabled
                        maxLength={20}
                        name="total_claim_amount"
                        placeholder="Enter Total Claimed Amount"
                        onChange={(e) => {
                          setExpenseData({
                            ...expenseData,
                            total_claim_amount: e.target.value,
                          });
                          // setFormValidation({
                          //   ...formValidation,
                          //   total_claim_amount: "",
                          // });

                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[i].total_claim_amount = "";
                          setFormValidation(curVal);
                        }}
                        value={Number(
                          expenseData[i]?.total_claim_amount
                        ).toFixed(2)}
                        // value={
                        //   isEdit
                        //     ? expenseData?.total_claim_amount
                        //     : expenseData[i]?.total_claim_amount
                        // }
                        // value={
                        //   isEdit
                        //     ? expenseData?.total_claim_amount
                        //     : totalClaimAmountFinal
                        // }
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Remarks</label>
                      <input
                        type="text"
                        class="form-control"
                        name="remark"
                        maxLength={30}
                        placeholder="Enter Remarks"
                        onChange={(e) => {
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[i].remark = "";
                          // setFormValidation(curVal);
                          changeHandler(e.target.value, "remark", i);
                        }}
                        value={
                          isEdit ? expData?.remark : expenseData[i]?.remark
                        }
                      />
                      {/* <div className="small text-danger">
                      {formValidation[i]?.remark}
                    </div> */}
                    </div>
                  </div>

                  {
                    // expenseData[i]?.claim_amount >
                    //   expenseData[i]?.days *
                    //     (expenseData[i]?.city_entitlement +
                    //       (expenseData[i]?.sharing === false
                    //         ? 0
                    //         : expenseData[i].enterValueFirst) +
                    //       (expenseData[i]?.sharing === false
                    //         ? 0
                    //         : expenseData[i]?.enterValueSecond)) &&
                    expenseData[i]?.claim_amount > billAmtNot[i] ? (
                      //  &&
                      // expenseData[i]?.stay_at === "Hotel" &&
                      // expenseData[i].reason_for_exception_name !== null &&
                      // isEdit == false
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>
                            Reason For Exception
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            maxLength={40}
                            name="reason_for_exception_name"
                            placeholder="Enter Reason For Exception"
                            onChange={(e) => {
                              changeHandler(
                                e.target.value,
                                "reason_for_exception_name",
                                i
                              );
                              // setExpenseData({
                              //   ...expenseData,
                              //   reason_for_exception_name: e.target.value,
                              // });
                              // setFormValidation({
                              //   ...formValidation,
                              //   reason_for_exception_name: "",
                              // });
                            }}
                            value={expenseData[i]?.reason_for_exception_name}
                          />

                          <i
                            class="fa fa-flag"
                            aria-hidden="true"
                            style={{ color: "red", paddingTop: "10px" }}
                          ></i>
                          {/* <div className="small text-danger">
                        {formValidation[i]?.reason_for_exception_name}
                      </div> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  }
                </div>
              </div>
              <div class="col-md-12 text-sm-right">
                {moment(expenseData[i]?.check_out_date).diff(
                  moment(expenseData[i]?.check_in_date),
                  "day"
                ) > 1 &&
                  expenseData[i]?.activeRec === true &&
                  expenseData[i]?.days_limit > 0 && (
                    // expenseData[i]?.days_limit > 0 &&
                    <button
                      class="btn btnblanktd text-primary"
                      onClick={() => {
                        // addMoreExpense(expenseData);
                        // handleValidate();
                        onShow(i);
                      }}
                    >
                      <i class="far fa-plus-square mr-2"></i> Add More
                    </button>
                  )}
                {/* 
              {moment(expenseData[i]?.check_out_date).diff(
                moment(expenseData[i]?.check_in_date),
                "day"
              ) === 1 && (
                <button
                  class="btn btnblanktd text-primary"
                  onClick={() => {
                    // addMoreExpense(expenseData);
                    // handleValidate();
                    onShow(-1);
                  }}
                >
                  <i class="far fa-plus-square mr-2"></i> Add More****
                </button>
              )} */}
                {/* {moment(expenseData[i]?.check_out_date).diff(
                moment(expenseData[i]?.check_in_date),
                "day"
              ) == 1 && ( */}
                <button
                  style={{ marginLeft: "10px" }}
                  class="btn btnblankth text-danger p-0"
                  disabled={expenseData.length === 1}
                  onClick={() => {
                    // removeHotel(i);
                    removeHotelData(i);
                  }}
                >
                  <i class="far fa-trash-alt"></i>
                </button>
                {/* )} */}
              </div>
              <div class="mb-3">
                <div class="row">
                  <div class="col-md-11 mt-3"></div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            <span style={{ paddingLeft: "290px" }}>
              Hotel not applicable for current trip,Please contact to SSC team
              to enable Stay At.
            </span>
          </div>
        )}

        {expenseData?.length !== 0 ? (
          <>
            <div class="row">
              <div class="col-md-12">
                <div class="border-top my-3"></div>
              </div>

              <div class="col-md-12">
                <div class="text-sm-right">
                  <button
                    class="btn btnblanktd text-primary"
                    onClick={() => {
                      sethotelDateModal(true);

                      // handleValidate();
                    }}
                    // disabled={newData.length == 0}
                  >
                    <i class="far fa-plus-square mr-2"></i> Add New Row
                  </button>
                </div>
              </div>

              <div class="col-md-4 d-flex flex-column mt-3">
                <h5 class="text-theme pb-2 mb-3">
                  <strong>
                    Attachments<span class="text-danger">*</span>
                  </strong>
                </h5>

                <div class="form-group mt-auto mb-0 innergroup position-relative modaldefaultclose ">
                  <label>
                    Attach File<span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control bg-white"
                    disabled
                    value={
                      // edit === true
                      // ? editPolicy?.logo
                      uploadDataIm?.logo?.original_name
                    }
                    placeholder="(jpeg, png, jpg, pdf, msg, doc, docx, xlsx, xlsb)"
                  />
                  <div class="upload-btn-wrapper up-loposition">
                    <button class="uploadBtn">Browse</button>
                    <input
                      type="file"
                      title=""
                      id="policyattachment"
                      multiple
                      name="attachment_path"
                      // accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                      onChange={(e) => onProfileChange(e, "attachment_path")}
                    />
                  </div>
                  <p>
                    Selected files{" "}
                    {/* <span> {uploadDataIm[0]?.original_name}</span> */}
                  </p>
                </div>
              </div>
            </div>
            {Object.keys(file).length !== 0 ? (
              <div className="d-flex flex-wrap">{attachments123()}</div>
            ) : attachment != undefined && attachment.length != 0 ? (
              <>
                <div class="d-flex flex-wrap">
                  {attachment?.map((Data) => (
                    <div class="uploaded-image mr-1 col-2">
                      <i class="" style={{ width: "20px" }}>
                        <a
                          href={Data?.attachment_path}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          {Data?.attachment_path?.includes(".pdf") ? (
                            <img
                              src={"/images/policy-icon/pdf.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".xlsx") ? (
                            <img
                              src={"/images/policy-icon/xlsx.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".xls") ? (
                            <img
                              src={"/images/policy-icon/xlsx.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".doc") ? (
                            <img
                              src={"/images/policy-icon/docs.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".docs") ||
                            Data?.attachment_path.includes(".csv") ? (
                            <img
                              src={"/images/policy-icon/docs.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".pptx") ? (
                            <img
                              src={"/images/policy-icon/ppt.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data.attachment_path.includes(".mp4") ? (
                            <img
                              src={"/images/policy-icon/video.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : Data?.attachment_path.includes(".msg") ? (
                            <img
                              src={"/images/policy-icon/msg.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : (
                            <img
                              src={Data.attachment_path}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          )}
                        </a>
                      </i>
                      <button
                        type="button"
                        onClick={(e) => {
                          deleteAttachment(
                            Data?.attachment_path?.split("/")[4]
                          );
                        }}
                        className="btn btn-blanktd text-danger ml-2"
                      >
                        <i className="far fa-trash-alt text-danger"></i>
                      </button>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <div class="row">
          {showForm && (
            <>
              <AddHotelChild
                showForm={showForm}
                onClose={onClose}
                hotelDataValue={hotelDataValue}
                isEdit={isEdit}
                hotelList={hotelList}
                deleteHotelData={deleteHotelData}
                isDelete={isDelete}
                getHotelList={getHotelList}
                setIsDelete={setIsDelete}
                getTotalAmt={getTotalAmt}
                dataNew={dataNew}
              />
            </>
          )}

          {expenseData?.length !== 0 ? (
            <>
              {isEdit === true ? (
                <div class="col-md-12 mt-3 text-center">
                  <button
                    type="button"
                    class="btn btn-outline-secondary mb-2 mt-2"
                    onClick={() => {
                      setCheckActiveTab("Summary");
                    }}
                  >
                    Back
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary-inner bpi-main"
                    onClick={() => handleValidateForm(expenseData)}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <div class="col-md-12 mt-3 text-center">
                  <button
                    type="button"
                    class="btn btn-outline-secondary mb-2 mt-2"
                    onClick={() => {
                      setCheckActiveTab("Summary");
                    }}
                  >
                    Back
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary-inner bpi-main"
                    onClick={() => {
                      handleValidateForm(expenseData);
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </>
          ) : (
            ""
          )}
          {/* {hotelListDataTwo?.length !== 0 ? (
    
          ) : (
            ""
          )} */}
        </div>
      </div>

      <Modal show={hotelDateModal}>
        <Modal.Header className="d-flex justify-content-between">
          <h5 className="fw-bold text-theme">Select Date & Location</h5>
          <i
            className="fas fa-times"
            style={{ cursor: "pointer" }}
            onClick={() => sethotelDateModal(false)}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <Select
            options={hotelDateOption}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => sethotelDate(e)}
            placeholder={"Select Date"}
            value={hotelDate}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary-inner bpi-main"
            onClick={() => {
              addFromModel(hotelDate);
            }}
          >
            Add Hotel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={visibleD} onHide={() => handleClose(true)}>
        <Modal.Header closeButton onClick={() => handleClose(true)}>
          <Modal.Title>
            {" "}
            <h5 class="fw-bold text-theme">GST NUMBER: 08AABCP2183M1Z5</h5>
            <i
              className="fas fa-times"
              style={{ cursor: "pointer" }}
              onClick={() => handleClose(true)}
            ></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{tooltipTableComp}</Modal.Body>
        <Modal.Footer>
          {/* <button variant="primary" onClick={() => handleClose(true)}>
    Ok
  </button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddHotel;
