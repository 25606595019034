import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const savesourceList = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/source-list/request`,
      method: "POST",
      data: data,
    });
  };
  export const updatesourceList = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/source-list/request`,
      method: "PUT",
      data: data,
    });
  };
  export const saveApprover = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/source-list/save-approver`,
      method: "POST",
      data: data,
    });
  };
  export const editApprover = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/source-list/save-approver`,
      method: "PUT",
      data: data,
    });
  };
  export const deleteApprover = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/source-list/save-approver`,
      method: "DELETE",
      data: data,
    });
  };


  export const getPoOrg = async (id,paginate) => {
    return axios.get(`${AUTH_BASE_URL}/purchase-org?sort_by=${id}&paginate=${paginate}`);
  };
  export const getPoOrgDrop = async (id,paginate) => {
    return axios.get(`${AUTH_BASE_URL}/purchase-org?sort_by=${id}&paginate=${paginate}`);
  };
  export const getPGroupDrop = async (id,paginate) => {
    return axios.get(`${AUTH_BASE_URL}/purchase-group?sort_by=${id}&paginate=${paginate}`);
  };

  export const getApprovalMastList = async (search,pagno,pagesize,sortby,plantcode,pocode, approver1_id,approver2_id,additional_approver_id,requisitioner_id,category) => {
    return axios.get(`${AUTH_BASE_URL}/source-list/approver-list?search=${search}&page_no=${pagno}&page_size=${pagesize}&sort_by=${sortby}&plant_id=${plantcode}&purchase_org_id=${pocode}&approver1_id=${approver1_id}&approver2_id=${approver2_id}&additional_approver_id=${additional_approver_id}&requisitioner_id=${requisitioner_id}&category=${category}`);
   
  };

  export const getApprovalMastListExport = async (search,pagno,pagesize,sortby,plantcode,pocode, approver1_id,approver2_id,additional_approver_id,category) => {
    return axios({ 
    url:`${AUTH_BASE_URL}/source-list/approver-list/export?search=${search}&page_no=${pagno}&page_size=${pagesize}&sort_by=${sortby}&plant_id=${plantcode}&purchase_org_id=${pocode}&approver1_id=${approver1_id}&approver2_id=${approver2_id}&additional_approver_id=${additional_approver_id}&category=${category}`,
    method: "GET",
    responseType: "blob"});
   
  };

  export const getSourcelist = async (search,pagno,pagesize,sortby,status,created,created_to,category,source_list_id,material_id,plant_id,vendor_id,approverStatus) => {
    return axios.get(`${AUTH_BASE_URL}/source-list/request?search=${search}&page_no=${pagno}&page_size=${pagesize}&sort_by=${sortby}&status=${status}&created_at=${created}&created_to=${created_to}&category=${category}&source_list_id=${source_list_id}&approverStatus=${approverStatus}&material_id=${material_id}&plant_id=${plant_id}&vendor_id=${vendor_id}`);
  };
  export const getSourcelistAll = async (search,pagno,pagesize,sortby,status,created,created_to,category,source_list_id,material_id,plant_id,vendor_id, self_approval_data, approverStatus) => {
    return axios.get(`${AUTH_BASE_URL}/source-list/request?search=${search}&page_no=${pagno}&page_size=${pagesize}&sort_by=${sortby}&status=${status}&created_at=${created}&created_to=${created_to}&category=${category}&source_list_id=${source_list_id}&material_id=${material_id}&plant_id=${plant_id}&vendor_id=${vendor_id}&self_approval_data=${self_approval_data}`);
  };
  export const getSourcelistExport = async (search,pagno,pagesize,sortby,status,created,created_to,category,source_list_id,material_id,plant_id,vendor_id,approverStatus) => {
    return axios({
      url:`${AUTH_BASE_URL}/source-list/request/export?search=${search}&page_no=${pagno}&page_size=${pagesize}&sort_by=${sortby}&status=${status}&created_at=${created}&created_to=${created_to}&category=${category}&source_list_id=${source_list_id}&approverStatus=${approverStatus}&material_id=${material_id}&plant_id=${plant_id}&vendor_id=${vendor_id}`,
      method: "GET",
      responseType: "blob",
    });
  }
  export const getSourcelistExportAll = async (search,pagno,pagesize,sortby,status,created,created_to,category,source_list_id,material_id,plant_id,vendor_id,approverStatus) => {
    return axios({
      url:`${AUTH_BASE_URL}/source-list/request/export?search=${search}&page_no=${pagno}&page_size=${pagesize}&sort_by=${sortby}&status=${status}&created_at=${created}&created_to=${created_to}&category=${category}&source_list_id=${source_list_id}&material_id=${material_id}&plant_id=${plant_id}&vendor_id=${vendor_id}`,
      method: "GET",
      responseType: "blob",
    });
  }


 

  export const changeStatus = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/source-list/action`,
      method: "POST",
      data: data,
    });
  };
  export const getMateriallist = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/buss-transactions/material-list`,
      method: "POST",
      data: data,
    });
  };
  export const getMateriallistDrop = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/buss-transactions/material-list-drop`,
      method: "POST",
      data: data,
    });
  }
 
  export const getSourcelistPlant = async (pg_id) => {
    return axios.get(`${AUTH_BASE_URL}/source-list/PlantDropdown?pg_id=${pg_id}`);
  };
  export const getSourcelistVedndor = async (pg_id) => {
    return axios.get(`${AUTH_BASE_URL}/source-list/vendorDropdown?pg_id=${pg_id}`);
  };

  export const changeBulkSourceListstatus = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/source-list/bulk/action`,
      method: "POST",
      data: data,
    });
  };