import React, { useEffect, useState } from 'react'

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Modal, Spinner } from 'react-bootstrap';
import * as MediclamService from '../../../service/medicalService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getAllOrganisation } from '../../../service/organisation';
import { getOnlyNumber } from '../../../utils/helpers';

const validationSchema = Yup.object({
    top_up_type: Yup.string().required("* Top up type is required").nullable(),
    sum_insured: Yup.number().required("* Sum insured is required").nullable(),
    premium: Yup.number().max(99999).required("* Preminum is required").nullable(),
    lower_age_limit: Yup.number().min(0).max(80).required("* Lower age limit is required").nullable(),
    upper_age_limit: Yup.number().max(80).required("* Upper age limit is required").nullable(),
    fin_year: Yup.number().required("* Financial year is required").nullable(),
    organisation_id: Yup.string().required("* Organisation code is required").nullable(),
});

const initialValues = {
    organisation_id: undefined, /* dropdown */
    top_up_type: undefined, /* dropdown */
    sum_insured: undefined, /* dropdown */
    premium: undefined,
    lower_age_limit: undefined,
    upper_age_limit: undefined,
    fin_year: undefined,
    is_active: false,
}

const AddMediclamPremiumTopupModal = ({ isOpen, edit, handleModalClose, refreshData }) => {
    const [orgs, setOrgs] = useState([]);
    const typeOptions = [
        { value: 'Single', label: 'Single' },
        { value: 'Double', label: 'Double' }
    ]
    const insuredOptions = [
        { value: 100000, label: 100000 },
        { value: 200000, label: 200000 },
        { value: 300000, label: 300000 },
        { value: 500000, label: 500000 },
    ]
    const orgOptions = orgs.map(org => ({ value: org?.id, label: `${org?.name} (${org?.code})` || '...' }));

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                let response = null;
                if (values?.id) {
                    response = await MediclamService.updateMediclaimPremiumTopUp(values)
                } else {
                    response = await MediclamService.addMediclaimPremiumTopUp(values)
                }
                // if (currentItem) {
                //     response = await MediclamService.updateReleaseCode(values, currentItem.id)
                // } else {
                //     response = await MediclamService.saveReleaseCode(values)
                // }
                if (response?.status) {
                    if (response?.data?.dataList?.id === 0) {
                        toast.error('Entry already exist')
                    } else {
                        toast.success(response?.data?.message)
                    }
                    refreshData()
                    _handleModalClose()
                } else {
                    toast.error(response.message)
                }
            } catch (error) {
                console.error("🚀 ~ file: pr-add-org-master.js:39 ~ onSubmit: ~ error:", error)
            } finally { setSubmitting(false) }
        }
    })
    const { handleSubmit, getFieldProps, errors, values, setFieldValue, touched, isSubmitting, resetForm, setValues } = formik;


    const _handleModalClose = () => {
        resetForm();
        handleModalClose()
    }

    // useEffect(() => {
    //     console.log("🚀 ~ file: pr-add-org-group.js:70 ~ PrAddOrgGroupModal ~ currentItem:", currentItem)
    //     if (currentItem) {
    //         setFieldValue('pgcode', currentItem.pgcode)
    //         setFieldValue('pgdesc', currentItem.pgdesc)
    //     }
    // }, [currentItem])
    const getOrgs = () => {
        getAllOrganisation('True')
            .then((response) => {
                if (response.data.code === 200) {
                    setOrgs(response?.data?.dataList?.result);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        isOpen && orgs.length === 0 && getOrgs()
    }, [isOpen]);

    useEffect(() => {
        if (edit) {
            setValues(edit)
        }
    }, [edit])

    return (
        <Modal
            show={isOpen}
            keyboard={false}
            size='lg'
            backdrop="static"
            onHide={_handleModalClose}
            className="modaldefaultclose modaldefaultclosecenter"
        >
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Mediclaim Premium Topup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group innergroup">
                                        <label>
                                            Top up type<span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            options={typeOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            value={typeOptions ? typeOptions.find(option => option.value === values.top_up_type) : ''}
                                            onChange={(option) => setFieldValue('top_up_type', option.value)}
                                        />
                                        {touched.top_up_type && errors.top_up_type ? (
                                            <div className="small text-danger">
                                                {errors.top_up_type}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group innergroup">
                                        <label>
                                            Sum insured{" "}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            options={insuredOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            value={insuredOptions ? insuredOptions.find(option => option.value === values.sum_insured) : ''}
                                            onChange={(option) => setFieldValue('sum_insured', option.value)}
                                        />
                                        {touched.sum_insured && errors.sum_insured ? (
                                            <div className="small text-danger">
                                                {errors.sum_insured}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group innergroup">
                                        <label>
                                            Premium{" "}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            {...getFieldProps('premium')}
                                            placeholder="Enter premium"
                                        />
                                        {touched.premium && errors.premium ? (
                                            <div className="small text-danger">
                                                {errors.premium}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group innergroup">
                                        <label>
                                            Lower age limit{" "}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            {...getFieldProps('lower_age_limit')}
                                            placeholder="Enter premium"
                                        />
                                        {touched.lower_age_limit && errors.lower_age_limit ? (
                                            <div className="small text-danger">
                                                {errors.lower_age_limit}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group innergroup">
                                        <label>
                                            Upper age limit{" "}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            {...getFieldProps('upper_age_limit')}
                                            placeholder="Enter premium"
                                        />
                                        {touched.upper_age_limit && errors.upper_age_limit ? (
                                            <div className="small text-danger">
                                                {errors.upper_age_limit}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group innergroup">
                                        <label>
                                            Financial year{" "}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            // className="form-control"
                                            // {...getFieldProps('fin_year')}
                                            placeholder="Enter premium"
                                            name='fin_year'
                                            className="form-control"
                                            value={values.fin_year}
                                            onChange={e => {
                                                setFieldValue('fin_year', getOnlyNumber(values.fin_year, e.target.value, 9999))
                                            }}
                                        />
                                        {touched.fin_year && errors.fin_year ? (
                                            <div className="small text-danger">
                                                {errors.fin_year}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group innergroup">
                                        <label>
                                            Company Code<span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            options={orgOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            value={orgOptions ? orgOptions.find(option => option.value === values.organisation_id) : ''}
                                            onChange={(option) => setFieldValue('organisation_id', option.value)}
                                        />
                                        {touched.organisation_id && errors.organisation_id ? (
                                            <div className="small text-danger">
                                                {errors.organisation_id}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group innergroup">
                                        <label className="d-block mb-2">
                                            Status<span className="text-danger">*</span>
                                        </label>
                                        <label className="logCheck d-inline-block mr-4">
                                            Active
                                            <input type="radio" name="statusupdate1" onChange={(e) => {
                                                setFieldValue('is_active', true)
                                            }} checked={(values?.is_active)} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="logCheck d-inline-block">
                                            Inactive
                                            <input type="radio" name="statusupdate1" onChange={(e) => {
                                                setFieldValue('is_active', false)
                                            }} checked={(values?.is_active === false)} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmitting
                            ? <Spinner animation="border" variant="primary" as="span" role="status" aria-hidden="true" />
                            : (
                                <>
                                    <div className="btn btn-outline-danger" onClick={_handleModalClose}>
                                        Cancel
                                    </div>
                                    <button type='submit' className="btn btn-primary-inner bpi-main">Submit</button>
                                </>
                            )
                    }
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddMediclamPremiumTopupModal;