import axios from "../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";


  export const save_visitor_response = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/visitor/response`, data);
  }
  export const get_response_submission_status = async(visitor_info_id)=>{
    return axios.get(`${AUTH_BASE_URL}/visitor/is_response_submitted?visitor_info_id=${visitor_info_id}`)
  }
  export const get_visitor_info = async(queryParm)=>{
    return axios.get(`${AUTH_BASE_URL}/visitor/view_visitor_info?${queryParm}`)
  }
  export const save_visitor_info = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/visitor/save_visitor_info`, data);
  }
  export const get_visitor_response = async(visitor_info_id)=>{
    return axios.get(`${AUTH_BASE_URL}/visitor/view_visitor_response?visitor_info_id=${visitor_info_id}`)
  }
  export const save_visiting_site_details = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/visitor/save_visiting_site_details`, data);
  }
  export const save_visiting_site_master = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/visitor/save_visiting_site_master`, data);
  }
  export const get_visiting_site_master_response = async(queryParm)=>{
    return axios.get(`${AUTH_BASE_URL}/visitor/get_visiting_site_master?${queryParm}`)
  }
  export const delete_site_master = async (id) => {
    return axios.delete(`${AUTH_BASE_URL}/visitor/visiting_site_master/${id}`)
};
  export const update_visiting_site_master = async (id, data) => {
    return axios.put(`${AUTH_BASE_URL}/visitor/visiting_site_master/${id}`, data)
};
  export const getPlantList = async (organisation_id) => {
    return axios.get(
      `${AUTH_BASE_URL}/buss-transactions/master-plant/list?organisation_id=${organisation_id}`
    );
  };