import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import * as ApproveService from "../../service/dashboard";
import ReactPaginate from "react-paginate";
import * as moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ApprovalData() {
  const navigate = useNavigate();
  const [approvalDataList, setApprovalDataList] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(1);
  const [approvalPaginationData, setApprovalPaginationData] = useState([]);
  const [pageSizeNo, setPageSizeNo] = useState(10);

  const handleViewRedirect = (transaction_id) => {
    navigate(`/viewJv/${transaction_id}`, {
      state: { id: transaction_id, flag: false },
    });
  };

  const getApproval = () => {
    ApproveService.getApproval()
      .then((response) => {
        setApprovalDataList(response?.data?.dataList?.result);
        setApprovalPaginationData(response?.data?.dataList?.paginated_data);
        let dataA = [];
        response?.data?.dataList?.result?.map((value) => {
          value.type_name === "JV" &&
            value?.sub_type?.map((item) => {
              item?.approvals?.map((items) => {
                dataA.push(items);
              });
            });
          setMapData(dataA);
        });
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getApproval();
  };

  useEffect(() => {
    getApproval();
  }, []);

  const oneDay = 24 * 60 * 60 * 1000;
  let firstDate = "";
  mapData?.map((items, index) => (firstDate = new Date(items.created_at)));
  const secondDate = new Date();
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            {approvalDataList?.map(
              (items, index) =>
                items.type_name === "JV" &&
                items?.sub_type.map((value) => (
                  <li class="active">
                    <Link to="">{value?.sub_type_name}</Link>
                    <span class="numberpurchse active float-none ml-2">
                      {value?.approvals?.length}
                    </span>
                  </li>
                ))
            )}
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">JV Approval</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Approval Master</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-12">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Trasaction ID</th>
                    <th>Requested By</th>
                    <th>Requested At</th>
                    <th>Pending Age</th>
                  </tr>
                </thead>
                <tbody>
                  {mapData?.map((items, index) => (
                    <tr>
                      <td>
                        <button
                          class="btn btn-blanktd text-success"
                          onClick={() =>
                            handleViewRedirect(items.transaction_id)
                          }
                        >
                          <img src="images/eye.png" alt="" />
                        </button>
                      </td>
                      <td>
                        <span class="badge bad-status badge-success">
                          {items?.transaction_id}
                        </span>
                      </td>
                      <td class="fw-bold text-theme">
                        {items?.created_by_name}
                      </td>
                      <td>{moment(items?.created_at).format("DD-MM-YYYY")}</td>

                      <td>
                        <span class="badge badge-secondary">{diffDays}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) => {
                setJvDataFilter({
                  ...jvDataFilter,
                  start_date: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={jvDataFilter.start_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) => {
                setJvDataFilter({
                  ...jvDataFilter,
                  end_date: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={jvDataFilter.end_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select
              class="form-control newbgselect"
              onChange={(e) => {
                setJvDataFilter({
                  ...jvDataFilter,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={jvDataFilter.status}
            >
              <option value="">Select</option>
              <option value="">All</option>
              <option value="PENDING">Pending</option>
              <option value="APPROVED">Approved</option>
              <option value="REJECTED">Rejected</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            <i className="fa fa-undo"></i> CLEAR
          </button>
          <button
            type="submit"
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default ApprovalData;
