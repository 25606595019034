import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";


export const getSkillLocation = async (pageNo,page_size,sort_by,paginate) => {
    return axios.get(`${AUTH_BASE_URL}/skill-location-master?page_no=${pageNo}&page_size=${page_size}&sort_by=${sort_by}&paginate=${paginate}`);
};
  
  export const saveSkillLocation = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/skill-location-master`, data);
  };
  
  export const updateSkillLocation= async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/skill-location-master/${id}`, data);
};
  
  export const deleteSkillLocation = async (id) => {
    return axios.delete(`${AUTH_BASE_URL}/skill-location-master/${id}`);
  };

  export const getSubPersonal = async () => {
    return axios.get(`${AUTH_BASE_URL}/skill-location-master/personal-sub-area-dropdown`);
  };

    export const isDataExists = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/skill-location-master/exists`, data);
  };
  
// export const getGroup = async (paginate) => {
//     return axios.get(`${AUTH_BASE_URL}/skill-group-master?paginate=${paginate}`);
//   };
// export const getEmployee = async (paginate) => {
//     return axios.get(`${AUTH_BASE_URL}/users?paginate=${paginate}`);
//   };

//   export const getExportValue = async (queryParm) => {
//     return axios({
//       url: `${AUTH_BASE_URL}/skill-group-mapping-master/export?${queryParm}`,
//       method: "GET",
//       responseType: "blob",
//     });
//   };


  

