import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as CreService from "../../service/creServices.js";
import Select from "react-select";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import "react-toastify/dist/ReactToastify.css";
import { setMaxListeners } from "events";
if (typeof window !== "undefined") {
  injectStyle();
}
const options = [
  { value: "WIP", label: "WIP" },
  { value: "HOLD", label: "HOLD" },
  { value: "CLOSED", label: "CLOSED" },
];

const EditCreInstrument = (props) => {
  const { importshow, importclose, editDataCre, onAddCallBack } = props;
  const [editCreData, setEditCreData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buildingValue, setBuildingValue] = useState();
  const [statusError, setStatusError] = useState(false);
  const [creInstrumentData, setCreInstrumentData] = useState({
    status: "",
    remarks: "",
  });
  const [formValidation, setFormValidation] = useState({
    status: "",
    remarks: "",
  });

  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    {
      console.log(formerr, "ok");
    }
    // if (!creInstrumentData.status) {
    //   isError = true;
    //   formerr.status = "Status is Required ";
    //   setFormValidation(formerr);
    // }
    if (!creInstrumentData.remarks) {
      isError = true;
      formerr.remarks = "Remarks is Required ";
      setFormValidation(formerr);
    }
    return isError;
  };

  const getCreData = async () => {
    await CreService.getCreData()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.description}`,
          };
        });
        // setEditCreData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const pushBuildingId = (e) => {
    setBuildingValue(e);
    setCreInstrumentData({
      ...creInstrumentData,
      status: e.value,
    });
  };

  const updateCre = (values) => {
    if (values?.status == "CLOSED") {
    }
    let id = editDataCre.id;
    setIsLoading(true);
    CreService.updateCre(id, values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          setIsLoading(false);
          toast.success("Cre Instrument Update Successfully");
          importclose();
          onAddCallBack();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsLoading(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleValidateForm = (creInstrumentData) => {
    if (handleValidate()) return false;

    if (creInstrumentData?.status == "CLOSED") {
      importclose();
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Request!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          console.log(creInstrumentData);
          Swal.fire("Closed!", "CRE Instrument Closed.", "success");
          updateCre(creInstrumentData);
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
        }
      });
    } else {
      updateCre(creInstrumentData);
    }
  };

  useEffect(() => {
    let opt = options
      .filter((obj) => {
        if (obj?.value === editDataCre?.status) {
          return true;
        }
      })
      .map((obj) => obj);
    setBuildingValue(opt);

    setCreInstrumentData({
      ...creInstrumentData,
      status: opt[0]?.value,
    });
  }, [editDataCre]);

  return (
    <Modal
      show={importshow}
      onHide={importclose}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit New Service Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>Status</label>
                  <Select
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="status"
                    onChange={(e) => {
                      pushBuildingId(e);
                      setFormValidation({
                        ...formValidation,
                        status: "",
                      });
                    }}
                    value={buildingValue}
                  />
                  <div className="small text-danger">
                    {formValidation.status}
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group innergroup">
                  <label>Problem Descripion </label>
                  <textarea
                    type="text"
                    class="form-control"
                    disabled
                    value={editDataCre?.issue_problem}
                  ></textarea>
                </div>
              </div>

              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Status</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[editDataCre]?.map((data, i) => (
                        <tr>
                          <td>{i + 1}.</td>
                          <td>{data.status}</td>
                          <td>{data.remarks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group innergroup">
                  <label>
                    Remarks<span class="text-danger">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    placeholder="Enter Remarks here..."
                    maxLength={200}
                    onChange={(e) => {
                      setCreInstrumentData({
                        ...creInstrumentData,
                        remarks: e.target.value,
                      });
                      setFormValidation({
                        ...formValidation,
                        remarks: "",
                      });
                    }}
                  ></textarea>

                  <div className="small text-danger">
                    {formValidation.remarks}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isLoading ? (
          <Spinner
            animation="border"
            variant="primary"
            as="span"
            role="status"
            aria-hidden="true"
          ></Spinner>
        ) : (
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => {
              handleValidateForm(creInstrumentData);
            }}
          >
            Submit
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditCreInstrument;
