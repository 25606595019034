/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as HeaderService from "../../service/jv_service";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { injectStyle } from "react-toastify/dist/inject-style";
if (typeof window !== "undefined") {
  injectStyle();
}

const Attachments = (props) => {
  let condition = "false";
  const { jvData, setJvData, jvDocDetails, setTabStatus, attachments } = props;
  let navigate = useNavigate();
  const [uploadAttachments, setUploadAttachments] = useState({
    attachments: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [imgSize, setImgSize] = useState(false);
  const [errorAttachment, setErrorAttachment] = useState(false);
  const [deleteattachment, setDeleteAttachment] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [file, setfiles] = useState([]);
  const [flag, setflag] = useState(false);

  useEffect(() => {
    setAttachment(attachments?.attachment_list);
  }, [attachments]);

  const onProfileChange = async (e, flag) => {
    let file = e.target.files;
    if (
      file[0].type === "image/docx" ||
      file[0].type === "image/xls" ||
      file[0].type === "image/xlsx" ||
      file[0].type === "image/XLSX" ||
      file[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file[0].type === "text/csv" ||
      file[0].type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file[0].type ===
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12" ||
      file[0].type === "application/pdf" ||
      file[0].name.includes("msg")
    ) {
      setfiles(file);
      let files = [];
      let filename = [];
      let myAllPic = [];
      for (let i = 0; i < file.length; i++) {
        let data = e.target.files[i];
        let myUrl = URL.createObjectURL(e.target.files[i]);
        myAllPic.push(myUrl);
        const fileData = new FormData();
        fileData.append("uploaded_file", data);
        await HeaderService.uploadImages(fileData).then((response) => {
          let name = { name: data.name };
          filename.push(name);
          files.push(response.data);
        });
      }
      setErrorAttachment(false);
      setAttachment([]);
      setflag(true);
      setUploadDataIm(files);
    } else {
      toast.error("Only xls,xlsx,xlsb,docx and doc files are supported");
    }
  };
  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ||
                data2.name.includes(".csv") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name.includes(".msg") ? (
                <img
                  src={"/images/policy-icon/msg.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      );
    }
    return data;
  };
  const ItemsData = jvDocDetails?.map((items) => ({
    posting_key: items.posting_key,
    gl_code: Number(items.gl_code),
    cost_center:
      Number(items.cost_center) === 0 ? null : Number(items.cost_center),
    profit_center:
      Number(items.profit_center) === 0 ? null : Number(items.profit_center),
    business_area:
      Number(items.business_area) === 0 ? null : Number(items.business_area),
    internal_order: items.internal_order,
    amount: items.amount,
    assignment: items.assignment,
    plant: Number(items.plant) === 0 ? null : Number(items.plant),
    item_text: items.item_text,
    currency: Number(items.currency),
    rate: items.rate,
    tax_code: items.tax_code,
    business_place:
      Number(items.business_place) === 0 ? null : Number(items.business_place),
  }));

  const saveJvDetails = (dataSave) => {
    if (condition == "false") {
      condition = "true";
      setIsLoading(true);
      dataSave.attachments = uploadDataIm;
      HeaderService.saveJvDetails(dataSave)
        .then((response) => {
          condition = "false";
          toast.success(response.data.message);
          setUploadAttachments({ attachments: {} });
          setTimeout(() => {
            navigate("/jv");
          }, 2000);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const editJvDetails = (id, dataSave) => {
    dataSave.deleteAttachment = deleteattachment;
    dataSave.attachments = uploadDataIm;
    setIsLoading(true);
    HeaderService.editJvDetails(id, dataSave)
      .then((response) => {
        condition = "false";
        setUploadAttachments({ attachments: {} });
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/jv");
        }, 2000);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const allJvData = (value) => {
    if (uploadDataIm?.length > 0 || attachments?.attachment_list?.length > 0) {
      let dataSave = {
        doc_no: jvData.doc_no,
        company_code: Number(jvData.company_code),
        document_type: Number(jvData.document_type),
        document_date: jvData.document_date,
        posting_date: jvData.posting_date,
        document_header: jvData.document_header,
        reference: jvData.reference,
        status: value === "draft" ? "DRAFT" : "PENDING",
        items: ItemsData,
        remarks: jvData.remarks,
        attachments: uploadDataIm,
      };
      if (attachments?.id) {
        editJvDetails(attachments?.id, dataSave);
      } else {
        saveJvDetails(dataSave);
      }
    } else {
      setErrorAttachment(true);
    }
  };
  const deleteAttachment = (id) => {
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (attachments == undefined) {
      let obj = {};
      Object.keys(file)
        .filter((del) => {
          console.log(file[del].name);

          if (file[del].name !== id) {
            return true;
          }
        })
        .map((item) => {
          obj[item] = file[item];
        });
      document.getElementById("attachmentsFile").value = "";
      setfiles(obj);
    } else {
      let deleteId = [];
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          if (del.attachment?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            deleteattachment.push(del.id);
          }
        });
        setAttachment(data2);
        document.getElementById("attachmentsFile").value = "";
      } else {
        let obj = {};
        Object.keys(file)
          .filter((del) => {
            if (file[del].name !== id) {
              return true;
            }
          })
          .map((item) => {
            obj[item] = file[item];
          });
        document.getElementById("attachmentsFile").value = "";
        setfiles(obj);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        <div class="col-md-4">
          <div class="form-group innergroup position-relative">
            <label>
              Attachment<span class="text-danger">*</span>
            </label>
            <input
              type="text"
              name="attachments"
              class="form-control bg-white"
              placeholder="(xls, xlsx,xlsb, docx and doc files Format only)"
              disabled
            />
            {errorAttachment ? (
              <div className="small text-danger">*Attachment is required</div>
            ) : null}

            <div class="upload-btn-wrapper up-loposition">
              <button class="uploadBtn">Browse</button>
              <input
                multiple
                type="file"
                title=""
                name="attachments"
                id="attachmentsFile"
                accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                onChange={(e) => onProfileChange(e, "attachments")}
              />
            </div>
            {imgSize ? (
              <div className="small text-danger">
                *File size should not exceed 1mb
              </div>
            ) : null}
            <p>
              Selected files
              {[file].map((a) => (
                <span>
                  <br />
                  {a.name}
                </span>
              ))}
            </p>
            <div>
              {Object.keys(file).length !== 0 ? (
                <div className="d-flex">{attachments123()}</div>
              ) : attachment != undefined && attachment.length != 0 ? (
                <>
                  <div class="d-flex">
                    {attachment?.map((Data) => (
                      <div class="uploaded-image mr-1">
                        <i class="" style={{ width: "20px" }}>
                          <a
                            href={Data?.attachment}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            {Data?.attachment?.includes(".pdf") ? (
                              <img
                                src={"/images/policy-icon/pdf.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment.includes(".xlsx") ? (
                              <img
                                src={"/images/policy-icon/xlsx.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment.includes(".xls") ? (
                              <img
                                src={"/images/policy-icon/xlsx.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment.includes(".doc") ? (
                              <img
                                src={"/images/policy-icon/docs.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment.includes(".docs") ||
                              Data?.attachment.includes(".csv") ? (
                              <img
                                src={"/images/policy-icon/docs.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment.includes(".pptx") ? (
                              <img
                                src={"/images/policy-icon/ppt.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data.attachment.includes(".mp4") ? (
                              <img
                                src={"/images/policy-icon/video.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment.includes(".msg") ? (
                              <img
                                src={"/images/policy-icon/msg.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : (
                              <img
                                src={Data.attachment}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            )}
                          </a>
                        </i>
                        <button
                          type="button"
                          onClick={(e) => {
                            deleteAttachment(Data?.attachment?.split("/")[4]);
                          }}
                          className="btn btn-blanktd text-danger ml-2"
                        >
                          <i className="far fa-trash-alt text-danger"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group innergroup">
            <label>Remarks</label>
            <textarea
              type="text"
              class="form-control"
              placeholder="Enter Remarks"
              name="remarks"
              maxLength={500}
              onChange={(e) =>
                setJvData({ ...jvData, remarks: e.target.value })
              }
              value={jvData.remarks}
            ></textarea>
          </div>
        </div>

        <div class="col-md-12 mt-2">
          <button
            class="btn btn-outline-secondary mr-3"
            onClick={() => setTabStatus("Personal")}
          >
            <i class="far fa-arrow-alt-circle-left"></i> Previous
          </button>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <button
                type="Button"
                class="btn btn-outline-primary mr-3"
                onClick={() => allJvData("draft")}
              >
                <i class="far fa-save"></i> Save as Draft
              </button>
              <button
                type="submit"
                class="btn btn-primary-inner bpi-main"
                onClick={() => allJvData("submit")}
              >
                <i class="far fa-arrow-alt-circle-right"></i> Submit
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Attachments;
