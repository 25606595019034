import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModularTable from "../ModularTable";
import {
  getCropProductStrategy,
  getAllStrategy,
  getCropPlanData,
  getLastProductStrategy,
  getProductStrategyApprovalLogs,
  getProductStrategyCropDropdown,
  postProductStrategy,
  postProductStrategyAction,
  putProductStrategy,
} from "../../../../service/AgriPortalService/CropProductStrategyService";
import { getCurrentFy } from "../../../../service/AgriPortalService/CropScenarioService";
import {
  getLastMarketSize,
  getMarketSizeApprovalLogs,
  postMarketSize,
  putMarketSize,
} from "../../../../service/AgriPortalService/MarketSizeService";
import {
  getLastSalesPlan,
  getSalesPlanApprovalLogs,
  postSalesPlan,
  putSalesPlan,
} from "../../../../service/AgriPortalService/SalesPlanService";
import { getOverallMonthDropdown } from "../../../../service/AgriPortalService/OverallStrategyService";
import styles from "./Table.module.css";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import moment from "moment/moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";

const Root = (props) => {
  const [currentfy, setCurrentfy] = useState({});

  const [cropDropdown, setCropDropdown] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState({});
  const [selectedSeason, setSelectedSeason] = useState({});
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [tableData, setTableData] = useState({});
  const [tableData2, setTableData2] = useState({});
  const [locationData, setLocationData] = useState({});
  const [dummyRow, setDummyRow] = useState([]);
  const [existingData, setExistingData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [freezeCTA, setFreezeCTA] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mode, setMode] = useState("");
  const [cfy, setCfy] = useState("");

  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getCropProductStrategy()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mode]);

  useEffect(() => {
    if (!mode) return;
    let query = "/myplan/salesplan";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  useEffect(() => {}, [tableData, tableData2]);
  const [approvalList, setApprovalList] = useState([]);

  useEffect(() => {
    console.log("td", tableData);
  }, [tableData]);

  useEffect(() => {
    if (Object.keys(locationData).length != 0) {
      console.log("LOCATION", locationData);
      getProductStrategyCropDropdown(locationData?.territory_code)
        .then((res) => {
          setLoader(false);
          if (!res.data.dataList || res.data.dataList.length == 0) return;
          setCropDropdown(res.data.dataList);
          setSelectedCrop(res.data.dataList[0]);
        })
        .catch((err) => {
          setLoader(false);
          toast.error(
            "Crop Plan should be Submitted before Crop Product Strategy"
          );
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        });
    }

    const params = new URLSearchParams(window.location.search);
    setPageId(params.get("id"));
    setMode(params.get("mode"));
    setCfy(params.get("fy"));
    let query = "";
    if (params.get("mode") != "create") {
      query = "?product_strategy_id=" + params.get("id");
    }

    if (params.get("mode") != "create") {
      getProductStrategyApprovalLogs(query)
        .then((res) => setApprovalList(res.data.dataList))
        .catch((err) => {
          console.log(err.response.data.detail);
          navigate("mystrategy/cropproductstrategy");
        });
    }
  }, [mode, locationData]);

  useEffect(() => {
    if (!cropDropdown || cropDropdown.length == 0) return;

    setSelectedCrop(cropDropdown[0]);
    setSelectedSeason(cropDropdown[0]?.season_data[0].season);
  }, [cropDropdown]);

  useEffect(() => {
    if (!selectedCrop || !selectedCrop?.season_data) return;
    console.log(selectedCrop);
    setSelectedSeason(selectedCrop?.season_data[0]?.season);
  }, [selectedCrop]);
  useEffect(() => {
    // changeCropSeasonHandler().then(res=>console.log(res)).catch(err=>console.log(err));
  }, [selectedCrop, selectedSeason]);

  async function loadAllData() {
    for (let i = 0; i < cropDropdown?.length; i++) {
      for (let j = 0; j < cropDropdown[i]?.season_data?.length; j++) {
        // if(cropDropdown[i]?.crop_id==1&&cropDropdown[i]?.season_data[j]?.season=="K") continue;
        if (
          !cropDropdown[i]?.crop_id ||
          !cropDropdown[i]?.season_data[j]?.season
        )
          continue;
        await changeCropSeasonHandler(
          cropDropdown[i]?.crop_id,
          cropDropdown[i]?.season_data[j]?.season
        );
      }
    }
    return true;
  }

  useEffect(() => {
    if (Object.keys(locationData).length == 0) return;
    if (!cropDropdown || cropDropdown?.length == 0) return;
    loadAllData()
      .then((res) => {
        prefillInEditCase()
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [cropDropdown, locationData]);

  // const setEditData = ()

  const convertCategoryData = (data) => {
    console.log("TABLLEEEE", data);
    const ans = [];

    if (!data) return;

    for (let i = 0; i < Object.keys(data).length; i++) {
      for (
        let j = 0;
        j < Object.keys(data[Object.keys(data)[i]])?.length;
        j++
      ) {
        if (
          !data[Object.keys(data)[i]] ||
          !data[Object.keys(data)[i]][
            Object.keys(data[Object.keys(data)[i]])[j]
          ]
        )
          continue;

        let strategy_data = data[Object.keys(data)[i]][
          Object.keys(data[Object.keys(data)[i]])[j]
        ]?.map((row) => {
          let strategy_id = row[0]?.meta?.raw?.find(
            (el) => el?.name == row[0]?.selected
          )?.id;
          let month_id = row[2]?.meta?.raw?.find(
            (el) => el?.name == row[2]?.selected
          )?.id;
          return {
            id: 0,
            strategy_id,
            action_plan: row[1].value[0],
            month_id,
          };
        });
        ans.push({
          id: 0,
          crop_id: parseInt(Object.keys(data)[i]),
          crop_season: Object.keys(data[Object.keys(data)[i]])[j],
          strategy_data,
        });
      }
    }

    // data.forEach((row)=>{

    //     if(row[0].value!="total") {
    //     ans.push({
    //         category_id:row[0].meta.id,
    //         fy_m_2:tableHeadData[1].name,
    //         fy_m_1:tableHeadData[2].name,
    //         fy:tableHeadData[3].name,
    //         fy_p_1:tableHeadData[4].name,
    //         fy_p_2:tableHeadData[5].name,
    //         fy_m_2_value:row[1].value[0],
    //         fy_m_1_value:row[2].value[0],
    //         fy_value:row[3].value[0],
    //         fy_p_1_value:row[4].value[0],
    //         fy_p_2_value:row[5].value[0],
    //     });}
    // });

    return ans;
  };
  async function prefillInEditCase() {
    const params = new URLSearchParams(window.location.search);

    if (params.get("mode") != "create")
      try {
        let strategy = await getAllStrategy();

        if (!strategy || !strategy?.data?.dataList) {
          return toast.error("Failed to fetch strategies!");
        }

        let months = await getOverallMonthDropdown();

        if (!months || !months?.data?.dataList) {
          return toast.error("Failed to fetch strategies!");
        }

        let dummyrow = [
          {
            value: strategy?.data?.dataList?.map((el) => el.name),
            meta: { raw: strategy?.data?.dataList },
            selected: strategy?.data?.dataList[0]?.name,
          },
          {
            value: [""],
            meta: {},
          },
          {
            value: months?.data?.dataList?.map((el) => el.name),
            meta: { raw: months?.data?.dataList },
            selected: months?.data?.dataList[0]?.name,
          },
          {},
        ];

        setDummyRow([
          {
            value: strategy?.data?.dataList?.map((el) => el.name),
            meta: { raw: strategy?.data?.dataList },
            selected: strategy?.data?.dataList[0]?.name,
          },
          {
            value: [""],
            meta: {},
          },
          {
            value: months?.data?.dataList?.map((el) => el.name),
            meta: { raw: months?.data?.dataList },
            selected: months?.data?.dataList[0]?.name,
          },
          {},
        ]);
        const params = new URLSearchParams(window.location.search);
        let query = "?product_strategy_id=" + params.get("id");
        let oldData = await getLastProductStrategy(query);
        setExistingData(oldData.data.dataList.crop_data);

        if (!oldData || !oldData?.data?.dataList) return;

        for (let i = 0; i < cropDropdown?.length; i++) {
          for (let j = 0; j < cropDropdown[i]?.season_data?.length; j++) {
            if (
              cropDropdown[i]?.crop_id == 1 &&
              cropDropdown[i]?.season_data[j]?.season == "K"
            )
              continue;
            if (
              !cropDropdown[i]?.crop_id ||
              !cropDropdown[i]?.season_data[j]?.season
            )
              continue;

            let td2 = [];

            let crop = oldData?.data?.dataList?.crop_data?.find((el) => {
              return (
                el?.crop_id == cropDropdown[i]?.crop_id &&
                el?.crop_season == cropDropdown[i]?.season_data[j]?.season
              );
            });

            if (!crop || !crop?.strategy_data) continue;

            td2 = crop?.strategy_data?.map((el) => {
              let row = JSON.parse(JSON.stringify(dummyrow));

              row[0].selected = el?.strategy_name;
              row[1].value[0] = el?.action_plan;
              row[2].selected = el?.month_name;

              return row;
            });

            setTableData2((state) => {
              let newState = { ...state };

              if (!newState[cropDropdown[i]?.crop_id])
                newState[cropDropdown[i]?.crop_id] = {};

              newState[cropDropdown[i]?.crop_id][
                cropDropdown[i]?.season_data[j]?.season
              ] = td2;

              return newState;
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
  }

  async function changeCropSeasonHandler(cropid, season) {
    let lastState, dummyrow;
    try {
      if (!tableData[cropid] || !tableData[cropid][season]) {
        let data = [[]];

        let query =
          "?crop_id=" +
          cropid +
          "&season=" +
          season +
          "&territory_code=" +
          locationData?.territory_code;

        let cropData = await getCropPlanData(query);

        let finalData = cropData?.data?.dataList?.stage_data?.map((stage) => {
          return [
            {
              value: [stage?.stage_name],
              meta: {
                raw: stage,
              },
            },
            {
              value: [stage?.company_product_1_name],
              meta: {},
            },
            {
              value: [stage?.company_product_1_potentiality],
              meta: {},
            },
            {
              value: [stage?.company_product_1_qty],
              meta: {},
            },
            {
              value: [stage?.company_product_1_demo],
              meta: {},
            },
            {
              value: [stage?.company_product_2_name],
              meta: {},
            },
            {
              value: [stage?.company_product_2_potentiality],
              meta: {},
            },
            {
              value: [stage?.company_product_2_qty],
              meta: {},
            },
            {
              value: [stage?.company_product_2_demo],
              meta: {},
            },
            {
              value: [stage?.molecule_1_name],
              meta: {},
            },
            {
              value: [stage?.molecule_1_qty],
              meta: {},
            },
            {
              value: [stage?.molecule_2_name],
              meta: {},
            },
            {
              value: [stage?.molecule_2_qty],
              meta: {},
            },

            {
              value: [stage?.new_molecule_qty],
              meta: {},
            },
            {
              value: [stage?.new_molecule_name],
              meta: {},
            },
            {
              value: [stage?.new_molecule_qty],
              meta: {},
            },
          ];
        });

        setTableData((state) => {
          let newState = { ...state };

          if (!newState[cropid]) newState[cropid] = {};

          newState[cropid][season] = finalData;

          return newState;
        });

        ////////

        let strategy = await getAllStrategy();

        if (!strategy || !strategy?.data?.dataList) {
          return toast.error("Failed to fetch strategies!");
        }

        let months = await getOverallMonthDropdown();

        if (!months || !months?.data?.dataList) {
          return toast.error("Failed to fetch strategies!");
        }

        dummyrow = [
          {
            value: strategy?.data?.dataList?.map((el) => el.name),
            meta: { raw: strategy?.data?.dataList },
            selected: strategy?.data?.dataList[0]?.name,
          },
          {
            value: [""],
            meta: {},
          },
          {
            value: months?.data?.dataList?.map((el) => el.name),
            meta: { raw: months?.data?.dasetDummyRowtaList },
            selected: months?.data?.dataList[0]?.name,
          },
          {},
        ];

        setDummyRow([
          {
            value: strategy?.data?.dataList?.map((el) => el.name),
            meta: { raw: strategy?.data?.dataList },
            selected: strategy?.data?.dataList[0]?.name,
          },
          {
            value: [""],
            meta: {},
          },
          {
            value: months?.data?.dataList?.map((el) => el.name),
            meta: { raw: months?.data?.dataList },
            selected: months?.data?.dataList[0]?.name,
          },
          {},
        ]);
        let td2 = [
          [
            {
              value: strategy?.data?.dataList?.map((el) => el.name),
              meta: { raw: strategy?.data?.dataList },
              selected: strategy?.data?.dataList[0]?.name,
            },
            {
              value: [""],
              meta: {},
            },
            {
              value: months?.data?.dataList?.map((el) => el.name),
              meta: { raw: months?.data?.dataList },
              selected: months?.data?.dataList[0]?.name,
            },
            {},
          ],
          [
            {
              value: strategy?.data?.dataList?.map((el) => el.name),
              meta: { raw: strategy?.data?.dataList },
              selected: strategy?.data?.dataList[0]?.name,
            },
            {
              value: [""],
              meta: {},
            },
            {
              value: months?.data?.dataList?.map((el) => el.name),
              meta: { raw: months?.data?.dataList },
              selected: months?.data?.dataList[0]?.name,
            },
            {},
          ],
        ];

        setTableData2((state) => {
          let newState = { ...state };

          if (!newState[cropid]) newState[cropid] = {};

          newState[cropid][season] = td2;

          lastState = { ...newState };

          return newState;
        });
      }
    } catch (err) {
      toast.error("Crop Plan should be Submitted before Crop Product Strategy");
      navigate("/myplan/cropplan");
      console.log(err);
    }
  }

  const onTableDataChange = (data) => {
    if (!data) return;
    setTableData2((state) => {
      let newState = { ...state };

      if (!newState[selectedCrop?.crop_id])
        newState[selectedCrop?.crop_id] = {};

      // console.log("td",td2);
      newState[selectedCrop?.crop_id][selectedSeason] = data;

      return newState;
    });
  };

  //CHANGE SELECTED SEASON IN STATE WHEN USER CHANGES IT ON UI
  const changeSelectedSeason = (season) => {
    let index = selectedCrop.season_data.findIndex((el) => el.season == season);
    console.log(selectedCrop.season_data[index]);
    setSelectedSeason(selectedCrop.season_data[index].season);
  };

  let previousYearColumns;

  previousYearColumns = [
    {
      name: "Stages",
      type: "T",
      isEditable: false,
    },
    {
      name: "PI Product",
      type: "T",
      isEditable: false,
    },
    {
      name: "Potentiality",
      type: "N",
      isEditable: false,
    },
    {
      name: "Qty",
      type: "N",
      isEditable: false,
    },
    {
      name: "Demo",
      type: "N",
      isEditable: false,
    },
    {
      name: "PI Product",
      type: "T",
      isEditable: false,
    },
    {
      name: "Potentiality",
      type: "N",
      isEditable: false,
    },
    {
      name: "Qty",
      type: "N",
      isEditable: false,
    },
    {
      name: "Demo",
      type: "N",
      isEditable: false,
    },
    {
      name: "Product",
      type: "T",
      isEditable: false,
    },
    {
      name: "Qty",
      type: "N",
      isEditable: false,
    },
    {
      name: "Product",
      type: "T",
      isEditable: false,
    },
    {
      name: "Qty",
      type: "N",
      isEditable: false,
    },
    ,
    {
      name: "New Product",
      type: "T",
      isEditable: false,
    },
    {
      name: "Qty",
      type: "N",
      isEditable: false,
    },
  ];

  const previousYearData = [
    [
      {
        value: ["First Flash"],
        meta: {},
      },
      {
        value: ["CLUTCH"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["BIOVITA"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 1"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 2"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 3"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
    ],
    [
      {
        value: ["First Flash"],
        meta: {},
      },
      {
        value: ["CLUTCH"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["BIOVITA"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 1"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 2"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 3"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
    ],
    [
      {
        value: ["First Flash"],
        meta: {},
      },
      {
        value: ["CLUTCH"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["BIOVITA"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 1"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 2"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 3"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
    ],
    [
      {
        value: ["First Flash"],
        meta: {},
      },
      {
        value: ["CLUTCH"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["BIOVITA"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 1"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 2"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: ["MOLECULE 3"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
    ],
  ];

  const planningYearColumns = [
    {
      name: "Product Pack",
      type: "T",
      isEditable: false,
    },
    {
      name: "Qty Planned",
      type: "N",
      isEditable: false,
    },
    {
      name: "Apr",
      type: "N",
      isEditable: true,
    },
    {
      name: "May",
      type: "N",
      isEditable: true,
    },
    {
      name: "Jun",
      type: "N",
      isEditable: true,
    },
    {
      name: "Jul",
      type: "N",
      isEditable: true,
    },
    {
      name: "Aug",
      type: "N",
      isEditable: true,
    },
    {
      name: "Sep",
      type: "N",
      isEditable: true,
    },
    {
      name: "Oct",
      type: "N",
      isEditable: true,
    },
    {
      name: "Nov",
      type: "N",
      isEditable: true,
    },
    {
      name: "Dec",
      type: "N",
      isEditable: true,
    },
    {
      name: "Jan",
      type: "N",
      isEditable: true,
    },
    {
      name: "Feb",
      type: "N",
      isEditable: true,
    },
    {
      name: "Mar",
      type: "N",
      isEditable: true,
    },
  ];

  let customerPlanColumns;
  if (mode != "view" && mode != "approve")
    customerPlanColumns = [
      {
        name: "Strategy",
        type: "D",
        isEditable: true,
      },
      {
        name: "Action Plan",
        type: "T",
        isEditable: true,
      },
      {
        name: "MONTH",
        type: "D",
        isEditable: true,
      },
      {
        type: "CB",
      },
    ];
  else
    customerPlanColumns = [
      {
        name: "Strategy",
        type: "D",
        isEditable: false,
      },
      {
        name: "Action Plan",
        type: "T",
        isEditable: false,
      },
      {
        name: "MONTH",
        type: "D",
        isEditable: false,
      },
    ];

  const customerPlanData = [
    [
      {
        value: ["COMMERCIAL", "NON-COMMERCIAL"],
        meta: {},
        selected: "COMMERCIAL",
      },
      {
        value: ["This is my action plan bla bla"],
        meta: {},
      },
      {
        value: [
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
          "JAN",
          "FEB",
          "MAR",
        ],
        meta: {},
      },
    ],
    [
      {
        value: ["COMMERCIAL", "NON-COMMERCIAL"],
        meta: {},
        selected: "COMMERCIAL",
      },
      {
        value: ["This is my action plan bla bla"],
        meta: {},
      },
      {
        value: [
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
          "JAN",
          "FEB",
          "MAR",
        ],
        meta: {},
      },
    ],
    [
      {
        value: ["COMMERCIAL", "NON-COMMERCIAL"],
        meta: {},
        selected: "COMMERCIAL",
      },
      {
        value: ["This is my action plan bla bla"],
        meta: {},
      },
      {
        value: [
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
          "JAN",
          "FEB",
          "MAR",
        ],
        meta: {},
      },
    ],
  ];

  const confirmDialog = (type) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };
  useEffect(() => {
    getCurrentFy()
      .then((res) => {
        setCurrentfy(res.data.dataList);
        // console.log(res,"Result")
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const onSubmitHandler = async (e) => {
    if (freezeCTA) return;

    setFreezeCTA(true);

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "P",
    };
    const crop_data = convertCategoryData(tableData2);

    if (!crop_data) return;
    body.crop_data = crop_data;

    if (mode == "create") {
      body.id = 0;
      let cfy = await getCurrentFy();
      if (!cfy.data.dataList) {
        return toast.error("Cannot save as cfy could not be fetched!");
      }

      cfy = cfy.data.dataList.fy;
      body.cfy = cfy;
      postProductStrategy(body)
        .then((res) => {
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/mystrategy/cropproductstrategy");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Submit Failed!");
          setTimeout(() => {
            navigate("/mystrategy/cropproductstrategy");
          }, 1000);
        });
    }

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      body.cfy = params.get("fy");

      putProductStrategy(body)
        .then((res) => {
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/mystrategy/cropproductstrategy");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Submit Failed!");
          setTimeout(() => {
            navigate("/mystrategy/cropproductstrategy");
          }, 1000);
        });
    }
  };

  const onSaveHandler = async (e) => {
    if (freezeCTA) return;

    setFreezeCTA(true);

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "D",
    };
    const crop_data = convertCategoryData(tableData2);

    if (!crop_data) return;
    body.crop_data = crop_data;

    if (mode == "create") {
      body.id = 0;
      let cfy = await getCurrentFy();
      if (!cfy.data.dataList) {
        return toast.error("Cannot save as cfy could not be fetched!");
      }

      cfy = cfy.data.dataList.fy;
      body.cfy = cfy;
      postProductStrategy(body)
        .then((res) => {
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/mystrategy/cropproductstrategy");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/mystrategy/cropproductstrategy");
          }, 1000);
        });
    }

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      body.cfy = params.get("fy");
      putProductStrategy(body)
        .then((res) => {
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/mystrategy/cropproductstrategy");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/mystrategy/cropproductstrategy");
          }, 1000);
        });
    }
  };

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      product_strategy_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postProductStrategyAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };

  const onCancelHandler = () => {
    return navigate("/mystrategy/cropproductstrategy");
  };

  (function () {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
    }
  })();

  //JSX MARKUP
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Crop Product Strategy
          </h4>
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{cfy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              {/*backend error check if data is being populated or not*/}
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>REGION</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.region_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>TERRITORY</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.territory_name}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["agri-portal-controls"]}>
          <div className={styles["left"]}>
            {/* <div>
                        <div style={{zIndex:1010,position:"relative"}}>
                        <Dropdown show>
                            <Dropdown.Toggle
                                variant="primary-inner dropdownbtn"
                                id="dropdown-basic"
                            >
                                {selectedCrop?selectedCrop.crop_name:"Crop"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    cropDropdown && cropDropdown.map(el=>{
                                        return <Dropdown.Item href="#" onClick={e=>setSelectedCrop(el)}>
                                           {el.crop_name}
                                        </Dropdown.Item>
                                    })  
                                }
                            </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div> */}
            <div>
              <select
                className="form-control newbgselect"
                name="business_unit_id"
                style={{
                  cursor: "pointer",
                  border: "1px solid #0195D4",
                  color: "#0195DA",
                  minWidth: "120px",
                }}
                // classNamePrefix="select"
                value={selectedCrop.crop_name}
                onChange={(e) => {
                  setSelectedCrop(
                    cropDropdown.find((el) => el?.crop_name == e.target.value)
                  );
                }}
              >
                {cropDropdown &&
                  cropDropdown.map((el) => {
                    return <option value={el.crop_name} label={el.crop_name} />;
                  })}
              </select>
            </div>
            {/* <div>
                        <div style={{zIndex:1010,position:"relative"}}>
                            <Dropdown show>
                            <Dropdown.Toggle
                                variant="primary-inner dropdownbtn"
                                id="dropdown-basic"
                            >
                                {selectedSeason=="R"?"Rabi":""}
                                {selectedSeason=="K"?"Kharif":""}
                                {selectedSeason=="N"?"None":""}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    selectedCrop?.season_data && selectedCrop.season_data?.map((el,idx)=>{
                                        return <Dropdown.Item href="#" onClick={()=>changeSelectedSeason(idx)}>
                                        {el.season=="R"?"Rabi":""}
                                        {el.season=="K"?"Kharif":""}
                                        {el.season=="N"?"None":""}
                                    </Dropdown.Item>
                                    })
                                }
                                
                            </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div> */}
            <div>
              <select
                className="form-control newbgselect"
                name="business_unit_id"
                style={{
                  cursor: "pointer",
                  border: "1px solid #0195D4",
                  color: "#0195DA",
                  minWidth: "120px",
                }}
                // classNamePrefix="select"
                value={selectedSeason}
                onChange={(e) => {
                  console.log(e.target.value);
                  changeSelectedSeason(e.target.value);
                }}
              >
                {selectedCrop?.season_data &&
                  selectedCrop?.season_data.map((el, idx) => {
                    let s;
                    if (el?.season == "R") s = "Rabi";
                    if (el?.season == "K") s = "Kharif";
                    if (el?.season == "N") s = "None";
                    return <option value={el.season} label={s} />;
                  })}
              </select>
            </div>
          </div>

          <div className={styles["right"]}>
            <div>
              <div style={{ zIndex: 1010, position: "relative" }}>
                <div className={styles["agri-portal-label"]}>
                  <b>Accrage</b>
                </div>
                <div className={styles["valuebox"]}>0</div>
              </div>
            </div>
          </div>
        </div>
        {existingData.length > 0 &&
          (mode == "edit" || mode == "view" || mode == "approve") && (
            <div className={styles["table_contain"]}>
              <div style={{ zIndex: 1010, position: "relative" }}>
                <div className={styles["agri-portal-label"]}>
                  <b>Data Entered For</b>
                </div>
              </div>
              <div className={styles["agri-portal-table"]}>
                <div
                  class="table-responsive"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <table
                    class="table table-striped table-bordered tablecured"
                    style={{ maxHeight: "200px" }}
                  >
                    <thead style={{ position: "sticky", top: 0 }}>
                      <tr style={{ textAlign: "center" }}>
                        <th>Crop Name</th>
                        <th>Crop Season</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {existingData.map((ele) => {
                        let season = "";
                        switch (ele.crop_season) {
                          case "K":
                            season = "Kharif";
                            break;
                          case "R":
                            season = "Rabi";
                            break;
                          case "N":
                            season = "None";
                        }
                        console.log("SEASONNNNN_>", ele);
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{ele?.crop_name}</td>
                            <td>{season}</td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                console.log("SEASONNNNN_>", ele);
                                setSelectedCrop(
                                  cropDropdown?.find(
                                    (el) => el?.crop_name == ele?.crop_name
                                  )
                                );
                                if (
                                  selectedCrop ==
                                    cropDropdown?.find(
                                      (el) => el?.crop_name == ele?.crop_name
                                    ) &&
                                  selectedSeason != ele?.season
                                ) {
                                  changeSelectedSeason(ele?.season);
                                }
                                console.log(
                                  "SE+>",
                                  selectedCrop,
                                  selectedSeason
                                );
                              }}
                            >
                              {mode == "view" ? "View" : "Edit"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              columns={previousYearColumns}
              data={
                tableData
                  ? tableData[selectedCrop?.crop_id]
                    ? tableData[selectedCrop?.crop_id][selectedSeason]
                    : undefined
                  : undefined
              }
            />
          </div>
        </div>

        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              dummyRow={dummyRow}
              onTableDataChange={onTableDataChange}
              columns={customerPlanColumns}
              data={
                tableData2
                  ? tableData2[selectedCrop?.crop_id]
                    ? tableData2[selectedCrop?.crop_id][selectedSeason]
                    : undefined
                  : undefined
              }
            />
          </div>
        </div>

        {/* <div className={styles["instructions"]}>
                <p><b>INSTRUCTIONS</b></p>
                <p>You would find a list of product when you click this form</p>
                <p>These are the products for which you have planned sales for current F year in the previous form</p>
                <p>Select a product and complete approvalListmonthwise/packwise/customerwise detailing</p>
                <p>Previous year actual sales monthwise/productwise breakup is given in 1st table just for</p>
            </div> */}
        {approvalList?.approvals && approvalList?.approvals.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>Action At</th>
                      <th>Action By</th>
                      <th>Action User</th>
                      <th>Action Status</th>
                      <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList?.approvals &&
                      approvalList?.approvals.map((el, i) => {
                        let status;

                        const findPendingIndex =
                          approvalList.approvals.findIndex(
                            (ele) => ele.status == "P" && ele.discarded == false
                          );

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P" && el.discarded == true)
                          status = "Auto Discarded";
                        else if (el.status == "P") status = "Pending";
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr>
                            <td>
                              {status == "Pending"
                                ? "-"
                                : moment(el.action_at)
                                    .utc()
                                    .format("DD-MM-YYYY HH:mm:ss")}
                            </td>
                            <td>{el.action_by_id}</td>
                            <td>{el.action_user_name}</td>
                            <td>
                              {findPendingIndex == -1
                                ? status
                                : i > findPendingIndex
                                ? "-"
                                : status}
                            </td>
                            <td>{el.remarks}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  confirmDialog("P");
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  confirmDialog("D");
                }}
              >
                Save
              </div>
            </div>
          )}
          {mode == "approve" || approvalList?.for_approval ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Root;

// const columnSettings = [
//     {
//         name:"Category",
//         type:"T",
//         isEditable:false,
//     },
//     {
//         name:"2021-22",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2022-23",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2023-24",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2024-25",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2025-26",
//         type:"N",
//         isEditable:true,
//     }
// ];

// const data = [
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
// ]
