import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getSurveyCategory = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/survey-category?${queryParm}`);
  };

  export const getAllOrganisation = async (paginate) => {
    return axios.get(`${AUTH_BASE_URL}/organisations?paginate=${paginate}`);
  };

export const saveSurveyCategory = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/survey-category`, data);
};

export const updateSurveyCategory = async (data,id) => {
    return axios.put(`${AUTH_BASE_URL}/survey-category/${id}`, data);
  };

export const deleteSurveyCategory = async (id) => {
return axios({
    url: `${AUTH_BASE_URL}/survey-category/${id}`,
    method: "DELETE",
    data: id,
});
};

export const isSurveyCategoryExists = async (isData) => {
    return axios.post(`${AUTH_BASE_URL}/survey-category/exists`, isData);
  };

export const getExportValue = async (queryParm) => {
return axios({
    url: `${AUTH_BASE_URL}/survey-category/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
});
};
