import React from "react";

const SuggestionsList = (props) => {
  const {
    suggestions,
    inputValue,
    onSelectSuggestion,
    displaySuggestions,
    selectedSuggestion,
    inputValueNext,
    flag,
    displaySuggestionsNext,
  } = props;

  if (
    (inputValue && displaySuggestions) ||
    (inputValueNext && displaySuggestionsNext)
  ) {
    if (suggestions.length > 0) {
      return (
        <ul className="suggestions-list" id="scrollpart">
          {suggestions.map((suggestion, index) => {
            // console.log("suggestion", suggestion);
            const isSelected = selectedSuggestion === index;
            // console.log("isSleected", isSelected);
            const classname = `suggestion ${isSelected ? "selected" : ""}`;
            return (
              <li
                key={index}
                className={classname}
                onClick={() => onSelectSuggestion(suggestion, flag)}
              >
                {suggestion.label}
              </li>
            );
          })}
        </ul>
      );
    }
  }
  return <></>;
};

export default SuggestionsList;
