import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";


export const getTerritory = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/territory/drop-down`);
}
export const getFieldWork = async () => {
    return axios.get(`${AUTH_BASE_URL}/field-work-plan`);
};
export const getAllFields = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/field-work-plan/all-work-plans`);
}
export const getTotalDemos = async (query="")=>{
    return axios.get(`${AUTH_BASE_URL}/field-work-plan/month-wise-total${query}`)
}

export const postFieldWork = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/field-work-plan`,body);
}

export const putFieldWork = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/field-work-plan`,body);
}
export const getLastFieldPlan = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/field-work-plan/last-field-work-plan${query}`)
}
export const getFieldPlanApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/field-work-plan/approver-field-work-plan-logs${query}`);
}

export const postFieldWorkAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/field-work-plan/action-field-work-plan`,body);
}
