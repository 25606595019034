import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import GlcostCenterFrom from "./gl_costcenter_user_mappingForm";
import { deleteses, getAllSes } from "../../service/sesService";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { getPlantList, getUserRmList,getGlCodeList } from "../../service/jv_service";
import { getPGroupDrop, getPoOrgDrop } from "../../service/source-list";
import { getAllStrat } from "../../service/approver";
import { getGlApprover, getGlcost } from "../../service/glService";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import { getCostCenter } from "../../service/userService";

function GlApprover() {
  const userData = useSelector(selectUserData);
  const navigate = useNavigate();
  const [GlData, setGlData] = useState([]);
  const [filterdata, setfilterData] = useState({
    costcenter: { value: 0 },
    user_id: { value: 0 },
    glcode: { value: 0 },
   
  });
  const resetFilterData = () => setfilterData({
    costcenter: { value: 0 },
    user_id: { value: 0 },
    glcode: { value: 0 },
   
  });
  const [editData, seteditData] = useState([]);
  const resetEditState = () => seteditData([]);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [userlist, setuserlist] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [search, setSearch] = useState("");
  const [glcode, setGlcode] = useState([]);
  const [costcenter, setCostcenter] = useState([]);
  const getGlcostcenter = (
   pageNo,pageSize,sortBy,paginate,search,status,glcode,costcenter,user_id
  ) => {
    getGlcost(
       pageNo,pageSize,sortBy,paginate,search,status,glcode,costcenter,user_id
    )
      .then((response) => {
        setGlData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.username };
          plant.push(data);
        });
        setuserlist(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const deleteApprover = (id) => {
    deleteses(id)
      .then((response) => {
        getGlcostcenter(currentPage, pageSizeNo, "-id", true, "", true,filterdata?.glcode.value,filterdata?.approver.value,filterdata?.requester.value);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleDelete = (id) => {
    confirmAlert({
      title: "DeleteA Apply Leave",
      message: `Are you sure to delete ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteApprover(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  useEffect(() => {
    getGlcostcenter(currentPage, pageSizeNo, "-id", true, "", true, 0,0,0);
    getGlcodeList()
    getcostCenter()
    getUserList();
  }, []);
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getGlcostcenter(activePage, pageSizeNo, "-id", true, "", true, 0,0,0);
  };
  const getGlcodeList = () => {
    getGlCodeList()
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let glcode = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.gl_account };
          glcode.push(data);
        });
        setGlcode(glcode);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getcostCenter = () => {
    let branch=userData?.branch_id
    console.log(branch);
    getCostCenter(branch)
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let costcenter = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.code };
          costcenter.push(data);
        });
        setCostcenter(costcenter);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "user_id":
        setfilterData((prev) => ({
          ...prev,
          user_id: event,
        }));
        break;
      case "costcenter":
        setfilterData((prev) => ({
          ...prev,
          costcenter: event,
        }));
        break;
      case "glcode":
        setfilterData((prev) => ({
          ...prev,
          glcode: event,
        }));
        break;
    }
  };
  const resetFilter=() => {
    filterclose();
    resetFilterData()
    getGlcostcenter(currentPage, pageSizeNo, "-id", true, "", true, 0,0,0);
  }
  const mainDataFilter = () => {
    getGlcostcenter(currentPage, pageSizeNo, "-id", true, "", true,filterdata?.glcode.value,filterdata?.costcenter.value,filterdata?.user_id.value);
    filterclose();
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
    getGlcostcenter(currentPage, pageSizeNo, "-id", true,  e.target.value.toLowerCase(), true, filterdata?.glcode.value,filterdata?.costcenter.value,filterdata?.user_id.value);
    setSearch(e.target.value);
    }
  };
  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/GlMapping">Mapped GL</Link>
            </li>
            <li className="active">
              <Link to="/GlCostMapping">Gl costcenter user mapping</Link>
            </li>
            
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Map Cost Center</h4>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/dashboard">Gl</Breadcrumb.Item>
                    <Breadcrumb.Item active>Map Cost Center</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button class="btn btn-primary-inner" onClick={addnewopen}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Map Cost Center
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>User</th>
                    <th>User username</th>
                    <th>Cost Center</th>
                    <th>Gl Account</th>
                  </tr>
                </thead>
                <tbody>
                  {GlData?.map((x) => (
                    <tr>
                      <td>
                        <button
                          type="submit"
                          onClick={() => {
                            seteditData(x);
                            addnewopen();
                          }}
                          class="btn btn-blanktd text-primary ml-2 f-12"
                        >
                          <i class="far fa-edit"></i>
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDelete(x?.id)}
                          class="btn btn-blanktd text-danger ml-2 f-12"
                        >
                          <i class="far fa-trash-alt text-danger"></i>
                        </button>
                      </td>
                      <td>{x?.user_name}</td>
                      <td>{x?.user_username}</td>
                      <td>{x?.costcenter_code}</td>
                      <td>{x?.gl_account}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select class="form-control">
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Gl Code<span class="text-danger">*</span>
            </label>
            <Select
              name="glcode"
              options={glcode}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.glcode}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Cost Center<span class="text-danger">*</span>
            </label>
            <Select
              name="costcenter"
              options={costcenter}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.costcenter}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              User<span class="text-danger">*</span>
            </label>
            <Select
              name="user_id"
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.user_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
        <button class="btn btn-outline-danger" onClick={()=>{resetFilter()}}>
            Cancel
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {addnewshow && (
        <GlcostCenterFrom
          show={addnewshow}
          getList={()=>getGlcostcenter(currentPage, pageSizeNo, "-id", true, "", true, 0,0,0)}
          close={addnewclose}
          open={addnewopen}
          editState={editData}
          resetEditState={resetEditState}
        ></GlcostCenterFrom>
      )}
    </>
  );
}

export default GlApprover;
