import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import {getUserRmList,getGlCodeList } from "../../service/jv_service";
import {  updateses } from "../../service/sesService";
import { AddGlcost } from "../../service/glService";
import { uploadFile } from "../../service/brandService";
import { getCostCenter } from "../../service/userService";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";

function GlcostCenterFrom(props) {
  const userData = useSelector(selectUserData);
  const { show, close, edit, getList, editState,resetEditState} = props;
  const navigate = useNavigate();
  const [glData, setglData] = useState({
    user: {},
    Gl: {},
    costcenter:{},
    
  });
  const [sesError, setsesError] = useState({
    user: false,
    Gl: false,
    costcenter:false,
  });
  const [userlist, setuserlist] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [Errorattachment, setErrorattachment] = useState(false);
  const [glcode, setGlcode] = useState([]);
  const [costcenter, setCostcenter] = useState([]);
  const [oldApproverData, setOldApproverData] = useState([]);
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let user = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.username };
          user.push(data);
        });
        setuserlist(user);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getGlcodeList = () => {
    getGlCodeList()
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let glcode = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.gl_account };
          glcode.push(data);
        });
        setGlcode(glcode);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getcostCenter = () => {
    let branch=userData?.branch_id
    console.log(branch);
    getCostCenter(branch)
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let costcenter = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.code };
          costcenter.push(data);
        });
        setCostcenter(costcenter);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
 
  useEffect(() => {
    if (editState) {
      Object.keys(editState).forEach((item) => {
        glData[item] = editState[item];
      });
      setOldApproverData(editState?.Approvers)

    }
  }, [editState]);
  useEffect(() => {
    getUserList();
    getGlcodeList()
    getcostCenter()

  }, []);
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "user":
        setglData((prev) => ({
          ...prev,
          user: event,
        }));
        setsesError((prev) => ({
          ...prev,
          user: false,
        }));
        break;
     
      case "costcenter":
        setglData((prev) => ({
          ...prev,
          costcenter: event,
        }));
        setsesError((prev) => ({
          ...prev,
          costsenter: false,
        }));
        break;
      case "Gl":
        setglData((prev) => ({
          ...prev,
          Gl: event,
        }));
        setsesError((prev) => ({
          ...prev,
          Gl: false,
        }));
        break;
      default:
        break;
    }
  };
  const resetForm=() => {
    setglData({
      Requestor: {},
      Gl: {},
      Approvers: [],
    })
    resetEditState()
  }
  const submit = () => {
    glData.attachment=uploadDataIm
 if(editState.length===0) 
    { AddGlcost(glData)
      .then((response) => {
        getList();
        close();
        resetForm()
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });}
      else{
        glData.oldApprover=oldApproverData;
        updateses(glData)
      .then((response) => {
        getList();
        close();
        resetForm()
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      })
      }
  };
  const onProfileChange = async (e, flag) => {
    let file = e.target.files;
    let files = [];
    let filename = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      await uploadFile(fileData).then((response) => {
        let name = { name: data.name };
        filename.push(name);
        files.push(response.data);
      });
    }
    setErrorattachment(false);
    setAttachment(filename);
    setUploadDataIm(files);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Assign Approver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Gl Code<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="Gl"
                      options={glcode}
                      className="basic-multi-select"
                      value={glData?.Gl}
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                       <div
                      hidden={!sesError?.Gl}
                      className="small text-danger"
                    >
                      Kindly select Gl Code
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      User<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="user"
                      options={userlist}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={glData?.user}
                      placeholder="Enter Requestor SAP ID"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                       <div
                      hidden={!sesError?.user}
                      className="small text-danger"
                    >
                      Kindly select User
                    </div>
                  </div>
                </div>
                

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Cost center<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="costcenter"
                      options={costcenter}
                      value={glData?.costcenter}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                        <div
                      hidden={!sesError?.costcenter}
                      className="small text-danger"
                    >
                      Kindly select Cost Center
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                        <div className="form-group innergroup position-relative">
                          <label>
                            Upload File<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control bg-white"
                            disabled
                            placeholder="(Image, PDF Format)"
                          />
                          <div className="upload-btn-wrapper up-loposition">
                            <button type="button" className="uploadBtn">
                              Browse
                            </button>
                            <input
                              multiple
                              type="file"
                              title=""
                              name="attachment_path"
                              accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                              onChange={(e) =>
                                onProfileChange(e, "attachment_path")
                              }
                            />
                          </div>
                          {Errorattachment && (
                            <div className="small text-danger">
                              *Attachment is required
                            </div>
                          )}
                          <p>
                            Selected files{" "}
                            {attachment.map((a) => (
                              <span>
                                {" "}
                                <br />
                                {a.name}
                              </span>
                            ))}
                          </p>
                        </div>
                      </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={close}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => submit()}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GlcostCenterFrom;
