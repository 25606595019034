import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Dropdown, Tabs, Tab } from "react-bootstrap";
import {
  deleteReimbursementData,
  getExportData,
  getReimbursementData,
} from "./claimReimbServices";
import * as moment from "moment";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import MobileReimbursement from "./MobileReimbursement";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import MarriageGiftVoucher from "./MarriageGiftVoucher";

import NoticeReimbursement from "./NoticeReimb";
import EmpRefReimbursement from "./EmployeeRefReimb";

function FbpReportComp() {
  const dispatch = useDispatch()
  const claimTypeOptions = [
    { value: "Select", label: "Select" },
    { value: "1", label: "Mobile Handset Reimbursement" },
    { value: "2", label: "Marriage Gift Voucher" },
    { value: "3", label: "Notice Reimbursement" },
    { value: "4", label: "Employee Referral Reimbursement" },
  ];
  const userData = useSelector(selectUserData);
  const [claimType, setClaimType] = useState();
  const [filterClaimType, setFilterClaimType] = useState("");

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [currentTab, setCurrentTab] = useState("P");
  const [pageCount, setpageCount] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "id" });
  const [dataList, setDataList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    if (userData?.emp_sub_group) {
      getReimbursement(
        searchStr,
        "",
        currentTab,
        currentPage,
        pageSizeNo,
        sortData?.sort_by,
        true
      );
    }
  }, [userData]);

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getReimbursement(
      searchStr,
      claimType?.value,
      currentTab,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  }, [pageSizeNo]);

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getReimbursement(
      searchStr,
      claimType?.value,
      currentTab,
      activePage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  };

  const getReimbursement = (
    search,
    reimburseType,
    currentTab,
    currentPage,
    pageSizeNo,
    sortBy,
    paginate
  ) => {
    getReimbursementData(
      handleKey(
        search,
        reimburseType,
        currentTab,
        currentPage,
        pageSizeNo,
        sortBy,
        paginate
      )
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      if (response?.status === 200) {
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setDataList(response?.data?.dataList?.result);
      }
    });
  };

  const handleKey = (
    search,
    reimburseType,
    currentTab,
    currentPage,
    pageSizeNo,
    sortBy,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      currentPage +
      "&page_size=" +
      pageSizeNo +
      "&sort_by=" +
      sortBy +
      "&paginate=" +
      paginate;
    if (currentTab !== "" && currentTab !== undefined && currentTab !== null) {
      queryParm = queryParm + "&currentTab=" + currentTab;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      reimburseType !== "" &&
      reimburseType !== undefined &&
      reimburseType !== null
    ) {
      queryParm = queryParm + "&reimburse_type=" + reimburseType;
    }
    return queryParm;
  };

  const onClose = () => {
    addnewclose();
    getReimbursement(
      searchStr,
      claimType?.value,
      currentTab,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
    // setClaimType("");

    // setSelectedData(obj);
    setEdit(false);
  };

  const onAddOrUpdate = () => {
    if (edit) {
      setEdit(false);
      setSelectedData(null);
    }
    addnewclose();
    getReimbursement(
      searchStr,
      claimType?.value,
      currentTab,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  };

  const deleteRecord = (id) => {
    deleteReimbursementData(id, claimType?.value).then((response) => {
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        getReimbursement(
          searchStr,
          claimType?.value,
          currentTab,
          currentPage,
          pageSizeNo,
          sortData?.sort_by,
          true
        );
      }
    });
  };

  const handleDelete = (id) => {
    confirmAlert({
      message: `Are you sure to delete this record ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteRecord(id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const submitFilter = () => {
    getReimbursement(
      searchStr,
      filterClaimType?.value,
      currentTab,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
    setClaimType(filterClaimType);
    filterclose();
  };

  const updateRecord = (obj) => {
    setClaimType(
      claimTypeOptions?.filter((x) => {
        if (x?.value === obj?.reimburse_type) {
          return true;
        }
      })[0]
    );
    addnewopen();
    setSelectedData(obj);
    setEdit(true);
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getReimbursement(
      searchStr,
      claimType?.value,
      currentTab,
      currentPage,
      pageSizeNo,
      sdata?.sort_by,
      true
    );
  };

  const getExportDetails = (
    search,
    reimburseType,
    currentTab,
    currentPage,
    pageSizeNo,
    sortBy,
    paginate
  ) => {
    getExportData(
      handleKey(
        search,
        reimburseType,
        currentTab,
        currentPage,
        pageSizeNo,
        sortBy,
        paginate
      )
    ).then((response) => {
      if (response?.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${claimType?.label}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getReimbursement(
        e.target.value?.toLowerCase(),
        filterClaimType?.value,
        currentTab,
        currentPage,
        pageSizeNo,
        sortData?.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getReimbursement(
        data,
        filterClaimType?.value,
        currentTab,
        currentPage,
        pageSizeNo,
        sortData?.sort_by,
        true
      );
    }
  };

  return (
    <>
      <Modal
        show={addnewshow}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose maxwidth90 modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Claim Reimbursement </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div className="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Apply Claim for <span class="text-danger">*</span>
                    </label>
                    <Select
                      options={claimTypeOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setClaimType(e);
                      }}
                      value={claimType}
                    />
                  </div>
                </div>
              </div>
              {claimType?.value === "1" ? (
                <MobileReimbursement
                  onClose={onClose}
                  edit={edit}
                  editRecord={selectedData}
                  onAddOrUpdate={onAddOrUpdate}
                />
              ) : claimType?.value === "2" ? (
                <MarriageGiftVoucher
                  onClose={onClose}
                  edit={edit}
                  editRecord={selectedData}
                  onAddOrUpdate={onAddOrUpdate}
                />
              ) : claimType?.value === "3" ? (
                <NoticeReimbursement
                  onClose={onClose}
                  edit={edit}
                  editRecord={selectedData}
                  onAddOrUpdate={onAddOrUpdate}
                />
              ) : claimType?.value === "4" ? (
                <EmpRefReimbursement 
                  onClose={onClose}
                  edit={edit}
                  editRecord={selectedData}
                  onAddOrUpdate={onAddOrUpdate}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Claim Type <span class="text-danger">*</span>
            </label>
            <Select
              options={claimTypeOptions}
              onChange={(e) => {
                setFilterClaimType(e);
              }}
              value={filterClaimType}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              filterclose();
              setClaimType("");
              setFilterClaimType("");
              setDataList([]);
            }}
          >
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main" onClick={submitFilter}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">FBP Report</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          {claimType?.value === "1" ? (
                            <></>
                          ) : claimType?.value === "2" ? (
                            <></>
                          ) : claimType?.value === "3" ? (
                            <>
                              Search on{" "}
                              <strong>
                                {" "}
                                Department,
                                <br />
                                Amount,
                                <br />
                                Location
                              </strong>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="searchbar"
                        placeholder="Search"
                        onChange={(e) => {
                          handleSearch(e);
                          setCurrentPage(1);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                searchStr,
                                claimType?.value,
                                currentTab,
                                currentPage,
                                pageSizeNo,
                                sortData?.sort_by,
                                true
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button class="btn btn-primary-inner" onClick={addnewopen}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add Claim
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
              <div class="col-lg-3">
                    Emp.Status
                    <select>
                    <option>Active</option>
                    <option>Inactive</option>
                    </select>
                </div>
                <div class="col-lg-3">
                    Emp.Code
                    <input type="text" />
                </div>
                <div class="col-lg-3">
                    From
                    <input type="date"/>
                </div>
                <div class="col-lg-3">
                    To
                    <input type="date"/>
                </div>
                <button className="btn btn-primary">Go</button>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              <Tabs
                defaultActiveKey="P"
                id="approvaltabs"
                onSelect={(status) => {
                  getReimbursement(
                    searchStr,
                    claimType?.value,
                    status,
                    currentPage,
                    pageSizeNo,
                    sortData?.sort_by,
                    true
                  );
                }}
              >
                <Tab
                  eventKey="P"
                  title={<React.Fragment>Pending</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                        <th>EMP NO</th>	<th>NAME OF EMPLOYEE</th>	<th>LTA</th>	<th>MEAL</th>	<th>DRIVER</th>	<th>FUEL</th>	<th>CREATED AT</th>
                          <th
                            onClick={(e) => {
                              handlerData("id");
                            }}
                            className={
                              sortData["id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["id"]
                            }
                          >
                            ID
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("created_at");
                            }}
                            className={
                              sortData["created_at"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["created_at"]
                            }
                          >
                            Requested On
                          </th>
                          {claimType?.value === "1" ? (
                            <th
                              onClick={(e) => {
                                handlerData("amount");
                              }}
                              className={
                                sortData["amount"] === undefined
                                  ? "unsortcls main-pointer"
                                  : sortData["amount"]
                              }
                            >
                              Amount
                            </th>
                          ) : claimType?.value === "2" ? (
                            <>
                              <th>Marriage For</th>
                              <th
                                onClick={(e) => {
                                  handlerData("marriage_date");
                                }}
                                className={
                                  sortData["marriage_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["marriage_date"]
                                }
                              >
                                Marriage Date
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("first_name");
                                }}
                                className={
                                  sortData["first_name"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["first_name"]
                                }
                              >
                                First Name
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("first_name");
                                }}
                                className={
                                  sortData["first_name"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["first_name"]
                                }
                              >
                                Last Name
                              </th>
                            </>
                          ) : claimType?.value === "3" ? (
                            <>
                              <th>Recruiter</th>
                              <th>Amount</th>
                              <th>Requested Employee Id</th>
                              <th>Department</th>
                              <th>Location</th>
                            </>
                          ) : (
                            ""
                          )}
                          
                        </tr>
                      </thead>
                      <tbody>
                        {dataList?.length > 0 && claimType?.value !== "" ? (
                          dataList.map((obj) => (
                            <tr>
                              <td>
                                <button
                                  class="btn btn-blanktd text-primary f-12"
                                  onClick={() => {
                                    updateRecord(obj);
                                  }}
                                >
                                  <i class="far fa-edit"></i>
                                </button>
                                <button
                                  class="btn btn-blanktd text-danger ml-2 f-12"
                                  onClick={() => {
                                    handleDelete(obj?.id);
                                  }}
                                >
                                  <i class="far fa-trash-alt text-danger"></i>
                                </button>
                              </td>
                              <td>
                                <span class="badge bad-status badge-warning">
                                  Pending
                                </span>
                              </td>
                              <td class="fw-bold">
                                <Link class="text-theme">{obj?.id}</Link>
                              </td>
                              <td>
                                {moment(obj?.created_at).format("DD-MM-YYYY")}
                              </td>
                              {claimType?.value === "1" ? (
                                <td>{obj?.amount}</td>
                              ) : claimType?.value === "2" ? (
                                <>
                                  <td>{obj?.marriage_for}</td>
                                  <td>{obj?.marriage_date}</td>
                                  <td>{obj?.first_name}</td>
                                  <td>{obj?.last_name}</td>
                                </>
                              ) : claimType?.value === "3" ? (
                                <>
                                  <td>{obj?.recruiter}</td>
                                  <td>{obj?.amount}</td>
                                  <td>{obj?.emp_code}</td>
                                  <td>{obj?.department}</td>
                                  <td>{obj?.location}</td>
                                </>
                              ) : (
                                ""
                              )}
                              <td>{obj?.action_by}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {dataList?.length > 0 && (
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control main-pointer"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>10</option>
                            <option value={20} label={20}>20</option>
                            <option value={30} label={30}>30</option>
                            <option value={100} label={100}>100</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </Tab>

                <Tab
                  eventKey="A"
                  title={<React.Fragment>Approved</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th
                            onClick={(e) => {
                              handlerData("id");
                            }}
                            className={
                              sortData["id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["id"]
                            }
                          >
                            ID
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("created_at");
                            }}
                            className={
                              sortData["created_at"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["created_at"]
                            }
                          >
                            Requested On
                          </th>
                          {claimType?.value === "1" ? (
                            <th
                              onClick={(e) => {
                                handlerData("amount");
                              }}
                              className={
                                sortData["amount"] === undefined
                                  ? "unsortcls main-pointer"
                                  : sortData["amount"]
                              }
                            >
                              Amount
                            </th>
                          ) : claimType?.value === "2" ? (
                            <>
                              <th>Marriage For</th>
                              <th
                                onClick={(e) => {
                                  handlerData("marriage_date");
                                }}
                                className={
                                  sortData["marriage_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["marriage_date"]
                                }
                              >
                                Marriage Date
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("first_name");
                                }}
                                className={
                                  sortData["first_name"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["first_name"]
                                }
                              >
                                First Name
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("last_name");
                                }}
                                className={
                                  sortData["last_name"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["last_name"]
                                }
                              >
                                Last Name
                              </th>
                            </>
                          ) : claimType?.value === "3" ? (
                            <>
                              <th>Approved by</th>
                            </>) : (
                            ""
                          )}
                          <th
                            onClick={(e) => {
                              handlerData("remarks");
                            }}
                            className={
                              sortData["remarks"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["remarks"]
                            }
                          >
                            Remarks
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataList?.length > 0 ? (
                          dataList.map((obj) => (
                            <tr>
                              <td>
                                <span class="badge bad-status badge-success">
                                  Approved
                                </span>
                              </td>

                              <td class="fw-bold">
                                <Link class="text-theme">{obj?.id}</Link>
                              </td>

                              <td>
                                {moment(obj?.created_at).format("DD-MM-YYYY")}
                              </td>
                              {claimType?.value === "1" ? (
                                <td>{obj?.amount}</td>
                              ) : claimType?.value === "2" ? (
                                <>
                                  <td>{obj?.marriage_for}</td>
                                  <td>{obj?.marriage_date}</td>
                                  <td>{obj?.first_name}</td>
                                  <td>{obj?.last_name}</td>
                                </>
                              ) : claimType?.value === "3" ? (
                                <>
                                  <td>-</td>
                                  
                                </>
                              ) : (
                                ""
                              )}
                              <td>{obj?.remarks}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {dataList?.length > 0 && (
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control main-pointer"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>10</option>
                            <option value={20} label={20}>20</option>
                            <option value={30} label={30}>30</option>
                            <option value={100} label={100}>100</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </Tab>

                <Tab
                  eventKey="D"
                  title={<React.Fragment>Rejected</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th
                            onClick={(e) => {
                              handlerData("id");
                            }}
                            className={
                              sortData["id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["id"]
                            }
                          >
                            ID
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("created_at");
                            }}
                            className={
                              sortData["created_at"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["created_at"]
                            }
                          >
                            Requested On
                          </th>
                          {claimType?.value === "1" ? (
                            <th
                              onClick={(e) => {
                                handlerData("amount");
                              }}
                              className={
                                sortData["amount"] === undefined
                                  ? "unsortcls main-pointer"
                                  : sortData["amount"]
                              }
                            >
                              Amount
                            </th>
                          ) : claimType?.value === "2" ? (
                            <>
                              <th>Marriage For</th>
                              <th
                                onClick={(e) => {
                                  handlerData("marriage_date");
                                }}
                                className={
                                  sortData["marriage_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["marriage_date"]
                                }
                              >
                                Marriage Date
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("first_name");
                                }}
                                className={
                                  sortData["first_name"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["first_name"]
                                }
                              >
                                First Name
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("last_name");
                                }}
                                className={
                                  sortData["last_name"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["last_name"]
                                }
                              >
                                Last Name
                              </th>
                            </>
                          ) : (
                            ""
                          )}
                          <th
                            onClick={(e) => {
                              handlerData("remarks");
                            }}
                            className={
                              sortData["remarks"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["remarks"]
                            }
                          >
                            Remarks
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataList?.length > 0 ? (
                          dataList?.map((obj) => (
                            <tr>
                              <td>
                                <span class="badge bad-status badge-danger">
                                  Rejected
                                </span>
                              </td>
                              <td class="fw-bold">
                                <Link class="text-theme">{obj?.id}</Link>
                              </td>
                              <td>
                                {moment(obj?.created_at).format("DD-MM-YYYY")}
                              </td>
                              {claimType?.value === "1" ? (
                                <td>{obj?.amount}</td>
                              ) : claimType?.value === "2" ? (
                                <>
                                  <td>{obj?.marriage_for}</td>
                                  <td>{obj?.marriage_date}</td>
                                  <td>{obj?.first_name}</td>
                                  <td>{obj?.last_name}</td>
                                </>
                              ) : (
                                ""
                              )}
                              <td>{obj?.remarks}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {dataList?.length > 0 && (
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control main-pointer"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>10</option>
                            <option value={20} label={20}>20</option>
                            <option value={30} label={30}>30</option>
                            <option value={100} label={100}>100</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FbpReportComp;
