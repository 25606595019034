import { useEffect } from "react";
import { useState } from "react";
import styles from "./ModularTable.module.css";
import AddIcon from "../../../../assets/images/plus.png"
import CloseIcon from "../../../../assets/images/close.png"
import { getCropDropdown, getLanguageDropdown, getPopItemDropdown, getProductItemDropdown } from "../../../../service/AgriPortalService/PopPlanner";
import { MAX_NUMBER_LENGTH, MAX_TEXT_LENGTH } from "../../utils/constant";

function restrictToWords(word, limit) {
    if (!word) return "";

    if (word.length >= limit) {
        return word.slice(0, limit) + "...";
    }
    return word;
}
const ModularTable = ({ columns, data, onTableDataChange, addRow, deleteRow, tableRowReRender, mode, showTotal }) => {
    const [internalData, setInternalData] = useState(data);
    const [cropData, setCropData] = useState([]);
    const [blacklistObj, setBlacklistObj] = useState({});
    const [reRender, setReRender] = useState(false)


    useEffect(() => {
        let BLCols = {};

        for (let i = 0; i < columns?.length; i++) {
            if (columns[i].nonrepeat) {
                BLCols[i] = [];
            }
        }

        setBlacklistObj(BLCols);
    }, [columns]);

    useEffect(() => {
        if (onTableDataChange)
            onTableDataChange(internalData);

        setBlacklistObj(state => {
            const BLObj = {};
            for (let i = 0; i < Object.keys(state).length; i++) {

                BLObj[Object.keys(state)[i]] = [];
                for (let j = 0; j < internalData.length; j++) {
                    BLObj[Object.keys(state)[i]].push(internalData[j][Object.keys(state)[i]].selected);
                }
            }



            return BLObj;
        })


    }, [internalData, tableRowReRender, reRender]);

    useEffect(() => {
        getCropDropdown()
            .then(res => {
                let data = []
                res.data.dataList.map((ele) => {
                    data.push({
                        name: ele.crop_name,
                        id: ele.crop_id,
                        code: ele.crop_code,
                    })
                })

                setCropData(data)

            })
            .catch(err => console.log(err));
    }, [])

    useEffect(() => {
        setInternalData(data)

    }, [data, data?.length, tableRowReRender, reRender]);

    const onChangeHandler = (e, col, row, type) => {
        if (e.target.value.length >= (MAX_NUMBER_LENGTH + 1) && e.key != "Backspace" && type == "N") { e.target.value = 99999 }
        if (e.target.value.length >= (MAX_TEXT_LENGTH + 1) && e.key != "Backspace" && type == "T") { e.preventDefault() }
        setInternalData(state => {
            const newData = [...state];
            newData[col][row].value[0] = e.target.value;
            return newData;
        })
    }

    const dropdownClickedHandler = (data, col, row) => {


        if (row == 0) {
            if (data == "None") {
                setInternalData(state => {
                    let newData = [...state];
                    newData[col][0].selected = "None"
                    newData[col][1].value = []
                    newData[col][1].meta.raw = []
                    newData[col][1].selected = ""
                    newData[col][2].value = []
                    newData[col][2].meta.raw = []
                    newData[col][2].selected = ""
                    newData[col][3].value = []
                    newData[col][3].meta.raw = []
                    newData[col][3].selected = ""
                    return newData;
                })
            } else {
                let findCropIndex = cropData.findIndex(e => e.name == data)
                getLanguageDropdown(cropData[findCropIndex]?.id).then((res) => {

                    let languageData = []
                    res.data.dataList.map((ele) => {
                        languageData.push({
                            name: ele.language_name,
                            id: ele.language_id,
                            code: ele.language_code,
                        })
                    })
                    let findLanguageIndex = languageData.findIndex(e => e.name == internalData[col][1].selected)
                    findLanguageIndex = findLanguageIndex == -1 ? 0 : findLanguageIndex
                    getPopItemDropdown(cropData[findCropIndex]?.id, languageData[findLanguageIndex]?.id).then((popResponse) => {

                        let popData = []
                        popResponse.data.dataList.map((ele) => {
                            popData.push({
                                name: ele.pop_item_name,
                                id: ele.pop_item_id,
                                code: ele.pop_item_code,
                            })
                        })
                        let findPopIndex = popData.findIndex(e => e.name == internalData[col][2].selected)
                        findPopIndex = findPopIndex == -1 ? 0 : findPopIndex
                        getProductItemDropdown(cropData[findCropIndex]?.id, languageData[findLanguageIndex]?.id, popData[findPopIndex]?.id).then((productRes) => {
                            let productData = []
                            productRes.data.dataList.map((ele) => {
                                productData.push({
                                    name: ele.company_product_name,
                                    id: ele.company_product_id,
                                    code: ele.company_product_code,
                                })
                            })
                            let findProductIndex = popData.findIndex(e => e.name == internalData[col][3].selected)
                            findProductIndex = findProductIndex == -1 ? 0 : findProductIndex

                            setInternalData(state => {
                                let newData = [...state];
                                newData[col][0].selected = data
                                newData[col][1].value = languageData.length > 0 ? languageData?.map(el => el.name) : []
                                newData[col][1].meta.raw = languageData
                                newData[col][1].selected = languageData[findLanguageIndex]?.name
                                newData[col][2].value = popData.length > 0 ? popData?.map(el => el.name) : []
                                newData[col][2].meta.raw = popData
                                newData[col][2].selected = popData[findPopIndex]?.name
                                newData[col][3].value = productData.length > 0 ? productData?.map(el => el.name) : []
                                newData[col][3].meta.raw = productData
                                newData[col][3].selected = productData[findProductIndex].name
                                return newData;
                            })
                        }).catch((err) => {
                            console.log(err)
                        })
                    }).catch((err) => {
                        console.log(err)
                    })
                }).catch((err) => {
                    console.log(err)
                })
            }
        } else if (row == 1) {

            let languageData = internalData[col][1].meta.raw
            let findCropIndex = cropData.findIndex(e => e.name == internalData[col][0].selected)
            let findLanguageIndex = languageData.findIndex(e => e.name == internalData[col][1].selected)
            findLanguageIndex = findLanguageIndex == -1 ? 0 : findLanguageIndex
            getPopItemDropdown(cropData[findCropIndex]?.id, languageData[findLanguageIndex]?.id).then((popResponse) => {

                let popData = []
                popResponse.data.dataList.map((ele) => {
                    popData.push({
                        name: ele.pop_item_name,
                        id: ele.pop_item_id,
                        code: ele.pop_item_code,
                    })
                })
                let findPopIndex = popData.findIndex(e => e.name == internalData[col][2].selected)
                findPopIndex = findPopIndex == -1 ? 0 : findPopIndex
                getProductItemDropdown(cropData[findCropIndex]?.id, languageData[findLanguageIndex]?.id, popData[findPopIndex]?.id).then((productRes) => {
                    let productData = []
                    productRes.data.dataList.map((ele) => {
                        productData.push({
                            name: ele.company_product_name,
                            id: ele.company_product_id,
                            code: ele.company_product_code,
                        })
                    })
                    let findProductIndex = popData.findIndex(e => e.name == internalData[col][3].selected)
                    findProductIndex = findProductIndex == -1 ? 0 : findProductIndex

                    setInternalData(state => {
                        let newData = [...state];
                        newData[col][1].selected = data
                        newData[col][2].value = popData.length > 0 ? popData?.map(el => el.name) : []
                        newData[col][2].meta.raw = popData
                        newData[col][2].selected = popData[findPopIndex]?.name
                        newData[col][3].value = productData.length > 0 ? productData?.map(el => el.name) : []
                        newData[col][3].meta.raw = productData
                        newData[col][3].selected = productData[findProductIndex].name
                        return newData;
                    })
                }).catch((err) => {
                    console.log(err)
                })
            }).catch((err) => {
                console.log(err)
            })
        } else if (row == 2) {
            let findCropIndex = cropData.findIndex(e => e.name == internalData[col][0].selected)
            let languageData = internalData[col][1].meta.raw
            let popData = internalData[col][2].meta.raw
            let findLanguageIndex = languageData.findIndex(e => e.name == internalData[col][1].selected)
            findLanguageIndex = findLanguageIndex == -1 ? 0 : findLanguageIndex
            let findPopIndex = popData.findIndex(e => e.name == data)
            findPopIndex = findPopIndex == -1 ? 0 : findPopIndex
            getProductItemDropdown(cropData[findCropIndex]?.id, languageData[findLanguageIndex]?.id, popData[findPopIndex]?.id).then((productRes) => {
                let productData = []
                productRes.data.dataList.map((ele) => {
                    productData.push({
                        name: ele.company_product_name,
                        id: ele.company_product_id,
                        code: ele.company_product_code,
                    })
                })
                let findProductIndex = popData.findIndex(e => e.name == internalData[col][3].selected)
                findProductIndex = findProductIndex == -1 ? 0 : findProductIndex

                setInternalData(state => {
                    let newData = [...state];
                    newData[col][1].selected = internalData[col][1].selected
                    newData[col][2].selected = data
                    newData[col][2].value = popData.length > 0 ? popData?.map(el => el.name) : []
                    newData[col][2].meta.raw = popData
                    newData[col][2].selected = popData[findPopIndex]?.name
                    newData[col][3].value = productData.length > 0 ? productData?.map(el => el.name) : []
                    newData[col][3].meta.raw = productData
                    newData[col][3].selected = productData[findProductIndex]?.name
                    return newData;
                })
            }).catch((err) => {
                console.log(err)
            })
        } else if (row == 3) {
            setInternalData(state => {
                const newData = [...state];
                newData[col][3].selected = data;
                return newData
            })
        }

        // setReRender(!reRender)
    }
    return <div>
        <div class="table-responsive" style={{ overflow: "auto", maxWidth: "180vw", maxHeight: "65vh" }}>
            <table class="table table-striped table-bordered tablecured">
                <thead>
                    <tr style={{ minWidth: "150px" }}>
                        {
                            columns && columns.map(el => {
                                return <th classname={styles["agri-portal-table"]} style={{ position: "sticky", top: "0", zIndex: "2", textOverflow: "clip", whiteSpace: "normal", textAlign: "center", minWidth: "50px" }}>{el.name}</th>
                            })
                        }
                        {
                            mode == "view" || mode == "approve" ?
                                <></>
                                :
                                (
                                    <th classname={styles["agri-portal-table"]} style={{ position: "sticky", top: "0", zIndex: "2", backgroundColor: "#FFF", cursor: "pointer", width: "30px", height: 50, minWidth: "30px" }}
                                        onClick={() => { addRow() }}
                                    >
                                        <img src={AddIcon} alt="add" style={{ width: 20, height: 15, visibility: "visible", objectFit: "cover" }}
                                        />
                                    </th>
                                )
                        }
                        {/* <th>Territory Code</th>
                        <th>Territory Name</th>
                        <th>Submitted On</th>
                        <th>Submitted By</th>
                        <th>Financial Year</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>

                    {
                        internalData && internalData?.map((el, i) => {
                            const ele = el && el.length > 0 && el.map((e, idx) => {

                                if (!columns[idx]) return <></>;
                                if (!internalData[i]) return <></>;

                                if (showTotal && (idx == (el.length - 1))) return <td classname={styles["agri-portal-table"]} style={{ textAlign: internalData[i][idx]?.value == "Total" ? "left" : "right", width: "50px", textOverflow: "clip", whiteSpace: "normal", backgroundColor: "#0195D4", color: "#FFF" }}><b>{internalData[i][idx]?.value}</b></td>;

                                //FOR EDITABLE TEXT INPUTS
                                if (columns[idx].type == "T" && columns[idx].isEditable)
                                    return <td classname={styles["agri-portal-table"]} style={{ maxWidth: "100px", minWidth: "60px", textOverflow: "clip", whiteSpace: "normal", }}>
                                        <input type="text" value={internalData[i][idx].value} style={{ width: "100%" }} onChange={(e) => onChangeHandler(e, i, idx, "T")} onKeyDown={(event) => { if (event.target.value.length >= MAX_TEXT_LENGTH && event.key != "Backspace") { event.preventDefault() } }} />
                                    </td>;

                                //FOR EDITABLE NUMBER INPUTS
                                else if (columns[idx].type == "N" && columns[idx].isEditable)
                                    return <td classname={styles["agri-portal-table"]} style={{ textAlign: "right", width: "50px", minWidth: "auto", textOverflow: "clip", whiteSpace: "normal", }}>
                                        <input type="number" onWheel={(e) => e.target.blur()} value={internalData[i][idx].value} style={{ width: "50px" }} onChange={(e) => onChangeHandler(e, i, idx, "N")} onKeyDown={(event) => { if (event.target.value.length >= MAX_NUMBER_LENGTH && event.key != "Backspace") { event.preventDefault() } else if (event.key == "ArrowUp" || event.key == "ArrowDown") { event.preventDefault() } else if ((!columns[idx].allowDecimalUpto && event.key === '.') || event.key === "-") { event.preventDefault(); } else if ((parseInt(event.target.value + event.key) > 100 && event.key != "Backspace") && columns[idx].isPercentage) { event.preventDefault(); } else if ((parseInt(event.target.value + event.key) >= 6 && event.key != "Backspace") && columns[idx].isRating) { event.preventDefault(); } }} onInput={(event) => { if (!columns[idx].allowDecimalUpto) event.target.value = event.target.value.replace(/[^0-9]*/g, ''); else if (event?.target?.value?.split(".")[1]?.length > 2) { event.target.value = event.target.value.split(".")[0] + "." + event.target.value.split(".")[1].slice(0, 2) } }} />
                                    </td>;

                                // FOR EDITABLE DROPDOWNS
                                // else if(columns[idx].type=="D"&&columns[idx].isEditable)
                                // return <td style={{overflow:"visible",width:"110%"}}>
                                // <div class="dropdown" >
                                //     <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{position:"relative",zIndex:1000, width:"170px", display:"flex", justifyContent:"space-between"}}>
                                //         {
                                //             internalData[i][idx].selected?restrictToWords(internalData[i][idx].selected,20):restrictToWords("Select "+columns[idx].name,20)
                                //         }
                                //     </button>
                                //     <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{zIndex:1001,width:"170px"}}>
                                //         {
                                //             data && data[i][idx].value.map(e=>{
                                //                 return <div className="dropdown-item"  onClick={(event)=>dropdownClickedHandler(e,i,idx)}>{restrictToWords(e,17)}</div>
                                //             })
                                //         }
                                //     </div>
                                //     </div>
                                // </td>;

                                else if (columns[idx].type == "D" && columns[idx].isEditable)
                                    return <td classname={styles["agri-portal-table"]} style={{ border: "none", margin: "0 auto", minWidth: "100px", textOverflow: "clip", whiteSpace: "normal" }}>
                                        <select
                                            className="form-control newbgselect"
                                            name="business_unit_id"
                                            style={{ cursor: "pointer" }}

                                            // classNamePrefix="select"
                                            onChange={(e) => {
                                                dropdownClickedHandler(e.target.value, i, idx);
                                            }}
                                        >
                                            {/* <option value="" label="Select Business Unit" /> */}
                                            {
                                                internalData && internalData[i][idx]?.value?.map(e => {

                                                    if (blacklistObj[idx]) {
                                                        if (blacklistObj[idx].find(ell => ell == e) != undefined && e !== internalData[i][idx].selected) return <></>;
                                                    }

                                                    if (internalData[i][idx].selected == e) {

                                                        return <option value={e} label={e} selected />
                                                    }
                                                    else
                                                        return <option value={e} label={e} />
                                                })


                                            }
                                        </select></td>;

                                //FOR UNEDITABLE VALUES 
                                else if (columns[idx].type == "D" && !columns[idx].isEditable)
                                    return <td classname={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>
                                        {e.selected}
                                    </td>

                                else if (columns[idx].type == "N" && !columns[idx].isEditable)
                                    return <td classname={styles["agri-portal-table"]} style={{ textAlign: "right", textOverflow: "clip", whiteSpace: "normal" }}>
                                        {e.value}
                                    </td>

                                else
                                    return <td classname={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>
                                        {e.value}
                                    </td>

                            });
                            return <tr>
                                {ele}
                                {
                                    (mode == "view" || mode == "approve") ?
                                        null
                                        :
                                        (
                                            <td classname={styles["agri-portal-table"]} style={{ backgroundColor: "#FFF", cursor: "pointer", textOverflow: "clip", whiteSpace: "normal" }}
                                                onClick={() => { deleteRow(i) }}
                                            >
                                                <img src={CloseIcon} alt="" style={{ width: 25, height: 15, objectFit: "cover" }}
                                                />
                                            </td>
                                        )
                                }

                            </tr>;
                        })
                    }
                    {/* <td>T1</td>
                        <td>T1</td>
                        <td>1-March-2022</td>
                        <td>Raj</td>
                        <td>FY 22-23</td>
                        <td>Approved</td>
                        <td>All good</td>
                        <td>View</td>                              */}
                    {/* </tr> */}
                </tbody>
            </table>
        </div>
    </div>
}

export default ModularTable;