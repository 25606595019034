import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getCompanyProduct = async ()=>{    
    return axios.get(`${AUTH_BASE_URL}/company-product/drop-down?is_brand=true`);
}

export const getMarketPlayerDropdown = async () =>{    
    return axios.get(`${AUTH_BASE_URL}/market-player/drop-down`)
}

export const getCurrentFy = async () =>{    
    return axios.get(`${AUTH_BASE_URL}/current-fy`)
}

export const getCompetitiveMolecules = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/competitive-molecules/drop-down${query}`)
}

export const getCompititionBrand = async () => {
    return axios.get(`${AUTH_BASE_URL}/competing-brand`);
};

export const getLastCompititionBrand = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/competing-brand/last-competing-brand${query}`)
}

export const postCompititionBrand = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/competing-brand`,body);
}

export const putCompititionBrand = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/competing-brand`,body);
}

export const getCompetitionBrandApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/competing-brand/approver-competing-brand-logs${query}`);
}

export const postCompetitionBrandAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/competing-brand/action-competing-brand`,body);
}