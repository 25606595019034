import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getDetailedSalesPlan = async () => {
    return axios.get(`${AUTH_BASE_URL}/detailed-sale-plan`);
};

export const getDetailedSalesPlanDropdown = async (id) => {
    return axios.get(`${AUTH_BASE_URL}/detailed-sale-plan/products/drop-down?detailed_sale_plan_id=${id}`);
};

export const getLastDetailedSalesPlan = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/detailed-sale-plan/last-detailed-sale-plan${query}`)
}

export const getLastDetailedSalesPlanByCrop = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/detailed-sale-plan/last-product-detailed-sale-plan${query}`)
}

export const postLastDetailedSalesPlan = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/detailed-sale-plan`,body);
}
export const putLastDetailedSalesPlan = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/detailed-sale-plan`,body);
}


export const getDetailedSalesPlanApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/detailed-sale-plan/approver-detailed-sale-plan-logs${query}`);
}

export const postDetailedSalesPlanAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/detailed-sale-plan/action-detailed-plan`,body);
}