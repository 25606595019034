import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import {
  Tabs,
  Tab,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";

function SurveySetting() {
  const selectquestion = [
    {
      value: "Multiple Choice",
      label: (
        <div>
          <i class="far fa-dot-circle"></i> Multiple Choice
        </div>
      ),
    },
    {
      value: "Dropdown",
      label: (
        <div>
          <i class="fas fa-caret-down"></i> Dropdown
        </div>
      ),
    },
    {
      value: "File upload",
      label: (
        <div>
          <i class="fas fa-cloud-upload-alt"></i> File upload
        </div>
      ),
    },
    {
      value: "Short Answer",
      label: (
        <div>
          <i class="fas fa-align-left"></i> Short Answer
        </div>
      ),
    },
  ];

  return (
    <>
      <div class="content-wrapper-inner">
        <div class="innerheadsec pb-0">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Survey Setting</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/home">Company</Breadcrumb.Item>
                <Breadcrumb.Item href="/survey">Survey</Breadcrumb.Item>
                <Breadcrumb.Item active>Survey Setting</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
          </div>
        </div>

        <div class="addusertabs">
          <Tabs defaultActiveKey="Primary" id="adduserinfo">
            <Tab
              eventKey="Primary"
              title={
                <React.Fragment>
                  <span>1</span> Email Setting
                </React.Fragment>
              }
            >
              <div class="approval-bg no-bg border-0 purchaseinnertabs mt-0 position-relative">
                <Tabs defaultActiveKey="pending" id="approvaltabs">
                  <Tab
                    eventKey="pending"
                    title={<React.Fragment>Email</React.Fragment>}
                  >
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Email Subject<span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Email Subject"
                          />
                         
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group innergroup">
                          <label>
                            Email Body<span class="text-danger">*</span>
                          </label>
                          <textarea
                            type="text"
                            class="form-control"
                            placeholder="Enter Content"
                          ></textarea>
                         
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label className="d-block mb-2">
                            User Name<span class="text-danger">*</span>
                          </label>
                          <label class="logCheck d-inline-block mr-4">
                            Yes
                            <input type="radio" name="statusupdate5" checked />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-inline-block">
                            No
                            <input type="radio" name="statusupdate5" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label className="d-block mb-2">
                            User Email<span class="text-danger">*</span>
                          </label>
                          <label class="logCheck d-inline-block mr-4">
                            Yes
                            <input type="radio" name="statusupdate8" checked />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-inline-block">
                            No
                            <input type="radio" name="statusupdate8" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label className="d-block mb-2">
                            User Mobile<span class="text-danger">*</span>
                          </label>
                          <label class="logCheck d-inline-block mr-4">
                            Yes
                            <input type="radio" name="statusupdate9" checked />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-inline-block">
                            No
                            <input type="radio" name="statusupdate9" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div class="col-md-12 mt-3">
                        <button class="btn btn-outline-secondary mr-3">
                          <i class="far fa-times-circle"></i> Cancel
                        </button>
                        <button class="btn btn-primary-inner bpi-main">
                          <i class="far fa-check-circle"></i> Save
                        </button>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="draft"
                    title={<React.Fragment>Recipents</React.Fragment>}
                  >
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reply-to-email<span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Reply-to-email"
                          />
                         
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Sender-name<span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Sender-name"
                          />
                         
                        </div>
                      </div>

                      <div class="col-md-12 mt-2">
                        <button class="btn btn-outline-secondary mr-3">
                          <i class="far fa-times-circle"></i> Cancel
                        </button>
                        <button class="btn btn-primary-inner bpi-main">
                          <i class="far fa-check-circle"></i> Save
                        </button>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="approved"
                    title={<React.Fragment>Advanced</React.Fragment>}
                  >
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <div class=" border-bottom mb-3">
                          <h6 class="fw-bold mb-1">Send Email On</h6>
                          <p class="f-14 text-muted">
                            Users can get a new email every time the form
                            submission is edited
                          </p>

                          <div class="form-group innergroup">
                            <label class="logCheck d-block mb-2">
                              Send Email on form submitted
                              <input
                                type="radio"
                                name="statusupdate10"
                                checked
                              />
                              <span class="checkmark"></span>
                            </label>
                            <label class="logCheck d-block">
                              Send Email on form edited
                              <input type="radio" name="statusupdate10" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>

                        <div class=" border-bottom mb-3">
                          <h6 class="fw-bold mb-1">PDF Attachemnt</h6>
                          <p class="f-14 text-muted">
                            Select PDF Documents you would like to attach to
                            email
                          </p>

                          <div class="form-group innergroup">
                            <label class="logCheck d-inline-block mr-2">
                              Yes
                              <input
                                type="radio"
                                name="statusupdate10"
                                checked
                              />
                              <span class="checkmark"></span>
                            </label>
                            <label class="logCheck d-inline-block">
                              No
                              <input type="radio" name="statusupdate10" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>

                        <div class=" border-bottom mb-3">
                          <h6 class="fw-bold mb-1">Attach a file</h6>
                          <p class="f-14 text-muted">
                            User can get this file as Attachemnt
                          </p>

                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group innergroup position-relative modaldefaultclose">
                                <label>
                                  Attachment<span class="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control bg-white"
                                  disabled
                                  placeholder="(Image, PDF Format)"
                                />
                                <div class="upload-btn-wrapper up-loposition">
                                  <button class="uploadBtn">Browse</button>
                                  <input type="file" />
                                </div>
                                <p>
                                  Selected files <span>file-1.png</span>
                                </p>
                                <div class="uploaded-image">
                                  <img src={Logo} alt="" height={50} />
                                  <button class="btn-close"></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class=" border-bottom mb-3">
                          <h6 class="fw-bold mb-1">Send Email Later</h6>
                          <p class="f-14 text-muted">
                            Users can receive reminder emails many days after
                            they fill a form.
                          </p>

                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group innergroup">
                                <select class="form-control newbgselect">
                                  <option>Select</option>
                                  <option>Right Away</option>
                                  <option selected>10 days</option>
                                </select>
                                <p>
                                  Caption text, description, error notification
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mt-2">
                        <button class="btn btn-outline-secondary mr-3">
                          <i class="far fa-times-circle"></i> Cancel
                        </button>
                        <button class="btn btn-primary-inner bpi-main">
                          <i class="far fa-check-circle"></i> Save
                        </button>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </Tab>
            <Tab
              eventKey="Personal"
              title={
                <React.Fragment>
                  <span>2</span> Thank you Setting
                </React.Fragment>
              }
            >
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup position-relative modaldefaultclose">
                    <label>
                      Thank you Image<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control bg-white"
                      disabled
                      placeholder="(Image, PDF Format)"
                    />
                    <div class="upload-btn-wrapper up-loposition">
                      <button class="uploadBtn">Browse</button>
                      <input type="file" />
                    </div>
                    <p>
                      Selected files <span>file-1.png</span>
                    </p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Thank You Message<span class="text-danger">*</span>
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      placeholder="Enter Thank You Message"
                    ></textarea>
                   
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <button class="btn btn-outline-secondary mr-3">
                    <i class="far fa-times-circle"></i> Cancel
                  </button>
                  <button class="btn btn-primary-inner bpi-main">
                    <i class="far fa-check-circle"></i> Save
                  </button>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Addressess"
              title={
                <React.Fragment>
                  <span>3</span> Publish Setting
                </React.Fragment>
              }
            >
              <div class="row">
                <div class="col-md-12">
                  <div class=" border-bottom mb-3">
                    <h6 class="fw-bold mb-1">Private/Public Setting</h6>
                    <p class="f-14 text-muted">
                      Select form visibility setting.
                    </p>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="privatepublic mb-2 innergroup">
                          <label class="logCheck d-sm-inline-block mr-0 mr-sm-4">
                            <i class="fas fa-lock"></i> Private form
                            <br />
                            <span>Only available to invited people</span>
                            <input type="radio" name="statusupdate31" checked />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-sm-inline-block mr-0 mr-sm-4">
                            <i class="fas fa-unlock-alt"></i> Public form
                            <br />
                            <span>Available to anyone.</span>
                            <input type="radio" name="statusupdate31" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup position-relative modaldefaultclose">
                          <label>
                            Upload Excel<span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control bg-white"
                            disabled
                            placeholder="(csv, excel Format only)"
                          />
                          <div class="upload-btn-wrapper up-loposition">
                            <button class="uploadBtn">Browse</button>
                            <input type="file" />
                          </div>
                          <p>
                            Selected files <span>file-1.png</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class=" border-bottom mb-3">
                    <h6 class="fw-bold mb-1">Permissions</h6>
                    <p class="f-14 text-muted">
                      Select user Permissions settings
                    </p>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="privatepublic permissionpublic mb-2 innergroup">
                          <label class="logCheck d-block">
                            Submit Only
                            <br />
                            <span>
                              Invited people can view and submit the form only.
                            </span>
                            <input type="radio" name="permission" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-block">
                            Submit & View Later
                            <br />
                            <span>
                              Invited People can submit the form and view their
                              submissions later
                            </span>
                            <input type="radio" name="permission" checked />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-block">
                            Submit & Edit Later
                            <br />
                            <span>
                              Invited People can submit the form and view and
                              edit their
                            </span>
                            <input type="radio" name="permission" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <button class="btn btn-outline-secondary mr-3">
                    <i class="far fa-times-circle"></i> Cancel
                  </button>
                  <button class="btn btn-primary-inner bpi-main">
                    <i class="far fa-check-circle"></i> Save
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default SurveySetting;
