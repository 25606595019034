import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown, Badge, Spinner } from "react-bootstrap";
import AddSubGroup from "./AddSubGroup";
import ViewSubGroup from "./ViewSubGroup";
import { Link } from "react-router-dom";
import * as SkillGroupService from "../../service/skillGroup";
import * as SkillService from "../../service/skill";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { injectStyle } from "react-toastify/dist/inject-style";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
if (typeof window !== "undefined") {
    injectStyle();
}
function SubGroupMaster() {
    const dispatch = useDispatch();
    const [searchStr, setSearchStr] = useState("");
    const [subGroupList, setSubGroupList] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [viewForm, setViewForm] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [subGroupData, setSubGroupData] = useState(null);
    const [parentGroupList, setParentGroupList] = useState(null);
    const [parentGroupOption, setParentGroupOption] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [viewFormData, setViewFormData] = useState(null);
    const [importshow, importsetShow] = useState(false);
    const importuser = () => importsetShow(true);
    const [downloadButton, setDownloadButton] = useState(false);
    const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
    const [permissionAccess, setPermissionAccess] = useState({});
    const [showSkillTab, setShowSkillTab] = useState(false)
    const handleKey = (search, pageNo, page_size, sort_by, paginate) => {
        let queryParm =
            "page_no=" +
            pageNo +
            "&page_size=" +
            page_size +
            "&sort_by=" +
            sort_by +
            "&paginate=" +
            paginate;
        if (search !== "" && search !== undefined && search !== null) {
            queryParm = queryParm + "&search=" + search;
        }
        return queryParm;
    };

    const getGroup = (search, pageNo, page_size, sort_by, paginate) => {
        SkillGroupService.getSkillGroup(
            handleKey(search, pageNo, page_size, sort_by, false)
        )
            .then((response) => {
                dispatch(setDisplayLoader("Hide"));
                setParentGroupList(response?.data?.dataList?.result);
                let option = []
                response?.data?.dataList?.result.map((el) => {
                    option.push({
                        value: el.id,
                        label: `${el.group_id}-${el.group_name}`
                    })
                })
                setParentGroupOption(option)
                setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    }

    const getSubGroup = (search, pageNo, page_size, sort_by, paginate) => {
        dispatch(setDisplayLoader("Display"));
        SkillGroupService.getSkillSubGroup(
            handleKey(search, pageNo, page_size, sort_by, paginate)
        )
            .then((response) => {
                dispatch(setDisplayLoader("Hide"));
                setSubGroupList(response?.data?.dataList?.result);
                setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };
    const addDepartment = () => {
        setShowForm(true);
        setEditMode(false);
        setSubGroupData(null);
    };
    const onView = (data) => {
        setViewForm(true);
        setViewFormData(data);
    };
    const editDepartment = (eData) => {
        setShowForm(true);
        setEditMode(true);
        setSubGroupData(eData);
    };
    const onClose = () => {
        setShowForm(false);
        setSubGroupData(null);
    };
    const onViewClose = () => {
        setViewForm(false);
    };
    const onAddCallBack = () => {
        setShowForm(false);
        getSubGroup("", currentPage, pageSizeNo, sortData.sort_by, true);
    };
    const deleteGroup = (idx) => {
        SkillGroupService.deleteSkillSubGroup(idx)
            .then((res) => {
                if (res.data.status === true) {
                    getSubGroup("", currentPage, pageSizeNo, sortData.sort_by, true);
                    toast.success(res.data.message);
                }
            })
            .catch((error) => {
                toast.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };
    const submit = (id, name) => {
        console.log(id)
        confirmAlert({
            title: "Delete Department",
            message: `Are you sure to delete ${name}?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteGroup(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };
    const handlePageClick = (action) => {
        let activePage = action.selected + 1;
        setCurrentPage(activePage);
        getSubGroup(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
    };
    const closeButtonCallBack = () => {
        let closeBar = document.getElementById("searchbar");
        if (closeBar.value != "") {
            closeBar.value = "";
            let data = "";
            setSearchStr("");
            getSubGroup(data, 1, pageSizeNo, sortData.sort_by, true);
        }
    };
    const handleSearch = (e) => {
        getSubGroup(e.target.value.toLowerCase(), 1, pageSizeNo, "-id", true);
        setSearchStr(e.target.value);
    };
    const closeModal = () => {
        importsetShow(false);
        setDownloadButton(false);
    };
    const getSkillPermission = () => {
        let query = "/skill-tab";
        SkillService.getPermissions(query)
            .then((res) => {
                let findIndex = res.data.dataList.findIndex(el => el.name == "Can Add Skill Tab Access")
                if (findIndex != -1) {
                    setShowSkillTab(true)
                }
            })
            .catch((err) => console.log(err));
        SkillService.getSkillPermission()
            .then((response) => {
                setPermissionAccess(response?.data?.dataList);
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };
    useEffect(() => {
        getSkillPermission();
        getGroup("", currentPage, pageSizeNo, sortData.sort_by, true);
        getSubGroup(searchStr, currentPage, pageSizeNo, sortData.sort_by, true);
    }, [pageSizeNo]);
    useEffect(() => {
        getSkillPermission();
    }, [pageSizeNo]);
    return (
        <>
            <div>
                <ToastContainer />
                <div class="row">
                    <div class="col-md-3">
                        <ul class="policylistnames">
                            {/* <CommonLinks/> */}
                            <li>
                                <Link to="/skill">Home</Link>
                            </li>
                            {permissionAccess?.report_view === true && (
                                <li>
                                    <Link to="/skill-report">Report</Link>
                                </li>
                            )}
                            {permissionAccess?.skill_department_view === true && (
                                <li>
                                    <Link to="/skill-department">Department Master</Link>
                                </li>
                            )}
                            {permissionAccess?.skill_plant_view === true && (
                                <li>
                                    <Link to="/skill-plant">Plant Master</Link>
                                </li>
                            )}
                            {permissionAccess?.skill_list_view === true && (
                                <li>
                                    <Link to="/skill-master">Skill Master</Link>
                                </li>
                            )}
                            {permissionAccess?.skill_group_view === true && (
                                <li>
                                    <Link to="/skill-group">Skill Group Master</Link>
                                </li>
                            )}
                            {permissionAccess?.skill_sub_group_view === true && (
                                <li class="active">
                                    <Link to="/skill-sub-group">Sub Group Master</Link>
                                </li>
                            )}
                            {permissionAccess?.group_owner_mapping_view === true && (
                                <li>
                                    <Link to="/skill-group-mapping">Skill Group Mapping</Link>
                                </li>
                            )}
                            {permissionAccess?.quarterly_report_view === true && (
                                <li>
                                    <Link to="/skill-quarterly">Quarterly Reports</Link>
                                </li>
                            )}
                            {permissionAccess?.skill_matrix_usage_view === true && (
                                <li>
                                    <Link to="/skill-usage">Skill Matrix Usage</Link>
                                </li>
                            )}
                            {permissionAccess?.user_group_mapping_view === true && (
                                <li>
                                    <Link to="/skill-user">User Group Mapping</Link>
                                </li>
                            )}
                            {permissionAccess?.skill_location_view === true && (
                                <li>
                                    <Link to="/skill-location">Skill Location Access</Link>
                                </li>
                            )}
                            {
                                showSkillTab && (
                                    <li>
                                        <Link to="/skill-tab">Skill Tab Access</Link>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <div class="col-md-9">
                        <div class="content-wrapper-inner content-wrapper-inner2">
                            <div class="innerheadsec">
                                <div className="row">
                                    <div class="col-md-12">
                                        <h4 class="inner-page-title">Sub-Group-Master</h4>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="border-top mb-3"></div>
                                    </div>
                                </div>
                                {
                                    permissionAccess?.skill_sub_group_update === true && (
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="inline-spacing btn-betweenspaing">
                                                    <button
                                                        className="btn btn-primary-inner"
                                                        onClick={() => {
                                                            addDepartment();
                                                        }}
                                                    >
                                                        <img
                                                            src="images/upload.png"
                                                            alt=""
                                                            className="mr-3"
                                                        />
                                                        Add New
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped table-bordered tablecured">
                                    <thead>
                                        <tr>
                                            <th>Sub Group Code</th>
                                            <th>Sub Group Name</th>
                                            <th>Parent Group</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subGroupList?.length ? (
                                            subGroupList?.map((data, index) => (
                                                <>
                                                    <tr key={`desg${index}`}>
                                                        <td>{data?.group_id}</td>
                                                        <td className="text-theme fw-bold">{data?.group_name}</td>
                                                        <td>{data?.parent_group?.group_name}</td>
                                                        <td>
                                                            {data?.is_active ? (
                                                                <span className="badge bad-status badge-success">
                                                                    Active
                                                                </span>
                                                            ) : (
                                                                <span className="badge bad-status badge-danger">
                                                                    InActive
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-blanktd text-primary"
                                                                onClick={() => {
                                                                    onView(data);
                                                                }}
                                                            >
                                                                <img src="images/eye.png" alt="" />
                                                            </button>
                                                            {permissionAccess?.skill_list_update === true && (
                                                                <>
                                                                    <button
                                                                        className="btn btn-blanktd text-primary ml-2"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            editDepartment(data);
                                                                        }}
                                                                    >
                                                                        <i className="far fa-edit"></i>
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-blanktd text-danger ml-2"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            submit(data?.id, data?.group_id);
                                                                        }}
                                                                    >
                                                                        <i className="far fa-trash-alt text-danger"></i>
                                                                    </button>
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={12}> No Data Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {subGroupList?.length == 0 ? (
                                ""
                            ) : (
                                <div className="row">
                                    <div className="col-sm-5">
                                        <div className="sortinglist">
                                            Show
                                            <select
                                                className="form-control"
                                                onChange={(e) => {
                                                    setPageSizeNo(e.target.value);
                                                }}
                                            >
                                                <option value={10} label={10} />
                                                <option value={20} label={20} />
                                                <option value={30} label={30} />
                                                <option value={100} label={100} />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={2}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination justify-content-left"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
            {
                showForm ? (
                    <>
                        <AddSubGroup
                            edit={editMode}
                            showForm={showForm}
                            onClose={onClose}
                            editSkills={subGroupData}
                            onAddCallBack={onAddCallBack}
                            masterGroup={parentGroupList}
                            masterGroupOption={parentGroupOption}
                        />
                    </>
                ) : (
                    ""
                )
            }
            {
                viewForm ? (
                    <>
                        <ViewSubGroup
                            viewForm={viewForm}
                            onViewClose={onViewClose}
                            viewFormData={viewFormData}
                        />
                    </>
                ) : (
                    ""
                )
            }
        </>
    );
}
export default SubGroupMaster;