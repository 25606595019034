import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import SubItemForm from "./modals/sub-item-from";
import * as PrOrgService from "../../service/purchaseOrg";
import * as PrService from "../../service/prService";
import * as PrPurchaseGroup from "../../service/purchaseGroup";
import * as UserService from "../../service/userService";
import { toast } from "react-toastify";
import { prMasterCodes } from "../../constants/Constants";
import { selectUserData } from "../redux/piDataStore";
import { useSelector } from "react-redux";
import moment from "moment/moment";
const CreatePrForm = ({
  formIndex,
  createFlag,
  formik,
  SUB_ITEM_INITIAL,
  prTypeOptions,
  key,
  accountAssignmentValues,
  subitemflag,
  subItemIndex,
  handleSubItemCloseModal,
  createSubItemFlag,
  handleSubItemCreate,
  price,
  rejectFlag,
  setPrice,
  editMode,
  editIndex,
  itemNO,
  sapPrNo,
}) => {
  const userData = useSelector(selectUserData);
  const [purchaseOrgOptions, setPurchaseOrgOptions] = useState([]);
  const [purchaseGroupsOptions, setPurchaseGroupsOptions] = useState([]);
  const [plantOptions, setPlantOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [materialGroupOptions, setMaterialGroupOptions] = useState([]);
  const [assetCodeOptions, setAssetCodeOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [materialCodeOptions, setMaterialCodeOptions] = useState([]);
  const [materialCodes, setMaterialCodes] = useState([]);
  const [networkOptions, setNetworkOptions] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState(null);
  const [glOptions, setGlOptions] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [accountAssignmentOptions, setAccountAssignmentOptions] = useState([]);
  const [wbsElementOptions, setWbsElementOptions] = useState([]);
  const [valuationDisable, setValuationDisable] = useState(true);
  const [valuationOptions, setValuationOptions] = useState([]);
  const [materialStockDetails, setMaterialStockDetails] = useState([]);
  const [editableVendor, setEditableVendor] = useState(false);
  const getPurchaseOrgs = () => {
    PrOrgService.getReleaseCode("paginate=false&pr_flag=true")
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.desc}(${item.code})`,
          }));
          setPurchaseOrgOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.message);
      });
  };
  const getOrderNo = (index, plant_id, account_assignment) => {
    let plant = { ...getSelectFeilds(plantOptions, index, "plant_id") };
    let account = {
      ...getSelectFeilds(accountAssignmentOptions, index, "account_assignment"),
    };
    console.log(plant_id, account_assignment);
    if (plant_id == undefined && account_assignment == undefined) {
      if (
        account?.value?.value != undefined &&
        (account?.value?.value == "F" || account?.value?.value == "N")
      ) {
        if (plant?.value?.value != undefined) {
          getOrders(plant?.value?.value);
          handleOpen();
        } else {
          toast.warning("Kindly Select Account Assignment and Plant ");
        }
      } else {
        handleOpen();
      }
    } else {
      if (
        account_assignment != undefined &&
        (account_assignment == "F" || account_assignment == "N")
      ) {
        if (plant_id != undefined) {
          getOrders(plant_id);
          // handleOpen();
        } else {
          toast.warning("Kindly Select Account Assignment and Plant ");
        }
      }
    }
  };
  const getPurchaseGroups = () => {
    PrPurchaseGroup.getReleaseCode("paginate=false&pr_flag=true")
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.pgdesc}(${item.pgcode})`,
          }));
          setPurchaseGroupsOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPlants = () => {
    PrPurchaseGroup.getPlants({ organisation_id: "undefined" })
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.description}(${item.code})`,
            code: `${item.code}`,
          }));
          setPlantOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVendors = (search, vendor_id, code, desc) => {
    PrService.getVendorsList(search)
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.name}(${item.code}) -- ${item.gst !== null ? item.gst : ""
              } -- ${item.location_name !== null ? item.location_name : ""}`,
          }));
          if (
            vendor_id !== undefined &&
            code !== undefined &&
            desc !== undefined
          ) {
            let vendor_obj = {
              value: vendor_id,
              label: desc + "(" + code + ")",
            };
            options.push(vendor_obj);
          }
          setVendorOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePlantChange = (index) => {
    setFieldValue(`${formIndex}.${"valuation"}`, "");
    setFieldValue(`${formIndex}.${"delivery_date"}`, "");
    setFieldValue(`${formIndex}.${"hsn_code"}`, "");
    setFieldValue(`${formIndex}.${"material_group"}`, "");
    setFieldValue(`${formIndex}.${"material_desc"}`, "");
    setFieldValue(`${formIndex}.${"material_code"}`, "");
    setFieldValue(`${formIndex}.${"pr_unit_id_name"}`, "");
  };

  const getMaterialGroups = (searchpr, id, name, desc) => {
    const plantId = values[formIndex].plant_id;
    const search = searchpr === undefined ? "" : searchpr;
    if (!plantId) return;
    PrService.getMaterialListByPlant({ plant_id: plantId, search: search })
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item?.id,
            label: `${item?.description}(${item.code})`,
            code: item?.code,
          }));
          const options2 = list.map((item) => ({
            value: item?.id,
            label: `${item?.description}(${item.code}) ${item?.cas_no !== null ? ('-' + item?.cas_no) : ""}`,
            code: item?.code,
            material_type: item?.material_type,
          }));
          // setMaterialGroupOptions(options);
          if (id !== undefined) {
            let mat_obj = { value: id, label: desc + "(" + name + ")" };
            options2.push(mat_obj);
          }
          setMaterialCodeOptions(options2);
          setMaterialCodes(list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMaterial = () => {
    PrService.getMaterialGroup()
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item?.id,
            label: `${item?.description}(${item.code})`,
            code: item?.code,
          }));

          setMaterialGroupOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPrUnits = () => {
    PrService.getPrUnits("paginate=false")
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list
            .filter((x) => {
              if (x?.unit_desc !== null) {
                return true;
              }
            })
            .map((item) => ({
              value: item.id,
              label: `${item.unit_desc}(${item.unit_code})`,
            }));
          setUnitOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGlCodes = async () => {
    try {
      const glRes = await PrService.getGlCodeList();
      if (glRes.data.code === 200) {
        const list = glRes?.data?.dataList?.result;
        const options = list.map((item) => ({
          value: item.id,
          label: `${item.gl_account}(${item.short_desc})`,
        }));
        setGlOptions(options);
      } else {
        toast.warn(glRes.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.warn(error.message);
    }
  };

  const getCostCenters = () => {
    UserService.getCostCenter(0)
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.name}(${item.code})`,
          }));
          setCostCenterOptions(options);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getNetworks = () => {
    PrService.getNetworks()
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.desc}(${item.code})`,
          }));
          setNetworkOptions(options);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getAssetCodes = (search, asset_id, asset_name) => {
    PrService.getAssetCodes(search)
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.desc}(${item.code})`,
          }));
          if (asset_id !== undefined && asset_id !== null) {
            let asset_obj = { value: asset_id, label: asset_name }
            options.push(asset_obj)
          }
          setAssetCodeOptions(options);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getWbsElements = () => {
    PrService.getWbsElements()
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.desc}(${item.code})`,
          }));
          setWbsElementOptions(options);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getOrders = (plant_id) => {
    PrService.getOrders(plant_id)
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.description}(${item.order})`,
          }));
          setOrderOptions(options);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  /* parent formik */
  const { errors, values, setValues, setFieldValue, touched, getFieldProps } =
    formik;
  /* isolated states */
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    handleSubItemCloseModal();
  };

  const getSelectFeilds = (options, index, key) => {
    return {
      value: options
        ? options.find((option) => option.value === values[index][key])
        : "",
      onChange: (option) => {
        setFieldValue(`${index}.${key}`, option?.value);
        if (key === "plant_id") {
          setFieldValue(`${index}.${"plant_name"}`, option?.code);
          setFieldValue(`${formIndex}.${"valuation"}`, "");
          setFieldValue(`${formIndex}.${"delivery_date"}`, "");
          setFieldValue(`${formIndex}.${"hsn_code"}`, "");
          setFieldValue(`${formIndex}.${"material_group"}`, "");
          setFieldValue(`${formIndex}.${"material_desc"}`, "");
          setFieldValue(`${formIndex}.${"material_code"}`, "");
          setFieldValue(`${formIndex}.${"pr_unit_id_name"}`, "");
          setFieldValue(`${formIndex}.${"pr_unit_id"}`, "");
          if (values[0].pr_type === "T") {
            getOrderNo(formIndex);
          }
        }
        if (key === "material_code") {
          console.log("option", option);
          setFieldValue(`${index}.${"material_code_name"}`, option?.code);
          setFieldValue(`${index}.${"material_id"}`, option?.value);
        }
        if (key === "purchase_group_id") {
          setFieldValue(`${index}.${"purchase_group_name"}`, option?.label);
        }
        if (key === "pr_unit_id") {
          setFieldValue(`${index}.${"pr_unit_id_name"}`, option?.value);
        }
      },
    };
  };
  const getSelectFeildsLabel = (options, index, key) => {
    return {
      value: options
        ? options.find((option) => option.value === values[index][key])
        : "",
      onChange: (option) => setFieldValue(`${index}.${key}`, option?.code),
    };
  };

  const handleRemove = () => {
    if (values.length === 0) return;
    const temp = values;
    temp.splice(formIndex, 1);
    setValues([...temp]);
  };

  const handlePrType = ({ value }) => {
    if (Array.isArray(values)) {
      values.map((nodata, index) => {
        setFieldValue(
          `${index}.pr_type`,
          value
        ); /* syncing all pr_types to the same */
      });
    }
  };

  const handleMaterialCodeChange = (option, index) => {
    let exist_flag = values?.map((res) => {
      if (res?.material_id === option?.value) {
        return true;
      } else {
        return false;
      }
    })
    // if(exist_flag.includes(true)){
    //   toast.warn("Duplicate Material Code")
    //   setFieldValue(`${index}.material_code`, option?.value);
    //   setFieldValue(`${index}.material_id`, option?.value);
    //   setFieldValue(`${index}.material_code_name`, option?.code);
    // }
    // else{
    getMaterialPrice(option.value, index);
    setValuationDisable(false);
    const data = materialCodes.find((item) => item.id == option.value);
    console.log("dataattattatata", data);
    setFieldValue(`${index}.material_code`, option?.value);
    setFieldValue(`${index}.material_id`, option?.value);
    setFieldValue(`${index}.material_code_name`, option?.code);
    setFieldValue(`${index}.pr_unit_id_name`, data.unit);
    setFieldValue(`${index}.pr_unit_id`, data?.unit_id);

    setFieldValue(`${index}.material_group_name`, data.group);
    setFieldValue(`${index}.material_group`, data.mat_group_id);
    setFieldValue(`${index}.material_desc`, data.description);
    setFieldValue(`${index}.valuation_type`, data.valuation_type);
    setFieldValue(`${index}.hsn_code`, data.hsn_code);
    setFieldValue(`${index}.cas`, data.cas_no);
    if (data.delivery_days === null) {
      setFieldValue(`${index}.delivery_date`, moment().format("YYYY-MM-DD"));
    } else {
      getMaterialPrice(option.value, index);
      setValuationDisable(false);
      const data = materialCodes.find((item) => item.id == option.value);
      console.log("dataattattatata", data);
      setFieldValue(`${index}.material_code`, option?.value);
      setFieldValue(`${index}.material_id`, option?.value);
      setFieldValue(`${index}.material_code_name`, option?.code);
      setFieldValue(`${index}.pr_unit_id_name`, data.unit);
      setFieldValue(`${index}.pr_unit_id`, data?.unit_id);

      setFieldValue(`${index}.material_group_name`, data.group);
      setFieldValue(`${index}.material_group`, data.mat_group_id);
      setFieldValue(`${index}.material_desc`, data.description);
      setFieldValue(`${index}.valuation_type`, data.valuation_type);
      setFieldValue(`${index}.hsn_code`, data.hsn_code);
      setFieldValue(`${index}.cas`, data.cas_no);
      if (data.delivery_days === null) {
        setFieldValue(`${index}.delivery_date`, moment().format("YYYY-MM-DD"));
      } else {
        setFieldValue(
          `${index}.delivery_date`,
          moment(new Date())
            .add(data.delivery_days, "days")
            .format("YYYY-MM-DD")
        );
      }
      if (
        data.material_type === "ERSA" &&
        (data.valuation_category === "P" || data.valuation_category === "H")
      ) {
        let obj = ["DAMAGED", "GOOD", "REPAIRED"];
        setValuationOptions(obj);
      } else if (
        data.material_type === "ROH" &&
        (data.valuation_category === "P" || data.valuation_category === "H")
      ) {
        let obj = ["DOMESTIC", "DUTYFREE", "DUTYPAID", "JOBWORK"];
        setValuationOptions(obj);
      }
      getMaterialStock(option.value);
    }
    if (
      data.material_type === "ERSA" &&
      (data.valuation_category === "P" || data.valuation_category === "H")
    ) {
      let obj = ["DAMAGED", "GOOD", "REPAIRED"];
      setValuationOptions(obj);
    } else if (
      data.material_type === "ROH" &&
      (data.valuation_category === "P" || data.valuation_category === "H")
    ) {
      let obj = ["DOMESTIC", "DUTYFREE", "DUTYPAID", "JOBWORK"];
      setValuationOptions(obj);
    }
    getMaterialStock(option.value);

  };

  const getMaterialStock = (material_id) => {
    PrService.getMaterialStock(material_id).then((response) => {
      let obj = response?.data?.dataList?.result;
      if (obj.length > 0) {
        setMaterialStockDetails(response?.data?.dataList?.result);
        // setPrice(obj[0]?.price)
      }
    });
  };

  const getMaterialPrice = (material_id, index) => {
    PrService.getMaterialPrice(material_id).then((response) => {
      let obj = response?.data?.dataList?.result;
      if (obj.length > 0) {
        setFieldValue(`${index}.valuation`, obj[0]?.price);
        // setPrice(obj[0]?.price)
      }
    });
  };

  const handleAccountAssignmentChange = (option, index) => {
    setFieldValue(`${index}.account_assignment`, option?.value);
    if (option.value === accountAssignmentValues.asset) {
      if (assetCodeOptions.length === 0) getAssetCodes("");
    } else if (option.value === accountAssignmentValues.network) {
      if (networkOptions.length === 0) getNetworks();
    }
    // else if (option.value === accountAssignmentValues.order) {
    //   console.log();
    //   // if (orderOptions.length === 0){
    //     let plant= {...getSelectFeilds(plantOptions, index, 'plant_id')}
    //     console.log(plant?.value?.value);
    //     // getOrders(plant?.value?.value);
    //   // }
    // }
  };

  useEffect(() => {
    getMaterialGroups(
      "",
      values[formIndex]?.material_id,
      values[formIndex]?.material_code_name,
      values[formIndex]?.material_desc
    );
  }, [values[formIndex].plant_id]);

  useEffect(() => {
    getPurchaseOrgs();
    getPurchaseGroups();
    getPlants();
    getVendors(
      "",
      values[formIndex]?.suggested_vendor_id,
      values[formIndex]?.vendor_code,
      values[formIndex]?.vendor_name
    );
    getPrUnits();
    getCostCenters();
    getGlCodes();
    getMaterial();
    getMaterial()
    if (editMode === true) {
      getOrderNo(formIndex, values[formIndex]?.plant_id, values[formIndex]?.account_assignment)
      getAssetCodes('', values[formIndex]?.asset_code_id, values[formIndex]?.asset_code_name)
      setFieldValue(`${formIndex}.${"material_code_name"}`, values[formIndex]?.material_code_name)
      setFieldValue(`${formIndex}.${"material_id"}`, values[formIndex]?.material_id)
      setFieldValue(`${formIndex}.${"material_code"}`, values[formIndex]?.material_id)
      setFieldValue(`${formIndex}.${"order_no_id"}`, values[formIndex]?.order_no_id)
      setFieldValue(`${formIndex}.${"pr_unit_id"}`, values[formIndex]?.pr_unit_id)
      setFieldValue(`${formIndex}.${"pr_unit_id_name"}`, values[formIndex]?.pr_unit_id_name)
      setFieldValue(`${formIndex}.${"material_group"}`, values[formIndex]?.material_group)
      setFieldValue(`${formIndex}.${"material_group_name"}`, values[formIndex]?.material_group_name)
    }
    if (subitemflag === true) {
      getOrderNo(
        subItemIndex,
        values[subItemIndex]?.plant_id,
        values[subItemIndex]?.account_assignment
      );
    }
  }, []);

  useEffect(() => {
    switch (values[0].pr_type) {
      case prMasterCodes.textPr:
        setAccountAssignmentOptions([
          { value: "A", label: "Asset" },
          { value: "F", label: "Order" },
          { value: "K", label: "Cost center" },
          { value: "N", label: "Network" },
        ]);
        break;
      case prMasterCodes.projectPr:
        setAccountAssignmentOptions([
          { value: "Q", label: "Proj make-to-order" },
        ]);
        if (wbsElementOptions.length === 0) getWbsElements();
        break;
      case prMasterCodes.assetPr:
        setFieldValue(`${formIndex}.${"asset_code_id"}`, values[0]?.asset_code_id);
        setAccountAssignmentOptions([{ value: "A", label: "Asset" }]);
        if (assetCodeOptions.length === 0) getAssetCodes('', values[formIndex]?.asset_code_id, values[formIndex]?.asset_code_name);
        break;
      default: /* service pr case */
        setAccountAssignmentOptions([
          { value: "F", label: "Order" },
          { value: "K", label: "Cost center" },
          { value: "N", label: "Network" },
        ]);
        break;
    }
  }, [values[0].pr_type]);
  const checkCreate = () => { };
  return (
    <div key={key}>
      <div className="bg-light px-3 pt-3 mb-3">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group innergroup">
              <label>
                PR Type<span className="text-danger">*</span>
              </label>
              <Select
                onClick={checkCreate}
                isDisabled={values[0].pr_type}
                options={prTypeOptions}
                classNamePrefix="select"
                className="basic-multi-select"
                {...getSelectFeilds(prTypeOptions, formIndex, "pr_type")}
                onChange={handlePrType}
              />
              <div className="small text-danger">
                {touched[formIndex]?.pr_type && errors[formIndex]?.pr_type}
              </div>
            </div>
          </div>
        </div>

        {values[0].pr_type && (
          <>
            <div className="row">
              {[prMasterCodes.servicePr].includes(
                values[formIndex].pr_type
              ) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Item of Requisition <span className="text-danger">*</span>
                      </label>
                      <input
                        {...getFieldProps(`item_no`)}
                        type="number"
                        disabled
                        value={(formIndex + 1) * 10}
                        name={`${formIndex}.item_no`}
                        className="form-control"
                        placeholder="Enter Item of Requisition"
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.item_no &&
                          errors[formIndex]?.item_no}
                      </div>
                    </div>
                  </div>
                )}

              {[prMasterCodes.servicePr].includes(
                values[formIndex].pr_type
              ) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Item Category<span className="text-danger">*</span>
                      </label>
                      <select
                        disabled={rejectFlag}
                        className="form-control newbgselect"
                        {...getFieldProps(`${formIndex}.item_category`)}
                      >
                        <option value="">Select</option>
                        <option value="Service PR">Service PR</option>
                      </select>
                      <div className="small text-danger">
                        {touched[formIndex]?.item_category &&
                          errors[formIndex]?.item_category}
                      </div>
                    </div>
                  </div>
                )}

              {[
                prMasterCodes.servicePr,
                prMasterCodes.textPr,
                prMasterCodes.projectPr,
                prMasterCodes.assetPr,
              ].includes(values[formIndex].pr_type) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Account Assignment <span className="text-danger">*</span>
                      </label>
                      <Select
                        isDisabled={rejectFlag}
                        options={accountAssignmentOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        {...getSelectFeilds(
                          accountAssignmentOptions,
                          formIndex,
                          "account_assignment"
                        )}
                        onChange={(option) =>
                          handleAccountAssignmentChange(option, formIndex)
                        }
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.account_assignment &&
                          errors[formIndex]?.account_assignment}
                      </div>
                    </div>
                  </div>
                )}

              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Purchasing Org<span className="text-danger">*</span>
                  </label>
                  <Select
                    isDisabled={rejectFlag}
                    options={purchaseOrgOptions}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    {...getSelectFeilds(
                      purchaseOrgOptions,
                      formIndex,
                      "purchase_org_id"
                    )}
                  />
                  <div className="small text-danger">
                    {touched[formIndex]?.purchase_org_id &&
                      errors[formIndex]?.purchase_org_id}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Purchasing Group <span className="text-danger">*</span>
                  </label>
                  <Select
                    isDisabled={rejectFlag}
                    options={purchaseGroupsOptions}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    {...getSelectFeilds(
                      purchaseGroupsOptions,
                      formIndex,
                      "purchase_group_id"
                    )}
                  />
                  <div className="small text-danger">
                    {touched[formIndex]?.purchase_group_id &&
                      errors[formIndex]?.purchase_group_id}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Plant<span className="text-danger">*</span>
                  </label>
                  <Select
                    isDisabled={rejectFlag}
                    options={plantOptions}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    {...getSelectFeilds(plantOptions, formIndex, "plant_id")}
                  // {...getSelectFeildsLabel(plantOptions, formIndex, "plant_name")}
                  // onChange={(e)=>{handlePlantChange(formIndex)}}
                  />
                  <div className="small text-danger">
                    {touched[formIndex]?.plant_id &&
                      errors[formIndex]?.plant_id}
                  </div>
                </div>
              </div>

              {[
                prMasterCodes.standardPr,
                prMasterCodes.assetPr,
                prMasterCodes.projectPr,
              ].includes(values[formIndex].pr_type) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Material Code <span className="text-danger">*</span>
                      </label>
                      <Select
                        isDisabled={rejectFlag}
                        options={materialCodeOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        {...getSelectFeilds(
                          materialCodeOptions,
                          formIndex,
                          "material_code"
                        )}
                        onChange={(option) =>
                          handleMaterialCodeChange(option, formIndex)
                        }
                        onInputChange={(e) => {
                          getMaterialGroups(
                            // formObj?.plant?.value,
                            e,
                            "input"
                          );
                        }}
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.material_code &&
                          errors[formIndex]?.material_code}
                      </div>
                    </div>
                  </div>
                )}

              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Material Group<span className="text-danger">*</span>
                  </label>
                  {[
                    prMasterCodes.standardPr,
                    prMasterCodes.projectPr,
                    prMasterCodes.assetPr,
                  ].includes(values[formIndex].pr_type) ? (
                    <input
                      disabled
                      value={values[formIndex].material_group_name}
                      type="text"
                      className="form-control"
                    // placeholder="Enter Unit"
                    />
                  ) : (
                    <Select
                      isDisabled={
                        [
                          prMasterCodes.standardPr,
                          prMasterCodes.projectPr,
                          prMasterCodes.assetPr,
                        ].includes(values[formIndex].pr_type) || false
                      }
                      options={materialGroupOptions}
                      classNamePrefix="select"
                      className="basic-multi-select"
                      {...getSelectFeilds(
                        materialGroupOptions,
                        formIndex,
                        "material_group"
                      )}
                    />
                  )}

                  <div className="small text-danger">
                    {touched[formIndex]?.material_group &&
                      errors[formIndex]?.material_group}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Material Description <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={
                      [
                        prMasterCodes.standardPr,
                        prMasterCodes.projectPr,
                        prMasterCodes.assetPr,
                      ].includes(values[formIndex].pr_type) || false
                    }
                    type="text"
                    className="form-control"
                    placeholder="Enter Material Description"
                    {...getFieldProps(`${formIndex}.material_desc`)}
                  />
                  <div className="small text-danger">
                    {touched[formIndex]?.material_desc &&
                      errors[formIndex]?.material_desc}
                  </div>
                </div>
              </div>

              {[
                prMasterCodes.standardPr,
                prMasterCodes.assetPr,
                prMasterCodes.textPr,
                prMasterCodes.projectPr,
              ].includes(values[formIndex].pr_type) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Unit <span className="text-danger">*</span>
                      </label>
                      {[
                        prMasterCodes.standardPr,
                        prMasterCodes.projectPr,
                        prMasterCodes.assetPr,
                      ].includes(values[formIndex].pr_type) ? (
                        <input
                          disabled
                          value={values[formIndex].pr_unit_id_name}
                          type="text"
                          className="form-control"
                          placeholder="Enter Unit"
                        />
                      ) : (
                        <Select
                          isDisabled={rejectFlag}
                          options={unitOptions}
                          classNamePrefix="select"
                          className="basic-multi-select"
                          {...getSelectFeilds(
                            unitOptions,
                            formIndex,
                            "pr_unit_id"
                          )}
                        />
                      )}
                      <div className="small text-danger">
                        {touched[formIndex]?.pr_unit_id &&
                          errors[formIndex]?.pr_unit_id}
                      </div>
                    </div>
                  </div>
                )}

              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Suggested Vendor <span className="text-danger"></span>
                  </label>
                  {editableVendor ||
                    (values[formIndex]?.new_vendor !== null &&
                      values[formIndex]?.new_vendor !== undefined) ? (
                    <>
                      <input
                        // disabled={rejectFlag}
                        type="text"
                        className="form-control"
                        placeholder="Enter Vendor Code"
                        {...getFieldProps(`${formIndex}.new_vendor`)}
                      />
                      {values[formIndex]?.new_vendor === null ||
                        (values[formIndex]?.new_vendor === undefined && (
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => {
                              setEditableVendor(false);
                            }}
                          >
                            Exist Vendor
                          </button>
                        ))}
                    </>
                  ) : (
                    <>
                      <Select
                        isDisabled={rejectFlag}
                        options={vendorOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        {...getSelectFeilds(
                          vendorOptions,
                          formIndex,
                          "suggested_vendor_id"
                        )}
                        onInputChange={(e) => {
                          getVendors(e);
                        }}
                      />
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                          setEditableVendor(true);
                        }}
                      >
                        New Vendor
                      </button>
                    </>
                  )}
                  <div className="small text-danger">
                    {touched[formIndex]?.suggested_vendor_id &&
                      errors[formIndex]?.suggested_vendor_id}
                  </div>
                </div>
              </div>

              {[
                prMasterCodes.standardPr,
                prMasterCodes.assetPr,
                prMasterCodes.textPr,
                prMasterCodes.projectPr,
              ].includes(values[formIndex].pr_type) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        HSN Code <span className="text-danger">*</span>
                      </label>
                      <input
                        disabled={rejectFlag}
                        // disabled={
                        //   [
                        //     prMasterCodes.standardPr,
                        //     prMasterCodes.projectPr,
                        //     prMasterCodes.assetPr,
                        //   ].includes(values[formIndex].pr_type) ||
                        //   false
                        // }
                        type="text"
                        className="form-control"
                        placeholder="Enter HSN Code"
                        {...getFieldProps(`${formIndex}.hsn_code`)}
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.hsn_code &&
                          errors[formIndex]?.hsn_code}
                      </div>
                    </div>
                  </div>
                )}

              {/* {[prMasterCodes.standardPr].includes(
                values[formIndex].pr_type
              ) && (
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Cas No.
                    </label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="Enter CAS No."
                      {...getFieldProps(`${formIndex}.cas`)}
                    />
                    <div className="small text-danger">
                      {touched[formIndex]?.cas && errors[formIndex]?.cas}
                    </div>
                  </div>
                </div>
              )} */}

              {/* <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Is Capex<span className="text-danger">*</span>
                  </label>
                  <select className="form-control newbgselect" {...getFieldProps(`${formIndex}.is_capex`)}>
                    <option value="">Select</option>
                    <option value="True">Yes</option>
                    <option value="False">No</option>
                  </select>
                  <div className="small text-danger">
                    {touched[formIndex]?.is_capex && errors[formIndex]?.is_capex}
                  </div>
                </div>
              </div> */}

              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Tracking No.<span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={rejectFlag}
                    min={0}
                    type="text"
                    className="form-control"
                    placeholder="Enter Tracking No."
                    {...getFieldProps(`${formIndex}.tracking_no`)}
                  />
                  <div className="small text-danger">
                    {touched[formIndex]?.tracking_no &&
                      errors[formIndex]?.tracking_no}
                  </div>
                </div>
              </div>

              {[prMasterCodes.textPr, prMasterCodes.servicePr].includes(
                values[formIndex].pr_type
              ) &&
                values[formIndex].account_assignment ===
                accountAssignmentValues.order && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Order Category Code{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        disabled={rejectFlag}
                        className="form-control newbgselect"
                        {...getFieldProps(`${formIndex}.order_category`)}
                      >
                        <option value="">Select</option>
                        <option value="Maintenance Order">
                          Maintenance Order
                        </option>
                        <option value="Internal order">Internal order</option>
                      </select>
                      <div className="small text-danger">
                        {touched[formIndex]?.order_category &&
                          errors[formIndex]?.order_category}
                      </div>
                    </div>
                  </div>
                )}

              {[prMasterCodes.textPr].includes(values[formIndex].pr_type) &&
                values[formIndex].account_assignment ===
                accountAssignmentValues.order && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Order <span className="text-danger"></span>
                      </label>
                      <Select
                        isDisabled={rejectFlag}
                        options={orderOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        {...getSelectFeilds(
                          orderOptions,
                          formIndex,
                          "order_no_id"
                        )}
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.order_no_id &&
                          errors[formIndex]?.order_no_id}
                      </div>
                    </div>
                  </div>
                )}

              {[
                prMasterCodes.textPr,
                prMasterCodes.projectPr,
                prMasterCodes.assetPr,
              ].includes(values[formIndex].pr_type) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        G/L Account <span className="text-danger"></span>
                      </label>
                      <Select
                        isDisabled={rejectFlag}
                        options={glOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        {...getSelectFeilds(
                          glOptions,
                          formIndex,
                          "gl_account_id"
                        )}
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.gl_account_id &&
                          errors[formIndex]?.gl_account_id}
                      </div>
                    </div>
                  </div>
                )}

              {[prMasterCodes.textPr].includes(values[formIndex].pr_type) &&
                (values[formIndex].account_assignment === "K" ||
                  values[formIndex].account_assignment === "F") && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Cost Center <span className="text-danger"></span>
                      </label>
                      <Select
                        isDisabled={rejectFlag}
                        options={costCenterOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        {...getSelectFeilds(
                          costCenterOptions,
                          formIndex,
                          "cost_center_id"
                        )}
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.cost_center_id &&
                          errors[formIndex]?.cost_center_id}
                      </div>
                    </div>
                  </div>
                )}

              {[prMasterCodes.textPr].includes(values[formIndex].pr_type) &&
                values[formIndex].account_assignment ===
                accountAssignmentValues.network && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Network No. <span className="text-danger"></span>
                      </label>
                      <Select
                        isDisabled={rejectFlag}
                        options={orderOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        {...getSelectFeilds(
                          networkOptions,
                          formIndex,
                          "network_no_id"
                        )}
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.network_no_id &&
                          errors[formIndex]?.network_no_id}
                      </div>
                    </div>
                  </div>
                )}
              {[prMasterCodes.projectPr].includes(
                values[formIndex].pr_type
              ) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        WBS Element <span className="text-danger"></span>
                      </label>
                      {/* <Select
                      options={wbsElementOptions}
                      classNamePrefix="select"
                      className="basic-multi-select"
                      {...getSelectFeilds(wbsElementOptions, formIndex, 'wbs_element_id')}
                    /> */}
                      <input
                        disabled={rejectFlag}
                        min={0}
                        // type="number"
                        className="form-control"
                        placeholder="Enter WBS Element"
                        {...getFieldProps(`${formIndex}.wbs_element_id`)}
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.wbs_element_id &&
                          errors[formIndex]?.wbs_element_id}
                      </div>
                    </div>
                  </div>
                )}

              {([prMasterCodes.assetPr].includes(values[formIndex].pr_type) ||
                ([prMasterCodes.textPr].includes(values[formIndex].pr_type) &&
                  values[formIndex].account_assignment ===
                  accountAssignmentValues.asset)) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Asset Code<span className="text-danger"></span>
                      </label>
                      <Select
                        isDisabled={rejectFlag}
                        options={assetCodeOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        {...getSelectFeilds(
                          assetCodeOptions,
                          formIndex,
                          "asset_code_id"
                        )}
                        onInputChange={(e) => {
                          getAssetCodes(e);
                        }}
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.asset_code_id &&
                          errors[formIndex]?.asset_code_id}
                      </div>
                    </div>
                  </div>
                )}

              {[
                prMasterCodes.standardPr,
                prMasterCodes.assetPr,
                prMasterCodes.textPr,
                prMasterCodes.projectPr,
              ].includes(values[formIndex].pr_type) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Requisition Qty<span className="text-danger">*</span>
                      </label>
                      <input
                        disabled={rejectFlag}
                        min={0}
                        type="text"
                        className="form-control"
                        placeholder="Enter asset code"
                        {...getFieldProps(`${formIndex}.requisition_quantity`)}
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.requisition_quantity &&
                          errors[formIndex]?.requisition_quantity}
                      </div>
                    </div>
                  </div>
                )}

              {[
                prMasterCodes.standardPr,
                prMasterCodes.assetPr,
                prMasterCodes.textPr,
                prMasterCodes.projectPr,
              ].includes(values[formIndex].pr_type) && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Price (per unit)<span className="text-danger">*</span>
                      </label>
                      <input
                        disabled={rejectFlag}
                        min={0}
                        type="text"
                        className="form-control"
                        placeholder="Enter price"
                        {...getFieldProps(`${formIndex}.valuation`)}
                      />
                      <div className="small text-danger">
                        {touched[formIndex]?.valuation &&
                          errors[formIndex]?.valuation}
                      </div>
                    </div>
                  </div>
                )}

              {[prMasterCodes.standardPr].includes(values[formIndex].pr_type) &&
                valuationOptions?.length > 0 && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Valuation<span className="text-danger"></span>
                      </label>
                      {/* <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="Enter valuation type"
                      {...getFieldProps(`${formIndex}.valuation_type`)}
                    /> */}
                      <select
                        class="form-control newbgselect"
                        disabled={valuationDisable || rejectFlag}
                        {...getFieldProps(`${formIndex}.valuation_type`)}
                      // value={shiftDataFilter?.personal_sub_area}
                      >
                        <option>Select</option>
                        {valuationOptions?.map((res) => {
                          return <option value={res} label={res}></option>;
                        })}
                      </select>
                      <div className="small text-danger">
                        {touched[formIndex]?.valuation_type &&
                          errors[formIndex]?.valuation_type}
                      </div>
                    </div>
                  </div>
                )}

              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Delivery date<span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={rejectFlag}
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    className="form-control"
                    placeholder="Enter Delivery date"
                    {...getFieldProps(`${formIndex}.delivery_date`)}
                  />
                  <div className="small text-danger">
                    {touched[formIndex]?.delivery_date &&
                      errors[formIndex]?.delivery_date}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    PR Item Text<span className="text-danger"></span>
                  </label>
                  <textarea
                    disabled={false}
                    className="form-control"
                    placeholder="Enter PR Item Text"
                    {...getFieldProps(`${formIndex}.remarks`)}
                  ></textarea>
                  <div className="small text-danger">
                    {touched[formIndex]?.remarks && errors[formIndex]?.remarks}
                  </div>
                </div>
              </div>
            </div>
            {values[0]?.pr_type !== "S" && values[0]?.pr_type !== "T" && (
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured viewpo">
                  <thead>
                    <tr>
                      <th>plant</th>
                      <th>Stock Type</th>
                      <th>Unrestricted</th>
                      <th>Quality Stock</th>
                      <th>Block Stocked</th>
                    </tr>
                  </thead>
                  <tbody>
                    {materialStockDetails?.length > 0 ? (
                      materialStockDetails?.map((data) => {
                        return (
                          <tr>
                            <td>{data?.plant}</td>
                            <td>{data?.stock_type}</td>
                            <td>{data?.unres_stock}</td>
                            <td>{data?.qual_stock}</td>
                            <td>{data?.block_stock}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>No Record Found...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <div className="pb-4">
              <div className="row">
                <div className="col-md-12 mt-3 text-center">
                  {values.length > 1 && values[formIndex]?.status == "A" && (
                    <span
                      className="btn btn-outline-danger mr-2"
                      onClick={handleRemove}
                    >
                      Remove
                    </span>
                  )}
                  {values[formIndex].pr_type == prMasterCodes.servicePr &&
                    (rejectFlag === undefined || rejectFlag === false) && (
                      <span
                        className="btn btn-outline-primary mr-2"
                        onClick={() => {
                          getOrderNo(formIndex);
                        }}
                      >
                        Add Sub-Item
                      </span>
                    )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {console.log("SUB_ITEM_INITIAL", SUB_ITEM_INITIAL, subitemflag)}
      {values[formIndex].pr_type == prMasterCodes.servicePr && (
        <SubItemForm
          handleClose={handleClose}
          open={open}
          formIndex={subitemflag === false ? formIndex : subItemIndex}
          formikProps={formik}
          SUB_ITEM_INITIAL={SUB_ITEM_INITIAL}
          glOptions={glOptions}
          getGlCodes={getGlCodes}
          costCenterOptions={costCenterOptions}
          getCostCenters={getCostCenters}
          orderOptions={orderOptions}
          networkOptions={networkOptions}
          getOrders={getOrders}
          unitOptions={unitOptions}
          getPrUnits={getPrUnits}
          subitemflag={subitemflag}
          subItemIndex={subItemIndex}
          handleSubItemCloseModal={handleSubItemCloseModal}
          createSubItemFlag={createSubItemFlag}
          sapPrNo={sapPrNo}
          itemNO={itemNO}
        />
      )}
    </div>
  );
};

export default CreatePrForm;
