import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { useFormik } from "formik";
import * as cashbookService from "../../service/cashbookService";
import { toast } from "react-toastify";
import { ToastContainer } from "react-bootstrap";
import moment from "moment";
import { selectFinalUrlPermission, selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

const expenseDetailInitialValue = {
  item_no: null,
  expense_gl_id: null,
  hsn: null,
  uom: undefined,
  quantity: null,
  credit: null,
  tax_rate: null,
  tax_code: null,
  base_amount: null,
  tax_amount: 0,
  total_amount: 0,
};

const initialValues = {
  vendor_id: null,
  gst_state: null,
  pi_gst_no: null,
  invoice_no: null,
  invoice_date: null,
  cost_center_id: null,
  total_amount: 0,
  expense_detail: [expenseDetailInitialValue],
};

const validationSchema = Yup.object({
  vendor_id: Yup.number().required("*Required").nullable(),
  // gst_state: Yup.number().required("*Required").nullable(),
  invoice_no: Yup.string()
    .matches(/^\S*$/, "This field cannot contain only blank spaces")
    // .required("*Required")
    .nullable(),
  invoice_date: Yup.date().required("*Required").nullable(),
  cost_center_id: Yup.number().required("*Required").nullable(),
  // total_amount: Yup.number().required("*Required").nullable(),
  // pi_gst_no: Yup.string().required("*Required").nullable(),
  expense_detail: Yup.array().of(
    Yup.object().shape({
      item_no: Yup.string().required("*Required").nullable(),

      expense_gl_id: Yup.string().required("*Required").nullable(),
      // hsn: Yup.string().required("*Required").nullable(),
      // uom: Yup.string().required("*Required").nullable(),
      quantity: Yup.number()
        .typeError('"Must be a number"')
        .min(0, "Cannot contain negative Values")
        // .matches(/^\S*$/, "This field cannot contain only blank spaces")
        .required("*Required")
        .nullable(),
      credit: Yup.string().required("*Required").nullable(),
      tax_rate: Yup.number().required("*Required").nullable(),
      // tax_code: Yup.string()
      //   .matches(/^\S*$/, "This field cannot contain only blank spaces")
      //   .required("*Required")
      //   .nullable(),
      base_amount: Yup.number()
        .typeError('"Must be a number"')
        .min(0, "Cannot contain negative Values")
        // .matches(/^\S*$/, "This field cannot contain only blank spaces")
        .required("*Required")
        .nullable(),
      tax_amount: Yup.number().required("*Required").nullable(),
      // total_amount: Yup.number().required("*Required").nullable(),
    })
  ),
});

const AddExpenses = (props) => {
  const { setActiveTab } = props;
  const [vendors, setVendors] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [hsnCodes, setHsnCodes] = useState([]);
  const [expensesGl, setExpensesGl] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [placeGstOptions, setPlaceGstOptions] = useState([]);
  const costCenterOptions = useMemo(
    () =>
      costCenters.map((item) => ({
        value: item.id,
        label: `${item.cc_desc}-${item.cost_center}`,
      })),
    [costCenters]
  );
  const vendorOptions = useMemo(
    () => vendors.map((item) => ({ value: item.id, label: item.name + "(" + item?.state_name + "-" + item?.gst_state + ")" })),
    [vendors]
  );
  const hsnOptions = useMemo(
    () =>
      hsnCodes.map((item) => ({
        value: item.code,
        label: `${item.desc}(${item.code})`,
      })),
    [hsnCodes]
  );
  const expensesGlOptions = useMemo(
    () =>
      expensesGl.map((item) => ({
        value: item.id,
        label: `${item.desc}(${item.gl_code})`,
      })),
    [expensesGl]
  );
  const uomOptions = [{ value: "Others", label: "Others" }];
  const gstCreditOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const taxRateOptios = [
    { value: 0, label: "0%", code: "V0" },
    { value: 5, label: "5%", code: "UA" },
    { value: 12, label: "12%", code: "FC" },
    { value: 18, label: "18%", code: "FF" },
    { value: 28, label: "28%", code: "FH" },
  ];
  const userDetails = useSelector(selectUserData);
  const currentDate = new Date();
  let finalDate = moment(currentDate).format("Do MMM YYYY");
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setValues }) => {
      setSubmitting(true);
      try {
        var credit_yes = values?.expense_detail.some(item => item.credit === "Yes");
        if (credit_yes && values?.pi_gst_no === null) {
          toast.error("Kindly Select Business Place GST No.");
        }
        else{
        values.total_amount = totalAmount;
        const response = await cashbookService.addCashbook(values);
        if (response.data.code === 200) {
          toast.success("Expense Added Sucessfully");
          setActiveTab("purchase");
        } else {
          toast.warn(response.data.message);
        }
      }
    }
       catch (error) {
        toast.warn(error.message);
      } finally {
        if(credit_yes === false){
        setValues({ ...initialValues });
        resetForm();
        }
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    handleSubmit,
    setValues,
    errors,
    getFieldProps,
    setFieldValue,
    touched,
    isSubmitting,
  } = formik;

  const totalAmount = useMemo(() => {
    if (Array.isArray(values.expense_detail)) {
      return values.expense_detail.reduce(
        (acc, cur) => acc + cur.total_amount,
        0
      );
    }
  }, [values.expense_detail]);

  const getSelectFeildProps = (options, key) => {
    return {
      value: options
        ? options.find((option) => option.value === values[key])
        : "",
      onChange: (option) => setFieldValue(key, option?.value),
    };
  };
  const getMasterData = (paginate) => {
    cashbookService
      .getMasterData(paginate)
      .then((response) => {
        setMasterData(response?.data?.dataList?.result);
        let cashbook_id = response?.data?.dataList?.result[0]?.id
        getGstForCashbook(cashbook_id)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getGstForCashbook = (cashbook_id) => {
    cashbookService.get_cashbook_gst(cashbook_id).then((response)=>{
        let obj = response?.data?.dataList?.result?.map((data)=>{
          return {
            value: data?.gst_no,
            label: `${data?.gst_no}-${data?.state}`
          }
        })
        setPlaceGstOptions(obj)
    })
  }

  const getSelectFeildPropsByIndex = (options, index, key) => {
    return {
      value: options
        ? options.find(
            (option) => option.value === values?.expense_detail?.[index]?.[key]
          )
        : "",
      onChange: (option) =>
        setFieldValue(`expense_detail.${index}.${key}`, option?.value),
    };
  };

  const handleAddForm = () => {
    const expense_detail = values.expense_detail;
    for (const item of expense_detail) {
      if (Object.values(item).filter((e) => e === null).length > 0) {
        return toast.warn("please fill item form to move futher");
      }
    }
    setFieldValue("expense_detail", [
      ...values.expense_detail,
      expenseDetailInitialValue,
    ]);
  };

  const handleRemoveForm = (index) => {
    const temp = values.expense_detail;
    temp.splice(index, 1);
    setValues({ ...values, ...{ expense_detail: [...temp] } });
  };

  const getVendors = () => {
    // let state=0
    // if(userDetails?.gst_details?.length > 0){
    // state = userDetails?.gst_details[0]?.state_name_is
    // }
    // else{
    //   state=0
    // }
    cashbookService
      .getVendorList("paginate=false")
      .then((response) => {
        if (response.data.code === 200) {
          setVendors(response?.data?.dataList?.result);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getCostCenters = () => {
    cashbookService
      .getCostCenterList()
      .then((response) => {
        if (response.data.code === 200) {
          setCostCenters(response?.data?.dataList?.result);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getHsnCodes = () => {
    cashbookService
      .getHsnCodes("paginate=false")
      .then((response) => {
        if (response.data.code === 200) {
          setHsnCodes(response?.data?.dataList?.result);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getExpensesGl = () => {
    cashbookService
      .getExpensesGl("paginate=false")
      .then((response) => {
        if (response.data.code === 200) {
          setExpensesGl(response?.data?.dataList?.result);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const fillDropdowns = () => {
    getHsnCodes();
    getExpensesGl();
  };
  useEffect(() => {
    if (userDetails?.gst_details) {
      let data = userDetails?.gst_details?.map((obj) => {
        return {
          label: `${obj?.gst_no}-${obj?.state_name_is}`,
          value: obj?.gst_no,
        };
      });
      // setPlaceGstOptions(data);
    }
  }, [userDetails]);

  useEffect(() => {
    const selectedVendor = vendors.find(
      (vendor) => vendor.id == values.vendor_id
    );
    if (selectedVendor) {
      setFieldValue("gst_type", selectedVendor.gst_type);
      setFieldValue("gst_state", selectedVendor.state_name);
      setFieldValue("gst_no", selectedVendor.gst_no);
      setFieldValue("vendor_address", selectedVendor.vendor_address);
    }
  }, [values.vendor_id]);

  useEffect(() => {
    getCostCenters();
    getVendors();
    getMasterData(userDetails?.id);
    fillDropdowns();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} key={props.activeTab}>
        <ToastContainer autoClose={1000} />
        <div className="px-4 pb-2">
          <div className="tickethistorbg mt-2">
            <div className="row viewformaftersubmit">
              <div className="col-md-3">
                <div className="innergroup">
                  <p>Cashbook </p>
                  <label> {masterData[0]?.desc}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="innergroup">
                  <p>Opening Balance</p>
                  <label>
                    <i className="fas fa-rupee-sign mr-1"></i> INR{" "}
                    {masterData[0]?.opening_balance.toFixed(2)}
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="innergroup">
                  <p>Current Balance</p>
                  <label>
                    <i className="fas fa-rupee-sign mr-1"></i> INR{" "}
                    {masterData[0]?.current_balance.toFixed(2)}
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="innergroup">
                  <p>Provision Balance</p>
                  <label>
                    <i className="fas fa-rupee-sign mr-1"></i> INR{" "}
                    {masterData[0]?.provision_balance.toFixed(2)}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>Vendor</label>
                <span className="text-danger">*</span>
                <Select
                  options={vendorOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  {...getSelectFeildProps(vendorOptions, "vendor_id")}
                />
                <div className="small text-danger">
                  {touched?.[`vendor_id`] && errors?.[`vendor_id`]}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>GST State</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={values.gst_state}
                  placeholder="Enter GST Type"
                />
                {/* <Select
                  disabled
                  options={options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  {...getSelectFeildProps(options, "gst_state")}
                />
                <div className="small text-danger">
                  {
                    touched?.[`gst_state`]
                    && errors?.[`gst_state`]
                  }
                </div> */}
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>GST Type</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={values.gst_type}
                  placeholder="Enter GST Type"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>GST No</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={values.gst_no}
                  placeholder="Enter GST No"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>Address</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={values.vendor_address}
                  placeholder="Enter Address"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>Business Place GST No</label>
                {
                    values?.expense_detail.some(item => item?.credit === "Yes") && (
                      <span className="text-danger">*</span>
                    )
                  }
                <Select
                  options={placeGstOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  {...getSelectFeildProps(placeGstOptions, "pi_gst_no")}
                />
                <div className="small text-danger">
                  {touched?.[`pi_gst_no`] && errors?.[`pi_gst_no`]}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>Invoice No</label>
                <span className="text-danger"></span>
                <input
                  type="text"
                  maxLength={15}
                  className="form-control"
                  placeholder="Enter Invoice No"
                  {...getFieldProps("invoice_no")}
                />
                <div className="small text-danger">
                  {touched?.[`invoice_no`] && errors?.[`invoice_no`]}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>Invoice Date</label>
                <span className="text-danger">*</span>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Enter Invoice Date"
                  max={moment(new Date()).format("YYYY-MM-DD")}
                  {...getFieldProps("invoice_date")}
                />
                <div className="small text-danger">
                  {touched?.[`invoice_date`] && errors?.[`invoice_date`]}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Expense Cost Centre<span className="text-danger">*</span>
                </label>
                <Select
                  options={costCenterOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  {...getSelectFeildProps(costCenterOptions, "cost_center_id")}
                />
                <div className="small text-danger">
                  {touched?.[`cost_center_id`] && errors?.[`cost_center_id`]}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>Amount</label>
                <input
                  type="text"
                  disabled
                  value={totalAmount}
                  className="form-control"
                  placeholder="Enter Amount"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-2 ml-4">
          <h6 className="text-theme mb-0">
            <i className="far fa-arrow-al t-circle-right"></i>{" "}
            <strong>Items</strong>
          </h6>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured viewpo">
            <thead>
              <tr>
                <th>Action</th>
                <th>Item</th>
                <th>Expense GL</th>
                <th>HSN Code</th>
                <th>UOM</th>
                <th>Quantity</th>
                <th>GST Credit</th>
                <th>Tax Rate</th>
                <th>Tax Code</th>
                <th>Base Amount</th>
                <th>Tax Amount</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(values.expense_detail) &&
                values.expense_detail.map((item, i) => {
                  return item.item_no ? (
                    <tr key={`expense_detail-${i}`}>
                      <td>
                        {/* <span className="btn btn-blanktd text-primary f-12">
                      <i className="far fa-edit"></i>
                    </span> */}
                        {values.expense_detail.length > 1 && (
                          <span
                            className="btn btn-blanktd text-danger ml-2 f-12"
                            onClick={() => handleRemoveForm(i)}
                          >
                            <i className="far fa-trash-alt text-danger"></i>
                          </span>
                        )}
                      </td>
                      <td>
                        <span className="badge badge-secondary">
                          {item.item_no}
                        </span>
                      </td>
                      <td>{item.expense_gl}</td>
                      <td>{item.hsn}</td>
                      <td>{item.uom}</td>
                      <td>{item.quantity}</td>
                      <td>{item.credit}</td>
                      <td>{item.tax_rate}%</td>
                      <td>{item.tax_code}</td>
                      <td>{item.base_amount}</td>
                      <td>{item.tax_amount}</td>
                      <td>{item.total_amount}</td>
                    </tr>
                  ) : (
                    <></>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="px-4 pb-2">
          <div className="row align-items-center">
            <div className="col-md-12 text-sm-right">
              <button
                className="btn btnblanktd text-primary mb-3"
                onClick={handleAddForm}
              >
                <i className="far fa-plus-square mr-2"></i> Add Item More
              </button>
            </div>
          </div>
          <div className="bg-light px-3 pt-3 mb-3">
            {values?.expense_detail?.map((item, index) => {
              return (
                <div
                  key={`expense-form-${
                    (index + 1) * values?.expense_detail?.length
                  }`}
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>Item</label>{" "}
                        <span className="text-danger">*</span>
                        <input
                          type="text"
                          maxLength={250}
                          {...getFieldProps(`expense_detail.${index}.item_no`)}
                          className="form-control"
                          placeholder="Enter Item"
                        />
                        <div className="small text-danger">
                          {touched?.[`expense_detail`]?.[index]?.[`item_no`] &&
                            errors?.[`expense_detail`]?.[index]?.[`item_no`]}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>Expense GL</label>{" "}
                        <span className="text-danger">*</span>
                        <Select
                          options={expensesGlOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          {...getSelectFeildPropsByIndex(
                            expensesGlOptions,
                            index,
                            "expense_gl_id"
                          )}
                        />
                        <div className="small text-danger">
                          {touched?.[`expense_detail`]?.[index]?.[
                            `expense_gl_id`
                          ] &&
                            errors?.[`expense_detail`]?.[index]?.[
                              `expense_gl_id`
                            ]}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>HSN Code</label>
                        <Select
                          options={hsnOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          {...getSelectFeildPropsByIndex(
                            hsnOptions,
                            index,
                            "hsn"
                          )}
                        />
                        <div className="small text-danger">
                          {touched?.[`expense_detail`]?.[index]?.[`hsn`] &&
                            errors?.[`expense_detail`]?.[index]?.[`hsn`]}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>UOM</label>
                        <Select
                          options={uomOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          {...getSelectFeildPropsByIndex(
                            uomOptions,
                            index,
                            "uom"
                          )}
                        />
                        <div className="small text-danger">
                          {touched?.[`expense_detail`]?.[index]?.[`uom`] &&
                            errors?.[`expense_detail`]?.[index]?.[`uom`]}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Quantity<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          maxlength="15"
                          className="form-control"
                          placeholder="Enter Quantity"
                          {...getFieldProps(`expense_detail.${index}.quantity`)}
                        />
                        <div className="small text-danger">
                          {touched?.[`expense_detail`]?.[index]?.[`quantity`] &&
                            errors?.[`expense_detail`]?.[index]?.[`quantity`]}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          GST Credit<span className="text-danger">*</span>
                        </label>
                        <Select
                          options={gstCreditOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          {...getSelectFeildPropsByIndex(
                            gstCreditOptions,
                            index,
                            "credit"
                          )}
                        />
                        <div className="small text-danger">
                          {touched?.[`expense_detail`]?.[index]?.[`credit`] &&
                            errors?.[`expense_detail`]?.[index]?.[`credit`]}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Tax Rate<span className="text-danger">*</span>
                        </label>
                        <Select
                          options={taxRateOptios}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          {...getSelectFeildPropsByIndex(
                            taxRateOptios,
                            index,
                            "tax_rate"
                          )}
                          onChange={(option) => {
                            let taxAmount = 0;
                            let totalAmount = 0;
                            if (
                              !isNaN(values.expense_detail[index].base_amount)
                            ) {
                              taxAmount =
                                values.expense_detail[index].base_amount *
                                (option.value / 100);
                              totalAmount =
                                Number(
                                  values.expense_detail[index].base_amount
                                ) + Number(taxAmount);
                            }
                            setFieldValue(
                              `expense_detail.${index}.tax_rate`,
                              option?.value
                            );
                            setFieldValue(
                              `expense_detail.${index}.tax_code`,
                              option?.code
                            );
                            setFieldValue(
                              `expense_detail.${index}.total_amount`,
                              totalAmount
                            );
                            setFieldValue(
                              `expense_detail.${index}.tax_amount`,
                              taxAmount
                            );
                          }}
                        />
                        <div className="small text-danger">
                          {touched?.[`expense_detail`]?.[index]?.[`tax_rate`] &&
                            errors?.[`expense_detail`]?.[index]?.[`tax_rate`]}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>Tax Code</label>
                        <input
                          {...getFieldProps(`expense_detail.${index}.tax_code`)}
                          type="text"
                          disabled
                          maxLength={15}
                          className="form-control"
                          placeholder="Enter Tax Code"
                        />
                        {/* <div className="small text-danger">
                          {touched?.[`expense_detail`]?.[index]?.[`tax_code`] &&
                            errors?.[`expense_detail`]?.[index]?.[`tax_code`]}
                        </div> */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Base Amount<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          maxlength="15"
                          className="form-control"
                          placeholder="Enter Base Amount"
                          {...getFieldProps(
                            `expense_detail.${index}.base_amount`
                          )}
                          onChange={({ target: { value } }) => {
                            let taxAmount = 0;
                            let totalAmount = 0;
                            if (!isNaN(values.expense_detail[index].tax_rate)) {
                              taxAmount =
                                value *
                                (values.expense_detail[index].tax_rate / 100);
                              totalAmount = Number(value) + Number(taxAmount);
                            }
                            setFieldValue(
                              `expense_detail.${index}.base_amount`,
                              value
                            );
                            setFieldValue(
                              `expense_detail.${index}.total_amount`,
                              totalAmount
                            );
                            setFieldValue(
                              `expense_detail.${index}.tax_amount`,
                              taxAmount
                            );
                          }}
                        />
                        <div className="small text-danger">
                          {touched?.[`expense_detail`]?.[index]?.[
                            `base_amount`
                          ] &&
                            errors?.[`expense_detail`]?.[index]?.[
                              `base_amount`
                            ]}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>Tax Amount</label>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          placeholder="Enter Tax Amount"
                          {...getFieldProps(
                            `expense_detail.${index}.tax_amount`
                          )}

                          // value={
                          //   (values?.[`expense_detail`]?.[index]?.[
                          //     `base_amount`
                          //   ] /
                          //     100) *
                          //   values?.[`expense_detail`]?.[index]?.[`tax_rate`]
                          // }
                        />
                        {/* <div className="small text-danger">
                          {touched?.[`expense_detail`]?.[index]?.[
                            `tax_amount`
                          ] &&
                            errors?.[`expense_detail`]?.[index]?.[`tax_amount`]}
                        </div> */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>Total Amount</label>
                        <input
                          type="text"
                          disabled
                          {...getFieldProps(
                            `expense_detail.${index}.total_amount`
                          )}
                          className="form-control"
                          placeholder="Enter Total Amount"
                        />
                      </div>
                    </div>
                  </div>
                  {values.expense_detail.length > 1 && (
                    <center>
                      <span
                        className="btn btn-outline-danger mb-3 "
                        onClick={() => handleRemoveForm(index)}
                      >
                        Remove
                      </span>
                    </center>
                  )}
                </div>
              );
            })}
          </div>

          <div className="mb-3">
            <div className="row">
              <div className="col-md-12 mt-3 text-center">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Add to Cashbook
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddExpenses;
