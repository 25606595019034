import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidemenu from "../../include/sidemenu";
import Header from "../../include/header";
import { Modal, Breadcrumb, Dropdown, Pagination } from "react-bootstrap";
import Select from "react-select";
import AddSubPolicy from "./AddPolicySubCategory";
import ReactPaginate from "react-paginate";
import ViewPolicySubCategory from "./ViewPolicySubCategory";
import * as PolicyCategoryService from "../../../service/policyCategory";
import { confirmAlert } from "react-confirm-alert";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import { Formik } from "formik";
import * as moment from "moment";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { injectStyle } from "react-toastify/dist/inject-style";
if (typeof window !== "undefined") {
  injectStyle();
}

function PolicySub() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [pageCount, setpageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [policyCategoryList, setPolicyCategoryList] = useState([]);
  const [policyCategoryData, setPolicyCategoryData] = useState([]);
  const [policyCategory, setPolicyCategory] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [allPolicyCategory, setAllpolicyCategory] = useState([]);
  const [dropPolicyCategory, setDropPolicyCategory] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [allOrg, setAllOrg] = useState([]);
  const [isSelectOrganization, setSelectOrganization] = useState(false);
  const [policyCategoryDataForExport, setPolicyCategoryDataForExport] =
    useState({
      policy_category_id: "",
      category_id: "",
      status: "",
    });
  const [showForm, setShowForm] = useState(false);
  const [policyCategoryDataFilter, setPolicyCategoryDataFilter] = useState({
    policy_category_id: "",
    category_id: "",
    status: "",
  });
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const getOrganisation = (paginate) => {
    PolicyCategoryService.getOrganisation(paginate)
      .then((response) => {
        setAllOrg(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPolicyCategory = (
    search,
    policy_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    PolicyCategoryService.getPolicySubCategory(
      search,
      policy_category_id,
      status,
      page_no,
      page_size,
      sort_by,
      paginate
    )
      .then((response) => {
        setPolicyCategoryList(response?.data?.dataList?.result);
        setPolicyCategoryData(response?.data?.dataList?.paginated_data);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deletePolicyCategory = (idx) => {
    // let dataId = { id: idx };
    PolicyCategoryService.deletePolicySubCategory(idx)
      .then((res) => {
        if (res.data.status == true) {
          getPolicyCategory(currentPage, "-id", "", true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const submit = (id, deptName) => {
    confirmAlert({
      title: "Delete Policy Sub Category",
      message: `Are you sure to delete ${deptName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deletePolicyCategory(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addPolicyCategory = (edata) => {
    setShowForm(true);
    setEditMode(false);
    setPolicyCategory(edata);
  };
  const editPolicyCategory = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setPolicyCategory(eData);
  };
  const onClose = () => {
    setShowForm(false);
    setPolicyCategory(null);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getPolicyCategory(
      searchStr,
      policyCategoryDataFilter.policy_category_id,
      policyCategoryDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getPolicyCategory(
      searchStr,
      policyCategoryDataFilter.policy_category_id,
      policyCategoryDataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    setCurrentPage(activePage);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getPolicyCategory(
        e.target.value.toLowerCase(),
        policyCategoryDataFilter.policy_category_id,
        policyCategoryDataFilter.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(policyCategoryDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setPolicyCategoryDataFilter(ClearData);
    filterClose();
    getPolicyCategory(
      // searchStr,
      // policyCategoryDataFilter.category_id,
      // policyCategoryDataFilter.policy_category_id,
      // policyCategoryDataFilter.status,
      // 1,
      // pageSizeNo,
      // sortData.sort_by,
      // true
      searchStr,
      policyCategoryDataFilter.policy_category_id,
      policyCategoryDataFilter.status,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const filterClose = () => {
    filterclose();
  };

  useEffect(() => {
    getPolicyCategory(
      searchStr,
      policyCategoryDataFilter.policy_category_id,
      policyCategoryDataFilter.status,
      currentPage,
      pageSizeNo,
      "-id",
      true
    );
  }, [pageSizeNo]);

  // const getModule = (paginate) => {
  //   PolicyCategoryService.getModule(paginate)
  //     .then((response) => {
  //       setAllpolicyCategory(response?.data?.dataList?.result);
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };
  const getAllPolicyCategory = (paginate) => {
    PolicyCategoryService.getAllPolicyCategory(paginate)
      .then((response) => {
        setDropPolicyCategory(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const filterShow = () => {
    filteropen();
    getAllPolicyCategory(false);
    getOrganisation(false);
  };

  const mainDataFilter = (policyCategoryDataFilter) => {
    setPolicyCategoryDataForExport({
      ...policyCategoryDataForExport,
      policy_category_id: policyCategoryDataFilter.policy_category_id,
      category_id: policyCategoryDataFilter.category_id,
      status: policyCategoryDataFilter.status,
    });
    // const org = policyCategoryDataFilter.category_id;
    // const dept = policyCategoryDataFilter.policy_category_id;
    // const stats = policyCategoryDataFilter.status;
    getPolicyCategory(
      searchStr,
      policyCategoryDataFilter.policy_category_id,
      policyCategoryDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };

  const closeButtonCallBack = () => {
    console.log("pagecount", pageCount);
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getPolicyCategory(
        data,
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getPolicyCategory(
      searchStr,
      policyCategoryDataFilter.policy_category_id,
      policyCategoryDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  const rendePolicyOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Policy Sub Category</h4>
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on <strong> Sub Category Name,</strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Sub Category Name..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addPolicyCategory(policyCategoryList);
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                ) : (
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addPolicyCategory(policyCategoryList);
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["name"]
                  }
                >
                  Sub Category Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("category_id__name");
                  }}
                  className={
                    sortData["category_id__name"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["category_id__name"]
                  }
                >
                  Policy Category Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_by");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_by"]
                  }
                >
                  Last Updated By
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_at");
                  }}
                  className={
                    sortData["updated_at"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_at"]
                  }
                >
                  Last Updated On
                </th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                {finalUrlValue?.V || finalUrlValue?.E || finalUrlValue?.R ? (
                  <th>Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {policyCategoryList?.length ? (
                policyCategoryList?.map((data, index) => (
                  <>
                    <tr key={`policyCategory${index}`}>
                      <td className="text-theme fw-bold">{data?.name}</td>
                      <td>{data?.category_name}</td>
                      <td>{data?.updated_by}</td>
                      <td>
                        {" "}
                        {moment
                          .utc(data?.updated_at)
                          .format("DD-MM-YYYY,HH:mm")}
                      </td>
                      <td>
                        {data?.is_active ? (
                          <span className="badge bad-status badge-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bad-status badge-danger">
                            InActive
                          </span>
                        )}
                      </td>
                      {finalUrlValue?.V ||
                      finalUrlValue?.E ||
                      finalUrlValue?.R ? (
                        <td>
                          {finalUrlValue?.V ? (
                            <button
                              type="button"
                              className="btn btn-blanktd text-primary"
                              onClick={() => {
                                onView(data);
                              }}
                            >
                              <img src="images/eye.png" alt="" />
                            </button>
                          ) : (
                            ""
                          )}
                          {finalUrlValue?.E ? (
                            <button
                              className="btn btn-blanktd text-primary ml-2"
                              onClick={() => {
                                editPolicyCategory(data);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            </button>
                          ) : (
                            ""
                          )}

                          {finalUrlValue?.R ? (
                            <button
                              className="btn btn-blanktd text-danger ml-2"
                              onClick={() => {
                                submit(data?.id, data?.name);
                              }}
                            >
                              <i className="far fa-trash-alt text-danger"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {policyCategoryList?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control bgselect main-pointer"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                disabledClassName={"disabled"}
              />
            </div>
          </div>
        )}
        {/* <Pagination>
                            <Pagination.Item onClick={() => getNextPrevPage(0)}>
                                <i className="fa fa-angle-left"></i>
                            </Pagination.Item>
                            <Pagination.Item className="main-pointer-remove">
                                <span>
                                    {currentPage}/ {policyCategoryData?.totalPages}
                                </span>
                            </Pagination.Item>
                            <Pagination.Item
                                onClick={() => getNextPrevPage(1)}
                            >
                                <i className="fa fa-angle-right"></i>
                            </Pagination.Item>
                        </Pagination> */}

        {/* <Pagination>
                    <Pagination.Prev className="previpag" />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{9}</Pagination.Item>
                    <Pagination.Item>{10}</Pagination.Item>
                    <Pagination.Next className="previpag" />
                  </Pagination> */}
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Select Policy Category<span class="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect main-pointer"
              classNamePrefix="select"
              style={{ width: "334px" }}
              name="policy_category_id"
              onChange={(e) => {
                setPolicyCategoryDataFilter({
                  ...policyCategoryDataFilter,
                  policy_category_id: e.target.value,
                });
              }}
              value={policyCategoryDataFilter?.policy_category_id}
            >
              <option
                className="form-control newbgselect main-pointer"
                value=""
                label="Select Policy Category"
                disabled
              />
              {rendePolicyOptions(dropPolicyCategory)}
            </select>
          </div>
          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setPolicyCategoryDataFilter({
                  ...policyCategoryDataFilter,
                  status: e.target.value,
                });
              }}
              value={policyCategoryDataFilter?.status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter(policyCategoryDataFilter)}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddSubPolicy
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editPolicyCategory={policyCategory}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {viewForm ? (
        <>
          <ViewPolicySubCategory
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default PolicySub;
