import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown, Tabs, Tab } from "react-bootstrap";
import * as HeaderService from "../../service/jv_service";
import * as permissionService from "../../service/header";
import ReactPaginate from "react-paginate";
import JvBulkImport from "./JvBulkImport";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { getOrganisation } from "../../service/businessService";
import * as AttendanceService from "../../service/AttendanceService";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { injectStyle } from "react-toastify/dist/inject-style";
import Select from "react-select";
import * as searchPoService from "../../service/searchpo";

if (typeof window !== "undefined") {
  injectStyle();
}

function Jv() {
  let dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [importshow, importsetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  const [dublicateOrgData, setDubOrgData] = useState([]);
  const [org, setOrg] = useState([]);
  const [draftListData, setDraftListData] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageCountReport, setPageCountReport] = useState(1);
  const [draftPageCount, setDraftPageCount] = useState(1);
  const [currentTab, setCurrentTab] = useState("DRAFT");
  const [allListData, setAllListData] = useState([]);
  const [allReportList, setAllReportList] = useState([]);
  const [checkedId, setCheckedId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [jvRmPermission, setJvRmPermission] = useState("");
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const [nextSapSyncingTime, setNextSapSyncingTime] = useState('')
  const [orgListValue, setOrgListValue] = useState([]);
  const [userListValue, setUserListValue] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [jvDataFilter, setJvDataFilter] = useState({
    status: "",
    end_date: "",
    start_date: "",
    sap_doc_no: "",
    user_id: "",
    org_id: "",
  });
  const [jvDataForExport, setJvDataForExport] = useState({
    status: "",
    end_date: "",
    start_date: "",
  });
  const [checkData, setCheckData] = useState({
    id_list: [0],
  });

  const handleViewRedirect = (jv_id, flag) => {
    navigate(`/viewjv/${jv_id}`, {
      state: { from: jv_id, flag: flag, data: currentTab },
    });
  };
  const handleEditRedirect = (id) => {
    if (id === null) {
      navigate("/addjv", {
        state: { id: "" },
      });
    } else {
      navigate("/addjv", {
        state: { id: id },
      });
    }
  };

  const { state } = useLocation();
  useEffect(() => {
    setCurrentTab(state?.data);
  }, [state]);

  const handleKey = (
    search,
    status,
    page_no,
    page_size,
    start_date,
    end_date,
    approverStatus
  ) => {
    let queryParm = "page_no=" + page_no + "&page_size=" + page_size;
    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }
    if (
      approverStatus !== "" &&
      approverStatus !== undefined &&
      approverStatus !== null
    ) {
      queryParm = queryParm + "&approverStatus=" + approverStatus;
    }

    return queryParm;
  };

  const handleKeyReport = (
    search,
    status,
    page_no,
    page_size,
    start_date,
    end_date,
    sap_doc_no,
    user_id,
    org_id,
    self_approval_data
  ) => {
    let queryParm = "page_no=" + page_no + "&page_size=" + page_size;
    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }
    if (sap_doc_no !== "" && sap_doc_no !== undefined && sap_doc_no !== null) {
      queryParm = queryParm + "&sap_doc_no=" + sap_doc_no;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (org_id !== "" && org_id !== undefined && org_id !== null) {
      queryParm = queryParm + "&org_id=" + org_id;
    }
    if (self_approval_data !== "" && self_approval_data !== undefined && self_approval_data !== null) {
      queryParm = queryParm + "&self_approval_data=" + self_approval_data;
    }

    return queryParm;
  };

  const getExportDetails = (status, jv_id) => {
    dispatch(setDisplayLoader("Display"));
    HeaderService.getExportDetails(status, jv_id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Jv.xlsx");
        document.body.appendChild(link);
        link.click();
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetailsReport = (
    status,
    start_date,
    end_date,
    search,
    sap_doc_no,
    user_id
  ) => {
    dispatch(setDisplayLoader("Display"));
    HeaderService.getExportDetailsReport(
      status,
      start_date,
      end_date,
      search,
      sap_doc_no,
      user_id
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Jv-report.xlsx");
        document.body.appendChild(link);
        link.click();
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getExportSummaryDetail = () => {
    dispatch(setDisplayLoader("Display"));
    HeaderService.getExportSummaryDetail()
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Jv-Summary-Report.xlsx");
        document.body.appendChild(link);
        link.click();
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getDraftList = (page_no, page_size, search) => {
    HeaderService.getDraftList(page_no, page_size, search)
      .then((response) => {
        setDraftListData(response?.data?.dataList?.result);
        setDraftPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const checkImportClose = () => {
    getDraftList(1, 10, "");
  };

  const saveMultiApprove = (data) => {
    if (data !== null && checkedId.length > 0) {
      checkData.id_list = checkedId;
      HeaderService.saveMultiApprove(checkData)
        .then((response) => {
          setCheckedId([]);
          setCheckData({
            id_list: [0],
          });
          getDraftList(1, 10, "");
          setCurrentTab("PENDING");
          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  };

  const saveInDraft = (data) => {
    HeaderService.saveInDraft(data)
      .then((response) => {
        toast.success(response.data.dataList.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getAllList = (
    search,
    status,
    page_no,
    page_size,
    start_date,
    end_date,
    approverStatus
  ) => {
    HeaderService.getAllList(
      handleKey(
        search,
        status,
        page_no,
        page_size,
        start_date,
        end_date,
        approverStatus
      )
    )
      .then((response) => {
        setAllListData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getReportAllList = (
    search,
    status,
    page_no,
    page_size,
    start_date,
    end_date,
    sap_doc_no,
    user_id,
    org_id,
    self_approval_data
  ) => {
    dispatch(setDisplayLoader("Display"));
    HeaderService.getReportAllList(
      handleKeyReport(
        search,
        status,
        page_no,
        page_size,
        start_date,
        end_date,
        sap_doc_no,
        user_id,
        org_id,
        self_approval_data
      )
    )
      .then((response) => {
        setAllReportList(response?.data?.dataList?.result);
        setPageCountReport(
          response?.data?.dataList?.paginated_data?.totalPages
        );
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updateCheckBoxValue = (e, value) => {
    let data = draftListData;
    data?.map((obj) => {
      if (Number(obj.id) === Number(value.id)) {
        obj.checkData = e.target.checked;
      }
    });
    if (e.target.checked) {
      setCheckedId([...checkedId, value.id]);
    } else {
      const index = checkedId.indexOf(value.id);
      if (index > -1) {
        checkedId.splice(index, 1);
      }
    }
  };

  const deleteMultiApprove = (data) => {
    if (data !== null && checkedId?.length > 0) {
      checkData.id_list = checkedId;
      HeaderService.deleteMultiApprove(checkData)
        .then((response) => {
          setCheckedId([]);
          setCheckData({
            id_list: [0],
          });
          getDraftList(1, 10, searchStr);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  };

  const getOrganisationList = () => {
    getOrganisation(true).then((response) => {
      let organisation = [];
      let organisation2 = [];

      response?.data?.dataList?.result.map((x) => {
        let data = { value: x.id, label: x.name };
        organisation.push(data);
        organisation2.push(data);
      });
      setDubOrgData(organisation2);
      let all = { value: "All", label: "All" };
      organisation.push(all);
      setOrg(organisation);
    });
  };

  const pushOrg = (e) => {
    setOrgListValue(e);
  };

  const handleAllData = (e) => {
    if (e.target.checked === true) {
      let data = [];
      let finalData = draftListData;
      finalData.map((fd, i) => {
        fd["checkData"] = e.target.checked;
        data.push(fd.id);
      });
      setCheckedId(data);
    } else {
      let finalData = draftListData;
      finalData.map((fd, i) => {
        fd["checkData"] = e.target.checked;
      });
      setCheckedId([]);
    }
  };

  const handleSearch = (e) => {
    if (currentTab === "PENDING") {
      getAllList(
        e.target.value.toLowerCase(),
        jvDataFilter.status,
        currentPage,
        pageSizeNo,
        jvDataFilter.start_date,
        jvDataFilter.end_date
      );
    } else if (currentTab === "APPROVED") {
      getAllList(
        e.target.value.toLowerCase(),
        jvDataFilter.status,
        currentPage,
        pageSizeNo,
        jvDataFilter.start_date,
        jvDataFilter.end_date,
        "ApproveStatus"
      );
    } else if (currentTab === "Report" && pathname === "/jv-report") {
      getReportAllList(
        e.target.value.toLowerCase(),
        jvDataFilter.status,
        currentPage,
        pageSizeNo,
        jvDataFilter.start_date,
        jvDataFilter.end_date,
        jvDataFilter.sap_doc_no,
        jvDataFilter.user_id
      );
    } else if (currentTab === "Report" && pathname === "/jv-approval") {
      getReportAllList(
        e.target.value.toLowerCase(),
        jvDataFilter.status,
        currentPage,
        pageSizeNo,
        jvDataFilter.start_date,
        jvDataFilter.end_date,
        jvDataFilter.sap_doc_no,
        jvDataFilter.user_id,
        '',
        true
      );
    }
    else {
      getDraftList(currentPage, pageSizeNo, e.target.value.toLowerCase());
    }
    setSearchStr(e.target.value);
  };

  const moveToDraftData = (id) => {
    confirmAlert({
      title: "Move to Draft",
      message: `Are you sure you want to save to DRAFT ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => saveInDraft(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const AllGridDetails = () => {
    return (
      <>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                {finalUrlValue?.V && <th>Actions</th>}
                <th>Move To Draft</th>
                <th>ID</th>
                <th>Doc. No.</th>
                <th>Comp. Name</th>
                <th>Doc. Type</th>
                <th>Doc. Date</th>
                <th>Posting Date</th>
                <th>Doc. Header</th>
                <th>Reference</th>
                <th>SAP Status</th>
                <th>SAP Log</th>
                <th>SAP Doc. No.</th>
                <th>Pending At</th>
                <th>Approval Status</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              {allListData?.length ? (
                allListData?.map((items, idx) => (
                  <tr key={`draft${idx}`}>
                    {finalUrlValue?.V ? (
                      <td>
                        {finalUrlValue?.V ? (
                          <button
                            class="btn btn-blanktd text-success mt-2 ml-2"
                            onClick={() => handleViewRedirect(items.id, true)}
                          >
                            <img src="images/eye.png" alt="" />
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                    <td>
                      {items?.status === "REJECTED" && (
                        <button
                          class="btn btn-blanktd text-success"
                          onClick={() => moveToDraftData(items.id)}
                        >
                          <img src="images/move to draft blue.png" alt="" />
                        </button>
                      )}
                    </td>

                    <td
                      class="text-theme fw-bold"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleViewRedirect(items.id, true)}
                    >
                      {items?.id}
                    </td>
                    <td>{items?.doc_no}</td>
                    <td>{items?.company_code_name}</td>
                    <td>{items?.document_type_name}</td>
                    <td>
                      {moment(items?.document_date).format("DD-MMM-YYYY")}
                    </td>
                    <td>{moment(items?.posting_date).format("DD-MMM-YYYY")}</td>
                    <td>{items?.document_header}</td>
                    <td>{items?.reference}</td>
                    <td>
                      {items?.sap_sended === true
                        ? "Synced"
                        : "Pending for synced"}
                    </td>
                    <td>
                      <Tooltip title={items?.logs} position="bottom">
                        {items?.logs}
                      </Tooltip>
                    </td>
                    <td>{items?.sap_doc_no}</td>
                    <td>
                      {items?.status !== "APPROVED" &&
                        items?.status !== "REJECTED" &&
                        items?.currentApprover?.map((value) => (
                          <Tooltip
                            title={value?.approver_name}
                            position="bottom"
                          >
                            {value?.approver_name}
                          </Tooltip>
                        ))}
                    </td>
                    {items?.on_hold === true ?
                      <td>On Hold</td>
                      :
                      <td>{items?.status}</td>
                    }
                    <td>{moment(items?.created_at).format("DD-MMM-YYYY")}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={15}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    setJvDataForExport({
      ...jvDataForExport,
      status: jvDataFilter.status,
      end_date: jvDataFilter.end_date,
      start_date: jvDataFilter.start_date,
      sap_doc_no: jvDataFilter.sap_doc_no,
      user_id: jvDataFilter.user_id,
      org_id: jvDataFilter.org_id,
    });
    const status = jvDataFilter.status;
    const end_date = jvDataFilter.end_date;
    const start_date = jvDataFilter.start_date;
    const sap_doc_no = jvDataFilter.sap_doc_no;
    const user_id = jvDataFilter.user_id;
    const org_id = jvDataFilter.org_id;
    if (currentTab === "PENDING") {
      getAllList(
        searchStr,
        status,
        currentPage,
        pageSizeNo,
        start_date,
        end_date
      );
    } else if (currentTab === "Report" && pathname === "/jv-report") {
      getReportAllList(
        searchStr,
        status,
        currentPage,
        pageSizeNo,
        start_date,
        end_date,
        sap_doc_no,
        user_id,
        org_id,
      );
    } else if (currentTab === "Report" && pathname === "/jv-approval") {
      getReportAllList(
        searchStr,
        status,
        currentPage,
        pageSizeNo,
        start_date,
        end_date,
        sap_doc_no,
        user_id,
        org_id,
        true
      );
    }
    else {
      getAllList(
        searchStr,
        status,
        currentPage,
        pageSizeNo,
        start_date,
        end_date,
        "ApproveStatus"
      );
    }

    filterClose();
  };

  const getPermissionUrlData = async (data) => {
    permissionService
      .getPermissionUrlData(data)
      .then((response) => {
        let data = response?.data?.dataList;
        let jsonData = {};
        data.map((obj) => {
          jsonData[obj.code] = obj;
        });
        setJvRmPermission(jsonData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      if (currentTab === "PENDING") {
        getAllList(
          data,
          jvDataFilter.status,
          currentPage,
          pageSizeNo,
          jvDataFilter.start_date,
          jvDataFilter.end_date
        );
      } else if (currentTab === "Report" && pathname === "/jv-report") {
        getReportAllList(data, "", currentPage, pageSizeNo, "", "", "", "");
      } else if (currentTab === "Report" && pathname === "/jv-approval") {
        getReportAllList(data, "", currentPage, pageSizeNo, "", "", "", "", '', true);
      }
      else {
        getAllList(
          data,
          jvDataFilter.status,
          currentPage,
          pageSizeNo,
          jvDataFilter.start_date,
          jvDataFilter.end_date,
          "ApproveStatus"
        );
      }
    }
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(jvDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setJvDataFilter(ClearData);
    filterClose();
    if (currentTab === "Report") {
      getReportAllList(searchStr, "", currentPage, pageSizeNo, "", "", "", "");
    } else {
      getAllList(searchStr, "PENDING", 1, pageSizeNo, "", "");
    }
  };

  const getUserData = (value) => {
    AttendanceService.getUserDataNew(value)
      .then((response) => {
        let obj = response?.data?.dataList?.result
            ?.map((res) => {
              return {
                value:res?.id,
                label: res?.first_name + res?.last_name + "(" + res?.username + ")"
              };
            });
            setUserOptions(obj);
        // setModuleList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    if (currentTab === "PENDING") {
      getAllList(
        searchStr,
        jvDataFilter.status,
        activePage,
        pageSizeNo,
        jvDataFilter.start_date,
        jvDataFilter.end_date
      );
    } else if (currentTab === "Report" && pathname === "/jv-report") {
      getReportAllList(
        searchStr,
        jvDataFilter.status,
        activePage,
        pageSizeNo,
        jvDataFilter.start_date,
        jvDataFilter.end_date,
        jvDataFilter.sap_doc_no,
        jvDataFilter.user_id
      );
    } else if (currentTab === "Report" && pathname === "/jv-approval") {
      getReportAllList(
        searchStr,
        jvDataFilter.status,
        activePage,
        pageSizeNo,
        jvDataFilter.start_date,
        jvDataFilter.end_date,
        jvDataFilter.sap_doc_no,
        jvDataFilter.user_id,
        '',
        true
      );
    }
    else {
      getDraftList(activePage, pageSizeNo, searchStr);
    }
  };

  const submit = () => {
    confirmAlert({
      title: "Delete JV ",
      message: `Are you sure to delete jv ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteMultiApprove(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const submitPosting = () => {
    confirmAlert({
      title: "Submit JV",
      message: `Are you sure to Submit jv ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => saveMultiApprove(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  function dateCheck(items) {
    let fDate = moment(items?.created_at).format("DD-MMM-YYYY");
    let lDate = new Date("30-06-2023");
    if (fDate < lDate) {
      return moment(items?.old_document_date).format("DD-MMM-YYYY");
    }
    return moment(items?.document_date).format("DD-MMM-YYYY");
  }

  useEffect(() => {
    getSapSchedule()
    getPermissionUrlData("/jvRm");
    if (pageSizeNo > "10") {
      if (currentTab === "PENDING") {
        getAllList(
          searchStr,
          "PENDING",
          1,
          pageSizeNo,
          jvDataFilter.start_date,
          jvDataFilter.end_date
        );
      } else if (currentTab === "APPROVED") {
        getAllList(
          searchStr,
          jvDataFilter.status,
          1,
          pageSizeNo,
          jvDataFilter.start_date,
          jvDataFilter.end_date,
          "ApproveStatus"
        );
      } else if (currentTab === "Report") {
        getReportAllList(
          searchStr,
          jvDataFilter.status,
          1,
          pageSizeNo,
          jvDataFilter.start_date,
          jvDataFilter.end_date,
          jvDataFilter.sap_doc_no,
          jvDataFilter.user_id
        );
      } else {
        getDraftList(1, pageSizeNo, searchStr);
      }
    }
  }, [pageSizeNo]);

  const filterClose = () => {
    filterclose();
  };

  useEffect(() => {
    if (currentTab === "PENDING") {
      getAllList("", "", 1, pageSizeNo, "", "");
    } else if (currentTab === "APPROVED") {
      getAllList("", "PENDING", 1, pageSizeNo, "", "", "ApproveStatus");
    } else {
      getDraftList(1, pageSizeNo, searchStr);
    }
  }, [currentTab]);

  useEffect(() => {
    if (pathname === "/jv-report") {
      getReportAllList("", "", 1, pageSizeNo, "", "", "", "");
      setCurrentTab("Report");
    }
    if (pathname === "/jv-approval") {
      getReportAllList("", "", 1, pageSizeNo, "", "", "", "", '', true);
      setCurrentTab("Report");
    }
  }, [pathname]);

  useEffect(() => {
    getOrganisationList();
    getUserData(0);
  }, []);

  const getSapSchedule = () => {
    searchPoService.get_sap_schedule('sync_post_jv_master_data').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    searchPoService.get_sap_schedule('sync_jv_master_data').then((response) => {
      let next_sap_syncing_time = response?.data?.dataList?.result
      setNextSapSyncingTime(next_sap_syncing_time)
    })
  }

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-9">
                  <h4 class="inner-page-title">
                    {pathname === "/jv" ? "JV" : "JV Report"}
                  </h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/home">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>JV</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div className="col-md-3">
                  {finalUrlValue?.V && (
                    <button
                      onClick={() => {
                        navigate("/jvrm");
                      }}
                      className="ml-5 btn btn-primary-inner"
                    >
                      JV Request Manager
                    </button>
                  )}
                </div>
                <div class="col-md-12">
                  <div class="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            JV ID,
                            <br />
                            Doc. Number,
                            <br />
                            Sap Doc. Number,
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on JV ID and Sap Doc.No...."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    {pathname === "/jv" && (
                      <>
                        <Dropdown show>
                          <Dropdown.Toggle
                            variant="primary-inner dropdownbtn"
                            id="dropdown-basic"
                          >
                            <img src="images/export.png" alt="" class="mr-2" />
                            Export
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#">
                              <button
                                style={{ border: 0, background: "none" }}
                                onClick={() => getExportDetails(currentTab, "")}
                              >
                                <i className="far fa-file-excel"></i>Excel
                              </button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    )}

                    {pathname === "/jv-report" && (
                      <>
                        <Dropdown show>
                          <Dropdown.Toggle
                            variant="primary-inner dropdownbtn"
                            id="dropdown-basic"
                          >
                            <img src="images/export.png" alt="" class="mr-2" />{" "}
                            Export
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#">
                              <button
                                style={{ border: 0, background: "none" }}
                                onClick={() => getExportSummaryDetail()}
                              >
                                <i className="far fa-file-excel"></i>Summary
                              </button>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                              <button
                                style={{ border: 0, background: "none" }}
                                onClick={() =>
                                  getExportDetailsReport(
                                    jvDataFilter.status,
                                    jvDataFilter.start_date,
                                    jvDataFilter.end_date,
                                    searchStr,
                                    jvDataFilter.sap_doc_no,
                                    jvDataFilter.user_id
                                  )
                                }
                              >
                                <i className="far fa-file-excel"></i>Detail
                              </button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    )}
                    {currentTab !== "DRAFT" && (
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    )}
                    {pathname === "/jv" && finalUrlValue?.U ? (
                      <button class="btn btn-danger" onClick={importuser}>
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Bulk Upload
                      </button>
                    ) : (
                      ""
                    )}
                    {pathname === "/jv" && (
                      <>
                        {finalUrlValue?.A ? (
                          <Link
                            to="/addjv"
                            class="btn btn-primary-inner"
                            onClick={() => handleEditRedirect(null)}
                          >
                            <img src="images/upload.png" alt="" class="mr-3" />
                            Add New
                          </Link>
                        ) : (
                          <Link
                            to="/addjv"
                            class="btn btn-primary-inner"
                            onClick={() => handleEditRedirect(null)}
                          >
                            <img src="images/upload.png" alt="" class="mr-3" />
                            Add New
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next SAP Syncing:</b> <span className="" style={{ fontSize: "13px" }}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs
                unmountOnExit
                id="approvaltabs"
                onSelect={(e) => {
                  setCurrentTab(e);
                }}
                activeKey={currentTab}
              >
                {pathname == "/jv" && (
                  <Tab
                    eventKey="DRAFT"
                    title={
                      <React.Fragment>
                        Draft
                        {currentTab === "DRAFT" && (
                          <span class="numberpurchse">
                            {draftListData.length}
                          </span>
                        )}
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>
                              <label class="logCheck d-inline-block">
                                <input
                                  type="checkbox"
                                  name="permission"
                                  onClick={(e) => {
                                    handleAllData(e);
                                  }}
                                />
                                <span class="checkmark"></span>
                              </label>
                            </th>
                            {finalUrlValue?.V || finalUrlValue?.E ? (
                              <th>Action</th>
                            ) : (
                              ""
                            )}
                            <th>ID</th>
                            <th>Doc. No.</th>
                            <th>Doc. Name</th>
                            <th>Comp. Name</th>
                            <th>Doc. Date</th>
                            <th>Posting Date</th>
                            <th>Doc. Header</th>
                            <th>Reference</th>
                            <th>SAP Status</th>
                            <th>SAP Log</th>
                            <th>SAP Doc. No.</th>
                            <th>Approval Status</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {draftListData?.length ? (
                            draftListData?.map((item, idx) => (
                              <tr>
                                <td>
                                  <label class="logCheck d-inline-block">
                                    <input
                                      type="checkbox"
                                      name="permission"
                                      id={"permission" + idx}
                                      onClick={(e) => {
                                        updateCheckBoxValue(e, item);
                                      }}
                                      defaultChecked={item.checkData}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </td>
                                <td style={{ overflow: "visible" }}>
                                  {finalUrlValue?.E ? (
                                    <button
                                      className="btn btn-blanktd text-primary"
                                      onClick={() => {
                                        handleEditRedirect(item.id);
                                      }}
                                    >
                                      <i className="far fa-edit"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {finalUrlValue?.V ? (
                                    <button
                                      class="btn btn-blanktd text-success"
                                      onClick={() =>
                                        handleViewRedirect(item.id, true)
                                      }
                                    >
                                      <img src="images/eye.png" alt="" />
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{item?.id}</td>
                                <td
                                  class="text-theme fw-bold"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleViewRedirect(item.id, true)
                                  }
                                >
                                  {item?.doc_no}
                                </td>
                                <td>{item?.document_type_name}</td>
                                <td>{item?.company_code_name}</td>
                                <td>
                                  {moment(item?.document_date).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.posting_date).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>{item?.document_header}</td>
                                <td>{item?.reference}</td>
                                <td>
                                  {item?.sap_sended === true
                                    ? "Synced"
                                    : "Pending for synced"}
                                </td>
                                <td>
                                  <Tooltip title={item?.logs} position="bottom">
                                    {item?.logs}
                                  </Tooltip>
                                </td>
                                <td>{item?.sap_doc_no}</td>
                                <td>{item?.status}</td>
                                <td>
                                  {moment(item?.created_at).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={17}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={draftPageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>

                    <div class="row jvactionsbutton">
                      <div class="col-md-12">
                        <div class="p-3">
                          {finalUrlValue?.R ? (
                            <button
                              class="btn btn-danger mr-2"
                              onClick={() => {
                                if (checkedId.length === 0) {
                                  toast.error("Please select atleast one Data");
                                } else {
                                  submit();
                                }
                              }}
                            >
                              <i class="far fa-times-circle"></i> Delete
                            </button>
                          ) : (
                            ""
                          )}
                          <button
                            class="btn btn-success"
                            onClick={() => {
                              if (checkedId.length === 0) {
                                toast.error("Please select atleast one Data");
                              } else {
                                submitPosting();
                              }
                            }}
                          >
                            <i class="far fa-check-circle"></i> Submit for
                            Posting
                          </button>
                        </div>
                      </div>
                    </div>
                  </Tab>
                )}
                {pathname == "/jv" && (
                  <Tab
                    eventKey="PENDING"
                    title={
                      <React.Fragment>
                        My JVs
                        {currentTab === "PENDING" && (
                          <span class="numberpurchse">
                            {allListData.length}
                          </span>
                        )}
                      </React.Fragment>
                    }
                  >
                    <AllGridDetails />

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Tab>
                )}
                {pathname == "/jv" && (
                  <Tab
                    eventKey="APPROVED"
                    title={
                      <React.Fragment>
                        JV Approval
                        {currentTab === "Approved" && (
                          <span class="numberpurchse">
                            {allListData.length}
                          </span>
                        )}
                      </React.Fragment>
                    }
                  >
                    <AllGridDetails />

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Tab>
                )}
                {(pathname == "/jv-report" || pathname === "/jv-approval") && (
                  <Tab
                    eventKey="Report"
                    title={
                      <React.Fragment>
                        JV Report
                        {/* {currentTab === "Approved" && (
                        <span class="numberpurchse">{allListData.length}</span>
                      )} */}
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            {finalUrlValue?.V && <th>Actions</th>}
                            <th>JV ID</th>
                            <th>Doc. No.</th>
                            <th>Comp. Name</th>
                            <th>Doc. Type</th>
                            <th>Doc. Date</th>
                            <th>Posting Date</th>
                            <th>Doc. Header</th>
                            <th>Reference</th>
                            <th>SAP Status</th>
                            <th>Debit Amount</th>
                            <th>Credit Amount</th>
                            <th>SAP Log</th>
                            <th>SAP Doc. No.</th>
                            <th>Pending At</th>
                            <th>Created By</th>
                            <th>Approval Status</th>
                            <th>Created On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allReportList?.length ? (
                            allReportList?.map((items, idx) => (
                              <tr key={`draft${idx}`}>
                                {finalUrlValue?.V ? (
                                  <button
                                    class="btn btn-blanktd text-success"
                                    onClick={() =>
                                      handleViewRedirect(items.id, "1")
                                    }
                                  >
                                    <img src="images/eye.png" alt="" />
                                  </button>
                                ) : (
                                  ""
                                )}
                                {/* <td>{items?.id}</td> */}
                                <td>{items?.id}</td>
                                <td>{items?.doc_no}</td>
                                <td>{items?.company_code_name}</td>
                                <td>{items?.document_type_name}</td>
                                <td>{dateCheck(items)}</td>
                                <td>
                                  {moment(items?.posting_date).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>{items?.document_header}</td>
                                <td>{items?.reference}</td>
                                <td>
                                  {items?.sap_sended === true
                                    ? "Synced"
                                    : "Pending for synced"}
                                </td>
                                <td>{items?.sum_40?.amount__sum}</td>
                                <td>{items?.sum_50?.amount__sum}</td>
                                <td>
                                  <Tooltip
                                    title={items?.logs}
                                    position="bottom"
                                  >
                                    {items?.logs}
                                  </Tooltip>
                                </td>
                                <td>{items?.sap_doc_no}</td>
                                <td>
                                  {items?.status !== "APPROVED" &&
                                    items?.status !== "REJECTED" &&
                                    items?.currentApprover?.map(
                                      (value) => value?.approver_name
                                    )}
                                </td>

                                <td>
                                  {" "}
                                  <Tooltip
                                    title={items?.username}
                                    position="bottom"
                                  >{`${items?.user_first_name}${items?.user_last_name}(${items?.username})`}</Tooltip>
                                </td>
                                {items?.on_hold === true ?
                                  <td>On Hold</td>
                                  :
                                  <td>{items?.status}</td>
                                }
                                <td>
                                  {moment(items?.created_at).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={17}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCountReport}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>From Date</label>
            <input
              type="date"
              class="form-control"
              placeholder=""
              name="start_date"
              onChange={(e) => {
                setJvDataFilter({
                  ...jvDataFilter,
                  start_date: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={jvDataFilter.start_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>To Date</label>
            <input
              type="date"
              class="form-control"
              placeholder=""
              name="end_date"
              min={jvDataFilter.start_date}
              disabled={!jvDataFilter.start_date}
              onChange={(e) => {
                setJvDataFilter({
                  ...jvDataFilter,
                  end_date: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={jvDataFilter.end_date}
            />
          </div>
          {pathname === "/jv-report" && (
            <>
              <div class="form-group innergroup">
                <label>Sap Doc. No.</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Sap Doc.No."
                  name="sap_doc_no"
                  onChange={(e) => {
                    setJvDataFilter({
                      ...jvDataFilter,
                      sap_doc_no: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={jvDataFilter.sap_doc_no}
                />
              </div>

              <div class="form-group innergroup">
                <label>User Id</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter User Id"
                  name="user_id"
                  onChange={(e) => {
                    setJvDataFilter({
                      ...jvDataFilter,
                      user_id: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={jvDataFilter.user_id}
                />
                {/* <Select
                  name="user_id"
                  options={userOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setUserListValue(e)
                    setJvDataFilter({
                      ...jvDataFilter,
                      user_id: e.value,
                    });
                  }}
                  value={userListValue}
                /> */}
              </div>

              <div class="form-group innergroup">
                <label>
                  Organisation<span class="text-danger">*</span>
                </label>
                <Select
                  name="organisation"
                  options={org}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    pushOrg(e);
                    setJvDataFilter({
                      ...jvDataFilter,
                      org_id: e.value,
                    });
                  }}
                  value={orgListValue}
                />
              </div>
            </>
          )}
          <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              class="form-control newbgselect"
              onChange={(e) => {
                setJvDataFilter({
                  ...jvDataFilter,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={jvDataFilter.status}
            >
              <option value="0" disabled>
                Select
              </option>
              <option value="">All</option>
              <option value="PENDING">Pending</option>
              <option value="APPROVED">Approved</option>
              <option value="REJECTED">Rejected</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            type="submit"
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {importsetShow && (
        <JvBulkImport
          importshow={importshow}
          importclose={importclose}
          checkImportClose={checkImportClose}
        />
      )}
    </>
  );
}

export default Jv;
