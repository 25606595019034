import React, { useState, useEffect } from "react";
import { Spinner, Alert, NavItem } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayLoader } from "../../redux/piDataStore";
import * as TravelListingAPI from "../../../service/travelService";
import * as DomesticService from "../../../service/domestic";
import {
  selectUserData,
  selectVehicleAssignData,
} from "../../redux/piDataStore";
import { injectStyle } from "react-toastify/dist/inject-style";
import AlertBox from "../../CommonComp/AlertBox";
import SuggestionsList from "../TravelCommon/SuggestionList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

if (typeof window !== "undefined") {
  injectStyle();
}

const AddDomestic = (props) => {
  const {
    setCallGrid,
    domData,
    edit,
    setTimeCheck,
    editDomestic,
    setChangeValueData,
    onClose,
    onAddCallBack,
    allData,
    migrate,
    update,
    AddFunction,
    setValidLastData,
    userDetailsLatest,
    userBackDate,
    indexValue,
    dataNew,
    flagSet,
    managerEditing,
    dynamicUserData,
  } = props;

  let date = new Date();
  let valueDate = moment(date).format("YYYY-MM-DD");
  const userData = useSelector(selectUserData);
  const vehicleAssignValues = useSelector(selectVehicleAssignData);
  const [purpose, setPurpose] = useState([]);
  const [purposeList, setPurposeList] = useState([]);
  const [purposeData, setPurposeData] = useState([]);
  const [mode, setMode] = useState([]);
  const [modeData, setModeData] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [reasonData, setReasonData] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [citiesRecord, setCitiesRecord] = useState([]);
  const [allEmployee, setAllEmployee] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [modePermission, setModePermission] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [hqCondition, setHqCondition] = useState(false);
  const [hqConditionValue, setHqConditionValue] = useState(false);
  const [conditionValue, setConditionValue] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [error, setError] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [fromTimeValue, setFromTimeValue] = useState();
  const [toTime, setToTime] = useState("");
  const [toTimeValue, setToTimeValue] = useState("");
  const [errorTime, setErrorTime] = useState("");
  const [colleagueError, setColleagueError] = useState(false);
  const [exceptionField, setExceptionField] = useState(false);
  const [leaveCondition, setLeaveCondition] = useState(false);
  const [leaveConditionValue, setLeaveConditionValue] = useState(false);
  const [pharma, setPharma] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [addIsExist, setAddIsExist] = useState(false);
  const [isLeaveExist, setIsLeaveExist] = useState();
  const [validJoinDate, setValidJoinDate] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [otherField, setOtherField] = useState(false);
  const [territoryPermit, setTerritoryPermit] = useState();
  const [dateCalcData, setDateCalcData] = useState();
  const [validDateJoin, setValidDateJoin] = useState(false);
  const [timeSetValue, setTimeSetValue] = useState([]);
  const [validTimeZone, setValidTimeZone] = useState(false);
  const [domFormData, setDomFormData] = useState({
    mode_id: "",
    from_city_id: "",
    to_city_id: "",
    from_city_input: "",
    to_city_input: "",
    from_date: "",
    from_time: "",
    to_time: "",
    to_date: "",
    purpose_of_travel_id: "",
    hotel: "",
    hq: "",
    reason_for_exception_id: "",
    territory: "",
    colleague_user_id: "",
    additional_information: "",
    reason: "",
    stay_needed: null,
  });
  const [formValidation, setFormValidation] = useState({
    from_city_id: "",
    to_city_id: "",
    from_date: "",
    from_time: "",
    to_time: "",
    to_date: "",
    purpose_of_travel_id: "",
    additional_information: "",
    mode_id: "",
    hotel: "",
    hq: "",
    territory: "",
    reason_for_exception_id: "",
    colleague_user_id: "",
    reason: "",
    to_city_input: "",
    from_city_input: "",
    stay_needed: "",
  });
  const [timeOption, setTimeOption] = useState([]);
  const dispatch = useDispatch();

  let doubleClick = "false";

  const getPurposeList = () => {
    TravelListingAPI.getPurposeList()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result?.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.purpose,
            color: `${item?.purpose === "Leave" ? "red" : "green"}`,
          };
          data.push(data1);
        });
        setPurpose(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    let arrayG = [];
    if (
      userDetails[0]?.organisation_code == "PIIL" &&
      (userDetails[0]?.business_area == "PICO" ||
        userDetails[0]?.business_area == "PIFC") &&
      (userDetails[0]?.personal_area_name == "1001" ||
        userDetails[0]?.personal_area_name == "1002")
    ) {
      for (var i = 0; i < purpose?.length; i++) {
        var purposeValue = purpose[i];
        [purposeValue]?.filter((ele) => {
          if (ele?.value !== 27) {
            arrayG?.push(ele);
          }
        });
        setPurposeList(arrayG);
      }
    } else {
      setPurposeList(purpose);
    }
  }, [purpose]);

  const getCity = () => {
    TravelListingAPI.getCitys()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((item) => {
          let data1 = {
            value: item?.id,
            label: `${item?.name} - ${item?.city_category}`,
            city_name: item?.name,
          };
          data.push(data1);
        });
        setCities(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getModeList = () => {
    TravelListingAPI.getModeList()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.mode,
          };
          data?.push(data1);
          if (
            userData?.organisation_code == "PIJA" &&
            (vehicleAssignValues == undefined || vehicleAssignValues == false)
          ) {
            let dataValue = data?.filter((ele) => {
              if (ele?.label != "Car") {
                return true;
              }
            });
            setMode(dataValue);
            // } else if (
            //   userData?.organisation_code == "PIJA" &&
            //   vehicleAssignValues !== undefined &&
            //   vehicleAssignValues == true
            // ) {
            //   let dataValue = data?.filter((ele) => {
            //     if (ele?.label != "Personal Car") {
            //       return true;
            //     }
            //   });
            //   setMode(dataValue);
          } else {
            setMode(data);
          }
        });
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getModePermission = () => {
    DomesticService.getModePermission()
      .then((response) => {
        setModePermission(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const TravelTerritoryExists = (user_id) => {
    TravelListingAPI.TravelTerritoryExists(user_id)
      .then((response) => {
        setTerritoryPermit(response?.data?.dataList?.is_exist);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (userDetailsLatest[0]?.id !== undefined) {
      TravelTerritoryExists(userDetailsLatest[0]?.id);
    }
  }, [userDetailsLatest]);

  const getReasonList = () => {
    TravelListingAPI.getReasonList()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.reason,
          };
          data.push(data1);
        });
        setReasons(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getEmployee = (paginate) => {
    TravelListingAPI.getEmployee(paginate)
      .then((response) => {
        let data = [];

        const filteredUsers = response?.data?.dataList?.result.filter(
          (user) => user.is_active
        );
        filteredUsers.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const addMoreTravelInfo = () => {
    if (validateRecords()) return false;
    if (validDateJoin === false) {
      if (
        moment(domFormData?.from_date).isSame(moment(domFormData?.to_date)) &&
        (domFormData?.from_time == domFormData?.to_time ||
          domFormData?.from_time > domFormData?.to_time)
      ) {
        setValidTimeZone(true);
      } else {
        validateAdditional();
      }
    }
  };

  const saveTable = (values) => {
    dispatch(setDisplayLoader("Display"));
    let data = {};
    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      if (values[key] !== "") {
        data[key] = value;
      }
    });
    if (values?.id === undefined && doubleClick === "false") {
      doubleClick = "true";
      TravelListingAPI.saveTable(data)
        .then((response) => {
          dispatch(setDisplayLoader("Hide"));
          if (response.data.status === true && response.data.code === 200) {
            setCallGrid(true);
            doubleClick = "false";
            setValidLastData(false);
            AllClear();
            onClose();
            onAddCallBack();
            setColleagueError(false);
            toast.success(response?.data?.message);
          }
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Display"));
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      TravelListingAPI.updateTable(data, values?.id)
        .then((response) => {
          dispatch(setDisplayLoader("Hide"));
          if (response.data.status === true && response.data.code === 200) {
            setTimeCheck("");
            setCallGrid(true);
            AllClear();
            setValidLastData(false);
            onClose();
            setColleagueError(false);
            onAddCallBack();
            // toast.success(response?.data?.message);
          }
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Display"));
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const AllClear = () => {
    setCitiesData([]);
    setInputValue("");
    setInputValueNext("");
    setPurposeData([]);
    setModeData([]);
    setReasonData([]);
    setCitiesRecord([]);
    setEmployeeData([]);
    setFromTimeValue();
    setToTimeValue();
    setDomFormData({
      from_date: "",
      from_time: "",
      to_time: "",
      to_date: "",
      hotel: "",
      hq: "",
      territory: "",
      additional_information: "",
      stay_needed: null,
    });
  };

  const validateRecords = () => {
    let selectElement = document.getElementById("hotelselect");
    let selectedIndex = selectElement.selectedIndex;
    let isError = false;
    const fromData = { ...formValidation };
    if (!inputValue) {
      isError = true;
      fromData.from_city_id = "*Departure Location is required ";
      setFormValidation(fromData);
    }
    if (!inputValueNext) {
      isError = true;
      fromData.to_city_id = "*Reaching Location is required ";
      setFormValidation(fromData);
    }

    if (!domFormData.from_date) {
      isError = true;
      fromData.from_date = "Departure Date is required";
      setFormValidation(fromData);
    }
    if (!domFormData.from_time) {
      isError = true;
      fromData.from_time = "*Departure Time is required";
      setFormValidation(fromData);
    }
    if (!domFormData.to_time) {
      isError = true;
      fromData.to_time = "*Reaching Time is required";
      setFormValidation(fromData);
    }
    if (!domFormData.to_date) {
      isError = true;
      fromData.to_date = "*Reaching Date is required";
      setFormValidation(fromData);
    }

    if (
      userDetails[0]?.personal_area_desc === "Marketing" ||
      userDetails[0]?.personal_area_desc === "PIJIV Marketing"
    ) {
      if (!domFormData.additional_information) {
        isError = true;
        fromData.additional_information = "*Additional Information is required";
        setFormValidation(fromData);
      }
    }
    if (!leaveCondition) {
      if (!domFormData.purpose_of_travel_id) {
        isError = true;
        fromData.purpose_of_travel_id = "*Purpose of Travel is required";
        setFormValidation(fromData);
      }
    }

    if (leaveCondition === false && editDomestic?.purpose !== "Leave") {
      if (!domFormData.mode_id) {
        isError = true;
        fromData.mode_id = "*Travel Mode is required";
        setFormValidation(fromData);
      }
    }
    // if (leaveCondition === false && hqCondition === false) {
    //   if (selectedIndex === 0) {
    //     isError = true;
    //     fromData.hotel = "*Stay needed is required";
    //     setFormValidation(fromData);
    //   }
    // }
    if (typeof domFormData.stay_needed !== "boolean") {
      if (
        domFormData?.hq == "" &&
        domFormData.stay_needed == null &&
        userDetails[0]?.personal_area_name !== "GG01" &&
        leaveCondition === false &&
        (userDetails[0]?.personal_area_desc === "Marketing" ||
          userDetails[0]?.personal_area_desc === "PIJIV Marketing")
      ) {
        isError = true;
        fromData.hq = "*HQ is required";
        setFormValidation(fromData);
      }
    }

    if (!domFormData?.territory && territoryPermit) {
      isError = true;
      fromData.territory = "*Territory is required";
      setFormValidation(fromData);
    }
    if (exceptionField) {
      if (!domFormData.reason_for_exception_id) {
        isError = true;
        fromData.reason_for_exception_id = "*Reason for exception is required";
        setFormValidation(fromData);
      }
    }
    // if (
    //   (edit === false &&
    //     domFormData.stay_needed == "" &&
    //     leaveCondition === false &&
    //     hqConditionValue === false) ||
    //   (edit == true && domFormData.stay_needed === "")
    // ) {
    //   isError = true;
    //   fromData.stay_needed = "*Stay Needed is required";
    //   setFormValidation(fromData);
    // }
    if (
      (typeof domFormData.stay_needed !== "boolean" &&
        domFormData?.purpose != "Leave") ||
      hqConditionValue == "false"
    ) {
      if (!domFormData.stay_needed) {
        isError = true;
        fromData.stay_needed = "*Stay Needed is required";
        setFormValidation(fromData);
      }
    }
    if (otherField) {
      if (!domFormData.reason) {
        isError = true;
        fromData.reason = "*Other Reason is required";
        setFormValidation(fromData);
      }
    }

    if (colleagueError) {
      if (employeeData.length === 0) {
        isError = true;
        fromData.colleague_user_id = "*Collegue Employee Code is required";
        setFormValidation(fromData);
      }
    }
    if (error) {
      isError = true;
    }
    if (errorTime) {
      isError = true;
    }
    if (isExist) {
      isError = true;
    }
    if (addIsExist) {
      isError = true;
    }
    // if (
    //   !isLeaveExist &&
    //   isLeaveExist != undefined &&
    //   domFormData?.purpose != undefined &&
    //   domFormData?.purpose == "Leave"
    // ) {
    //   isError = true;
    // }
    if (
      isLeaveExist &&
      isLeaveExist != undefined &&
      domFormData?.purpose != undefined &&
      domFormData?.purpose != "Leave"
    ) {
      isError = true;
    }
    if (validJoinDate) {
      isError = true;
    }
    if (pharma) {
      isError = true;
    }

    return isError;
  };

  const handleChange = (e, name) => {
    setChangeValueData(true);
    switch (name) {
      case "from_city_input":
        setDomFormData((prev) => ({
          ...prev,
          from_city_input: e?.split("-")[0],
        }));
        break;

      case "to_city_input":
        setDomFormData((prev) => ({
          ...prev,
          to_city_input: e?.target?.value?.split("-")[0],
        }));
        break;
      case "from_date":
        setDomFormData((prev) => ({
          ...prev,
          from_date: e ? moment(e).format("YYYY-MM-DD") : "",
        }));
        setDomFormData((prev) => ({ ...prev, from_time: "" }));
        setFromTimeValue("");
        break;
      case "hq":
        setDomFormData((prev) => ({ ...prev, hq: e.target.value }));
        break;
      case "hotel":
        setDomFormData((prev) => ({ ...prev, hotel: e.target.value }));
        break;
      case "territory":
        setDomFormData((prev) => ({ ...prev, territory: e.target.value }));
        break;
      case "from_time":
        setDomFormData((prev) => ({ ...prev, from_time: e.value }));
        break;
      case "to_time":
        setDomFormData((prev) => ({ ...prev, to_time: e.value }));
        break;
      case "to_date":
        setDomFormData((prev) => ({
          ...prev,
          to_date: e ? moment(e).format("YYYY-MM-DD") : "",
        }));
        setDomFormData((prev) => ({ ...prev, to_time: "" }));
        setToTimeValue("");
        break;
      case "reason":
        setDomFormData((prev) => ({ ...prev, reason: e.target.value }));
        break;

      case "purpose_of_travel_id":
        setDomFormData((prev) => ({
          ...prev,
          purpose_of_travel_id: e.value,
          purpose: e.label,
        }));
        setPurposeData(e);
        break;
      case "mode_id":
        setDomFormData((prev) => ({
          ...prev,
          mode_id: e?.value,
          mode_name: e?.label,
        }));
        setModeData(e);
        break;
      case "colleague_user_id":
        setDomFormData((prev) => ({
          ...prev,
          colleague_user_id: e.value,
          colleague_user_name: e.label,
        }));
        setEmployeeData(e);
        break;
      case "reason_for_exception_id":
        setDomFormData((prev) => ({
          ...prev,
          reason_for_exception_id: e.value,
          reason_for_exception_name: e.label,
        }));
        setReasonData(e);
        break;
      case "additional_information":
        setDomFormData((prev) => ({
          ...prev,
          additional_information: e.target.value,
        }));
        break;
      case "stay_needed":
        setDomFormData((prev) => ({
          ...prev,
          stay_needed: e.target.value,
        }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getPurposeList();
    getCity();
    getModeList();
    getModePermission();
    getReasonList();
    getEmployee(false);
    if (managerEditing && dynamicUserData) {
      getUsersDetails(dynamicUserData?.username);
    } else getUsersDetails(userData?.username);
    timeOptionFunc();
  }, []);

  useEffect(() => {
    if (editDomestic) {
      if (editDomestic?.colleague_user_id) {
        setColleagueError(true);
      }
      setDomFormData(editDomestic);
      let startFromLocation =
        editDomestic?.from_city_name !== "Other"
          ? `${
              editDomestic?.from_city_name +
              " - " +
              editDomestic?.from_city_category
            }`
          : `${
              editDomestic?.from_city_input +
              " - " +
              editDomestic?.from_city_category
            }`;
      let startfromLocationId = { value: editDomestic?.from_city_id };

      let mainArray = domData;
      var currentIndex = mainArray.findIndex(function (item) {
        return item?.id === editDomestic?.id;
      });
      let last = mainArray[currentIndex - 1];
      let newCity = last?.to_city_input;
      let fromLocation =
        editDomestic?.from_city_name !== "Other"
          ? `${
              editDomestic?.from_city_name +
              " - " +
              editDomestic?.from_city_category
            }`
          : `${
              editDomestic?.from_city_input +
              " - " +
              editDomestic?.from_city_category
            }`;
      let fromLocationId = { value: editDomestic?.from_city_id };
      let fromLocate = newCity?.split("-")[0];
      if (last !== undefined) {
        setInputValue(fromLocation);
        setStateSelection(fromLocationId);
        setDomFormData((prev) => ({
          ...prev,
          from_city_input: fromLocate,
        }));
      } else {
        setInputValue(startFromLocation);
        setStateSelection(startfromLocationId);
        setDomFormData((prev) => ({
          ...prev,
          from_city_input: fromLocate,
        }));
      }
      if (editDomestic?.from_city_name === "Other") {
        let fromLocate = editDomestic?.from_city_input?.split("-")[0];
        setDomFormData((prev) => ({
          ...prev,
          from_city_input: fromLocate,
        }));
      }
      if (editDomestic?.to_city_name === "Other") {
        setDomFormData((prev) => ({
          ...prev,
          to_city_input: editDomestic?.to_city_input,
        }));
      }
      setHqCondition(editDomestic?.hq);
      if (editDomestic?.hq == true) {
        setHqConditionValue(true);
      }
      let toLocation = `${
        editDomestic?.to_city_name === "Other"
          ? editDomestic?.to_city_input + " - " + editDomestic?.to_city_category
          : editDomestic?.to_city_name + " - " + editDomestic?.to_city_category
      }`;
      setInputValueNext(toLocation);

      let dateFrom = {
        value: editDomestic?.from_time.substring(0, 5),
        label: editDomestic?.from_time.substring(0, 5),
      };
      setFromTimeValue(dateFrom);
      let dateto = {
        value: editDomestic?.to_time.substring(0, 5),
        label: editDomestic?.to_time.substring(0, 5),
      };
      setToTimeValue(dateto);
      let purpose = {
        value: editDomestic?.purpose_of_travel_id,
        label: editDomestic?.purpose,
      };
      setPurposeData(purpose);
      let mode = {
        value: editDomestic?.mode_id,
        label:
          editDomestic?.modee_name == undefined
            ? editDomestic?.mode_name
            : editDomestic?.modee_name,
      };
      setModeData(mode);
      let reasonData = {
        value: editDomestic?.reason_for_exception_id,
        label: editDomestic?.reason_for_exception_name,
      };
      setReasonData(reasonData);
      setDomFormData((prev) => ({ ...prev, reason: editDomestic?.reason }));
      let colleage = {
        value: editDomestic?.colleague_user_id,
        label:
          editDomestic?.colleague_user_name +
          " " +
          "(" +
          editDomestic?.colleague_user_code +
          ")",
      };
      setEmployeeData(colleage);

      setFromDate(editDomestic?.from_date);
      setToDate(editDomestic?.to_date);
      setToTime(editDomestic?.to_time);
      setFromTime(editDomestic?.from_time);
      Object.keys(editDomestic).forEach((item) => {
        domFormData[item] = editDomestic[item];
      });
    }
  }, [editDomestic]);

  const editUpdate = () => {
    if (validateRecords()) return false;
    AllClear();
    let payload = { ...domFormData };
    payload.from_date = moment(domFormData?.from_date).format("YYYY-MM-DD");
    payload.to_date = moment(domFormData?.to_date).format("YYYY-MM-DD");
    update(payload);
  };

  const onConfirmClose = () => {
    setConfirmModal(false);
  };

  const AddNewRecord = () => {
    if (validateRecords()) return false;
    if (messageDisplay == false && validDateJoin === false) {
      AllClear();
      AddFunction(domFormData);
    }
  };

  useEffect(() => {
    //Using this for checking last data.
    if (domData.length !== 0 && edit !== true) {
      let mainArray = domData;
      let last = mainArray[mainArray.length - 1];
      let fromLocation =
        last?.to_city_name !== "Other"
          ? `${last?.to_city_name + " - " + last?.to_city_category}`
          : `${last?.to_city_input + " - " + last?.to_city_category}`;
      let fromLocationId = { value: last?.to_city_id };
      let fromLocate = fromLocation?.split("-")[0];
      setDomFormData((prev) => ({
        ...prev,
        from_city_id: last?.to_city_id,
        from_city_name: last?.to_city_name,
      }));
      setDomFormData((prev) => ({
        ...prev,
        from_city_input: fromLocate,
      }));
      setInputValue(fromLocation);
      setStateSelection(fromLocationId);
    }
  }, [domData]);

  const handleFromDateChange = (date) => {
    // let value = event;
    validateDateOfJoin(date);
    handleChange(date, "from_date");
    setFromDate(date);
    validateDates(
      moment(date).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD")
    );
    if (domData.length == 0) {
      isDataExists(
        date,
        toDate,
        fromTime,
        toTime,
        date,
        fromTime,
        toDate,
        toTime
      );
    } else if (indexValue == true) {
      isDataExists(
        date,
        toDate,
        fromTime,
        toTime,
        domData && domData[0] && domData[0].from_date,
        domData && domData[0] && domData[0].from_time,
        toDate,
        toTime
      );
    } else {
      isDataExists(
        date,
        toDate,
        fromTime,
        toTime,
        domData && domData[0] && domData[0].from_date,
        domData && domData[0] && domData[0].from_time,
        domData &&
          domData[domData.length - 1] &&
          domData[domData.length - 1].to_date,
        domData &&
          domData[domData.length - 1] &&
          domData[domData.length - 1].to_time
      );
    }
    isAddDataExists(date, toDate, fromTime, toTime);
    // if (leaveCondition) {
    isLeaveExists(date, toDate);
    // }
  };

  const handleToDateChange = (date) => {
    // let value = event;
    setToDate(date);
    handleChange(date, "to_date");
    validateDates(
      moment(fromDate).format("YYYY-MM-DD"),
      moment(date).format("YYYY-MM-DD")
    );

    if (domData.length == 0) {
      isDataExists(
        fromDate,
        date,
        fromTime,
        toTime,
        fromDate,
        fromTime,
        date,
        toTime
      );
    } else if (indexValue == true) {
      isDataExists(
        fromDate,
        date,
        fromTime,
        toTime,
        domData && domData[0] && domData[0].from_date,
        domData && domData[0] && domData[0].from_time,
        date,
        toTime
      );
    } else {
      isDataExists(
        fromDate,
        date,
        fromTime,
        toTime,
        domData && domData[0] && domData[0].from_date,
        domData && domData[0] && domData[0].from_time,
        domData &&
          domData[domData.length - 1] &&
          domData[domData.length - 1].to_date,
        domData &&
          domData[domData.length - 1] &&
          domData[domData.length - 1].to_time
      );
    }
    isAddDataExists(fromDate, date, fromTime, toTime);
    // if (leaveCondition) {
    isLeaveExists(fromDate, date);
    // }
  };

  const validateDates = (from, to) => {
    if (from && to && moment(from).isAfter(moment(to))) {
      setError("Departed On date should be before the Reaching On date.");
    } else {
      setError("");
    }
  };

  const validateDateOfJoin = (date) => {
    let value = date;
    if (userDetails[0]?.date_of_joining > value) {
      setValidJoinDate(true);
    } else {
      setValidJoinDate(false);
    }
  };

  const handleFromTimeChange = (event) => {
    setFromTimeValue(event);
    const { value } = event;
    setFromTime(value);
    validateTime(value, toTime);
    if (domData.length == 0) {
      isDataExists(
        fromDate,
        toDate,
        value,
        toTime,
        fromDate,
        value,
        toDate,
        toTime
      );
    } else if (indexValue == true) {
      isDataExists(
        fromDate,
        toDate,
        value,
        toTime,
        domData && domData[0] && domData[0].from_date,
        domData && domData[0] && domData[0].from_time,
        toDate,
        toTime
      );
    } else {
      isDataExists(
        fromDate,
        toDate,
        value,
        toTime,
        domData && domData[0] && domData[0].from_date,
        domData && domData[0] && domData[0].from_time,
        domData &&
          domData[domData.length - 1] &&
          domData[domData.length - 1].to_date,
        domData &&
          domData[domData.length - 1] &&
          domData[domData.length - 1].to_time
      );
    }
    isAddDataExists(fromDate, toDate, value, toTime);
  };

  const handleToTimeChange = (event) => {
    setToTimeValue(event);
    const { value } = event;
    setToTime(value);
    validateTime(fromTime, value);
    if (domData.length == 0) {
      isDataExists(
        fromDate,
        toDate,
        fromTime,
        value,
        fromDate,
        fromTime,
        toDate,
        value
      );
    } else if (indexValue == true) {
      isDataExists(
        fromDate,
        toDate,
        fromTime,
        value,
        domData && domData[0] && domData[0].from_date,
        domData && domData[0] && domData[0].from_time,
        toDate,
        value
      );
    } else {
      isDataExists(
        fromDate,
        toDate,
        fromTime,
        value,
        domData && domData[0] && domData[0].from_date,
        domData && domData[0] && domData[0].from_time,
        domData &&
          domData[domData.length - 1] &&
          domData[domData.length - 1].to_date,
        domData &&
          domData[domData.length - 1] &&
          domData[domData.length - 1].to_time
      );
    }
    isAddDataExists(fromDate, toDate, fromTime, value);
  };

  const validateTime = (from, to) => {
    if (from && to && fromDate === toDate) {
      if (from === to) {
        setErrorTime("Departed time should not be same to reaching time.");
      } else if (from > to) {
        setErrorTime("Departed time should be before the to reaching time.");
      } else {
        setErrorTime("");
      }
    } else {
      setErrorTime("");
    }
  };

  const isDataExists = (
    fromDate,
    toDate,
    fromTime,
    toTime,
    fromDateEnd,
    fromTimeEnd,
    toDateEnd,
    toTimeEnd
  ) => {
    let addTime = ":00";
    let data = {};
    let travelId = allData !== null ? allData?.id : "";
    let totime = toTime?.split(":").length < 3 ? toTime + addTime : toTime;
    let fromValuetime =
      fromTime?.split(":").length < 3 ? fromTime + addTime : fromTime;
    let toEndValuetime =
      toTimeEnd?.split(":").length < 3 ? toTimeEnd + addTime : toTimeEnd;
    let fromEndValuetime =
      fromTimeEnd?.split(":").length < 3 ? fromTimeEnd + addTime : fromTimeEnd;
    let fromEndDatevalue = fromDateEnd == undefined ? "" : fromDateEnd;
    let toEndDatevalue = toDateEnd == undefined ? "" : toDateEnd;
    if (editDomestic == undefined) {
      data["user_id"] =
        managerEditing && dynamicUserData ? dynamicUserData?.id : userData?.id;
      data["from_date"] = moment(fromDate).format("YYYY-MM-DD");
      data["to_date"] = moment(toDate).format("YYYY-MM-DD");
      data["from_time"] = fromValuetime;
      data["to_time"] = totime;
      data["trip_start_date"] = moment(fromEndDatevalue).format("YYYY-MM-DD");
      data["trip_start_time"] = fromEndValuetime;
      data["trip_end_date"] = moment(toEndDatevalue).format("YYYY-MM-DD");
      data["trip_end_time"] = toEndValuetime;
    } else {
      data["id"] = editDomestic ? editDomestic.id : 0;
      data["user_id"] =
        managerEditing && dynamicUserData ? dynamicUserData?.id : userData?.id;
      data["from_date"] = moment(fromDate).format("YYYY-MM-DD");
      data["to_date"] = moment(toDate).format("YYYY-MM-DD");
      data["from_time"] = fromValuetime;
      data["to_time"] = totime;
      data["trip_start_date"] = moment(fromDateEnd).format("YYYY-MM-DD");
      data["trip_start_time"] = fromEndValuetime;
      data["trip_end_date"] = moment(toEndDatevalue).format("YYYY-MM-DD");
      data["trip_end_time"] = toEndValuetime;
    }
    DomesticService.isDataExists(travelId, data)
      .then((res) => {
        setIsExist(res?.data?.dataList?.is_exist);
      })
      .catch((err) => console.log("Error=>", err));
  };

  const isAddDataExists = (fromDate, toDate, fromTime, toTime) => {
    let addTime = ":00";
    let data = {};
    let totime = toTime.split(":").length < 3 ? toTime + addTime : toTime;
    let fromValuetime =
      fromTime.split(":").length < 3 ? fromTime + addTime : fromTime;
    data["user_id"] =
      managerEditing && dynamicUserData ? dynamicUserData?.id : userData?.id;
    data["from_date"] = moment(fromDate).format("YYYY-MM-DD");
    data["to_date"] = moment(toDate).format("YYYY-MM-DD");
    data["from_time"] = fromValuetime;
    data["to_time"] = totime;
    data["id"] = editDomestic ? editDomestic.id : 0;

    if (fromDate !== "" && toDate !== "" && fromTime !== "" && toTime !== "") {
      DomesticService.isAddDataExists(data)
        .then((res) => {
          setAddIsExist(res?.data?.dataList?.is_exist);
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const isLeaveExists = (fromDate, toDate, flag) => {
    let data = {};
    data["user_id"] =
      managerEditing && dynamicUserData ? dynamicUserData?.id : userData?.id;
    data["from_date"] = moment(fromDate).format("YYYY-MM-DD");
    data["to_date"] = moment(toDate).format("YYYY-MM-DD");
    if (fromDate !== "" && toDate !== "") {
      DomesticService.isLeaveExists(data)
        .then((res) => {
          setIsLeaveExist(res?.data?.dataList?.is_exist);
          if (res?.data?.dataList?.is_exist == true && flag == "tag") {
            if (migrate == undefined) {
              migrate && edit ? editUpdate() : addMoreTravelInfo();
            }
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const getUsersDetails = (employee_code) => {
    DomesticService.getUsersDetails(employee_code)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setUserDetails(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    if (editDomestic) {
      if (editDomestic?.reason_for_exception_id !== null) {
        setExceptionField(true);
      }
      if (editDomestic?.reason_for_exception_name == "Other") {
        setOtherField(true);
      }
      setFormValidation({
        from_city_id: "",
        to_city_id: "",
        from_date: "",
        from_time: "",
        to_time: "",
        to_date: "",
        purpose_of_travel_id: "",
        additional_information: "",
        mode_id: "",
        hotel: "",
        territory: "",
        hq: "",
        reason: "",
        reason_for_exception_id: "",
        colleague_user_id: "",
      });
    }
  }, [editDomestic]);

  //In t
  const [inputValue, setInputValue] = useState("");
  const [inputValueNext, setInputValueNext] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [displaySuggestionsNext, setDisplaySuggestionsNext] = useState(false);
  const [stateSelection, setStateSelection] = useState(null);
  const [stateSelectionNext, setStateSelectionNext] = useState(null);
  const [messageDisplay, setMessageDisplay] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const [minDateValue, setMinDateValue] = useState("");
  const [minimumValueDate, setMinimumValueDate] = useState("");
  const [maximumValueDate, setMaximumValueDate] = useState("");
  const [minBackDateValue, setMinBackDateValue] = useState("");
  const [maxBackDateValue, setMaxBackDateValue] = useState("");
  const [backDatedError, setBackDatedError] = useState(false);
  const [checkToOrg, setCheckToOrg] = useState();

  useEffect(() => {
    if (moment(new Date()).date() >= 23) {
      setMinDateValue(
        `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
          "MM"
        )}-21`
      );
    } else if (
      `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
        "MM"
      )}-${moment(new Date()).format("DD")}` <= "2024-01-23"
    ) {
      setMinDateValue(
        `${moment(new Date()).subtract(1, "years").format("YYYY")}-${moment(
          new Date()
        )
          .subtract(1, "month")
          .format("MM")}-21`
      );
    } else {
      setMinDateValue(
        `${moment(new Date()).format("YYYY")}-${moment(new Date())
          .subtract(1, "month")
          .format("MM")}-21`
      );
    }
  }, []);

  useEffect(() => {
    if (userBackDate?.length > 0) {
      let curData = userBackDate[userBackDate?.length - 1];
      setMinBackDateValue(curData?.opened_from);
      setMaxBackDateValue(curData?.to_date);
    }
  }, [userBackDate]);

  let suggestions = cities.filter((object) => {
    if (object.label !== "Other - C") {
      return true;
    }
  });

  const onChange = (event, flag) => {
    if (flag === "from_city") {
      const Actualvalue =
        event.target.value?.charAt(0)?.toUpperCase() +
        event.target.value?.slice(1);
      const filteredSuggestionsAll = suggestions?.filter((suggestion) =>
        suggestion?.label?.toLowerCase()?.includes(Actualvalue.toLowerCase())
      );
      if (filteredSuggestionsAll?.length === 0) {
        setDomFormData((prev) => ({
          ...prev,
          from_city_id: null,
        }));
      }
      handleChange(Actualvalue, "from_city_input");
      setInputValue(Actualvalue);
      setFilteredSuggestions(filteredSuggestionsAll);
      setDisplaySuggestions(true);
    }
    if (flag === "to_city") {
      const Actualvalue =
        event.target.value?.charAt(0)?.toUpperCase() +
        event.target.value?.slice(1);
      const filteredSuggestionsAll = suggestions?.filter((suggestion) =>
        suggestion.label?.toLowerCase()?.includes(Actualvalue.toLowerCase())
      );
      if (filteredSuggestionsAll?.length === 0) {
        setDomFormData((prev) => ({
          ...prev,
          to_city_id: null,
        }));
        handleChange(event, "to_city_input");
      } else if (filteredSuggestionsAll?.length === 1) {
        setDomFormData((prev) => ({
          ...prev,
          to_city_id: filteredSuggestionsAll[0]?.value,
        }));
      }
      setInputValueNext(Actualvalue);
      setFilteredSuggestions(filteredSuggestionsAll);
      setDisplaySuggestionsNext(true);
    }
  };

  const onSelectSuggestion = (values, flag) => {
    if (flag === "from_city") {
      let dd = values;
      setInputValue(values?.label);
      setStateSelection(dd);
      setDisplaySuggestions(false);
    }
    if (flag === "to_city") {
      let xx = values;
      setInputValueNext(values?.label);
      setStateSelectionNext(xx);
      setDisplaySuggestionsNext(false);
    }
  };

  useEffect(() => {
    if (stateSelection !== null && stateSelection.value !== 1) {
      setDomFormData((prev) => ({
        ...prev,
        from_city_id: stateSelection.value,
      }));

      setDomFormData((prev) => ({
        ...prev,
        from_city_input: "",
      }));
    }
  }, [stateSelection]);

  useEffect(() => {
    if (stateSelectionNext !== null) {
      setDomFormData((prev) => ({
        ...prev,
        to_city_id: stateSelectionNext.value,
      }));

      setDomFormData((prev) => ({
        ...prev,
        to_city_input: "",
      }));
    }
  }, [stateSelectionNext]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.data.label === "Leave" || state.data.label === "Holiday/WeeklyOff"
          ? "red"
          : "black",
    }),
  };

  const checkTravelMode = (newValue) => {
    setReasonData([]);
    // setDomFormData((prev) => ({ ...prev, reason: "" }));
    setFormValidation({
      ...formValidation,
      mode_id: "",
    });
    if (domFormData?.reason == null || domFormData?.reason == "") {
      setDomFormData((prev) => ({
        ...prev,
        reason_for_exception_id: null,
        reason_for_exception_name: null,
      }));
    }
    if (
      newValue.label === "Car" ||
      newValue.label === "Taxi" ||
      newValue.label === "Personal Car" ||
      newValue.label === "Air"
    ) {
      setColleagueError(false);
      setEmployeeData([]);
      setDomFormData((prev) => ({
        ...prev,
        colleague_user_id: null,
        colleague_user_name: null,
      }));
      setOtherField(false);
    } else if (newValue.label === "Travel with Colleague") {
      setColleagueError(true);
      setEmployeeData([]);
      setDomFormData((prev) => ({
        ...prev,
        colleague_user_id: null,
        colleague_user_name: null,
      }));
      setOtherField(false);
    } else if (
      newValue.label !== "Car" ||
      newValue.label !== "Taxi" ||
      newValue.label !== "Personal Car" ||
      newValue.label !== "Travel with Colleague" ||
      newValue.label !== "Air"
    ) {
      setColleagueError(false);
      setEmployeeData([]);
      setDomFormData((prev) => ({
        ...prev,
        colleague_user_id: null,
        colleague_user_name: null,
      }));
      setOtherField(false);
    }
    // if (domFormData?.purpose == undefined) {
    //   switch (newValue.label) {
    //     case "Air":
    //       if (
    //         !["D", "T", "S", "M"]?.includes(empSubGrp) ||
    //         !userDetails[0]?.emp_subgroup_cons?.includes("S")
    //       ) {
    //         setExceptionField(true);
    //       }
    //       break;

    //     case "Car":
    //       if (
    //         !["D", "T", "S"]?.includes(empSubGrp) ||
    //         !userDetails[0]?.emp_subgroup_cons?.includes("S") ||
    //         (!["1003", "1007", "1017"]?.includes(
    //           userDetails[0]?.personal_area_name
    //         ) &&
    //           ["CO01", "GG01"]?.includes(
    //             !userDetails[0]?.sub_personal_area_name
    //           ))
    //       ) {
    //         setExceptionField(true);
    //       }
    //       break;

    //     case "Taxi":
    //       if (
    //         !["D", "T", "S"]?.includes(empSubGrp) ||
    //         !userDetails[0]?.emp_subgroup_cons?.includes("S")
    //       ) {
    //         setExceptionField(true);
    //       }
    //       break;

    //     case "Personal Car":
    //       if (
    //         !["D", "T", "S"]?.includes(empSubGrp) ||
    //         !userDetails[0]?.emp_subgroup_cons?.includes("S") ||
    //         !userDetails[0]?.personal_area_name === "1007"
    //       ) {
    //         setExceptionField(true);
    //       }
    //       break;
    //     default:
    //       setExceptionField(false);
    //       break;
    //   }
    // }
  };

  const checkLeave = (label) => {
    domData
      ?.filter((ele) => ele?.purpose == "Leave")
      ?.map((value) => {
        if (
          // isLeaveExist == true &&
          moment(value?.from_date).isSame(moment(fromDate)) &&
          moment(value?.to_date).isSame(moment(toDate)) &&
          value?.purpose == "Leave"
        ) {
          setIsLeaveExist(false);
        } else {
          isLeaveExists(fromDate, toDate, label);
        }
      });
  };

  useEffect(() => {
    if (userDetails?.length > 0 && cities?.length > 0 && domData.length === 0) {
      let curUser = userDetails[0];
      if (curUser?.location_id !== null) {
        let match = cities?.filter((obj) => {
          if (obj?.value === curUser?.location_id) {
            return true;
          }
        });
        setInputValue(match[0].label);
        setDomFormData((prev) => ({
          ...prev,
          from_city_id: match[0].value,
        }));
      } else {
        let data = `${curUser?.location} - ${"C"}`;
        setInputValue(data);
        setDomFormData((prev) => ({
          ...prev,
          from_city_input: curUser?.location,
        }));
      }
    }
  }, [userDetails, cities]);

  const textToCheck = userDetails[0]?.personal_area_desc;
  useEffect(() => {
    let empSubGrp = userDetails[0]?.emp_sub_group?.charAt(0);
    let empSubGrpCons = userDetails[0]?.emp_subgroup_cons?.charAt(0);
    if (
      (domFormData?.purpose !== undefined &&
        domFormData?.purpose !== "Leave" &&
        edit !== true) ||
      edit == true
    ) {
      if (
        domFormData?.purpose === "Annual Meet" &&
        (domFormData?.mode_name === "Air" || domFormData?.modee_name === "Air")
      ) {
        setExceptionField(false);
        setDomFormData((prev) => ({
          ...prev,
          reason_for_exception_id: null,
        }));
        setReasonData([]);
        // } else if (
        //   domFormData?.purpose !== "Annual Meet" &&
        //   domFormData?.mode_name == "Air"
        // ) {
        //   setExceptionField(true);
        // } else if (
        //   domFormData?.purpose == "Annual Meet" &&
        //   (domFormData?.mode_name === "Car" ||
        //     domFormData?.mode_name === "Taxi" ||
        //     domFormData?.mode_name === "Personal Bike")
        // ) {
        //   setExceptionField(true);
      } else {      
        let value =
          domFormData?.mode_name == undefined
            ? domFormData.modee_name
            : domFormData.mode_name;
        switch (value) {
          case "Air":            
            if (empSubGrp !== undefined) {
              if (
                !["D", "T", "S", "M"]?.includes(empSubGrp) &&
                !["D", "T", "S", "M"]?.includes(empSubGrpCons) &&
                empSubGrpCons !== null
              ) {
                setExceptionField(true);
              } else {          
                setExceptionField(false);
              }
            }
            break;
          case "Car":
            if (empSubGrp !== undefined) {
              if (["D", "T", "S"]?.includes(empSubGrp)) {
                setExceptionField(false);
              } else if (
                !["D", "T", "S"]?.includes(empSubGrpCons) &&
                empSubGrpCons != null &&
                empSubGrpCons !== undefined
              ) {
                setExceptionField(true);
              } else if (
                ["1003", "1007", "1017"]?.includes(
                  userDetails[0]?.personal_area_name
                ) &&
                !["CO01", "GG01"]?.includes(
                  userDetails[0]?.sub_personal_area_name
                )
              ) {
                setExceptionField(false);
              } else {
                setExceptionField(true);
              }
            }
            break;
          case "Taxi":
            if (empSubGrp !== undefined) {
              if (
                !["D", "T", "S"]?.includes(empSubGrp) ||
                (!["D", "T", "S"]?.includes(empSubGrpCons) &&
                  empSubGrpCons != null)
              ) {
                setExceptionField(true);
              } else {
                setExceptionField(false);
              }
            }
            break;
          case "Personal Car":
            if (empSubGrp !== undefined) {
              if (["D", "T", "S"]?.includes(empSubGrp)) {
                setExceptionField(false);
              } else if (
                !["D", "T", "S"]?.includes(empSubGrpCons) &&
                empSubGrpCons != null &&
                empSubGrpCons !== undefined
              ) {
                setExceptionField(true);
              } else if (
                !["1007"]?.includes(userDetails[0]?.personal_area_name) &&
                vehicleAssignValues !== undefined &&
                vehicleAssignValues == false
              ) {
                setExceptionField(true);
              } else {
                setExceptionField(false);
              }
            }
            break;
          default:
            setExceptionField(false);
            break;
        }
      }
    }
    let fromDataValue =
      domFormData?.from_city_input == "" ? null : domFormData?.from_city_input;
    let toDataValue =
      domFormData?.to_city_input == "" ? null : domFormData?.to_city_input;
    if (
      moment(domFormData?.from_date).format("DD-MM-yyyy") ===
        moment(domFormData?.to_date).format("DD-MM-yyyy") &&
      domFormData?.from_city_id == domFormData?.to_city_id &&
      fromDataValue == toDataValue
    ) {
      let tt = moment(domFormData?.to_time, "HH:mm").diff(
        moment(domFormData?.from_time, "HH:mm"),
        "hours"
      );
      setDateCalcData(tt);
    }
    if (
      domFormData?.from_city_id &&
      domFormData?.to_city_id == dataNew[0]?.location_id
    ) {
      setHqCondition(true);
    } else if (
      domFormData?.from_city_input &&
      domFormData?.to_city_input == dataNew[0]?.location
    ) {
      setHqCondition(false);
    } else {
      setHqCondition(false);
    }
  }, [domFormData]);

  useEffect(() => {
    if (userDetails !== undefined) {
      setCheckToOrg(userDetails[0]?.organisation_code);
    }
  }, [userDetails]);

  useEffect(() => {
    if (hqCondition == true && checkToOrg !== undefined) {
      if (
        (checkToOrg === "PIIL" || checkToOrg == "PIJA") &&
        dateCalcData > 17 &&
        hqCondition === true &&
        inputValue.split("-")[0] == inputValueNext?.split("-")[0]
      ) {
        setConditionValue("true");
        setDomFormData((prev) => ({
          ...prev,
          reason_for_exception_id: "5",
          reason_for_exception_name: "Other",
        }));
        setDomFormData((prev) => ({
          ...prev,
          reason: "Traveled more than 17hr on same day",
        }));
      } else {
        setConditionValue("false");
      }
    }
  }, [dateCalcData, checkToOrg]);

  useEffect(() => {
    if (
      conditionValue == "false" &&
      domFormData?.reason !== "" &&
      domFormData?.reason !== null
    ) {
      setOtherField(false);
      setDomFormData((prev) => ({
        ...prev,
        reason_for_exception_id: null,
        reason_for_exception_name: null,
      }));
      setDomFormData((prev) => ({
        ...prev,
        reason: "",
      }));
    }
  }, [conditionValue]);

  const handleDOJValid = (value) => {
    let DOJ = userDetails[0]?.date_of_joining;
    let date = moment(value).format("YYYY-MM-DD");
    if (moment(DOJ).isAfter(moment(date))) {
      setValidDateJoin(true);
    } else {
      setValidDateJoin(false);
    }
  };

  const handleBackDated = (value) => {
    let date = value;
    if (
      moment(date).isAfter(moment(maxBackDateValue)) ||
      moment(date).isBefore(moment(minBackDateValue))
    ) {
      setBackDatedError(true);
    } else {
      setBackDatedError(false);
    }
  };

  const checkIfIncludesMarketing = () => {
    return textToCheck?.includes("Marketing");
  };

  const timeOptionFunc = () => {
    const timeOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        const timeValue = `${formattedHour}:${formattedMinute}`;
        timeOptions.push({ label: timeValue, value: timeValue });
      }
    }
    setTimeOption(timeOptions);
  };

  useEffect(() => {
    let data = timeOption?.map((option) => {
      return {
        value: option.value,
        label: option.label,
      };
    });
    setTimeSetValue(data);
  }, [timeOption]);

  const customSelectStyle = {
    padding: "8px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "17px",
    backgroundColor: "#fff",
    color: "#333",
    cursor: "pointer",
    outline: "none",
    width: "150px",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    backgroundImage: "url('path/to/your/custom-arrow.png')",
    backgroundPosition: "right 8px center",
    backgroundRepeat: "no-repeat",
  };

  const validateAdditional = () => {
    if (
      userDetails[0]?.personal_area_name == 1003 &&
      (domFormData?.additional_information == "" ||
        domFormData?.additional_information == null)
    ) {
      setMessageDisplay(true);
    } else {
      let payload = { ...domFormData };
      payload.from_date = moment(domFormData?.from_date).format("YYYY-MM-DD");
      payload.to_date = moment(domFormData?.to_date).format("YYYY-MM-DD");
      saveTable(payload);
    }
  };

  useEffect(() => {
    setMinimumValueDate(allData?.travel_detail_data[0]?.from_date);
    setMaximumValueDate(
      allData?.travel_detail_data[allData?.travel_detail_data?.length - 1]
        ?.to_date
    );
  }, [flagSet]);

  return (
    <>
      <div className="px-4 pb-2">
        {validJoinDate && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i>Departed On date
            should be after Date of joining
          </Alert>
        )}
        {!isLeaveExist &&
          isLeaveExist != undefined &&
          domFormData?.purpose != undefined &&
          domFormData?.purpose == "Leave" && (
            <Alert variant="danger" className="mb-5 mt-2 text-center">
              <i className="fa fa-exclamation-triangle"></i>&nbsp;Please apply
              leave first.
            </Alert>
          )}
        {console.log(isLeaveExist, "Leaveeeee22")}
        {isLeaveExist &&
          isLeaveExist != undefined &&
          domFormData?.purpose != undefined &&
          domFormData?.purpose != "Leave" && (
            <Alert variant="danger" className="mb-5 mt-2 text-center">
              <i className="fa fa-exclamation-triangle"></i>&nbsp;Please select
              appropriate value for Travel Mode in case of leave applied.
            </Alert>
          )}
        {(isExist || addIsExist) && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> This date and time is
            already reserved for another journey.
          </Alert>
        )}
        {/* {addIsExist && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> This date and time is
            already reserved for another journey.
          </Alert>
        )} */}
        {messageDisplay && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Additional Info can
            not be Blank
          </Alert>
        )}
        {validDateJoin && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> You can not travel
            before Date of joining
          </Alert>
        )}
        {validTimeZone && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Reaching time cannot
            be less than or same as departure time for same date
          </Alert>
        )}

        <div className="bg-light px-3 pt-3 mb-3">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Departure Location<span className="text-danger">*</span>
                </label>

                <div class="modulesearchbox">
                  <input
                    className="innergroup form-control"
                    type="text"
                    name="from_city_id"
                    onChange={(e) => {
                      onChange(e, "from_city");
                      setDisplaySuggestionsNext(false);
                      setTimeCheck("");
                      setFormValidation({
                        ...formValidation,
                        from_city_id: "",
                      });
                    }}
                    value={
                      inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
                    }
                    placeholder="Search Departure Location..."
                    maxLength={30}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                  />

                  <SuggestionsList
                    inputValue={inputValue}
                    selectedSuggestion={selectedSuggestion}
                    onSelectSuggestion={onSelectSuggestion}
                    displaySuggestions={displaySuggestions}
                    suggestions={filteredSuggestions}
                    flag={"from_city"}
                  />
                </div>
                <div className="small text-danger">
                  {formValidation.from_city_id}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group innergroup d-flex flex-column">
                <label>
                  Departed On<span className="text-danger">*</span>
                </label>
                {/* {console.log(
                  moment(maxBackDateValue).isBefore(moment(valueDate)),
                  "getBackDateData09",
                  moment(maxBackDateValue).isSame(moment(valueDate))
                )} */}
                {/* <input
                  type="date"
                  className="form-control dateIconClick"
                  name="from_date"
                  maxlength="4"
                  min={
                    userBackDate?.length > 0 &&
                      (moment(maxBackDateValue).isBefore(moment(valueDate)) ||
                        moment(maxBackDateValue).isSame(moment(valueDate)))
                      ? minBackDateValue
                      : minDateValue
                  }
                  max={
                    (moment(maxBackDateValue).isBefore(moment(valueDate)) ||
                      moment(maxBackDateValue).isSame(moment(valueDate))) &&
                    maxBackDateValue
                  }
                  onBlur={(e) => {
                    handleDOJValid(e.target.value);
                    if (
                      userBackDate?.length > 0 &&
                      moment(maxBackDateValue).isAfter(moment(valueDate))
                    ) {
                      handleBackDated(e.target.value);
                    }
                  }}
                  onChange={(e) => {
                    handleFromDateChange(e);
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      from_date: "",
                    });
                  }}
                  value={domFormData.from_date}
                /> */}

                {console.log(
                  moment(maxBackDateValue).isBefore(valueDate) ||
                    moment(maxBackDateValue).isSame(valueDate),
                  moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                    moment(maxBackDateValue).isSame(moment(valueDate)),
                  "victoryDated"
                )}
                <DatePicker
                  selected={
                    domFormData?.from_date && domFormData?.from_date !== ""
                      ? new Date(domFormData.from_date)
                      : null
                  }
                  // onBlur={(e) => {
                  //   if (
                  //     userBackDate?.length > 0 &&
                  //     moment(maxBackDateValue).isAfter(moment(valueDate))
                  //   ) {
                  //     handleBackDated(e.target.value);
                  //   }
                  // }}
                  onChange={(date) => {
                    handleDOJValid(date);
                    handleFromDateChange(date);
                    handleChange(date, "from_date");
                    setFormValidation({
                      ...formValidation,
                      from_date: "",
                    });
                  }}
                  minDate={
                    flagSet != true
                      ? userBackDate?.length > 0 &&
                        (moment(maxBackDateValue).isSame(valueDate) ||
                          moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                          moment(maxBackDateValue).isSame(moment(valueDate)))
                        ? new Date(minBackDateValue)
                        : new Date(minDateValue)
                      : new Date(minimumValueDate)
                  }
                  maxDate={
                    flagSet == true
                      ? new Date(maximumValueDate)
                      : new Date().setDate(new Date().getDate() + 90)
                  }
                  // maxDate={
                  //   (moment(maxBackDateValue).isBefore(moment(valueDate)) ||
                  //     moment(maxBackDateValue).isSame(moment(valueDate))) &&
                  //   new Date(maxBackDateValue)
                  // }
                  placeholderText="DD-MM-YYYY"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                />
                <div className="small text-danger">
                  {formValidation.from_date}
                </div>
                {backDatedError && (
                  <div className="small text-danger">
                    You cannot enter BackDate
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Departed Time<span className="text-danger">*</span>
                </label>

                <Select
                  name="from_time"
                  style={customSelectStyle}
                  options={timeSetValue}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    handleChange(e, "from_time");
                    handleFromTimeChange(e);
                    setFormValidation({
                      ...formValidation,
                      from_time: "",
                    });
                  }}
                  value={fromTimeValue}
                ></Select>

                <div className="small text-danger">
                  {formValidation.from_time}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Reaching Location<span className="text-danger">*</span>
                </label>

                <div class="modulesearchbox">
                  <input
                    className="innergroup form-control"
                    type="text"
                    name="to_city_id"
                    onChange={(e) => {
                      onChange(e, "to_city");
                      setDisplaySuggestions(false);
                      setFormValidation({
                        ...formValidation,
                        to_city_id: "",
                      });
                    }}
                    value={
                      inputValueNext?.charAt(0)?.toUpperCase() +
                      inputValueNext?.slice(1)
                    }
                    placeholder="Search Reaching Location..."
                    maxLength={30}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                  />

                  <SuggestionsList
                    inputValueNext={inputValueNext}
                    selectedSuggestion={selectedSuggestion}
                    onSelectSuggestion={onSelectSuggestion}
                    displaySuggestionsNext={displaySuggestionsNext}
                    suggestions={filteredSuggestions}
                    flag={"to_city"}
                  />
                </div>
                <div className="small text-danger">
                  {formValidation.to_city_id}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup d-flex flex-column">
                <label>
                  Reaching On<span className="text-danger">*</span>
                </label>
                {/* <input
                  type="date"
                  className="form-control dateIconClick"
                  name="to_date"
                  maxlength="4"
                  min={
                    userBackDate?.length > 0 &&
                      (moment(maxBackDateValue).isBefore(moment(valueDate)) ||
                        moment(maxBackDateValue).isSame(moment(valueDate)))
                      ? minBackDateValue
                      : moment(domFormData?.from_date).format("YYYY-MM-DD")
                  }
                  max={
                    (moment(maxBackDateValue).isBefore(moment(valueDate)) ||
                      moment(maxBackDateValue).isSame(moment(valueDate))) &&
                    maxBackDateValue
                  }
                  onChange={(e) => {
                    handleToDateChange(e);
                    setTimeCheck("");
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      to_date: "",
                    });
                  }}
                  value={domFormData.to_date}
                /> */}
                <DatePicker
                  selected={
                    domFormData?.to_date && domFormData?.to_date !== ""
                      ? new Date(domFormData.to_date)
                      : null
                  }
                  onChange={(date) => {
                    handleToDateChange(date);
                    setTimeCheck("");
                    handleChange(date, "to_date");
                    setFormValidation({
                      ...formValidation,
                      to_date: "",
                    });
                  }}
                  minDate={
                    flagSet != true
                      ? userBackDate?.length > 0 &&
                        (moment(maxBackDateValue).isSame(valueDate) ||
                          moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                          moment(maxBackDateValue).isSame(moment(valueDate)))
                        ? new Date(minBackDateValue)
                        : new Date(domFormData?.from_date)
                      : new Date(minimumValueDate)
                  }
                  maxDate={
                    flagSet == true
                      ? new Date(maximumValueDate)
                      : domFormData.from_date != ""
                      ? new Date(domFormData.from_date).setDate(
                          new Date(domFormData.from_date).getDate() + 90
                        )
                      : new Date().setDate(new Date().getDate() + 90)
                  }
                  // maxDate={
                  //   (moment(maxBackDateValue).isBefore(moment(valueDate)) ||
                  //     moment(maxBackDateValue).isSame(moment(valueDate))) &&
                  //   new Date(maxBackDateValue)
                  // }
                  placeholderText="DD-MM-YYYY"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                />
                <div className="small text-danger">
                  {formValidation.to_date}
                </div>
                {error && <div className="small text-danger">{error}</div>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Reaching Time<span className="text-danger">*</span>
                </label>
                {/* <input
                  type="time"
                  className="form-control"
                  name="to_time"
                  onChange={(e) => {
                    console.log(">>>hey hello doms", e.target.value);
                    handleToTimeChange(e);
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      to_time: "",
                    });
                  }}
                  value={domFormData.to_time}
                /> */}
                <Select
                  name="to_time"
                  style={customSelectStyle}
                  options={timeSetValue}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    handleChange(e, "to_time");
                    handleToTimeChange(e);
                    setFormValidation({
                      ...formValidation,
                      to_time: "",
                    });
                  }}
                  value={toTimeValue}
                ></Select>
                <div className="small text-danger">
                  {formValidation.to_time}
                </div>
                {errorTime && (
                  <div className="small text-danger">{errorTime}</div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Purpose of Travel<span className="text-danger">*</span>
                </label>
                <Select
                  name="purpose_of_travel_id"
                  id="purpose_of_travel_ids"
                  options={purposeList}
                  styles={customStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(newValue, action) => {
                    checkLeave(newValue.label);
                    handleChange(newValue, action.name);
                    setFormValidation({
                      ...formValidation,
                      purpose_of_travel_id: "",
                    });
                    if (
                      newValue.label === "Pharma Business" &&
                      userDetails[0]?.personal_area_name !== "1001" &&
                      userDetails[0]?.personal_area_name !== "1002" &&
                      userDetails[0]?.organisation_code !== "PIIL" &&
                      userDetails[0]?.business_area !== "PICO" &&
                      userDetails[0]?.business_area !== "PIFC"
                    ) {
                      setPharma(true);
                    }
                    if (newValue.label === "Leave") {
                      setDomFormData((prev) => ({ ...prev, reason: "" }));
                      setLeaveCondition(true);
                      setOtherField(false);
                      setColleagueError(false);
                      setExceptionField(false);
                      setEmployeeData([]);
                      setExceptionField(false);
                      setReasonData();
                      setDomFormData((prev) => ({
                        ...prev,
                        reason_for_exception_id: null,
                        reason_for_exception_name: "",
                        mode_id: null,
                        stay_needed: null,
                      }));
                      setDomFormData((prev) => ({
                        ...prev,
                        colleague_user_id: null,
                        colleague_user_name: "",
                      }));
                      setModeData([]);
                    }
                    if (newValue.label !== "Leave") {
                      // setIsLeaveExist(true);
                      setLeaveCondition(false);
                      setLeaveConditionValue(true);
                    }
                    if (newValue.label !== "Pharma Business") {
                      setPharma(false);
                    }
                  }}
                  value={purposeData}
                />

                <div className="small text-danger">
                  {formValidation.purpose_of_travel_id}
                </div>
              </div>
            </div>
            {/* {leaveCondition ||
            (editDomestic?.mode_id !== undefined &&
              editDomestic?.mode_id !== null) ? ( */}
            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Travel Mode<span className="text-danger">*</span>
                </label>
                <Select
                  name="mode_id"
                  id="mode_ids"
                  options={mode}
                  isDisabled={
                    leaveCondition || domFormData?.purpose == "Leave"
                      ? true
                      : false
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(newValue, action) => {
                    handleChange(newValue, action.name);
                    checkTravelMode(newValue);
                    setConditionValue(0);
                  }}
                  value={modeData}
                />
                <div className="small text-danger">
                  {formValidation.mode_id}
                </div>
              </div>
            </div>
            {/* ) : (
              ""
            )} */}
            {colleagueError ? (
              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Collegue Employee Code
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    name="colleague_user_id"
                    id="colleague_user_ids"
                    options={allEmployee?.filter((obj) => {
                      if (
                        obj.value !==
                        (managerEditing && dynamicUserData
                          ? dynamicUserData?.id
                          : userData?.id)
                      ) {
                        return true;
                      }
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(newValue, action) => {
                      handleChange(newValue, action.name);
                      setFormValidation({
                        ...formValidation,
                        colleague_user_id: "",
                      });
                    }}
                    value={employeeData}
                  />
                  <div className="small text-danger">
                    {formValidation.colleague_user_id}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {exceptionField ? (
              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Reason For Exception<span className="text-danger">*</span>
                  </label>
                  <Select
                    name="reason_for_exception_id"
                    id="reason_for_exception_ids"
                    options={reasons}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(newValue, action) => {
                      handleChange(newValue, action.name);
                      setFormValidation({
                        ...formValidation,
                        reason_for_exception_id: "",
                      });
                      if (newValue.label === "Other") {
                        setOtherField(true);
                      } else if (newValue.label !== "Other") {
                        setOtherField(false);
                      }
                    }}
                    value={reasonData}
                  />
                  <div className="small text-danger">
                    {formValidation.reason_for_exception_id}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {otherField ? (
              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Other Reason<span className="text-danger">*</span>
                  </label>
                  <input
                    name="reason"
                    className="form-control"
                    maxLength={100}
                    placeholder="Enter Other Reason..."
                    value={domFormData.reason}
                    onChange={(e) => {
                      handleChange(e, e.target.name);
                      setFormValidation({
                        ...formValidation,
                        reason: "",
                      });
                    }}
                  />
                  <div className="small text-danger">
                    {formValidation.reason}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {userDetails[0]?.personal_area_name === "GG01" ||
            !checkIfIncludesMarketing() ? (
              ""
            ) : (
              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    HQ<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control newbgselect"
                    name="hq"
                    disabled={
                      leaveCondition || editDomestic?.purpose == "Leave"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (e.target.value === "true") {
                        setHqConditionValue(true);
                        setDomFormData({ ...domFormData, stay_needed: false });
                        setDomFormData((prev) => ({
                          ...prev,
                          hotel: false,
                        }));
                      } else {
                        setHqConditionValue(false);
                        setDomFormData({ ...domFormData, stay_needed: null });
                      }
                      handleChange(e, e.target.name);
                      setFormValidation({
                        ...formValidation,
                        hq: "",
                        stay_needed: null,
                      });
                    }}
                    value={domFormData?.hq}
                  >
                    <option value="null">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <div className="small text-danger">{formValidation.hq}</div>
                </div>
              </div>
            )}
            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Stay Needed<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control newbgselect"
                  name="stay_needed"
                  id="hotelselect"
                  disabled={
                    leaveCondition ||
                    hqConditionValue ||
                    domFormData?.purpose == "Leave"
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setConfirmModal(true);
                    }
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      stay_needed: "",
                    });
                  }}
                  value={domFormData?.stay_needed}
                >
                  <option value="">Select</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
                <div className="small text-danger">
                  {formValidation.stay_needed}
                </div>
              </div>
            </div>

            {territoryPermit && (
              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Territory<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control newbgselect"
                    name="territory"
                    onChange={(e) => {
                      handleChange(e, e.target.name);
                      setFormValidation({
                        ...formValidation,
                        territory: "",
                      });
                    }}
                    value={domFormData.territory}
                  >
                    <option value="">Select</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  <div className="small text-danger">
                    {formValidation.territory}
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12">
              <div className="form-group innergroup">
                <label>Additional Information/Places Visited</label>
                {(userDetails[0]?.personal_area_desc === "Marketing" ||
                  userDetails[0]?.personal_area_desc === "PIJIV Marketing") && (
                  <span className="text-danger">*</span>
                )}
                <textarea
                  name="additional_information"
                  className="form-control"
                  maxLength={200}
                  style={{ width: "100%", height: "auto" }}
                  rows="2"
                  placeholder="Enter Additional Information..."
                  value={domFormData.additional_information}
                  onChange={(e) => {
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      additional_information: "",
                    });
                  }}
                ></textarea>
                <div className="small text-danger">
                  {formValidation.additional_information}
                </div>
              </div>
            </div>
            <div className="col md-12 pb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="border-top my-3"></div>
                </div>

                <div className="col-md-12 mt-2 text-center">
                  {migrate === undefined && (
                    <button
                      className="btn btn-outline-primary mr-2"
                      type="button"
                      onClick={() => {
                        if (
                          isLeaveExist == false &&
                          domFormData?.purpose != undefined &&
                          domFormData?.purpose == "Leave"
                        ) {
                          checkLeave("tag");
                        } else {
                          migrate && edit ? editUpdate() : addMoreTravelInfo();
                        }
                      }}
                    >
                      {edit ? "Update Record" : "Add Record"}
                    </button>
                  )}
                  {migrate && edit ? (
                    <button
                      className="btn btn-outline-primary mr-2"
                      type="button"
                      onClick={() => {
                        if (
                          isLeaveExist == false &&
                          domFormData?.purpose != undefined &&
                          domFormData?.purpose == "Leave"
                        ) {
                          checkLeave("tag");
                        } else {
                          migrate && edit ? editUpdate() : addMoreTravelInfo();
                        }
                      }}
                    >
                      {edit ? "Update Record" : "Add Record"}
                    </button>
                  ) : (
                    ""
                  )}
                  {migrate && !edit ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary mr-2"
                      onClick={() => AddNewRecord()}
                    >
                      Add Records
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    className="btn btn-primary-inner bpi-main mr-2"
                    onClick={() => AllClear()}
                  >
                    Reset Record
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger bpi-main"
                    onClick={() => onClose()}
                  >
                    Close
                  </button>
                  {/* <button
                  type="submit"
                  className="btn btn-outline-danger bpi-main"
                  onClick={(e) => handleSubmit(e)}
                >
                  callll
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}

        {/* <div className="mb-3">
          <div className="row">
            <div className="col-md-12 mt-3">
              <h5 className="text-theme border-bottom pb-2 mb-3">
                <strong>Advance Details</strong>
              </h5>
            </div>
            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Outstanding <i className="fas fa-rupee-sign"></i>
                </label>
                <input
                  type="text"
                  disabled
                  value="Debit : 12500"
                  className="form-control"
                  placeholder="Enter Outstanding"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Advance Amount <i className="fas fa-rupee-sign"></i>{" "}
                  <span className="text-danger"></span>
                </label>
                <input
                  type="number"
                  name="advance_amount"
                  onWheel={(e) => e.target.blur()}
                  className="form-control"
                  onKeyDown={blockInvalidChar}
                  placeholder="Enter Advance Amount"
                  onChange={(e) => {
                    setFinalData((prev) => ({
                      ...prev,
                      advance_amount: e.target.value,
                    }));
                  }}
                  min={0}
                  value={finalData?.advance_amount}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="border-top my-3"></div>
            </div>
            <div className="col-md-12 mt-3 text-center">
              <button
                type="button"
                className="btn btn-outline-primary mr-2"
                // onClick={() => saveTravelInfo("draft")}
              >
                Save As Draft
              </button>
              <button
                type="button"
                className="btn btn-primary-inner bpi-main"
                // onClick={() => saveTravelInfo("pending")}
              >
                Submit
              </button>
            </div>
          </div>
        </div> */}
      </div>
      {confirmModal && (
        <AlertBox
          confirmModal={confirmModal}
          onConfirmClose={onConfirmClose}
          // sendIdData={sendIdData}
          // getTravelList={getTravelList}
        />
      )}
    </>
  );
};

export default AddDomestic;
