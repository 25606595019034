import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import AddTravelIntimation from "./AddTravelIntimation";
import * as moment from "moment";
import Domestic from "./Domestic/Domestic";
import International from "./International/International";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setVehicleAssign } from "../redux/piDataStore";
import * as DomesticService from "../../service/domestic";
import { confirmAlert } from "react-confirm-alert";
import * as vehicleService from "../../service/vehiclelog";

function TravelIntimation(props) {
  const {
    condition,
    allGridDatas,
    allData,
    onViewClose,
    migrate,
    onCallBackTravel,
    onCloseForm,
    mainData,
    managerEditing,
    dynamicUserData,
  } = props;
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const [dataNew, setDataNew] = useState([]);
  const [userBackDate, setUserBackDate] = useState([]);
  const [checkActiveTab, setCheckActiveTab] = useState("Domestic");
  const [allGridData, setAllGridData] = useState(allGridDatas);
  const [roleValue, setRoleValue] = useState(false);
  const [domDataIntegration, setDomDataIntegration] = useState(false);
  const [vehicleAssignData, setVehicleAssignData] = useState();
  const locate = window.location.href;
  const locateFlag = locate?.split("?")[1];
  const flagSet = locateFlag && locateFlag?.includes("isedit");
  const DomesticRef = useRef();
  const InternationalRef = useRef();

  const getUsersDetails = (employee_code) => {
    DomesticService.getUsersDetails(employee_code)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setDataNew(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey1 = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    is_free
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (is_free !== "" && is_free !== undefined && is_free !== null) {
      queryParm = queryParm + "&is_free=" + is_free;
    }
    return queryParm;
  };

  const getVehicleAssignData = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    is_free
  ) => {
    vehicleService
      .getVehicleAssignData(
        handleKey1(search, pageNo, page_size, sort_by, paginate, is_free)
      )
      .then((response) => {
        response?.data?.dataList?.result?.map((item) => {
          if (item !== undefined) {
            if (Number(userData?.username) == Number(item?.user_code)) {
              dispatch(setVehicleAssign(true));
              setVehicleAssignData(item);
            }
          }
        });
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBackDateData = async () => {
    let queryParam = `user_id=${userData?.id}`;
    if (managerEditing && dynamicUserData) {
      queryParam = `user_id=${dynamicUserData?.id}`;
    }
    await DomesticService.getBackDateData(queryParam).then((response) => {
      if (response?.status === 200) {
        setUserBackDate(response?.data?.dataList?.result);
        if (response?.data?.dataList?.result?.length > 0) {
          let userBackDate = response?.data?.dataList?.result[0];
          let fromDate = moment(userBackDate?.from_date);
          let toDate = moment(userBackDate?.to_date);
          let today = moment(new Date());
          if (today.isBetween(fromDate, toDate, "date", "[]")) {
            // setAllowBackDate(true);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (userData?.username !== undefined) {
      if (managerEditing && dynamicUserData) {
        let params = new URL(document.location).searchParams;
        let refid = params.get("id");
        dynamicUserData.ti_ref_id = refid;
        getUsersDetails(dynamicUserData?.username);
      } else {
        getUsersDetails(userData?.username);
      }
      getBackDateData();
    }

    userData?.groups?.map((value, i) => {
      if (value?.name?.toLowerCase() == "instation") {
        setRoleValue(true);
      }
    });

    if (
      [
        "1001",
        "1002",
        "1004",
        "1005",
        "1006",
        "1008",
        "1009",
        "1010",
        "1011",
        "1012",
        "1013",
        "1014",
        "1015",
        "1016",
      ].includes(userData?.personal_area_name) ||
      roleValue == true
    ) {
      // setCheckActiveTab("On-Duty");
    } else {
      setCheckActiveTab("Domestic");
    }
  }, [userData]);

  useEffect(() => {
    getVehicleAssignData("", 1, 400, "-id", true, false);
  }, []);

  const onCallBackDomData = (data) => {
    setDomDataIntegration(data);
  };

  const confirmDialog = (type) => {
    let flag = false;
    switch (checkActiveTab) {
      case "Domestic":
        flag = DomesticRef.current.isDataPresent();
        break;
      case "International":
        flag = InternationalRef.current.isDataPresent();
        break;
      default:
    }
    if (!flag) {
      setCheckActiveTab(type);
      return;
    }
    confirmAlert({
      title: "Warning",
      message:
        "Are you sure want to change the intimation type, after changing the intimation type all saved data will be removed.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setCheckActiveTab(type);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      <div class="row">
        <ToastContainer autoClose={1000} />
        {/* <div class="col-md-3">
          <TravelLinks />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Travel Intimation</h4>
                </div>
                <div class="col-md-12 mb-4">
                  {managerEditing ? (
                    <>
                      <h6 class="text-muted">
                        <strong>TI Reference ID : </strong>
                        <span className="text-muted">
                          {dynamicUserData?.ti_ref_id}
                        </span>
                      </h6>
                      <h6 class="text-muted">
                        <strong>Name : </strong>
                        <span className="text-muted">
                          {dynamicUserData?.first_name +
                            " " +
                            dynamicUserData?.last_name}
                        </span>
                      </h6>
                      <h6 class="text-muted">
                        <strong>Emp Code : </strong>
                        <span className="text-muted">
                          {dynamicUserData?.username}
                        </span>
                      </h6>
                    </>
                  ) : (
                    ""
                  )}
                  <h6 class="text-muted">
                    <strong>Reporting Manager : </strong>
                    {dataNew[0]?.reporting_person_firstname == undefined ? (
                      ""
                    ) : dataNew[0]?.reporting_person_firstname != null ? (
                      <span className="text-muted">
                        {dataNew[0]?.reporting_person_firstname +
                          " " +
                          dataNew[0]?.reporting_person_lastname}
                        (
                        {managerEditing && dynamicUserData
                          ? dynamicUserData?.reporting_to_name
                          : userData?.functional_head_name}
                        )
                      </span>
                    ) : (
                      <span style={{ color: "Red" }}>
                        Your reporting manager is not assigned in MYPI, Kindly
                        contact to your HR.
                      </span>
                    )}
                  </h6>
                  <h6 class="text-muted">
                    <strong>Head Quarter : </strong>
                    <span className="text-muted">{dataNew[0]?.location}</span>
                  </h6>
                  {vehicleAssignData?.vehicle_no != undefined && (
                    <h6 class="text-muted">
                      <strong>Vehicle Number : </strong>
                      <span className="text-muted">
                        {vehicleAssignData?.vehicle_no != undefined
                          ? vehicleAssignData?.vehicle_no
                          : ""}
                      </span>
                    </h6>
                  )}
                </div>
                {/* <div className="col-md-6">
                  <h6 className="f-14 mt-2">
                    <strong>RM :</strong>
                    <span className="text-muted">hey ram</span>
                  </h6>
                </div> */}
              </div>
            </div>
            <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
              <Tabs
                activeKey={
                  migrate == undefined
                    ? checkActiveTab && checkActiveTab == "Domestic"
                      ? "Domestic"
                      : checkActiveTab && checkActiveTab == "On-Duty"
                      ? "On-Duty"
                      : checkActiveTab &&
                        checkActiveTab == "International" &&
                        "International"
                    : condition == "Domestic"
                    ? "Domestic"
                    : condition == "On-Duty"
                    ? "On-Duty"
                    : "International"
                }
                id="approvaltabs"
                onSelect={(e) => confirmDialog(e)}
              >
                {[
                  "1001",
                  "1002",
                  "1004",
                  "1005",
                  "1006",
                  "1008",
                  "1009",
                  "1010",
                  "1011",
                  "1012",
                  "1013",
                  "1014",
                  "1015",
                  "1016",
                ].includes(userData?.personal_area_name) ||
                roleValue == true ? (
                  <Tab
                    id="On-Duty"
                    eventKey="On-Duty"
                    title={<>In Station</>}
                    disabled={
                      allData?.request_type &&
                      allData?.request_type !== "On-Duty"
                    }
                  >
                    <AddTravelIntimation
                      checkActiveTab={checkActiveTab}
                      userBackDate={userBackDate}
                    />
                  </Tab>
                ) : (
                  ""
                )}
                {((condition === "Domestic" || condition == "International") &&
                  migrate) ||
                condition === undefined ? (
                  <Tab
                    eventKey="Domestic"
                    id="Domestic"
                    title={<>Domestic</>}
                    disabled={
                      allData?.request_type &&
                      allData?.request_type !== "Domestic"
                    }
                  >
                    <Domestic
                      checkActiveTab={checkActiveTab}
                      condition={condition}
                      userBackDate={userBackDate}
                      allGridData={allGridData}
                      allData={allData}
                      onViewClose={onViewClose}
                      migrate={migrate}
                      onCallBackTravel={onCallBackTravel}
                      onCallBackDomData={onCallBackDomData}
                      onCloseForm={onCloseForm}
                      mainData={mainData}
                      dataNew={dataNew}
                      ref={DomesticRef}
                      flagSet={flagSet}
                      managerEditing={managerEditing}
                      dynamicUserData={dynamicUserData}
                    />
                  </Tab>
                ) : (
                  ""
                )}
                {((condition === "International" || condition == "Domestic") &&
                  migrate) ||
                condition === undefined ? (
                  <Tab
                    eventKey="International"
                    title={<>International</>}
                    disabled={
                      allData?.request_type &&
                      allData?.request_type !== "International"
                    }
                  >
                    <International
                      checkActiveTab={checkActiveTab}
                      condition={condition}
                      allGridData={allGridData}
                      allData={allData}
                      userBackDate={userBackDate}
                      onViewClose={onViewClose}
                      migrate={migrate}
                      onCallBackTravel={onCallBackTravel}
                      onCloseForm={onCloseForm}
                      mainData={mainData}
                      dataNew={dataNew}
                      ref={InternationalRef}
                      managerEditing={managerEditing}
                      dynamicUserData={dynamicUserData}
                    />
                  </Tab>
                ) : (
                  ""
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TravelIntimation;
