import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Tabs,
  Tab,
} from "react-bootstrap";
import * as moment from "moment";
import Select from "react-select";
import { Formik } from "formik";
import * as HolidayService from "../../service/HolidayService";
import AddHolidayMaster from "./AddHolidayMaster";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import {
  setDisplayLoader,
  selectFinalUrlPermission,
} from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import HolidayBulkImport from "./holidayBulkImport";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const HolidayMaster = () => {
  const dispatch = useDispatch();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [showForm, setShowForm] = useState(false);
  const [holidayManageList, setHolidayManageList] = useState([]);
  const [holidayManageData, setHolidayManageData] = useState([]);
  const [holidayManageList1, setHolidayManageList1] = useState([]);
  const [holidayManageData1, setHolidayManageData1] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [pageCount1, setpageCount1] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [holidayManage, setHolidayManage] = useState(null);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [sortData, setSortData] = useState({
    colName: "day_date",
    sort_by: "day_date",
  });
  const [holidayDataForExport, setHolidayDataForExport] = useState({
    holiday_date: "",
    status: "",
    year: ""
  });
  const [holidayDataFilter, setHolidayDataFilter] = useState({
    holiday_date: "",
    status: "",
    year: ""
  });
  const [currentTab, setCurrentTab] = useState("mandatory");

  const onClose = () => {
    setShowForm(false);
  };

  const getHolidayList = (
    search,
    holiday_date,
    status,
    year,
    page_no,
    page_size,
    sort_by,
    paginate,
    currentTab,
    optional
  ) => {
    HolidayService.getHolidayList(
      handleKey(
        search,
        holiday_date,
        status,
        year,
        page_no,
        page_size,
        sort_by,
        paginate,
        currentTab,
        optional
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setHolidayManageList(response?.data?.dataList?.result);
        setHolidayManageData(response?.data?.dataList?.paginated_data);
        setHolidayManageList1(response?.data?.dataList?.result1);
        setHolidayManageData1(response?.data?.dataList?.paginated_data1);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setpageCount1(response?.data?.dataList?.paginated_data1?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleKey = (
    search,
    holiday_date,
    status,
    year,
    page_no,
    page_size,
    sort_by,
    paginate,
    currentTab,
    optional
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (year !== undefined && year !== null) {
      queryParm = queryParm + "&year=" + year;
    }
    if (
      holiday_date !== "" &&
      holiday_date !== undefined &&
      holiday_date !== null
    ) {
      queryParm = queryParm + "&holiday_date=" + holiday_date;
    }
    if (currentTab !== "" && currentTab !== undefined && currentTab !== null) {
      queryParm = queryParm + "&current_tab=" + currentTab;
    }
    queryParm = queryParm + "&self_data=" + true;
    queryParm = queryParm + "&optional=" + optional;
    return queryParm;
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getHolidayList(
      searchStr,
      holidayDataFilter.holiday_date,
      holidayDataFilter.status,
      holidayDataFilter?.year,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true,
      currentTab,
      currentTab === "optional" ? true : false
    );
  };

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const [importshow, importsetShow] = useState(false);
  const importclose = () => importsetShow(false);
  const importuser = () => importsetShow(true);
  const editHoliday = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setHolidayManage(eData);
  };
  const addNewHoliday = () => {
    setEditMode(false);
    setShowForm(true);
    setHolidayManage(null);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    getHolidayList(
      searchStr,
      holidayDataFilter.holiday_date,
      holidayDataFilter.status,
      holidayDataFilter?.year,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      currentTab,
      currentTab === "optional" ? true : false
    );
  };
  const getExportDetails = (
    search,
    holiday_date,
    status,
    year,
    page_no,
    page_size,
    sort_by,
    paginate,
    currentTab
  ) => {
    dispatch(setDisplayLoader("Display"));
    HolidayService.getExportValue(
      handleKey(
        search,
        holiday_date,
        status,
        year,
        page_no,
        page_size,
        sort_by,
        paginate,
        currentTab
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "HolidayList.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const deleteCountry = (id, idx) => {
    let dataId = { id: idx };
    HolidayService.deleteHoliday(id, dataId)
      .then((res) => {
        if (res.data.status == true) {
          getHolidayList(
            "",
            "",
            "",
            "",
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true,
            currentTab,
            currentTab === "optional" ? true : false
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const submit = (id, countryName) => {
    confirmAlert({
      title: "Delete Holiday ",
      message: `Are you sure to delete ${countryName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCountry(id, id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getHolidayList(
      searchStr,
      holidayDataFilter.holiday_date,
      holidayDataFilter.status,
      holidayDataFilter?.year,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      currentTab,
      currentTab === "optional" ? true : false
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getHolidayList(
        e.target.value.toLowerCase(),
        holidayDataFilter.holiday_date,
        holidayDataFilter.status,
        holidayDataFilter?.year,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        currentTab,
        currentTab === "optional" ? true : false
      );
      setSearchStr(e.target.value);
    }
  };

  const filterClose = () => {
    filterclose();
  };
  const filterShow = () => {
    filteropen();
    getHolidayList(false);
  };
  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(holidayDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setHolidayDataFilter(ClearData);
    getHolidayList(
      searchStr,
      "",
      "",
      holidayDataFilter.status,
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      currentTab,
      currentTab === "optional" ? true : false
    );
    filterClose();
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getHolidayList(
        data,
        holidayDataFilter.holiday_date,
        holidayDataFilter.status,
        holidayDataFilter?.year,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        currentTab,
        currentTab === "optional" ? true : false
      );
    }
  };
  const mainDataFilter = () => {
    setHolidayDataForExport({
      ...holidayDataForExport,
      holiday_date: holidayDataFilter.holiday_date,
      status: holidayDataFilter.status,
      year: holidayDataFilter?.year
    });
    const status = holidayDataFilter.status;
    const holiday_date = holidayDataFilter.holiday_date;
    const year = holidayDataFilter?.year
    getHolidayList(
      searchStr,
      holiday_date,
      status,
      year,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      currentTab,
      currentTab === "optional" ? true : false
    );
    filterClose();
  };
  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getHolidayList(
      searchStr,
      holidayDataFilter.holiday_date,
      holidayDataFilter.status,
      holidayDataFilter?.year,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      currentTab,
      currentTab === "optional" ? true : false
    );
  }, [pageSizeNo, currentTab]);

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="content-wrapper-inner content-wrapper-inner2">
          <div class="innerheadsec">
            <div className="row">
              <div class="col-md-12">
                <h4 class="inner-page-title">Holiday Master</h4>
                {/* <Breadcrumb>
                  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="/home">
                    Role Management
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Holiday Master</Breadcrumb.Item>
                </Breadcrumb> */}
              </div>
              <div class="col-md-12">
                <div class="border-top mt-2 mt-md-4 mb-3"></div>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-4">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="overlay-example">
                      Search on <strong> Occasion name, Day</strong>.
                    </Tooltip>
                  }
                >
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      id="searchbar"
                      class="form-control"
                      placeholder="Search on Occasion name..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                    <span class="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          class="far fa-times-circle cursor-pointer"
                          onClick={() => {
                            closeButtonCallBack();
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
              <div class="col-lg-8">
                <div class="inline-spacing btn-betweenspaing">
                  {finalUrlValue?.D ? (
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            // disabled={
                            //   holidayManageList.length !== 0 ? false : true
                            // }
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                searchStr,
                                holidayDataForExport.holiday_date,
                                holidayDataForExport.status,
                                holidayDataForExport.year,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true,
                                currentTab
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    ""
                  )}
                  <button onClick={filteropen} class="btn btn-secondary-inner">
                    <img src="images/filter.png" alt="" class="mr-3" />
                    Filter
                  </button>
                  {/* {userData?.is_admin === true && ( */}
                  {finalUrlValue?.U ? (
                    <button class="btn btn-danger" onClick={importuser}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Bulk Upload
                    </button>
                  ) : (
                    ""
                  )}
                  {finalUrlValue?.A ? (
                    <>
                      <button
                        class="btn btn-primary-inner"
                        onClick={() => {
                          addNewHoliday();
                        }}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add New
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>

          <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
            <Tabs
              defaultActiveKey="Mandatory"
              id="approvaltabs"
              onSelect={(e) => {
                setCurrentTab(e == "Optional" ? "optional" : "mandatory");
              }}
            >
              <Tab
                eventKey="Mandatory"
                title={<React.Fragment>Mandatory Holidays</React.Fragment>}
              >
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th
                          onClick={(e) => {
                            handlerData("name");
                          }}
                          className={
                            sortData["name"] === undefined
                              ? "unsortcls main-pointer"
                              : sortData["name"]
                          }
                        >
                          Occasion name
                        </th>
                        <th
                          onClick={(e) => {
                            handlerData("day_date");
                          }}
                          className={
                            sortData["day_date"] === undefined
                              ? "unsortcls main-pointer"
                              : sortData["day_date"]
                          }
                        >
                          Date
                        </th>
                        <th
                          onClick={(e) => {
                            handlerData("day");
                          }}
                          className={
                            sortData["day"] === undefined
                              ? "unsortcls main-pointer"
                              : sortData["day"]
                          }
                        >
                          Day
                        </th>
                        {/* <th>Status</th> */}
                        {/* <th>Actions</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {holidayManageList?.length > 0 ? (
                        holidayManageList?.map(
                          (data, i) =>
                            data?.type == "mandatory" && (
                              <tr>
                                <td>{i + 1}</td>
                                <td class="fw-bold text-theme">{data?.name}</td>
                                <td>
                                  {" "}
                                  {moment(data?.day_date).format("DD-MM-YYYY")}
                                </td>
                                <td>{moment(data?.day_date).format("dddd")}</td>
                                {/* 
                                  {data?.is_active ? (
                                    <span className="badge bad-status badge-success">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge bad-status badge-danger">
                                      InActive
                                    </span>
                                  )}
                                </td> */}
                              </tr>
                            )
                        )
                      ) : (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {holidayManageList.length == 0 ? (
                  ""
                ) : (
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}

                <div class="row jvactionsbutton">
                  <div class="col-md-12">
                    <div class="p-3">
                      {/* <button class="btn btn-success">
                        <i class="fas fa-sync"></i> Sync
                      </button> */}
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab
                eventKey="Optional"
                title={<React.Fragment>Optional Holidays</React.Fragment>}
              >
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        {/* <th
                          onClick={(e) => {
                            handlerData("year");
                          }}
                          className={
                            sortData["year"] === undefined
                              ? "unsortcls main-pointer"
                              : sortData["year"]
                          }
                        >
                          Year
                        </th> */}
                        <th
                          onClick={(e) => {
                            handlerData("name");
                          }}
                          className={
                            sortData["name"] === undefined
                              ? "unsortcls main-pointer"
                              : sortData["name"]
                          }
                        >
                          Occasion name
                        </th>

                        <th
                          onClick={(e) => {
                            handlerData("day_date");
                          }}
                          className={
                            sortData["day_date"] === undefined
                              ? "unsortcls main-pointer"
                              : sortData["day_date"]
                          }
                        >
                          Date
                        </th>
                        <th
                          onClick={(e) => {
                            handlerData("day");
                          }}
                          className={
                            sortData["day"] === undefined
                              ? "unsortcls main-pointer"
                              : sortData["day"]
                          }
                        >
                          Day
                        </th>
                        {finalUrlValue?.A ? (
                          <th
                            onClick={(e) => {
                              handlerData("is_active");
                            }}
                            className={
                              sortData["is_active"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["is_active"]
                            }
                          >
                            Status
                          </th>
                        ) : (
                          ""
                        )}
                        {/* {finalUrlValue?.A ? */}
                        {finalUrlValue?.E || finalUrlValue?.R ? (
                          <th>Actions</th>
                        ) : (
                          ""
                        )}
                        {/* :""} */}
                      </tr>
                    </thead>
                    <tbody>
                      {holidayManageList1?.length ? (
                        holidayManageList1?.map(
                          (data, index) =>
                            data?.type == "optional" && (
                              <tr>
                                <td>{index + 1}</td>
                                {/* <td>{data?.year}</td> */}
                                <td class="fw-bold text-theme">{data?.name}</td>
                                <td>
                                  {" "}
                                  {moment(data?.day_date).format("DD-MM-YYYY")}
                                </td>
                                <td>{moment(data?.day_date).format("dddd")}</td>
                                {finalUrlValue?.A ? (
                                  <td>
                                    {data?.is_active ? (
                                      <span className="badge bad-status badge-success">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge bad-status badge-danger">
                                        Inactive
                                      </span>
                                    )}
                                  </td>
                                ) : (
                                  ""
                                )}

                                {finalUrlValue?.E || finalUrlValue?.R ? (
                                  <td>
                                    {finalUrlValue?.E ? (
                                      <button
                                        class="btn btn-blanktd text-primary"
                                        onClick={() => {
                                          editHoliday(data);
                                        }}
                                        disabled={moment(
                                          data?.day_date
                                        ).isBefore(moment(new Date()))}
                                      >
                                        <i class="far fa-edit"></i>{" "}
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {finalUrlValue?.R ? (
                                      <button
                                        class="btn btn-blanktd text-danger ml-2"
                                        disabled={moment(
                                          data?.day_date
                                        ).isBefore(moment(new Date()))}
                                      >
                                        <i
                                          class="far fa-trash-alt text-danger"
                                          onClick={() => {
                                            submit(data?.id, data?.name);
                                          }}
                                        ></i>
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                ) : (
                                  ""
                                )}
                              </tr>
                            )
                        )
                      ) : (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {holidayManageList1.length == 0 ? (
                  ""
                ) : (
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      {importsetShow && (
        <HolidayBulkImport
          importshow={importshow}
          importclose={() => {
            importclose();
            getHolidayList(
              searchStr,
              holidayDataFilter.holiday_date,
              holidayDataFilter.status,
              holidayDataFilter?.year,
              currentPage,
              pageSizeNo,
              sortData.sort_by,
              true,
              currentTab,
              currentTab === "optional" ? true : false
            );
          }}
        />
      )}
      <Formik
        initialValues={holidayDataFilter}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                {/* <div class="form-group innergroup">
                  <label>
                    Select Holiday Type<span class="text-danger">*</span>
                  </label>
                  <select class="form-control newbgselect">
                    <option>Select</option>
                    <option>Mandatory</option>
                    <option>Optional</option>
                  </select>
                </div> */}
                <div class="form-group innergroup">
                  <label>
                    Select Date<span class="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    name="holiday_date"
                    onChange={(e) => {
                      setHolidayDataFilter({
                        ...holidayDataFilter,
                        holiday_date: e.target.value,
                      });
                      setCurrentPage(1);
                    }}
                    value={holidayDataFilter.holiday_date}
                  />
                </div>
                <div class="form-group innergroup">
                  <label>
                    Select Status<span class="text-danger">*</span>
                  </label>
                  <select
                    className="form-control newbgselect main-pointer"
                    name="status"
                    onChange={(e) => {
                      setHolidayDataFilter({
                        ...holidayDataFilter,
                        status: e.target.value,
                      });
                      setCurrentPage(1);
                    }}
                    value={holidayDataFilter.status}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value={true} label="Active" />
                    <option value={false} label="In-Active" />
                  </select>
                </div>
                <div class="form-group innergroup">
                  <label>Select Year</label>
                  <select
                    className="form-control newbgselect main-pointer"
                    name="year"
                    onChange={(e) => {
                      setHolidayDataFilter((prev) => ({
                        ...prev,
                        year: e.target.value,
                      }));
                      setCurrentPage(1);
                    }}
                    value={holidayDataFilter?.year}
                  >
                    <option value="" label="Select Leave Type" hidden />
                    <option
                      value={moment(new Date()).subtract(1, "year").format("YYYY")}
                      label={moment(new Date()).subtract(1, "year").format("YYYY")}
                    >
                      {moment(new Date()).subtract(1, "year").format("YYYY")}
                    </option>
                  </select>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  class="btn btn-outline-danger"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  Clear
                </button>
                <button class="btn btn-primary-inner bpi-main">Apply</button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
      {showForm ? (
        <>
          <AddHolidayMaster
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editHoliday={holidayManage}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default HolidayMaster;
