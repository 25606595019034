import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as TravelListingAPI from "../../service/travelService";
import { Dropdown, Modal } from "react-bootstrap";
import * as moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import ReactPaginate from "react-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

function AllOtherAdvance(props) {
  const { backDateData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, filtersetShow] = useState(false);
  const [reminderBtnFlag, setReminderBtnFlag] = useState(false);
  const [reminderModalFlag, setReminderModalFlag] = useState({
    flag: false,
    id: 0,
  });
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageRecord, setpageRecord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [travelList, setTravelList] = useState([]);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [userDataNew, setUserDataNew] = useState(false);
  const userDetails = useSelector(selectUserData);
  const [userOption, setUserOption] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterdata, setfilterData] = useState({
    from: "",
    to: "",
    username: 0,
  });
  const filteropen = () => filtersetShow(true);
  const filterclose = () => {
    filtersetShow(false);
    getAdvanceDetails(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      // userDetails?.id,
      null,
      "",
      "",
      "",
      ""
    );
    setfilterData({
      from: "",
      to: "",
      username: 0,
    });
    setSelectedUser(null);
  };

  let userListController = null;

  const getBackDateList = async (data) => {
    await TravelListingAPI.getBackDateList(data)
      .then((response) => {
        setTravelList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserList = () => {
    if (userListController) userListController.abort();
    userListController = new AbortController();
    TravelListingAPI.getUserList(userListController.signal).then((res) => {
      userListController = null;
      let userList = [];
      res?.data?.dataList?.result?.forEach((obj) => {
        if (obj.is_active)
          userList.push({
            label: `${obj.first_name} ${obj.last_name} (${obj.username})`,
            value: obj.id,
          });
      });
      setUserOption(userList);
    });
  };

  const getAdvanceDetails = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    from_date,
    to_date
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getAdvanceDetailsNew(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        status,
        from_date,
        to_date
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let data = response?.data?.dataList?.result.map((each) => {
          each.first_approver_remark = "";
          each.latest_remark = "";
          each.latest_remark_date = "";

          let rejected = each?.approval_list?.filter((x) => {
            if (x?.status === "R") {
              return true;
            }
          });
          if (rejected?.length > 0) {
            each.rejected_by = `${rejected[0]?.full_name} (${rejected[0]?.username})`;
            each.latest_remark_date = moment.parseZone(rejected[0]?.updated_at).format("DD-MM-YYYY");
          }

          let approvedList = each?.approval_list?.filter(
            (obj) => obj.status === "A"
          );
          let latestRemark =
            approvedList.length > 0
              ? approvedList[approvedList.length - 1].remark
              : "";
          each.latest_remark = latestRemark;

          let approverMap = {};
          let len = 0;
          each?.approval_list?.forEach((obj) => {
            if (Object.keys(approverMap).includes(obj.approver_type)) {
              approverMap[obj.approver_type].push(obj);
            } else {
              approverMap[obj.approver_type] = [obj];
              len++;
            }
          });
          let approverMapData = Object.values(approverMap);
          if (len > 0) {
            let firsrApprovedList = approverMapData[0]?.filter(
              (obj) => obj.status === "A"
            );
            each.first_approver_remark =
              firsrApprovedList.length > 0
                ? firsrApprovedList[firsrApprovedList.length - 1].remark
                : "";

            let approvedList = approverMapData[len - 1]?.filter(
              (obj) => obj.status === "A"
            );
            each.latest_remark =
              approvedList.length > 0
                ? approvedList[approvedList.length - 1].remark
                : "";
            
            if(approvedList.length > 0){
              each.latest_remark_date = moment.parseZone(approvedList[approvedList.length - 1].updated_at).format("DD-MM-YYYY");
            }
          }
          return each;
        });
        setUserDataNew(data);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
        // dispatch(setMessage(response?.data?.dataList?.result));
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    from_date,
    to_date
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (user_id && user_id > 0) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    // if (user_id !== "" && user_id !== undefined && user_id !== null) {
    //   queryParm = queryParm + "&user_id=" + user_id;
    // }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    return queryParm;
  };

  // const getUserDetails = async () => {
  //   await TravelListingAPI.getAdvanceDetails()
  //     .then((response) => {
  //       let res = response?.data?.dataList?.result;
  //       setUserDataNew(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    // getAdvanceDetails(
    //   searchStr,
    //   activePage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   // userDetails?.id
    // );
    getAdvanceDetails(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata.username,
      "",
      filterdata.from !== "" ? filterdata.from : "",
      filterdata.to !== ""
        ? moment(filterdata.to).add(1, "days").format("YYYY-MM-DD")
        : ""
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getAdvanceDetails(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
        // userDetails?.id
      );
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getAdvanceDetails(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
        // userDetails?.id
      );
    }
  };

  const otherAdvanceReminder = (id) => {
    TravelListingAPI.otherAdvanceReminder(id).then((res) => {
      toast.success("Mail sent");
      setReminderModalFlag({ flag: false, id: 0 });
    });
  };

  const filterMyListing = () => {
    getAdvanceDetails(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata.username,
      "",
      filterdata.from !== ""
        ? filterdata.from
        : moment(new Date()).format("YYYY-MM-DD"),
      filterdata.to !== ""
        ? filterdata.to
        : moment(new Date()).add(1, "days").format("YYYY-MM-DD")
    );
    filtersetShow(false);
    // if (filterdata.username) {
    // } else {
    //   toast.error("All fields are mandatory")
    // }
  };

  const exportAllOtherAdvance = () => {
    dispatch(setDisplayLoader("Display"));
    let payload =
      filterdata.from_date !== "" ? `?from_date=${filterdata.from}` : "";
    if (filterdata.to !== "") payload += `&to_date=${filterdata.to}`;
    if (filterdata.username > 0) payload += `&user_id=${filterdata.username}`;

    TravelListingAPI.exportAllOtherAdvance(payload)
      .then((res) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "All_Other_Advance.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCurrentUser = () => {
    TravelListingAPI.getCurrentUser().then((res) => {
      let flag = false;
      res.data?.dataList?.groups.forEach((obj) => {
        if (obj.name === "SSC User" || obj.name === "SSC Manager") flag = true;
      });
      setReminderBtnFlag(flag);
    });
  };

  useEffect(() => {
    // getBackDateList(userDetails?.id);
    // getUserDetails();
    getCurrentUser();
    getAdvanceDetails(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      // userDetails?.id,
      null,
      "",
      "",
      "",
      ""
    );
    getUserList();
    return () => {
      if (userListController) userListController.abort();
    };
  }, []);

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <div class="col-md-4" style={{ float: "left" }}>
                    {" "}
                    <h5 class="inner-page-title">All Other Advance</h5>
                  </div>
                  <div class="col-md-6" style={{ float: "right" }}>
                    {" "}
                    <p
                      class="inner-page-title"
                      style={{
                        fontSize: "10px",
                        color: "grey",
                        float: "right",
                      }}
                    >
                      *Transactions approved between date 1st to 5th of every
                      month, may delay due to posting period not open.
                    </p>
                  </div>
                </div>

                <div class="col-md-12 d-flex justify-content-between">
                  <div class="col-md-4" style={{ float: "left" }}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Employee Code,
                              <br />
                              Employee Name, <br />
                              Request Id <br />
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          class="form-control"
                          placeholder="Search on Requested Id..."
                          onChange={(e) => {
                            handleSearch(e);
                            setCurrentPage(1);
                          }}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className="col-lg-8">
                    <div className="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <button
                              type="button"
                              // disabled={travelList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() => exportAllOtherAdvance()}
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        className="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" className="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured travelintim">
                <thead>
                  <tr>
                    <th>Emp Id</th>
                    <th>Emp Name</th>
                    <th>Emp Vendor Code</th>
                    <th>Advance Other Req. No</th>
                    <th>Other Advance Request On Dated</th>
                    <th>Advance Amt</th>
                    <th>SAP Balance Upto</th>
                    <th>Reason For Advance Taken</th>
                    <th>Approved / Reject by Reporting Manager on DT.</th>
                    <th>Reporting Manager Remarks </th>
                    <th>Approved / Reject by FH on DT</th>
                    <th>Functional Head Remarks</th>
                    <th>DPR No</th>
                    <th>Action</th>
                    <th>Settelment Date</th>
                    <th>SAP Status</th>
                  </tr>
                </thead>
                <tbody>
                  {userDataNew?.length ? (
                    userDataNew?.map((data, index) => (
                      <tr>
                        <td>{data?.username}</td>
                        <td>{data?.full_name}</td>
                        <td>{data?.employee_vendor_code}</td>
                        <td>{`${data?.user_branch}OA${data?.id}`}</td>
                        <td>{moment.parseZone(data?.created_at).format("DD-MM-YYYY")}</td>
                        <td> {data?.advance_amount ?? "-"}</td>

                        <td>{data?.out_standing_balance}</td>
                        <td>{data?.reason_advance_amount ?? "-"}</td>
                        <td className="d-flex justify-content-center">
                          {" "}
                          <Tooltip
                            position="bottom"
                            title={
                              data?.status === "A" ? (
                                <span>Approved</span>
                              ) : data?.status === "P" ? (
                                `Pending at ${data?.approve} (${data?.approve_username})`
                              ) : data?.status === "R" ? (
                                `Rejected by ${data?.rejected_by}`
                              ) : data?.status === "D" ? (
                                "Draft"
                              ) : (
                                "-"
                              )
                            }
                          >
                            {data?.status === "A" ? (
                              <span class="badge bad-status badge-success">
                                Approved
                              </span>
                            ) : data?.status === "P" ? (
                              <span
                                class="badge bad-status badge-warning"
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                Pending by{" "}
                                {`${data?.approve} (${data?.approve_username})`}
                              </span>
                            ) : data?.status === "R" ? (
                              <span
                                class="badge bad-status badge-danger"
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                Rejected by {data?.rejected_by}
                              </span>
                            ) : data?.status === "D" ? (
                              <span class="badge bad-status badge-danger">
                                Draft
                              </span>
                            ) : (
                              "-"
                            )}{" "}
                            {/* {data?.approve_username === null ||
                              data?.approve_username === ""
                              ? ""
                              : data?.approve_username} */}
                          </Tooltip>
                        </td>
                        <td>{data.first_approver_remark}</td>
                        <td>{data.latest_remark_date}</td>
                        <td>{data.latest_remark}</td>

                        <td>{data.sap_doc_no}</td>
                        <td>
                          {/* {data?.sap_status === "A" ? (
                            <span class="badge bad-status badge-success">
                              Approved
                            </span>
                          ) : (
                            <span class="badge bad-status badge-danger">
                              Pending
                            </span>
                          )} */}
                          {reminderBtnFlag &&
                          data?.status === "A" &&
                          moment(data?.advance_amount_date).isBefore(
                            new Date()
                          ) ? (
                            <button
                              className="btn btn-primary-inner bpi-main f-14 py-1"
                              style={{ padding: 7 }}
                              onClick={() =>
                                setReminderModalFlag({
                                  flag: true,
                                  id: data.id,
                                })
                              }
                            >
                              Reminder
                            </button>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          {moment(data?.advance_amount_date).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{data.sap_status ? "Posted" : ""}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {userDataNew?.length == 0 ? (
              ""
            ) : (
              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control main-pointer"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={reminderModalFlag.flag}
        onHide={() => setReminderModalFlag({ flag: false, id: 0 })}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-12 text-center">
                  {/* <i class="fas fa-check-circle fa-2x text-success mt-3 mb-3"></i> */}
                  <p class="fa-20 text-muted mb-4">
                    <b>Confirm</b> to send reminder.
                  </p>
                  <div class="mb-3">
                    <button
                      class="btn btn-primary-inner bpi-main f-14 py-1"
                      onClick={() => otherAdvanceReminder(reminderModalFlag.id)}
                    >
                      Yes
                    </button>
                    <button
                      class="btn btn-outline-primary f-14 py-1"
                      onClick={() =>
                        setReminderModalFlag({ flag: false, id: 0 })
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-12 text-center">
                  <i class="fas fa-check-circle fa-2x text-success mt-3 mb-3"></i>
                  <p class="fa-20 text-muted mb-4">
                    You are generating SAP Trip No, After this you will not be
                    able to change tour
                  </p>

                  <div class="mb-3">
                    <Link to="/claim-listing" class="mr-2">
                      <button class="btn btn-primary-inner bpi-main f-14 py-1">
                        Yes
                      </button>
                    </Link>
                    <button
                      class="btn btn-outline-primary f-14 py-1"
                      onClick={addnewclose}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={() => filtersetShow(false)}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup d-flex flex-column">
            <label>
              User <span className="text-danger">*</span>
            </label>
            <Select
              options={userOption}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setfilterData({
                  ...filterdata,
                  username: e.value,
                });
                setSelectedUser(e);
              }}
              value={selectedUser}
            />
          </div>

          <div className="form-group innergroup d-flex flex-column">
            <label>
              From <span className="text-danger">*</span>
            </label>
            <DatePicker
              key={filterdata.from}
              selected={
                filterdata.from !== "" ? new Date(filterdata.from) : new Date()
              }
              onChange={(date) =>
                setfilterData({
                  ...filterdata,
                  from: date ? moment(date).format("YYYY-MM-DD") : ""
                })
              }
              maxDate={new Date(filterdata.to)}
              placeholderText="DD-MM-YYYY"
              className="form-control"
              dateFormat="dd-MM-yyyy"
            />
          </div>

          <div className="form-group innergroup d-flex flex-column">
            <label>
              To <span className="text-danger">*</span>
            </label>
            <DatePicker
              key={filterdata.to}
              selected={
                filterdata.to !== "" ? new Date(filterdata.to) : new Date()
              }
              onChange={(date) =>
                setfilterData({
                  ...filterdata,
                  to: date ? moment(date).format("YYYY-MM-DD") : ""
                })
              }
              minDate={new Date(filterdata.from)}
              placeholderText="DD-MM-YYYY"
              className="form-control"
              dateFormat="dd-MM-yyyy"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            //  onClick={() => clearfilter()}
            onClick={filterclose}
          >
            Clear
          </button>
          <button
            className="btn btn-primary-inner bpi-main"
            onClick={filterMyListing}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AllOtherAdvance;
