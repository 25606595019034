import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import * as moment from "moment";
import { toast } from "react-toastify";
import { ToastContainer } from "react-bootstrap";
import { Tooltip } from "react-tippy";
import * as VendorService from "../../service/cashbookService";

function ViewExpenes(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [reverseData, setReverseData] = useState([]);

  const updateCashbook = (id, data) => {
    let obj = { status: data };
    VendorService.updateCashbook(id, obj)
      .then((response) => {
        setReverseData(response?.data?.dataList?.result);
        toast.success("Expenses Reversed Successfully");
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  var TodayDate = new Date();
  var myDate = new Date(state?.created_at);
  myDate.setHours(myDate.getHours() + 24);
  console.log(myDate > TodayDate, myDate, TodayDate, "oikanu");
  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link to="/cashbook-expenses">Manage Expenses</Link>
            </li>
            <li>
              <Link to="/cashbook-receipts">Manage Receipts</Link>
            </li>
            <li>
              <Link to="/cashbook-vendors">Manage Vendors</Link>
            </li>
            <li>
              <Link to="/cashbook-demand">Cash Demand</Link>
            </li>
            <li>
              <Link to="/cashbook-approval">For Approval</Link>
            </li>
            <li>
              <Link to="/cashbook-reports">Reports</Link>
            </li>
          </ul>
        </div> */}

        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">View Expense: PICB{state?.id}</h3>
                </div>
              </div>
              <div class="row viewformaftersubmit">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Request ID</label>
                    <p>PICB{state?.id}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Approver</label>
                    <p>
                      {state?.current_approver_data?.current_approver_name ??
                        "-"}
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Total Amount</label>
                    <p>{state?.total_amount ?? "-"}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured viewpo">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Cost Center</th>
                    <th>Amount</th>
                    <th>Vendor</th>
                    <th>PI GSTNO</th>
                    <th>Invoice</th>
                    <th>Date</th>
                    <th>Item Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span class="badge badge-secondary">
                        {state?.id ?? "-"}
                      </span>
                    </td>
                    <td>{state?.cost_center_id ?? "-"}</td>
                    <td>{state?.total_amount ?? "-"}</td>
                    <td>
                      {state?.vendor_name ?? "-"}
                      {/* <br /> */}
                      {/* Registered
                      <br />
                      Link-1, KK Road, Raipur, C.G. */}
                    </td>
                    <td>{state?.pi_gst_no ?? "-"}</td>
                    <td> {state?.invoice_no ?? "-"}</td>
                    <td>
                      {moment(state?.created_at).format("DD-MM-YYYY hh:mm A")}
                    </td>
                    <td>{state?.status ?? "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-4 mb-2 ml-3">
              <h6 class="text-theme mb-0">
                <i class="far fa-arrow-alt-circle-right"></i>{" "}
                <strong>Items</strong>
              </h6>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured viewpo">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>SAP No</th>
                    <th>SAP Message</th>
                    <th>SAP Reverse Message</th>
                    <th>Expense GL</th>
                    <th>HSN Code</th>
                    <th>UOM</th>
                    <th>Quantity</th>
                    <th>GST Credit</th>
                    <th>Tax Rate</th>
                    <th>Tax Code</th>
                    <th>Base Amount</th>
                    <th>Tax Amount</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>

                <tbody>
                  {state?.expense_detail?.length ? (
                    state?.expense_detail?.map((data, index) => (
                      <tr>
                        {console.log(data, "kanussoni")}
                        <td>
                          <span class="badge badge-secondary">
                            {data?.item_no ?? "-"}
                          </span>
                        </td>
                        <td>{data?.document_no ?? "-"}</td>

                        <td>
                          <Tooltip title={data?.sap_message} position="bottom">
                            {data?.sap_message}
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip
                            title={data?.sap_reverse_message}
                            position="bottom"
                          >
                            {data?.sap_reverse_message ?? "--"}
                          </Tooltip>
                        </td>
                        <td>{data?.expense_gl_code ?? "-"}</td>
                        <td> {data?.hsn ?? "-"}</td>
                        <td>{data?.uom ?? "-"}</td>
                        <td>{data?.quantity ?? "-"}</td>
                        <td>{data?.credit ?? "-"}</td>
                        <td>{data?.tax_rate ?? "-"}%</td>
                        <td>{data?.tax_code ?? "-"}</td>
                        <td>{data?.base_amount ?? "-"}</td>
                        <td>{data?.tax_amount ?? "-"}</td>
                        <td>{data?.total_amount ?? "-"}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div class="col-md-12 mt-4 pb-3 text-center">
              <button
                class="btn btn-outline-secondary mr-2"
                onClick={() =>
                  navigate("/cashbook-expenses", {
                    state: {
                      defaultActiveTab: "purchase",
                    },
                  })
                }
              >
                <i class="fas fa-arrow-circle-left"></i> Back
              </button>

              {console.log(state, "statekiusoni")}
              {state?.status == "R" || state?.status == "reverse" ? (
                ""
              ) : (
                <>
                  {myDate > TodayDate == true ? (
                    <button
                      class="btn btn-primary-inner mr-2"
                      onClick={(e) => {
                        updateCashbook(state?.id, "reverse");
                        navigate("/cashbook-expenses");
                      }}
                    >
                      <i class="far fa-times-circle"></i> Reverse
                    </button>
                  ) : (
                    ""
                  )}
                </>
              )}

              {/* <button class="btn btn-primary-inner bpi-main">
                <i class="far fa-check-square"></i> Approve
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewExpenes;
