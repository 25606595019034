import React from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

const ViewEmployee = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;
  return (
    <>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Employee Code <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.code}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Name <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.name}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Email Id <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.email}</p>
                  </div>
                </div>

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Country Code <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.country_code}</p>
                  </div>
                </div> */}

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Mobile No. <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.mobile}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Department <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.department_name}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Designation <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.designation_name}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Location <span className="text-danger"></span>
                    </label>
                    <p>
                      {viewFormData?.sub_personal} (
                      {viewFormData?.sub_personal_name})
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Landline No. <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.telephone_ext}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Sequence <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.sequence}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Masking Mobile <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.masking_mobile == true ? "YES" : "NO"}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Masking Email <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.masking_email == true ? "YES" : "NO"}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Office Address <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.office_address}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Geo Location <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.geo_location}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Created By <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.created_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Created At <span className="text-danger"></span>
                    </label>
                    <p>
                      {" "}
                      {moment
                        .utc(viewFormData?.created_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Updated By <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.updated_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Updated At <span className="text-danger"></span>
                    </label>
                    <p>
                      {moment
                        .utc(viewFormData?.updated_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label className="d-block">
                      Status <span className="text-danger"></span>
                    </label>
                    <label
                      className="logCheck d-inline-block mr-4 mt-2"
                      style={{ cursor: "auto" }}
                    >
                      Active
                      <input
                        type="radio"
                        name="statusupdate1"
                        checked={viewFormData.is_active === true}
                        readOnly
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      className="logCheck d-inline-block"
                      style={{ cursor: "auto" }}
                    >
                      Inactive
                      <input
                        type="radio"
                        name="is_active"
                        checked={viewFormData.is_active === false}
                        readOnly
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={onViewClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewEmployee;
