import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getPurchaseOrder = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/purchase-order/po_list?${queryParm}`);
};

export const getViewPurchaseOrder = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/purchase-order/view_po?po_id=${id}`);
};

export const uploadAttachment = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};
